import { call, put, select, cancel } from 'redux-saga/effects';
import { REQUEST } from '../constants/actionType';
import {
  getRequestsService,
  getRequestDetailService,
  addRequestsService,
  updateRequestService,
  duplicateRequestService,
  exportDataRequestService,
  cancelRequestService,
  getEmployeesService,
  getTotalTabService,
  approvalRequestService,
  denyRequestService,
  getTasksService,
  addTaskService,
  updateTaskService,
  changeProgressTaskService,
  getActivitiesLogRequestService,
  getTimelineRequestService,
  changeProgressRequestService,
} from '../services/request';
import { countBadgesRequest } from '../actions/notice';
import { getDepartmentsService } from '../services/department';
import { getApartments } from '../services/apartment';
import { X_UNIT_GROUP_ID } from '../constants';
import { checkResponseError } from '../libs/http';
import { pick, map, isEmpty } from 'lodash';

export function* getRequests(action) {
  const {
    buildingId,
    page,
    filterObject: {
      from,
      to,
      receiver,
      keyword,
      requestFrom,
      unitId,
    },
    limit,
    status
  } = action.payload;

  const result = yield call(getRequestsService, {
    params: {
      page,
      limit,
      status,
      from,
      to,
      departmentId: receiver,
      requestFrom,
      keyword,
      unitId,
    },
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, REQUEST.REQUEST_GETS);

  yield put({
    ...result,
  });
  yield put({
    type: REQUEST.REQUEST_GETS.REFRESH
  });
}

export function* getRequestDetail(action) {
  const {
    buildingId,
    requestId
  } = action.payload;

  const result = yield call(getRequestDetailService, {
    params: { requestId },
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, REQUEST.REQUEST_GET);

  yield put({
    ...result,
  });
  yield put({
    type: REQUEST.REQUEST_GET.REFRESH
  });
}

export function* approvalRequest(action) {
  const {
    buildingId,
    requestId,
    dueDate,
  } = action.payload;

  const result = yield call(approvalRequestService, {
    params: { requestId },
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
    body: {
      dueDate,
    }
  }, REQUEST.REQUEST_APPROVE);

  yield put(result);
  yield put({ type: REQUEST.REQUEST_APPROVE.REFRESH });
  yield put(countBadgesRequest());
}

export function* denyRequest(action) {
  const {
    buildingId,
    requestId,
    note
  } = action.payload;

  const result = yield call(denyRequestService, {
    params: { requestId, note },
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, REQUEST.REQUEST_DENY);

  yield put({
    ...result,
  });
  yield put({
    type: REQUEST.REQUEST_DENY.REFRESH
  });
}

export function* addRequest(action) {
  const {
    buildingId,
    description,
    departmentId,
    unitId,
    residentId,
    dueDate,
    receiverId,
  } = action.payload;

  const result = yield call(addRequestsService, {
    body: {
      description,
      departmentId,
      unitId,
      residentId,
      dueDate,
      receiverId,
    },
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, REQUEST.REQUEST_ADD);

  yield put({
    ...result,
  });

  yield put({
    type: REQUEST.REQUEST_ADD.REFRESH,
  });
}

export function* updateRequest(action) {
  const {
    buildingId,
    title,
    description,
    departmentId,
    receiverId,
    labelIds,
    requestId,
    dueDate,
  } = action.payload;

  const result = yield call(updateRequestService, {
    body: {
      description,
      departmentId,
      receiverId,
      dueDate,
    },
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
    id: requestId
  }, REQUEST.REQUEST_UPDATE);

  yield put(result);
  yield put({ type: REQUEST.REQUEST_UPDATE.REFRESH });
  yield put(countBadgesRequest());
}

export function* duplicateRequest({ payload }) {
  const {
    id,
    description,
    dueDate,
    tasks,
    departmentId,
    receiverId,
    buildingId,
  } = payload;

  const result = yield call(duplicateRequestService, {
    body: {
      id,
      description,
      dueDate,
      tasks,
      departmentId,
      receiverId
    },
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, REQUEST.REQUEST_DUPLICATE);

  yield put({
    ...result,
  });

  yield put({
    type: REQUEST.REQUEST_DUPLICATE.REFRESH,
  });
}

export function* exportDataRequest({ payload }) {
  try {
    const headers = {
      [X_UNIT_GROUP_ID]: payload.buildingId,
    };

    let params = pick(payload, ['keyword', 'requestFrom', 'unitId', 'receiverId', 'from', 'to', 'status']);

    const totalPage = yield select(state => state.request.requestGets.totalPage);

    let tasks = map(new Array(totalPage), (o, index) => call(exportDataRequestService, {
      headers,
      params: {
        ...params,
        page: index + 1,
        limit: 20,
      }
    }));

    let result = [];
    for (let task of tasks) {
      const respone = yield task;
      if (!checkResponseError(respone)) {
        result = [...result, ...respone.data];
      }
    }

    yield put({
      type: REQUEST.REQUEST_EXPORT_DATA.SUCCESS,
      payload: {
        code: 200,
        result,
      },
    });
    yield put({
      type: REQUEST.REQUEST_EXPORT_DATA.REFRESH,
    });
  } catch (error) {
    yield put({
      type: REQUEST.REQUEST_EXPORT_DATA.FAIL,
      payload: {
        code: 500,
        error,
      },
    });
  }
}

export function* cancelRequest(action) {
  const {
    buildingId,
    requestId,
    progressId = 0,
    note,
  } = action.payload;

  const result = yield call(changeProgressRequestService, {
    body: {
      note
    },
    params: {
      requestId,
      progressId,
    },
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, REQUEST.REQUEST_CANCEL);

  yield put(result);
  yield put({ type: REQUEST.REQUEST_CANCEL.REFRESH });
  yield put(countBadgesRequest());
}

export function* changeProgressRequest(action) {
  const {
    buildingId,
    requestId,
    progressId,
    note = '',
  } = action.payload;

  const result = yield call(changeProgressRequestService, {
    body: {
      note
    },
    params: {
      requestId,
      progressId,
    },
    
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, REQUEST.REQUEST_CHANGE_PROGRESS);

  yield put(result);
  yield put({ type: REQUEST.REQUEST_CHANGE_PROGRESS.REFRESH });
  yield put(countBadgesRequest());
}

export function* getDepartmentsInRequest(action) {
  const {
    buildingId,
  } = action.payload;

  const result = yield call(getDepartmentsService, {
    params: {},
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, REQUEST.REQUEST_DEPARTMENT_GETS);

  yield put({
    ...result,
  });
  yield put({
    type: REQUEST.REQUEST_DEPARTMENT_GETS.REFRESH
  });
}

export function* getApartmentsInRequest(action) {
  const {
    page = 1,
    limit = 20,
    filters,
    buildingId,
  } = action.payload;

  const result = yield call(getApartments, buildingId, page, limit, filters, REQUEST.REQUEST_APARTMENT_GETS);

  yield put(result);
  yield put({ type: REQUEST.REQUEST_APARTMENT_GETS.REFRESH });
}

export function* getEmployeesInRequest(action) {
  const {
    buildingId,
  } = action.payload;

  const result = yield call(getEmployeesService, {
    params: {},
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, REQUEST.REQUEST_EMPLOYEES_GETS);

  yield put({
    ...result,
  });
  yield put({
    type: REQUEST.REQUEST_EMPLOYEES_GETS.REFRESH
  });
}

export function* getTotalTabs(action) {
  const {
    buildingId,
  } = action.payload;

  const result = yield call(getTotalTabService, {
    params: {},
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, REQUEST.REQUEST_TOTAL_TAB_GETS);

  yield put({
    ...result,
  });
  yield put({
    type: REQUEST.REQUEST_TOTAL_TAB_GETS.REFRESH
  });
}

export function* getTasks(action) {
  const {
    buildingId,
    requestId,
  } = action.payload;

  const result = yield call(getTasksService, {
    params: {
      requestId,
    },
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, REQUEST.REQUEST_TASK_GETS);

  yield put(result);
  yield put({ type: REQUEST.REQUEST_TASK_GETS.REFRESH });
}

export function* addTask(action) {
  const {
    buildingId,
    requestId,
    description,
    dueDate,
    assignees,
    attachments,
  } = action.payload;

  const result = yield call(addTaskService, {
    body: {
      requestId,
      description,
      dueDate,
      assignees,
      attachments,
    },
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, REQUEST.REQUEST_TASK_ADD);

  yield put(result);
  yield put({ type: REQUEST.REQUEST_TASK_ADD.REFRESH });
}

export function* updateTask(action) {
  const {
    buildingId,
    taskId,
    dueDate,
    // assignees,
    attachments,
  } = action.payload;

  const result = yield call(updateTaskService, {
    body: {
      dueDate,
      // assignees,
      attachments,
    },
    params: {
      taskId,
    },
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, REQUEST.REQUEST_TASK_UPDATE);

  yield put(result);
  yield put({ type: REQUEST.REQUEST_TASK_UPDATE.REFRESH });
}

export function* changeProgressTask(action) {
  const {
    buildingId,
    taskId,
    progressId,
    note,
  } = action.payload;

  const result = yield call(changeProgressTaskService, {
    body: {
      note,
    },
    params: {
      taskId,
      progressId,
    },
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, REQUEST.REQUEST_TASK_CHANGE_PROGRESS);

  yield put(result);
  yield put({ type: REQUEST.REQUEST_TASK_CHANGE_PROGRESS.REFRESH });
}

export function* getActivitiesLogRequest(action) {
  const {
    buildingId,
    requestId,
  } = action.payload;

  const result = yield call(getActivitiesLogRequestService, {
    params: {
      requestId,
    },
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, REQUEST.REQUEST_ACTIVITY_LOG);

  yield put(result);
  yield put({ type: REQUEST.REQUEST_ACTIVITY_LOG.REFRESH });
}

export function* getTimelineRequest(action) {
  const {
    buildingId,
    requestId,
  } = action.payload;

  const result = yield call(getTimelineRequestService, {
    params: {
      requestId,
    },
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, REQUEST.REQUEST_TIMELINE);

  yield put(result);
  yield put({ type: REQUEST.REQUEST_TIMELINE.REFRESH });
}




