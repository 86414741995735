import { combineReducers } from 'redux';
import { RESIDENT_UTILITY } from '../constants/actionType';
import paginate, { initStatePagination } from './paginate';
import fetching, { initStateFetching } from './fetching';

export const initState = {
  utilityGets: {
    ...initStatePagination,
  },
  utilityDetail: {
    ...initStatePagination,
  },
  utilityAdd: {
    ...initStateFetching,
  },
  utilityUpdate: {
    ...initStateFetching,
  },
  utilityDelete: {
    ...initStateFetching,
  },
  utilityChangeStatus: {
    ...initStateFetching,
  },
  iconGets: {
    ...initStatePagination,
  },
};

const utilityGets = paginate(RESIDENT_UTILITY.UTILITY_GETS);
const utilityDetail = paginate(RESIDENT_UTILITY.UTILITY_DETAIL);
const utilityAdd = fetching(RESIDENT_UTILITY.UTILITY_ADD);
const utilityUpdate = fetching(RESIDENT_UTILITY.UTILITY_UPDATE);
const utilityDelete = fetching(RESIDENT_UTILITY.UTILITY_DELETE);
const utilityChangeStatus = fetching(RESIDENT_UTILITY.UTILITY_CHANGE_STATUS);

const iconGets = paginate(RESIDENT_UTILITY.ICON_GETS);

export default combineReducers({
  utilityGets,
  utilityDetail,
  utilityAdd,
  utilityUpdate,
  utilityDelete,
  utilityChangeStatus,
  iconGets,
});
