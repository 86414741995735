import { DEBT } from './service-update-until-current-time.types';

export const getDataDebt = ({ unitGroupId, page, filterObject }) => ({
  type: DEBT.GETS.REQUEST,
  payload: {
    unitGroupId,
    page,
    filterObject,
  },
});

export const exportDefault = ({ unitGroupId, filterObject }) => ({
  type: DEBT.EXPORT_DEFAULT.REQUEST,
  payload: {
    unitGroupId,
    filterObject,
  },
});

export const exportRevenue = ({ unitGroupId, filterObject }) => ({
  type: DEBT.EXPORT_REVENUE.REQUEST,
  payload: {
    unitGroupId,
    filterObject,
  },
});

export const exportDebt = ({ unitGroupId, filterObject }) => ({
  type: DEBT.EXPORT_DEBT.REQUEST,
  payload: {
    unitGroupId,
    filterObject,
  },
});

export const exportRemainingDebt = ({ unitGroupId, filterObject }) => ({
  type: DEBT.EXPORT_REMAINING_DEBT.REQUEST,
  payload: {
    unitGroupId,
    filterObject,
  },
});

export const exportRevenueService = ({ unitGroupId, filterObject }) => ({
  type: DEBT.EXPORT_REVENUE_SERVICE.REQUEST,
  payload: {
    unitGroupId,
    filterObject,
  },
});

export const getSumTotalPaymentDebt = ({ unitGroupId, filterObject }) => ({
  type: DEBT.TOTAL.REQUEST,
  payload: {
    unitGroupId,
    filterObject,
  },
});
