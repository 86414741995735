import { combineReducers } from 'redux';
import { SETTING_REGISTRATION_FORM_GETS, SETTING_REGISTRATION_FORM_UPDATE } from './resident-registration-form.types';

export const initState = {
  update: {
    code: undefined,
    codeLanguage: undefined,
    isFetching: false,
  },
  gets: {
    isFetching: false,
    data: [],
  },
};

const reducer = combineReducers({
  gets: (state = initState.gets, action) => {
    switch (action.type) {
      case SETTING_REGISTRATION_FORM_GETS.REQUEST:
        return {
          isFetching: true,
          data: [],
        };
      case SETTING_REGISTRATION_FORM_GETS.SUCCESS:
        return {
          isFetching: false,
          data: action.payload.result,
        };
      case SETTING_REGISTRATION_FORM_GETS.FAIL:
      case SETTING_REGISTRATION_FORM_GETS.REFRESH:
        return {
          isFetching: false,
          data: [],
        };
      default: {
        return state;
      }
    }
  },
  update: (state = initState.update, action) => {
    switch (action.type) {
      case SETTING_REGISTRATION_FORM_UPDATE.REQUEST:
        return {
          isFetching: true,
          code: undefined,
          codeLanguage: undefined,
        };
      case SETTING_REGISTRATION_FORM_UPDATE.SUCCESS:
      case SETTING_REGISTRATION_FORM_UPDATE.FAIL:
        return {
          isFetching: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
        };
      case SETTING_REGISTRATION_FORM_UPDATE.REFRESH:
        return {
          ...initState.update,
        };
      default: {
        return state;
      }
    }
  },
});

export default reducer;
