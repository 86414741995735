import { PAYMENT_INIT, PAYMENT_BILL_GETS, PAYMENT_BILL_SUM_PAYMENT_GETS, PAYMENT_RECEIPT_CREATE, PAYMENT_UNIT_GETS } from './payment.types';

export const initPaymentPage = (unitGroupId = 0) => ({
  type: PAYMENT_INIT.REQUEST,
  payload: {
    unitGroupId,
  },
});

export const getBillInPagePayment = ({ unitGroupId = 0, page = 1, filterObject }) => ({
  type: PAYMENT_BILL_GETS.REQUEST,
  payload: {
    unitGroupId,
    page,
    filterObject,
  },
});

export const getSumPayment = ({ unitGroupId, filterObject }) => ({
  type: PAYMENT_BILL_SUM_PAYMENT_GETS.REQUEST,
  payload: {
    unitGroupId,
    filterObject,
  },
});

export const createReceiptPagePayment = (receipt, unitGroupId) => ({
  type: PAYMENT_RECEIPT_CREATE.REQUEST,
  payload: {
    receipt,
    unitGroupId,
  },
});

export const getUnits = ({ name, page, limit }, unitGroupId) => ({
  type: PAYMENT_UNIT_GETS.REQUEST,
  payload: {
    name,
    page,
    limit,
    unitGroupId,
  },
});
