import { callApi, downloadFile } from './api';
import {
  PAY_SLIP_GETS,
  PAY_SLIP,
  PAY_SLIP_CANCEL,
  PAY_SLIP_PREVIEW,
  PAY_SLIP_EXPORT,
  PAY_SLIP_SUM_TOTAL_PAYMENT,
  PAY_SLIP_UPDATE,
} from '../defined/API';
import { METHOD, PAGINATION, X_UNIT_GROUP_ID } from '../constants';
import { handleError, handleResponse } from '../libs/http';

export async function getPaySlipService(buildingId, page, limit = 20, filters, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;

  try {
    let response = await callApi(PAY_SLIP_GETS, {}, METHOD.GET, {
      params: {
        ...filters,
        page,
        limit,
      },
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      }
    });
    return handleResponse(response, type, 'result', page, limit);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function addPaySlipService(buildingId, body, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;

  try {
    let response = await callApi(PAY_SLIP, body, METHOD.POST, { headers: { [X_UNIT_GROUP_ID]: buildingId } });
    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function updatePaySlipService(buildingId, data, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;

  const body = { labelId: data.labelId };

  try {
    let response = await callApi(PAY_SLIP_UPDATE(data.paySlipId), body, METHOD.PUT, { headers: { [X_UNIT_GROUP_ID]: buildingId } });
    return handleResponse(response, type, 'data');
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function cancelPaySlipService(buildingId, paySlipId, body, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;

  try {
    let response = await callApi(PAY_SLIP_CANCEL(paySlipId), body, METHOD.POST, { headers: { [X_UNIT_GROUP_ID]: buildingId } });
    return handleResponse(response, type, 'data');
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function exportPaySlipService({ params, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;

  try {
    let response = await downloadFile(PAY_SLIP_EXPORT, {}, METHOD.GET, { params, headers });

    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getPaySlipPreviewService(buildingId, paySlipId, type) {
  const { FAIL } = type;

  try {
    let response = await callApi(PAY_SLIP_PREVIEW(paySlipId), null, METHOD.GET, {
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      },
      bodyType: 'html'
    });

    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getSumTotalPaymentService({ headers, params }, type) {
  const { FAIL } = type;

  try {
    let response = await callApi(PAY_SLIP_SUM_TOTAL_PAYMENT, null, METHOD.GET, { headers, params });
    
    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}
