import { DOCUMENT } from '../constants';

export const initPageDocument = (unitGroupId) => ({
    type: DOCUMENT.INIT.REQUEST,
    payload: {
        unitGroupId,
    },
});

export const getDepartmentsInPageDocument = (unitGroupId) => ({
    type: DOCUMENT.DEPARTMENT.GETS.REQUEST,
    payload: {
        unitGroupId,
    },
});

export const getDocuments = (buildingId: number, page: number, filters: Object) => ({
    type: DOCUMENT.GETS.REQUEST,
    payload: {
        buildingId,
        page,
        filters,
    },
});

export const getDocumentsInMoveToBox = (buildingId: number, filter: Object) => ({
    type: DOCUMENT.GETS_IN_MOVE_TO_BOX.REQUEST,
    payload: {
        buildingId,
        filter,
    },
});

export const addDocument = ({ document, unitGroupId }) => ({
    type: DOCUMENT.ADD.REQUEST,
    payload: {
        document,
        unitGroupId,
    },
});

export const updateDocument = ({ document, unitGroupId }) => ({
    type: DOCUMENT.UPDATE.REQUEST,
    payload: {
        document,
        unitGroupId,
    },
});

export const deleteDocument = (id, unitGroupId) => ({
    type: DOCUMENT.DELETE.REQUEST,
    payload: {
        id,
        unitGroupId,
    },
});