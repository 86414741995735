import { REPORT } from '../../constants/actionType';
import { all, call, put } from 'redux-saga/effects';
import { getLabelsService } from '../../services/label';
import { X_UNIT_GROUP_ID } from '../../constants';
import { getServiceInUseService } from '../../services';

export function* initPageReport(action) {
    try {
        const {
            unitGroupId,
        } = action.payload;

        const [labels, services] = yield all([
            call(getLabelsService, { headers: { [X_UNIT_GROUP_ID]: unitGroupId } }, REPORT.LABEL_GETS),
            call(getServiceInUseService, { headers: { [X_UNIT_GROUP_ID]: unitGroupId } }, REPORT.SERVICE_GETS),
        ]);
        yield all([
            put(labels),
            put(services),
        ]);
        yield put({
            type: REPORT.INIT.SUCCESS,
            payload: {
                code: 200
            }
        });
    } catch (e) {
        yield put({
            type: REPORT.INIT.FAIL,
            payload: {
                code: 0,
                error: e,
            },
        });
    }
}
