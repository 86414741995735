import { handleError, handleResponse } from '../libs/http';
import { callApi, callApiWithFile, downloadFile } from './api';
import { METHOD } from '../constants';
import {
  REPORT_REPORT_STORED,
  REPORT_REPORT_STORED_BY_ID,
  REPORT_REPORT_STORED_DETAIL,
  REPORT_REPORT_STORED_EXPORT,
  REPORT_REPORT_STORED_TOTAL,
  REPORT_STORED,
  REPORT_STORED_SAVE,
  REPORT_DEBT,
  REPORT_DEBT_EXPORT,
  REPORT_DEBT_TOTAL,
  REPORT_INVESTOR,
  REPORT_INVESTOR_EXPORT,
  REPORT_INVESTOR_TOTAL,
  REPORT_OPERATING,
  REPORT_OPERATING_EXPORT,
  REPORT_PAID_BEFORE,
  REPORT_PAID_BEFORE_EXPORT,
  REPORT_PAID_BEFORE_TOTAL,
  REPORT_BILL,
  REPORT_BILL_TOTAL,
  REPORT_BILL_EXPORT,
} from 'defined/API';

export async function getDebtService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(REPORT_DEBT, null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type, 'result', true);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

export async function getDebtTotalService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(REPORT_DEBT_TOTAL, null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

export async function exportDebtService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await downloadFile(REPORT_DEBT_EXPORT, null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

// advance payment
export async function getAdvancePaymentService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(REPORT_PAID_BEFORE, null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type, 'result', true);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

export async function getAdvancePaymentTotalService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(REPORT_PAID_BEFORE_TOTAL, null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

export async function exportAdvancePaymentService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await downloadFile(REPORT_PAID_BEFORE_EXPORT, null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

// investor
export async function getInvestorService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(REPORT_INVESTOR, null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type, 'result', true);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

export async function getInvestorTotalService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(REPORT_INVESTOR_TOTAL, null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

export async function exportInvestorService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await downloadFile(REPORT_INVESTOR_EXPORT, null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

export async function getOperatingService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(REPORT_OPERATING, null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

export async function exportOperatingService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await downloadFile(REPORT_OPERATING_EXPORT, null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

// report stored

export async function getReportStoredService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(REPORT_STORED, null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type, 'result', true);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

export async function getReportStoredServiceByIdService(
  { reportId, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(REPORT_REPORT_STORED_BY_ID(reportId), null, METHOD.GET, {
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

export async function getReportStoredDetailService(
  { reportId, params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(REPORT_REPORT_STORED_DETAIL(reportId), null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type, 'result', true);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

export async function getReportStoredTotalService(
  { reportId, params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(REPORT_REPORT_STORED_TOTAL(reportId), null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

export async function saveDataReportStoredService(
  { body, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(REPORT_STORED_SAVE, body, METHOD.POST, {
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

export async function exportReportStoredService(
  { reportId, params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await downloadFile(REPORT_REPORT_STORED_EXPORT(reportId), null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

export async function getUnitWithBillsService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIl',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(REPORT_BILL, null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type, 'result', true);
  } catch (error) {
    return handleError(error, FAIL);
  }
}

export async function totalUnitWithBillsService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIl',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(REPORT_BILL_TOTAL, null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type);
  } catch (error) {
    return handleError(error, FAIL);
  }
}

export async function exportUnitWithBillsService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIl',
  }
) {
  const { FAIL } = type;
  try {
    const response = await downloadFile(REPORT_BILL_EXPORT, null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type);
  } catch (error) {
    return handleError(error, FAIL);
  }
}
