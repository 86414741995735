import { COMMUNICATE } from '../../constants/actionType';
import { call, put } from 'redux-saga/effects';
import { checkRead } from '../../actions/communicate/residentMessage';
import {
  getRoomChatService,
  getRoomChatByIdService,
  getRoomMemberService,
  getMessagesService,
  sendMessagesService,
  readMessageByIdService,
  readAllMessageService,
  createRoomChatService
} from '../../services/residentMessage';
import {
  getDepartmentsOfUserService
} from 'services/department';
import { countBadges } from '../../actions/notice';
import { pick } from 'lodash';
import { X_UNIT_GROUP_ID } from 'shared/constants';

export function* getRoomChat(action) {
  const { buildingId, data } = action.payload;

  const params = pick(data, ['keyword', 'offset', 'limit', 'requestBy']);

  const result = yield call(getRoomChatService, buildingId, params, COMMUNICATE.ROOM_CHAT.GETS);

  yield put(result);
}

export function* getRoomChatById(action) {
  const { buildingId, data } = action.payload;

  const params = pick(data, ['departmentIds']);
  const roomId = data.roomId;

  const result = yield call(getRoomChatByIdService, buildingId, roomId, params, COMMUNICATE.ROOM_CHAT.GET_BY_ID);

  yield put(result);
}

export function* getRoomMember(action) {
  const {
    buildingId,
    data: { roomType, unitId }
  } = action.payload;

  const result = yield call(getRoomMemberService, buildingId, { unitId, roomType }, COMMUNICATE.ROOM_CHAT.GETS_MEMBER);

  yield put(result);
}

export function* getMessages(action) {
  const { buildingId, data } = action.payload;

  const params = pick(data, ['offset', 'limit']);
  const roomId = data.roomId;

  const result = yield call(getMessagesService, buildingId, roomId, params, COMMUNICATE.MESSAGES.GETS);

  yield put(result);
}

export function* sendMessages(action) {
  const { buildingId, data } = action.payload;

  const body = pick(data, ['content', 'attachmentURL']);

  const roomId = data.roomId;

  const result = yield call(sendMessagesService, buildingId, { roomId, body }, COMMUNICATE.MESSAGES.SEND_MESSAGE);

  yield put(result);
  yield put({ type: COMMUNICATE.MESSAGES.SEND_MESSAGE.REFRESH });
}

export function* readMessagesById(action) {
  const { buildingId, data: { roomId, messageId } } = action.payload;

  const result = yield call(readMessageByIdService, buildingId, { roomId, messageId }, COMMUNICATE.MESSAGES.READ_BY_ID);

  yield put(result);
}

export function* readMessagesAll(action) {
  const { buildingId, data: { roomId } } = action.payload;

  const result = yield call(readAllMessageService, buildingId, { roomId }, COMMUNICATE.MESSAGES.READ_ALL);

  yield put(result);
  yield put(checkRead(roomId));

  // Refresh badges after read all.
  yield put(countBadges());
}

export function* getDepartmentOfUser(action) {
  try {
    const { buildingId, userId } = action.payload;

    const options = {
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      },
      params: {
        userId
      }
    };
    const result = yield call(getDepartmentsOfUserService, options, COMMUNICATE.DEPARTMENT.GETS);
    yield put(result);
  } catch (error) {
    console.log(error);
  }
}

export function* createRoomChat(action) {
  try {
    const { name, departmentIds, unitIds, buildingId, content } = action.payload;

    const result = yield call(createRoomChatService, buildingId, {
      name,
      departmentIds,
      unitIds,
      content
    }, COMMUNICATE.ROOM_CHAT.CREATE);

    yield put(result);
  } catch (error) {
    console.log(error);
  }
}