import { combineReducers } from 'redux';
import paginate, { initStatePagination } from 'shared/utils/paginate';
import fetching, { initStateFetching } from 'shared/utils/fetching';
import {
  SEND_BILL_CANCEL_INVOICE,
  SEND_BILL_GET_DETAIL_PREVIEW,
  SEND_BILL_PRINT,
  SEND_BILL_SEND_EMAIL,
  SEND_BILL_GETS,
  SEND_BILL_GET_SUM,
  SEND_BILL_GET_FLOORS,
} from './sendBill.types';

import {
  SEND_BILL_SEND_EMAIL_START_PROGRESS,
  SEND_BILL_SEND_EMAIL_DONE_PROGRESS,
  SEND_BILL_PRINT_START_PROGRESS,
  SEND_BILL_PRINT_DONE_PROGRESS,
  SEND_BILL_CLEAR_DATA_TABLE,
} from './sendBill.constants';

const initStateDataPreview = {
  isFetching: false,
  data: null,
  code: null,
  codeLanguage: null,
};

const initStateSendEmail = {
  dataPage: {},
  isFetching: false,
  code: null,
};
const initStatePrint = {
  dataPage: {},
  isFetching: false,
  code: null,
};

const initStateFloors = {
  isFetching: false,
  data: [],
  code: null,
  codeLanguage: null,
};

export const initState = {
  dataTable: {
    ...initStatePagination,
  },
  sumPayment: {
    ...initStatePagination,
  },
  cancelInvoice: {
    ...initStateFetching,
  },
  sendEmail: initStateSendEmail,
  print: initStatePrint,
  dataPreview: initStateDataPreview,
  floors: initStateFloors,
};

const dataPreview = (state = initStateDataPreview, action) => {
  switch (action.type) {
    case SEND_BILL_GET_DETAIL_PREVIEW.REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case SEND_BILL_GET_DETAIL_PREVIEW.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.payload.result,
        code: action.payload.code,
        codeLanguage: action.payload.codeLanguage,
      };
    }
    case SEND_BILL_GET_DETAIL_PREVIEW.FAIL: {
      return {
        ...state,
        isFetching: false,
        code: action.payload.code,
        codeLanguage: action.payload.codeLanguage,
      };
    }
    case SEND_BILL_GET_DETAIL_PREVIEW.REFRESH: {
      return {
        ...state,
        isFetching: false,
        code: null,
        codeLanguage: null,
      };
    }
    default: {
      return state;
    }
  }
};

const sendEmail = (state = initStateSendEmail, action) => {
  switch (action.type) {
    case SEND_BILL_SEND_EMAIL_START_PROGRESS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case SEND_BILL_SEND_EMAIL.SUCCESS:
    case SEND_BILL_SEND_EMAIL.FAIL: {
      return {
        ...state,
        dataPage: {
          ...state.dataPage,
          ...action.payload.dataPage,
        },
      };
    }
    case SEND_BILL_SEND_EMAIL_DONE_PROGRESS: {
      return {
        ...state,
        code: action.payload.code,
        codeLanguage: action.payload.codeLanguage,
        isFetching: false,
      };
    }
    case SEND_BILL_SEND_EMAIL.REFRESH: {
      return {
        ...initStateSendEmail,
      };
    }
    default: {
      return state;
    }
  }
};

const print = (state = initStatePrint, action) => {
  switch (action.type) {
    case SEND_BILL_PRINT_START_PROGRESS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case SEND_BILL_PRINT.SUCCESS:
    case SEND_BILL_PRINT.FAIL: {
      return {
        ...state,
        dataPage: {
          ...state.dataPage,
          ...action.payload.dataPage,
        },
      };
    }
    case SEND_BILL_PRINT_DONE_PROGRESS: {
      return {
        ...state,
        code: action.payload.code,
        codeLanguage: action.payload.codeLanguage,
        isFetching: false,
      };
    }
    case SEND_BILL_PRINT.REFRESH: {
      return {
        ...initStatePrint,
      };
    }
    default: {
      return state;
    }
  }
};

const floors = (state = initStateFloors, action) => {
  switch (action.type) {
    case SEND_BILL_GET_FLOORS.REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case SEND_BILL_GET_FLOORS.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.payload.floors,
        code: action.payload.code,
        codeLanguage: action.payload.codeLanguage,
      };
    }
    case SEND_BILL_GET_FLOORS.FAIL: {
      return {
        ...state,
        isFetching: false,
        code: action.payload.code,
        codeLanguage: action.payload.codeLanguage,
      };
    }
    case SEND_BILL_GET_FLOORS.REFRESH: {
      return {
        ...state,
        isFetching: false,
        code: null,
        codeLanguage: null,
      };
    }
    default: {
      return state;
    }
  }
};

const handleDataTable = (reducer) => (state, action) => {
  switch (action.type) {
    case SEND_BILL_CLEAR_DATA_TABLE: {
      return { ...initStatePagination };
    }
    default: {
      return reducer(state, action);
    }
  }
};

const reducer = combineReducers({
  dataTable: handleDataTable(paginate(SEND_BILL_GETS)),
  sumPayment: paginate(SEND_BILL_GET_SUM),
  cancelInvoice: fetching(SEND_BILL_CANCEL_INVOICE),
  sendEmail,
  print,
  dataPreview,
  floors,
});

export default reducer;
