import { takeLatest, all, put, call } from 'redux-saga/effects';
import { FUND_GETS, FUND_ADD, FUND_UPDATE, FUND_DELETE } from './fund.types';
import { addAccountService, deleteAccountService, getAccountService, updateAccountService } from 'services/fund'

function* getFund(action) {
  const {
    unitGroupId,
  } = action.payload;
  const result = yield call(getAccountService, unitGroupId, FUND_GETS);
  yield put(result);
}

function* addFund(action) {
  const {
    fund,
    unitGroupId,
  } = action.payload;
  const result = yield call(addAccountService, {
    fund,
    unitGroupId,
  }, FUND_ADD);
  yield put(result);
}

function* updateFund(action) {
  const {
    fund,
    unitGroupId,
  } = action.payload;
  const result = yield call(updateAccountService, {
    fund,
    unitGroupId,
  }, FUND_UPDATE);
  yield put(result);
}

function* deleteFund(action) {
  const {
    fundId,
    unitGroupId,
  } = action.payload;

  let result = yield call(deleteAccountService, {
    fundId,
    unitGroupId,
  }, FUND_DELETE);
  result.payload.fundId = fundId;
  yield put(result);
}

export function* watch() {
  yield all([
    takeLatest(FUND_GETS.REQUEST, getFund),
    takeLatest(FUND_ADD.REQUEST, addFund),
    takeLatest(FUND_UPDATE.REQUEST, updateFund),
    takeLatest(FUND_DELETE.REQUEST, deleteFund),
  ]);
}
