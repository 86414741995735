import { language } from 'defined/Language';
import convertArrToObj from 'shared/utils/convertArrToObj';

export const FEMALE = 'FEMALE';
export const MALE = 'MALE';
export const OTHER = 'OTHER';

export const GENDERS = [
  {
    id: FEMALE,
    name: language.label.female,
  },
  {
    id: MALE,
    name: language.label.male,
  },
  {
    id: OTHER,
    name: language.label.other,
  },
];

export const GENDER_BY_ID = convertArrToObj(GENDERS, 'id');
