import { LABEL_GETS, LABEL_ADD, LABEL_UPDATE, LABEL_DELETE, LABEL_TREE_GETS } from './label.types';
import fetching, { initStateFetching } from 'shared/utils/fetching';
import paginate, { initStatePagination } from 'shared/utils/paginate';
import { combineReducers } from 'redux';

export const initState = {
  data: {
    ...initStatePagination,
  },
  labelAdd: {
    ...initStateFetching,
  },
  labelUpdate: {
    ...initStateFetching,
  },
  labelDelete: {
    ...initStateFetching,
  },
  labelTree: {
    ...initStatePagination,
  },
};

const data = paginate(LABEL_GETS);
const labelAdd = fetching(LABEL_ADD);
const labelUpdate = fetching(LABEL_UPDATE);
const labelDelete = fetching(LABEL_DELETE);
const labelTree = paginate(LABEL_TREE_GETS);

export default combineReducers({
  data,
  labelAdd,
  labelUpdate,
  labelDelete,
  labelTree,
});
