import { FUND_GETS, FUND_ADD, FUND_UPDATE, FUND_DELETE } from './fund.types';

export const getFundsAction = (unitGroupId) => ({
  type: FUND_GETS.REQUEST,
  payload: {
    unitGroupId,
  },
});

export const addFundAction = (label, unitGroupId) => ({
  type: FUND_ADD.REQUEST,
  payload: {
    label,
    unitGroupId,
  },
});

export const refreshAddFundAction = () => ({
  type: FUND_ADD.REFRESH,
});

export const updateFundAction = (label, unitGroupId) => ({
  type: FUND_UPDATE.REQUEST,
  payload: {
    label,
    unitGroupId,
  },
});

export const refreshUpdateFundAction = () => ({
  type: FUND_UPDATE.REFRESH,
});

export const deleteFundAction = (ids, unitGroupId) => ({
  type: FUND_DELETE.REQUEST,
  payload: {
    ids,
    unitGroupId,
  },
});

export const refreshDeleteFundAction = () => ({
  type: FUND_DELETE.REFRESH,
});
