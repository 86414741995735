import { combineReducers } from 'redux';
import paginate, { initStatePagination } from 'shared/utils/paginate';
import {
  AUTHORIZATION_DEPARTMENTS_GETS,
  AUTHORIZATION_GROUP_PERMISSION_GETS,
  AUTHORIZATION_DEPARTMENT_ADD,
  AUTHORIZATION_DEPARTMENT_UPDATE,
  AUTHORIZATION_DEPARTMENT_DELETE,
  AUTHORIZATION_POSITION_ADD,
  AUTHORIZATION_POSITION_UPDATE,
  AUTHORIZATION_POSITION_DELETE,
} from './authorization.types';

export const initState = {
  departments: {
    ...initStatePagination,
  },
  groupPermission: {
    ...initStatePagination,
  },
  departmentAdd: {
    ...initStatePagination,
  },
  departmentUpdate: {
    ...initStatePagination,
  },
  departmentDelete: {
    ...initStatePagination,
  },
  positionAdd: {
    ...initStatePagination,
  },
  positionUpdate: {
    ...initStatePagination,
  },
  positionDelete: {
    ...initStatePagination,
  },
};

export default combineReducers({
  departments: paginate(AUTHORIZATION_DEPARTMENTS_GETS),
  groupPermission: paginate(AUTHORIZATION_GROUP_PERMISSION_GETS),
  departmentAdd: paginate(AUTHORIZATION_DEPARTMENT_ADD),
  departmentUpdate: paginate(AUTHORIZATION_DEPARTMENT_UPDATE),
  departmentDelete: paginate(AUTHORIZATION_DEPARTMENT_DELETE),
  positionAdd: paginate(AUTHORIZATION_POSITION_ADD),
  positionUpdate: paginate(AUTHORIZATION_POSITION_UPDATE),
  positionDelete: paginate(AUTHORIZATION_POSITION_DELETE),
});
