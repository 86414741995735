import paginate, { initStatePagination } from 'shared/utils/paginate';
import { combineReducers } from 'redux';
import { SERVICE_IN_USE_GETS } from './service.types';

export const initState = {
  serviceInUse: {
    ...initStatePagination,
  },
};

const serviceInUse = paginate(SERVICE_IN_USE_GETS);

export default combineReducers({
  serviceInUse,
});
