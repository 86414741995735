import { initStateFetching } from './fetching';
import { initStatePagination } from './paginate';
import fetching from './fetching';
import { ENTERPRISE } from '../constants';
import paginate from './paginate';
import { combineReducers } from 'redux';

export const initState = {
    enterpriseAdd: {
        ...initStateFetching
    },
    enterpriseUpdate: {
        ...initStateFetching
    },
    enterpriseDelete: {
        ...initStateFetching,
    },
    data: {
        ...initStatePagination,
    }
};

const enterpriseAdd = fetching(ENTERPRISE.ADD);
const enterpriseUpdate = fetching(ENTERPRISE.UPDATE);
const enterpriseDelete = fetching(ENTERPRISE.DELETE);
const data = paginate(ENTERPRISE.GETS);

export default combineReducers({
    enterpriseAdd,
    enterpriseUpdate,
    enterpriseDelete,
    data,
});
