import {DEPARTMENT} from '../constants/actionType';
import {call, put} from 'redux-saga/effects';
import {getDepartments} from '../services';

export function* getDepartment(action) {
    const {
        buildingId
    } = action.payload;
    const result = yield call(getDepartments, buildingId, DEPARTMENT.GETS);
    yield put({
        ...result
    });
}