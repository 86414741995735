import { createSelector } from 'reselect';
import isNil from 'lodash/isNil';

const ALL = 'ALL';
const CUSTOMER_CODE = 'CUSTOMER_CODE';
const ACCOUNTING = 'ACCOUNTING';

export const labelSelector = (state) => state.label.data;

export const labelTreeSelector = (state) => state.label.labelTree;

export const labelTreeDataStatisticsSelector = (state) => state?.label?.labelTree?.data.filter((node) => {
  if (!isNil(node.color)) {
    if ([ALL, ACCOUNTING].includes(node.type)) {
      return true;
    }
    return false;
  }
  return true;
})

export const labelsCustomerCode = createSelector([labelSelector], (data) =>
  data.data.filter((label) => label.type === CUSTOMER_CODE || label.type === ALL)
);

export const labelsAccounting = createSelector([labelSelector], (data) =>
  data.data.filter((label) => label.type === ACCOUNTING || label.type === ALL)
);

export const labelTreeGroup = createSelector([labelTreeSelector], (data) => {
  const mappingTreeData = (array = []) =>
    array.map((node) => {
      const isLeaf = !isNil(node.color);
      if (isLeaf) {
        return {
          title: node.title,
          value: node.id,
          selectable: true,
          isLeaf: true,
        };
      } else {
        return {
          title: node.title,
          value: node.id,
          selectable: false,
          isLeaf: false,
          children: mappingTreeData(node.childs),
        };
      }
    });
  return mappingTreeData(data.data);
});
