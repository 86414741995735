import { call, put } from 'redux-saga/effects';
import { getAllStaffService, getDepartmentsService, getPartnerService, getStaffsByPageService } from '../../services';
import { PAGE_ASSET, X_UNIT_GROUP_ID } from '../../constants';

export function* initPageAsset(action) {
    const {
        unitGroupId,
    } = action.payload;

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    // get department
    const resDepartment = yield call(getDepartmentsService, { headers }, PAGE_ASSET.DEPARTMENT_GETS);
    yield put(resDepartment);

    // get employee
    const resEmployee = yield call(getAllStaffService, { unitGroupId }, PAGE_ASSET.EMPLOYEE_GETS);
    yield put(resEmployee);

    // getPartner
    const resPartner = yield call(getPartnerService, { unitGroupId }, PAGE_ASSET.PARTNER_GETS);
    yield put(resPartner);

    yield put({
        type: PAGE_ASSET.INIT.SUCCESS,
        payload: { code: 200 },
    });
}