import {
    STAFF_ADD,
    STAFF_DELETE_LIST, STAFF_EXPORT,
    STAFF_GET_LIST, STAFF_IMPORT,
    STAFF_UPDATE,
} from '../defined/API';
import { METHOD, X_UNIT_GROUP_ID } from '../constants';
import { callApi, callApiWithFile, downloadFile } from './api';
import { handleError, handleResponse } from '../libs/http';

export const getAllStaffService = async ({ unitGroupId }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(STAFF_GET_LIST, {}, METHOD.GET, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getStaffsByPageService = async ({ params, headers }, type: Object) => {
    const { FAIL } = type;
    try {
        const response = await callApi(STAFF_GET_LIST, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type, 'result', params?.page);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const addStaffService = async ({ staff, unitGroupId }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(STAFF_ADD, staff, METHOD.POST, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type);

    } catch (e) {
        return handleError(e, FAIL);
    }
};
export const updateStaffService = async ({ staff, unitGroupId }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(`${STAFF_UPDATE}/${staff.id}`, staff, METHOD.PUT, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const deleteListStaffService = async ({ ids, unitGroupId }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(STAFF_DELETE_LIST, { ids }, METHOD.POST, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};


export async function importStaffService(unitGroupId, file, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApiWithFile(STAFF_IMPORT, file, METHOD.POST, 'file', {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type, 'result', 1);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function exportStaffService(unitGroupId, filter, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        let response = await downloadFile(STAFF_EXPORT, {}, METHOD.GET, {
            params: {
                ...filter,
            },
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type, 'result');
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}