import moment from 'moment';
import { PAGINATION_LIMIT } from 'shared/constants';
import { isNil } from 'shared/utils';
import { getsAmenities } from './amenities.actions';
import {
  AMENITIES_TYPE_PRE_ORDER_PERIOD_TIME,
  AMENITIES_TYPE_PRE_ORDER_TIME,
  AMENITIES_FORMULA_TYPE_NUMBER_USE,
  AMENITIES_FORMULA_TYPE_ACCUMULATED,
  AMENITIES_FORMULA_STATUS_ACTIVE,
  AMENITIES_FORMULA_STATUS_INACTIVE,
  AMENITIES_REFUNDABLE_TYPE_REFUNDABLE,
} from './amenities.constants';

const TABLE_DEFAULT_PAGE_SIZE = PAGINATION_LIMIT;

export const loadDataAmenities = ({ filterObj, idBuilding, page, dispatch }) => {
  dispatch(
    getsAmenities({
      filterObj,
      page,
      limit: TABLE_DEFAULT_PAGE_SIZE,
      idBuilding,
    })
  );
};

const getFormulaType = (amenities) => {
  if (amenities.amenitiesType === AMENITIES_TYPE_PRE_ORDER_PERIOD_TIME) {
    return AMENITIES_FORMULA_TYPE_NUMBER_USE;
  }
  if (amenities.amenitiesType === AMENITIES_TYPE_PRE_ORDER_TIME) {
    return AMENITIES_FORMULA_TYPE_ACCUMULATED;
  }
  return '';
};

const getFormulas = (amenities) => {
  if (amenities.amenitiesType === AMENITIES_TYPE_PRE_ORDER_PERIOD_TIME) {
    return [
      {
        name: '',
        price: Number(amenities.amenitiesPeriodTimePrice),
      },
    ];
  }
  if (amenities.amenitiesType === AMENITIES_TYPE_PRE_ORDER_TIME) {
    const tmp = amenities.amenitiesFormulas.map((formula, index) => ({
      name: '',
      price: Number(formula.amenitiesFormulaPrice),
      from: Number(amenities?.amenitiesFormulas?.[index - 1]?.amenitiesFormula?.to ?? 0),
      to: index !== amenities.amenitiesFormulas.length - 1 ? Number(formula.amenitiesFormula.to) : null,
    }));
    return tmp;
  }
  return [];
};

export const convertAmenities = (amenities) => {
  const amenitiesCancelOrderPolicies = amenities?.amenitiesCancelOrderPolicies ?? [];
  const cancelBookingConditions = [];
  for (let index = 0; index < amenitiesCancelOrderPolicies.length; index += 1) {
    if (index === 0) {
      cancelBookingConditions.push({
        type: AMENITIES_REFUNDABLE_TYPE_REFUNDABLE,
        limitDate: 0,
        priceType: amenitiesCancelOrderPolicies[index].amenitiesCancelOrderPolicyTypeRefundableLevel,
        price: amenitiesCancelOrderPolicies[index].amenitiesCancelOrderPolicyTypeRefundableValue,
      });
    } else {
      cancelBookingConditions.push({
        type: AMENITIES_REFUNDABLE_TYPE_REFUNDABLE,
        limitDate: amenitiesCancelOrderPolicies[index - 1].amenitiesCancelOrderPolicy.to + 1,
        priceType: amenitiesCancelOrderPolicies[index].amenitiesCancelOrderPolicyTypeRefundableLevel,
        price: amenitiesCancelOrderPolicies[index].amenitiesCancelOrderPolicyTypeRefundableValue,
      });
    }
  }
  return {
    id: amenities.id,
    name: {
      vi: amenities.amenitiesName.trim(),
      en: amenities.amenitiesName.trim(),
      ja: amenities.amenitiesName.trim(),
      ko: amenities.amenitiesName.trim(),
      zh: amenities.amenitiesName.trim(),
    },
    code: amenities.amenitiesCode.trim(),
    type: amenities.amenitiesType,
    status: amenities.amenitiesStatus ? AMENITIES_FORMULA_STATUS_ACTIVE : AMENITIES_FORMULA_STATUS_INACTIVE,
    location: amenities.amenitiesLocation.trim(),
    description: amenities.amenitiesDescription.trim(),
    priorityInApp: amenities.amenitiesOrderInResidentApp,
    photos: amenities.amenitiesDescriptionImages,
    coverPhoto: amenities.amenitiesAvatar,
    activeStartDate: moment(amenities.amenitiesStartDateServe).startOf('day').toDate(),
    activeEndDate: amenities.amenitiesEndDateServe ? moment(amenities.amenitiesEndDateServe).endOf('day').toDate() : null,
    paymentMethod: amenities.amenitiesPaymentMethod,
    payBeforeTime: amenities.amenitiesPaymentExpiredNumberTime,
    payBeforeTimeType: amenities.amenitiesPaymentExpiredUnitTime,
    periodTimeServes: amenities.amenitiesTimeServes.map((amenitiesTimeServe) => {
      const periodStartTimeServe = moment(amenitiesTimeServe.amenitiesPeriodTimeServe[0]);
      const periodEndTimeServe = moment(amenitiesTimeServe.amenitiesPeriodTimeServe[1]);
      return {
        startTime: periodStartTimeServe.hour() * 100 + periodStartTimeServe.minute(),
        endTime: periodEndTimeServe.hour() * 100 + periodEndTimeServe.minute(),
        dayInWeek: amenitiesTimeServe.amenitiesDayServe,
        formulaType: getFormulaType(amenities),
        formulas: getFormulas(amenities),
      };
    }),
    limitOrderPerTime: amenities?.amenitiesLimitServe ?? null,
    preOrderMinTime: isNil({ value: amenities?.amenitiesPreOrder?.from ?? null, isCheckStringNull: true })
      ? null
      : amenities.amenitiesPreOrder.from,
    preOrderMaxTime: isNil({ value: amenities?.amenitiesPreOrder?.to ?? null, isCheckStringNull: true })
      ? null
      : amenities.amenitiesPreOrder.to,
    limitOrderPerUsage: amenities?.amenitiesPreOrderLimit?.time ?? null,
    limitOrderPerUsageType: amenities?.amenitiesPreOrderLimit?.unit ?? null,
    deposits: isNil({ value: amenities?.amenitiesDepositAmount, isCheckStringNull: true }) ? null : amenities?.amenitiesDepositAmount,
    surcharges: (amenities?.amenitiesSurcharges ?? []).map((surcharge) => ({
      name: surcharge.amenitiesSurchargeName.trim(),
      price: Number(surcharge.amenitiesSurchargePrice),
    })),
    cancelBookingConditions,
    unitGroupId: amenities.idBuilding,
  };
};
