//@flow
import * as React from 'react';
import ReactDOM from 'react-dom';
import { map } from 'lodash';
import Icon from '../Icon';

const alertRoot = document.getElementById('alert-root') || undefined;
const element = document.createElement('div');
let id = 0;

type Props = {};
type State = {
    position: string,
    alerts: Array<{
        id: number,
        title: string,
        message: string
    }>
};

export default class Alert extends React.Component<Props, State> {

    constructor(args: any) {
        super(args);
        (this: any).onClickBtnCancel = this.onClickBtnCancel.bind(this);
        (this: any).showAlert = this.showAlert.bind(this);
    }

    state = {
        alerts: [],
        position: ''
    };

    componentDidMount() {
        alertRoot && alertRoot.appendChild(element);
    }

    componentWillUnmount() {
        alertRoot && alertRoot.removeChild(element);
    }

    showAlert(title: string = '', message: string = '', position: string = 'top-left', type: string = 'info') {
        id = id + 1;
        const idTemp = id;
        this.setState((prevState, props) => ({
            position: position,
            alerts: [
                ...prevState.alerts,
                {
                    id: idTemp,
                    title: title,
                    message: message,
                    type: type
                }
            ]
        }), () => {
            setTimeout(() => {
                let alerts = [];
                for (let i = 0; i < this.state.alerts.length; i++) {
                    if (this.state.alerts[i].id !== idTemp) {
                        alerts.push(this.state.alerts[i]);
                    }
                }
                this.setState({
                    alerts: alerts
                });
            }, 5000);
        });

    }

    onClickBtnCancel = (id: number) => (e: any) => {
        let alerts = [];
        for (let i = 0; i < this.state.alerts.length; i++) {
            if (this.state.alerts[i].id !== id) {
                alerts.push(this.state.alerts[i]);
            }
        }
        this.setState({
            alerts: alerts
        });
    };

    render() {
        return ReactDOM.createPortal(<div
            className={`alert alert--${(this.state.position && this.state.position) || ''}`}>
            {
                map(this.state.alerts, alert => <div key={alert.id}
                    className={`alert__dialog alert--${(alert.type && alert.type) || ''}`}>
                    <svg onClick={this.onClickBtnCancel(alert.id)} width="10px" height="10px" viewBox="0 0 12 12"
                        version="1.1"
                        className="position--absolute pointer" style={{ top: 10, right: 10 }}>
                        <g id="Icon/Clear-Copy-3" stroke="none" strokeWidth="1" fill="none"
                            fillRule="evenodd">
                            <polygon id="Mask" fill="white"
                                points="11.86656 1.22688 7.18944 5.904 11.86656 10.58112 10.67712 11.77056 6 7.09344 1.32288 11.77056 0.13344 10.58112 4.81056 5.904 0.13344 1.22688 1.32288 0.03744 6 4.71456 10.67712 0.03744" />
                        </g>
                    </svg>
                    <div className="alert__dialog__icon">
                        {
                            alert.type === 'info' && <Icon name="info-circle" width='25px' height='25px' />
                        }
                        {
                            alert.type === 'success' && <Icon name="check-circle" width='25px' height='25px' />
                        }
                        {
                            alert.type === 'warning' && <Icon name="exclamation-circle" width='25px' height='25px' />
                        }
                        {
                            alert.type === 'danger' && <Icon name="exclamation-triangle" width='25px' height='25px' />
                        }
                    </div>
                    <div className="alert__dialog__content">
                        <h3>{alert.title}</h3>
                        <h6>{alert.message}</h6>
                    </div>
                </div>)
            }
        </div>, element);
    }
}