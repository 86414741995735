import {
  isArray,
  isEqual,
  each,
  size,
  differenceWith,
  map,
  cloneDeep,
  concat,
  isString,
  split,
  debounce,
  reduce,
  difference,
  find,
  get,
} from 'lodash';
import moment from 'moment';
import { language } from '../defined/Language';
import XLSX from 'xlsx';
import { getTotalPage } from './http';
import { PAGINATION, TIME_DEBOUNE_CALL_API } from '../constants';
import { fileTypes } from '../components/modules/Upload';
import filter from 'lodash/filter';
import { BILL_TYPE_ADJUST_UP, BILL_TYPE_ADJUST_DOWN } from 'shared/constants';

export const ucFirstLetter = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const compareObject = (obj1: Object, obj2: Object) => {
  const tmp =
    size(obj1) > size(obj2)
      ? {
          ...obj1,
        }
      : {
          ...obj2,
        };
  let result = true;
  if (!obj2 || !obj1) {
    result = false;
    return 0;
  } else {
    each(tmp, (value, key) => {
      if (obj2[key] === undefined || obj1[key] === undefined) {
        result = false;
        return 0;
      }
      if (isArray(obj1[key]) && isArray(obj2[key])) {
        if (obj1[key].length !== obj2[key].length) {
          result = false;
          return 0;
        }
        if (differenceWith(obj1[key], obj2[key], isEqual).length !== 0) {
          result = false;
          return 0;
        }
      }
      if (!isEqual(obj1[key], obj2[key])) {
        result = false;
        return 0;
      }
    });
  }
  return result;
};

export const coppyArray = (data) => {
  return map(data, cloneDeep);
};

export const threeDigist = (money) => {
  if (isNaN(money)) {
    return '';
  }
  return ((money || money === 0) && money.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')) || money;
};

export const generateDatetime = () => {
  function generateDate() {
    const date = [];
    for (let i = 1; i <= 31; i++) {
      date.push({
        label: i,
        value: i,
      });
    }
    return date;
  }

  function generateMonth() {
    const month = [];
    for (let i = 0; i < 12; i++) {
      month.push({
        label: i + 1,
        value: i,
      });
    }
    return month;
  }

  function generateYear() {
    const year = [];
    for (let i = moment().year() + 5; i >= moment().year() - 50; i--) {
      year.push({
        label: i,
        value: i,
      });
    }
    return year;
  }

  function generateQuarter() {
    const quarter = [];
    for (let i = 1; i <= 4; i++) {
      quarter.push({
        label: i,
        value: i,
      });
    }
    return quarter;
  }

  return {
    DATE: generateDate(),
    MONTH: generateMonth(),
    YEAR: generateYear(),
    QUARTER: generateQuarter(),
  };
};

export function uuid() {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

export function base64MimeType(encoded) {
  let result = null;

  if (typeof encoded !== 'string') {
    return result;
  }

  const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

  if (mime && mime.length) {
    result = mime[1];
  }

  return result;
}

export function printHtml(html) {
  const mywindow = window.open('Print', '', '', '');
  if (mywindow) {
    mywindow.document.write(html);
    mywindow.document.close();
    mywindow.onload = function() {
      mywindow.focus();
      mywindow.print();
      mywindow.close();
    };
  } else {
    alert(language['error_description']['print_error']);
  }
}

export function bytesToSize(bytes) {
  if (!bytes && bytes !== 0) {
    return '';
  }
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export function removeNullProperties(object) {
  for (let key in object) {
    if (!object[key] || object[key] === '') {
      delete object[key];
    }
  }
  return object;
}

export function changeNullProperties(object) {
  for (let key in object) {
    if (object[key] === undefined || object[key] === '') {
      object[key] = null;
    }
  }
  return object;
}

export function combineOptionsAll(options, label = 'name', value = 'id') {
  const ALL = {
    [label]: `----- ${language['label']['all']} -----`,
    [value]: '',
  };
  return concat([ALL], options);
}

export function getFileNameFromUrl(url) {
  if (!url) {
    return null;
  }
  const tmpArr = url.split('/');
  const nameWithTimeZone = tmpArr[tmpArr.length - 1];
  const [timezone, ...name] = nameWithTimeZone.split('-');
  return name.join('-');
}

export function downloadFileFromUrl(url, document, fileName) {
  if (!url) {
    throw Error('url is not null');
  }
  if (!document) {
    throw Error('document is not null');
  }
  if (!fileName) {
    throw Error('filename is not null');
  }
  const link = document.createElement('a');
  link.setAttribute('target', '_blank');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function readFileExcel(file) {
  const reader = new FileReader();
  reader.readAsBinaryString(file);

  function getHeaderRow(sheet) {
    const headers = [];
    if (!sheet['!ref']) {
      return headers;
    }
    const range = XLSX.utils.decode_range(sheet['!ref']);
    let C,
      R = range.s.r;
    /* start in the first row */
    /* walk every column in the range */
    for (C = range.s.c; C <= range.e.c; ++C) {
      const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
      /* find the cell in the first row */

      // var hdr = 'UNKNOWN ' + C; // <-- replace with your desired default
      let hdr = ''; // <-- replace with your desired default
      if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);

      hdr && headers.push(hdr);
    }
    return headers;
  }

  return new Promise((resolve, reject) => {
    reader.onload = function(e) {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[0]]);
      const json_object = JSON.stringify(XL_row_object);
      const headers = getHeaderRow(workbook.Sheets[workbook.SheetNames[0]]);
      resolve({
        headers,
        data: JSON.parse(json_object),
      });
    };
  });
}

export function paginate(data, page, limit = PAGINATION.LIMIT) {
  const tmp = [...data];
  const skip = (page - 1) * limit;
  const totalPage = getTotalPage(data.length, limit);
  const totalRecord = data.length;
  const sliced = tmp.slice(skip, skip + limit);
  return [sliced, totalPage, totalRecord];
}

export function buildDetailBills({
  createdFor,
  serviceName,
  contractTemplateName,
  totalNumberUse,
  parentId,
  registrationPlate,
  type,
  contentBill,
}) {
  const createdForYear = parseInt(createdFor / 100);
  let createdForMonth = parseInt(createdFor % 100);
  createdForMonth = createdForMonth < 10 ? '0' + createdForMonth : createdForMonth;

  let content = '';
  let note = '';
  content = `${serviceName}${registrationPlate ? ` (${registrationPlate})` : ''} ${language['label'][
    'month'
  ].toLowerCase()} ${createdForMonth}/${createdForYear} 
     ${parentId ? `(${language['label']['debt'].toLowerCase()})` : ''}`;

  if (['INTEREST', BILL_TYPE_ADJUST_UP, BILL_TYPE_ADJUST_DOWN].includes(type)) {
    content = `${contentBill} ${parentId ? `(${language['label']['debt'].toLowerCase()})` : ''}`;
  }

  note = `${language['label']['fees']}: ${contractTemplateName} - ${totalNumberUse}`;

  return { note, content };
}

export function createBillInterestCombine(bills) {
  let result = [...bills];

  function calcTotalBill(bills) {
    return reduce(bills, (result, bill) => result + Number(bill.totalPayment), 0);
  }

  function getId(bills) {
    let arrId = map(bills, (bill) => bill.id) || [];
    return arrId.join('-');
  }

  let billsInterest = filter(result, (bill) => bill.type === 'INTEREST');
  let billsInterestTmp = filter(billsInterest, (bill) => {
    let createdFor = moment().format('YYYYMM');
    if (bill.serviceType === 'SAVE_FROM_TO') {
      createdFor = moment()
        .subtract(1, 'months')
        .format('YYYYMM');
    }
    return bill.createdFor === Number(createdFor);
  });
  let billCombineInterest = {
    content: `${language['label']['interim_interest']} T${moment().format('MM/YYYY')}`,
    bills: billsInterestTmp,
    id: getId(billsInterestTmp),
    totalPayment: calcTotalBill(billsInterestTmp),
    type: 'BILL_INTEREST_COMBINE',
    deletedAt: get(billsInterestTmp, ['0', 'deletedAt']),
    serviceType: get(billsInterestTmp, ['0', 'serviceType']),
    createdFor: get(billsInterestTmp, ['0', 'createdFor']),
  };
  let billsInterestPrev = difference(billsInterest, billsInterestTmp);
  let billCombineInterestPrev = {
    content: language['label']['late_payment_fee'],
    bills: billsInterestPrev,
    id: getId(billsInterestPrev),
    totalPayment: calcTotalBill(billsInterestPrev),
    type: 'BILL_INTEREST_COMBINE',
    deletedAt: get(billsInterestPrev, ['0', 'deletedAt']),
  };

  result = filter(result, (bill) => bill.type !== 'INTEREST');

  if (billCombineInterest.bills.length) {
    result = [...result, billCombineInterest];
  }

  if (billCombineInterestPrev.bills.length) {
    result = [...result, billCombineInterestPrev];
  }

  return result;
}

export function checkBuildingUsingDeferredInterest(buildingId, buildings) {
  let usingDeferredInterest = false;
  let building = find(buildings, { id: Number(buildingId) });
  if (building) {
    let deferredInterest = get(building, 'deferredInterest', '');
    usingDeferredInterest = Boolean(deferredInterest);
  }
  return usingDeferredInterest;
}

export function isServiceDebt(serviceCode) {
  const pattern = /^DEBT/g;
  return pattern.test(serviceCode);
}

export function getIndexOfRow(index, page, limit = PAGINATION.LIMIT) {
  return (page - 1) * limit + index + 1;
}

export const convertFileUrlToObject = (fileUrl) => {
  if (!fileUrl || !isString(fileUrl)) {
    return {};
  }
  const arrSplitedByDot = split(fileUrl, '.');
  const fileType = arrSplitedByDot[arrSplitedByDot.length - 1];

  const arrSplitedBySlash = split(fileUrl, '/');
  const fileName = arrSplitedBySlash[arrSplitedBySlash.length - 1];

  return {
    id: Symbol('FILE_URL'),
    type: fileTypes.image[`.${fileType}`] || fileType,
    name: fileName,
    src: fileUrl,
    url: fileUrl,
    uploaded: true,
    error: false,
  };
};

export function isFloat(n) {
  let tmp = Number(n);
  return tmp % 1 !== 0;
}
export function getEmployeeId(employees, buildingId) {
  if (Array.isArray(employees) && employees.length && buildingId) {
    let employee = employees.find((employee) => employee.unitGroupId == buildingId);
    if (employee) {
      return employee.id;
    }
  }
  return '';
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function buildFunctionFilter(func, timeCall = TIME_DEBOUNE_CALL_API) {
  return debounce(func, timeCall);
}

export * from './convert.js';
