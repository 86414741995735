import { createAction } from 'shared/utils';

export const FLOOR = {
  GETS: createAction('FLOOR_GETS'),
  ADD: createAction('FLOOR_ADD'),
  UPDATE: createAction('FLOOR_UPDATE'),
  DELETE: createAction('FLOOR_DELETE'),
};

export const APARTMENT = {
  INIT: createAction('APARTMENT_INIT'),
  GETS: createAction('APARTMENT_GETS'),
  GETS_EMPTY: createAction('APARTMENT_GETS_EMPTY'),
  GETS_IN_USE: createAction('APARTMENT_GETS_IN_USE'),
  EXPORT: createAction('APARTMENT_EXPORT'),
  REFRESH: createAction('APARTMENT_REFRESH'),
  LABEL_GETS: createAction('APARTMENT_LABEL_GETS'),
};
