import {
    addContractTemplateService,
    deleteContractTemplateService,
    getContractTemplateService, getServiceInUseService,
    updateContractTemplateService,
} from '../services';
import { call, put } from 'redux-saga/effects';
import { CONTRACT_TEMPLATE } from '../constants/actionType';
import { handleError } from '../libs/http';
import { PAGINATION, X_UNIT_GROUP_ID } from '../constants';

export function* initPageContractTemplate(action) {
    const {
        buildingId,
    } = action.payload;
    try {
        const headers = {
            [X_UNIT_GROUP_ID]: buildingId,
        };
        const resServices = yield call(getServiceInUseService, { headers }, CONTRACT_TEMPLATE.SERVICE_GETS);
        yield put(resServices);

        const params = {
            page: 1,
            limit: PAGINATION.LIMIT,
        };
        const resultGetsContractTemplate = yield call(getContractTemplateService, {
            params,
            headers,
        }, CONTRACT_TEMPLATE.GETS);
        yield put(resultGetsContractTemplate);
        yield put({
            type: CONTRACT_TEMPLATE.INIT.SUCCESS,
            payload: {
                code: 200,
            },
        });
    } catch (e) {
        yield put(handleError(e, CONTRACT_TEMPLATE.INIT.FAIL));
    }

}

export function* addContractTemplate(action) {
    let contractTemplate = action.payload.contractTemplate;

    const result = yield call(addContractTemplateService, contractTemplate, CONTRACT_TEMPLATE.ADD);
    yield put(result);
    yield put({
        type: CONTRACT_TEMPLATE.ADD.REFRESH,
    });
}

export function* updateContractTemplate(action) {
    let contractTemplate = action.payload.contractTemplate;

    const result = yield call(updateContractTemplateService, contractTemplate, CONTRACT_TEMPLATE.UPDATE);
    yield put(result);
    yield put({
        type: CONTRACT_TEMPLATE.UPDATE.REFRESH,
    });
}

export function* getContractTemplate(action) {
    const {
        buildingId,
        page,
        size,
        filterObject,
    } = action.payload;

    const params = {
        ...filterObject,
        limit: size,
        page,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: buildingId,
    };

    const result = yield call(getContractTemplateService, {
        params,
        headers,
    }, CONTRACT_TEMPLATE.GETS);
    yield put(result);
}

export function* deleteContractTemplate(action) {
    const {
        contractTemplateId,
        buildingId,
    } = action.payload;
    const result = yield call(deleteContractTemplateService, contractTemplateId, buildingId, CONTRACT_TEMPLATE.DELETE);
    yield put(result);
    yield put({
        type: CONTRACT_TEMPLATE.DELETE.REFRESH,
    });
}