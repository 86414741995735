import { ENTERPRISE } from '../constants';

export const addEnterprise = (enterprise) => ({
    type: ENTERPRISE.ADD.REQUEST,
    payload: {
        enterprise,
    },
});

export const updateEnterprise = (enterprise) => ({
    type: ENTERPRISE.UPDATE.REQUEST,
    payload: {
        enterprise,
    },
});

export const deleteEnterprise = (enterpriseId) => ({
    type: ENTERPRISE.DELETE.REQUEST,
    payload: {
        enterpriseId,
    },
});

export const getsEnterprise = (buildingId) => ({
    type: ENTERPRISE.GETS.REQUEST,
    payload: {
        buildingId,
    },
});