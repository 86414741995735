import { combineReducers } from 'redux';
import paginate, { initStatePagination } from 'shared/utils/paginate';
import fetching, { initStateFetching } from 'shared/utils/fetching';
import { PAYMENT_INIT, PAYMENT_BILL_GETS, PAYMENT_BILL_SUM_PAYMENT_GETS, PAYMENT_RECEIPT_CREATE, PAYMENT_UNIT_GETS } from './payment.types';

export const initState = {
  paymentInit: {
    ...initStateFetching,
  },
  bills: {
    ...initStatePagination,
  },
  sumPayment: {
    ...initStatePagination,
  },
  receiptCreate: {
    ...initStatePagination,
  },
  units: {
    ...initStatePagination,
  },
};

export default combineReducers({
  paymentInit: fetching(PAYMENT_INIT),
  bills: paginate(PAYMENT_BILL_GETS),
  sumPayment: paginate(PAYMENT_BILL_SUM_PAYMENT_GETS),
  receiptCreate: paginate(PAYMENT_RECEIPT_CREATE),
  units: paginate(PAYMENT_UNIT_GETS),
});
