import { call, put, all, takeLatest } from 'redux-saga/effects';
import { PAGINATION_LIMIT, X_UNIT_GROUP_ID } from 'shared/constants';
import { updateLockDataService, createLockDataService, getHistoryLockDataService } from 'services/lockData';
import { getStaffsByPageService } from 'services';
import { LOCK_DATA_FORM_STAFFS_FILTER, LOCK_DATA_FORM_CREATE, LOCK_DATA_FORM_UPDATE, LOCK_DATA_FORM_HISTORY } from './lockDataForm.types';

function* createLockData(action) {
  const { unitGroupId, lockDataAt } = action.payload;

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const body = {
    lockDataAt,
  };

  const result = yield call(createLockDataService, { body, headers }, LOCK_DATA_FORM_CREATE);
  yield put(result);
}

function* updateLockData(action) {
  const { unitGroupId, lockDataAt, prevLockDataAt } = action.payload;

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const body = {
    lockDataAt,
    prevLockDataAt,
  };

  const result = yield call(updateLockDataService, { body, headers }, LOCK_DATA_FORM_UPDATE);
  yield put(result);
}

function* getHistoryLockData(action) {
  const { unitGroupId, page, filterObject } = action.payload;
  const { dateLock, dataLocker, status } = filterObject;
  let from;
  let to;
  if (dateLock) {
    [from, to] = dateLock;
    from = from.startOf('day').toISOString();
    to = to.endOf('day').toISOString();
  }

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };
  const params = {
    from,
    to,
    employeeId: dataLocker,
    status,
    limit: PAGINATION_LIMIT,
    page,
  };
  const result = yield call(getHistoryLockDataService, { params, headers }, LOCK_DATA_FORM_HISTORY);
  yield put(result);
}

function* getStaffs(action) {
  const { unitGroupId, name } = action.payload;

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };
  const params = {
    name,
    limit: PAGINATION_LIMIT,
    page: 1,
  };
  const result = yield call(getStaffsByPageService, { params, headers }, LOCK_DATA_FORM_STAFFS_FILTER);
  yield put(result);
}

export default function* watch() {
  yield all([
    takeLatest(LOCK_DATA_FORM_CREATE.REQUEST, createLockData),
    takeLatest(LOCK_DATA_FORM_UPDATE.REQUEST, updateLockData),
    takeLatest(LOCK_DATA_FORM_HISTORY.REQUEST, getHistoryLockData),
    takeLatest(LOCK_DATA_FORM_STAFFS_FILTER.REQUEST, getStaffs),
  ]);
}
