import { APARTMENT, FORM_SERVICE_REGISTER, PAGINATION, X_UNIT_GROUP_ID } from '../constants';
import { call, put } from 'redux-saga/effects';
import {
    getApartments as getApartmentsService,
    getContractTemplateTargetService,
    getResidentsService,
} from '../services';
import { RESIDENT } from '../constants/actionType';

export function* searchApartmentInFormServiceRegister(action) {
    const {
        unitGroupId,
        name,
    } = action.payload;
    const result = yield call(getApartmentsService, unitGroupId, 1, 20, {
        name
    }, FORM_SERVICE_REGISTER.SEARCH_UNIT);

    yield put(result);
}

export function* searchResidentInFormServiceRegister(action) {
    const {
        unitGroupId,
        name,
    } = action.payload;
    const result = yield call(getResidentsService, {
        params: {
            name,
            page: 1,
            limit: 20,
        },
        headers: {
            [X_UNIT_GROUP_ID]: unitGroupId,
        },
    }, FORM_SERVICE_REGISTER.SEARCH_RESIDENT);
    yield put(result);
}