import { language } from '../defined/Language';

export const TARGET_TYPE_CONTRACT_TEMPLATE_OPTIONS = [
    // {id: 'BLOCK', name: 'Block'},
    // {id: 'FLOOR', name: 'Floor'},

    { id: 'UNIT', name: language['label']['apartment'] },
    // { id: 'RESIDENT', name: language['label']['resident'] },
];

export const TARGET_TYPE_CONTRACT_TEMPLATE_BY_ID = {
    UNIT: { id: 'UNIT', name: language['label']['apartment'] },
    // RESIDENT: { id: 'RESIDENT', name: language['label']['resident'] },
};