import { combineReducers } from 'redux';
import { DEBT } from './service-by-months.types';

export const initState = {
  gets: {
    code: undefined,
    codeLanguage: undefined,
    isLoading: false,
    totalRecord: 0,
    data: [],
  },
  total: {
    isLoading: false,
    total: {},
  },
  export: {
    isLoading: false,
    data: undefined,
  },
};

const reducer = combineReducers({
  gets: (state = initState.gets, action) => {
    switch (action.type) {
      case DEBT.GETS.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case DEBT.GETS.SUCCESS:
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          totalRecord: action.payload.totalRecord,
          data: action.payload.result,
        };
      case DEBT.GETS.FAIL:
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case DEBT.GETS.REFRESH:
        return {
          ...initState.serviceDebtByMonthGets,
        };
      default:
        return state;
    }
  },
  total: (state = initState.total, action) => {
    switch (action.type) {
      case DEBT.TOTAL.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case DEBT.TOTAL.SUCCESS:
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          total: action.payload.result,
        };
      case DEBT.TOTAL.FAIL:
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case DEBT.TOTAL.REFRESH:
        return {
          ...initState.serviceDebtByMonthToTal,
        };
      default:
        return state;
    }
  },
  export: (state = initState.export, action) => {
    switch (action.type) {
      case DEBT.EXPORT.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case DEBT.EXPORT.SUCCESS:
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          data: action.payload.result,
        };
      case DEBT.EXPORT.FAIL:
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case DEBT.EXPORT.REFRESH:
        return {
          ...initState.serviceDebtByMonthExport,
        };
      default:
        return state;
    }
  },
});

export default reducer;
