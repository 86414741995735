import { language } from 'defined/Language';

export const TOTAL = 'TOTAL';

export const DEBT_MONTH = 'DEBT_MONTH';
export const CREATED_MONTH = 'CREATED_MONTH';
export const REPORT_DEBT_OPTIONS = [
  { id: 'DEBT_MONTH', name: language.label.debt_month },
  { id: 'CREATED_MONTH', name: language.label.fee_notification_month },
];
