import { createAction } from 'shared/utils';

export const STATISTIC_RECEIPT = {
  GETS: createAction('STATISTIC_RECEIPT_GETS'),
  GET_BY_ID: createAction('STATISTIC_RECEIPT_GET_BY_ID'),
  SUM_TOTAL_PAYMENT: createAction('STATISTIC_RECEIPT_SUM_TOTAL_PAYMENT'),
  ADD: createAction('STATISTIC_RECEIPT_ADD'),
  UPDATE: createAction('STATISTIC_RECEIPT_UPDATE'),
  CANCEL: createAction('STATISTIC_RECEIPT_CANCEL'),
  EXPORT: createAction('STATISTIC_RECEIPT_EXPORT'),
  GET_UNITS_IN_RECEIPT_FORM: createAction('GET_UNITS_IN_RECEIPT_FORM'),
};

export default STATISTIC_RECEIPT;
