import { call, put, takeLatest, all } from 'redux-saga/effects';
import { getDepartmentsService, addDepartmentService, updateDepartmentService, deleteDepartmentService } from 'services/department';

import { addPositionService, updatePositionService, deletePositionService, getGroupPermissionsService } from 'services/position';

import { X_UNIT_GROUP_ID } from 'shared/constants';
import { map } from 'lodash';
import {
  AUTHORIZATION_DEPARTMENTS_GETS,
  AUTHORIZATION_GROUP_PERMISSION_GETS,
  AUTHORIZATION_DEPARTMENT_ADD,
  AUTHORIZATION_DEPARTMENT_UPDATE,
  AUTHORIZATION_DEPARTMENT_DELETE,
  AUTHORIZATION_POSITION_ADD,
  AUTHORIZATION_POSITION_UPDATE,
  AUTHORIZATION_POSITION_DELETE,
} from './authorization.types';
import { splitName, getPrioritySuffix, sortPermission } from './authorization.util';

function* getDepartments(action) {
  const { unitGroupId, isIncludePermission } = action.payload;

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const params = {
    isIncludePermission,
  };

  const result = yield call(getDepartmentsService, { headers, params }, AUTHORIZATION_DEPARTMENTS_GETS);
  yield put(result);
  yield put({
    type: AUTHORIZATION_DEPARTMENTS_GETS.REFRESH,
  });
}

function* getGroupPermissison(action) {
  const result = yield call(getGroupPermissionsService, AUTHORIZATION_GROUP_PERMISSION_GETS);

  if (result.type === AUTHORIZATION_GROUP_PERMISSION_GETS.SUCCESS) {
    const groupPermissions = map(result.payload.result, (group) => {
      let permissions = map(group.permissions, (permission) => {
        const [prefix, suffix] = splitName(permission.name);
        const prioritySuffix = getPrioritySuffix(suffix);
        return {
          ...permission,
          prefix,
          suffix,
          prioritySuffix,
        };
      });
      permissions = sortPermission(permissions);
      return {
        ...group,
        permissions,
      };
    });

    result.payload.result = groupPermissions;
  }
  yield put(result);
}

function* addDepartment(action) {
  const { unitGroupId, ...rest } = action.payload;

  const params = {
    department: rest,
    unitGroupId,
  };

  const result = yield call(addDepartmentService, { ...params }, AUTHORIZATION_DEPARTMENT_ADD);
  yield put(result);
  yield put({
    type: AUTHORIZATION_DEPARTMENT_ADD.REFRESH,
  });
}

function* updateDepartment(action) {
  const { unitGroupId, ...rest } = action.payload;

  const params = {
    department: rest,
    unitGroupId,
  };

  const result = yield call(updateDepartmentService, { ...params }, AUTHORIZATION_DEPARTMENT_UPDATE);
  yield put(result);
  yield put({
    type: AUTHORIZATION_DEPARTMENT_UPDATE.REFRESH,
  });
}

function* deleteDepartment(action) {
  const { unitGroupId, departmentId } = action.payload;

  const result = yield call(deleteDepartmentService, { unitGroupId, departmentId }, AUTHORIZATION_DEPARTMENT_DELETE);
  yield put(result);
  yield put({
    type: AUTHORIZATION_DEPARTMENT_DELETE.REFRESH,
  });
}

function* addPosition(action) {
  const { unitGroupId, ...rest } = action.payload;

  const params = {
    position: rest,
    unitGroupId,
  };

  const result = yield call(addPositionService, { ...params }, AUTHORIZATION_POSITION_ADD);
  yield put(result);
  yield put({
    type: AUTHORIZATION_POSITION_ADD.REFRESH,
  });
}

function* updatePosition(action) {
  const { unitGroupId, ...rest } = action.payload;

  const params = {
    position: rest,
    unitGroupId,
  };

  const result = yield call(updatePositionService, { ...params }, AUTHORIZATION_POSITION_UPDATE);
  yield put(result);
  yield put({
    type: AUTHORIZATION_POSITION_UPDATE.REFRESH,
  });
}

function* deletePosition(action) {
  const { unitGroupId, positionId } = action.payload;

  const result = yield call(deletePositionService, { unitGroupId, positionId }, AUTHORIZATION_POSITION_DELETE);
  yield put(result);
  yield put({
    type: AUTHORIZATION_POSITION_DELETE.REFRESH,
  });
}

export function* watch() {
  yield all([
    takeLatest(AUTHORIZATION_DEPARTMENTS_GETS.REQUEST, getDepartments),
    takeLatest(AUTHORIZATION_GROUP_PERMISSION_GETS.REQUEST, getGroupPermissison),
    takeLatest(AUTHORIZATION_DEPARTMENT_ADD.REQUEST, addDepartment),
    takeLatest(AUTHORIZATION_DEPARTMENT_UPDATE.REQUEST, updateDepartment),
    takeLatest(AUTHORIZATION_DEPARTMENT_DELETE.REQUEST, deleteDepartment),

    takeLatest(AUTHORIZATION_POSITION_ADD.REQUEST, addPosition),
    takeLatest(AUTHORIZATION_POSITION_UPDATE.REQUEST, updatePosition),
    takeLatest(AUTHORIZATION_POSITION_DELETE.REQUEST, deletePosition),
  ]);
}
