import { combineReducers } from 'redux';
import paginate, { initStatePagination } from 'shared/utils/paginate';
import fetching, { initStateFetching } from 'shared/utils/fetching';
import {
  BUILDING_FORM_INIT,
  BUILDING_FORM_CITY_GETS,
  BUILDING_FORM_DISTRICT_GETS,
  BUILDING_FORM_BUILDING_TYPES_GETS,
} from './buildingForm.types';

export const initState = {
  buildingFormInit: {
    ...initStateFetching,
  },
  cities: {
    ...initStatePagination,
  },
  districts: {
    ...initStatePagination,
  },
  buildingTypes: {
    ...initStatePagination,
  },
};

const reducer = combineReducers({
  buildingFormInit: fetching(BUILDING_FORM_INIT),
  cities: paginate(BUILDING_FORM_CITY_GETS),
  districts: paginate(BUILDING_FORM_DISTRICT_GETS),
  buildingTypes: paginate(BUILDING_FORM_BUILDING_TYPES_GETS),
});

export default reducer;
