import { handleError, handleResponse } from '../libs/http';
import { callApi } from './api';
import { METHOD, X_UNIT_GROUP_ID } from '../constants/http';
import { SURCHARGE } from '../defined/API';

export const addSurchargeService = async (surcharge: Object, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(SURCHARGE, surcharge, METHOD.POST, {
            headers: {
                [X_UNIT_GROUP_ID]: surcharge.unitGroupId,
            }
        });
        return handleResponse(response, type, 'surcharge');
    } catch (e) {
        return handleError(e, FAIL);
    }
};