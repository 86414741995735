import { callApi, callApiWithFile, downloadFile } from './api';
import { handleError, handleResponse } from '../libs/http';
import { METHOD, X_UNIT_GROUP_ID } from '../constants';
import {
    ASSET,
    ASSET_DELETE,
    ASSET_GETS,
    ASSET_STATUS,
    ASSET_UPDATE,
    ASSET_IMPORT,
    ASSET_UPDATE_STATUS,
    MAINTENANCE,
    ASSET_HISTORY_GETS, 
    ASSET_EXPORT
} from '../defined/API';

export const getAssetStatus = async (type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(ASSET_STATUS, {}, METHOD.GET);
        return handleResponse(response, type, 'assetStatuses');
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getAssetService = async ({ unitGroupId, params = {} }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(ASSET_GETS, {}, METHOD.GET, {
            params,
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type, 'result', params.page, params.limit);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export async function importAssetService(unitGroupId, file, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApiWithFile(ASSET_IMPORT, file, METHOD.POST, 'file', {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type, 'result', 1);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function addAssetService({ asset, unitGroupId }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(ASSET, asset, METHOD.POST, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};


export async function updateAssetService({ id, asset, unitGroupId }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(ASSET_UPDATE(id), asset, METHOD.PUT, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export const updateStatusAssetService = async ({ id, nextMaintain, idBuilding, status, note }, type) => {
    const { FAIL } = type;
    try {
        const response = await callApi(ASSET_UPDATE_STATUS(id), { nextMaintain, status, note }, METHOD.PUT, {
            headers: {
                [X_UNIT_GROUP_ID]: idBuilding,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export const deleteAssetService = async ({ assetId, unitGroupId }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(ASSET_DELETE(assetId), {}, METHOD.DELETE, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            }
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const addMaintenanceService = async (maintenance: Object, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(MAINTENANCE, maintenance, METHOD.POST, {
            headers: {
                [X_UNIT_GROUP_ID]: maintenance.unitGroupId,
            },
        });
        return handleResponse(response, type, 'maintenance');
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getAssetHistoryService = async (buildingId, filters, type) => {
    const { FAIL } = type;
    try {
        const response = await callApi(ASSET_HISTORY_GETS, {}, METHOD.GET, {
            params: filters,
            headers: {
                [X_UNIT_GROUP_ID]: buildingId,
            },
        });
        return handleResponse(response, type, 'result', filters.page, filters.limit);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export async function exportAssetService({ headers, params }, type) {
    const { FAIL } = type;
    try {
        let response = await downloadFile(ASSET_EXPORT, {}, METHOD.GET, {
            params: params,
            headers: headers
        });
        return handleResponse(response, type, 'result');
    } catch (e) {
        return handleError(e, FAIL);
    }
}
