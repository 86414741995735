import { callApi, downloadFile } from './api';
import {
    PAYMENT_ONLINE_ORDER_GETS,
    PAYMENT_ONLINE_ORDER_TOTAL,
    PAYMENT_ONLINE_ORDER_EXPORT
} from 'defined/API';
import { METHOD, X_UNIT_GROUP_ID } from 'shared/constants';
import { handleError, handleResponse } from 'shared/utils';

export async function getPaymentOnlineOrder({ buildingId = 0, params = {}, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL'
} }) {
    const { FAIL } = type;
    try {
        const options = {
            headers: {
                [X_UNIT_GROUP_ID]: buildingId
            },
            params
        };
        const response = await callApi(PAYMENT_ONLINE_ORDER_GETS, null, METHOD.GET, options);

        return handleResponse(response, type, 'result', params.page, params.limit);
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}

// export async function getTotalPaymentOnlineOrder({ buildingId = 0, params = {}, type = {
//     SUCCESS: 'SUCCESS',
//     FAIL: 'FAIL'
// } }) {
//     const { FAIL } = type;
//     try {
//         const options = {
//             headers: {
//                 [X_UNIT_GROUP_ID]: buildingId
//             },
//             params
//         };
//         const response = await callApi(PAYMENT_ONLINE_ORDER_TOTAL, null, METHOD.GET, options);

//         return handleResponse(response, type, 'result');
//     }
//     catch (err) {
//         return handleError(err, FAIL);
//     }
// }

export async function exportPaymentOnlineOrder({ buildingId = 0, params = {}, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL'
} }) {
    const { FAIL } = type;
    try {
        const options = {
            headers: {
                [X_UNIT_GROUP_ID]: buildingId
            },
            params
        };
        const response = await downloadFile(PAYMENT_ONLINE_ORDER_EXPORT, null, METHOD.GET, options);

        return handleResponse(response, type, 'result');
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}