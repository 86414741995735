import { createSelector } from 'reselect';
import { CASH, ONLINE, TRANSFER } from 'constants/payment-account';

export const fundSelector = state => state.fund.data;

export const fundsTransferSelector = createSelector([fundSelector], (data) =>
  data.data.filter((fund) => fund.method === TRANSFER)
);

export const fundsCashSelector = createSelector([fundSelector], (data) =>
  data.data.filter((fund) => fund.method === CASH)
);

export const fundsOnlineSelector = createSelector([fundSelector], (data) =>
  data.data.filter((fund) => fund.method === ONLINE)
);
