import { language } from 'defined/Language';
import convertArrToObj from 'shared/utils/convertArrToObj';

export const BILL_TYPE = [
  { id: 'FULL_MONTH', name: language.label.bill_full_month },
  { id: 'PART_OF_MONTH', name: language.label.bill_part_of_month },
  { id: 'CUSTOM_HALF_OF_MONTH', name: language.label.bill_option },
];

export const BILL_TYPE_BY_ID = convertArrToObj(BILL_TYPE, 'id');
