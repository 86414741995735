import {
    BUILDING,
    LOGIN,
    LOGOUT,
    INTERNAL_TASK,
    APARTMENT,
    APARTMENT_DETAIL,
    RESIDENT,
    STAFF,
    REQUEST,
    CONFIG,
    COMMUNICATE,
    APPLICATION,
    COMMON,
    DEPARTMENT,
    SUPPLY,
    FLOOR,
    PROFILE,
} from '../constants/actionType';
import { filter, differenceBy, find } from 'lodash';
import { SUCCESS_CODE } from '../constants';

export const initState = {
    totalPageResident: 0,
    totalPageRequest: 0,
    isFetchingBuildingData: false,
    isFetchingApartmentTypeData: false,
    isFetchingApartmentData: false,
    isFetchingApplicationData: false,
    isFetchingFloorData: false,
    isFetchingBlockData: false,
    isFetchingPositionWithPermissionData: false,
    isFetchingPositionData: false,
    isFetchingDepartmentData: false,
    isFetchingStaffData: false,
    isFetchingRoleData: false,
    isFetchingTagData: false,
    isFetchingTemplateData: false,
    isFetchingResidentData: false,
    isFetchingResidentPositionData: false,
    isFetchingServiceSystem: false,
    isFetchingBuildingService: false,
    isFetchingAllowance: false,
    isFetchingAdditionalFee: false,
    isFetchingGroupPermissionData: false,
    isFetchingAssetStatusData: false,
    isFetchingResidentRoleData: false,
    user: {
        accessToken: '',
        id: '',
    },
    cities: [],
    districts: [],
    buildings: [],
    buildingTypes: [],
    apartments: [],
    apartmentTypes: [],
    blocks: [],
    floors: [],
    staffs: [],
    residents: [],
    assets: [],
    requests: [],
    receives: [],
    positions: [],
    posts: [],
    departments: [],
    tags: [],
    templates: [],
    announcements: [],
    comments: [],
    threads: [],
    files: [],
    residentPositions: [],
    servicesSystem: [],
    buildingServices: [],
    allowances: [],
    additionalFees: [],
    groupPermissions: [],
    constants: {
        asset: {
            status: [],
        },
    },
};

export default (state = initState, action) => {
    switch (action.type) {
    case APPLICATION.INIT.SUCCESS:
        return {
            ...state,
            apartmentTypes: action.payload.apartment.type || [],
            isFetchingApplicationData: true,
        };
    case LOGIN.SUCCESS:
        return {
            ...state,
            user: {
                ...action.payload.result.user,
                accessToken: action.payload.result.token,
            },
        };
    case LOGOUT.SUCCESS:
        return {
            ...state,
            user: undefined,
        };
    case BUILDING.GETS.SUCCESS:
        return {
            ...state,
            buildings: action.payload.result,
            buildingTypes: action.payload.buildingTypes,
            cities: action.payload.cities,
            districts: action.payload.districts,
            isFetchingBuildingData: true,
        };
    case BUILDING.ADD.SUCCESS:
        return {
            ...state,
            buildings: [
                ...state.buildings,
                action.payload.building,
            ],
        };
    case BUILDING.UPDATE.SUCCESS:
        return {
            ...state,
            buildings: [
                ...filter(state.buildings, building => building.id !== action.payload.building.id),
                action.payload.building,
            ],
        };
    case BUILDING.DELETE.SUCCESS: {
        const code = action.payload.code;
        return {
            ...state,
            buildings: SUCCESS_CODE.includes(code) ? [
                ...filter(state.buildings, building => building.id !== action.payload.id),
            ] : state.buildings,
        };
    }
    case APARTMENT_DETAIL.INIT.SUCCESS:
        return {
            ...state,
            residentPositions: action.payload.residentPositions || state.residentPositions,
            isFetchingResidentPositionData: true,
        };
    case APARTMENT_DETAIL.APARTMENT_INFO.GET.SUCCESS:
        return {
            ...state,
            apartments: [
                ...state.apartments,
                action.payload.apartment,
            ],
        };
    case SUPPLY.INIT.SUCCESS:
        return {
            ...state,
            departments: action.payload.departments || state.departments,
            isFetchingDepartmentData: true,
        };
    case RESIDENT.INIT.SUCCESS:
        return {
            ...state,
            constants: {
                ...state.constants,
                resident: {
                    ...state.constants.resident,
                    roles: action.payload.residentRoles || [],
                },
            },
            isFetchingResidentRoleData: true,
        };
    // case REQUEST.GETS.SUCCESS:
    //     return {
    //         ...state,
    //         requests: action.payload.requests,
    //     };
    // case REQUEST.ADD.SUCCESS:
    //     return {
    //         ...state,
    //         requests: [
    //             ...state.requests,
    //             action.payload.request,
    //         ],
    //     };
    case CONFIG.APARTMENT.INIT.SUCCESS:
        return {
            ...state,
            blocks: action.payload.blocks || state.blocks,
            floors: action.payload.floors || state.floors,
            apartments: action.payload.apartments || state.apartments,
            apartmentTypes: action.payload.apartmentTypes || state.apartmentTypes,
            isFetchingApartmentData: true,
            isFetchingFloorData: true,
            isFetchingApartmentTypeData: true,
        };
    case FLOOR.GETS.SUCCESS:
    case CONFIG.FLOOR.GETS.SUCCESS:
        return {
            ...state,
            floors: action.payload.floors || state.floors,
            isFetchingFloorData: true,
        };
    case FLOOR.DELETE.SUCCESS:
        return {
            ...state,
            floors: [
                ...differenceBy(state.floors, action.payload.result.floors, 'id'),
            ],
        };
        // case CONFIG.APARTMENT.ADD.SUCCESS:
        //     return {
        //         ...state,
        //         apartments: [
        //             ...state.apartments,
        //             action.payload.apartment
        //         ]
        //     };
        // case CONFIG.APARTMENT.IMPORT.SUCCESS:
        //     const newBlocks = [...state.blocks];
        //     const newFloors = [...state.floors];
        //     const newApartment = [...state.apartments];
        //     for (let i = 0; i < action.payload.apartments.length; i++) {
        //         const apartment = action.payload.apartments[i];
        //         if (!apartment.id) {
        //             continue;
        //         }
        //         if (!find(newBlocks, {id: apartment.blockId})) {
        //             newBlocks.push({
        //                 id: apartment.blockId,
        //                 name: apartment.blockName,
        //             });
        //         }
        //         if (!find(newFloors, {id: apartment.floorId})) {
        //             newFloors.push({
        //                 id: apartment.floorId,
        //                 name: apartment.floorName,
        //                 blockId: apartment.blockId,
        //             });
        //         }
        //         const apartmentType = find(state.apartmentTypes, {name: apartment.type});
        //         if (apartmentType) {
        //             newApartment.push({
        //                 ...apartment,
        //                 apartmentType: apartmentType
        //             });
        //         }
        //     }
        //     return {
        //         ...state,
        //         apartments: [
        //             ...newApartment,
        //         ],
        //         blocks: [
        //             ...newBlocks,
        //         ],
        //         floors: [
        //             ...newFloors,
        //         ]
        //     };
        // case CONFIG.APARTMENT.UPDATE.SUCCESS:
        //     return {
        //         ...state,
        //         apartments: [
        //             ...filter(state.apartments, apartment => apartment.id !== action.payload.apartment.id),
        //             action.payload.apartment
        //         ]
        //     };
        // case CONFIG.APARTMENT.DELETE.SUCCESS:
        //     return {
        //         ...state,
        //         apartments: [
        //             ...filter(state.apartments, apartment => apartment.id !== action.payload.apartment.id)
        //         ]
        //     };
    case CONFIG.POSITION.INIT.SUCCESS:
        return {
            ...state,
            departments: action.payload.departments || state.departments,
            groupPermissions: action.payload.groupPermissions || state.groupPermissions,
            isFetchingDepartmentData: true,
            isFetchingGroupPermissionData: true,
        };
    case CONFIG.DEPARTMENT.INIT.SUCCESS:
        return {
            ...state,
            departments: action.payload.departments || state.departments,
            isFetchingDepartmentData: true,
        };
    case CONFIG.DEPARTMENT.ADD.SUCCESS:
        return {
            ...state,
            departments: [
                ...state.departments,
                action.payload.department,
            ],
        };
    case CONFIG.DEPARTMENT.UPDATE.SUCCESS:
        return {
            ...state,
            departments: [
                ...filter(state.departments, department => department.id !== action.payload.department.id),
                action.payload.department,
            ],
        };
    case CONFIG.DEPARTMENT.DELETE.SUCCESS:
        return {
            ...state,
            departments: [
                ...filter(state.departments, department => department.id !== action.payload.departmentId),
            ],
        };
    case DEPARTMENT.GETS.SUCCESS:
        return {
            ...state,
            departments: action.payload.departments || state.departments,
            isFetchingDepartmentData: true,
        };
    case CONFIG.TAG.INIT.SUCCESS:
        return {
            ...state,
            tags: [
                ...action.payload.tags || state.tags,
            ],
            isFetchingTagData: true,
        };
    case CONFIG.TAG.ADD.SUCCESS:
        return {
            ...state,
            tags: [
                ...state.tags,
                action.payload.tag,
            ],
        };
    case CONFIG.TAG.UPDATE.SUCCESS:
        return {
            ...state,
            tags: [
                ...filter(state.tags, tag => tag.id !== action.payload.tag.id),
                action.payload.tag,
            ],
        };
    case CONFIG.TAG.DELETE.SUCCESS:
        return {
            ...state,
            tags: [
                ...filter(state.tags, tag => tag.id !== action.payload.tag.id),
            ],
        };
    case CONFIG.BLOCK.INIT.SUCCESS:
        return {
            ...state,
            blocks: [
                ...action.payload.blocks || state.blocks,
            ],
            buildingServices: [
                ...action.payload.buildingServices || state.buildingServices,
            ],
            allowances: [
                ...action.payload.allowances || state.allowances,
            ],
            servicesSystem: [
                ...action.payload.servicesSystem || state.allowances,
            ],
            isFetchingBlockData: true,
            isFetchingBuildingService: true,
            isFetchingAllowance: true,
            isFetchingServiceSystem: true,
        };
    case CONFIG.BLOCK.ADD.SUCCESS:
        return {
            ...state,
            blocks: [
                ...state.blocks,
                action.payload.block,
            ],
        };
    case CONFIG.BLOCK.UPDATE.SUCCESS:
        return {
            ...state,
            blocks: [
                ...filter(state.blocks, block => block.id !== action.payload.block.id),
                action.payload.block,
            ],
        };
    case CONFIG.BLOCK.DELETE.SUCCESS:
        return {
            ...state,
            blocks: [
                ...filter(state.blocks, block => block.id !== action.payload.block.id),
            ],
        };
    case CONFIG.SERVICE.BUILDING_USE_SERVICE.SUCCESS:
        return {
            ...state,
            buildingServices: [
                ...state.buildingServices,
                action.payload.service,
            ],
        };
    case CONFIG.SERVICE.BUILDING_UPDATE_SERVICE.SUCCESS:
        return {
            ...state,
            buildingServices: [
                ...filter(state.buildingServices, buildingService => buildingService.serviceId !== action.payload.service.serviceId),
                action.payload.service,
            ],
        };
    case CONFIG.SERVICE.ALLOWANCE.ADD.SUCCESS:
        return {
            ...state,
            allowances: [
                ...state.allowances,
                action.payload.allowance,
            ],
        };

    case CONFIG.SERVICE.ALLOWANCE.DELETE.SUCCESS:
        return {
            ...state,
            allowances: [
                ...filter(state.allowances, allowance => allowance.id !== action.payload.allowance.id),
            ],
        };
    case CONFIG.SERVICE.ADDITIONAL_FEE.ADD.SUCCESS:
        return {
            ...state,
            additionalFees: [
                ...state.additionalFees,
                action.payload.additionalFee,
            ],
        };
    case CONFIG.SERVICE.ADDITIONAL_FEE.UPDATE.SUCCESS:
        return {
            ...state,
            allowances: [
                ...filter(state.allowances, allowance => allowance.id !== action.payload.allowance.id),
                action.payload.allowance,
            ],
        };
    case CONFIG.SERVICE.ADDITIONAL_FEE.DELETE.SUCCESS:
        return {
            ...state,
            additionalFees: [
                ...filter(state.additionalFees, additionalFee => additionalFee.id !== action.payload.additionalFee.id),
            ],
        };
    case STAFF.INIT.SUCCESS:
        return {
            ...state,
            staffs: action.payload.staffs ? action.payload.staffs : state.staffs,
            departments: action.payload.departments ? action.payload.departments : state.departments,
            positions: action.payload.positions ? action.payload.positions : state.positions,
            isFetchingStaffData: true,
            isFetchingDepartmentData: true,
            isFetchingPositionData: true,
        };
    case COMMUNICATE.TEMPLATE.INIT.SUCCESS:
        return {
            ...state,
            templates: action.payload.templates ? action.payload.templates : state.templates,
            tags: action.payload.tags ? action.payload.tags : state.tags,
            departments: action.payload.departments ? action.payload.departments : state.departments,
            apartments: action.payload.apartments ? action.payload.apartments : state.apartments,
            isFetchingTemplateData: true,
            isFetchingTagData: true,
            isFetchingDepartmentData: true,
            isFetchingApartmentData: true,
        };
    case COMMUNICATE.TEMPLATE.ADD.SUCCESS:
        return {
            ...state,
            templates: [
                ...state.templates,
                action.payload.template,
            ],
        };
    case COMMUNICATE.TEMPLATE.UPDATE.SUCCESS:
        return {
            ...state,
            templates: [
                ...filter(state.templates, template => template.id !== action.payload.template.id),
                action.payload.template,
            ],
        };
    case COMMUNICATE.TEMPLATE.DELETE.SUCCESS:
        return {
            ...state,
            templates: [
                ...filter(state.templates, template => template.id !== action.payload.template.id),
            ],
        };
    case COMMUNICATE.ANNOUNCEMENT.INIT.SUCCESS:
        return {
            ...state,
        };
    case COMMUNICATE.ANNOUNCEMENT.GETS.SUCCESS:
        return {
            ...state,
            announcements: [...action.payload.announcements],

        };
    case COMMUNICATE.ANNOUNCEMENT.ADD.SUCCESS:
        return {
            ...state,
            announcements: [
                ...state.announcements,
                action.payload.announcement,
            ],
        };
    case COMMUNICATE.ANNOUNCEMENT.DELETE.SUCCESS:
        return {
            ...state,
            announcements: [
                ...filter(state.announcements, announcement => announcement.id !== action.announcementId),
            ],
        };
    case COMMUNICATE.THREAD.GETS.SUCCESS:
        return {
            ...state,
            threads: [
                ...differenceBy(action.payload.threads, state.threads, 'id'),
                ...state.threads,
            ],
        };
    case COMMUNICATE.RESIDENT_MESSAGES_PAGE.INIT.SUCCESS:
        return {
            ...state,
            departments: action.payload.departments || state.apartments,
            isFetchingDepartmentData: true,
        };
    case COMMON.UPLOAD_FILE.SUCCESS:
        return {
            ...state,
            files: [
                ...state.files,
                action.payload.file,
            ],
        };
    case COMMON.GET_FILE_BY_ID.SUCCESS:
        return {
            ...state,
            files: [
                ...state.files,
                action.payload.file,
            ],
        };
    case PROFILE.UPDATE.SUCCESS:
        return{
            ...state,
            user:{
                ...state.user,
                ...action.payload.result,
            },
        };
    default:
        return state;
    }
};
