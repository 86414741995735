import { useDispatch, useSelector } from 'react-redux';
import { useNotificationResponse, downloadFileFromUrl } from 'shared/helpers';
import {
  getStaffsAction,
  addStaffAction,
  updateStaffAction,
  deleteStaffsAction,
  importStaffAction,
  exportStaffAction,
  getDepartmentsAction,
  resetDataImportStaffAction,
} from './staff.actions';
import {
  dataTableSelector,
  staffAddSelector,
  staffUpdateSelector,
  staffDeleteSelector,
  staffImportSelector,
  staffExportSelector,
  departmentsSelector,
  emailCurrentUserSelector,
} from './staff.selectors';

export const useStaff = () => {
  const dispatch = useDispatch();

  const getDataTable = (unitGroupId, filterObject, page) => dispatch(getStaffsAction(unitGroupId, filterObject, page));
  const addStaff = (unitGroupId, staff) => dispatch(addStaffAction(unitGroupId, staff));
  const updateStaff = (unitGroupId, staff) => dispatch(updateStaffAction(unitGroupId, staff));
  const deleteStaff = (unitGroupId, ids, isAll) => dispatch(deleteStaffsAction(unitGroupId, ids, isAll));
  const importStaff = (unitGroupId, file) => dispatch(importStaffAction(unitGroupId, file));
  const exportStaff = (unitGroupId, filterObject) => dispatch(exportStaffAction(unitGroupId, filterObject));
  const getDepartments = (unitGroupId) => dispatch(getDepartmentsAction(unitGroupId, true));
  const resetDataImportStaff = () => dispatch(resetDataImportStaffAction());

  const dataTable = useSelector(dataTableSelector) || {};
  const staffAdd = useSelector(staffAddSelector) || {};
  const staffUpdate = useSelector(staffUpdateSelector) || {};
  const staffDelete = useSelector(staffDeleteSelector) || {};
  const staffImport = useSelector(staffImportSelector) || {};
  const staffExport = useSelector(staffExportSelector) || {};
  const departments = useSelector(departmentsSelector) || {};
  const emailCurrentUser = useSelector(emailCurrentUserSelector) || '';

  return {
    // Var
    dataTable,
    staffAdd,
    staffUpdate,
    staffDelete,
    staffImport,
    staffExport,
    departments,
    emailCurrentUser,
    // Func
    getDataTable,
    addStaff,
    updateStaff,
    deleteStaff,
    importStaff,
    exportStaff,
    getDepartments,
    resetDataImportStaff,
  };
};

export const useExportStaff = () => {
  const FILENAME = 'thong-tin-nhan-vien.xlsx';
  const { exportStaff, staffExport } = useStaff();

  const { data, code, codeLanguage, isFetching } = staffExport;

  useNotificationResponse(code, codeLanguage, null, () => downloadFileFromUrl(data, document, FILENAME));

  return {
    isFetching,
    exportStaff,
  };
};

export function trimProperpty(params) {
  const result = { ...params };
  Object.keys(result).forEach((key) => {
    result[key] = typeof result[key] === 'string' ? result[key].trim() : result[key];
  });
  return result;
}
