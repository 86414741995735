import {
  countBadgesService,
  countBadgesRequestService,
  countBadgesUtilityBookingService,
} from '../services/messageNotice';
import { MESSAGE_NOTICE } from '../constants';
import { call, put } from 'redux-saga/effects';

export function* countBages(action) {
  const result = yield call(countBadgesService, MESSAGE_NOTICE.COUNT_BADGES);
  yield put(result);
}

export function* countBagesRequest(action) {
  const result = yield call(countBadgesRequestService, MESSAGE_NOTICE.COUNT_BADGES_REQUEST);
  yield put(result);
}

export function* countBagesUtilityBooking(action) {
  const result = yield call(countBadgesUtilityBookingService, MESSAGE_NOTICE.COUNT_BADGES_UTILITY_BOOKING);
  yield put(result);
}