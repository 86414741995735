import { combineReducers } from 'redux';
import { AMENITIES_ORDER } from './amenities.types';

export const initState = {
  gets: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
    totalRecord: 0,
    data: [],
  },
  export: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
    data: undefined,
  },
};

const reducer = combineReducers({
  gets: (state = initState.gets, action) => {
    switch (action.type) {
      case AMENITIES_ORDER.GETS.REQUEST: {
        return {
          isLoading: true,
          code: undefined,
          codeLanguage: undefined,
          totalRecord: 0,
          data: [],
        };
      }
      case AMENITIES_ORDER.GETS.SUCCESS: {
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          totalRecord: action.payload.totalRecord,
          data: action.payload.result,
        };
      }
      case AMENITIES_ORDER.GETS.FAIL: {
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          totalRecord: 0,
          data: [],
        };
      }
      case AMENITIES_ORDER.GETS.REFRESH: {
        return {
          ...initState.gets,
        };
      }
      default:
        return state;
    }
  },
  export: (state = initState.export, action) => {
    switch (action.type) {
      case AMENITIES_ORDER.EXPORT.REQUEST: {
        return {
          isLoading: true,
          code: undefined,
          codeLanguage: undefined,
          data: undefined,
        };
      }
      case AMENITIES_ORDER.EXPORT.SUCCESS: {
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: action.payload.result,
        };
      }
      case AMENITIES_ORDER.EXPORT.FAIL: {
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: undefined,
        };
      }
      case AMENITIES_ORDER.EXPORT.REFRESH: {
        return {
          ...initState.export,
        };
      }
      default:
        return state;
    }
  },
});

export default reducer;
