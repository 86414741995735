import { call, put, all, takeLatest } from 'redux-saga/effects';
import {
  exportReportStoredService,
  getReportStoredService,
  getReportStoredDetailService,
  getReportStoredTotalService,
  saveDataReportStoredService,
  getReportStoredServiceByIdService,
} from 'services/report';
import { PAGINATION_LIMIT, X_UNIT_GROUP_ID } from 'shared/constants';
import {
  REPORT_STORED_GET_BY_ID,
  REPORT_STORED_GET,
  REPORT_STORED_GET_DETAIL,
  REPORT_STORED_GET_DETAIL_TOTAL,
  REPORT_STORED_EXPORT,
  REPORT_STORED_SAVE,
} from './reportStored.types';

function* getDataReportStored(action) {
  const { unitGroupId, filterObject, page } = action.payload;

  const params = {
    ...filterObject,
    page,
    limit: PAGINATION_LIMIT,
  };

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(getReportStoredService, { params, headers }, REPORT_STORED_GET);
  yield put(result);
}

function* getDataReportStoredById(action) {
  const { unitGroupId, reportId } = action.payload;

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(getReportStoredServiceByIdService, { reportId, headers }, REPORT_STORED_GET_BY_ID);
  yield put(result);
}

function* getDataReportStoredDetail(action) {
  const { filterObject, unitGroupId, reportId, page } = action.payload;

  const params = {
    ...filterObject,
    page,
    limit: PAGINATION_LIMIT,
  };

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(getReportStoredDetailService, { reportId, params, headers }, REPORT_STORED_GET_DETAIL);
  yield put(result);
}

function* getDataReportStoredDetailTotal(action) {
  const { filterObject, unitGroupId, reportId } = action.payload;

  const params = {
    ...filterObject,
  };

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(getReportStoredTotalService, { reportId, params, headers }, REPORT_STORED_GET_DETAIL_TOTAL);
  yield put(result);
}

function* exportDataReportStored(action) {
  const { filterObject, unitGroupId, reportId, reportName } = action.payload;

  const params = {
    ...filterObject,
  };

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(exportReportStoredService, { reportId, params, headers }, REPORT_STORED_EXPORT);
  result.payload.reportId = reportId;
  result.payload.reportName = reportName;
  yield put(result);

  yield put({
    type: REPORT_STORED_EXPORT.REFRESH,
    payload: {
      reportId,
    },
  });
}

function* saveDataReportStored(action) {
  const { unitGroupId, name, type, data } = action.payload;

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const body = {
    ...data,
    name,
    type,
  };

  const result = yield call(saveDataReportStoredService, { headers, body }, REPORT_STORED_SAVE);
  yield put(result);
}

export default function* watch() {
  yield all([
    takeLatest(REPORT_STORED_GET.REQUEST, getDataReportStored),
    takeLatest(REPORT_STORED_GET_BY_ID.REQUEST, getDataReportStoredById),
    takeLatest(REPORT_STORED_GET_DETAIL.REQUEST, getDataReportStoredDetail),
    takeLatest(REPORT_STORED_GET_DETAIL_TOTAL.REQUEST, getDataReportStoredDetailTotal),
    takeLatest(REPORT_STORED_EXPORT.REQUEST, exportDataReportStored),
    takeLatest(REPORT_STORED_SAVE.REQUEST, saveDataReportStored),
  ]);
}
