import { call, put } from 'redux-saga/effects';
import { isObject } from 'lodash';
import { APARTMENT_DETAIL } from '../constants/actionType';
import {
    addDocumentService,
    addResidentService,
    createReceiptService,
    deleteDocumentService,
    deleteResidentService,
    getApartmentByIdService,
    getBillUnpaidByUnitIdService,
    getDocumentsInBuildingService,
    getResidentsService,
    getWalletApartmentService,
    updateDocumentService,
    updateResidentService,
    getQRCodeApartmentService,
    refreshQRCodeApartmentService,
    exportQRCodeApartmentService,
    getServiceInUseService,
    getServiceHistoryService,
    getPaymentHistoryUnitService,
    getReceiptByIdService, getBillsService,
} from '../services'
import { X_UNIT_GROUP_ID } from '../constants';
import { getAccountService } from '../services/fund';
import { getLabelsService } from '../services/label';
import { getQRCodeApartmentDetail } from '../actions/apartmentDetail';
import { getPaySlipPreviewService } from '../services/paySlip';
import { PAYMENT_UNIT_GETS } from 'pages/Payment/Content/ducks/payment.types'

export function* initApartmentDetail(action) {
    const {
        unitGroupId,
        unitId,
    } = action.payload;

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const resFund = yield call(getAccountService, unitGroupId, APARTMENT_DETAIL.FUND.GETS);
    yield put(resFund);

    const resTag = yield call(getLabelsService, { headers }, APARTMENT_DETAIL.TAG.GETS);
    yield put(resTag);

    const resWallet = yield call(getWalletApartmentService, { unitId, headers }, APARTMENT_DETAIL.WALLET.GETS);
    yield put(resWallet);

    yield put({
        type: APARTMENT_DETAIL.INIT.SUCCESS,
        payload: {
            code: 200,
        },
    });
}

export function* getWalletApartmentInApartment(action) {
    const {
        unitId,
        unitGroupId,
    } = action.payload;

    const result = yield call(getWalletApartmentService, {
        unitId,
        headers: {
            [X_UNIT_GROUP_ID]: unitGroupId,
        },
    }, APARTMENT_DETAIL.WALLET.GETS);
    yield put(result);
}

export function* getApartmentById(action) {
    const {
        unitId,
        unitGroupId,
    } = action.payload;
    const result = yield call(getApartmentByIdService, { unitId, unitGroupId }, APARTMENT_DETAIL.APARTMENT_INFO.GET);
    yield put({
        ...result,
    });
}

export function* getResidentsByApartment(action) {
    const {
        unitId,
        unitGroupId,
    } = action.payload;

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const params = {
        unitId,
    };

    const result = yield call(getResidentsService, { params, headers }, APARTMENT_DETAIL.RESIDENT.GETS_BY_APARTMENT);
    yield put(result);
}

export function* addResidentToApartment(action) {
    const {
        resident,
    } = action.payload;
    const result = yield call(addResidentService, resident, APARTMENT_DETAIL.RESIDENT.ADD);
    yield put(result);
    yield put({
        type: APARTMENT_DETAIL.RESIDENT.ADD.REFRESH,
    });
}

export function* updateResidentToApartment(action) {
    const {
        resident,
    } = action.payload;
    const result = yield call(updateResidentService, resident, APARTMENT_DETAIL.RESIDENT.UPDATE);
    yield put(result);
    yield put({
        type: APARTMENT_DETAIL.RESIDENT.UPDATE.REFRESH,
    });
}

export function* deleteResidentToApartment(action) {
    const {
        residentId,
        unitGroupId,
    } = action.payload;

    const body = {
        array: [residentId],
    };

    let result = yield call(deleteResidentService, { body, unitGroupId }, APARTMENT_DETAIL.RESIDENT.DELETE);

    result.payload.residentId = residentId;

    yield put(result);

    yield put({
        type: APARTMENT_DETAIL.RESIDENT.DELETE.REFRESH,
    });
}

export function* getBillUnpaidInPageApartmentDetail(action) {
    const {
        unitGroupId,
        page,
        unitId,
    } = action.payload;

    const params = {
        page,
        unitId,
        // limit: 100,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(getBillUnpaidByUnitIdService, { headers, params }, APARTMENT_DETAIL.BILL.GETS);
    yield put(result);
}


export function* createReceiptInPageApartmentDetail(action) {
    const {
        receipt,
        unitGroupId,
    } = action.payload;

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(createReceiptService, {
        body: receipt,
        headers,
        bodyType: 'html',
    }, APARTMENT_DETAIL.RECEIPT.CREATE);

    yield put(result);
    yield put({
        type: APARTMENT_DETAIL.RECEIPT.CREATE.REFRESH,
    });
}

export function* getDocumentsByApartment(action) {
    const {
        unitId,
        unitGroupId,
    } = action.payload;
    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };
    const params = {
        unitId,
    };
    const result = yield call(getDocumentsInBuildingService, {
        params,
        headers,
    }, APARTMENT_DETAIL.DOCUMENT.GETS_BY_APARTMENT);
    yield put(result);
}

export function* addDocumentsToApartment(action) {
    const {
        document,
        unitGroupId,
    } = action.payload;

    const result = yield call(addDocumentService, {
        body: document,
        headers: {
            [X_UNIT_GROUP_ID]: unitGroupId,
        },
    }, APARTMENT_DETAIL.DOCUMENT.ADD);
    yield put(result);
    yield put({
        type: APARTMENT_DETAIL.DOCUMENT.ADD.REFRESH,
    });
}

export function* deleteDocumentToApartment(action) {
    const {
        id,
        unitGroupId,
    } = action.payload;
    let result = yield call(deleteDocumentService, {
        documentId: id,
        unitGroupId: unitGroupId,
    }, APARTMENT_DETAIL.DOCUMENT.DELETE);
    result.payload.documentId = id;
    yield put(result);
    yield put({
        type: APARTMENT_DETAIL.DOCUMENT.DELETE.REFRESH,
    });

}


export function* updateDocumentToApartment(action) {
    const {
        document,
        unitGroupId,
    } = action.payload;

    const result = yield call(updateDocumentService, {
        headers: {
            [X_UNIT_GROUP_ID]: unitGroupId,
        },
        document,
    }, APARTMENT_DETAIL.DOCUMENT.UPDATE);

    yield put({
        ...result,
    });
    yield put({
        type: APARTMENT_DETAIL.DOCUMENT.UPDATE.REFRESH,
    });
}

export function* initServicePageApartmentDetail(action) {
    const {
        unitGroupId,
    } = action.payload;

    // get list services
    const resServices = yield call(getServiceInUseService, {
        headers: {
            [X_UNIT_GROUP_ID]: unitGroupId,
        },
    }, APARTMENT_DETAIL.SERVICE.GETS);

    yield put(resServices);

    yield put({
        type: APARTMENT_DETAIL.SERVICE_HISTORY.INIT.SUCCESS,
        payload: {
            code: 200,
        },
    });
}

export function* getServiceHistoryPageApartmentDetail(action) {
    const {
        unitGroupId,
        unitId,
        serviceId,
        from,
        to,
        type,
    } = action.payload;
    const params = {
        unitId,
        serviceId,
        from,
        to,
        type,
    };
    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };
    const result = yield call(getServiceHistoryService, { params, headers }, APARTMENT_DETAIL.SERVICE_HISTORY.GETS);
    yield put(result);
}

export function* getPaymentHistoryPageApartmentDetail(action) {
    const {
        unitGroupId,
        unitId,
        from,
        to,
        code,
        payer,
        type,
        paymentMethod,
        page,
    } = action.payload;
    const params = {
        from,
        to,
        code,
        payer,
        type,
        paymentMethod,
        page,
        limit: 20,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(getPaymentHistoryUnitService, { unitId, params, headers }, APARTMENT_DETAIL.PAYMENT_HISTORY.GETS);

    yield put(result);
}

export function* getPaymentHistoryPreview(action) {
    const {
        id,
        unitGroupId,
        isReceipt,
    } = action.payload;

    let result;
    if (isReceipt) {
        result = yield call(getReceiptByIdService, {
            unitGroupId,
            receiptId: id
        }, APARTMENT_DETAIL.PAYMENT_HISTORY.PREVIEW);
    } else {
        result = yield call(getPaySlipPreviewService, unitGroupId, id, APARTMENT_DETAIL.PAYMENT_HISTORY.PREVIEW);
    }
    yield put(result);
}

export function* getQRCocdeApartmentDetail(action) {
    const {
        unitId,
        unitGroupId,
    } = action.payload;

    const result = yield call(getQRCodeApartmentService, {
        unitId,
        headers: {
            [X_UNIT_GROUP_ID]: unitGroupId,
        },
    }, APARTMENT_DETAIL.QRCODE.GET);

    yield put(result);
}

export function* refreshQRCocdeApartmentDetail(action) {
    const {
        unitIds,
        unitGroupId,
        isAll,
        filterObject,
        getAfterRefresh = false,
    } = action.payload;

    const result = yield call(refreshQRCodeApartmentService, {
        unitIds,
        isAll,
        filterObject,
        headers: {
            [X_UNIT_GROUP_ID]: unitGroupId,
        },
    }, APARTMENT_DETAIL.QRCODE.REFRESH);
    yield put(result);
    yield put({
        type: APARTMENT_DETAIL.QRCODE.REFRESH.REFRESH,
    });
    if (getAfterRefresh) {
        yield put(getQRCodeApartmentDetail({
            unitId: unitIds[0],
            unitGroupId,
        }));
    }
}

export function* exportQRCocdeApartmentDetail(action) {
    const {
        unitIds,
        unitGroupId,
        isAll,
        filterObject,
    } = action.payload;

    const result = yield call(exportQRCodeApartmentService, {
        unitIds,
        isAll,
        filterObject,
        headers: {
            [X_UNIT_GROUP_ID]: unitGroupId,
        },
    }, APARTMENT_DETAIL.QRCODE.EXPORT);

    yield put(result);
    yield put({ type: APARTMENT_DETAIL.QRCODE.EXPORT.REFRESH });
}

export function* getInfoPaymentApartmentDetail (action) {
    const {
        unitId,
        unitGroupId,
    } = action.payload;

    const params = {
        page: 1,
        limit: 20,
        unitIds: [unitId],
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(getBillsService, { params, headers }, APARTMENT_DETAIL.PAYMENT_INFO_GET);
    yield put(result);
}
