import { call, put, all } from 'redux-saga/effects';
import { REPORT, X_UNIT_GROUP_ID } from '../../constants';
import { exportDebtService, getDebtService, getDebtTotalService } from '../../services/report';

export function* getDebts(action) {
    const {
        page,
        filterObject,
        unitGroupId,
    } = action.payload;

    const params = {
        ...filterObject,
        page,
        limit: 20,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(getDebtService, { params, headers }, REPORT.DEBT.GETS);
    yield put(result);
}

export function* getTotalDebts(action) {
    const {
        filterObject,
        unitGroupId,
    } = action.payload;

    const params = {
        ...filterObject,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(getDebtTotalService, { params, headers }, REPORT.DEBT.GET_TOTAL);
    yield put(result);
}

export function* exportDebts(action) {
    const {
        filterObject,
        unitGroupId,
    } = action.payload;

    const params = {
        ...filterObject,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(exportDebtService, { params, headers }, REPORT.DEBT.EXPORT);
    yield put(result);

    yield put({
        type: REPORT.DEBT.EXPORT.REFRESH,
    });
}