import { initStatePagination } from './paginate';
import { combineReducers } from 'redux';
import paginate from './paginate';
import { PROFILE } from '../constants/actionType';
import { initStateFetching } from './fetching';
import fetching from './fetching';

export const initState = {
    profileGets: {
        ...initStatePagination,
    },
    profileUpdate: {
        ...initStateFetching,
    },
    passwordUpdate: {
        ...initStateFetching,
    }
};

export default combineReducers({
    profileGets: paginate(PROFILE.GETS),
    profileUpdate: fetching(PROFILE.UPDATE),
    passwordUpdate: fetching(PROFILE.PASSWORD_UPDATE)
});