// @flow

import { Middleware } from 'redux';

import { WEBSOCKET_CONNECT, WEBSOCKET_DISCONNECT, WEBSOCKET_SEND } from './actionTypes';
import createWebSocket from './createWebSocket';

// Middleware function.
export const webSocketMiddleware = (): Middleware => {
    // Hold a reference to the WebSocket instance in use.
    let webSocket: WebSocket;

    return store => next => (action) => {
        switch (action.type) {
        // User request to connect
        case WEBSOCKET_CONNECT:
            if (webSocket) {
                webSocket.close();
            }

            webSocket = createWebSocket(store.dispatch, action.payload.url);
            break;

            // User request to disconnect
        case WEBSOCKET_DISCONNECT:
            webSocket.close();
            break;

            // User request to send a message
        case WEBSOCKET_SEND:
            webSocket.send(JSON.stringify(action.payload));
            break;

        default:
            break;
        }

        return next(action);
    };
};

