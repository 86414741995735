import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
  addAccessManagementService,
  approveAccessManagementService,
  completeAccessManagementService,
  getAccessManagementsService,
  inProgressAccessManagementService,
  removeAccessManagementService,
  rejectAccessManagementService,
  exportAccessManagementsService,
} from 'services/accessManagement';
import { getApartments as getApartmentsService } from 'services/apartment';
import { getCountryCodes as getCountryCodesService } from 'services/base';
import {
  ACCESS_MANAGEMENT_ACTION_TYPE_ADD,
  ACCESS_MANAGEMENT_ACTION_TYPE_APARTMENT_GETS,
  ACCESS_MANAGEMENT_ACTION_TYPE_APPROVE,
  ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_IN,
  ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_OUT,
  ACCESS_MANAGEMENT_ACTION_TYPE_COUNTRY_CODE_GETS,
  ACCESS_MANAGEMENT_ACTION_TYPE_EXPORT,
  ACCESS_MANAGEMENT_ACTION_TYPE_GETS,
  ACCESS_MANAGEMENT_ACTION_TYPE_REJECT,
  ACCESS_MANAGEMENT_ACTION_TYPE_REMOVE,
} from './access-management.types';

function* getAccessManagements(action) {
  const { idBuilding, filter, page, limit } = action.payload;

  const result = yield call(getAccessManagementsService, {
    idBuilding,
    filter,
    page,
    limit,
    type: ACCESS_MANAGEMENT_ACTION_TYPE_GETS,
  });

  yield put(result);
}

function* getApartments(action) {
  const { idBuilding, filter, page, limit } = action.payload;

  const result = yield call(getApartmentsService, idBuilding, page, limit, filter, ACCESS_MANAGEMENT_ACTION_TYPE_APARTMENT_GETS);

  yield put(result);
}

function* getCountryCodes() {
  const result = yield call(getCountryCodesService, ACCESS_MANAGEMENT_ACTION_TYPE_COUNTRY_CODE_GETS);

  yield put(result);
}

function* exportAccessManagements(action) {
  const { idBuilding, filter } = action.payload;

  const result = yield call(exportAccessManagementsService, {
    idBuilding,
    filter,
    type: ACCESS_MANAGEMENT_ACTION_TYPE_EXPORT,
  });

  yield put(result);
  yield put({
    type: ACCESS_MANAGEMENT_ACTION_TYPE_EXPORT.REFRESH,
  });
}

function* addAccessManagement(action) {
  const { idBuilding, access } = action.payload;

  const result = yield call(addAccessManagementService, {
    idBuilding,
    access,
    type: ACCESS_MANAGEMENT_ACTION_TYPE_ADD,
  });

  yield put(result);
  yield put({
    type: ACCESS_MANAGEMENT_ACTION_TYPE_ADD.REFRESH,
  });
}

function* removeAccessManagement(action) {
  const { idBuilding, idAccess } = action.payload;

  const result = yield call(removeAccessManagementService, {
    idBuilding,
    idAccess,
    type: ACCESS_MANAGEMENT_ACTION_TYPE_REMOVE,
  });

  yield put(result);
  yield put({
    type: ACCESS_MANAGEMENT_ACTION_TYPE_REMOVE.REFRESH,
  });
}

function* approveAccessManagement(action) {
  const { idBuilding, idAccess } = action.payload;

  const result = yield call(approveAccessManagementService, {
    idBuilding,
    idAccess,
    type: ACCESS_MANAGEMENT_ACTION_TYPE_APPROVE,
  });

  yield put(result);
  yield put({
    type: ACCESS_MANAGEMENT_ACTION_TYPE_APPROVE.REFRESH,
  });
}

function* rejectAccessManagement(action) {
  const { idBuilding, idAccess, reason } = action.payload;

  const result = yield call(rejectAccessManagementService, {
    idBuilding,
    idAccess,
    reason,
    type: ACCESS_MANAGEMENT_ACTION_TYPE_REJECT,
  });

  yield put(result);
  yield put({
    type: ACCESS_MANAGEMENT_ACTION_TYPE_REJECT.REFRESH,
  });
}

function* checkInAccessManagement(action) {
  const { idBuilding, idAccess, images, note } = action.payload;

  const result = yield call(inProgressAccessManagementService, {
    idBuilding,
    idAccess,
    images,
    note,
    type: ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_IN,
  });

  yield put(result);
  yield put({
    type: ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_IN.REFRESH,
  });
}

function* checkOutAccessManagement(action) {
  const { idBuilding, idAccess, images, note } = action.payload;

  const result = yield call(completeAccessManagementService, {
    idBuilding,
    idAccess,
    images,
    note,
    type: ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_OUT,
  });

  yield put(result);
  yield put({
    type: ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_OUT.REFRESH,
  });
}

export default function* watch() {
  yield all([
    takeLatest(ACCESS_MANAGEMENT_ACTION_TYPE_GETS.REQUEST, getAccessManagements),
    takeLatest(ACCESS_MANAGEMENT_ACTION_TYPE_APARTMENT_GETS.REQUEST, getApartments),
    takeLatest(ACCESS_MANAGEMENT_ACTION_TYPE_COUNTRY_CODE_GETS.REQUEST, getCountryCodes),
    takeLatest(ACCESS_MANAGEMENT_ACTION_TYPE_EXPORT.REQUEST, exportAccessManagements),
    takeLatest(ACCESS_MANAGEMENT_ACTION_TYPE_ADD.REQUEST, addAccessManagement),
    takeLatest(ACCESS_MANAGEMENT_ACTION_TYPE_REMOVE.REQUEST, removeAccessManagement),
    takeLatest(ACCESS_MANAGEMENT_ACTION_TYPE_APPROVE.REQUEST, approveAccessManagement),
    takeLatest(ACCESS_MANAGEMENT_ACTION_TYPE_REJECT.REQUEST, rejectAccessManagement),
    takeLatest(ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_IN.REQUEST, checkInAccessManagement),
    takeLatest(ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_OUT.REQUEST, checkOutAccessManagement),
  ]);
}
