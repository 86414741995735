import Cookie from 'defined/Cookie';

export const checkTokenExpirationMiddleware = store => next => action => {
    const cookie = Cookie.get('cyhome');
    const token = cookie.user && cookie.user.accessToken;
    if (!token && action.type.indexOf('LOGIN') === -1) {
        next(action);
        document.location.href = '/login';
    }
    next(action);
};