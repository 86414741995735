import * as _api from './api';
import {browserHistory} from 'react-router';

export const api = _api;
export const history = browserHistory;

export * from './base';
export * from './account';
export * from './apartment';
export * from './application';
export * from './block';
export * from './building';
export * from './department';
export * from './document';
export * from './floor';
export * from './position';
export * from './resident';
export * from './staff';
export * from './tag';
export * from './template';
export * from './partner';
export * from './asset';
export * from './supply';
export * from './serviceSystem';
export * from './service';
export * from './formula';
export * from './surcharge';
export * from './contractTemplate';
export * from './invoice';
export * from './bill';
export * from './receipt';
export * from './enterprise';
export * from './contract';
export * from './paymentOnline';
export * from './card';
export * from './label';