import { PARTNER, RESIDENT } from '../../constants/actionType';

export const getPartners = (unitGroupId, filterObject) => ({
    type: PARTNER.GETS.REQUEST,
    payload: {
        unitGroupId,
        filterObject,
    }
});

export const addPartner = (partner, unitGroupId) => ({
    type: PARTNER.ADD.REQUEST,
    payload: {
        partner,
        unitGroupId,
    }
});

export const refreshAddPartner = () => ({
    type: PARTNER.ADD.REFRESH
});

export const updatePartner = (id, partner, unitGroupId) => ({
    type: PARTNER.UPDATE.REQUEST,
    payload: {
        id,
        partner,
        unitGroupId,
    }
});

export const refreshUpdatePartner = () => ({
    type: PARTNER.UPDATE.REFRESH
});

export const deletePartner = (partner: Object) => ({
    type: PARTNER.DELETE.REQUEST,
    payload: {
        partner
    }
});

export const refreshDeletePartner = () => ({
    type: PARTNER.DELETE.REFRESH
});

export const importPartner = (buildingId, file, filterObject) => ({
    type: PARTNER.IMPORT.REQUEST,
    payload: {
        buildingId,
        file,
        filterObject,
    },
});

export const resetDataImportPartner = () => ({
    type: PARTNER.IMPORT.SUCCESS,
    payload: {
        currentPage: 0,
        totalPage: 0,
        totalRecord: 0,
        result: [],
    },
});

export const exportPartners = (unitGroupId, filterObject) => ({
    type: PARTNER.EXPORT.REQUEST,
    payload: {
        unitGroupId,
        filterObject,
    }
});
