import { callApi } from 'services/api'
import {
  LOCK_DATA_CREATE,
  LOCK_DATA_HISTORY,
  LOCK_DATA_UPDATE,
} from 'defined/API'
import { METHOD } from 'constants/http'
import { handleError, handleResponse } from 'libs/http'

export const createLockDataService = async ({ body, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) => {
  const { FAIL } = type;
  try {
    const result = await callApi(LOCK_DATA_CREATE, body, METHOD.POST, {
      headers,
    });
    return handleResponse(result, type);
  } catch (e) {
    return handleError(e, FAIL);
  }
};


export const updateLockDataService = async ({ body, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) => {
  const { FAIL } = type;
  try {
    const result = await callApi(LOCK_DATA_UPDATE, body, METHOD.POST, {
      headers,
    });
    return handleResponse(result, type);
  } catch (e) {
    return handleError(e, FAIL);
  }
};


export const getHistoryLockDataService = async ({ params, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) => {
  const { FAIL } = type;
  try {
    const result = await callApi(LOCK_DATA_HISTORY, null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(result, type, 'result', true);
  } catch (e) {
    return handleError(e, FAIL);
  }
};
