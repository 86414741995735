import { combineReducers } from 'redux';
import paginate, { initStatePagination } from 'shared/utils/paginate';
import fetching, { initStateFetching } from 'shared/utils/fetching';
import { RESIDENT_CHANGE_STATUS, RESIDENT_DELETE, RESIDENT_EXPORT, RESIDENT_GETS, RESIDENT_IMPORT } from './resident.types';

export const initState = {
  data: {
    ...initStatePagination,
  },
  delete: {
    ...initStateFetching,
  },
  export: {
    ...initStatePagination,
  },
  import: {
    ...initStatePagination,
  },
  changeStatus: {
    ...initStateFetching,
  },
};

export default combineReducers({
  data: paginate(RESIDENT_GETS),
  delete: fetching(RESIDENT_DELETE),
  export: paginate(RESIDENT_EXPORT),
  import: paginate(RESIDENT_IMPORT),
  changeStatus: fetching(RESIDENT_CHANGE_STATUS),
});
