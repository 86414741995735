import { CONFIG, RESIDENT } from '../constants/actionType';
import paginate, { initStatePagination } from './paginate';
import { combineReducers } from 'redux';
import { initStateFetching } from './fetching';
import fetching from './fetching';
import { findIndex } from 'lodash';


export const initState = {
    apartmentInit: {
        ...initStateFetching,
    },
    apartmentGets: {
        ...initStateFetching,
    },
    apartmentAdd: {
        ...initStateFetching,
    },
    apartmentImport: {
        ...initStatePagination,
    },
    apartmentExport: {
        ...initStateFetching,
    },
    apartmentUpdate: {
        ...initStateFetching,
    },
    apartmentDelete: {
        ...initStateFetching,
        apartments: [],
    },
    departmentData: {
        ...initStatePagination,
    },
    departmentInit: {
        ...initStateFetching,
    },
    departmentAdd: {
        ...initStateFetching,
    },
    departmentUpdate: {
        ...initStateFetching,
    },
    departmentDelete: {
        ...initStateFetching,
    },

    labelData: {
        ...initStatePagination,
    },
    labelAdd: {
        ...initStateFetching,
    },
    labelUpdate: {
        ...initStateFetching,
    },
    labelDelete: {
        ...initStateFetching,
    },
    labelGroup: {
        ...initStatePagination,
    },
    labelTree: {
        ...initStatePagination,
    },

    templateGets: {
        ...initStatePagination,
    },
    templateSave: {
        ...initStateFetching,
    },
    templateGetByName: {
        ...initStatePagination,
    },
    accountData: {
        ...initStatePagination,
    },
    accountAdd: {
        ...initStateFetching,
    },
    accountUpdate: {
        ...initStateFetching,
    },
    accountDelete: {
        ...initStateFetching,
    },

    positionInit: {
        ...initStateFetching,
    },
    positionData: {
        ...initStatePagination,
    },
    positionAdd: {
        ...initStateFetching,
    },
    positionUpdate: {
        ...initStateFetching,
    },
    positionDelete: {
        ...initStateFetching,
    },
    serviceInit: {
        ...initStateFetching,
    },

    paginationApartment: {
        ...initStatePagination,
    },

    dataExport: '',
};

// apartment
const apartmentInit = fetching(CONFIG.APARTMENT.INIT);
const apartmentGets = fetching(CONFIG.APARTMENT.GETS);
const apartmentAdd = fetching(CONFIG.APARTMENT.ADD);
const apartmentUpdate = fetching(CONFIG.APARTMENT.UPDATE);
// const apartmentDelete = fetching(CONFIG.APARTMENT.DELETE);
const apartmentDelete = (state = initState.apartmentDelete, action) => {
    switch (action.type) {
    case CONFIG.APARTMENT.DELETE.REQUEST:
        return {
            ...state,
            isFetching: true,
            code: undefined,
            codeLanguage: undefined,
        };
    case CONFIG.APARTMENT.DELETE.SUCCESS: {
        let codeLanguage = undefined;
        if (action.payload.apartment) {
            action.payload.apartment.forEach(element => {
                if (element.code) codeLanguage = 'DELETE_APARTMENT_ERROR';
                return;
            });
        }
        return {
            ...state,
            isFetching: false,
            code: action.payload.code,
            codeLanguage: codeLanguage,
        };
    }
    case CONFIG.APARTMENT.DELETE.FAIL:
        return {
            ...state,
            isFetching: false,
            code: action.payload.code,
            codeLanguage: action.payload.codeLanguage,
        };
    case CONFIG.APARTMENT.DELETE.REFRESH:
        return {
            isFetching: false,
            code: undefined,
            codeLanguage: undefined,
            apartments: [],
        };
    default:
        return state;
    }
};

const apartmentImport = paginate(CONFIG.APARTMENT.IMPORT);
const apartmentExport = fetching(CONFIG.APARTMENT.EXPORT_APARTMENT);

function dataExport(state = '', action) {
    switch (action.type) {
    case CONFIG.APARTMENT.EXPORT_APARTMENT.SUCCESS:
        return action.payload.result;
    default:
        return state;
    }
}

// position
const positionInit = fetching(CONFIG.POSITION.INIT);
const positionData = paginate(CONFIG.POSITION.GETS);
const positionAdd = fetching(CONFIG.POSITION.ADD);
const positionUpdate = fetching(CONFIG.POSITION.UPDATE);
const positionDelete = fetching(CONFIG.POSITION.DELETE);

// service
const serviceInit = fetching(CONFIG.SERVICE.INIT);
const serviceAdd = fetching(CONFIG.SERVICE.ADD);
const serviceUpdate = fetching(CONFIG.SERVICE.UPDATE);
const serviceDelete = fetching(CONFIG.SERVICE.DELETE);

// department
const departmentData = paginate(CONFIG.DEPARTMENT.GETS);
const departmentInit = fetching(CONFIG.DEPARTMENT.INIT);
const departmentAdd = fetching(CONFIG.DEPARTMENT.ADD);
const departmentUpdate = fetching(CONFIG.DEPARTMENT.UPDATE);
const departmentDelete = fetching(CONFIG.DEPARTMENT.DELETE);

const paginationApartment = paginate(CONFIG.APARTMENT.GETS);

// label
const labelData = paginate(CONFIG.LABEL.GETS);
const handleData = reducer => (state, action) => {
    switch (action.type) {
    case CONFIG.LABEL.ADD.SUCCESS: {
        return {
            ...state,
            data: [
                ...state.data,
                action.payload.label,
            ],
        };

    }
    case CONFIG.LABEL.UPDATE.SUCCESS: {
        let indexUpdated = findIndex(state.data, { id: action.payload.label.id });
        state.data.splice(indexUpdated, 1, action.payload.label);
        return state;
    }
    case CONFIG.LABEL.DELETE.SUCCESS: {
        state.data = state.data.filter(item => item.id !== action.payload.labelId);
        return state;
    }
    default:
        return reducer(state, action);
    }
};
const labelAdd = fetching(CONFIG.LABEL.ADD);
const labelUpdate = fetching(CONFIG.LABEL.UPDATE);
const labelDelete = fetching(CONFIG.LABEL.DELETE);
const labelGroup = paginate(CONFIG.LABEL.GROUP_GETS);
const labelTree = paginate(CONFIG.LABEL.TREE_GETS);

// Template
const templateGets = paginate(CONFIG.TEMPLATE.GETS);
const templateSave = fetching(CONFIG.TEMPLATE.SAVE);
const templateGetByName = paginate(CONFIG.TEMPLATE.GET_BY_NAME);
const handleDataTemplate = (reducer) => (state, action) => {
    switch (action.type) {
    case CONFIG.TEMPLATE.SAVE.SUCCESS: {
        const { template } = action.payload;
        let templateList = [...state.data[template.type.toLowerCase()]];
        templateList = templateList.map(item => ({
            ...item,
            isSelected: item.name === template.templateName,
        }));
        return {
            ...state,
            data: {
                ...state.data,
                [template.type.toLowerCase()]: templateList
            }
        };
    }
    default: {
        return reducer(state, action);
    }
    }
};

//Account payment
const accountData = paginate(CONFIG.ACCOUNT.GETS);
const handleDataAccount = reducer => (state, action) => {
    switch (action.type) {
    case CONFIG.ACCOUNT.ADD.SUCCESS: {
        return {
            ...state,
            data: [...state.data, action.payload.account]
        };
    }
    case CONFIG.ACCOUNT.UPDATE.SUCCESS: {
        const updated = state.data.map(item => {
            if (item.id === action.payload.account.id) {
                return action.payload.account;
            }
            return item;
        });
        return {
            ...state,
            data: [...updated]
        };
    }
    case CONFIG.ACCOUNT.DELETE.SUCCESS: {
        state.data = state.data.filter(item => item.id !== action.payload.accountId);
        return state;
    }
    default:
        return reducer(state, action);
    }
};
const accountAdd = fetching(CONFIG.ACCOUNT.ADD);
const accountUpdate = fetching(CONFIG.ACCOUNT.UPDATE);
const accountDelete = fetching(CONFIG.ACCOUNT.DELETE);

export default combineReducers({
    paginationApartment,

    // apartment
    apartmentInit,
    apartmentGets,
    apartmentAdd,
    apartmentUpdate,
    apartmentDelete,
    apartmentImport,
    apartmentExport,
    dataExport,

    // position
    positionInit,
    positionData,
    positionAdd,
    positionUpdate,
    positionDelete,

    // service
    serviceInit,
    serviceAdd,
    serviceUpdate,
    serviceDelete,

    // department
    departmentData,
    departmentInit,
    departmentAdd,
    departmentUpdate,
    departmentDelete,

    // label
    labelData: handleData(labelData),
    labelAdd,
    labelUpdate,
    labelDelete,
    labelGroup,
    labelTree,

    // Template
    templateGets: handleDataTemplate(templateGets),
    templateSave,
    templateGetByName,

    // Account payment
    accountData: handleDataAccount(accountData),
    accountAdd,
    accountUpdate,
    accountDelete
});