import { combineReducers } from 'redux';
import paginate, { initStatePagination } from 'shared/utils/paginate';
import { CASHBOOK_GETS, CASHBOOK_EXPORT } from './cashbook.types';

export const initState = {
  data: {
    ...initStatePagination,
  },
  export: {
    ...initStatePagination,
  },
};

export default combineReducers({
  data: paginate(CASHBOOK_GETS),
  export: paginate(CASHBOOK_EXPORT),
});
