import {
    POSITION_ADD,
    POSITION_UPDATE,
    POSITION_DELETE,
    GROUP_PERMISSION_GETS,
    POSITION_GETS,
} from '../defined/API';
import { callApi } from './api';
import { METHOD, X_UNIT_GROUP_ID } from '../constants';
import { handleError, handleResponse } from '../libs/http';

export const getPositionsService = async (buildingId: number, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(POSITION_GETS, {}, METHOD.GET, {
            headers: {
                [X_UNIT_GROUP_ID]: buildingId,
            },
        });
        return handleResponse(response, type, 'positions', 1);
    } catch (e) {
        handleError(e, FAIL);
    }
};

export const addPositionService = async ({ position, unitGroupId }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(POSITION_ADD, position, METHOD.POST, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        handleError(e, FAIL);
    }
};
export const updatePositionService = async ({ position, unitGroupId }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(`${POSITION_UPDATE}/${position.id}`, position, METHOD.PUT, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        handleError(e, FAIL);
    }
};
export const deletePositionService = async ({ positionId, unitGroupId }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(`${POSITION_DELETE}/${positionId}`, {}, METHOD.DELETE, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        handleError(e, FAIL);
    }

};

export const getGroupPermissionsService = async (type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(GROUP_PERMISSION_GETS, {}, METHOD.GET);
        return handleResponse(response, type);
    } catch (e) {
        handleError(e, FAIL);
    }
};