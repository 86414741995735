const USER_API = '';
const COMMON_API = '';
const COMMUNICATE_API = '';
const TASK_API = '';
const SERVICE_API = '';

const SERVER = process.env.API_URL;
const SERVER_MOCK = 'http://localhost:3333';
const SERVER_CHAT = process.env.PARSE_SERVER_URL;

// user
export const USER = SERVER + '/user';
export const REGISTER = USER + '/register';
export const LOGIN = USER + '/login';
export const PASSWORD_FORGOT = USER + '/forgot-password';
export const SET_NEW_PASSWORD = USER + '/set-new-password';
export const PROFILE = USER + '/profile';
export const GET_PERMISSION = USER + '/get-permissions';
export const COUNT_BADGES = USER + '/count-badges';
export const COUNT_BADGES_REQUEST = SERVER + '/request/count-badges';
export const COUNT_BADGES_UTILITY_BOOKING = SERVER + '/amenity/booking/count-badges';

export const MAKE_CHAT_GROUP = SERVER + '/chats/makeChatGroup';
export const CREATE_CHAT_GROUP = SERVER + '/chats/createGroup';

export const LOGOUT = SERVER + '/user/logout';
export const PASSWORD_UPDATE = USER + '/change-password';

export const CITY = SERVER + '/base/cities';
export const DISTRICT = SERVER + '/base/districts';
export const COUNTRY_CODE = SERVER + '/base/country-codes';

export const UNIT_GROUP = SERVER + '/unit-group';
export const UNIT_GROUP_GET_LIST = UNIT_GROUP + '/list';
export const UNIT_GROUP_GET_BY_ID = (id) => `${UNIT_GROUP}/${id}`;
export const UNIT_GROUP_UPDATE = UNIT_GROUP;
export const UNIT_GROUP_DELETE = UNIT_GROUP;
export const UNIT_GROUP_TARGET_GETS = UNIT_GROUP + '/targets';
export const UNIT_GROUP_TYPE_GETS = SERVER + '/unit-group-type/list';
export const UNIT_GROUP_LIST_TEMPLATE = UNIT_GROUP + '/list-template';
export const UNIT_GROUP_SAVE_TEMPLATE = UNIT_GROUP + '/template';
export const UNIT_GROUP_GET_TEMPLATE = UNIT_GROUP + '/template';

export const LOCK_DATA_CREATE = UNIT_GROUP + '/lock-date';
export const LOCK_DATA_UPDATE = UNIT_GROUP + '/lock-date/update';
export const LOCK_DATA_HISTORY = UNIT_GROUP + '/lock-date/list';

export const FLOOR = SERVER + '/floor';
export const FLOOR_GET_LIST = FLOOR + '/list';
export const FLOOR_ADD = FLOOR;
export const FLOOR_UPDATE = FLOOR;
export const FLOOR_DELETE = FLOOR;
export const FLOOR_DELETE_MULTI = FLOOR + '/delete-multi';

export const UNIT = SERVER + '/unit';
export const UNIT_GET_LIST = UNIT + '/list';
export const UNIT_EXPORT = UNIT + '/export-data';
export const UNIT_ADD = UNIT;
export const UNIT_UPDATE = UNIT;
export const UNIT_DELETE = UNIT;
export const UNIT_DELETE_MULTI = UNIT + '/delete-multi';
export const UNIT_SEARCH_BY_NAME = UNIT + '/searchByName';
export const UNIT_IMPORT = UNIT + '/import-data';
export const UNIT_TYPE = SERVER + '/unit-type';
export const UNIT_WALLET_GETS = (id) => `${UNIT}/${id}/wallet`;
export const UNIT_QRCODE_GET = (id) => `${UNIT}/${id}/invite-resident`;
export const UNIT_QRCODE_EXPORT = `${UNIT}/export-unit-code`;
export const UNIT_QRCODE_REFRESH = UNIT + '/refresh-code';
export const UNIT_PAYMENT_HISTORY = (id) => `${UNIT}/${id}/payment-history`;

export const POSITION = SERVER + '/position';
export const POSITION_GETS = POSITION + '/list';
export const POSITION_ADD = POSITION;
export const POSITION_UPDATE = POSITION;
export const POSITION_DELETE = POSITION;

export const DEPARTMENT = SERVER + '/department';
export const DEPARTMENT_GET_LIST = DEPARTMENT + '/list';
export const DEPARTMENT_GET_LIST_OF_USER = (userId) => `${DEPARTMENT}/list/${userId}`;
export const DEPARTMENT_ADD = DEPARTMENT;
export const DEPARTMENT_UPDATE = DEPARTMENT;
export const DEPARTMENT_DELETE = DEPARTMENT;

export const RESIDENT = SERVER + '/resident';
export const RESIDENT_GET_LIST = RESIDENT + '/list';
export const RESIDENT_ADD = RESIDENT;
export const RESIDENT_UPDATE = RESIDENT;
export const RESIDENT_DELETE_LIST = RESIDENT + '/delete-multi';
export const RESIDENT_IMPORT = RESIDENT + '/import-data';
export const RESIDENT_EXPORT = RESIDENT + '/export-data';
export const RESIDENT_GET_ENTITY = (residentId) => `${RESIDENT}/${residentId}`;

export const RESIDENT_ROLE = SERVER + '/ResidentRoles';

export const PARTNER = SERVER + '/partner';
export const PARTNER_GETS = PARTNER + '/list';
export const PARTNER_UPDATE = (id) => PARTNER + `/${id}`;
export const PARTNER_DELETE = (id) => PARTNER + `/${id}`;
export const PARTNER_IMPORT = `${PARTNER}/import-data`;
export const PARTNER_EXPORT = `${PARTNER}/export-data`;

export const SUPPLY = SERVER + '/supply';
export const SUPPLY_GETS = SUPPLY + '/list';
export const SUPPLY_UPDATE = (id) => `${SUPPLY}/${id}`;
export const SUPPLY_DELETE = (id) => `${SUPPLY}/${id}`;
export const TRANSACTION = (id) => `${SUPPLY}/${id}/update-amount`;
export const SUPPLY_HISTORY_GETS = SUPPLY + '/history';
export const SUPPLY_EXPORT = SUPPLY + '/export-data';
export const SUPPLY_IMPORT = SUPPLY + '/import-data';
export const SUPPLY_HISTORY_EXPORT = SUPPLY_HISTORY_GETS + '/export-data';

export const ASSET = SERVER + '/asset';
export const ASSET_GETS = ASSET + '/list';
export const ASSET_UPDATE = (id) => `${ASSET}/${id}`;
export const ASSET_IMPORT = `${ASSET}/import-data`;
export const ASSET_EXPORT = `${ASSET}/export-data`;
export const ASSET_DELETE = (id) => `${ASSET}/${id}`;
export const ASSET_UPDATE_STATUS = (id) => `${ASSET}/${id}/status`;
export const ASSET_HISTORY_GETS = ASSET + '/history';

export const RESIDENT_GETS_BY_APARTMENT = USER_API + '/residents/apartment';
export const RESIDENT_GETS = USER_API + '/residents';
export const RESIDENT_AND_POSITION_IN_APARTMENT_GET = USER_API + '/position';
export const RESIDENT_GET_BY_ID = USER_API + '/resident/view';
export const RESIDENT_POSITION_GETS = USER_API + '/positions/resident';

export const DOCUMENT = SERVER + '/document';
export const DOCUMENT_GET_LIST = DOCUMENT + '/list';

export const TAG_GETS = COMMON_API + '/tags';
export const TAG_ADD = COMMON_API + '/tag';
export const TAG_UPDATE = COMMON_API + '/tag';
export const TAG_DELETE = COMMON_API + '/tag';

export const GROUP_PERMISSION = SERVER + '/group-permission';
export const GROUP_PERMISSION_GETS = GROUP_PERMISSION + '/list';

export const STAFF = SERVER + '/employee';
export const STAFF_GET_LIST = STAFF + '/list';
export const STAFF_ADD = STAFF;
export const STAFF_UPDATE = STAFF;
export const STAFF_DELETE = STAFF;
export const STAFF_DELETE_LIST = STAFF + '/deleteList';
export const STAFF_IMPORT = STAFF + '/import-data';
export const STAFF_EXPORT = STAFF + '/export-data';

export const TEMPLATE_GETS = COMMUNICATE_API + '/templates/building';
export const TEMPLATE_ADD = COMMUNICATE_API + '/templates';
export const TEMPLATE_UPDATE = COMMUNICATE_API + '/templates';
export const TEMPLATE_DELETE = COMMUNICATE_API + '/templates';

export const TASK = `${SERVER}/Tasks`;
export const TASK_STATUS = `${SERVER}/TaskStatuses`;

export const TASK_GETS = TASK_API + '/tasks/buildings';
export const TASK_ADD = TASK_API + '/tasks';
export const TASK_UPDATE = TASK_API + '/tasks';
export const TASK_DELETE = TASK_API + '/tasks';
export const TASK_CHANGE_STATUS = TASK_API + '/tasks';

export const ANNOUNCEMENT_GETS = SERVER + '/announcement/list';
export const ANNOUNCEMENT_SEND = SERVER + '/announcement';
export const ANNOUNCEMENT_DELETE = (announcementId) => SERVER + `/announcement/${announcementId}`;

export const OVERALL_TOTAL_ANNOUNCEMENT_FILTER_BY_TYPE = SERVER + '/announcement/chart-statistics';
export const OVERALL_TOTAL_ANNOUNCEMENT_FILTER_BY_DATE = SERVER + '/announcement/chart-sent-statistics';
export const OVERALL_TOTAL_ANNOUNCEMENT_FILTER_BY_LABEL = SERVER + '/announcement/chart-label-statistics';
export const OVERALL_TOTAL_ANNOUNCEMENT_FILTER_ALL = SERVER + '/announcement/statistics';

export const COMMUNICATE_UNIT_SEEN_GETS = (id) => SERVER + `/announcement/${id}/units`;
export const COMMUNICATE_UNIT_SEND_BY_EMAIL_GETS = (id) => SERVER + `/announcement/${id}/send-by-mail`;
export const COMMUNICATE_UNIT_SEND_BY_APP_GETS = (id) => SERVER + `/announcement/${id}/send-by-app`;
export const COMMUNICATE_UNIT_VOTE_GETS = (id) => SERVER + `/announcement/${id}/votes`;
export const COMMUNICATE_RESEND_EMAIL_GET = (id) => SERVER + `/announcement/${id}/resend-emails`;

export const ROOM_CHAT_GETS = SERVER_CHAT + '/rooms';
export const ROOM_CHAT_CREATE = SERVER_CHAT + '/rooms';
export const ROOM_CHAT_GETS_BY_ID = (roomId) => SERVER_CHAT + `/rooms/${roomId}`;
export const ROOM_MEMBER_GETS = (unitGroupId) => SERVER + `/unit-group/${unitGroupId}/room-members`;
export const MESSAGES_GETS = (roomId) => SERVER_CHAT + `/rooms/${roomId}/messages`;
export const MESSAGES_READ_BY_ID = (roomId, messageId) => SERVER_CHAT + `/rooms/${roomId}/messages/${messageId}/read`;
export const MESSAGES_READ_ALL = (roomId) => SERVER_CHAT + `/rooms/${roomId}/messages/readall`;

export const THREAD_GETS = COMMUNICATE_API + '/threads';
export const THREAD_GETS_BY_USER = COMMUNICATE_API + '/threads/users';
export const THREAD_ADD = COMMUNICATE_API + '/threads';
export const THREAD_UPDATE = COMMUNICATE_API + '/threads';
export const THREAD_DELETE = COMMUNICATE_API + '/threads';

export const UPLOAD = SERVER + '/upload';
export const UPLOAD_FILE = UPLOAD;
export const DELETE_FILE = UPLOAD;
export const FILE_GET_BY_ID = COMMON_API + '/documents';

export const SERVICE = SERVER + '/service';
export const SERVICE_GETS = SERVICE + '/list';
export const SERVICE_IN_USE_GETS = SERVICE + '/list-in-use';
export const SERVICE_UPDATE_STATUS = SERVICE + '/updateStatus';
export const PROVIDER = SERVER + '/Providers';

export const CONTRACT_REGISTER = SERVICE_API + '/contract';
export const CONTRACT_GET_APARTMENT_ID = SERVICE_API + '/contracts';
export const SERVICE_CONTRACT_DELETE = SERVICE_API + '/service-contract/remove';
export const SERVICE_CONTRACT_ACCUMULATION_ADD = SERVICE_API + '/service-contract/accumulation';
export const SERVICE_CONTRACT_PRICE_UNIT_ADD = SERVICE_API + '/service-contract/price-unit';

export const ICONS = SERVER + '/base/icons';

export const FORMULA = SERVER + '/Formulars';
export const SURCHARGE = SERVER + '/SurchargeServices';
export const CONTRACT_TEMPLATE = SERVER + '/contract-template';
export const CONTRACT_TEMPLATE_GET = CONTRACT_TEMPLATE + '/list';
export const CONTRACT_TEMPLATE_GET_BY_FORMULA_ID = CONTRACT_TEMPLATE + '/list-by-formula-id';
export const CONTRACT_TEMPLATE_GET_BY_SURCHARGE_ID = CONTRACT_TEMPLATE + '/list-by-surcharge-id';
export const CONTRACT_TEMPLATE_GET_BY_PROMOTION_ID = CONTRACT_TEMPLATE + '/list-by-promotion-id';
export const CONTRACT_TEMPLATE_ADD = CONTRACT_TEMPLATE;
export const CONTRACT_TEMPLATE_UPDATE = CONTRACT_TEMPLATE;
export const CONTRACT_TEMPLATE_DELETE = CONTRACT_TEMPLATE;
export const CONTRACT_TEMPLATE_TARGETS_GET = CONTRACT_TEMPLATE + '/targets';
export const SEARCH_TARGET_BY_CONTRACT_TEMPLATE_ID = CONTRACT_TEMPLATE + '/searchTargetByContractTemplateId';

export const CONTRACT = SERVER + '/contract';
export const CONTRACT_GET_LIST = CONTRACT + '/list';
export const CONTRACT_GET_SUM_TOTAL = CONTRACT + '/list/sum-total';
export const CONTRACT_GET_BY_TIME = CONTRACT + '/getContractsByTime';
export const CONTRACT_ADDS = CONTRACT;
export const CONTRACT_DELETE = CONTRACT;
export const CONTRACT_DELETE_LIST = CONTRACT + '/delete-multi';
export const CONTRACT_UPDATE = CONTRACT;
export const CONTRACT_GET_LATEST = CONTRACT + '/latest';
export const CONTRACT_SUM_PAYMENT_LATEST = CONTRACT + '/latest/sum-payment';
export const CONTRACT_IMPORT = CONTRACT + '/import-data';
export const CONTRACT_EXPORT = CONTRACT + '/export-data';
export const CONTRACT_FILE_TEMPLATE = (serviceType, serviceId) =>
  `${CONTRACT}/excel-template?serviceType=${serviceType}&serviceId=${serviceId}`;
export const CONTRACT_UNIT_SERVICE_HISTORY = CONTRACT + '/unit-service-history';

export const BILL = SERVER + '/bill';
export const BILL_GETS_LIST = BILL; // New API get list bill
export const BILL_SUM_TOTAL_PAYMENT = BILL + '/sum-total'; // New API get list bill
export const BILL_GET_LIST = BILL + '/list';
export const BILL_EXPORT = BILL_GET_LIST + '/export-data';
export const BILL_SUM_PAYMENT = BILL + '/sum-payment';
export const BILL_CREATE_AUTO = BILL + '/auto';
export const BILL_CREATE_MANUAL = BILL + '/manual';
export const BILL_UPDATE_INTEREST = BILL + '/interest/auto';
export const BILL_DELETE_MULTI = BILL + '/delete-multi';
export const BILL_UNPAID_GET = BILL + '/unpaid';
export const BILL_ADJUST = BILL + '/adjust';
export const BILL_SEND_GETS = BILL + '/send-bill';
export const BILL_SEND_SUM_TOTAL_PAYMENT = BILL + '/send-bill/sum-total';
export const BILL_LIST_NOT_GROUP_UNIT = BILL + '/list-not-group-unit';
export const BILL_LIST_BY_UNIT = BILL + '/list-bill-adjust-down-by-unit';

export const RECEIPT = SERVER + '/receipt';
export const RECEIPT_CREATE_MANUAL = RECEIPT + '/manual';
export const RECEIPT_UPDATE = (id) => `${RECEIPT}/${id}`;
export const RECEIPT_CREATE = RECEIPT;
export const RECEIPT_GETS = RECEIPT + '/list';
export const RECEIPT_GET_BY_ID = RECEIPT;
export const RECEIPT_EXPORT = RECEIPT + '/export-data';
export const RECEIPT_CANCEL = RECEIPT;
export const RECEIPT_SUM_TOTAL_PAYMENT = RECEIPT + '/sum-payment';

// Communication

export const POST = SERVER + '/Posts';

export const COMMENT = SERVER + '/Comments';

export const RESIDENT_STATUS = SERVER + '/ResidentStatuses';

export const ENTERPRISE = SERVER + '/enterprise';
export const ENTERPRISE_GET_LIST = ENTERPRISE + '/list';
export const ENTERPRISE_SEARCH_BY_NAME = ENTERPRISE + '/searchByName';
export const ENTERPRISE_ADD = ENTERPRISE;
export const ENTERPRISE_UPDATE = ENTERPRISE;
export const ENTERPRISE_DELETE = ENTERPRISE;

export const LABEL = SERVER + '/label';
export const LABEL_GETS = LABEL + '/list';
export const LABEL_ADD = LABEL;
export const LABEL_UPDATE = LABEL;
export const LABEL_DELETE_LIST = LABEL + '/delete';
export const LABEL_GROUP_LIST = LABEL + '/group-list';
export const LABEL_TREE_LIST = LABEL + '/list-tree';

export const INVOICE = SERVER + '/invoice';
export const INVOICE_SEND_EMAIL = INVOICE + '/send-email';
export const INVOICE_RESEND_EMAIL = INVOICE + '/resend-email';
export const INVOICE_PRINT = INVOICE + '/print';
export const INVOICE_REPRINT = INVOICE + '/re-print';
export const INVOICE_PREVIEW = INVOICE + '/preview';
export const INVOICE_GETS = INVOICE + '/list';
export const INVOICE_GET_BY_ID = INVOICE;
export const INVOICE_PREVIEW_DEBT = INVOICE + '/preview-debt';
export const INVOICE_PRINT_DEBT = INVOICE + '/print-debt';
export const INVOICE_SEND_EMAIL_DEBT = INVOICE + '/send-email-debt';

export const FUND = SERVER + '/fund';
export const FUND_GETS = FUND + '/list';
export const FUND_ADD = FUND;
export const FUND_UPDATE = FUND;
export const FUND_DELETE_LIST = FUND + '/delete';

export const CASH_BOOK = SERVER + '/cashbook';
export const CASH_BOOK_EXPORT = CASH_BOOK + '/export-data';
export const SERVICE_CHARGE_GETS = CASH_BOOK + '/list-total-payment-services';
export const SERVICE_CHARGE_EXPORT = SERVICE_CHARGE_GETS + '/export-data';
export const SERVICE_CHARGE_LIST_APARTMENT_USE_SERVICE = CASH_BOOK + '/list-unit-use-service';
export const LIST_UNIT_WITH_BALANCE = CASH_BOOK + '/list-unit-with-balance';
export const SUM_TOTAL_PAYMENT_LIST_UNIT_WITH_BALANCE = LIST_UNIT_WITH_BALANCE + '/sum-total-balance';
export const LIST_CHARGE_HISTORY_UNIT_ACCOUNT = CASH_BOOK + '/list-charge-history-unit-account';
export const LIST_UNIT_WITH_BALANCE_EXPORT = LIST_UNIT_WITH_BALANCE + '/export-data';
export const REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME = CASH_BOOK + '/report-service-update-until-current-time';
export const TOTAL_PAYMENT_REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME = CASH_BOOK + '/report-service-update-until-current-time/sum-payment';
export const EXPORT_REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME_DEFAULT = CASH_BOOK + '/report-service-update-until-current-time/export-data';
export const EXPORT_REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME_REVENUE =
  CASH_BOOK + '/report-service-update-until-current-time/export-revenue-service-data';
export const EXPORT_REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME_DEBT =
  CASH_BOOK + '/report-service-update-until-current-time/export-with-paid-before-data-investor-temp';
export const EXPORT_REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME_REMAINING_DEBT =
  CASH_BOOK + '/report-service-update-until-current-time/export-data-investor-temp';
export const REPORT_SERVICE_BY_MONTH = CASH_BOOK + '/report-service-by-month';
export const SUM_PAYMENT_REPORT_SERVICE_BY_MONTH = CASH_BOOK + '/report-service-by-month/sum-payment';
export const EXPORT_REPORT_SERVICE_BY_MONTH = CASH_BOOK + '/report-service-by-month/export-data';

export const REPORT_STORED_SAVE = CASH_BOOK + '/saved-report';
export const REPORT_STORED = CASH_BOOK + '/saved-report/list';
export const REPORT_REPORT_STORED_BY_ID = (reportId) => `${CASH_BOOK}/saved-report/${reportId}`;
export const REPORT_REPORT_STORED_DETAIL = (reportId) => `${CASH_BOOK}/saved-report/${reportId}/service-by-month`;
export const REPORT_REPORT_STORED_TOTAL = (reportId) => `${CASH_BOOK}/saved-report/${reportId}/service-by-month/sum-payment`;
export const REPORT_REPORT_STORED_EXPORT = (reportId) => `${CASH_BOOK}/saved-report/${reportId}/service-by-month/export-data`;

export const REPORT = SERVER + '/report';
export const REPORT_DEBT = REPORT + '/debt';
export const REPORT_DEBT_TOTAL = REPORT + '/debt/total';
export const REPORT_DEBT_EXPORT = REPORT + '/debt/export';
export const REPORT_PAID_BEFORE = REPORT + '/paid-before';
export const REPORT_PAID_BEFORE_TOTAL = REPORT + '/paid-before/total';
export const REPORT_PAID_BEFORE_EXPORT = REPORT + '/paid-before/export';
export const REPORT_INVESTOR = REPORT + '/investor';
export const REPORT_INVESTOR_TOTAL = REPORT + '/investor/total';
export const REPORT_INVESTOR_EXPORT = REPORT + '/investor/export';
export const REPORT_OPERATING = REPORT + '/operating';
export const REPORT_OPERATING_EXPORT = REPORT + '/operating/export';
export const REPORT_BILL = CASH_BOOK + '/report-bill';
export const REPORT_BILL_TOTAL = CASH_BOOK + '/report-bill/sum-payment';
export const REPORT_BILL_EXPORT = CASH_BOOK + '/report-bill/export-data'

export const PAY_SLIP = SERVER + '/pay-slip';
export const PAY_SLIP_GETS = PAY_SLIP + '/list';
export const PAY_SLIP_CANCEL = (paySlipId) => PAY_SLIP + `/${paySlipId}/cancel`;
export const PAY_SLIP_PREVIEW = (paySlipId) => PAY_SLIP + `/${paySlipId}`;
export const PAY_SLIP_EXPORT = PAY_SLIP + '/export-data';
export const PAY_SLIP_SUM_TOTAL_PAYMENT = PAY_SLIP + '/sum-total-payment';
export const PAY_SLIP_UPDATE = (paySlipId) => PAY_SLIP + `/${paySlipId}`;

export const REPORT_SERVICE_GETS = SERVER + '/cashbook/total-payment-service-per-month';
export const REPORT_SERVICE_EXPORT = SERVER + '/cashbook/total-payment-service-per-month/export-data';
export const REPORT_SERVICE_SUM_PAYMENT = SERVER + '/cashbook/total-payment-service-per-month/sum-payment';

export const PAYMENT_ONLINE_ORDER_GETS = SERVER + '/payment-online/histories';
export const PAYMENT_ONLINE_ORDER_TOTAL = SERVER + '/payment-online/histories/total';
export const PAYMENT_ONLINE_ORDER_EXPORT = SERVER + '/payment-online/histories/export-data';

export const FCM_SEND_DEVICE_TOKEN = SERVER + '/installation';

export const ACTIVITY_LOG_GETS = SERVER + '/activity/logs';
export const ACTIVITY_MANAGER_GETS = SERVER + '/employee/list';
export const ACTIVITY_EXPORT_GETS = SERVER + '/activity/export-data';

const DASHBOARD = SERVER + '/dashboard';
export const DASHBOARD_OVERVIEW_GETS = DASHBOARD + '/overview';
export const DASHBOARD_REVENUE_GETS = DASHBOARD + '/revenue';
export const DASHBOARD_TOTAL_PAID_AND_UNPAID_GETS = DASHBOARD + '/total-paid-and-unpaid';
export const DASHBOARD_RESIDENT_GETS = DASHBOARD + '/resident';
export const DASHBOARD_SERVICE_GETS = DASHBOARD + '/service/list-in-use';
export const DASHBOARD_VEHICLE_GETS = DASHBOARD + '/parking';
export const DASHBOARD_BUILDING_GETS = DASHBOARD + '/building/list';

export const NEWS_INVESTOR_GETS = SERVER + '/unit-group/list-investor';
export const NEWS_ADD = SERVER + '/news';
export const NEWS_DELETE = SERVER + '/news/delete-multi';
export const NEWS_GETS = SERVER + '/news/list';
export const NEWS_GET_BY_ID = (newsId) => SERVER + `/news/${newsId}`;

const CARD = SERVER + '/card';
export const CARD_ADD = CARD;
export const CARD_UPDATE = CARD;
export const CARD_LIST = CARD;
export const CARD_DELETE = CARD + '/delete-multi';
export const CARD_EXPORT = CARD + '/export';
export const CARD_IMPORT = CARD + '/import';
export const CARD_HISTORY = (cardId) => `${CARD}/activity/${cardId}`;
export const CARD_HISTORY_USAGE = (cardId) => `${CARD}/history-usage/${cardId}`;
export const CARD_UPDATE_STATUS = CARD + '/status/update-multi';
export const CARD_SUGGEST_GETS = CARD;
export const CARD_REGISTER_ADD = CARD + '/register';
export const CARD_REGISTER_SERVICE_GETS = SERVER + '/service/list-in-use';
export const CARD_REGISTER_GETS = CARD + '/register';
export const CARD_REGISTER_DELETE = CARD + '/register/delete-multi';
export const CARD_REGISTER_PREVIEW = CARD + '/register/preview';
export const CARD_REGISTER_PRINT = CARD + '/register/print';
export const CARD_REGISTER_REPRINT = CARD + '/register/print';
export const CARD_REGISTER_EXPORT = CARD + '/register/export';

const REQUEST = SERVER + '/request';
export const REQUEST_GETS = REQUEST + '/list';
export const REQUEST_GET = (requestId) => `${REQUEST}/${requestId}`;
export const REQUEST_ADD = REQUEST;
export const REQUEST_UPDATE = REQUEST;
export const REQUEST_DUPLICATE = REQUEST + '/duplicate';
export const REQUEST_EXPORT_DATA = REQUEST + '/export-data';
export const REQUEST_CANCEL = REQUEST + '/delete';
export const REQUEST_CHANGE_PROGRESS = (requestId, progressId) => `${REQUEST}/${requestId}/change-progress/${progressId}`;
export const REQUEST_APPROVE = (requestId) => `${REQUEST}/${requestId}/approval/1`;
export const REQUEST_DENY = (requestId) => `${REQUEST}/${requestId}/approval/0`;
export const REQUEST_ACTIVITY_LOG = (requestId) => `${REQUEST}/${requestId}/activities`;
export const REQUEST_TIMELINE = (requestId) => `${REQUEST}/${requestId}/timeline`;

const REQUEST_TASK = SERVER + '/task';
export const REQUEST_TASK_GETS = REQUEST_TASK + '/list';
export const REQUEST_TASK_ADD = REQUEST_TASK;
export const REQUEST_TASK_UPDATE = (taskId) => REQUEST_TASK + `/${taskId}`;
export const REQUEST_TASK_CHANGE_PROGRESS = (taskId, progressId) => REQUEST_TASK + `/${taskId}/change-progress/${progressId}`;
export const REQUEST_TASK_APPROVAL = REQUEST;
export const REQUEST_TASK_DELETE = REQUEST;
export const REQUEST_EMPLOYEES_GETS = SERVER + '/employee/list';
export const REQUEST_TOTAL_TABS_GETS = REQUEST;

const RESIDENT_UTILITY = SERVER + '/amenity';
export const RESIDENT_UTILITY_GETS = RESIDENT_UTILITY + '/list';
export const RESIDENT_UTILITY_DETAIL = (id) => RESIDENT_UTILITY + `/m/${id}`;
export const RESIDENT_UTILITY_ADD = RESIDENT_UTILITY;
export const RESIDENT_UTILITY_UPDATE = (id) => RESIDENT_UTILITY + `/${id}`;
export const RESIDENT_UTILITY_DELETE = (id) => RESIDENT_UTILITY + `/${id}/delete`;
export const RESIDENT_UTILITY_CHANGE_STATUS = (id, status) => RESIDENT_UTILITY + `/${id}/${status}`; // Status is active or inactive
export const RESIDENT_UTILITY_BOOKING_GETS = RESIDENT_UTILITY + '/booking/list';
export const RESIDENT_UTILITY_BOOKING_APPROVED = (id) => RESIDENT_UTILITY + `/${id}/approve`;
export const RESIDENT_UTILITY_BOOKING_REJECTED = (id) => RESIDENT_UTILITY + `/${id}/reject`;
export const RESIDENT_UTILITY_BOOKING_CANCELED = (id) => RESIDENT_UTILITY + `/${id}/cancel`;

const AMENITIES = SERVER + '/amenities';
export const AMENITIES_GETS = AMENITIES + '/list';
export const AMENITIES_GET = (id) => AMENITIES + '/' + id;
export const AMENITIES_GET_PRIORITY_IN_APP_SELECTED = AMENITIES + '/list-priority';
export const AMENITIES_ADD = AMENITIES;
export const AMENITIES_UPDATE = (id) => AMENITIES + '/' + id;
export const AMENITIES_ACTIVE = (id) => AMENITIES + '/' + id + '/active';
export const AMENITIES_INACTIVE = (id) => AMENITIES + '/' + id + '/inactive';
export const AMENITIES_ORDER_GETS = AMENITIES + '/booking/list';
export const AMENITY_MERCHANT_ORDERS_GETS = AMENITIES + '/pms/merchant/booking/list';
export const AMENITY_MERCHANT_ORDERS_EXPORT = AMENITIES + '/pms/merchant/booking/export';
export const AMENITY_MERCHANT_ORDERS_GET = (id) => `${AMENITIES}/pms/merchant/booking/${id}`;
export const AMENITIES_ORDER_EXPORT = AMENITIES + '/booking/export';
export const AMENITIES_ORDER_APPROVE = AMENITIES + '/booking/approve';
export const AMENITIES_ORDER_REJECT = AMENITIES + '/booking/reject';
export const AMENITIES_ORDER_GET = (id) => AMENITIES + '/booking/' + id;
export const AMENITIES_ORDER_PAYMENT = (id) => AMENITIES + '/booking/' + id + '/pay-manual';
export const AMENITIES_ORDER_REFUND = (id) => AMENITIES + '/booking/' + id + '/refund-manual';
export const AMENITIES_ORDER_REFUND_DEPOSITS = (id) => AMENITIES + '/booking/' + id + '/refund-manual';
