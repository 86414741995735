import { AMENITIES_ORDER } from './amenities.types';

export const gets = ({ filter, unitGroupId }) => ({
  type: AMENITIES_ORDER.GETS.REQUEST,
  payload: {
    filter,
    unitGroupId,
  },
});

export const exportData = ({ filter }) => ({
  type: AMENITIES_ORDER.EXPORT.REQUEST,
  payload: {
    ...filter,
  },
});
