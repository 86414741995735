import { FORM_SERVICE_REGISTER } from '../constants/actionType';

export const searchApartmentInFormServiceRegister = ({ unitGroupId, name }) => ({
    type: FORM_SERVICE_REGISTER.SEARCH_UNIT.REQUEST,
    payload: {
        unitGroupId,
        name,
    },
});

export const searchResidentInFormServiceRegister = ({ unitGroupId, name }) => ({
    type: FORM_SERVICE_REGISTER.SEARCH_RESIDENT.REQUEST,
    payload: {
        unitGroupId,
        name,
    },
});