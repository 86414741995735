import { language } from '../defined/Language';

export const PAYMENT_METHOD_OPTIONS = [
    {
        id: 'TRANSFER',
        name: language['label']['transfer'],
    },
    {
        id: 'CASH',
        name: language['label']['cash'],
    },
];

export const PAYMENT_METHOD_WITH_ONLINE_OPTIONS = [
    ...PAYMENT_METHOD_OPTIONS,
    {
        id: 'ONLINE',
        name: language['label']['online'],
    },
];

export const PAYMENT_METHOD_BY_ID = {
    TRANSFER: {
        id: 'TRANSFER',
        name: language['label']['transfer'],
    },
    CASH: {
        id: 'CASH',
        name: language['label']['cash'],
    },
    ONLINE: {
        id: 'ONLINE',
        name: language['label']['online'],
    },
};

export const APARTMENT_ACCOUNT_OPTIONS = [
    {
        id: 'DONT_USE_APARTMENT_ACCOUNT',
        name: language['label']['do_not_use'],
    },
    {
        id: 'PAY_USING_UNIT_ACCOUNT',
        name: language['label']['in_use'],
    },
];

export const PAY_USING_UNIT_ACCOUNT = 'PAY_USING_UNIT_ACCOUNT';
export const DONT_USE_APARTMENT_ACCOUNT = 'DONT_USE_APARTMENT_ACCOUNT';

export const TRANSFER = 'TRANSFER';
export const CASH = 'CASH';
