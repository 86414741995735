import { createSelector } from 'reselect';

export const staffSelector = (state) => state.staffPage;
export const userSelector = (state) => state.data.user;

export const dataTableSelector = createSelector(staffSelector, (staff) => staff?.data ?? {});
export const staffAddSelector = createSelector(staffSelector, (staff) => staff?.staffAdd ?? {});
export const staffUpdateSelector = createSelector(staffSelector, (staff) => staff?.staffUpdate ?? {});
export const staffDeleteSelector = createSelector(staffSelector, (staff) => staff?.staffDelete ?? {});
export const staffImportSelector = createSelector(staffSelector, (staff) => staff?.staffImport ?? {});
export const staffExportSelector = createSelector(staffSelector, (staff) => staff?.staffExport ?? {});
export const departmentsSelector = createSelector(staffSelector, (staff) => staff?.departments ?? {});
export const emailCurrentUserSelector = createSelector(userSelector, (user) => user?.email ?? '');
