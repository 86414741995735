import { RESIDENT_GETS, RESIDENT_DELETE, RESIDENT_IMPORT, RESIDENT_EXPORT, RESIDENT_CHANGE_STATUS } from './resident.types';

export const getResidentAction = (unitGroupId, page, filterObject) => ({
  type: RESIDENT_GETS.REQUEST,
  payload: {
    unitGroupId,
    page,
    filterObject,
  },
});

export const deleteResidentAction = (unitGroupId, isAll, array, filterObject) => ({
  type: RESIDENT_DELETE.REQUEST,
  payload: {
    isAll,
    array,
    filterObject,
    unitGroupId,
  },
});

export const refreshDeleteResidentAction = () => ({
  type: RESIDENT_DELETE.REFRESH,
});

export const importResidentAction = (unitGroupId, file) => ({
  type: RESIDENT_IMPORT.REQUEST,
  payload: {
    file,
    unitGroupId,
  },
});

export const exportResidentAction = (unitGroupId, filterObject) => ({
  type: RESIDENT_EXPORT.REQUEST,
  payload: {
    filterObject,
    unitGroupId,
  },
});

export const changeStatusResident = (unitGroupId, residentId, status) => ({
  type: RESIDENT_CHANGE_STATUS.REQUEST,
  payload: {
    residentId,
    status,
    unitGroupId,
  },
});

export const resetStateChangeStatusResident = () => ({
  type: RESIDENT_CHANGE_STATUS.REFRESH,
});
