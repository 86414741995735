import { createAction } from 'shared/utils';

export const ACTION_TYPE_EVENT_GETS = createAction('EVENT_GETS');
export const ACTION_TYPE_EVENT_GET = createAction('EVENT_GET');
export const ACTION_TYPE_EVENT_TAG_GETS = createAction('EVENT_TAG_GETS');
export const ACTION_TYPE_EVENT_FLOOR_GETS = createAction('EVENT_FLOOR_GETS');
export const ACTION_TYPE_EVENT_APARTMENT_GETS = createAction('EVENT_APARTMENT_GETS');
export const ACTION_TYPE_EVENT_APARTMENT_RECEIVE_EVENT_GETS = createAction('EVENT_APARTMENT_RECEIVE_EVENT_GETS');
export const ACTION_TYPE_EVENT_ADD = createAction('EVENT_ADD');
export const ACTION_TYPE_EVENT_DELETE = createAction('EVENT_DELETE');
