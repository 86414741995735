import { COMMUNICATE } from '../../constants/actionType';
import { call, put } from 'redux-saga/effects';
import { 
    getTotalAnnouncementByTypeService,
    getTotalAnnouncementByDateService,
    getTotalAnnouncementByAllService,
    getTotalAnnouncementByLabelService
 } from '../../services/announcement';
import { X_UNIT_GROUP_ID } from 'constants';

export function* getTotalAnnouncementByType(action) {
    const { buildingId, from, to, type } = action.payload;

    const result = yield call(getTotalAnnouncementByTypeService, {
        params: { from, to, type },
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, COMMUNICATE.OVERALL.ANNOUNCEMENT_FILTER_BY_TYPE);

    yield put(result);
}

export function* getTotalAnnouncementByDate(action) {
    const { buildingId, from, to } = action.payload;

    const result = yield call(getTotalAnnouncementByDateService, {
        params: { from, to },
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, COMMUNICATE.OVERALL.ANNOUNCEMENT_FILTER_BY_DATE);

    yield put(result);
}

export function* getTotalAnnouncementByAll(action) {
    const { buildingId } = action.payload;

    const result = yield call(getTotalAnnouncementByAllService, {
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, COMMUNICATE.OVERALL.ANNOUNCEMENT_FILTER_BY_ALL);

    yield put(result);
}

export function* getTotalAnnouncementByLabel(action) {
    const { buildingId, from, to } = action.payload;

    const result = yield call(getTotalAnnouncementByLabelService, {
        params: { from, to },
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, COMMUNICATE.OVERALL.ANNOUNCEMENT_FILTER_BY_LABEL);

    yield put(result);
}