// @flow

import { SUPPLY } from '../../constants/actionType';

export const initPageSupply = (buildingId: number) => ({
    type: SUPPLY.INIT.REQUEST,
    payload: {
        buildingId,
    },
});

export const getSupplies = (buildingId: number, filter: Object) => ({
    type: SUPPLY.GETS.REQUEST,
    payload: {
        buildingId,
        filter,
    },
});

export const addSupply = (supply, unitGroupId) => ({
    type: SUPPLY.ADD.REQUEST,
    payload: {
        supply,
        unitGroupId,
    },
});

export const refreshAddSupply = () => ({
    type: SUPPLY.ADD.REFRESH,
});

export const updateSupply = (id, supply, unitGroupId) => ({
    type: SUPPLY.UPDATE.REQUEST,
    payload: {
        id,
        supply,
        unitGroupId,
    },
});

export const refreshUpdateSupply = () => ({
    type: SUPPLY.UPDATE.REFRESH,
});

export const deleteSupply = (supply, unitGroupId) => ({
    type: SUPPLY.DELETE.REQUEST,
    payload: {
        supply,
        unitGroupId,
    },
});

export const refreshDeleteSupply = () => ({
    type: SUPPLY.DELETE.REFRESH,
});

export const getEmployeeInFormSupply = (buildingId: number, name: string) => ({
    type: SUPPLY.FORM.SEARCH_EMPLOYEE.REQUEST,
    payload: {
        buildingId,
        name,
    },
});

export const getPartnerInFormSupply = (buildingId: number, name: string) => ({
    type: SUPPLY.FORM.SEARCH_PARTNER.REQUEST,
    payload: {
        buildingId,
        name,
    },
});

export const getDepartmentInFormSupply = (buildingId: number, name: string) => ({
    type: SUPPLY.FORM.SEARCH_DEPARTMENT.REQUEST,
    payload: {
        buildingId,
        name,
    },
});

export const addTransaction = (data) => ({
    type: SUPPLY.TRANSACTION.ADD.REQUEST,
    payload: data,
});

export const refreshAddTransaction = () => ({
    type: SUPPLY.TRANSACTION.ADD.REFRESH,
});

export const getSupplyHistory = (buildingId, filters) => ({
    type: SUPPLY.HISTORY_GETS.REQUEST,
    payload: {
        buildingId,
        filters,
    },
});

export const exportSupplies = (buildingId: number, filter: Object) => ({
    type: SUPPLY.EXPORT.REQUEST,
    payload: {
        buildingId,
        filter,
    },
});

export const importSupplies = (buildingId, file) => ({
    type: SUPPLY.IMPORT.REQUEST,
    payload: {
        buildingId,
        file,
    },
});

export const resetImportSupply = () => ({
    type: SUPPLY.IMPORT.SUCCESS,
    payload: {
        currentPage: 0,
        totalPage: 0,
        totalRecord: 0,
        result: [],
    },
});

export const exportHistorySuppliesUse = (buildingId: number, filter: Object) => ({
    type: SUPPLY.HISTORY_EXPORT.REQUEST,
    payload: {
        buildingId,
        filter,
    },
});