import { initStatePagination } from './paginate';
import paginate from './paginate';
import { FORM_SERVICE_REGISTER } from '../constants/actionType';
import { combineReducers } from 'redux';

export const initState = {
    apartments: {
        ...initStatePagination,
    },
    residents: {
        ...initStatePagination,
    },
};

const apartments = paginate(FORM_SERVICE_REGISTER.SEARCH_UNIT);
const residents = paginate(FORM_SERVICE_REGISTER.SEARCH_RESIDENT);

export default combineReducers({
    apartments,
    residents,
});
