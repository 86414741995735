import { DEPARTMENT, DEPARTMENT_ADD, DEPARTMENT_GET_LIST, DEPARTMENT_UPDATE, DEPARTMENT_GET_LIST_OF_USER } from '../defined/API';
import { METHOD, X_UNIT_GROUP_ID } from '../constants';
import { callApi } from './api';
import { handleError, handleResponse } from '../libs/http';

export const getDepartmentsService = async ({ headers, params }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(DEPARTMENT_GET_LIST, {}, METHOD.GET, {
            headers,
            params,
        });
        return handleResponse(response, type);
    } catch (e) {
        handleError(e, FAIL);
    }

};
export const addDepartmentService = async ({ department, unitGroupId }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(DEPARTMENT_ADD, department, METHOD.POST, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type, 'department');
    } catch (e) {
        handleError(e, FAIL);
    }

};
export const updateDepartmentService = async ({ department, unitGroupId }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(`${DEPARTMENT_UPDATE}/${department.id}`, department, METHOD.PUT, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type, 'department');
    } catch (e) {
        handleError(e, FAIL);
    }

};
export const deleteDepartmentService = async ({ departmentId, unitGroupId }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(`${DEPARTMENT}/${departmentId}`, {}, METHOD.DELETE, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type, 'department');
    } catch (e) {
        handleError(e, FAIL);
    }
};

export async function getDepartmentsOfUserService({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        let response = await callApi(DEPARTMENT_GET_LIST_OF_USER(params.userId), {}, METHOD.GET, {
            headers,
        });
        return handleResponse(response, type);
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}