import { language } from 'defined/Language';
import convertArrToObj from '../utils/convertArrToObj';

export const GUEST_OF_RESIDENT = 'GUEST_OF_THE_RESIDENT';
export const WALK_IN_GUEST = 'WALK_IN';

export const GUEST_TYPES = [
  {
    label: language.guest_of_resident,
    value: GUEST_OF_RESIDENT,
  },
  {
    label: language.walk_in_guest,
    value: WALK_IN_GUEST,
  },
];

export const GUEST_TYPE_OBJ = convertArrToObj(GUEST_TYPES, 'value');

export const ACCESS_MANAGEMENT_STATUS_WAITING_APPROVED = 'WAITING_FOR_APPROVED';
export const ACCESS_MANAGEMENT_STATUS_APPROVED = 'APPROVED';
export const ACCESS_MANAGEMENT_STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const ACCESS_MANAGEMENT_STATUS_COMPLETED = 'COMPLETED';
export const ACCESS_MANAGEMENT_STATUS_CANCELED = 'CANCELLED';
export const ACCESS_MANAGEMENT_STATUS_ALL = '';

export const ACCESS_MANAGEMENT_STATUS_LIST = [
  {
    label: language.waiting_for_approved,
    value: ACCESS_MANAGEMENT_STATUS_WAITING_APPROVED,
  },
  {
    label: language.approved,
    value: ACCESS_MANAGEMENT_STATUS_APPROVED,
  },
  {
    label: language.in_progress,
    value: ACCESS_MANAGEMENT_STATUS_IN_PROGRESS,
  },
  {
    label: language.completed,
    value: ACCESS_MANAGEMENT_STATUS_COMPLETED,
  },
  {
    label: language.canceled,
    value: ACCESS_MANAGEMENT_STATUS_CANCELED,
  },
  {
    label: language.all,
    value: ACCESS_MANAGEMENT_STATUS_ALL,
  },
];

export const ACCESS_MANAGEMENT_STATUS_OBJ = convertArrToObj(ACCESS_MANAGEMENT_STATUS_LIST, 'value');
export const ACCESS_MANAGEMENT_DEFAULT_FILTER = {
  code: undefined,
  unitName: undefined,
  fullName: undefined,
  expectedIncomingAt: [],
  incomingAt: [],
  type: '',
};

export const ACCESS_MANAGEMENT_CANCEL_BY_EMPLOYEE = 'EMPLOYEE';
export const ACCESS_MANAGEMENT_CANCEL_BY_RESIDENT = 'EMPLOYEE';

export const ACCESS_MANAGEMENT_CANCEL_BY_OBJ = {
  [ACCESS_MANAGEMENT_CANCEL_BY_EMPLOYEE]: {
    label: language.employee,
    value: ACCESS_MANAGEMENT_CANCEL_BY_EMPLOYEE,
  },
  [ACCESS_MANAGEMENT_CANCEL_BY_RESIDENT]: {
    label: language.resident,
    value: ACCESS_MANAGEMENT_CANCEL_BY_RESIDENT,
  },
};

export const MODAL_TYPE_PROCESS_REGISTRATION_FORM_APPROVE = 'APPROVE';
export const MODAL_TYPE_PROCESS_REGISTRATION_FORM_REJECT = 'REJECT';
export const MODAL_TYPE_PROCESS_REGISTRATION_FORM_CHECK_IN = 'CHECK_IN';
export const MODAL_TYPE_PROCESS_REGISTRATION_FORM_CHECK_OUT = 'CHECK_OUT';
export const MODAL_TYPE_PROCESS_REGISTRATION_FORM_REMOVE = 'REMOVE';
