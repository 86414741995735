import { call, put } from 'redux-saga/effects';
import { ACTIVITY } from '../constants/actionType';
import {
    getActivityLogService,
    getManagersService,
    exportActivityLogService
} from '../services/activity';
import { X_UNIT_GROUP_ID } from '../constants';

export function* getActivityLog(action) {
    const { buildingId, feature, manager, from, to, page, limit, reset } = action.payload;

    if (reset) {
        yield put({ type: ACTIVITY.LOG.GETS.REFRESH });
    }

    const result = yield call(getActivityLogService, {
        params: {
            feature,
            manager,
            from,
            to,
            page,
            limit
        },
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, ACTIVITY.LOG.GETS);

    if (result.payload.result && result.payload.result.length === 0) {
        result.payload.loadMore = false;
    } else {
        result.payload.loadMore = true;
    }

    yield put(result);
}

export function* getManagers(action) {
    const { buildingId } = action.payload;
    
    const result = yield call(getManagersService, {
        params: {},
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, ACTIVITY.MANAGER.GETS);

    yield put(result);
}

export function* exportActivityLog(action) {
    const { buildingId, feature, manager, from, to } = action.payload;

    const result = yield call(exportActivityLogService, {
        params: {
            feature,
            manager,
            from,
            to
        },
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, ACTIVITY.EXPORT.GETS);

    yield put(result);
    yield put({ type: ACTIVITY.EXPORT.GETS.REFRESH });
}

