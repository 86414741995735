import { callApi } from './api';
import { handleError, handleResponse } from '../libs/http';
import { METHOD } from '../constants';
import {
    BILL_SUM_PAYMENT,
    CONTRACT_GET_LATEST,
    CONTRACT_SUM_PAYMENT_LATEST,
    CONTRACT_UNIT_SERVICE_HISTORY,
} from '../defined/API';

export const getContractLatestService = async ({params, headers}, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(CONTRACT_GET_LATEST, {}, METHOD.GET, { params, headers });
        return handleResponse(response, type, 'result', true);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const sumPaymentContractLatestService = async ({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL'
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(CONTRACT_SUM_PAYMENT_LATEST, {}, METHOD.GET, { params, headers });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getServiceHistoryService = async ({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(CONTRACT_UNIT_SERVICE_HISTORY, null, METHOD.GET, { params, headers });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};