import {
    addDocumentService,
    deleteDocumentService, getDepartmentsService,
    getDocumentsInBuildingService, getFolderInMoveToBoxService,
    updateDocumentService,
} from '../services';
import { call, put } from 'redux-saga/effects';
import { DOCUMENT, X_UNIT_GROUP_ID } from '../constants';

export function* initPageDocument(action) {
    const {
        unitGroupId,
    } = action.payload;

    const resDepartment = yield call(getDepartmentsService, {
        headers: {
            [X_UNIT_GROUP_ID]: unitGroupId,
        },
    }, DOCUMENT.DEPARTMENT.GETS);
    yield put(resDepartment);

    yield put({
        type: DOCUMENT.INIT.SUCCESS,
        payload: { code: 200 },
    });

}

export function* getDepartmentInPageDocument(action) {
    const {
        unitGroupId,
    } = action.payload;

    const result = yield call(getDepartmentsService, {
        headers: {
            [X_UNIT_GROUP_ID]: unitGroupId,
        },
    }, DOCUMENT.DEPARTMENT.GETS);
    yield put(result);
}

export function* getDocumentsInBuilding(action) {
    const {
        buildingId,
        page,
        filters,
    } = action.payload;
    const result = yield call(getDocumentsInBuildingService, {
        params: {

        },
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        }
    }, DOCUMENT.GETS);
    yield put({
        ...result,
    });
}

export function* getDocumentsMoveToBox(action) {
    const {
        buildingId,
        filter,
    } = action.payload;
    const result = yield call(getFolderInMoveToBoxService, buildingId, filter, DOCUMENT.GETS_IN_MOVE_TO_BOX);
    yield put({
        ...result,
    });
}

export function* addDocument(action) {

    const {
        document,
        unitGroupId,
    } = action.payload;

    const result = yield call(addDocumentService, {
        body: document,
        headers: {
            [X_UNIT_GROUP_ID]: unitGroupId,
        },
    }, DOCUMENT.ADD);
    yield put(result);
    yield put({
        type: DOCUMENT.ADD.REFRESH,
    });
}

export function* updateDocument(action) {
    const {
        document,
        unitGroupId,
    } = action.payload;

    const result = yield call(updateDocumentService, {
        headers: {
            [X_UNIT_GROUP_ID]: unitGroupId,
        },
        document,
    }, DOCUMENT.UPDATE);

    yield put({
        ...result,
    });
    yield put({
        type: DOCUMENT.UPDATE.REFRESH,
    });
}

export function* deleteDocument(action) {
    const {
        id,
        unitGroupId,
    } = action.payload;
    let result = yield call(deleteDocumentService, {
        documentId: id,
        unitGroupId: unitGroupId,
    }, DOCUMENT.DELETE);
    result.payload.documentId = id;
    yield put(result);
    yield put({
        type: DOCUMENT.DELETE.REFRESH,
    });
}