import { initStateFetching } from './fetching';
import { FLOOR } from '../constants/actionType';
import fetching from './fetching';
import { combineReducers } from 'redux';
import { initStatePagination } from './paginate';
import paginate from './paginate';

export const initState = {
    data: {
        ...initStatePagination,
    },
    floorAdd: {
        ...initStateFetching,
    },
    floorUpdate: {
        ...initStateFetching,
    },
    floorDelete: {
        ...initStatePagination,
    },
};

const data = paginate(FLOOR.GETS);
const floorAdd = fetching(FLOOR.ADD);
const floorUpdate = fetching(FLOOR.UPDATE);
const floorDelete = paginate(FLOOR.DELETE);

export default combineReducers({
    data,
    floorAdd,
    floorUpdate,
    floorDelete,
});

