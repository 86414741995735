import {
  ACCESS_MANAGEMENT_ACTION_TYPE_ADD,
  ACCESS_MANAGEMENT_ACTION_TYPE_APARTMENT_GETS,
  ACCESS_MANAGEMENT_ACTION_TYPE_APPROVE,
  ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_IN,
  ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_OUT,
  ACCESS_MANAGEMENT_ACTION_TYPE_COUNTRY_CODE_GETS,
  ACCESS_MANAGEMENT_ACTION_TYPE_EXPORT,
  ACCESS_MANAGEMENT_ACTION_TYPE_GETS,
  ACCESS_MANAGEMENT_ACTION_TYPE_REJECT,
  ACCESS_MANAGEMENT_ACTION_TYPE_REMOVE,
  ACCESS_MANAGEMENT_ACTION_TYPE_UPDATE,
} from './access-management.types';

export const getAccessManagements = ({ idBuilding, filter, page, limit }) => ({
  type: ACCESS_MANAGEMENT_ACTION_TYPE_GETS.REQUEST,
  payload: {
    idBuilding,
    filter,
    page,
    limit,
  },
});

export const getApartments = ({ idBuilding, filter, page, limit }) => ({
  type: ACCESS_MANAGEMENT_ACTION_TYPE_APARTMENT_GETS.REQUEST,
  payload: {
    idBuilding,
    filter,
    page,
    limit,
  },
});

export const getCountryCodes = () => ({
  type: ACCESS_MANAGEMENT_ACTION_TYPE_COUNTRY_CODE_GETS.REQUEST,
});

export const exportAccessManagements = ({ idBuilding, filter }) => ({
  type: ACCESS_MANAGEMENT_ACTION_TYPE_EXPORT.REQUEST,
  payload: {
    idBuilding,
    filter,
  },
});

export const addAccessManagement = ({ idBuilding, access }) => ({
  type: ACCESS_MANAGEMENT_ACTION_TYPE_ADD.REQUEST,
  payload: {
    idBuilding,
    access,
  },
});

export const updateAccessManagement = ({ idBuilding, idAccess, access }) => ({
  type: ACCESS_MANAGEMENT_ACTION_TYPE_UPDATE.REQUEST,
  payload: {
    idBuilding,
    idAccess,
    access,
  },
});

export const removeAccessManagement = ({ idBuilding, idAccess }) => ({
  type: ACCESS_MANAGEMENT_ACTION_TYPE_REMOVE.REQUEST,
  payload: {
    idBuilding,
    idAccess,
  },
});

export const approveAccessManagement = ({ idBuilding, idAccess }) => ({
  type: ACCESS_MANAGEMENT_ACTION_TYPE_APPROVE.REQUEST,
  payload: {
    idBuilding,
    idAccess,
  },
});

export const rejectAccessManagement = ({ idBuilding, idAccess, reason }) => ({
  type: ACCESS_MANAGEMENT_ACTION_TYPE_REJECT.REQUEST,
  payload: {
    idBuilding,
    idAccess,
    reason,
  },
});

export const checkInAccessManagement = ({ idBuilding, idAccess, images, note }) => ({
  type: ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_IN.REQUEST,
  payload: {
    idBuilding,
    idAccess,
    images,
    note,
  },
});

export const checkOutAccessManagement = ({ idBuilding, idAccess, images, note }) => ({
  type: ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_OUT.REQUEST,
  payload: {
    idBuilding,
    idAccess,
    images,
    note,
  },
});
