import { language } from '../defined/Language';

export const TARGET_INFO_STATUS_FILTER_OPTIONS = [
    {
        id: 'ALL',
        name: `----- ${language['label']['all']} -----`,
    },
    {
        id: 'SELECTED',
        name: language['label']['selected'],
    },
    {
        id: 'UNSELECT',
        name: language['label']['unselected'],
    }
];