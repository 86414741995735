import { FORM_RESIDENT } from '../constants';

export const initFormResident = (buildingId, apartmentId) => ({
    type: FORM_RESIDENT.INIT.REQUEST,
    payload: {
        buildingId,
        apartmentId,
    },
});

export const searchApartmentInFormResident = (buildingId, name) => ({
    type: FORM_RESIDENT.SEARCH_APARTMENT.REQUEST,
    payload: {
        buildingId,
        name,
    },
});
