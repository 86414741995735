import { DASHBOARD } from '../constants/actionType';
import { combineReducers } from 'redux';
import paginate, { initStatePagination } from './paginate';
import fetching, { initStateFetching } from './fetching';

export const initState = {
    overviewGets: { ...initStatePagination },
    revenueGets: { ...initStatePagination },
    totalPaidUnpaidGets: { ...initStatePagination },
    residentGets: { ...initStatePagination },
    servicesGets: { ...initStatePagination },
    vehicleGets: { ...initStatePagination },
    buildingGets: { ...initStatePagination},
};

const reducer = combineReducers({
    overviewGets: paginate(DASHBOARD.OVERVIEW.GETS),
    revenueGets: paginate(DASHBOARD.REVENUE.GETS),
    totalPaidUnpaidGets: paginate(DASHBOARD.TOTAL_PAID_UNPAID.GETS),
    residentGets: paginate(DASHBOARD.RESIDENT.GETS),
    servicesGets: paginate(DASHBOARD.SERVICE.GETS),
    vehicleGets: paginate(DASHBOARD.VEHICLE.GETS),
    buildingGets: paginate(DASHBOARD.BUILDING.GETS)
});

export default reducer;