import { CITY, DELETE_FILE, DISTRICT, UNIT_GROUP_GET_BY_ID, UPLOAD_FILE, COUNTRY_CODE } from '../defined/API';
import {METHOD} from '../constants';
import {callApi, callApiWithFile} from './api';
import { handleError, handleResponse } from '../libs/http';

export const getCities = async (type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const result = await callApi(CITY, null, METHOD.GET);
        return handleResponse(result, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};
export const getDistricts = async (type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const result = await callApi(DISTRICT, null, METHOD.GET);
        return handleResponse(result, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const uploadFileService = async (file, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApiWithFile(UPLOAD_FILE, file, METHOD.POST);
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const deleteFileService = async (key) => {
    await callApiWithFile(`${DELETE_FILE}/${key}`, {}, METHOD.DELETE);
};


export const getCountryCodes = async (type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const result = await callApi(COUNTRY_CODE, null, METHOD.GET);
        return handleResponse(result, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};
