import { takeLatest, takeEvery, all } from 'redux-saga/effects';
import { operations as apartmentOperations, types as apartmentTypes } from 'pages/Apartment/ducks';
const { getDataApartmentPage, getFloors, exportApartment, initApartmentPage } = apartmentOperations;
const { APARTMENT, FLOOR } = apartmentTypes;
import {
  getApartmentById,
  getResidentsByApartment,
  addResidentToApartment,
  updateResidentToApartment,
  deleteResidentToApartment,
  getDocumentsByApartment,
  addDocumentsToApartment,
  deleteDocumentToApartment,
  initApartmentDetail,
  initServicePageApartmentDetail,
  getBillUnpaidInPageApartmentDetail,
  createReceiptInPageApartmentDetail,
  updateDocumentToApartment,
  getWalletApartmentInApartment,
  getQRCocdeApartmentDetail,
  refreshQRCocdeApartmentDetail,
  exportQRCocdeApartmentDetail,
  getServiceHistoryPageApartmentDetail,
  getPaymentHistoryPageApartmentDetail,
  getPaymentHistoryPreview,
} from './apartmentDetail';
import {
  initApartmentConfigPage,
  addApartment,
  updateApartment,
  deleteApartment,
  initTagConfigPage,
  addTag,
  updateTag,
  deleteTag,
  addBlock,
  initBlockConfigPage,
  updateBlock,
  deleteBlock,
  importApartment,
  initServiceConfigPage,
  addServiceToBuilding,
  addAdditionalFee,
  updateAdditionalFee,
  deleteAdditionalFee,
  getAllAdditionFees,
  addAllowance,
  deleteAllowance,
  getAllowanceByBuilding,
  updateServiceToBuilding,
  initDepartmentConfigPage,
  getApartmentByPageInConfig,
  exportApartmentInPageConfig,
} from './config';
import { initStaffPage, addStaff, updateStaff, deleteStaff, importStaff, getStaffs, exportStaff } from './staff';
import {
  getResidentsOfBuildingByPage,
  addResident,
  deleteResident,
  initResidentPage,
  updateResident,
  importResidents,
  activeAccountResident,
  inactiveAccountResident,
  exportResident,
} from './resident';
import {
  getAnnouncement,
  sendAnnouncement,
  deleteAnnouncements,
  getFloorsAnnouncement,
  getTagsAnnouncement,
  getUnitsAnnouncement,
  getUnitSeen,
  getUnitSendByApp,
  getUnitSendByEmail,
  getUnitVote,
  resendEmail,
} from './communicate/announcement';
import {
  getTotalAnnouncementByAll,
  getTotalAnnouncementByDate,
  getTotalAnnouncementByLabel,
  getTotalAnnouncementByType,
} from './communicate/overall';
import {
  getRoomChat,
  getRoomChatById,
  getRoomMember,
  getMessages,
  sendMessages,
  readMessagesById,
  readMessagesAll,
  getDepartmentOfUser,
  createRoomChat
} from './communicate/residentMessage';
import {
  RESIDENT,
  LOGIN,
  LOGOUT,
  PASSWORD,
  BUILDING,
  INTERNAL_TASK,
  APARTMENT_DETAIL,
  CONFIG,
  STAFF,
  COMMUNICATE,
  APPLICATION,
  COMMON,
  REQUEST,
  PARTNER,
  ASSET,
  DEPARTMENT,
  SUPPLY,
  REGISTER,
  DOCUMENT,
  FORM_RESIDENT,
  FORM_CONTRACT_TEMPLATE,
  FORMULA,
  SURCHARGE,
  CONTRACT_TEMPLATE,
  SERVICE,
  INVOICE,
  PAYMENT,
  FORM_SERVICE_REGISTER,
  FORM_TARGET_INFO,
  FORM_STAFF,
  FORM_SERVICE,
  FORM_CONFIRM_SERVICE,
  STATISTIC,
  FORM_PAYSLIP,
  FORM_RECEIPT,
  FORM_DOCUMENT,
  FIREBASE,
  PROFILE,
  PERMISSION,
  ACTIVITY,
  DASHBOARD,
  NEWS,
  CARD,
  FORM_ASSET,
  PAGE_ASSET,
  REPORT,
  MESSAGE_NOTICE,
  RESIDENT_UTILITY,
} from '../constants/actionType';
import { deleteFile, uploadFile } from './common';
import { initApplication } from './application';
import { addPartner, deletePartner, getPartners, importPartner, updatePartner, exportPartners } from './page-asset/partner';
import {
  addAsset,
  addMaintenance,
  changeStatus,
  deleteAsset,
  getApartmentInFormAsset,
  getAssets,
  getBlockInFormAsset,
  getFloorInFormAsset,
  getPartnerInFormAsset,
  initTabAsset,
  updateAsset,
  importAsset,
  getMaintenanceUnit,
  getAssetHistory,
  exportAsset
} from './page-asset/asset';
import { getDepartment } from './department';
import {
  addSupply,
  addTransaction,
  deleteSupply,
  getEmployeeInFormSupply,
  getPartnerInFormSupply,
  getDepartmentInFormSupply,
  getSupplies,
  initPageSupply,
  updateSupply,
  getSupplyHistory,
  exportSupplies,
  importSupply,
  exportHistoryUseSupplies,
} from './page-asset/supply';
import {
  addDocument,
  deleteDocument,
  getDepartmentInPageDocument,
  getDocumentsInBuilding,
  getDocumentsMoveToBox,
  initPageDocument,
  updateDocument,
} from './document';
import { activeServiceSystem, addServiceSystem, deleteServiceSystem, inactiveServiceSystem, updateServiceSystem } from './serviceSystem';
import { initFormResident, searchApartmentInFormResident } from './formResident';
import { initFormContractTemplate } from './formContractTemplate';
import { addFormula } from './formula';
import {
  getServicesHandle,
  getServiceContractsHandle,
  getContractTemplatesHandle,
  addContractsHandle,
  getUnits,
  activeContract,
  inactiveContract,
  importContract,
  exportContract,
  updateContract,
  deleteContracts,
  downloadFileTemplateContract,
  getSumTotalContractsHandle,
  activeMultiContract,
  inactiveMultiContract,
} from './service';
import { addSurcharge } from './surcharge';
import {
  addContractTemplate,
  deleteContractTemplate,
  getContractTemplate,
  initPageContractTemplate,
  updateContractTemplate,
} from './contractTemplate';
import {
  createBillAuto,
  createBillManual,
  deleteBills,
  getBillInPageInvoice,
  getContractLatest,
  getInvoiceById,
  getInvoices,
  getSumPayment,
  getSumPaymentLatest,
  initPageInvoice,
  previewInvoice,
  printInvoice,
  rePrintInvoice,
  reSendEmailInvoice,
  sendEmailInvoice,
  updateInterest,
  getBillForCheckingAllInvoice,
  getInvoiceForCheckingAll,
  getContractLatestForCheckingAll,
  previewInvoiceDebt,
  printInvoiceDebt,
  sendEmailInvoiceDebt,
} from './invoice';
import { createReceiptInPagePayment, getBillByGroupTargetPagePayment, getSumPaymentInPagePayment, initPaymentPage } from './payment';
import { searchApartmentInFormServiceRegister, searchResidentInFormServiceRegister } from './formServiceRegister';
import { addFloor, deleteFloor, getFloorInPageConfig, updateFloor } from './floor';
import { ENTERPRISE } from '../constants';
import { addEnterprise, deleteEnterprise, updateEnterprise } from './enterprise';
import { getTargetInFormTargetInfo } from './formTargetInfo';
import {
  deleteDepartment,
  updateDepartment,
  addDepartment,
  initPositionConfigPage,
  getDepartments,
  addPosition,
  updatePosition,
  deletePosition,
  getPosition,
} from './config/position';
import { initFormStaff } from './formStaff';
import {
  getContractTemplateByFormula,
  getContractTemplateByPromotion,
  getContractTemplateBySurcharge,
  initFormService,
} from './formService';
import { initFormConfirmService } from './formConfirmService';
import { addLabel, deleteLabel, updateLabel, getLabel, getLabelGroup, getLabelTree } from './config/label';
import { getAccount, addAccount, updateAccount, deleteAccount } from './config/account';
import { initDataReport } from './statistic/initDataReport';
import { initFormPaySlip, searchApartmentInFormPaySlip, getReceiverSuggestions } from './formPaySlip';
import { initFormReceipt, searchApartmentInFormReceipt, getPayerSuggestions } from './formReceipt';
import { exportCashBookInPageReport, getDataCashBook, initDataCashBook } from './statistic/cashBook';
import {
  getSumTotalPayments,
  getPaySlip,
  addPaySlip,
  updatePaySlip,
  cancelPaySlip,
  getPaySlipPreview,
  exportPaySlip,
  getTag,
} from './statistic/paySlip';
import { exportServiceSurchargeInPageReport, getDataServiceCharge, getListApartmentUseService } from './statistic/serviceCharge';
import {
  exportAccountInPageReport,
  getAccountHistory,
  getDataAccountReport,
  getSumTotalPaymentListApartmentWithBalance,
} from './statistic/account';
import { exportDebtInPageReport, getDataDebtReport, getSumTotalPaymentListBillUnpaid, initTabDebtPageReport } from './statistic/debt';
import { getReportService, exportReportService, getSumPaymentReportService } from './statistic/service';
import { getPaymentOnlineOrder } from './statistic/paymentOnline';
import { searchApartmentInFormDocument } from './formDocument';
import { getListTemplateBuilding, getTemplateBuilding, saveTemplateBuilding } from './config/template';
import { sendDeviceToken } from './firebase/fcm';
import { getProfile, updatePasswordInProfile, updateProfile } from './profile';
import { getActivityLog, getManagers, exportActivityLog } from './activity';
import { getPermission } from './permission';
import { getOverview, getRevenue, getTotalPaidAndUnpaid, getResident, getServices, getVehicles, getBuildingsForDashboard } from './dashboard';
import { getListInvestor, getListNews, addNews, getNewsById, deleteNews } from './home/news';
import { initFormAsset, searchMaintenanceUnit, searchSupplier, searchUnit } from './page-asset/formAsset';
import { initPageAsset } from './page-asset';

import {
  addCard,
  updateCard,
  getCardList,
  deleteCard,
  exportCard,
  getCardHistory,
  importCard,
  updateCardStatus,
  getUnits as getUnitsInCard,
  getCardsSuggest,
  addRegister,
  getResidents,
  getVehicles as getVehiclesCard,
  getServices as getServicesCard,
  getRegisters,
  deleteRegister,
  printRegister,
  rePrintRegister,
  previewRegister,
  getContractTemplates,
  exportRegister,
  getCardUsageHistory,
  getCountryCodes
} from './card';

import {
  getRequests,
  getRequestDetail,
  getEmployeesInRequest,
  getDepartmentsInRequest,
  getApartmentsInRequest,
  addRequest,
  updateRequest,
  duplicateRequest,
  exportDataRequest,
  cancelRequest,
  getTotalTabs,
  approvalRequest,
  denyRequest,
  getTasks,
  addTask,
  updateTask,
  changeProgressTask,
  getActivitiesLogRequest,
  getTimelineRequest,
  changeProgressRequest,
} from './request';
import { exportDebts, getDebts, getTotalDebts } from './report/debt';
import { exportAdvancePayment, getAdvancePayment, getTotalAdvancePayment } from './report/advancePayment';
import { exportInvestor, getInvestor, getTotalInvestor } from './report/investor';
import { initPageReport } from './report';
import { countBages, countBagesRequest, countBagesUtilityBooking } from './messageNotice';
import { exportMustPayInPageReport, getDataMustPayReport, getSumTotalPaymentMustPay } from './report/mustPay';
import { exportOperating, getDataOperatingReport } from './report/operating';
import {
  getUtility,
  addUtility,
  getIconList,
  getUtilityDetail,
  updateUtility,
  deleteUtility,
  changeStatusUtility,
} from './residentUtility/utility';

import AuthDucks from 'pages/Auth/ducks';
import CashbookDucks from 'pages/Statistics/CashBook/ducks';
import { LabelDucksOperations } from 'pages/Config/Label/ducks';
import { FundDucksOperations } from 'pages/Config/PaymentAccount/ducks';
import { InvoiceBillOperations } from 'pages/Invoice/NewBill/ducks';
import { AdvancePaymentDucksOperations } from 'pages/Reports/AdvancePayment/ducks';
import { ResidentDucksOperations } from 'pages/Resident/ducks';
import { ResidentFormDucksOperations } from 'pages/Resident/ResidentForm/ducks';
import { ReportStoredDucksOperations } from 'pages/Reports/ReportStored/ducks';
import { OperatingRevenueReportDucksOperations } from 'pages/Reports/OperatingRevenueNew/ducks';
import { watchBuilding } from 'pages/Home/Building/ducks';
import { watchBuildingForm } from 'pages/Home/Building/BuildingForm/ducks';
import { LockDataFormDucksOperations } from 'pages/Home/Building/BuildingForm/LockDataForm/ducks';
import { watchPaymentOnline } from 'pages/Statistics/PaymentOnline/ducks';
import { watchStatisticServiceDebtByMonth } from 'pages/Statistics/Debt/ServiceByMonths/ducks';
import { watchStatisticServiceUpdateUntilCurrentTime } from 'pages/Statistics/Debt/ServiceUpdateUntilCurrentTime/ducks';
import { watchReceipt } from 'pages/Statistics/Receipt/ducks';
import { operations as PaymentSlipOperations } from 'pages/Statistics/PaymentSlip/ducks';
import { ServiceDucksOperations } from 'shared/serviceEntity/ducks';
import { SendBillOperations } from 'pages/Invoice/SendBill/ducks';
import { operations as AuthorizationOperations } from 'pages/Config/Authorization/ducks';
import { operations as StaffOperations } from 'pages/Staff/ducks';
import { watchAmenities } from 'pages/Config/Amenities/ducks';
import { watchAmenitiesOrder } from 'pages/Amenities/ducks';
import { watchAmenitiesOrderDetail } from 'pages/AmenitiesDetail/ducks';
import { watchAmenitiesOrderConfirmChangeStatus } from 'pages/AmenitiesOrderConfirmChangeStatus/ducks';
import { operations as PaymentOperations } from 'pages/Payment/Content/ducks';
import { getInfoPaymentApartmentDetail } from 'saga/apartmentDetail';
import { watchService } from 'pages/Config/Service/ducks';
import { watchAmenityMerchantOrder } from 'pages/AmenityMerchant/ducks';
import { watchAmenityMerchantOrderDetail } from 'pages/AmenityMerchant/Detail/ducks';
import { watch as watchRegistrationFormCovidTest } from 'pages/ResidentRegistrationForm/CovidTest/ducks';
import { watch as watchRegistrationFormCovidVaccination } from 'pages/ResidentRegistrationForm/CovidVaccination/ducks';
import { watch as watchRegistrationFormSetting } from 'pages/ResidentRegistrationForm/ducks';
import { watch as watchRegistrationFormReceivePackage } from 'pages/ResidentRegistrationForm/ReceivePackage/ducks';
import { watch as watchAccessManagement } from 'pages/ResidentRegistrationForm/AccessManagement/ducks';
import { watch as watchEvent } from 'pages/Communication/Event/ducks';

export default function* watch() {
  yield all([
    AuthDucks.watch(),
    CashbookDucks.watch(),
    LabelDucksOperations.watch(),
    FundDucksOperations.watch(),
    AdvancePaymentDucksOperations(),
    ReportStoredDucksOperations(),
    InvoiceBillOperations(),
    SendBillOperations(),
    OperatingRevenueReportDucksOperations(),
    PaymentSlipOperations.watch(),
    ServiceDucksOperations(),
    AuthorizationOperations.watch(),
    StaffOperations.watch(),
    PaymentOperations.watch(),
    watchBuilding(),
    watchBuildingForm(),
    LockDataFormDucksOperations(),
    watchPaymentOnline(),
    watchStatisticServiceDebtByMonth(),
    watchStatisticServiceUpdateUntilCurrentTime(),
    watchReceipt(),
    ResidentDucksOperations(),
    ResidentFormDucksOperations(),
    watchAmenities(),
    watchAmenitiesOrder(),
    watchAmenitiesOrderDetail(),
    watchAmenitiesOrderConfirmChangeStatus(),
    watchService(),
    watchAmenityMerchantOrder(),
    watchAmenityMerchantOrderDetail(),
    watchRegistrationFormCovidTest(),
    watchRegistrationFormCovidVaccination(),
    watchRegistrationFormSetting(),
    watchRegistrationFormReceivePackage(),
    watchAccessManagement(),
    watchEvent(),
    takeLatest(APPLICATION.INIT.REQUEST, initApplication),
    takeLatest(PERMISSION.GETS.REQUEST, getPermission),

    takeLatest(APARTMENT.INIT.REQUEST, initApartmentPage),
    takeLatest(APARTMENT.GETS.REQUEST, getDataApartmentPage),
    takeLatest(FLOOR.GETS.REQUEST, getFloors),
    takeLatest(APARTMENT.EXPORT.REQUEST, exportApartment),

    takeLatest(APARTMENT_DETAIL.INIT.REQUEST, initApartmentDetail),
    takeLatest(APARTMENT_DETAIL.APARTMENT_INFO.GET.REQUEST, getApartmentById),
    takeLatest(APARTMENT_DETAIL.RESIDENT.GETS_BY_APARTMENT.REQUEST, getResidentsByApartment),
    takeLatest(APARTMENT_DETAIL.RESIDENT.ADD.REQUEST, addResidentToApartment),
    takeLatest(APARTMENT_DETAIL.RESIDENT.UPDATE.REQUEST, updateResidentToApartment),
    takeLatest(APARTMENT_DETAIL.RESIDENT.DELETE.REQUEST, deleteResidentToApartment),
    takeLatest(APARTMENT_DETAIL.DOCUMENT.GETS_BY_APARTMENT.REQUEST, getDocumentsByApartment),
    takeLatest(APARTMENT_DETAIL.DOCUMENT.ADD.REQUEST, addDocumentsToApartment),
    takeLatest(APARTMENT_DETAIL.DOCUMENT.DELETE.REQUEST, deleteDocumentToApartment),
    takeLatest(APARTMENT_DETAIL.DOCUMENT.UPDATE.REQUEST, updateDocumentToApartment),
    takeLatest(APARTMENT_DETAIL.WALLET.GETS.REQUEST, getWalletApartmentInApartment),
    takeLatest(APARTMENT_DETAIL.BILL.GETS.REQUEST, getBillUnpaidInPageApartmentDetail),
    takeLatest(APARTMENT_DETAIL.RECEIPT.CREATE.REQUEST, createReceiptInPageApartmentDetail),
    takeLatest(APARTMENT_DETAIL.QRCODE.GET.REQUEST, getQRCocdeApartmentDetail),
    takeLatest(APARTMENT_DETAIL.QRCODE.REFRESH.REQUEST, refreshQRCocdeApartmentDetail),
    takeLatest(APARTMENT_DETAIL.QRCODE.EXPORT.REQUEST, exportQRCocdeApartmentDetail),
    takeLatest(APARTMENT_DETAIL.PAYMENT_INFO_GET.REQUEST, getInfoPaymentApartmentDetail),

    takeLatest(APARTMENT_DETAIL.SERVICE_HISTORY.INIT.REQUEST, initServicePageApartmentDetail),
    takeLatest(APARTMENT_DETAIL.SERVICE_HISTORY.GETS.REQUEST, getServiceHistoryPageApartmentDetail),

    takeLatest(APARTMENT_DETAIL.PAYMENT_HISTORY.GETS.REQUEST, getPaymentHistoryPageApartmentDetail),
    takeLatest(APARTMENT_DETAIL.PAYMENT_HISTORY.PREVIEW.REQUEST, getPaymentHistoryPreview),

    takeLatest(DOCUMENT.INIT.REQUEST, initPageDocument),
    takeLatest(DOCUMENT.DEPARTMENT.GETS.REQUEST, getDepartmentInPageDocument),
    takeLatest(DOCUMENT.GETS.REQUEST, getDocumentsInBuilding),
    takeLatest(DOCUMENT.GETS_IN_MOVE_TO_BOX.REQUEST, getDocumentsMoveToBox),
    takeLatest(DOCUMENT.ADD.REQUEST, addDocument),
    takeLatest(DOCUMENT.UPDATE.REQUEST, updateDocument),
    takeLatest(DOCUMENT.DELETE.REQUEST, deleteDocument),
    takeLatest(APARTMENT_DETAIL.SERVICE.INIT.REQUEST, initServicePageApartmentDetail),

    takeLatest(CONFIG.APARTMENT.INIT.REQUEST, initApartmentConfigPage),
    takeLatest(CONFIG.FLOOR.GETS.REQUEST, getFloorInPageConfig),
    takeLatest(FLOOR.ADD.REQUEST, addFloor),
    takeLatest(FLOOR.UPDATE.REQUEST, updateFloor),
    takeLatest(FLOOR.DELETE.REQUEST, deleteFloor),

    takeLatest(CONFIG.APARTMENT.GETS.REQUEST, getApartmentByPageInConfig),
    takeLatest(CONFIG.APARTMENT.ADD.REQUEST, addApartment),
    takeLatest(CONFIG.APARTMENT.IMPORT.REQUEST, importApartment),
    takeLatest(CONFIG.APARTMENT.EXPORT_APARTMENT.REQUEST, exportApartmentInPageConfig),
    takeLatest(CONFIG.APARTMENT.UPDATE.REQUEST, updateApartment),
    takeLatest(CONFIG.APARTMENT.DELETE.REQUEST, deleteApartment),

    takeLatest(CONFIG.POSITION.INIT.REQUEST, initPositionConfigPage),
    takeLatest(CONFIG.DEPARTMENT.INIT.REQUEST, initDepartmentConfigPage),
    takeLatest(CONFIG.DEPARTMENT.GETS.REQUEST, getDepartments),
    takeLatest(CONFIG.DEPARTMENT.ADD.REQUEST, addDepartment),
    takeLatest(CONFIG.DEPARTMENT.UPDATE.REQUEST, updateDepartment),
    takeLatest(CONFIG.DEPARTMENT.DELETE.REQUEST, deleteDepartment),
    takeLatest(CONFIG.POSITION.GETS.REQUEST, getPosition),
    takeLatest(CONFIG.POSITION.ADD.REQUEST, addPosition),
    takeLatest(CONFIG.POSITION.UPDATE.REQUEST, updatePosition),
    takeLatest(CONFIG.POSITION.DELETE.REQUEST, deletePosition),

    takeLatest(CONFIG.LABEL.GETS.REQUEST, getLabel),
    takeLatest(CONFIG.LABEL.ADD.REQUEST, addLabel),
    takeLatest(CONFIG.LABEL.DELETE.REQUEST, deleteLabel),
    takeLatest(CONFIG.LABEL.UPDATE.REQUEST, updateLabel),
    takeLatest(CONFIG.LABEL.GROUP_GETS.REQUEST, getLabelGroup),
    takeLatest(CONFIG.LABEL.TREE_GETS.REQUEST, getLabelTree),

    takeLatest(CONFIG.TEMPLATE.GETS.REQUEST, getListTemplateBuilding),
    takeLatest(CONFIG.TEMPLATE.SAVE.REQUEST, saveTemplateBuilding),
    takeLatest(CONFIG.TEMPLATE.GET_BY_NAME.REQUEST, getTemplateBuilding),

    takeLatest(CONFIG.ACCOUNT.GETS.REQUEST, getAccount),
    takeLatest(CONFIG.ACCOUNT.ADD.REQUEST, addAccount),
    takeLatest(CONFIG.ACCOUNT.UPDATE.REQUEST, updateAccount),
    takeLatest(CONFIG.ACCOUNT.DELETE.REQUEST, deleteAccount),

    takeLatest(CONFIG.TAG.INIT.REQUEST, initTagConfigPage),
    takeLatest(CONFIG.TAG.ADD.REQUEST, addTag),
    takeLatest(CONFIG.TAG.UPDATE.REQUEST, updateTag),
    takeLatest(CONFIG.TAG.DELETE.REQUEST, deleteTag),

    takeLatest(CONFIG.BLOCK.INIT.REQUEST, initBlockConfigPage),
    takeLatest(CONFIG.BLOCK.ADD.REQUEST, addBlock),
    takeLatest(CONFIG.BLOCK.UPDATE.REQUEST, updateBlock),
    takeLatest(CONFIG.BLOCK.DELETE.REQUEST, deleteBlock),

    takeLatest(FORM_SERVICE.INIT.REQUEST, initFormService),
    takeLatest(FORM_SERVICE.CONTRACT_TEMPLATE.FORMULA_GETS.REQUEST, getContractTemplateByFormula),
    takeLatest(FORM_SERVICE.CONTRACT_TEMPLATE.SURCHARGE_GETS.REQUEST, getContractTemplateBySurcharge),
    takeLatest(FORM_SERVICE.CONTRACT_TEMPLATE.PROMOTION_GETS.REQUEST, getContractTemplateByPromotion),
    takeLatest(FORM_CONFIRM_SERVICE.INIT.REQUEST, initFormConfirmService),
    takeLatest(CONFIG.SERVICE.INIT.REQUEST, initServiceConfigPage),
    takeLatest(CONFIG.SERVICE.ADD.REQUEST, addServiceSystem),
    takeLatest(CONFIG.SERVICE.UPDATE.REQUEST, updateServiceSystem),
    takeLatest(CONFIG.SERVICE.DELETE.REQUEST, deleteServiceSystem),
    takeEvery(CONFIG.SERVICE.ACTIVE.REQUEST, activeServiceSystem),
    takeEvery(CONFIG.SERVICE.INACTIVE.REQUEST, inactiveServiceSystem),
    takeLatest(CONFIG.SERVICE.BUILDING_USE_SERVICE.REQUEST, addServiceToBuilding),
    takeLatest(CONFIG.SERVICE.BUILDING_UPDATE_SERVICE.REQUEST, updateServiceToBuilding),
    takeLatest(CONFIG.SERVICE.ADDITIONAL_FEE.ADD.REQUEST, addAdditionalFee),
    takeLatest(CONFIG.SERVICE.ADDITIONAL_FEE.UPDATE.REQUEST, updateAdditionalFee),
    takeLatest(CONFIG.SERVICE.ADDITIONAL_FEE.DELETE.REQUEST, deleteAdditionalFee),
    takeLatest(CONFIG.SERVICE.ADDITIONAL_FEE.GETS.REQUEST, getAllAdditionFees),
    takeLatest(CONFIG.SERVICE.ALLOWANCE.ADD.REQUEST, addAllowance),
    takeLatest(CONFIG.SERVICE.ALLOWANCE.DELETE.REQUEST, deleteAllowance),
    takeLatest(CONFIG.SERVICE.ALLOWANCE.GETS_BY_BUILDING.REQUEST, getAllowanceByBuilding),

    takeLatest(STAFF.INIT.REQUEST, initStaffPage),
    takeLatest(STAFF.GETS.REQUEST, getStaffs),
    takeLatest(STAFF.ADD.REQUEST, addStaff),
    takeLatest(STAFF.IMPORT.REQUEST, importStaff),
    takeLatest(STAFF.EXPORT.REQUEST, exportStaff),
    takeLatest(STAFF.UPDATE.REQUEST, updateStaff),
    takeLatest(STAFF.DELETE.REQUEST, deleteStaff),
    takeLatest(FORM_STAFF.INIT.REQUEST, initFormStaff),

    takeLatest(COMMUNICATE.ANNOUNCEMENT_TAB.GETS.REQUEST, getAnnouncement),
    takeLatest(COMMUNICATE.ANNOUNCEMENT_TAB.SEND.REQUEST, sendAnnouncement),
    takeLatest(COMMUNICATE.ANNOUNCEMENT_TAB.DELETE.REQUEST, deleteAnnouncements),

    takeLatest(COMMUNICATE.UNIT_SEEN.GETS.REQUEST, getUnitSeen),
    takeLatest(COMMUNICATE.UNIT_SEND_BY_EMAIL.GETS.REQUEST, getUnitSendByEmail),
    takeLatest(COMMUNICATE.UNIT_SEND_BY_APP.GETS.REQUEST, getUnitSendByApp),
    takeLatest(COMMUNICATE.UNIT_VOTE.GETS.REQUEST, getUnitVote),
    takeLatest(COMMUNICATE.RESEND_EMAIL.GET.REQUEST, resendEmail),

    takeLatest(COMMUNICATE.OVERALL.ANNOUNCEMENT_FILTER_BY_ALL.REQUEST, getTotalAnnouncementByAll),
    takeLatest(COMMUNICATE.OVERALL.ANNOUNCEMENT_FILTER_BY_DATE.REQUEST, getTotalAnnouncementByDate),
    takeLatest(COMMUNICATE.OVERALL.ANNOUNCEMENT_FILTER_BY_LABEL.REQUEST, getTotalAnnouncementByLabel),
    takeLatest(COMMUNICATE.OVERALL.ANNOUNCEMENT_FILTER_BY_TYPE.REQUEST, getTotalAnnouncementByType),

    takeLatest(COMMUNICATE.FLOOR.GETS.REQUEST, getFloorsAnnouncement),
    takeLatest(COMMUNICATE.TAG.GETS.REQUEST, getTagsAnnouncement),
    takeLatest(COMMUNICATE.UNIT.GETS.REQUEST, getUnitsAnnouncement),

    takeLatest(COMMUNICATE.ROOM_CHAT.GETS.REQUEST, getRoomChat),
    takeLatest(COMMUNICATE.ROOM_CHAT.GET_BY_ID.REQUEST, getRoomChatById),
    takeLatest(COMMUNICATE.ROOM_CHAT.GETS_MEMBER.REQUEST, getRoomMember),
    takeLatest(COMMUNICATE.MESSAGES.GETS.REQUEST, getMessages),
    takeLatest(COMMUNICATE.MESSAGES.SEND_MESSAGE.REQUEST, sendMessages),
    takeLatest(COMMUNICATE.MESSAGES.READ_BY_ID.REQUEST, readMessagesById),
    takeLatest(COMMUNICATE.MESSAGES.READ_ALL.REQUEST, readMessagesAll),
    takeLatest(COMMUNICATE.DEPARTMENT.GETS.REQUEST, getDepartmentOfUser),
    takeLatest(COMMUNICATE.ROOM_CHAT.CREATE.REQUEST, createRoomChat),

    takeLatest(PARTNER.GETS.REQUEST, getPartners),
    takeLatest(PARTNER.ADD.REQUEST, addPartner),
    takeLatest(PARTNER.UPDATE.REQUEST, updatePartner),
    takeLatest(PARTNER.DELETE.REQUEST, deletePartner),
    takeLatest(PARTNER.IMPORT.REQUEST, importPartner),
    takeLatest(PARTNER.EXPORT.REQUEST, exportPartners),

    takeEvery(COMMON.UPLOAD_FILE.REQUEST, uploadFile),
    takeEvery(COMMON.DELETE_FILE.REQUEST, deleteFile),

    takeLatest(ASSET.INIT.REQUEST, initTabAsset),
    takeLatest(ASSET.GETS.REQUEST, getAssets),
    takeLatest(ASSET.ADD.REQUEST, addAsset),
    takeLatest(ASSET.IMPORT.REQUEST, importAsset),
    takeLatest(ASSET.UPDATE.REQUEST, updateAsset),
    takeLatest(ASSET.DELETE.REQUEST, deleteAsset),
    takeLatest(ASSET.CHANGE_STATUS.REQUEST, changeStatus),
    takeLatest(ASSET.MAINTENANCE.ADD.REQUEST, addMaintenance),
    takeLatest(ASSET.PARTNER.GETS.REQUEST, getMaintenanceUnit),
    takeLatest(ASSET.HISTORY_GETS.REQUEST, getAssetHistory),
    takeLatest(ASSET.EXPORT.REQUEST, exportAsset),

    takeLatest(SUPPLY.INIT.REQUEST, initPageSupply),
    takeLatest(SUPPLY.GETS.REQUEST, getSupplies),
    takeLatest(SUPPLY.ADD.REQUEST, addSupply),
    takeLatest(SUPPLY.UPDATE.REQUEST, updateSupply),
    takeLatest(SUPPLY.DELETE.REQUEST, deleteSupply),
    takeLatest(SUPPLY.FORM.SEARCH_EMPLOYEE.REQUEST, getEmployeeInFormSupply),
    takeLatest(SUPPLY.FORM.SEARCH_PARTNER.REQUEST, getPartnerInFormSupply),
    takeLatest(SUPPLY.FORM.SEARCH_DEPARTMENT.REQUEST, getDepartmentInFormSupply),
    takeLatest(SUPPLY.HISTORY_GETS.REQUEST, getSupplyHistory),
    takeLatest(SUPPLY.EXPORT.REQUEST, exportSupplies),
    takeLatest(SUPPLY.IMPORT.REQUEST, importSupply),
    takeLatest(SUPPLY.HISTORY_EXPORT.REQUEST, exportHistoryUseSupplies),

    takeLatest(SUPPLY.TRANSACTION.ADD.REQUEST, addTransaction),

    takeLatest(DEPARTMENT.GETS.REQUEST, getDepartment),
    takeLatest(FORM_RESIDENT.INIT.REQUEST, initFormResident),
    takeLatest(FORM_RESIDENT.SEARCH_APARTMENT.REQUEST, searchApartmentInFormResident),

    takeLatest(FORM_CONTRACT_TEMPLATE.INIT.REQUEST, initFormContractTemplate),

    takeLatest(FORM_SERVICE_REGISTER.SEARCH_UNIT.REQUEST, searchApartmentInFormServiceRegister),
    takeLatest(FORM_SERVICE_REGISTER.SEARCH_RESIDENT.REQUEST, searchResidentInFormServiceRegister),

    takeLatest(CONTRACT_TEMPLATE.INIT.REQUEST, initPageContractTemplate),
    takeLatest(CONTRACT_TEMPLATE.ADD.REQUEST, addContractTemplate),
    takeLatest(CONTRACT_TEMPLATE.UPDATE.REQUEST, updateContractTemplate),
    takeLatest(CONTRACT_TEMPLATE.GETS.REQUEST, getContractTemplate),
    takeLatest(CONTRACT_TEMPLATE.DELETE.REQUEST, deleteContractTemplate),

    takeLatest(FORMULA.ADD.REQUEST, addFormula),
    takeLatest(SURCHARGE.ADD.REQUEST, addSurcharge),

    takeLatest(SERVICE.GETS.REQUEST, getServicesHandle),
    takeLatest(SERVICE.CONTRACT.GETS.REQUEST, getServiceContractsHandle),
    takeLatest(SERVICE.CONTRACT.SUM_TOTAL.REQUEST, getSumTotalContractsHandle),
    takeLatest(SERVICE.CONTRACT.ADDS.REQUEST, addContractsHandle),
    takeLatest(SERVICE.CONTRACT.DELETE.REQUEST, deleteContracts),
    takeLatest(SERVICE.CONTRACT.UPDATE.REQUEST, updateContract),
    takeLatest(SERVICE.CONTRACT.ACTIVE.REQUEST, activeContract),
    takeLatest(SERVICE.CONTRACT.INACTIVE.REQUEST, inactiveContract),
    takeLatest(SERVICE.CONTRACT.IMPORT.REQUEST, importContract),
    takeLatest(SERVICE.CONTRACT.EXPORT.REQUEST, exportContract),
    takeLatest(SERVICE.DOWNLOAD_TEMPLATE.REQUEST, downloadFileTemplateContract),
    takeLatest(SERVICE.CONTRACT_TEMPLATE.GETS.REQUEST, getContractTemplatesHandle),
    takeLatest(SERVICE.CONTRACT.ACTIVE_MULTI.REQUEST, activeMultiContract),
    takeLatest(SERVICE.CONTRACT.INACTIVE_MULTI.REQUEST, inactiveMultiContract),

    takeLatest(INVOICE.CONTRACT.GETS.REQUEST, getContractLatest),
    takeLatest(INVOICE.CONTRACT.SUM_PAYMENT_GETS.REQUEST, getSumPaymentLatest),
    takeLatest(INVOICE.CONTRACT.CHECKING_ALL_GETS.REQUEST, getContractLatestForCheckingAll),
    // takeLatest(INVOICE.BILL.GETS.REQUEST, getBillInPageInvoice),
    takeLatest(INVOICE.BILL.SUM_PAYMENT_GETS.REQUEST, getSumPayment),
    takeLatest(INVOICE.BILL.CHECKING_ALL_GETS.REQUEST, getBillForCheckingAllInvoice),

    takeLatest(INVOICE.BILL.CREATE_AUTO.REQUEST, createBillAuto),
    takeLatest(INVOICE.BILL.CREATE_MANUAL.REQUEST, createBillManual),
    takeLatest(INVOICE.BILL.UPDATE_INTEREST.REQUEST, updateInterest),
    takeLatest(INVOICE.BILL.DELETE_MULTI.REQUEST, deleteBills),
    takeLatest(INVOICE.PREVIEW.REQUEST, previewInvoice),
    takeLatest(INVOICE.PRINT.REQUEST, printInvoice),
    takeLatest(INVOICE.REPRINT.REQUEST, rePrintInvoice),
    takeLatest(INVOICE.SEND_EMAIL.REQUEST, sendEmailInvoice),
    takeLatest(INVOICE.RESEND_EMAIL.REQUEST, reSendEmailInvoice),
    takeLatest(INVOICE.INIT.REQUEST, initPageInvoice),
    takeLatest(INVOICE.GETS.REQUEST, getInvoices),
    takeLatest(INVOICE.GET_BY_ID.REQUEST, getInvoiceById),
    takeLatest(INVOICE.CHECKING_ALL_GETS.REQUEST, getInvoiceForCheckingAll),
    takeLatest(INVOICE.PREVIEW_DEBT.REQUEST, previewInvoiceDebt),
    takeLatest(INVOICE.PRINT_DEBT.REQUEST, printInvoiceDebt),
    takeLatest(INVOICE.SEND_EMAIL_DEBT.REQUEST, sendEmailInvoiceDebt),

    takeLatest(PAYMENT.INIT.REQUEST, initPaymentPage),
    takeLatest(PAYMENT.BILL.GETS.REQUEST, getBillByGroupTargetPagePayment),
    takeLatest(PAYMENT.BILL.SUM_PAYMENT_GETS.REQUEST, getSumPaymentInPagePayment),
    takeLatest(PAYMENT.RECEIPT.CREATE.REQUEST, createReceiptInPagePayment),
    takeLatest(SERVICE.UNIT.GETS.REQUEST, getUnits),

    takeLatest(ENTERPRISE.ADD.REQUEST, addEnterprise),
    takeLatest(ENTERPRISE.UPDATE.REQUEST, updateEnterprise),
    takeLatest(ENTERPRISE.DELETE.REQUEST, deleteEnterprise),

    takeLatest(STATISTIC.INIT.REQUEST, initDataReport),

    takeLatest(STATISTIC.CASH_BOOK.INIT.REQUEST, initDataCashBook),
    takeLatest(STATISTIC.CASH_BOOK.GETS.REQUEST, getDataCashBook),
    takeLatest(STATISTIC.CASH_BOOK.EXPORT.REQUEST, exportCashBookInPageReport),

    takeLatest(STATISTIC.SERVICE_CHARGE.GETS.REQUEST, getDataServiceCharge),
    takeLatest(STATISTIC.SERVICE_CHARGE.EXPORT.REQUEST, exportServiceSurchargeInPageReport),
    takeLatest(STATISTIC.SERVICE_CHARGE.GETS_APARTMENT_USE_SERVICE.REQUEST, getListApartmentUseService),

    takeLatest(STATISTIC.ACCOUNT.GETS.REQUEST, getDataAccountReport),
    takeLatest(STATISTIC.ACCOUNT.EXPORT.REQUEST, exportAccountInPageReport),
    takeLatest(STATISTIC.ACCOUNT.SUM_TOTAL_PAYMENT.REQUEST, getSumTotalPaymentListApartmentWithBalance),
    takeLatest(STATISTIC.ACCOUNT.HISTORY.REQUEST, getAccountHistory),

    takeLatest(STATISTIC.DEBT.INIT.REQUEST, initTabDebtPageReport),
    takeLatest(STATISTIC.DEBT.GETS.REQUEST, getDataDebtReport),
    takeLatest(STATISTIC.DEBT.EXPORT.REQUEST, exportDebtInPageReport),
    takeLatest(STATISTIC.DEBT.SUM_TOTAL_PAYMENT.REQUEST, getSumTotalPaymentListBillUnpaid),

    takeLatest(FORM_PAYSLIP.INIT.REQUEST, initFormPaySlip),
    takeLatest(FORM_PAYSLIP.SEARCH_APARTMENT.REQUEST, searchApartmentInFormPaySlip),
    takeLatest(FORM_PAYSLIP.RECEIVER_SUGGESTIONS.REQUEST, getReceiverSuggestions),

    takeLatest(FORM_RECEIPT.INIT.REQUEST, initFormReceipt),
    takeLatest(FORM_RECEIPT.SEARCH_APARTMENT.REQUEST, searchApartmentInFormReceipt),
    takeLatest(FORM_RECEIPT.PAYER_SUGGESTIONS.REQUEST, getPayerSuggestions),

    takeLatest(FORM_DOCUMENT.SEARCH_APARTMENT.REQUEST, searchApartmentInFormDocument),

    takeLatest(FORM_TARGET_INFO.GETS.REQUEST, getTargetInFormTargetInfo),

    takeLatest(STATISTIC.PAY_SLIP.GETS.REQUEST, getPaySlip),
    takeLatest(STATISTIC.PAY_SLIP.ADD.REQUEST, addPaySlip),
    takeLatest(STATISTIC.PAY_SLIP.UPDATE.REQUEST, updatePaySlip),
    takeLatest(STATISTIC.PAY_SLIP.GET_PREVIEW.REQUEST, getPaySlipPreview),
    takeLatest(STATISTIC.PAY_SLIP.CANCEL.REQUEST, cancelPaySlip),
    takeLatest(STATISTIC.PAY_SLIP.EXPORT.REQUEST, exportPaySlip),
    takeLatest(STATISTIC.TAG.GETS.REQUEST, getTag),
    takeLatest(STATISTIC.PAY_SLIP.SUM_TOTAL_PAYMENT.REQUEST, getSumTotalPayments),

    takeLatest(STATISTIC.REPORT_SERVICE.GETS.REQUEST, getReportService),
    takeLatest(STATISTIC.REPORT_SERVICE.EXPORT.REQUEST, exportReportService),
    takeLatest(STATISTIC.REPORT_SERVICE.SUM_PAYMENT.REQUEST, getSumPaymentReportService),

    takeLatest(STATISTIC.PAYMENT_ONLINE.GETS.REQUEST, getPaymentOnlineOrder),

    takeLatest(REPORT.INIT.REQUEST, initPageReport),

    takeLatest(REPORT.DEBT.GETS.REQUEST, getDebts),
    takeLatest(REPORT.DEBT.GET_TOTAL.REQUEST, getTotalDebts),
    takeLatest(REPORT.DEBT.EXPORT.REQUEST, exportDebts),

    takeLatest(REPORT.ADVANCE_PAYMENT.GETS.REQUEST, getAdvancePayment),
    takeLatest(REPORT.ADVANCE_PAYMENT.GET_TOTAL.REQUEST, getTotalAdvancePayment),
    takeLatest(REPORT.ADVANCE_PAYMENT.EXPORT.REQUEST, exportAdvancePayment),

    takeLatest(REPORT.INVESTOR.GETS.REQUEST, getInvestor),
    takeLatest(REPORT.INVESTOR.GET_TOTAL.REQUEST, getTotalInvestor),
    takeLatest(REPORT.INVESTOR.EXPORT.REQUEST, exportInvestor),

    takeLatest(REPORT.MUST_PAY.GETS.REQUEST, getDataMustPayReport),
    takeLatest(REPORT.MUST_PAY.GET_TOTAL.REQUEST, getSumTotalPaymentMustPay),
    takeLatest(REPORT.MUST_PAY.EXPORT.REQUEST, exportMustPayInPageReport),

    takeLatest(FIREBASE.FCM.SEND_DEVICE_TOKEN.REQUEST, sendDeviceToken),

    takeLatest(REPORT.OPERATING.GETS.REQUEST, getDataOperatingReport),
    takeLatest(REPORT.OPERATING.EXPORT.REQUEST, exportOperating),

    takeLatest(FIREBASE.FCM.SEND_DEVICE_TOKEN.REQUEST, sendDeviceToken),
    takeLatest(PROFILE.GETS.REQUEST, getProfile),
    takeLatest(PROFILE.UPDATE.REQUEST, updateProfile),
    takeLatest(PROFILE.PASSWORD_UPDATE.REQUEST, updatePasswordInProfile),

    takeLatest(ACTIVITY.LOG.GETS.REQUEST, getActivityLog),
    takeLatest(ACTIVITY.MANAGER.GETS.REQUEST, getManagers),
    takeLatest(ACTIVITY.EXPORT.GETS.REQUEST, exportActivityLog),

    takeLatest(DASHBOARD.OVERVIEW.GETS.REQUEST, getOverview),
    takeLatest(DASHBOARD.REVENUE.GETS.REQUEST, getRevenue),
    takeLatest(DASHBOARD.TOTAL_PAID_UNPAID.GETS.REQUEST, getTotalPaidAndUnpaid),
    takeLatest(DASHBOARD.RESIDENT.GETS.REQUEST, getResident),
    takeLatest(DASHBOARD.SERVICE.GETS.REQUEST, getServices),
    takeLatest(DASHBOARD.VEHICLE.GETS.REQUEST, getVehicles),
    takeLatest(DASHBOARD.BUILDING.GETS.REQUEST, getBuildingsForDashboard),

    takeLatest(NEWS.INVESTOR.GETS.REQUEST, getListInvestor),
    takeLatest(NEWS.GETS.REQUEST, getListNews),
    takeLatest(NEWS.ADD.REQUEST, addNews),
    takeLatest(NEWS.GET_BY_ID.REQUEST, getNewsById),
    takeLatest(NEWS.DELETE.REQUEST, deleteNews),

    takeLatest(CARD.ADD.REQUEST, addCard),
    takeLatest(CARD.UPDATE.REQUEST, updateCard),
    takeLatest(CARD.GETS.REQUEST, getCardList),
    takeLatest(CARD.DELETE.REQUEST, deleteCard),
    takeLatest(CARD.EXPORT.REQUEST, exportCard),
    takeLatest(CARD.HISTORY.REQUEST, getCardHistory),
    takeLatest(CARD.HISTORY_USAGE.REQUEST, getCardUsageHistory),
    takeLatest(CARD.IMPORT.REQUEST, importCard),
    takeLatest(CARD.UPDATE_STATUS.REQUEST, updateCardStatus),
    takeLatest(CARD.UNIT.GETS.REQUEST, getUnitsInCard),
    takeLatest(CARD.SUGGEST.GETS.REQUEST, getCardsSuggest),
    takeLatest(CARD.REGISTER_ADD.REQUEST, addRegister),
    takeLatest(CARD.REGISTER_RESIDENT_GETS.REQUEST, getResidents),
    takeLatest(CARD.REGISTER_VEHICLE_GETS.REQUEST, getVehiclesCard),
    takeLatest(CARD.REGISTER_SERVICE_GETS.REQUEST, getServicesCard),
    takeLatest(CARD.REGISTER_GETS.REQUEST, getRegisters),
    takeLatest(CARD.REGISTER_DELETE.REQUEST, deleteRegister),
    takeLatest(CARD.REGISTER_PRINT.REQUEST, printRegister),
    takeLatest(CARD.REGISTER_REPRINT.REQUEST, rePrintRegister),
    takeLatest(CARD.REGISTER_PREVIEW.REQUEST, previewRegister),
    takeLatest(CARD.REGISTER_CONTRACT_TEMPLATE_GETS.REQUEST, getContractTemplates),
    takeLatest(CARD.REGISTER_EXPORT.REQUEST, exportRegister),
    takeLatest(CARD.REGISTER_COUNTRY_CODE_GETS.REQUEST, getCountryCodes),

    takeLatest(REQUEST.REQUEST_GETS.REQUEST, getRequests),
    takeLatest(REQUEST.REQUEST_EMPLOYEES_GETS.REQUEST, getEmployeesInRequest),
    takeLatest(REQUEST.REQUEST_DEPARTMENT_GETS.REQUEST, getDepartmentsInRequest),
    takeLatest(REQUEST.REQUEST_APARTMENT_GETS.REQUEST, getApartmentsInRequest),
    takeLatest(REQUEST.REQUEST_GET.REQUEST, getRequestDetail),
    takeLatest(REQUEST.REQUEST_ADD.REQUEST, addRequest),
    takeLatest(REQUEST.REQUEST_UPDATE.REQUEST, updateRequest),
    takeLatest(REQUEST.REQUEST_DUPLICATE.REQUEST, duplicateRequest),
    takeLatest(REQUEST.REQUEST_EXPORT_DATA.REQUEST, exportDataRequest),
    takeLatest(REQUEST.REQUEST_CANCEL.REQUEST, cancelRequest),
    takeLatest(REQUEST.REQUEST_CHANGE_PROGRESS.REQUEST, changeProgressRequest),
    takeLatest(REQUEST.REQUEST_TOTAL_TAB_GETS.REQUEST, getTotalTabs),
    takeLatest(REQUEST.REQUEST_APPROVE.REQUEST, approvalRequest),
    takeLatest(REQUEST.REQUEST_DENY.REQUEST, denyRequest),

    takeLatest(REQUEST.REQUEST_ACTIVITY_LOG.REQUEST, getActivitiesLogRequest),
    takeLatest(REQUEST.REQUEST_TIMELINE.REQUEST, getTimelineRequest),

    takeLatest(REQUEST.REQUEST_TASK_GETS.REQUEST, getTasks),
    takeLatest(REQUEST.REQUEST_TASK_ADD.REQUEST, addTask),
    takeLatest(REQUEST.REQUEST_TASK_UPDATE.REQUEST, updateTask),
    takeLatest(REQUEST.REQUEST_TASK_CHANGE_PROGRESS.REQUEST, changeProgressTask),

    takeLatest(FORM_ASSET.INIT.REQUEST, initFormAsset),
    takeLatest(FORM_ASSET.GET_MAINTENANCE_UNIT.REQUEST, searchMaintenanceUnit),
    takeLatest(FORM_ASSET.GET_SUPPLY.REQUEST, searchSupplier),
    takeLatest(FORM_ASSET.GET_UNIT.REQUEST, searchUnit),

    takeLatest(PAGE_ASSET.INIT.REQUEST, initPageAsset),

    takeLatest(MESSAGE_NOTICE.COUNT_BADGES.REQUEST, countBages),
    takeLatest(MESSAGE_NOTICE.COUNT_BADGES_REQUEST.REQUEST, countBagesRequest),
    takeLatest(MESSAGE_NOTICE.COUNT_BADGES_UTILITY_BOOKING.REQUEST, countBagesUtilityBooking),

    takeLatest(RESIDENT_UTILITY.UTILITY_GETS.REQUEST, getUtility),
    takeLatest(RESIDENT_UTILITY.UTILITY_ADD.REQUEST, addUtility),
    takeLatest(RESIDENT_UTILITY.UTILITY_DETAIL.REQUEST, getUtilityDetail),
    takeLatest(RESIDENT_UTILITY.UTILITY_UPDATE.REQUEST, updateUtility),
    takeLatest(RESIDENT_UTILITY.UTILITY_DELETE.REQUEST, deleteUtility),
    takeLatest(RESIDENT_UTILITY.UTILITY_CHANGE_STATUS.REQUEST, changeStatusUtility),
    takeLatest(RESIDENT_UTILITY.ICON_GETS.REQUEST, getIconList),
  ]);
}
