import { combineReducers } from 'redux';
import paginate, { initStatePagination } from 'shared/utils/paginate';
import {
  INVOICE_BILL_APARTMENT_ACCOUNT,
  INVOICE_BILL_CANCEL_INVOICE,
  INVOICE_BILL_GETS,
  INVOICE_BILL_GET_SUM,
  INVOICE_BILL_CREATE_ADJUST,
  INVOICE_BILL_UPDATE_ADJUST,
  INVOICE_BILL_FORM_CANCEL_GETS,
} from './invoiceBill.types';
import { INVOICE_BILL_CLEAR_DATA_TABLE } from './invoiceBill.constants';

export const initState = {
  dataTable: {
    ...initStatePagination,
  },
  sumPayment: {
    ...initStatePagination,
  },
  cancelInvoice: {
    ...initStatePagination,
  },
  createBillAdjust: {
    ...initStatePagination,
  },
  updateBillAdjust: {
    ...initStatePagination,
  },
  dataTableFormCancel: {
    ...initStatePagination,
  },
  apartmentAccount: {
    ...initStatePagination,
  },
};

const handleDataTable = (reducer) => (state, action) => {
  switch (action.type) {
    case INVOICE_BILL_CLEAR_DATA_TABLE: {
      return { ...initStatePagination };
    }
    default: {
      return reducer(state, action);
    }
  }
};

const reducer = combineReducers({
  dataTable: handleDataTable(paginate(INVOICE_BILL_GETS)),
  sumPayment: paginate(INVOICE_BILL_GET_SUM),
  cancelInvoice: paginate(INVOICE_BILL_CANCEL_INVOICE),
  createBillAdjust: paginate(INVOICE_BILL_CREATE_ADJUST),
  updateBillAdjust: paginate(INVOICE_BILL_UPDATE_ADJUST),
  dataTableFormCancel: paginate(INVOICE_BILL_FORM_CANCEL_GETS),
  apartmentAccount: paginate(INVOICE_BILL_APARTMENT_ACCOUNT),
});

export default reducer;
