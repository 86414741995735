import { handleError, handleResponse } from '../libs/http';
import { callApi } from './api';
import { FORMULA } from '../defined/API';
import { METHOD, X_UNIT_GROUP_ID } from '../constants/http';

export const addFormulaService = async (formula: Object, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(FORMULA, formula, METHOD.POST, {
            headers: {
                [X_UNIT_GROUP_ID]: formula.unitGroupId,
            }
        });
        return handleResponse(response, type, 'formula');
    } catch (e) {
        return handleError(e, FAIL);
    }
};