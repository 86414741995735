import {language} from '../defined/Language';

const FEMALE = 'FEMALE';
const MALE = 'MALE';
const OTHER = 'OTHER';

export const genders = [
    {
        value: 'FEMALE',
        label: language['label']['female']
    },
    {
        value: 'MALE',
        label: language['label']['male']
    },
    {
        value: 'OTHER',
        label: language['label']['other']
    },
];

export const GENDER_BY_ID = {
    [FEMALE]: language['label']['female'],
    [MALE]: language['label']['male'],
    [OTHER]: language['label']['other'],
};

export const GENDER_OBJECT = {
    [FEMALE]: {
        value: 'FEMALE',
        label: language['label']['female']
    },
    [MALE]: {
        value: 'MALE',
        label: language['label']['male']
    },
    [OTHER]: {
        value: 'OTHER',
        label: language['label']['other']
    }
};