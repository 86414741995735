import { createAction } from 'shared/utils';

const DEBT = {
  GETS: createAction('STATISTIC_SERVICE_DEBT_UNTIL_CURRENT_TIME_GETS'),
  EXPORT_DEFAULT: createAction('STATISTIC_SERVICE_DEBT_UNTIL_CURRENT_TIME_EXPORT_DEFAULT'),
  EXPORT_REVENUE: createAction('STATISTIC_SERVICE_DEBT_UNTIL_CURRENT_TIME_EXPORT_REVENUE'),
  EXPORT_DEBT: createAction('STATISTIC_SERVICE_DEBT_UNTIL_CURRENT_TIME_EXPORT_DEBT'),
  EXPORT_REMAINING_DEBT: createAction('STATISTIC_SERVICE_DEBT_UNTIL_CURRENT_TIME_EXPORT_REMAINING_DEBT'),
  TOTAL: createAction('STATISTIC_SERVICE_DEBT_UNTIL_CURRENT_TIME_TOTAL'),
};

export { DEBT };
