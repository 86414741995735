import { handleError, handleResponse } from 'libs/http';
import { callApi, downloadFile } from './api';
import {
    CASH_BOOK,
    CASH_BOOK_EXPORT, EXPORT_REPORT_SERVICE_BY_MONTH, EXPORT_REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME,
    LIST_CHARGE_HISTORY_UNIT_ACCOUNT,
    LIST_UNIT_WITH_BALANCE,
    LIST_UNIT_WITH_BALANCE_EXPORT, REPORT_SERVICE_BY_MONTH, REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME,
    SERVICE_CHARGE_EXPORT,
    SERVICE_CHARGE_GETS,
    SERVICE_CHARGE_LIST_APARTMENT_USE_SERVICE, SUM_PAYMENT_REPORT_SERVICE_BY_MONTH, TOTAL_PAYMENT_REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME, EXPORT_REPORT_REVENUE_SERVICE,
    SUM_TOTAL_PAYMENT_LIST_UNIT_WITH_BALANCE
} from 'defined/API';
import { METHOD } from '../constants';

export const getDataCashbookService = async ({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(CASH_BOOK, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type, 'result', true);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export const exportCashbookService = async ({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        let response = await downloadFile(CASH_BOOK_EXPORT, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type);
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}

// account
export async function getDataAccountReportService({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(LIST_UNIT_WITH_BALANCE, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type, 'result', true);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function exportAccountReportService({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        let response = await downloadFile(LIST_UNIT_WITH_BALANCE_EXPORT, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type);
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}

export async function getSumTotalPaymentListApartmentWithBalanceService({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(SUM_TOTAL_PAYMENT_LIST_UNIT_WITH_BALANCE, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function getAccountHistoryService({ unitId, params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(`${LIST_CHARGE_HISTORY_UNIT_ACCOUNT}/${unitId}`, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type, 'result', true);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

// service surcharge
export async function getDataServiceChargeService({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(SERVICE_CHARGE_GETS, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function exportServiceChargeService({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        let response = await downloadFile(SERVICE_CHARGE_EXPORT, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type);
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}

export async function getListApartmentUseServiceService({ serviceId, params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(`${SERVICE_CHARGE_LIST_APARTMENT_USE_SERVICE}/${serviceId}`, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type, 'result', true);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

// debt

export async function getReportServiceFromToService({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME, null, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type, 'result', true);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function getSumPaymentReportServiceFromToService({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIl',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(TOTAL_PAYMENT_REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME, null, METHOD.GET, {
            headers,
            params,
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function exportReportServiceFromToService({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIl',
}) {
    const { FAIL } = type;
    try {
        let response = await downloadFile(EXPORT_REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type);
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}

export async function exportReportRevenueServices({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIl',
}) {
    const { FAIL } = type;
    try {
        let response = await downloadFile(EXPORT_REPORT_REVENUE_SERVICE, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type);
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}


export async function getReportServiceByMonthService({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(REPORT_SERVICE_BY_MONTH, null, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type, 'result', true);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function getSumPaymentReportServiceByMonthService({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIl',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(SUM_PAYMENT_REPORT_SERVICE_BY_MONTH, null, METHOD.GET, {
            headers,
            params,
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function exportReportServiceByMonthService({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIl',
}) {
    const { FAIL } = type;
    try {
        let response = await downloadFile(EXPORT_REPORT_SERVICE_BY_MONTH, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type);
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}
