import { createAction } from 'shared/utils';

export const INVOICE_BILL_GETS = createAction('INVOICE_BILL_GETS');
export const INVOICE_BILL_GET_SUM = createAction('INVOICE_BILL_GET_SUM');
export const INVOICE_BILL_CANCEL_INVOICE = createAction('INVOICE_BILL_CANCEL_INVOICE');

export const INVOICE_BILL_RESET_STATE = 'INVOICE_BILL_RESET_STATE';
export const INVOICE_BILL_CLEAR_DATA_TABLE = 'INVOICE_BILL_CLEAR_DATA_TABLE';

export const INVOICE_BILL_CREATE_ADJUST = createAction('INVOICE_BILL_CREATE_ADJUST');
export const INVOICE_BILL_UPDATE_ADJUST = createAction('INVOICE_BILL_UPDATE_ADJUST');

export const INVOICE_BILL_FORM_CANCEL_GETS = createAction('INVOICE_BILL_FORM_CANCEL_GETS');

export const INVOICE_BILL_APARTMENT_ACCOUNT = createAction('INVOICE_BILL_APARTMENT_ACCOUNT');
