import { callApi } from './api';
import {
  RESIDENT_UTILITY_GETS,
  RESIDENT_UTILITY_ADD,
  RESIDENT_UTILITY_UPDATE,
  RESIDENT_UTILITY_DELETE,
  RESIDENT_UTILITY_CHANGE_STATUS,
  RESIDENT_UTILITY_DETAIL,
  RESIDENT_UTILITY_BOOKING_GETS,
  RESIDENT_UTILITY_BOOKING_APPROVED,
  RESIDENT_UTILITY_BOOKING_REJECTED,
  RESIDENT_UTILITY_BOOKING_CANCELED,
} from '../defined/API';
import { METHOD, X_UNIT_GROUP_ID } from '../constants';
import { handleError, handleResponse } from '../libs/http';

export async function getUtilityService(buildingId, filters, type) {
  const { FAIL } = type;
  const { page, limit } = filters;

  try {
    let response = await callApi(RESIDENT_UTILITY_GETS, {}, METHOD.GET, {
      params: filters,
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      }
    });
    return handleResponse(response, type, 'result', page, limit);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getUtilityBookingService(buildingId, filters, type) {
  const { FAIL } = type;
  const { page, limit } = filters;

  try {
    let response = await callApi(RESIDENT_UTILITY_BOOKING_GETS, {}, METHOD.GET, {
      params: filters,
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      }
    });
    return handleResponse(response, type, 'result', page, limit);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getUtilityDetailService(buildingId, id, type) {
  const { FAIL } = type;

  try {
    let response = await callApi(RESIDENT_UTILITY_DETAIL(id), {}, METHOD.GET, {
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      }
    });
    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function addUtilityService(buildingId, params, type) {
  const { FAIL } = type;

  try {
    let response = await callApi(RESIDENT_UTILITY_ADD, params, METHOD.POST, {
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      }
    });
    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function updateUtilityService(buildingId, params, type) {
  const { FAIL } = type;

  try {
    let response = await callApi(RESIDENT_UTILITY_UPDATE(params.id), params, METHOD.PUT, {
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      }
    });
    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function deleteUtilityService(buildingId, id, type) {
  const { FAIL } = type;

  try {
    let response = await callApi(RESIDENT_UTILITY_DELETE(id), {}, METHOD.DELETE, {
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      }
    });
    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function changeStatusUtilityService(buildingId, { id, status }, type) {
  const { FAIL } = type;

  try {
    let response = await callApi(RESIDENT_UTILITY_CHANGE_STATUS(id, status), {}, METHOD.PUT, {
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      }
    });
    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function approvedUtilityBooingService(buildingId, amenityBookingId, type) {
  const { FAIL } = type;

  try {
    let response = await callApi(RESIDENT_UTILITY_BOOKING_APPROVED(amenityBookingId), {}, METHOD.PUT, {
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      }
    });
    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function rejectedUtilityBooingService({ idBuilding, reason, id }, type) {
  const { FAIL } = type;

  try {
    let response = await callApi(RESIDENT_UTILITY_BOOKING_REJECTED(id), { reason }, METHOD.PUT, {
      headers: {
        [X_UNIT_GROUP_ID]: idBuilding,
      }
    });
    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function canceledUtilityBooingService({ idBuilding, reason, id }, type) {
  const { FAIL } = type;

  try {
    let response = await callApi(RESIDENT_UTILITY_BOOKING_CANCELED(id), { reason }, METHOD.PUT, {
      headers: {
        [X_UNIT_GROUP_ID]: idBuilding,
      }
    });
    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}
