import { language } from 'defined/Language';

export const LABEL_ALL = 'ALL';
export const LABEL_CUSTOMER_CODE = 'CUSTOMER_CODE';
export const LABEL_ACCOUNTING = 'ACCOUNTING';

export const LABEL_TYPE_OPTIONS = [
  { id: 'ACCOUNTING', name: language.label.accounting },
  { id: 'CUSTOMER_CODE', name: language.label.customer_code },
  { id: 'INTERNAL', name: language.label.internal },
  { id: 'ALL', name: language.label.all },
];
