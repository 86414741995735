import React from 'react';

export default function ErrorPage() {
    return <div id="notfound">
        <div className="notfound">
            <div className="notfound-404">
                <h1>500</h1>
            </div>
            <h2>We are sorry!</h2>
            <p>The page you are looking for might have been removed had its name changed or is temporarily
                unavailable.</p>

            <a href="/">Back To Homepage</a>
        </div>
    </div>;
}