import { call, put } from 'redux-saga/effects';
import { NEWS } from '../../constants/actionType';
import {
  getListInvestorService,
  addNewsService,
  getListNewsService,
  getNewsByIdService,
  deleteNewsService,
} from '../../services/news';
import { pick } from 'lodash';

export function* getListInvestor(action) {

  const result = yield call(getListInvestorService, NEWS.INVESTOR.GETS);

  yield put(result);
}

export function* getListNews(action) {
  const { payload } = action;

  const params = pick(payload, ['investorId', 'unitGroupIds', 'keyword', 'symbol', 'page', 'limit']);

  const result = yield call(getListNewsService, { params }, NEWS.GETS);

  yield put(result);
}

export function* addNews(action) {
  const { payload } = action;

  const body = pick(payload, ['investorId', 'unitGroupIds', 'title', 'description', 'thumbnail', 'content']);

  const result = yield call(addNewsService, { body }, NEWS.ADD);

  yield put(result);
}

export function* deleteNews(action) {
  const { payload } = action;

  const body = pick(payload, ['newsIds']);

  const result = yield call(deleteNewsService, { body }, NEWS.DELETE);

  yield put(result);
  
  yield put({ type: NEWS.DELETE.REFRESH });
}

export function* getNewsById(action) {
  const { payload } = action;

  const query = pick(payload, ['newsId']);

  const result = yield call(getNewsByIdService, { query }, NEWS.GET_BY_ID);

  yield put(result);
}