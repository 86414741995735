import { PERMISSION, X_UNIT_GROUP_ID } from '../constants';
import { getPermissionService } from '../services';
import {call, put} from 'redux-saga/effects';

export function* getPermission(action) {
    const {
        unitGroupId
    } = action.payload;

    const params = {
        unitGroupId,
    };
    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId
    };

    const result = yield call(getPermissionService, { params, headers }, PERMISSION.GETS);
    yield put(result);
    yield put({
        type: PERMISSION.GETS.REFRESH
    });
}