// @flow

type State = {
    isFetching: boolean,
    message?: string,
    code?: number,
    codeLanguage?: number,
}

export const initStateFetching = {
    isFetching: false,
};
const fetching = (types: Object) => {
    const {REQUEST, SUCCESS, FAIL, REFRESH} = types;
    const updateFetching = (state: State = initStateFetching, action: Object) => {
        switch (action.type) {
        case REQUEST:
            return {
                code: undefined,
                codeLanguage: undefined,
                isFetching: true
            };
        case SUCCESS:
        case FAIL:
            return {
                isFetching: false,
                code: action.payload.code,
                codeLanguage: action.payload.codeLanguage,
                message: action.payload.message,
            };
        case REFRESH:
            return {
                isFetching: false,
                code: undefined,
                codeLanguage: undefined,
            };
        default:
            return state;
        }
    };

    return (state: State = initStateFetching, action: Object) => {
        switch (action.type) {
        case REQUEST:
        case SUCCESS:
        case FAIL:
        case REFRESH:
            return {
                ...state,
                ...updateFetching(state, action)
            };
        default:
            return state;
        }
    };
};

export default fetching;