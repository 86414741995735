import { language } from 'defined/Language';

export const SERVICE_DEBT_TYPE = 'SERVICE';
export const SERVICE_DEBT_TYPE_ARISE_BY_MONTH = 'SERVICE_DEBT_ARISE_BY_MONTH';

export const DEBT_VIEW_TYPES = [
  { id: SERVICE_DEBT_TYPE, name: language.label.update_to_the_time_of_viewing },
  { id: SERVICE_DEBT_TYPE_ARISE_BY_MONTH, name: language.label.arise_by_month },
];

export const PERIODS = [
  { id: 'OPENING', name: language.label.opening },
  { id: 'IN_PERIOD', name: language.label.in_period },
  { id: 'CLOSING', name: language.label.closing },
];

export const DETAIL_PERIODS = [
  { id: 'DEBT', name: language.label.debt },
  { id: 'PAID_BEFORE', name: language.label.paid_before },
  { id: 'MUST_PAY', name: language.label.must_pay },
  { id: 'PAID', name: language.label.paid },
  { id: 'REST', name: language.label.remaining_receivables },
];

export const DETAIL_PAIDS = [
  { id: 'DEDUCT', name: language.label.deduct },
  { id: 'PAID_IN_PERIOD', name: language.label.paid_in_period },
  { id: 'PAID_DEBT', name: language.label.must_pay },
  { id: 'PAID_BEFORE', name: language.label.paid },
  { id: 'TOTAL', name: language.label.total },
];
