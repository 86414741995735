export const placeTypeOptions = [
    {
        id: 'building',
        name: 'Building',
    }, {
        id: 'floor',
        name: 'Floor',
    }, {
        id: 'apartment',
        name: 'Apartment',
    }, {
        id: 'block',
        name: 'Block',
    }
];