import { call, put } from 'redux-saga/effects';
import { REPORT, X_UNIT_GROUP_ID } from '../../constants';
import { exportAdvancePaymentService, getAdvancePaymentService, getAdvancePaymentTotalService } from '../../services/report';

export function* getAdvancePayment(action) {
    const {
        page,
        filterObject,
        unitGroupId,
    } = action.payload;

    const params = {
        ...filterObject,
        page,
        limit: 20,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(getAdvancePaymentService, { params, headers }, REPORT.ADVANCE_PAYMENT.GETS);
    yield put(result);
}

export function* getTotalAdvancePayment(action) {
    const {
        filterObject,
        unitGroupId,
    } = action.payload;

    const params = {
        ...filterObject,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(getAdvancePaymentTotalService, { params, headers }, REPORT.ADVANCE_PAYMENT.GET_TOTAL);
    yield put(result);
}

export function* exportAdvancePayment(action) {
    const {
        filterObject,
        unitGroupId,
    } = action.payload;

    const params = {
        ...filterObject,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(exportAdvancePaymentService, { params, headers }, REPORT.ADVANCE_PAYMENT.EXPORT);
    yield put(result);

    yield put({
        type: REPORT.ADVANCE_PAYMENT.EXPORT.REFRESH,
    });
}