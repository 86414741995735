let global = {
    alert: undefined,
    limitSizePerPage: 200
};

export default {
    get: get,
    set: set
};

export function get() {
    return global;
}

export function set(newGlobal) {
    global = newGlobal;
}