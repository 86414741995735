export const CH_INSTALLATION = 'ch_installation';

export const MSG_NOTICE = 'msg_notice';

export const NOTICE_TPYE = {
  MSG: 'MSG_NOTICE',
  PAYMENT: 'PAYMENT_NOTICE',
  UTILITY_BOOKING: 'BOOKING_NOTICE',
  ADD: 'ADD_NOTICE',
  REQUEST: 'REQUEST_NOTICE',
}