import { FUND_GETS, FUND_ADD, FUND_UPDATE, FUND_DELETE } from './fund.types';
import fetching, { initStateFetching } from 'shared/utils/fetching';
import paginate, { initStatePagination } from 'shared/utils/paginate';
import { combineReducers } from 'redux';

export const initState = {
  data: {
    ...initStatePagination,
  },
  fundAdd: {
    ...initStateFetching,
  },
  fundUpdate: {
    ...initStateFetching,
  },
  fundDelete: {
    ...initStateFetching,
  },
};

const data = paginate(FUND_GETS);
const fundAdd = fetching(FUND_ADD);
const fundUpdate = fetching(FUND_UPDATE);
const fundDelete = fetching(FUND_DELETE);

export default combineReducers({
  data,
  fundAdd,
  fundUpdate,
  fundDelete,
});
