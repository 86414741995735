import { COMMUNICATE } from '../constants/actionType';
import { combineReducers } from 'redux';
import paginate, { initStatePagination } from './paginate';
import fetching, { initStateFetching } from './fetching';
import { findIndex, remove, uniq, find } from 'lodash';

export const initState = {
  announcementData: { ...initStatePagination },
  announcementSend: { ...initStateFetching },
  announcementTotalFilterByType: { ...initStatePagination },
  announcementTotalFitlerByDate: { ...initStatePagination },
  announcementTotalFilterByAll: { ...initStatePagination },
  announcementTotalFilterByLabel: { ...initStatePagination },
  unitSeen: { ...initStatePagination },
  unitSendByApp: { ...initStatePagination },
  unitSeenByEmail: { ...initStatePagination },
  unitVote: { ...initStatePagination },
  resendEmail: {
    ...initStateFetching
  },
  roomChatCreate: {
    code: undefined,
    codeLanguage: undefined,
    isFetching: false,
    data: undefined
  },
};

const roomChatData = paginate(COMMUNICATE.ROOM_CHAT.GETS);
const handleRoomChatData = reducer => (state, action) => {
  switch (action.type) {
    case COMMUNICATE.ROOM_CHAT.GETS.SUCCESS:
      let roomData = [...state.data, ...action.payload.result];
      let roomIds = uniq(roomData.map(o => o.roomId));
      roomData = roomIds.map(roomId => find(roomData, o => o.roomId == roomId));
      return {
        ...state,
        isFetching: false,
        code: action.payload.code,
        loadMore: action.payload.loadMore,
        data: roomData,
      }
    case COMMUNICATE.ROOM_CHAT.GETS.REFRESH:
      return {
        ...initStatePagination
      }
    case COMMUNICATE.ROOM_CHAT.READ:
      return {
        ...state,
        data: state.data.map(item => {
          if (item.roomId === action.payload.roomId) {
            return {
              ...item,
              unread: 0
            };
          }
          return item;
        })
      }
    case COMMUNICATE.ROOM_CHAT.GET_BY_ID.SUCCESS: {
      let indexUpdated = findIndex(state.data, { roomId: action.payload.result[0].roomId });
      if (indexUpdated !== -1) {
        remove(state.data, { roomId: action.payload.result[0].roomId });
      }
      return {
        ...state,
        code: action.payload.code,
        data: [...action.payload.result, ...state.data]
      }
    }
    default:
      return reducer(state, action);
  }
};

const messagesData = paginate(COMMUNICATE.MESSAGES.GETS);
const handleMessagesData = reducer => (state, action) => {
  switch (action.type) {
    case COMMUNICATE.MESSAGES.GETS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        code: action.payload.code,
        loadMore: action.payload.loadMore,
        data: [...action.payload.result, ...state.data]
      }
    case COMMUNICATE.MESSAGES.GETS.REFRESH:
      return {
        ...initStatePagination
      }
    case COMMUNICATE.MESSAGES.HAS_NEW_MESSAGE:
      return {
        ...state,
        isFetching: false,
        code: action.payload.code,
        data: [...state.data, action.payload.data]
      }
    default:
      return reducer(state, action);
  }
};

const reducer = combineReducers({
  announcementData: paginate(COMMUNICATE.ANNOUNCEMENT_TAB.GETS),
  announcementSend: fetching(COMMUNICATE.ANNOUNCEMENT_TAB.SEND),
  announcementDelete: fetching(COMMUNICATE.ANNOUNCEMENT_TAB.DELETE),
  floors: paginate(COMMUNICATE.FLOOR.GETS, 'floors'),
  tags: paginate(COMMUNICATE.TAG.GETS),
  units: paginate(COMMUNICATE.UNIT.GETS),
  departments: paginate(COMMUNICATE.DEPARTMENT.GETS),
  roomChatData: handleRoomChatData(roomChatData),
  roomMemberData: paginate(COMMUNICATE.ROOM_CHAT.GETS_MEMBER),
  messagesData: handleMessagesData(messagesData),
  messageSend: fetching(COMMUNICATE.MESSAGES.SEND_MESSAGE),
  messageReadById: fetching(COMMUNICATE.MESSAGES.READ_BY_ID),
  messageReadAll: fetching(COMMUNICATE.MESSAGES.READ_ALL),
  announcementTotalFilterByType: paginate(COMMUNICATE.OVERALL.ANNOUNCEMENT_FILTER_BY_TYPE),
  announcementTotalFitlerByDate: paginate(COMMUNICATE.OVERALL.ANNOUNCEMENT_FILTER_BY_DATE),
  announcementTotalFilterByAll: paginate(COMMUNICATE.OVERALL.ANNOUNCEMENT_FILTER_BY_ALL),
  announcementTotalFilterByLabel: paginate(COMMUNICATE.OVERALL.ANNOUNCEMENT_FILTER_BY_LABEL),
  unitSeen: paginate(COMMUNICATE.UNIT_SEEN.GETS),
  unitSendByApp: paginate(COMMUNICATE.UNIT_SEND_BY_APP.GETS),
  unitSeenByEmail: paginate(COMMUNICATE.UNIT_SEND_BY_EMAIL.GETS),
  unitVote: paginate(COMMUNICATE.UNIT_VOTE.GETS),
  resendEmail: fetching(COMMUNICATE.RESEND_EMAIL.GET),
  roomChatCreate: (state = initState.roomChatCreate, action) => {
    switch (action.type) {
      case COMMUNICATE.ROOM_CHAT.CREATE.REQUEST: {
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          data: undefined,
          isFetching: true
        }
      }
      case COMMUNICATE.ROOM_CHAT.CREATE.SUCCESS: {
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: {
            ...action.payload.result,
          },
          isFetching: false
        }
      }
      case COMMUNICATE.ROOM_CHAT.CREATE.FAIL: {
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: undefined,
          isFetching: false
        }
      }
      case COMMUNICATE.ROOM_CHAT.CREATE.REFRESH: {
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          data: undefined,
          isFetching: false
        }
      }
      default:
        return state;
    }
  },
});

export default reducer;