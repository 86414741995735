import { language } from 'defined/Language';
import convertArrToObj from 'shared/utils/convertArrToObj';

export const APARTMENT_BUILD_STATUS_OPTIONS = [
  {
    id: 'COMPLETE',
    name: language.label.complete1,
  },
  {
    id: 'RAW',
    name: language.label.raw_building,
  },
];

export const APARTMENT_BUILD_STATUS_BY_ID = {
  COMPLETE: {
    id: 'COMPLETE',
    name: language.label.complete1,
  },
  RAW: {
    id: 'RAW',
    name: language.label.raw_building,
  },
};

export const APARTMENT_TYPE_OPTIONS = [
  {
    id: 'VILLA',
    name: language.label.villa,
  },
  {
    id: 'APARTMENT',
    name: language.label.apartment2,
  },
  {
    id: 'SERVICE',
    name: language.label.service_apartment,
  },
  {
    id: 'OWNER',
    name: language.label.owner1,
  },
  {
    id: 'RENT',
    name: language.label.rent,
  },
  {
    id: 'SHOP',
    name: language.label.shop,
  },
];

export const APARTMENT_TYPE_BY_ID = convertArrToObj(APARTMENT_TYPE_OPTIONS, 'id');

export const APARTMENT_STATUS_OPTIONS = [
  {
    id: '0',
    name: language.label.empty,
  },
  {
    id: '1',
    name: language.label.in_use,
  },
];
