import { useDispatch, useSelector } from 'react-redux';
import { useNotificationResponse, downloadFileFromUrl } from 'shared/helpers';
import { getDataCashbookAction, exportDataCashbookAction } from './cashbook.actions';
import {
  cashbookSelector,
  cashbookExportSelector,
  openingBalanceSelector,
  closeBalanceSelector,
  grossRevenueSelector,
  grossExpenseSelector,
  fundDataSelector,
  serviceDataSelector,
  rowsSelector,
  fundTotalSelector,
  serviceTotalSelector,
  totalUnitAccountRevenueSelector,
  totalUnitAccountExpenseSelector,
  otherTotalSelector,
} from './cashbook.selectors';

export const useCashbook = () => {
  const dispatch = useDispatch();

  const getDataCashbook = (unitGroupId, filterObject, page) => dispatch(getDataCashbookAction(unitGroupId, filterObject, page));
  const exportDataCashbook = (unitGroupId, filterObject) => dispatch(exportDataCashbookAction(unitGroupId, filterObject));

  const cashbookData = useSelector(cashbookSelector);
  const cashbookExport = useSelector(cashbookExportSelector);
  const openingBalance = useSelector(openingBalanceSelector);
  const closeBalance = useSelector(closeBalanceSelector);
  const otherTotal = useSelector(otherTotalSelector);
  const grossRevenue = useSelector(grossRevenueSelector);
  const grossExpense = useSelector(grossExpenseSelector);
  const fundsData = useSelector(fundDataSelector);
  const servicesData = useSelector(serviceDataSelector);
  const rows = useSelector(rowsSelector);
  const serviceTotal = useSelector(serviceTotalSelector);
  const fundTotal = useSelector(fundTotalSelector);
  const totalUnitAccountRevenue = useSelector(totalUnitAccountRevenueSelector);
  const totalUnitAccountExpense = useSelector(totalUnitAccountExpenseSelector);

  return {
    getDataCashbook,
    exportDataCashbook,

    cashbookData,
    cashbookExport,
    openingBalance,
    closeBalance,
    otherTotal,
    grossRevenue,
    grossExpense,
    fundsData,
    servicesData,
    rows,
    serviceTotal,
    fundTotal,
    totalUnitAccountRevenue,
    totalUnitAccountExpense,
  };
};

export const useExportCashbook = () => {
  const FILENAME = 'thong-tin-so-quy.xlsx';
  const { exportDataCashbook, cashbookExport } = useCashbook();

  const { data, code, codeLanguage, isFetching } = cashbookExport;

  useNotificationResponse(code, codeLanguage, null, () => downloadFileFromUrl(data, document, FILENAME));

  return {
    isFetching,
    exportDataCashbook,
  };
};
