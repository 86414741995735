import { METHOD } from 'constants/http';
import { handleError, handleResponse } from 'shared/utils';
import { callApi, downloadFile } from '../api';

const SERVER = process.env.API_URL;
const REGISTRATION_FORM_COVID_TEST_GETS = `${SERVER}/form-data/v1/covid-test`;
const REGISTRATION_FORM_COVID_TEST_EXPORT = `${SERVER}/form-data/v1/covid-test/export`;
const REGISTRATION_FORM_COVID_TEST_UPDATE = (id) => `${SERVER}/form-data/v1/covid-test/${id}/note`;
const REGISTRATION_FORM_COVID_TEST_CANCEL = (id) => `${SERVER}/form-data/v1/covid-test/${id}/cancel`;
const REGISTRATION_FORM_COVID_VACCINATION_GETS = `${SERVER}/form-data/v1/covid-vaccination`;
const REGISTRATION_FORM_COVID_VACCINATION_EXPORT = `${SERVER}/form-data/v1/covid-vaccination/export`;
const REGISTRATION_FORM_COVID_VACCINATION_UPDATE = (id) => `${SERVER}/form-data/v1/covid-vaccination/${id}/note`;
const REGISTRATION_FORM_COVID_VACCINATION_CANCEL = (id) => `${SERVER}/form-data/v1/covid-vaccination/${id}/cancel`;
const REGISTRATION_FORM_SETTING_GETS = `${SERVER}/unit-group/form-config`;
const REGISTRATION_FORM_SETTING_UPDATE = `${SERVER}/unit-group/form-config`;

export const getsCovidTestRegistrationFormService = async (
  headers,
  params,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) => {
  const { FAIL } = type;
  try {
    const response = await callApi(REGISTRATION_FORM_COVID_TEST_GETS, {}, METHOD.GET, {
      headers,
      params,
    });
    return handleResponse(response, type, 'result', 1);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const updateNoteCovidTestRegistrationFormService = async (
  headers,
  body,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) => {
  const { FAIL } = type;
  const { id, ...newBody } = body;
  try {
    const response = await callApi(REGISTRATION_FORM_COVID_TEST_UPDATE(body.id), newBody, METHOD.PUT, {
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const cancelCovidTestRegistrationFormService = async (
  headers,
  body,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) => {
  const { FAIL } = type;
  const { id, ...newBody } = body;
  try {
    const response = await callApi(REGISTRATION_FORM_COVID_TEST_CANCEL(id), newBody, METHOD.PUT, {
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const exportCovidTestRegistrationFormService = async (
  headers,
  params,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) => {
  const { FAIL } = type;
  try {
    const response = await downloadFile(REGISTRATION_FORM_COVID_TEST_EXPORT, {}, METHOD.GET, {
      headers,
      params,
    });
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const getsCovidVaccinationRegistrationFormService = async (
  headers,
  params,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) => {
  const { FAIL } = type;
  try {
    const response = await callApi(REGISTRATION_FORM_COVID_VACCINATION_GETS, {}, METHOD.GET, {
      headers,
      params,
    });
    return handleResponse(response, type, 'result', 1);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const updateNoteCovidVaccinationRegistrationFormService = async (
  headers,
  body,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) => {
  const { FAIL } = type;
  const { id, ...newBody } = body;
  try {
    const response = await callApi(REGISTRATION_FORM_COVID_VACCINATION_UPDATE(body.id), newBody, METHOD.PUT, {
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const cancelCovidVaccinationRegistrationFormService = async (
  headers,
  body,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) => {
  const { FAIL } = type;
  const { id, ...newBody } = body;
  try {
    const response = await callApi(REGISTRATION_FORM_COVID_VACCINATION_CANCEL(id), newBody, METHOD.PUT, {
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const exportCovidVaccinationRegistrationFormService = async (
  headers,
  params,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) => {
  const { FAIL } = type;
  try {
    const response = await downloadFile(REGISTRATION_FORM_COVID_VACCINATION_EXPORT, {}, METHOD.GET, {
      headers,
      params,
    });
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const getsSettingRegistrationFormService = async (
  headers,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) => {
  const { FAIL } = type;
  try {
    const response = await callApi(REGISTRATION_FORM_SETTING_GETS, {}, METHOD.GET, {
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const updateSettingRegistrationFormService = async (
  headers,
  body,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) => {
  const { FAIL } = type;
  try {
    const response = await callApi(REGISTRATION_FORM_SETTING_UPDATE, body, METHOD.POST, {
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};
