import { FLOOR_ADD, FLOOR_DELETE, FLOOR_DELETE_MULTI, FLOOR_GET_LIST, FLOOR_UPDATE } from '../defined/API';
import { callApi } from './api';
import { handleError, handleResponse } from '../libs/http';
import { METHOD, X_UNIT_GROUP_ID } from '../constants/http';

export async function getFloorsService(unitGroupId: number, type: Object = { SUCCESS: 'SUCCESS', FAIL: 'FAIL' }) {
    const { FAIL } = type;
    try {
        let response = await callApi(FLOOR_GET_LIST, {}, METHOD.GET, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            }
        });
        return handleResponse(response, type, 'floors');
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}

export async function addFloorService(floor, type: Object = { SUCCESS: 'SUCCESS', FAIL: 'FAIL' }) {
    const { FAIL } = type;
    try {
        let response = await callApi(FLOOR_ADD, floor, METHOD.POST, {
            headers: {
                [X_UNIT_GROUP_ID]: floor.unitGroupId,
            }
        });
        return handleResponse(response, type, 'floor');
    } catch (err) {
        return handleError(err, FAIL);
    }
}

export async function updateFloorService(floor, type: Object = { SUCCESS: 'SUCCESS', FAIL: 'FAIL' }) {
    const { FAIL } = type;
    try {
        let response = await callApi(`${FLOOR_UPDATE}/${floor.id}`, floor, METHOD.PUT, {
            headers: {
                [X_UNIT_GROUP_ID]: floor.unitGroupId,
            }
        });
        return handleResponse(response, type, 'floor');
    } catch (err) {
        return handleError(err, FAIL);
    }
}

export async function deleteFloorService({floorId, unitGroupId}, type: Object = { SUCCESS: 'SUCCESS', FAIL: 'FAIL' }) {
    const { FAIL } = type;
    try {
        let response = await callApi(`${FLOOR_DELETE}/${floorId}`, {}, METHOD.DELETE, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            }
        });
        return handleResponse(response, type, 'floor');
    } catch (err) {
        return handleError(err, FAIL);
    }
}

export async function deleteMultiFloorService({ ids, unitGroupId }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        let response = await callApi(FLOOR_DELETE_MULTI, {
            ids,
        }, METHOD.POST, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            }
        });
        return handleResponse(response, type, 'floors');
    } catch (e) {
        return handleError(e, FAIL);
    }
}