import { FORM_ASSET, X_UNIT_GROUP_ID } from '../../constants';
import { call, put } from 'redux-saga/effects';
import { getApartments, getPartnerService } from '../../services';

export function* initFormAsset(action) {
    const {
        unitGroupId,
        options,
    } = action.payload;

    const {
        apartment,
    } = options;

    // get unit
    let resUnit = yield call(getApartments, unitGroupId, 1, 20, {
        name: apartment
    }, FORM_ASSET.GET_UNIT);
    yield put(resUnit);

    yield put({
        type: FORM_ASSET.INIT.SUCCESS,
        payload: {
            code: 200
        }
    });
}

export function* searchUnit(action) {
    const {
        unitGroupId,
        name,
    } = action.payload;

    let resUnit = yield call(getApartments, unitGroupId, 1, 20, {
        name
    }, FORM_ASSET.GET_UNIT);
    yield put(resUnit);
}

export function* searchMaintenanceUnit(action) {
    const {
        unitGroupId,
        name,
    } = action.payload;

    let resMaintenanceUnit = yield call(getPartnerService, unitGroupId, 1, {
        name
    }, FORM_ASSET.GET_MAINTENANCE_UNIT);
    yield put(resMaintenanceUnit);
}

export function* searchSupplier(action) {
    const {
        unitGroupId,
        name,
    } = action.payload;

    let resMaintenanceUnit = yield call(getPartnerService, unitGroupId, 1, {
        name
    }, FORM_ASSET.GET_SUPPLY);
    yield put(resMaintenanceUnit);
}
