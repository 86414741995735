import { all, takeLatest, call, put } from 'redux-saga/effects';
import { addEventService, deleteEventService, getEventService, getEventsService, getUnitsReceiveEventService } from 'services/event';
import { getFloorsService } from 'services/floor';
import { getApartments as getApartmentsService } from 'services/apartment';
import { getLabelsService } from 'services/label';
import { X_UNIT_GROUP_ID } from 'shared/constants';
import {
  ACTION_TYPE_EVENT_GETS,
  ACTION_TYPE_EVENT_GET,
  ACTION_TYPE_EVENT_ADD,
  ACTION_TYPE_EVENT_DELETE,
  ACTION_TYPE_EVENT_FLOOR_GETS,
  ACTION_TYPE_EVENT_APARTMENT_GETS,
  ACTION_TYPE_EVENT_TAG_GETS,
  ACTION_TYPE_EVENT_APARTMENT_RECEIVE_EVENT_GETS,
} from './event.types';

function* getEvents(action) {
  const { buildingId, data, page, limit } = action.payload;

  const { title, labelIds, startDate, endDate } = data;

  const result = yield call(
    getEventsService,
    buildingId,
    page,
    limit,
    {
      title,
      labelIds,
      startDate,
      endDate,
    },
    ACTION_TYPE_EVENT_GETS
  );

  yield put(result);
}

function* getEvent(action) {
  const { buildingId, eventId } = action.payload;

  const result = yield call(getEventService, buildingId, eventId, ACTION_TYPE_EVENT_GET);

  yield put(result);
}

function* addEvent(action) {
  const { buildingId, event } = action.payload;

  const result = yield call(addEventService, buildingId, event, ACTION_TYPE_EVENT_ADD);

  yield put(result);
}

function* deleteEvent(action) {
  const { buildingId, eventId, refreshState } = action.payload;

  const result = yield call(deleteEventService, buildingId, eventId, ACTION_TYPE_EVENT_DELETE);
  refreshState();

  yield put(result);
  yield put({
    type: ACTION_TYPE_EVENT_DELETE.REFRESH,
  });
}

function* getFloors(action) {
  const { buildingId } = action.payload;

  const result = yield call(getFloorsService, buildingId, ACTION_TYPE_EVENT_FLOOR_GETS);

  yield put(result);
}

function* getTags(action) {
  const { buildingId, data } = action.payload;

  const options = {
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
    params: {
      ...data,
    },
  };

  const result = yield call(getLabelsService, options, ACTION_TYPE_EVENT_TAG_GETS);

  yield put(result);
}

function* getUnits(action) {
  const { buildingId, page, limit, filter } = action.payload;

  const result = yield call(getApartmentsService, buildingId, page, limit, filter, ACTION_TYPE_EVENT_APARTMENT_GETS);

  yield put(result);
}

function* getUnitsReceiveEvent(action) {
  const { buildingId, page, limit, filter, idEvent } = action.payload;

  const result = yield call(
    getUnitsReceiveEventService,
    buildingId,
    page,
    limit,
    filter,
    idEvent,
    ACTION_TYPE_EVENT_APARTMENT_RECEIVE_EVENT_GETS
  );

  yield put(result);
}

export default function* watch() {
  yield all([
    takeLatest(ACTION_TYPE_EVENT_GETS.REQUEST, getEvents),
    takeLatest(ACTION_TYPE_EVENT_GET.REQUEST, getEvent),
    takeLatest(ACTION_TYPE_EVENT_FLOOR_GETS.REQUEST, getFloors),
    takeLatest(ACTION_TYPE_EVENT_APARTMENT_GETS.REQUEST, getUnits),
    takeLatest(ACTION_TYPE_EVENT_APARTMENT_RECEIVE_EVENT_GETS.REQUEST, getUnitsReceiveEvent),
    takeLatest(ACTION_TYPE_EVENT_TAG_GETS.REQUEST, getTags),
    takeLatest(ACTION_TYPE_EVENT_ADD.REQUEST, addEvent),
    takeLatest(ACTION_TYPE_EVENT_DELETE.REQUEST, deleteEvent),
  ]);
}
