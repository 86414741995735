import * as React from 'react';
import PropTypes from 'prop-types';
import Wrapper from './Wrapper';
import Spin from '../Spin';

const Loading = (props) => {
  const { isLoading, width, height, noBackground } = props;
  return (
    isLoading && (
      <Wrapper noBackground={noBackground}>
        <Spin loading={isLoading} width={width} height={height} />
      </Wrapper>
    )
  );
};

Loading.propTypes = {
  isLoading: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  noBackground: PropTypes.bool,
};

Loading.defaultProps = {
  isLoading: false,
  width: '20px',
  height: '20px',
  noBackground: false,
};

export default Loading;
