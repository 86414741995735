import { callApi } from './api';
import { handleError, handleResponse } from '../libs/http';
import { METHOD, X_UNIT_GROUP_ID } from '../constants';
import {
  NEWS_INVESTOR_GETS,
  NEWS_ADD,
  NEWS_GETS,
  NEWS_GET_BY_ID,
  NEWS_DELETE,
} from '../defined/API';

export const getListInvestorService = async (type) => {
  const { FAIL } = type;

  try {
    const response = await callApi(NEWS_INVESTOR_GETS, {}, METHOD.GET);
    
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const getListNewsService = async ({ params }, type) => {
  const { FAIL } = type;

  try {
    const response = await callApi(NEWS_GETS, {}, METHOD.GET, { params });

    return handleResponse(response, type, 'result', params.page, params.limit);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const addNewsService = async ({ body }, type) => {
  const { FAIL } = type;

  try {
    const response = await callApi(NEWS_ADD, body, METHOD.POST);

    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const deleteNewsService = async ({ body }, type) => {
  const { FAIL } = type;

  try {
    const response = await callApi(NEWS_DELETE, body, METHOD.POST);

    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const getNewsByIdService = async ({ query }, type) => {
  const { FAIL } = type;

  const { newsId } = query;

  try {
    const response = await callApi(NEWS_GET_BY_ID(newsId), {}, METHOD.GET, {});

    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};
