import { handleError, handleResponse } from '../libs/http';
import { callApi, downloadFile } from './api';
import {
    BILL_CREATE_AUTO,
    BILL_CREATE_MANUAL,
    BILL_DELETE_MULTI, BILL_EXPORT,
    BILL_GET_LIST,
    BILL_SUM_PAYMENT, BILL_UNPAID_GET, BILL_UPDATE_INTEREST,
    BILL_GETS_LIST,
    BILL_SUM_TOTAL_PAYMENT,
    BILL_ADJUST,
    BILL_SEND_GETS,
    BILL_SEND_SUM_TOTAL_PAYMENT,
    BILL_LIST_NOT_GROUP_UNIT,
    BILL_LIST_BY_UNIT,
} from '../defined/API';
import { METHOD } from '../constants/http';

export const createBillAutoService = async ({ body, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(BILL_CREATE_AUTO, body, METHOD.POST, { headers });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const createBillManualService = async ({ body, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(BILL_CREATE_MANUAL, body, METHOD.POST, { headers });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

// New API get list bill
export const getListBillsService = async ({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(BILL_GETS_LIST, {}, METHOD.GET, { params, headers });
        return handleResponse(response, type, 'result', true);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getBillsService = async ({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(BILL_GET_LIST, {}, METHOD.GET, { params, headers });
        return handleResponse(response, type, 'result', true);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getBillUnpaidByUnitIdService = async ({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(BILL_UNPAID_GET, {}, METHOD.GET, {
            params, headers,
        });
        return handleResponse(response, type, 'result', true);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

// New API sum total payment
export const sumTotalPaymentService = async ({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(BILL_SUM_TOTAL_PAYMENT, {}, METHOD.GET, { params, headers });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const sumPaymentService = async ({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(BILL_SUM_PAYMENT, {}, METHOD.GET, { params, headers });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const updateInterestService = async ({ headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(BILL_UPDATE_INTEREST, {}, METHOD.POST, { headers });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const deleteBillService = async ({ body, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(BILL_DELETE_MULTI, body, METHOD.POST, {
            headers,
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export async function exportBillService({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        let response = await downloadFile(BILL_EXPORT, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type);
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}

export const createBillAdjustService = async ({ body, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(BILL_ADJUST, body, METHOD.POST, {
            headers,
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const updateBillAdjustService = async ({ body, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(BILL_ADJUST, body, METHOD.PUT, {
            headers,
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

// Api for Send Bill
export const getListSendBillsService = async ({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(BILL_SEND_GETS, {}, METHOD.GET, { params, headers });
        return handleResponse(response, type, 'result', true);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const sumTotalPaymentSendBillService = async ({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(BILL_SEND_SUM_TOTAL_PAYMENT, {}, METHOD.GET, { params, headers });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getListBillsNotGroupUnitService = async ({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(BILL_LIST_NOT_GROUP_UNIT, {}, METHOD.GET, { params, headers });
        return handleResponse(response, type, 'result', true);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getListBillByUnit = async ({ params, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) => {
  const { FAIL } = type;
    try {
        const response = await callApi(BILL_LIST_BY_UNIT, {}, METHOD.GET, { params, headers });
        return handleResponse(response, type, 'result', true);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

