import { SUCCESS_CODE, X_UNIT_GROUP_ID } from '../../constants';
import { STATISTIC } from '../../constants/actionType';
import { call, put, select } from 'redux-saga/effects';
import { exportCashbookService, getDataCashbookService } from 'services/cashbook';
import { convertEndOfDate, convertStartOfDate } from '../../libs/convert';
import { getAccountService } from '../../services/fund';
import { exportReceiptService, getServiceInUseService } from '../../services';

export function* initDataCashBook(action) {
    const {
        unitGroupId,
    } = action.payload;

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(getDataCashbookService, {
        params: {
            from: convertStartOfDate(new Date()),
            to: convertEndOfDate(new Date()),
            page: 1,
            limit: 20,
        }, headers,
    }, STATISTIC.CASH_BOOK.GETS);
    yield put(result);

    // const codeFund = yield select(state => state.report.funds.code);
    // if (!codeFund) {
    // const resFund = yield call(getAccountService, unitGroupId, STATISTIC.FUND.GETS);
    // yield put(resFund);
    // }

    // const codeService = yield select(state => state.report.services.code);
    // if (!codeService) {
    // const resService = yield call(getServiceInUseService, {
    //     headers,
    // }, STATISTIC.SERVICE.GETS);
    // yield put(resService);
    // }

    yield put({
        type: STATISTIC.CASH_BOOK.INIT.SUCCESS,
        payload: {
            code: 200,
        },
    });
}

export function* getDataCashBook(action) {
    const {
        unitGroupId,
        filterObject,
        page,
    } = action.payload;

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const params = {
        ...filterObject,
        page,
        limit: 20,
    };

    const result = yield call(getDataCashbookService, { params, headers }, STATISTIC.CASH_BOOK.GETS);
    yield put(result);
}

export function* exportCashBookInPageReport(action) {
    const {
        unitGroupId,
        filterObject,
    } = action.payload;
    const params = {
        ...filterObject,
    };
    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };
    const result = yield call(exportCashbookService, { params, headers }, STATISTIC.CASH_BOOK.EXPORT);
    yield put(result);
    yield put({
        type: STATISTIC.CASH_BOOK.EXPORT.REFRESH,
    });
}
