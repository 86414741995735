import { callApi, downloadFile } from './api';
// import { SERVER } from 'defined/API';
import { METHOD, X_UNIT_GROUP_ID } from 'shared/constants';
import { handleError, handleResponse } from 'shared/utils';

const SERVER = process.env.API_URL;

const ACCESS_MANAGEMENT_API_GETS = `${SERVER}/form-data/v1/visitor`;
const ACCESS_MANAGEMENT_API_EXPORT = `${SERVER}/form-data/v1/visitor/export`;
const ACCESS_MANAGEMENT_API_ADD = `${SERVER}/form-data/v1/visitor`;
const ACCESS_MANAGEMENT_API_REMOVE = (id) => `${SERVER}/form-data/v1/visitor/${id}`;
const ACCESS_MANAGEMENT_API_APPROVE = (id) => `${SERVER}/form-data/v1/visitor/${id}/approve`;
const ACCESS_MANAGEMENT_API_CANCEL = (id) => `${SERVER}/form-data/v1/visitor/${id}/cancel`;
const ACCESS_MANAGEMENT_API_IN_PROGRESS = (id) => `${SERVER}/form-data/v1/visitor/${id}/in-progress`;
const ACCESS_MANAGEMENT_API_COMPLETE = (id) => `${SERVER}/form-data/v1/visitor/${id}/complete`;

export const getAccessManagementsService = async ({ idBuilding, filter, page, limit, type }) => {
  const { FAIL } = type;

  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  const params = {
    ...filter,
    page,
    limit,
  };

  try {
    const response = await callApi(ACCESS_MANAGEMENT_API_GETS, null, METHOD.GET, { headers, params });
    return handleResponse(response, type, 'result', page);
  } catch (err) {
    return handleError(err, FAIL);
  }
};

export const exportAccessManagementsService = async ({ idBuilding, filter, type }) => {
  const { FAIL } = type;

  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  const params = {
    ...filter,
  };

  try {
    const response = await downloadFile(ACCESS_MANAGEMENT_API_EXPORT, null, METHOD.GET, { headers, params });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
};

export const addAccessManagementService = async ({ idBuilding, access, type }) => {
  const { FAIL } = type;

  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  const body = {
    ...access,
  };

  try {
    const response = await callApi(ACCESS_MANAGEMENT_API_ADD, body, METHOD.POST, { headers });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
};

export const removeAccessManagementService = async ({ idBuilding, idAccess, type }) => {
  const { FAIL } = type;

  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  try {
    const response = await callApi(ACCESS_MANAGEMENT_API_REMOVE(idAccess), null, METHOD.DELETE, { headers });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
};

export const approveAccessManagementService = async ({ idBuilding, idAccess, type }) => {
  const { FAIL } = type;

  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  try {
    const response = await callApi(ACCESS_MANAGEMENT_API_APPROVE(idAccess), null, METHOD.PUT, { headers });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
};

export const rejectAccessManagementService = async ({ idBuilding, idAccess, reason, type }) => {
  const { FAIL } = type;

  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  const body = { reason };

  try {
    const response = await callApi(ACCESS_MANAGEMENT_API_CANCEL(idAccess), body, METHOD.PUT, { headers });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
};

export const inProgressAccessManagementService = async ({ idBuilding, idAccess, images, note, type }) => {
  const { FAIL } = type;

  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  const body = {
    images,
    note,
  };

  try {
    const response = await callApi(ACCESS_MANAGEMENT_API_IN_PROGRESS(idAccess), body, METHOD.PUT, { headers });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
};

export const completeAccessManagementService = async ({ idBuilding, idAccess, images, note, type }) => {
  const { FAIL } = type;

  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  const body = {
    images,
    note,
  };

  try {
    const response = await callApi(ACCESS_MANAGEMENT_API_COMPLETE(idAccess), body, METHOD.PUT, { headers });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
};
