import map from 'lodash/map';

export const MODULE_BUILDING = {
  READ: 'BUILDING_READ',
  WRITE: 'BUILDING_WRITE',
};

export const MODULE_APARTMENT = {
  READ: 'APARTMENT_READ',
  EXPORT: 'APARTMENT_EXPORT',
  CODE_READ: 'APARTMENT_CODE_READ',
  CODE_WRITE: 'APARTMENT_CODE_WRITE',
  CODE_EXPORT: 'APARTMENT_CODE_EXPORT',
};

export const MODULE_APARTMENT_DETAIL = {
  READ: 'APARTMENT_DETAIL_READ',
  SERVICE_HISTORY_READ: 'APARTMENT_DETAIL_SERVICE_HISTORY_READ',
  TRANSACTION_HISTORY_READ: 'APARTMENT_DETAIL_TRANSACTION_HISTORY_READ',
};

export const MODULE_RESIDENT = {
  READ: 'RESIDENT_READ',
  WRITE: 'RESIDENT_WRITE',
  EXPORT: 'RESIDENT_EXPORT',
};

export const MODULE_CONTRACT_TEMPLATE = {
  READ: 'CONTRACT_TEMPLATE_READ',
  WRITE: 'CONTRACT_TEMPLATE_WRITE',
};

export const MODULE_SERVICE = {
  SERVICE_READ: 'SERVICE_READ',
  SERVICE_WRITE: 'SERVICE_WRITE',
  SERVICE_EXPORT: 'SERVICE_EXPORT',
  UTILITY_BOOKING_READ: 'AMENITY_BOOKING_READ',
  UTILITY_BOOKING_APPROVE: 'AMENITY_BOOKING_APPROVE',
  UTILITY_BOOKING_EXPORT: 'AMENITY_BOOKING_READ',
};

export const MODULE_INVOICE = {
  READ: 'INVOICE_READ',
  WRITE: 'INVOICE_WRITE',
  SEND: 'INVOICE_SEND',
};

export const MODULE_PAYMENT = {
  READ: 'PAYMENT_READ',
  WRITE: 'PAYMENT_WRITE',
};

export const MODULE_COMMUNICATE = {
  ANNOUNCEMENT_READ: 'COMMUNICATE_ANNOUNCEMENT_READ',
  ANNOUNCEMENT_WRITE: 'COMMUNICATE_ANNOUNCEMENT_WRITE',
  CHAT_READ_ALL: 'COMMUNICATE_CHAT_READ_ALL',
  CHAT_READ_DEPARTMENT: 'COMMUNICATE_CHAT_READ_DEPARTMENT',
  CHAT_WRITE_ALL: 'COMMUNICATE_CHAT_WRITE_ALL',
  CHAT_WRITE_DEPARTMENT: 'COMMUNICATE_CHAT_WRITE_DEPARTMENT',
};

export const MODULE_STAFF = {
  READ: 'STAFF_READ',
  WRITE: 'STAFF_WRITE',
  EXPORT: 'STAFF_EXPORT',
};

export const MODULE_STATISTIC = {
  CASHBOOK_READ: 'REPORT_CASHBOOK_READ',
  CASHBOOK_EXPORT: 'REPORT_CASHBOOK_EXPORT',
  SERVICE_CHARGE_READ: 'REPORT_SERVICE_CHARGE_READ',
  SERVICE_CHARGE_EXPORT: 'REPORT_SERVICE_CHARGE_EXPORT',
  RECEIPT_READ: 'REPORT_RECEIPT_READ',
  RECEIPT_WRITE: 'REPORT_RECEIPT_WRITE',
  RECEIPT_EXPORT: 'REPORT_RECEIPT_EXPORT',
  PAYSLIP_READ: 'REPORT_PAYSLIP_READ',
  PAYSLIP_WRITE: 'REPORT_PAYSLIP_WRITE',
  PAYSLIP_EXPORT: 'REPORT_PAYSLIP_EXPORT',
  DEBT_READ: 'REPORT_DEBT_READ',
  DEBT_EXPORT: 'REPORT_DEBT_EXPORT',
  APARTMENT_ACCOUNT_READ: 'REPORT_APARTMENT_ACCOUNT_READ',
  APARTMENT_ACCOUNT_EXPORT: 'REPORT_APARTMENT_ACCOUNT_EXPORT',
  SERVICE_REVENUE_READ: 'REPORT_SERVICE_REVENUE_READ',
  SERVICE_REVENUE_EXPORT: 'REPORT_SERVICE_REVENUE_EXPORT',
};

export const MODULE_REPORT = {};

export const MODULE_CONFIG = {
  UNIT_READ: 'CONFIG_UNIT_READ',
  UNIT_WRITE: 'CONFIG_UNIT_WRITE',
  UNIT_EXPORT: 'CONFIG_UNIT_EXPORT',
  AUTHORIZATION_WRITE: 'CONFIG_AUTHORIZATION_WRITE',
  AUTHORIZATION_READ: 'CONFIG_AUTHORIZATION_READ',
  SERVICE_WRITE: 'CONFIG_SERVICE_WRITE',
  SERVICE_READ: 'CONFIG_SERVICE_READ',
  LABEL_WRITE: 'CONFIG_LABEL_WRITE',
  LABEL_READ: 'CONFIG_LABEL_READ',
  TEMPLATE_WRITE: 'CONFIG_TEMPLATE_WRITE',
  TEMPLATE_READ: 'CONFIG_TEMPLATE_READ',
  FUND_WRITE: 'CONFIG_FUND_WRITE',
  FUND_READ: 'CONFIG_FUND_READ',
  UTILITY_READ: 'CONFIG_AMENITY_READ',
  UTILITY_WRITE: 'CONFIG_AMENITY_WRITE',
};

export const MODULE_REQUEST = {
  REQUEST_READ: 'REQUEST_READ',
  REQUEST_WRITE: 'REQUEST_WRITE',
  REQUEST_EXPORT: 'REQUEST_EXPORT',
};

export const MODULE_EQUIPMENT_SUPPLY = {
  EQUIPMENT_READ: 'ASSET_READ',
  EQUIPMENT_WRITE: 'ASSET_WRITE',
  SUPPLY_READ: 'SUPPLY_READ',
  SUPPLY_WRITE: 'SUPPLY_WRITE',
  SUPPLIER_READ: 'PARTNER_READ',
  SUPPLIER_WRITE: 'PARTNER_WRITE',
};

export const MODULE_ACCOUNTING_DATA_LOCK = {
  LOCK_DATA_READ: 'LOCK_DATA_READ',
  LOCK_DATA_WRITE: 'LOCK_DATA_WRITE',
  LOCK_DATA_UPDATE: 'LOCK_DATA_UPDATE',
  LOCK_DATA_HISTORY_READ: 'LOCK_DATA_HISTORY_READ',
};

export const MODULE_VISITOR = {
  VISITOR_READ: 'VISITOR_READ',
  VISITOR_ACTION: 'VISITOR_ACTION',
};

export const permissions = [
  // ...map(MODULE_BUILDING),
  ...map(MODULE_APARTMENT),
  ...map(MODULE_RESIDENT),
  ...map(MODULE_CONTRACT_TEMPLATE),
  ...map(MODULE_SERVICE),
  ...map(MODULE_INVOICE),
  ...map(MODULE_PAYMENT),
  ...map(MODULE_COMMUNICATE),
  ...map(MODULE_STAFF),
  ...map(MODULE_STATISTIC),
  ...map(MODULE_CONFIG),
  ...map(MODULE_APARTMENT_DETAIL),
  ...map(MODULE_REQUEST),
  ...map(MODULE_EQUIPMENT_SUPPLY),
  ...map(MODULE_ACCOUNTING_DATA_LOCK),
  ...map(MODULE_VISITOR),
];
