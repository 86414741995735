import { STATISTIC } from '../../constants';

export const getPaymentOnlineOrders = ({ unitGroupId, page, filterObject }) => ({
    type: STATISTIC.PAYMENT_ONLINE.GETS.REQUEST,
    payload: {
        unitGroupId,
        page,
        filterObject,
    }
});
