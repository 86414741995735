import { SUPPLY } from '../../constants/actionType';
import { X_UNIT_GROUP_ID } from '../../constants';
import { call, put } from 'redux-saga/effects';
import {
  addSupplyService,
  addTransactionService,
  deleteSupplyService,
  getPartnerService,
  getStaffsByPageService,
  getSupplyService,
  updateSupplyService,
  getDepartmentsService,
  getSupplyHistoryService,
  exportSupplyService,
  importSupplyService,
  exportHistoryUseSupplyService,
} from '../../services';

export function* initPageSupply(action) {
  try {
    let payload = {
      code: 200,
      departments: undefined,
    };
    const error = [];

    const { buildingId } = action.payload;

    const resSupply = yield call(getSupplyService, buildingId, 1, {}, SUPPLY.GETS);
    yield put({
      ...resSupply,
    });

    yield put({
      type: SUPPLY.INIT.SUCCESS,
      payload: {
        ...payload,
        error: error.join(', '),
      },
    });
  } catch (e) {
    yield put({
      type: SUPPLY.INIT.FAIL,
      payload: {
        error: e,
      },
    });
  }
}

export function* getSupplies(action) {
  const { buildingId, filter } = action.payload;
  const result = yield call(getSupplyService, buildingId, filter, SUPPLY.GETS);
  yield put({
    ...result,
  });
}

export function* addSupply(action) {
  const { supply, unitGroupId } = action.payload;
  const result = yield call(
    addSupplyService,
    {
      supply,
      unitGroupId,
    },
    SUPPLY.ADD
  );
  yield put({
    ...result,
  });
  yield put({
    type: SUPPLY.ADD.REFRESH,
  });
}

export function* updateSupply(action) {
  const { id, unitGroupId, supply } = action.payload;
  const result = yield call(
    updateSupplyService,
    {
      id,
      supply,
      unitGroupId,
    },
    SUPPLY.UPDATE
  );
  yield put({
    ...result,
  });
  yield put({
    type: SUPPLY.UPDATE.REFRESH,
  });
}

export function* deleteSupply(action) {
  const { supply } = action.payload;
  let result = yield call(
    deleteSupplyService,
    {
      supplyId: supply.id,
      unitGroupId: supply.unitGroupId,
    },
    SUPPLY.DELETE
  );
  result.payload.id = supply.id;
  yield put({
    ...result,
  });
  yield put({
    type: SUPPLY.DELETE.REFRESH,
  });
}

export function* getPartnerInFormSupply(action) {
  const { buildingId, name } = action.payload;
  const result = yield call(
    getPartnerService,
    {
      unitGroupId: buildingId,
      params: {
        name,
        limit: 20,
      },
    },
    SUPPLY.FORM.SEARCH_PARTNER
  );
  yield put({
    ...result,
  });
}

export function* getEmployeeInFormSupply(action) {
  const { buildingId, name } = action.payload;
  const result = yield call(
    getStaffsByPageService,
    {
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      },
      params: {
        name,
        limit: 20,
      },
    },
    SUPPLY.FORM.SEARCH_EMPLOYEE
  );
  yield put({
    ...result,
  });
}

export function* getDepartmentInFormSupply(action) {
  const { buildingId, name } = action.payload;
  const result = yield call(
    getDepartmentsService,
    {
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      },
      params: {
        name,
        limit: 20,
      },
    },
    SUPPLY.FORM.SEARCH_DEPARTMENT
  );
  yield put({
    ...result,
  });
}

export function* addTransaction(action) {
  const params = action.payload;

  const result = yield call(addTransactionService, params, SUPPLY.TRANSACTION.ADD);
  yield put({
    ...result,
  });
  yield put({
    type: SUPPLY.TRANSACTION.ADD.REFRESH,
  });
}

export function* getSupplyHistory(action) {
  const { buildingId, filters } = action.payload;

  const result = yield call(getSupplyHistoryService, buildingId, filters, SUPPLY.HISTORY_GETS);

  yield put(result);
  yield put({ type: SUPPLY.HISTORY_GETS.REFRESH });
}

export function* exportSupplies(action) {
  const { buildingId, filter } = action.payload;
  const result = yield call(
    exportSupplyService,
    {
      params: {
        ...filter,
      },
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      },
    },
    SUPPLY.EXPORT
  );
  yield put({
    ...result,
  });
}

export function* importSupply(action) {
  const { buildingId, file } = action.payload;
  const result = yield call(importSupplyService, buildingId, file, SUPPLY.IMPORT);
  yield put(result);
  yield put({
    type: SUPPLY.IMPORT.REFRESH,
  });
}

export function* exportHistoryUseSupplies(action) {
  const { buildingId, filter } = action.payload;
  const result = yield call(
    exportHistoryUseSupplyService,
    {
      params: {
        ...filter,
      },
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      },
    },
    SUPPLY.HISTORY_EXPORT
  );
  yield put({
    ...result,
  });
}
