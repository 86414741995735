import { call, put, all, takeLatest } from 'redux-saga/effects';
import {
  gets,
  getTotalMoney,
  exportDefault,
  exportRevenue,
  exportDebt,
  exportRemainingDebt,
} from 'services/serviceDebtUpdateUntilCurrentTime';
import { X_UNIT_GROUP_ID, PAGINATION_LIMIT } from 'shared/constants';
import { DEBT } from './service-update-until-current-time.types';
import { convertFilterObject } from './service-update-until-current-time.utils';

function* getDataDebtReport(action) {
  const { unitGroupId, filterObject, page } = action.payload;
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };
  const params = {
    ...convertFilterObject(filterObject),
    page,
    limit: PAGINATION_LIMIT,
  };

  const result = yield call(gets, { params, headers }, DEBT.GETS);
  yield put(result);
}

function* getTotalDebtReport(action) {
  const { unitGroupId, filterObject } = action.payload;
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };
  const params = {
    ...convertFilterObject(filterObject),
  };
  const result = yield call(getTotalMoney, { params, headers }, DEBT.TOTAL);
  yield put(result);
}

function* exportDefaultInPageReport(action) {
  const { unitGroupId, filterObject } = action.payload;
  const params = {
    ...convertFilterObject(filterObject),
  };
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(exportDefault, { params, headers }, DEBT.EXPORT_DEFAULT);
  yield put(result);
  yield put({
    type: DEBT.EXPORT_DEFAULT.REFRESH,
  });
}

function* exportRevenueInPageReport(action) {
  const { unitGroupId, filterObject } = action.payload;
  const params = {
    ...convertFilterObject(filterObject),
  };
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(exportRevenue, { params, headers }, DEBT.EXPORT_REVENUE);
  yield put(result);
  yield put({
    type: DEBT.EXPORT_REVENUE.REFRESH,
  });
}

function* exportDebtInPageReport(action) {
  const { unitGroupId, filterObject } = action.payload;
  const params = {
    ...convertFilterObject(filterObject),
  };
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(exportDebt, { params, headers }, DEBT.EXPORT_DEBT);
  yield put(result);
  yield put({
    type: DEBT.EXPORT_DEBT.REFRESH,
  });
}

function* exportRemainingDebtInPageReport(action) {
  const { unitGroupId, filterObject } = action.payload;
  const params = {
    ...convertFilterObject(filterObject),
  };
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(exportRemainingDebt, { params, headers }, DEBT.EXPORT_REMAINING_DEBT);
  yield put(result);
  yield put({
    type: DEBT.EXPORT_REMAINING_DEBT.REFRESH,
  });
}

export default function* watch() {
  yield all([
    takeLatest(DEBT.GETS.REQUEST, getDataDebtReport),
    takeLatest(DEBT.TOTAL.REQUEST, getTotalDebtReport),
    takeLatest(DEBT.EXPORT_DEFAULT.REQUEST, exportDefaultInPageReport),
    takeLatest(DEBT.EXPORT_REVENUE.REQUEST, exportRevenueInPageReport),
    takeLatest(DEBT.EXPORT_DEBT.REQUEST, exportDebtInPageReport),
    takeLatest(DEBT.EXPORT_REMAINING_DEBT.REQUEST, exportRemainingDebtInPageReport),
  ]);
}
