import { getServiceInUseService } from 'services';
import { takeLatest, all, put, call } from 'redux-saga/effects';
import { X_UNIT_GROUP_ID } from 'constants/http';
import { SERVICE_IN_USE_GETS } from './service.types';

function* getsServiceInUse(action) {
  const { unitGroupId } = action.payload;
  const params = {};
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };
  const result = yield call(getServiceInUseService, { params, headers }, SERVICE_IN_USE_GETS);
  yield put(result);
}

export default function* watch() {
  yield all([takeLatest(SERVICE_IN_USE_GETS.REQUEST, getsServiceInUse)]);
}
