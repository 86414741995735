import { put, select, call, all, takeLatest } from 'redux-saga/effects';
import { SUCCESS_CODE } from 'shared/constants';
// eslint-disable-next-line import/no-unresolved
import { getBuildingTypes, getCities, getDistricts } from 'services';
import {
  BUILDING_FORM_BUILDING_TYPES_GETS,
  BUILDING_FORM_CITY_GETS,
  BUILDING_FORM_DISTRICT_GETS,
  BUILDING_FORM_INIT,
} from './buildingForm.types';

function* init() {
  const codeCity = select((state) => state.buildingForm.cities.code);
  const codeDistrict = select((state) => state.buildingForm.districts.code);
  const codeBuildingTypes = select((state) => state.buildingForm.buildingTypes.code);
  let resCity;
  let resDistricts;
  let resBuildingTypes;
  if (!SUCCESS_CODE.includes(codeCity)) {
    resCity = yield call(getCities, BUILDING_FORM_CITY_GETS);
  }
  if (!SUCCESS_CODE.includes(codeDistrict)) {
    resDistricts = yield call(getDistricts, BUILDING_FORM_DISTRICT_GETS);
  }
  if (!SUCCESS_CODE.includes(codeBuildingTypes)) {
    resBuildingTypes = yield call(getBuildingTypes, BUILDING_FORM_BUILDING_TYPES_GETS);
  }
  yield all([put(resCity), put(resDistricts), put(resBuildingTypes)]);
  yield put({
    type: BUILDING_FORM_INIT.SUCCESS,
    payload: {
      code: 200,
    },
  });
}

// eslint-disable-next-line import/prefer-default-export
export function* watchBuildingForm() {
  yield all([takeLatest(BUILDING_FORM_INIT.REQUEST, init)]);
}
