import { checkResponseError } from '../libs/http';
import { APPLICATION, } from '../constants/actionType';
import { call, put } from 'redux-saga/effects';
import { isObject, isArray } from 'lodash';
import { getApartmentType, getBuilding } from '../services';

export function* initApplication(action) {
    try {
        let payload = {
            code: 200,
            resident: {
                feeStatus: undefined,
            },
            apartment: {
                type: undefined,
            },
            building: [],
        };
        let error = [];

        //get list buildings for check permission
        let resBuildings = yield call(getBuilding);

        let resApartmentType = {};
        if (action.dataFetching.application) {
            // apartment
            resApartmentType = yield call(getApartmentType);
        }
        if (isObject(resApartmentType.data)) {
            const err = checkResponseError(resApartmentType.data);
            if (!err) {
                payload.apartment.type = resApartmentType.data;
            } else {
                error.push(err.message);
            }
        }
        if (isArray(resBuildings.data)) {
            const err = checkResponseError(resBuildings);
            if (!err) {
                payload.buildings = [...resBuildings.data];
            } else {
                error.push(err.message);
            }
        }

        yield put({
            type: APPLICATION.INIT.SUCCESS,
            payload: {
                ...payload,
                error: error.join(', ')
            }
        });
    }
    catch (err) {
        yield put({
            type: APPLICATION.INIT.FAIL,
            payload: {
                code: 0,
                error: err
            }
        });
    }
}