import { call, takeLatest, put, all } from 'redux-saga/effects';
import {
  getsCovidVaccinationRegistrationFormService,
  cancelCovidVaccinationRegistrationFormService,
  exportCovidVaccinationRegistrationFormService,
  updateNoteCovidVaccinationRegistrationFormService,
} from 'services/residentRegistrationForm';
import { X_UNIT_GROUP_ID } from 'shared/constants';
import {
  REGISTRATION_FORM_COVID_VACCINATION_ACTION_CANCEL,
  REGISTRATION_FORM_COVID_VACCINATION_ACTION_EXPORT,
  REGISTRATION_FORM_COVID_VACCINATION_ACTION_GETS,
  REGISTRATION_FORM_COVID_VACCINATION_ACTION_UPDATE,
} from './covid-vaccination.types';

function* getsCovidVaccinationRegistrationForm(action) {
  const { idBuilding, filterObj, page, limit } = action.payload;
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const params = {
    ...filterObj,
    page,
    limit,
  };
  const result = yield call(getsCovidVaccinationRegistrationFormService, headers, params, REGISTRATION_FORM_COVID_VACCINATION_ACTION_GETS);
  yield put(result);
}

function* updateNoteCovidVaccinationRegistrationForm(action) {
  const { idBuilding, id, note } = action.payload;
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const body = {
    id,
    note,
  };
  const result = yield call(
    updateNoteCovidVaccinationRegistrationFormService,
    headers,
    body,
    REGISTRATION_FORM_COVID_VACCINATION_ACTION_UPDATE
  );
  yield put(result);
  yield put({
    type: REGISTRATION_FORM_COVID_VACCINATION_ACTION_UPDATE.REFRESH,
  });
}

function* cancelCovidVaccinationRegistrationForm(action) {
  const { idBuilding, id, reason } = action.payload;
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const body = {
    id,
    reason,
  };
  const result = yield call(
    cancelCovidVaccinationRegistrationFormService,
    headers,
    body,
    REGISTRATION_FORM_COVID_VACCINATION_ACTION_CANCEL
  );
  yield put(result);
  yield put({
    type: REGISTRATION_FORM_COVID_VACCINATION_ACTION_CANCEL.REFRESH,
  });
}

function* exportCovidVaccinationRegistrationForm(action) {
  const { idBuilding, filterObj } = action.payload;
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const params = {
    ...filterObj,
  };
  const result = yield call(
    exportCovidVaccinationRegistrationFormService,
    headers,
    params,
    REGISTRATION_FORM_COVID_VACCINATION_ACTION_EXPORT
  );
  yield put(result);
  yield put({
    type: REGISTRATION_FORM_COVID_VACCINATION_ACTION_EXPORT.REFRESH,
  });
}

export default function* watch() {
  yield all([
    takeLatest(REGISTRATION_FORM_COVID_VACCINATION_ACTION_GETS.REQUEST, getsCovidVaccinationRegistrationForm),
    takeLatest(REGISTRATION_FORM_COVID_VACCINATION_ACTION_UPDATE.REQUEST, updateNoteCovidVaccinationRegistrationForm),
    takeLatest(REGISTRATION_FORM_COVID_VACCINATION_ACTION_CANCEL.REQUEST, cancelCovidVaccinationRegistrationForm),
    takeLatest(REGISTRATION_FORM_COVID_VACCINATION_ACTION_EXPORT.REQUEST, exportCovidVaccinationRegistrationForm),
  ]);
}
