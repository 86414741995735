import { call, put, all, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import { BUILDING } from './building.types';
import {
    deleteBuildingResource,
    getBuildingByIdService,
    getBuildings as getBuildingsService,
} from 'services';
import { addBuildingResource, updateBuildingResource } from 'services';

function* getBuildings(action) {
    const result = yield call(getBuildingsService, BUILDING.GETS);
    yield put(result);
}


function* getBuildingById(action) {
    const { id } = action.payload;

    const result = yield call(getBuildingByIdService, id, BUILDING.GET_BY_ID);
    yield put(result);
}

function* addBuilding(action) {
    const { building } = action.payload;
    const result = yield call(addBuildingResource, building, BUILDING.ADD);
    yield put(result);
}

function* updateBuilding(action) {
    const { building } = action.payload;
    const result = yield call(updateBuildingResource, building, BUILDING.UPDATE);
    yield put(result);
}

function* deleteBuilding(action) {
    const { buildings } = action.payload;

    const result = yield call(deleteBuildingResource, get(buildings, ['0'], ''), BUILDING.DELETE);
    result.payload.id = get(buildings, ['0'], '');
    yield put(result);
}

export function* watchBuilding() {
    yield all([
        takeLatest(BUILDING.GETS.REQUEST, getBuildings),
        takeLatest(BUILDING.GET_BY_ID.REQUEST, getBuildingById),
        takeLatest(BUILDING.ADD.REQUEST, addBuilding),
        takeLatest(BUILDING.UPDATE.REQUEST, updateBuilding),
        takeLatest(BUILDING.DELETE.REQUEST, deleteBuilding),
    ]);
}
