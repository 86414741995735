import { DASHBOARD } from '../constants/actionType';
import { call, put } from 'redux-saga/effects';
import {
  getOverviewService,
  getRevenueService,
  getTotalPaidAndUnpaidService,
  getResidentService,
  getServiceOfBuildingService,
  getVehicleService,
  getBuildingForDashboardService,
} from '../services/dashboard';
import { X_UNIT_GROUP_ID } from 'constants';

export function* getOverview(action) {
  const { buildingIds } = action.payload;

  const result = yield call(
    getOverviewService,
    {
      body: {
        unitGroupIds: buildingIds,
      },
    },
    DASHBOARD.OVERVIEW.GETS
  );

  yield put(result);
}

export function* getRevenue(action) {
  const { buildingIds, from, to, type } = action.payload;

  const result = yield call(
    getRevenueService,
    {
      body: {
        unitGroupIds: buildingIds,
        from,
        to,
        type,
      },
    },
    DASHBOARD.REVENUE.GETS
  );

  yield put(result);
}

export function* getTotalPaidAndUnpaid(action) {
  const { buildingIds, from, to, type, serviceId } = action.payload;

  const result = yield call(
    getTotalPaidAndUnpaidService,
    {
      body: {
        unitGroupIds: buildingIds,
        from,
        to,
        type,
        serviceId,
      },
    },
    DASHBOARD.TOTAL_PAID_UNPAID.GETS
  );

  yield put(result);
}

export function* getResident(action) {
  const { buildingIds } = action.payload;

  const result = yield call(
    getResidentService,
    {
      body: {
        unitGroupIds: buildingIds,
      },
    },
    DASHBOARD.RESIDENT.GETS
  );

  yield put(result);
}

export function* getServices(action) {
  const { buildingIds } = action.payload;

  const result = yield call(
    getServiceOfBuildingService,
    {
      body: {
        unitGroupIds: buildingIds,
      },
    },
    DASHBOARD.SERVICE.GETS
  );

  yield put(result);
}

export function* getVehicles(action) {
  const { buildingIds } = action.payload;

  const result = yield call(
    getVehicleService,
    {
      body: {
        unitGroupIds: buildingIds,
      },
    },
    DASHBOARD.VEHICLE.GETS
  );

  yield put(result);
  yield put({
    type: DASHBOARD.VEHICLE.GETS.REFRESH,
  });
}

export function* getBuildingsForDashboard(action) {
  const result = yield call(getBuildingForDashboardService,{},DASHBOARD.BUILDING.GETS);

  yield put(result);
}