import { CONFIG, FLOOR } from '../constants/actionType';

export const initDataApartmentConfigPage = (buildingId: string, dataFetching: Object) => ({
    type: CONFIG.APARTMENT.INIT.REQUEST,
    buildingId,
    dataFetching,
});

export const getApartmentByPageInConfig = (buildingId: number, page: number, filters: Object) => ({
    type: CONFIG.APARTMENT.GETS.REQUEST,
    payload: {
        buildingId,
        page,
        filters,
    },
});

export const getFloorInConfig = (buildingId) => ({
    type: CONFIG.FLOOR.GETS.REQUEST,
    payload: {
        buildingId,
    },
});

export const addApartment = (apartment: Object) => ({
    type: CONFIG.APARTMENT.ADD.REQUEST,
    payload: {
        apartment,
    },
});

export const refreshAddApartment = () => ({
    type: CONFIG.APARTMENT.ADD.REFRESH,
});

export const importApartment = (buildingId, file) => ({
    type: CONFIG.APARTMENT.IMPORT.REQUEST,
    payload: {
        buildingId,
        file,
    },
});

export const refreshImportApartment = () => ({
    type: CONFIG.APARTMENT.IMPORT.REFRESH,
});

export const resetDataImportApartment = () => ({
    type: CONFIG.APARTMENT.IMPORT.SUCCESS,
    payload: {
        currentPage: 0,
        totalPage: 0,
        totalRecord: 0,
        result: [],
    },
});

export const updateApartment = (apartment: Object) => ({
    type: CONFIG.APARTMENT.UPDATE.REQUEST,
    payload: {
        apartment,
    },
});

export const refreshUpdateApartment = () => ({
    type: CONFIG.APARTMENT.UPDATE.REFRESH,
});

export const deleteApartments = ({ isAll, array, filterObject }, unitGroupId) => ({
    type: CONFIG.APARTMENT.DELETE.REQUEST,
    payload: {
        isAll,
        array,
        filterObject,
        unitGroupId,
    },
});

export const deleteApartment = (apartment: Object) => ({
    type: CONFIG.APARTMENT.DELETE.REQUEST,
    apartment,
});

export const refreshDeleteApartment = () => ({
    type: CONFIG.APARTMENT.DELETE.REFRESH,
});

export const exportApartmentInPageConfig = (buildingId, filterObject) => ({
    type: CONFIG.APARTMENT.EXPORT_APARTMENT.REQUEST,
    payload: {
        buildingId,
        filterObject,
    },
});

// floor

export const addFloor = (floor: Object) => ({
    type: FLOOR.ADD.REQUEST,
    payload: {
        floor,
    },
});

export const updateFloor = (floor: Object) => ({
    type: FLOOR.UPDATE.REQUEST,
    payload: {
        floor,
    },
});

export const deleteFloor = (ids, buildingId) => ({
    type: FLOOR.DELETE.REQUEST,
    payload: {
        ids,
        buildingId,
    },
});

export const initDataPositionConfigPage = (buildingId: string) => ({
    type: CONFIG.POSITION.INIT.REQUEST,
    payload: {
        buildingId,
    },
});

export const initDataDepartmentConfigPage = (buildingId: number, dataFetching: Object) => ({
    type: CONFIG.DEPARTMENT.INIT.REQUEST,
    buildingId,
    dataFetching,
});

export const addDepartment = (department: Object) => ({
    type: CONFIG.DEPARTMENT.ADD.REQUEST,
    payload: {
        department,
    },
});

export const refreshAddDepartment = () => ({
    type: CONFIG.DEPARTMENT.ADD.REFRESH,
});

export const updateDepartment = (department: Object) => ({
    type: CONFIG.DEPARTMENT.UPDATE.REQUEST,
    payload: {
        department,
    },
});

export const refreshUpdateDepartment = () => ({
    type: CONFIG.DEPARTMENT.UPDATE.REFRESH,
});

export const deleteDepartment = (department: Object, unitGroupId) => ({
    type: CONFIG.DEPARTMENT.DELETE.REQUEST,
    payload: {
        department,
        unitGroupId,
    },
});

export const refreshDeleteDepartment = () => ({
    type: CONFIG.DEPARTMENT.DELETE.REFRESH,
});

export const initDataTagConfigPage = (buildingId: string) => ({
    type: CONFIG.TAG.INIT.REQUEST,
    buildingId,
});

export const addTag = (tag: Object) => ({
    type: CONFIG.TAG.ADD.REQUEST,
    tag,
});

export const refreshAddTag = () => ({
    type: CONFIG.TAG.ADD.REFRESH,
});

export const updateTag = (tag: Object) => ({
    type: CONFIG.TAG.UPDATE.REQUEST,
    tag,
});

export const refreshUpdateTag = () => ({
    type: CONFIG.TAG.UPDATE.REFRESH,
});

export const deleteTag = (tag: Object) => ({
    type: CONFIG.TAG.DELETE.REQUEST,
    tag,
});

export const refreshDeleteTag = () => ({
    type: CONFIG.TAG.DELETE.REFRESH,
});

// block

export const initDataBlockConfigPage = (buildingId: string, dataFetching: Object) => ({
    type: CONFIG.BLOCK.INIT.REQUEST,
    buildingId,
    dataFetching,
});

export const addBlock = (block: Object) => ({
    type: CONFIG.BLOCK.ADD.REQUEST,
    block,
});

export const refreshAddBlock = () => ({
    type: CONFIG.BLOCK.ADD.REFRESH,
});

export const updateBlock = (block: Object, blockPrev: Object) => ({
    type: CONFIG.BLOCK.UPDATE.REQUEST,
    block,
    blockPrev,
});

export const refreshUpdateBlock = () => ({
    type: CONFIG.BLOCK.UPDATE.REFRESH,
});

export const deleteBlock = (block: Object) => ({
    type: CONFIG.BLOCK.DELETE.REQUEST,
    block,
});

export const refreshDeleteBlock = () => ({
    type: CONFIG.BLOCK.DELETE.REFRESH,
});


