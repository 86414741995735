import { call, put, takeLatest, all } from 'redux-saga/effects';
import { X_UNIT_GROUP_ID } from 'shared/constants';
import { createReceiptService, getBillsService, sumPaymentService } from 'services';
import { PAYMENT_INIT, PAYMENT_BILL_GETS, PAYMENT_BILL_SUM_PAYMENT_GETS, PAYMENT_RECEIPT_CREATE, PAYMENT_UNIT_GETS } from './payment.types';

function* initPaymentPage(action) {
  const { unitGroupId } = action.payload;

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(
    getBillsService,
    {
      params: {
        page: 1,
        limit: 20,
        isShowBillInProcess: true,
      },
      headers,
    },
    PAYMENT_BILL_GETS
  );

  yield put(result);

  const resSumPayment = yield call(
    sumPaymentService,
    {
      headers,
    },
    PAYMENT_BILL_SUM_PAYMENT_GETS
  );
  yield put(resSumPayment);

  yield put({
    type: PAYMENT_INIT.SUCCESS,
    payload: {
      code: 200,
    },
  });
}

function* getSumPaymentInPagePayment(action) {
  const { unitGroupId, filterObject } = action.payload;

  const params = {
    ...filterObject,
  };

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(
    sumPaymentService,
    {
      params,
      headers,
    },
    PAYMENT_BILL_SUM_PAYMENT_GETS
  );
  yield put(result);
}

function* getBillByGroupTargetPagePayment(action) {
  const { unitGroupId, page, filterObject } = action.payload;
  const params = {
    page,
    limit: 20,
    ...filterObject,
    isShowBillInProcess: true,
  };

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(getBillsService, { params, headers }, PAYMENT_BILL_GETS);
  yield put(result);
}

function* createReceiptInPagePayment(action) {
  const { receipt, unitGroupId } = action.payload;

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(
    createReceiptService,
    {
      body: receipt,
      headers,
    },
    PAYMENT_RECEIPT_CREATE
  );

  yield put(result);
  yield put({
    type: PAYMENT_RECEIPT_CREATE.REFRESH,
  });
}

function* getUnits(action) {
  const { unitGroupId, name, page, limit } = action.payload;

  const params = {
    page,
    limit,
    unitName: name,
    isShowBillInProcess: true,
  };

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };
  const result = yield call(getBillsService, { params, headers }, PAYMENT_UNIT_GETS);
  yield put(result);
  yield put({
    type: PAYMENT_UNIT_GETS.REFRESH,
  });
}

// eslint-disable-next-line import/prefer-default-export
export function* watch() {
  yield all([
    takeLatest(PAYMENT_INIT.REQUEST, initPaymentPage),
    takeLatest(PAYMENT_BILL_GETS.REQUEST, getBillByGroupTargetPagePayment),
    takeLatest(PAYMENT_BILL_SUM_PAYMENT_GETS.REQUEST, getSumPaymentInPagePayment),
    takeLatest(PAYMENT_RECEIPT_CREATE.REQUEST, createReceiptInPagePayment),
    takeLatest(PAYMENT_UNIT_GETS.REQUEST, getUnits),
  ]);
}
