import { FORM_SERVICE } from '../constants/actionType';
import { combineReducers } from 'redux';
import { initStateFetching } from './fetching';
import fetching from './fetching';

export const initState = {
    formInit: {
        ...initStateFetching,
    },
    icons: [],
    dataFormInit: false,
    // contract template by formula id
    formulaId: {},
    // contract template by surcharge id
    surchargeId: {},
    // contract template by surcharge id
    promotionId: {},
};

const formInit = fetching(FORM_SERVICE.INIT);

function dataFormInit(state = false, action) {
    switch (action.type) {
    case FORM_SERVICE.INIT.SUCCESS:
        return true;
    default:
        return state;
    }
}

function icons(state = [], action) {
    switch (action.type) {
    case FORM_SERVICE.INIT.SUCCESS:
        return action.payload.icons;
    default:
        return state;
    }
}

function formulaId(state = {}, action) {
    switch (action.type) {
    case FORM_SERVICE.CONTRACT_TEMPLATE.FORMULA_GETS.REQUEST: {
        return {
            ...state,
            [action.payload.formulaId]: {
                isFetching: true,
                code: undefined,
                codeLanguage: undefined,
            }
        };
    }
    case FORM_SERVICE.CONTRACT_TEMPLATE.FORMULA_GETS.SUCCESS: {
        return {
            ...state,
            [action.payload.formulaId]: {
                isFetching: false,
                code: action.payload.code,
                codeLanguage: action.payload.codeLanguage,
                data: action.payload.result,
            },
        };
    }
    case FORM_SERVICE.CONTRACT_TEMPLATE.FORMULA_GETS.FAIL: {
        return {
            ...state,
            [action.payload.formulaId]: {
                code: action.payload.code,
                codeLanguage: action.payload.codeLanguage,
                isFetching: false,
                message: action.payload.message,
            }
        };
    }
    default:
        return state;
    }
}

function surchargeId(state = {}, action) {
    switch (action.type) {
    case FORM_SERVICE.CONTRACT_TEMPLATE.SURCHARGE_GETS.REQUEST: {
        return {
            ...state,
            [action.payload.surchargeId]: {
                isFetching: true,
                code: undefined,
                codeLanguage: undefined,
            }
        };
    }
    case FORM_SERVICE.CONTRACT_TEMPLATE.SURCHARGE_GETS.SUCCESS: {
        return {
            ...state,
            [action.payload.surchargeId]: {
                isFetching: false,
                code: action.payload.code,
                codeLanguage: action.payload.codeLanguage,
                data: action.payload.result,
            },
        };
    }
    case FORM_SERVICE.CONTRACT_TEMPLATE.SURCHARGE_GETS.FAIL: {
        return {
            ...state,
            [action.payload.surchargeId]: {
                code: action.payload.code,
                codeLanguage: action.payload.codeLanguage,
                isFetching: false,
                message: action.payload.message,
            }
        };
    }
    default:
        return state;
    }
}

function promotionId(state = {}, action) {
    switch (action.type) {
    case FORM_SERVICE.CONTRACT_TEMPLATE.PROMOTION_GETS.REQUEST: {
        return {
            ...state,
            [action.payload.promotionId]: {
                isFetching: true,
                code: undefined,
                codeLanguage: undefined,
            }
        };
    }
    case FORM_SERVICE.CONTRACT_TEMPLATE.PROMOTION_GETS.SUCCESS: {
        return {
            ...state,
            [action.payload.promotionId]: {
                isFetching: false,
                code: action.payload.code,
                codeLanguage: action.payload.codeLanguage,
                data: action.payload.result,
            },
        };
    }
    case FORM_SERVICE.CONTRACT_TEMPLATE.PROMOTION_GETS.FAIL: {
        return {
            ...state,
            [action.payload.promotionId]: {
                code: action.payload.code,
                codeLanguage: action.payload.codeLanguage,
                isFetching: false,
                message: action.payload.message,
            }
        };
    }
    default:
        return state;
    }
}

export default combineReducers({
    formInit,
    icons,
    dataFormInit,
    formulaId,
    surchargeId,
    promotionId,
});
