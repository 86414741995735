import { CONFIG, FLOOR } from '../constants/actionType';
import { call, put } from 'redux-saga/effects';
import get from 'lodash/get';
import {
    addFloorService,
    deleteFloorService,
    deleteMultiFloorService,
    getFloorsService,
    updateFloorService,
} from '../services';

export function* getFloorInPageConfig(action) {
    const {
        buildingId,
    } = action.payload;
    const result = yield call(getFloorsService, buildingId, CONFIG.FLOOR.GETS);
    yield put({
        ...result
    });
}

export function* addFloor(action) {
    const {
        name,
        description,
        unitGroupId,
        blockId,
    } = action.payload.floor;

    const floor = {
        name,
        description,
        unitGroupId,
        blockId,
    };

    const result = yield call(addFloorService, floor, FLOOR.ADD);
    yield put({
        ...result,
    });
    yield put({
        type: FLOOR.ADD.REFRESH,
    });
}

export function* updateFloor(action) {
    const {
        id,
        name,
        description,
        unitGroupId,
        blockId,
    } = action.payload.floor;

    const floor = {
        id,
        name,
        description,
        unitGroupId,
        blockId,
    };
    const result = yield call(updateFloorService, floor, FLOOR.UPDATE);
    yield put({
        ...result,
    });
    yield put({
        type: FLOOR.UPDATE.REFRESH,
    });
}

export function* deleteFloor(action) {
    const {
        ids,
        buildingId
    } = action.payload;
    const result = yield call(deleteMultiFloorService, {
        ids,
        unitGroupId: buildingId,
    }, FLOOR.DELETE);
    yield put({
        ...result,
        payload: {
            ...result.payload,
            result: {
                floors: get(result, 'payload.floors.result', []),
                errors: get(result, 'payload.floors.errs', [])
            }
        }
    });
    yield put({
        type: FLOOR.DELETE.REFRESH,
    });
}
