import { combineReducers } from 'redux';
import paginate, { initStatePagination } from 'shared/utils/paginate';
import fetching, { initStateFetching } from 'shared/utils/fetching';
import { STATISTIC_RECEIPT } from './receipt.types';

export const initState = {
  receipts: {
    ...initStatePagination,
  },
  receiptSumTotalPayment: {
    ...initStatePagination,
  },
  receiptGetById: {
    ...initStatePagination,
  },
  receiptAdd: {
    ...initStateFetching,
  },
  receiptUpdate: {
    ...initStateFetching,
  },
  receiptCancel: {
    ...initStateFetching,
  },
  receiptExport: {
    ...initStateFetching,
  },
  units: {
    ...initStatePagination,
  },
};

const receipts = paginate(STATISTIC_RECEIPT.GETS);
const receiptSumTotalPayment = paginate(STATISTIC_RECEIPT.SUM_TOTAL_PAYMENT);
const receiptGetById = paginate(STATISTIC_RECEIPT.GET_BY_ID);
const receiptAdd = paginate(STATISTIC_RECEIPT.ADD);
const receiptUpdate = fetching(STATISTIC_RECEIPT.UPDATE);
const receiptCancel = fetching(STATISTIC_RECEIPT.CANCEL);
const receiptExport = paginate(STATISTIC_RECEIPT.EXPORT);
const units = paginate(STATISTIC_RECEIPT.GET_UNITS_IN_RECEIPT_FORM);

export default combineReducers({
  receipts,
  receiptSumTotalPayment,
  receiptGetById,
  receiptAdd,
  receiptUpdate,
  receiptCancel,
  receiptExport,
  units,
});
