import { SERVICE } from '../constants';

export const serviceGets = (buildingId) => ({
    type: SERVICE.GETS.REQUEST,
    payload: {
        buildingId,
    },
});

export const serviceContractGets = (filter, page) => ({
    type: SERVICE.CONTRACT.GETS.REQUEST,
    filter,
    page,
});

export const sumTotalContract = (filter, page) => ({
    type: SERVICE.CONTRACT.SUM_TOTAL.REQUEST,
    filter,
    page,
});

export const resetDataContract = () => ({
    type: SERVICE.CONTRACT.GETS.SUCCESS,
    payload: {
        currentPage: 0,
        totalPage: 0,
        totalRecord: 0,
        result: [],
    },
});

export const serviceContractTemplateGets = (filter) => ({
    type: SERVICE.CONTRACT_TEMPLATE.GETS.REQUEST,
    filter,
});

export const serviceContractAdds = (contracts, unitGroupId) => ({
    type: SERVICE.CONTRACT.ADDS.REQUEST,
    contracts,
    unitGroupId,
});

export const unitGets = (filter) => ({
    type: SERVICE.UNIT.GETS.REQUEST,
    filter,
});

export const activeContract = (contractId, buildingId) => ({
    type: SERVICE.CONTRACT.ACTIVE.REQUEST,
    payload: {
        contractId,
        buildingId,
    },
});

export const activeMultiContract = ({ array = [], isAll = false, filterObject = {}, buildingId = '', contractTemplateName = [] }) => ({
    type: SERVICE.CONTRACT.ACTIVE_MULTI.REQUEST,
    payload: {
        array,
        isAll,
        filterObject,
        buildingId,
        contractTemplateName,
    },
});

export const inactiveContract = (contractId, buildingId) => ({
    type: SERVICE.CONTRACT.INACTIVE.REQUEST,
    payload: {
        contractId,
        buildingId,
    },
});

export const inactiveMultiContract = ({ array = [], isAll = false, filterObject = {}, buildingId = '', contractTemplateName = [] }) => ({
    type: SERVICE.CONTRACT.INACTIVE_MULTI.REQUEST,
    payload: {
        array,
        isAll,
        filterObject,
        buildingId,
        contractTemplateName,
    },
});

export const importContract = ({ buildingId, file, createdFor, startDate, endDate, identifier }) => ({
    type: SERVICE.CONTRACT.IMPORT.REQUEST,
    payload: {
        buildingId,
        file,
        createdFor,
        startDate,
        endDate,
        identifier,
    },
});

export const resetDataImportContract = () => ({
    type: SERVICE.CONTRACT.IMPORT.SUCCESS,
    payload: {
        currentPage: 0,
        totalPage: 0,
        totalRecord: 0,
        result: [],
    },
});

export const exportContract = (buildingId, filterObject) => ({
    type: SERVICE.CONTRACT.EXPORT.REQUEST,
    payload: {
        buildingId,
        filterObject,
    },
});

export const updateContract = (contract, unitGroupId) => ({
    type: SERVICE.CONTRACT.UPDATE.REQUEST,
    payload: {
        contract,
        unitGroupId,
    },
});

export const deleteContracts = ({ isAll, ids, serviceId, filterObject }, unitGroupId) => ({
    type: SERVICE.CONTRACT.DELETE.REQUEST,
    payload: {
        isAll,
        ids,
        unitGroupId,
        serviceId,
        filterObject,
    },
});

export const downloadFileTemplate = ({ unitGroupId, serviceId, serviceType }) => ({
    type: SERVICE.DOWNLOAD_TEMPLATE.REQUEST,
    payload: {
        unitGroupId,
        serviceId,
        serviceType,
    },
});
