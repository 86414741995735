import { combineReducers } from 'redux';
import paginate, { initStatePagination } from 'shared/utils/paginate';
import { STAFF_GETS, STAFF_ADD, STAFF_UPDATE, STAFF_DELETE, STAFF_IMPORT, STAFF_EXPORT, STAFF_DEPARTMENT_GETS } from './staff.types';

export const initState = {
  data: {
    ...initStatePagination,
  },
  staffAdd: {
    ...initStatePagination,
  },
  staffUpdate: {
    ...initStatePagination,
  },
  staffDelete: {
    ...initStatePagination,
  },
  staffImport: {
    ...initStatePagination,
  },
  staffExport: {
    ...initStatePagination,
  },
  departments: {
    ...initStatePagination,
  },
};

export default combineReducers({
  data: paginate(STAFF_GETS),
  staffAdd: paginate(STAFF_ADD),
  staffUpdate: paginate(STAFF_UPDATE),
  staffDelete: paginate(STAFF_DELETE),
  staffImport: paginate(STAFF_IMPORT),
  staffExport: paginate(STAFF_EXPORT),
  departments: paginate(STAFF_DEPARTMENT_GETS),
});
