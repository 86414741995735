import { CONFIG } from '../../constants/actionType';
import { call, put } from 'redux-saga/effects';
import { getAccountService, addAccountService, updateAccountService, deleteAccountService } from '../../services/fund';
import { get } from 'lodash';

export function* getAccount(action) {
  const { buildingId } = action.payload;

  const result = yield call(getAccountService, buildingId, CONFIG.ACCOUNT.GETS);

  yield put(result);
}

export function* addAccount(action) {
  const { data, buildingId } = action.payload;

  const result = yield call(addAccountService, { body: data, unitGroupId: buildingId }, CONFIG.ACCOUNT.ADD);

  yield put(result);
  yield put({ type: CONFIG.ACCOUNT.ADD.REFRESH });
}

export function* updateAccount(action) {
  const { data, buildingId } = action.payload;

  const accountId = data.id;

  const result = yield call(updateAccountService, { body: data, accountId, unitGroupId: buildingId }, CONFIG.ACCOUNT.UPDATE);

  yield put(result);
  yield put({ type: CONFIG.ACCOUNT.UPDATE.REFRESH });
}

export function* deleteAccount(action) {
  const { data, buildingId } = action.payload;

  let result = yield call(deleteAccountService, {
    data,
    unitGroupId: buildingId,
  }, CONFIG.ACCOUNT.DELETE);

  result.payload.accountId = data;

  yield put(result);
  yield put({ type: CONFIG.ACCOUNT.DELETE.REFRESH });
}
