import { METHOD } from 'constants/http';
import { handleError, handleResponse } from 'shared/utils';
import { callApi } from '../api';

const SERVER = process.env.API_URL;
const REGISTRATION_FORM_RECEIVE_PACKAGE_GETS = `${SERVER}/form-data/v1/receive-package`;
const REGISTRATION_FORM_RECEIVE_PACKAGE_APPROVE = (idReceivePackage) =>
  `${SERVER}/form-data/v1/receive-package/${idReceivePackage}/approve`;
const REGISTRATION_FORM_RECEIVE_PACKAGE_CANCEL = (idReceivePackage) => `${SERVER}/form-data/v1/receive-package/${idReceivePackage}/cancel`;
const REGISTRATION_FORM_RECEIVE_PACKAGE_COMPLETE = (idReceivePackage) =>
  `${SERVER}/form-data/v1/receive-package/${idReceivePackage}/complete`;
const REGISTRATION_FORM_RECEIVE_PACKAGE_RECEIVED = (idReceivePackage) =>
  `${SERVER}/form-data/v1/receive-package/${idReceivePackage}/received`;

export const getsReceivePackageRegistrationFormService = async (
  headers,
  params,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) => {
  const { FAIL } = type;
  try {
    const response = await callApi(REGISTRATION_FORM_RECEIVE_PACKAGE_GETS, {}, METHOD.GET, {
      headers,
      params,
    });
    return handleResponse(response, type, 'result', 1);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const approveReceivePackageRegistrationFormService = async (
  headers,
  body,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) => {
  const { FAIL } = type;
  const { id, ...newBody } = body;
  try {
    const response = await callApi(REGISTRATION_FORM_RECEIVE_PACKAGE_APPROVE(id), newBody, METHOD.PUT, {
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const cancelReceivePackageRegistrationFormService = async (
  headers,
  body,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) => {
  const { FAIL } = type;
  const { id, ...newBody } = body;
  try {
    const response = await callApi(REGISTRATION_FORM_RECEIVE_PACKAGE_CANCEL(id), newBody, METHOD.PUT, {
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const receivedReceivePackageRegistrationFormService = async (
  headers,
  body,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) => {
  const { FAIL } = type;
  const { id, ...newBody } = body;
  try {
    const response = await callApi(REGISTRATION_FORM_RECEIVE_PACKAGE_RECEIVED(id), newBody, METHOD.PUT, {
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const completeReceivePackageRegistrationFormService = async (
  headers,
  body,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) => {
  const { FAIL } = type;
  const { id, ...newBody } = body;
  try {
    const response = await callApi(REGISTRATION_FORM_RECEIVE_PACKAGE_COMPLETE(id), newBody, METHOD.PUT, {
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};
