import { addEnterpriseService, deleteEnterpriseService, updateEnterpriseService } from '../services/enterprise';
import { call, put } from 'redux-saga/effects';
import { ENTERPRISE } from '../constants/actionType';

export function* addEnterprise(action) {
    const {
        shortName,
        name,
        address,
        phone,
        fax,
        representative,
        title,
        taxCode,
        businessCode,
        businessRegistrationDate,
        businessRegistrationPlace,
        buildingId,
    } = action.payload.enterprise;

    const enterprise = {
        shortName,
        name,
        address,
        phone,
        fax,
        representative,
        title,
        taxCode,
        businessCode,
        businessRegistrationDate,
        businessRegistrationPlace,
        unitGroupId: buildingId,
    };

    const result = yield call(addEnterpriseService, enterprise, ENTERPRISE.ADD);

    yield put(result);

    yield put({
        type: ENTERPRISE.ADD.REFRESH,
    });
}

export function* updateEnterprise(action) {
    const {
        id,
        shortName,
        name,
        address,
        phone,
        fax,
        representative,
        title,
        taxCode,
        businessCode,
        businessRegistrationDate,
        businessRegistrationPlace,
        buildingId,
    } = action.payload.enterprise;

    const enterprise = {
        id,
        shortName,
        name,
        address,
        phone,
        fax,
        representative,
        title,
        taxCode,
        businessCode,
        businessRegistrationDate,
        businessRegistrationPlace,
        unitGroupId: buildingId,
    };

    const result = yield call(updateEnterpriseService, enterprise, ENTERPRISE.UPDATE);

    yield put(result);

    yield put({
        type: ENTERPRISE.UPDATE.REFRESH,
    });
}

export function* deleteEnterprise(action) {
    const {
        enterpriseId,
    } = action.payload;
    const result = yield call(deleteEnterpriseService, enterpriseId, ENTERPRISE.DELETE);
    yield put(result);
    yield put({
        type: ENTERPRISE.DELETE.REFRESH,
    });
}