import paginate, { initStatePagination } from '../paginate';
import { FORM_ASSET } from '../../constants';
import { combineReducers } from 'redux';


export const initState = {
    maintenanceUnits: {
        ...initStatePagination,
    },
    departments: {
        ...initStatePagination,
    },
    suppliers: {
        ...initStatePagination,
    },
    units: {
        ...initStatePagination,
    },
    floors: {
        ...initStatePagination,
    },
};

const maintenanceUnits = paginate(FORM_ASSET.GET_MAINTENANCE_UNIT);
const departments = paginate(FORM_ASSET.GET_DEPARTMENT);
const suppliers = paginate(FORM_ASSET.GET_SUPPLY);
const units = paginate(FORM_ASSET.GET_UNIT);
const floors = paginate(FORM_ASSET.GET_FLOOR);

export default combineReducers({
    maintenanceUnits,
    departments,
    suppliers,
    units,
    floors,
});