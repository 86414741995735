import { combineReducers } from 'redux';
import paginate, { initStatePagination } from 'shared/utils/paginate';
import fetching, { initStateFetching } from 'shared/utils/fetching';
import { APARTMENT, FLOOR } from './apartment.types';

export const initState = {
  apartmentInit: {
    ...initStateFetching,
  },
  apartmentEmpty: {
    ...initStatePagination,
  },
  apartmentInUse: {
    ...initStatePagination,
  },
  apartmentGets: {
    ...initStatePagination,
  },
  floor: {
    ...initStateFetching,
  },
  exportApartment: {
    ...initStateFetching,
  },
  labelData: {
    ...initStatePagination,
  },
  dataExport: '',
};

function dataExport(state = initState, action) {
  switch (action.type) {
    case APARTMENT.EXPORT.SUCCESS:
      return action.payload.result;
    default:
      return state;
  }
}

const reducer = combineReducers({
  apartmentInit: fetching(APARTMENT.INIT),
  apartmentGets: paginate(APARTMENT.GETS),
  apartmentEmpty: paginate(APARTMENT.GETS_EMPTY),
  apartmentInUse: paginate(APARTMENT.GETS_IN_USE),
  exportApartment: fetching(APARTMENT.EXPORT),
  labelData: paginate(APARTMENT.LABEL_GETS),
  floor: fetching(FLOOR.GETS),
  dataExport,
});

export default reducer;
