import {
    getContractTemplateByFormulaService,
    getContractTemplateByPromotionService,
    getContractTemplateBySurchargeService,
    getIcons,
} from '../services';
import { call, put } from 'redux-saga/effects';
import { FORM_SERVICE, X_UNIT_GROUP_ID } from '../constants';

export function* initFormService(action) {
    const {
        buildingId,
    } = action.payload;

    const resultIcon = yield call(getIcons);

    yield put({
        type: FORM_SERVICE.INIT.SUCCESS,
        payload: {
            code: 200,
            icons: resultIcon.payload.result,
        },
    });

}

export function* getContractTemplateByFormula(action) {
    const {
        buildingId,
        formulaId,
    } = action.payload;
    const result = yield call(getContractTemplateByFormulaService, {
        params: {
            formulaId,
        },
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, FORM_SERVICE.CONTRACT_TEMPLATE.FORMULA_GETS);
    result.payload.formulaId = formulaId;
    yield put(result);
}

export function* getContractTemplateBySurcharge(action) {
    const {
        buildingId,
        surchargeId,
    } = action.payload;
    const result = yield call(getContractTemplateBySurchargeService, {
        params: {
            surchargeId,
        },
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, FORM_SERVICE.CONTRACT_TEMPLATE.SURCHARGE_GETS);
    result.payload.surchargeId = surchargeId;
    yield put(result);
}

export function* getContractTemplateByPromotion(action) {
    const {
        buildingId,
        promotionId,
    } = action.payload;
    const result = yield call(getContractTemplateByPromotionService, {
        params: {
            promotionId,
        },
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, FORM_SERVICE.CONTRACT_TEMPLATE.PROMOTION_GETS);
    result.payload.promotionId = promotionId;
    yield put(result);
}