import { callApi } from './api';
import {
  ANNOUNCEMENT_GETS,
  ANNOUNCEMENT_SEND,
  ANNOUNCEMENT_DELETE,
  FLOOR_GET_LIST,
  OVERALL_TOTAL_ANNOUNCEMENT_FILTER_BY_TYPE,
  OVERALL_TOTAL_ANNOUNCEMENT_FILTER_BY_DATE,
  OVERALL_TOTAL_ANNOUNCEMENT_FILTER_BY_LABEL,
  OVERALL_TOTAL_ANNOUNCEMENT_FILTER_ALL,
  COMMUNICATE_UNIT_SEEN_GETS,
  COMMUNICATE_UNIT_SEND_BY_APP_GETS,
  COMMUNICATE_UNIT_SEND_BY_EMAIL_GETS,
  COMMUNICATE_UNIT_VOTE_GETS,
  COMMUNICATE_RESEND_EMAIL_GET
} from '../defined/API';
import { METHOD, X_UNIT_GROUP_ID } from '../constants';
import { handleError, handleResponse } from '../libs/http';

export async function getAnnouncementService(buildingId, page = 1, limit = 20, params, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;

  try {
    let response = await callApi(ANNOUNCEMENT_GETS, {}, METHOD.GET, {
      params: {
        ...params,
        page,
        limit,
      },
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      }
    });
    return handleResponse(response, type, 'result', page, limit);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function sendAnnouncementService(buildingId, body, type) {
  const { FAIL } = type;

  const options = {
    headers: { [X_UNIT_GROUP_ID]: buildingId },
  };

  try {
    let response = await callApi(ANNOUNCEMENT_SEND, body, METHOD.POST, options);
    return handleResponse(response, type, 'result');
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function deleteAnnouncementService(buildingId, annoucementId, type) {
  const { FAIL } = type;

  const options = {
    headers: { [X_UNIT_GROUP_ID]: buildingId },
  };

  try {
    let response = await callApi(ANNOUNCEMENT_DELETE(annoucementId), {}, METHOD.DELETE, options);
    return handleResponse(response, type, 'result');
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getFloorsService(buildingId, type) {
  const { FAIL } = type;
  try {
    let response = await callApi(FLOOR_GET_LIST, {}, METHOD.GET, {
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      }
    });
    return handleResponse(response, type, 'result');
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getTotalAnnouncementByTypeService({ params, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;
  try {
    let response = await callApi(OVERALL_TOTAL_ANNOUNCEMENT_FILTER_BY_TYPE, {}, METHOD.GET, {
      params: params,
      headers: headers
    });
    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}
export async function getTotalAnnouncementByDateService({ params, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;
  try {
    let response = await callApi(OVERALL_TOTAL_ANNOUNCEMENT_FILTER_BY_DATE, {}, METHOD.GET, {
      params: params,
      headers: headers
    });
    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}
export async function getTotalAnnouncementByAllService({ params, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;
  try {
    let response = await callApi(OVERALL_TOTAL_ANNOUNCEMENT_FILTER_ALL, {}, METHOD.GET, {
      params: params,
      headers: headers
    });
    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}
export async function getTotalAnnouncementByLabelService({ params, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;
  try {
    let response = await callApi(OVERALL_TOTAL_ANNOUNCEMENT_FILTER_BY_LABEL, {}, METHOD.GET, {
      params: params,
      headers: headers
    });
    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getUnitSeenService({ params, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;
  try {
    let response = await callApi(COMMUNICATE_UNIT_SEEN_GETS(params.annouceId), {}, METHOD.GET, {
      params: {
        keyword: params.keyword,
        page: params.page,
        limit: params.limit
      },
      headers: headers
    });
    return handleResponse(response, type, 'result', params.page, params.limit);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getUnitSendByEmailService({ params, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;
  try {
    let response = await callApi(COMMUNICATE_UNIT_SEND_BY_EMAIL_GETS(params.annouceId), {}, METHOD.GET, {
      params: {
        keyword: params.keyword,
        page: params.page,
        limit: params.limit,
        emailStatus: params.emailStatus
      },
      headers: headers
    });
    return handleResponse(response, type, 'result', params.page, params.limit);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getUnitSendByAppService({ params, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;
  try {
    let response = await callApi(COMMUNICATE_UNIT_SEND_BY_APP_GETS(params.annouceId), {}, METHOD.GET, {
      params: {
        keyword: params.keyword,
        page: params.page,
        limit: params.limit
      },
      headers: headers
    });
    return handleResponse(response, type, 'result', params.page, params.limit);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getUnitVoteService({ params, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;
  try {
    let response = await callApi(COMMUNICATE_UNIT_VOTE_GETS(params.annouceId), {}, METHOD.GET, {
      params: {
        keyword: params.keyword,
        page: params.page,
        limit: params.limit,
        pollId: params.pollId
      },
      headers: headers
    });
    return handleResponse(response, type, 'result', params.page, params.limit);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function resendEmailService({ params, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;
  try {
    let response = await callApi(COMMUNICATE_RESEND_EMAIL_GET(params.annouceId), { unitId: params.unitId }, METHOD.POST, {
      headers: headers
    });
    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}


