import { handleError, handleResponse } from '../libs/http';
import { callApi, callApiWithFile, downloadFile } from './api';
import { METHOD, X_UNIT_GROUP_ID } from '../constants';
import { PARTNER, PARTNER_DELETE, PARTNER_GETS, PARTNER_IMPORT, PARTNER_UPDATE, UNIT_IMPORT, PARTNER_EXPORT } from '../defined/API';

export const getPartnerService = async ({ unitGroupId, params = {} }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(PARTNER_GETS, {}, METHOD.GET, {
            params,
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type, 'result', params.page, params?.limit);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const addPartnerService = async ({ body, headers }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(PARTNER, body, METHOD.POST, {
            headers,
        });
        return handleResponse(response, type, 'partner');
    } catch (e) {
        return handleError(e, FAIL);
    }
};


export const updatePartnerService = async ({ id, body, headers }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(PARTNER_UPDATE(id), body, METHOD.PUT, {
            headers,
        });
        return handleResponse(response, type, 'partner');
    } catch (e) {
        handleError(e, FAIL);
    }
};

export const deletePartnerService = async ({ partnerId, unitGroupId }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(PARTNER_DELETE(partnerId), {}, METHOD.DELETE, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export async function importPartnerService(unitGroupId, file, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApiWithFile(PARTNER_IMPORT, file, METHOD.POST, 'file', {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type, 'result', 1);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export const exportPartnerService = async ({ headers, params = {} }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await downloadFile(PARTNER_EXPORT, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type, 'result');
    } catch (e) {
        return handleError(e, FAIL);
    }
};
