import { RESIDENT, BUILDING } from '../constants/actionType';

export const initDataResidentPage = (buildingId: string, dataFetching: Object) => ({
    type: RESIDENT.INIT.REQUEST,
    payload: {
        buildingId,
        dataFetching,
    },
});

export const getResidents = () => ({
    type: BUILDING.GETS.REQUEST,
});

export const getResidentById = (idResident: number) => ({
    type: RESIDENT.GET_BY_ID.REQUEST,
    idResident,
});

export const refreshGetResidentById = () => ({
    type: RESIDENT.GET_BY_ID.REFRESH,
});

export const addResident = (resident: Object) => ({
    type: RESIDENT.ADD.REQUEST,
    payload: {
        resident,
    },
});

export const updateResident = (resident: Object) => ({
    type: RESIDENT.UPDATE.REQUEST,
    payload: {
        resident: resident,
    },
});

export const deleteResident = ({ array, filterObject, isAll }, unitGroupId) => ({
    type: RESIDENT.DELETE.REQUEST,
    payload: {
        unitGroupId,
        array,
        filterObject,
        isAll,
    },
});

export const refreshDeleteResident = () => ({
    type: RESIDENT.DELETE.REFRESH,
});

export const getDataResidentPage = (buildingId, filterObject, page) => ({
    type: RESIDENT.GETS.REQUEST,
    payload: {
        page,
        buildingId,
        filterObject,
    },
});

export const activeAccountResident = (resident: Object) => ({
    type: RESIDENT.ACTIVE_ACCOUNT.REQUEST,
    payload: {
        resident,
    },
});

export const inactiveAccountResident = (resident: Object) => ({
    type: RESIDENT.INACTIVE_ACCOUNT.REQUEST,
    payload: {
        resident,
    },
});

export const refreshActiveAccount = () => ({
    type: RESIDENT.ACTIVE_ACCOUNT.REFRESH,
});

export const refreshInactiveAccount = () => ({
    type: RESIDENT.INACTIVE_ACCOUNT.REFRESH,
});

export const importResident = (unitGroupId, file) => ({
    type: RESIDENT.IMPORT.REQUEST,
    payload: {
        unitGroupId,
        file,
    },
});

export const resetDataImportResident = () => ({
    type: RESIDENT.IMPORT.SUCCESS,
    payload: {
        currentPage: 0,
        totalPage: 0,
        totalRecord: 0,
        result: [],
    },
});

export const exportResident = (buildingId, filterObject) => ({
    type: RESIDENT.EXPORT.REQUEST,
    payload: {
        buildingId,
        filterObject,
    },
});
