import moment from 'moment';
import { language } from 'defined/Language';

export const VIEW_TYPE_OVERALL = 'VIEW_TYPE_OVERALL';
export const VIEW_TYPE_SERVICE = 'VIEW_TYPE_SERVICE';
export const CREATED_FOR_TYPE_DEBT_MONTH = 'DEBT_MONTH';
export const CREATED_FOR_TYPE_INVOICE_MONTH = 'INVOICE_MONTH';
export const EXPORT_TYPE_DEFAULT = 'DEFAULT';
export const EXPORT_TYPE_REVENUE = 'REVENUE';
export const EXPORT_TYPE_DEBT = 'DEBT';
export const EXPORT_TYPE_REMAINING_DEBT = 'REMAINING_DEBT';

export const DEFAULT_FILTER = {
  unitName: '',
  serviceId: undefined,
  time: [moment(), moment()],
  viewType: VIEW_TYPE_OVERALL,
  createdForType: CREATED_FOR_TYPE_INVOICE_MONTH,
  customerCode: undefined,
};

export const CREATED_FOR_TYPES = [
  {
    id: CREATED_FOR_TYPE_DEBT_MONTH,
    name: language.created_for_type_debt_month,
  },
  {
    id: CREATED_FOR_TYPE_INVOICE_MONTH,
    name: language.created_for_type_invoice_month,
  },
];

export const VIEW_TYPES = [
  {
    id: VIEW_TYPE_OVERALL,
    name: language.view_type_overall,
  },
  {
    id: VIEW_TYPE_SERVICE,
    name: language.view_type_service,
  },
];

export const EXPORT_TYPES = [
  { key: EXPORT_TYPE_DEFAULT, label: language.default },
  { key: EXPORT_TYPE_REVENUE, label: language.revenue_service },
  { key: EXPORT_TYPE_DEBT, label: language.debt },
  { key: EXPORT_TYPE_REMAINING_DEBT, label: language.remaining_debt },
];
