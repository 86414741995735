import { APARTMENT_DETAIL } from '../constants/actionType';

export const initApartmentDetail = (unitGroupId, unitId) => ({
    type: APARTMENT_DETAIL.INIT.REQUEST,
    payload: {
        unitGroupId,
        unitId,
    },
});

export const getApartmentById = ({ unitId, unitGroupId }) => ({
    type: APARTMENT_DETAIL.APARTMENT_INFO.GET.REQUEST,
    payload: {
        unitId,
        unitGroupId,
    },
});

export const getApartmentByIdRefresh = () => ({
    type: APARTMENT_DETAIL.APARTMENT_INFO.GET.REFRESH,
});

export const getResidentsByApartment = ({ unitId, unitGroupId }) => ({
    type: APARTMENT_DETAIL.RESIDENT.GETS_BY_APARTMENT.REQUEST,
    payload: {
        unitGroupId,
        unitId,
    },
});

export const addResidentToApartment = (resident) => ({
    type: APARTMENT_DETAIL.RESIDENT.ADD.REQUEST,
    payload: {
        resident,
    },
});

export const refreshAddResidentToApartment = () => ({
    type: APARTMENT_DETAIL.RESIDENT.ADD.REFRESH,
});

export const updateResidentToApartment = (resident) => ({
    type: APARTMENT_DETAIL.RESIDENT.UPDATE.REQUEST,
    payload: {
        resident
    },
});

export const refreshUpdateResidentToApartment = () => ({
    type: APARTMENT_DETAIL.RESIDENT.UPDATE.REFRESH,
});

export const deleteResidentToApartment = (residentId, unitGroupId) => ({
    type: APARTMENT_DETAIL.RESIDENT.DELETE.REQUEST,
    payload: {
        residentId,
        unitGroupId,
    },
});

export const refreshDeleteResidentToApartment = () => ({
    type: APARTMENT_DETAIL.RESIDENT.DELETE.REFRESH,
});


// document

export const getDocumentsByApartment = ({ unitId, unitGroupId }) => ({
    type: APARTMENT_DETAIL.DOCUMENT.GETS_BY_APARTMENT.REQUEST,
    payload: {
        unitId,
        unitGroupId,
    },
});

export const addDocumentsToApartment = ({ document, unitGroupId }) => ({
    type: APARTMENT_DETAIL.DOCUMENT.ADD.REQUEST,
    payload: {
        document,
        unitGroupId,
    },
});

export const refreshAddDocumentToApartment = () => ({
    type: APARTMENT_DETAIL.DOCUMENT.ADD.REFRESH,
});

export const deleteDocumentToApartment = (id, unitGroupId) => ({
    type: APARTMENT_DETAIL.DOCUMENT.DELETE.REQUEST,
    payload: {
        id,
        unitGroupId,
    }
});

export const refreshDeleteDocumentToApartment = () => ({
    type: APARTMENT_DETAIL.DOCUMENT.DELETE.REFRESH,
});

export const updateDocumentToApartment = ({ document, unitGroupId }) => ({
    type: APARTMENT_DETAIL.DOCUMENT.UPDATE.REQUEST,
    payload: {
        document,
        unitGroupId,
    },
});

export const createReceiptPageApartmentDetail = (receipt, unitGroupId) => ({
    type: APARTMENT_DETAIL.RECEIPT.CREATE.REQUEST,
    payload: {
        receipt,
        unitGroupId,
    },
});

export const getInfoPayment = (unitGroupId, unitId) => ({
    type: APARTMENT_DETAIL.PAYMENT_INFO_GET.REQUEST,
    payload: {
        unitGroupId,
        unitId,
    }
})

export const getBillUnpaidInPageApartmentDetail = ({
    unitGroupId,
    unitId,
    page,
}) => ({
    type: APARTMENT_DETAIL.BILL.GETS.REQUEST,
    payload: {
        unitGroupId,
        page,
        unitId,
    },
});

export const getWalletUnitInPageApartmentDetail = ({
    unitId,
    unitGroupId,
}) => ({
    type: APARTMENT_DETAIL.WALLET.GETS.REQUEST,
    payload: {
        unitGroupId,
        unitId,
    }
});

export const getQRCodeApartmentDetail = ({
    unitId,
    unitGroupId
}) => ({
    type: APARTMENT_DETAIL.QRCODE.GET.REQUEST,
    payload: {
        unitId,
        unitGroupId,
    }
});

export const exportQRCodeApartmentDetail = ({
    unitIds,
    unitGroupId,
    isAll,
    filterObject,
}) => ({
    type: APARTMENT_DETAIL.QRCODE.EXPORT.REQUEST,
    payload: {
        unitIds,
        unitGroupId,
        isAll,
        filterObject,
    }
});

export const refreshQRCodeApartmentDetail = ({
    unitIds,
    unitGroupId,
    isAll,
    filterObject,
    getAfterRefresh
}) => ({
    type: APARTMENT_DETAIL.QRCODE.REFRESH.REQUEST,
    payload: {
        unitIds,
        unitGroupId,
        isAll,
        filterObject,
        getAfterRefresh
    }
});

// init tab service history
export const initTabServiceHistory = ({
    unitGroupId,
    apartmentId,
}) => ({
    type: APARTMENT_DETAIL.SERVICE_HISTORY.INIT.REQUEST,
    payload: {
        unitGroupId,
        apartmentId,
    }
});

export const getServiceHistory = ({
    unitGroupId,
    unitId,
    serviceId,
    from,
    to,
    type,
    page,
}) => ({
    type: APARTMENT_DETAIL.SERVICE_HISTORY.GETS.REQUEST,
    payload: {
        unitGroupId,
        unitId,
        serviceId,
        from,
        to,
        type,
        page,
    }
});

export const getPaymentHistory = ({
    unitGroupId,
    unitId,
    from,
    to,
    code,
    payer,
    type,
    paymentMethod,
    page,
}) => ({
    type: APARTMENT_DETAIL.PAYMENT_HISTORY.GETS.REQUEST,
    payload: {
        unitGroupId,
        unitId,
        from,
        to,
        code,
        payer,
        type,
        paymentMethod,
        page,
    }
});

export const getPaymentHistoryPreview = ({
    id,
    unitGroupId,
    isReceipt,
}) => ({
    type: APARTMENT_DETAIL.PAYMENT_HISTORY.PREVIEW.REQUEST,
    payload: {
        id,
        unitGroupId,
        isReceipt,
    }
});

export const resetPaymentHistoryPreview = () => ({
    type: APARTMENT_DETAIL.PAYMENT_HISTORY.PREVIEW.SUCCESS,
    payload: {
        isFetching: false,
        data: '',
        code: undefined,
        codeLanguage: undefined,
    },
});
