import {
    APARTMENT_DEBT_TYPE,
    SERVICE_DEBT_TYPE,
    SERVICE_DEBT_TYPE_ARISE_BY_MONTH,
    X_UNIT_GROUP_ID,
} from '../../constants';
import { STATISTIC } from '../../constants/actionType';
import { call, put, select } from 'redux-saga/effects';
import { exportBillService, getBillsService, sumPaymentService } from '../../services';
import {
    exportReportServiceByMonthService,
    exportReportServiceFromToService, getReportServiceByMonthService,
    getReportServiceFromToService, getSumPaymentReportServiceByMonthService,
    getSumPaymentReportServiceFromToService,
} from 'services/cashbook';
import moment from 'moment';

export function* initTabDebtPageReport(action) {
    const {
        unitGroupId,
    } = action.payload;

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const resDebt = yield call(getBillsService, {
        headers,
    },
        STATISTIC.DEBT.GETS);
    yield put(resDebt);

    // const codeService = yield select(state => state.report.services.code);
    // if (!codeService) {
    //     const resService = yield call(getServiceInUseService, {
    //         headers,
    //     }, STATISTIC.SERVICE.GETS);
    //     yield put(resService);
    // }

    yield put({
        type: STATISTIC.DEBT.INIT.SUCCESS,
        payload: {
            code: 200,
        },
    });
}

export function* getDataDebtReport(action) {
    const {
        unitGroupId,
        filterObject,
        page,
        type,
    } = action.payload;

    let { from, to } = filterObject;
    if (from && to && typeof from !== 'string' && moment(from) > moment(to)) {
        yield put({
            type: STATISTIC.DEBT.GETS.SUCCESS,
            payload: {
                data: [],
            }
        });
        return;
    }

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const params = {
        ...filterObject,
        page,
        limit: 20,
    };
    let result;
    if (type === APARTMENT_DEBT_TYPE) {
        result = yield call(getBillsService, { params, headers }, STATISTIC.DEBT.GETS);
    } else if (type === SERVICE_DEBT_TYPE) {
        result = yield call(getReportServiceFromToService, { params, headers }, STATISTIC.DEBT.GETS);
    } else if (type === SERVICE_DEBT_TYPE_ARISE_BY_MONTH) {
        result = yield call(getReportServiceByMonthService, { params, headers }, STATISTIC.DEBT.GETS);
    }

    yield put(result);
}

export function* exportDebtInPageReport(action) {
    const {
        unitGroupId,
        filterObject,
        type,
    } = action.payload;
    const params = {
        ...filterObject,
    };
    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };
    let result;
    if (type === APARTMENT_DEBT_TYPE) {
        result = yield call(exportBillService, { params, headers }, STATISTIC.DEBT.EXPORT);
    } else if (type === SERVICE_DEBT_TYPE) {
        result = yield call(exportReportServiceFromToService, { params, headers }, STATISTIC.DEBT.EXPORT);
    } else if (type === SERVICE_DEBT_TYPE_ARISE_BY_MONTH) {
        result = yield call(exportReportServiceByMonthService, { params, headers }, STATISTIC.DEBT.EXPORT);
    }
    yield put(result);
    yield put({
        type: STATISTIC.DEBT.EXPORT.REFRESH,
    });
}

export function* getSumTotalPaymentListBillUnpaid(action) {
    const {
        unitGroupId,
        filterObject,
        type,
    } = action.payload;

    let { from, to } = filterObject;

    if (from && to && moment(from) > moment(to)) {
        yield put({
            type: STATISTIC.DEBT.SUM_TOTAL_PAYMENT.SUCCESS,
            payload: {
                data: {},
            }
        });
        return;
    }

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const params = {
        ...filterObject
    };

    let result;
    if (type === APARTMENT_DEBT_TYPE) {
        result = yield call(sumPaymentService, { params, headers }, STATISTIC.DEBT.SUM_TOTAL_PAYMENT);
    } else if (type === SERVICE_DEBT_TYPE) {
        result = yield call(getSumPaymentReportServiceFromToService, { params, headers }, STATISTIC.DEBT.SUM_TOTAL_PAYMENT);
    } else if (type === SERVICE_DEBT_TYPE_ARISE_BY_MONTH) {
        result = yield call(getSumPaymentReportServiceByMonthService, { params, headers }, STATISTIC.DEBT.SUM_TOTAL_PAYMENT);
    }

    yield put(result);
}
