import { CONTRACT_TEMPLATE } from '../constants/actionType';
import { PAGINATION } from '../constants';

export const initContractTemplate = (buildingId = 0) => ({
    type: CONTRACT_TEMPLATE.INIT.REQUEST,
    payload: {
        buildingId
    }
});

export const addContractTemplate = (contractTemplate: Object) => ({
    type: CONTRACT_TEMPLATE.ADD.REQUEST,
    payload: {
        contractTemplate,
    },
});

export const updateContractTemplate = (contractTemplate: Object) => ({
    type: CONTRACT_TEMPLATE.UPDATE.REQUEST,
    payload: {
        contractTemplate
    }
});

export const getContractTemplate = (buildingId: number = 0, page: number = 1, filterObject: Object = {}, size: number = PAGINATION.LIMIT) => ({
    type: CONTRACT_TEMPLATE.GETS.REQUEST,
    payload: {
        buildingId,
        page,
        filterObject,
        size,
    },
});

export const deleteContractTemplate = (contractTemplateId, buildingId) => ({
    type: CONTRACT_TEMPLATE.DELETE.REQUEST,
    payload: {
        contractTemplateId,
        buildingId,
    },
});