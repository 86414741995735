import { FORM_TARGET_INFO, PAGINATION } from '../constants';

export const initFormTargetInfoAdd = () => ({
    type: FORM_TARGET_INFO.INIT_ADD.REQUEST,
});

export const initFormTargetInfoUpdate = (buildingId, contractTemplateId) => ({
    type: FORM_TARGET_INFO.INIT_UPDATE.REQUEST,
    payload: {
        buildingId,
        contractTemplateId,
    },
});

export const resetTargetSelectedInFormTargetInfo = () => ({
    type:FORM_TARGET_INFO.RESET_DATA_SELECTED.SUCCESS
});

export const getTargetsSelectedInFormTargetInfo = (buildingId, filterObject) => ({
    type: FORM_TARGET_INFO.GETS_TARGET_WHEN_CHECK_ALL.REQUEST,
    payload: {
        buildingId,
        filterObject,
    },
});

export const getTargetsInFormTargetInfo = (buildingId, filterObject, page, size) => ({
    type: FORM_TARGET_INFO.GETS.REQUEST,
    payload: {
        buildingId,
        page,
        size,
        filterObject,
    },
});

export const getTargetsWhenClickAllInFormTargetInfo = (buildingId, filterObject) => ({
    type: FORM_TARGET_INFO.GETS_TARGET_WHEN_CHECK_ALL.REQUEST,
    payload: {
        buildingId,
        filterObject,
    },
});