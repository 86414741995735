import {
    ACTIVITY_LOG_GETS,
    ACTIVITY_MANAGER_GETS,
    ACTIVITY_EXPORT_GETS
} from '../defined/API';
import { handleError, handleResponse } from '../libs/http';
import { METHOD } from '../constants';
import { callApi } from './api';

export async function getActivityLogService({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        let response = await callApi(ACTIVITY_LOG_GETS, {}, METHOD.GET, {
            params: params,
            headers: headers
        });
        return handleResponse(response, type, 'result', params.page);
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}

export async function getManagersService({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        let response = await callApi(ACTIVITY_MANAGER_GETS, {}, METHOD.GET, {
            params: params,
            headers: headers
        });
        return handleResponse(response, type);
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}

export async function exportActivityLogService({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        let response = await callApi(ACTIVITY_LOG_GETS, {}, METHOD.GET, {
            params: params,
            headers: headers
        });
        return handleResponse(response, type, 'result');
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}
