import { combineReducers } from 'redux';
import paginate, { initStatePagination } from 'shared/utils/paginate';
import { ADVANCE_PAYMENT_GETS, ADVANCE_PAYMENT_TOTAL_GET, ADVANCE_PAYMENT_EXPORT } from './advancePayment.types';

export const initState = {
  data: {
    ...initStatePagination,
  },
  export: {
    ...initStatePagination,
  },
  total: {
    ...initStatePagination,
  },
};

export default combineReducers({
  data: paginate(ADVANCE_PAYMENT_GETS),
  total: paginate(ADVANCE_PAYMENT_TOTAL_GET),
  export: paginate(ADVANCE_PAYMENT_EXPORT),
});
