import { combineReducers } from 'redux';
import { DEBT } from './service-update-until-current-time.types';

export const initState = {
  gets: {
    code: undefined,
    codeLanguage: undefined,
    isLoading: false,
    totalRecord: 0,
    data: [],
  },
  total: {
    isLoading: false,
    total: {},
  },
  exportDefault: {
    isLoading: false,
    data: undefined,
  },
  exportRevenue: {
    isLoading: false,
    data: undefined,
  },
  exportDebt: {
    isLoading: false,
    data: undefined,
  },
  exportRemainingDebt: {
    isLoading: false,
    data: undefined,
  },
};

const reducer = combineReducers({
  gets: (state = initState.gets, action) => {
    switch (action.type) {
      case DEBT.GETS.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case DEBT.GETS.SUCCESS:
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          totalRecord: action.payload.totalRecord,
          data: action.payload.result,
        };
      case DEBT.GETS.FAIL:
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case DEBT.GETS.REFRESH:
        return {
          ...initState.gets,
        };
      default:
        return state;
    }
  },
  total: (state = initState.total, action) => {
    switch (action.type) {
      case DEBT.TOTAL.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case DEBT.TOTAL.SUCCESS:
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          total: action.payload.result,
        };
      case DEBT.TOTAL.FAIL:
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case DEBT.TOTAL.REFRESH:
        return {
          ...initState.total,
        };
      default:
        return state;
    }
  },
  exportDefault: (state = initState.exportDefault, action) => {
    switch (action.type) {
      case DEBT.EXPORT_DEFAULT.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case DEBT.EXPORT_DEFAULT.SUCCESS:
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          data: action.payload.result,
        };
      case DEBT.EXPORT_DEFAULT.FAIL:
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case DEBT.EXPORT_DEFAULT.REFRESH:
        return {
          ...initState.exportDefault,
        };
      default:
        return state;
    }
  },
  exportRevenue: (state = initState.exportRevenue, action) => {
    switch (action.type) {
      case DEBT.EXPORT_REVENUE.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case DEBT.EXPORT_REVENUE.SUCCESS:
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          data: action.payload.result,
        };
      case DEBT.EXPORT_REVENUE.FAIL:
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case DEBT.EXPORT_REVENUE.REFRESH:
        return {
          ...initState.exportRevenue,
        };
      default:
        return state;
    }
  },
  exportDebt: (state = initState.exportDebt, action) => {
    switch (action.type) {
      case DEBT.EXPORT_DEBT.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case DEBT.EXPORT_DEBT.SUCCESS:
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          data: action.payload.result,
        };
      case DEBT.EXPORT_DEBT.FAIL:
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case DEBT.EXPORT_DEBT.REFRESH:
        return {
          ...initState.exportDebt,
        };
      default:
        return state;
    }
  },
  exportRemainingDebt: (state = initState.exportRemainingDebt, action) => {
    switch (action.type) {
      case DEBT.EXPORT_REMAINING_DEBT.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case DEBT.EXPORT_REMAINING_DEBT.SUCCESS:
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          data: action.payload.result,
        };
      case DEBT.EXPORT_REMAINING_DEBT.FAIL:
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case DEBT.EXPORT_REMAINING_DEBT.REFRESH:
        return {
          ...initState.exportRemainingDebt,
        };
      default:
        return state;
    }
  },
});

export default reducer;
