import { language } from '../defined/Language';
import { convertArrayToObject } from '../libs/convert';

export const CASH = 'CASH';
export const TRANSFER = 'TRANSFER';
export const ONLINE = 'ONLINE';

export const DEAL_METHOD_OPTION = [
    {
        id: CASH,
        name: language['label']['cash'],
    },
    {
        id: TRANSFER,
        name: language['label']['transfer'],
    },
    {
        id: ONLINE,
        name: language['label']['online'],
    }
]

export const FUND_PAYMENT_MODE_ON = 'ON'; // Sẵn sàng có thể thanh toán
export const FUND_PAYMENT_MODE_VIEW = 'VIEW'; // Chỉ hiển thị dạng disabled
export const FUND_PAYMENT_MODE_OFF = 'OFF'; // Ẩn thanh toán

export const FUND_PAYMENT_MODES = [
    { id: FUND_PAYMENT_MODE_ON, name: language.label.payment_mode_on },
    { id: FUND_PAYMENT_MODE_VIEW, name: language.label.payment_mode_view },
    { id: FUND_PAYMENT_MODE_OFF, name: language.label.payment_mode_off }
];

export const DEAL_METHOD_BY_ID = convertArrayToObject(DEAL_METHOD_OPTION);

export const ONLINE_ACCOUNT_OPTION = [
    {
        id: 1,
        code: 'VNPAY_QR',
        name: language['label']['vnpay_qr'],
        fundName: 'Quét mã VNPAY-QR',
        domestic: true
    },
    {
        id: 2,
        code: 'MOMO_IN_APP',
        name: language['label']['momo'],
        fundName: 'Ví MoMo',
        domestic: true
    },
    {
        id: 3,
        code: 'VNPAY_GW',
        name: language['label']['vnpay_gw_domestic'],
        fundName: 'ATM nội địa và tài khoản ngân hàng',
        domestic: true
    },
    {
        id: 4,
        code: 'VNPAY_GW',
        name: language['label']['vnpay_gw_international'],
        fundName: 'Thẻ thanh toán quốc tế (Visa, Mastercard)',
        domestic: false
    },
]
