import {BUILDING} from '../constants/actionType';

export const getBuildings = (managerId: string) => ({
    type: BUILDING.GETS.REQUEST,
    managerId
});

export const getBuildingById = (id) => ({
    type: BUILDING.GET_BY_ID.REQUEST,
    payload: {
        id,
    }
});

export const addBuilding = (building: Object) => ({
    type: BUILDING.ADD.REQUEST,
    payload: {
        building: building
    }
});

export const refreshAddBuilding = () => ({
    type: BUILDING.ADD.REFRESH
});

export const updateBuilding = (building: Object) => ({
    type: BUILDING.UPDATE.REQUEST,
    payload: {
        building: building
    }
});

export const refreshUpdateBuilding = () => ({
    type: BUILDING.UPDATE.REFRESH
});

export const deleteBuildings = (buildings) => ({
    type: BUILDING.DELETE.REQUEST,
    payload: {
        buildings
    },
});

export const refreshDeleteBuildings = () => ({
    type: BUILDING.DELETE.REFRESH
});

export const changeViewTypeBuilding = (viewType: string) => ({
    type: BUILDING.CHANGE_VIEW_TYPE,
    viewType: viewType
});
