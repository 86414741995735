import Cookie from 'defined/Cookie';
import get from 'lodash/get';

const en = require('defined/language/en.json');
const vn = require('defined/language/vn.json');
const jp = require('defined/language/jp.json');

const getLanguage = () => {
    const cache = Cookie.get('cyhome');
    switch (cache.language) {
    case 'en':
        return en;
    case 'vi':
        return vn;
    case 'jp':
        return jp;
    default:
        return vn;
    }
};

export const getCacheLanguage = () => {
    const cache = Cookie.get('cyhome');
    return cache?.language ?? 'vi';
}

export let language = getLanguage();

export const setLanguage = (languageTmp) => {
    const cache = Cookie.get('cyhome');
    Cookie.save('cyhome', {
        ...cache,
        language: languageTmp
    });
};

export const languageBindArgs = (keyLanguage, data) => {
    let tmp = String(get(language, keyLanguage, ''));
    Object.keys(data).map(key => {
        const expression = `{${key}}`;
        const regex = new RegExp(expression, 'i');
        tmp.replace(regex, data[key]);
    });
    return tmp;
};
