import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getsAmenitiesService,
  getAmenitiesService,
  getsPriorityInAppSelectedService,
  addAmenitiesService,
  updateAmenitiesService,
} from 'services/amenities';
import { X_UNIT_GROUP_ID } from 'shared/constants';
import { handleError } from 'shared/utils';
import { AMENITIES } from './amenities.types';
import { convertAmenities } from './amenities.utils';

function* getsAmenities(action) {
  const { page, limit, filterObj, idBuilding } = action.payload;
  const result = yield call(
    getsAmenitiesService,
    {
      params: {
        page,
        limit,
        ...filterObj,
      },
      headers: {
        [X_UNIT_GROUP_ID]: idBuilding,
      },
    },
    AMENITIES.GETS
  );
  yield put({
    ...result,
  });
}

function* getAmenities(action) {
  const { idAmenities, idBuilding } = action.payload;
  const result = yield call(
    getAmenitiesService,
    {
      params: {
        id: idAmenities,
      },
      headers: {
        [X_UNIT_GROUP_ID]: idBuilding,
      },
    },
    AMENITIES.GET
  );
  yield put({
    ...result,
  });
}

function* getPriorityInAppSelected(action) {
  const { idBuilding } = action.payload;
  const result = yield call(
    getsPriorityInAppSelectedService,
    {
      headers: {
        [X_UNIT_GROUP_ID]: idBuilding,
      },
    },
    AMENITIES.GET_PRIORITY_IN_APP_SELECTED
  );
  yield put({
    ...result,
  });
}

function* addAmenities(action) {
  try {
    const amenities = {
      ...action.payload,
    };

    const result = yield call(
      addAmenitiesService,
      {
        body: convertAmenities(amenities),
        headers: {
          [X_UNIT_GROUP_ID]: amenities.unitGroupId,
        },
      },
      AMENITIES.ADD
    );

    yield put({
      ...result,
    });
  } catch (error) {
    yield put({
      ...handleError(error, AMENITIES.ADD.FAIL),
    });
  }
}

function* updateAmenities(action) {
  try {
    const amenities = {
      ...action.payload,
    };
    const result = yield call(
      updateAmenitiesService,
      {
        body: convertAmenities(amenities),
        headers: {
          [X_UNIT_GROUP_ID]: amenities.unitGroupId,
        },
      },
      AMENITIES.UPDATE
    );
    yield put({
      ...result,
    });
  } catch (error) {
    yield put({
      ...handleError(error, AMENITIES.UPDATE.FAIL),
    });
  }
}

export default function* watch() {
  yield all([
    takeLatest(AMENITIES.GETS.REQUEST, getsAmenities),
    takeLatest(AMENITIES.GET.REQUEST, getAmenities),
    takeLatest(AMENITIES.GET_PRIORITY_IN_APP_SELECTED.REQUEST, getPriorityInAppSelected),
    takeLatest(AMENITIES.ADD.REQUEST, addAmenities),
    takeLatest(AMENITIES.UPDATE.REQUEST, updateAmenities),
  ]);
}
