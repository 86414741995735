import { AMENITIES_MERCHANT_ORDER } from './amenities.types';

export const gets = ({ filter, unitGroupId }) => ({
  type: AMENITIES_MERCHANT_ORDER.GETS.REQUEST,
  payload: {
    filter,
    unitGroupId,
  },
});

export const exportData = ({ filter, unitGroupId }) => ({
  type: AMENITIES_MERCHANT_ORDER.EXPORT.REQUEST,
  payload: {
    filter,
    unitGroupId,
  },
});

export const refreshExportData = () => ({
  type: AMENITIES_MERCHANT_ORDER.EXPORT.REFRESH,
});
