import { combineReducers } from 'redux';
import { PAYMENT_ONLINE } from './payment-online.types';

export const initState = {
    gets: {
        code: undefined,
        codeLanguage: undefined,
        isLoading: false,
        totalRecord: 0,
        data: []
    },
    getReceipt: {
        code: undefined,
        codeLanguage: undefined,
        isLoading: false,
        data: undefined
    },
    // total: {
    //     code: undefined,
    //     codeLanguage: undefined,
    //     isLoading: false,
    //     total: {}
    // },
    export: {
        code: undefined,
        codeLanguage: undefined,
        isLoading: false,
        data: undefined
    }
};

const reducer = combineReducers({
    gets: (state = initState.gets, action) => {
        switch (action.type) {
            case PAYMENT_ONLINE.GETS.REQUEST:
                return {
                    ...state,
                    code: undefined,
                    codeLanguage: undefined,
                    isLoading: true
                }
            case PAYMENT_ONLINE.GETS.SUCCESS:
                return {
                    ...state,
                    code: action.payload.code,
                    codeLanguage: action.payload.codeLanguage,
                    isLoading: false,
                    totalRecord: action.payload.totalRecord,
                    data: action.payload.result
                }
            case PAYMENT_ONLINE.GETS.FAIL:
                return {
                    ...state,
                    code: action.payload.code,
                    codeLanguage: action.payload.codeLanguage,
                    isLoading: false
                }
            case PAYMENT_ONLINE.GETS.REFRESH:
                return {
                    ...state,
                    code: undefined,
                    codeLanguage: undefined,
                    isLoading: false,
                    totalRecord: 0,
                    data: []
                }
            default: return state;
        }
    },
    getReceipt: (state = initState.getReceipt, action) => {
        switch (action.type) {
            case PAYMENT_ONLINE.GET_RECEIPT.REQUEST:
                return {
                    ...state,
                    code: undefined,
                    codeLanguage: undefined,
                    isLoading: true
                }
            case PAYMENT_ONLINE.GET_RECEIPT.SUCCESS:
                return {
                    ...state,
                    code: action.payload.code,
                    codeLanguage: action.payload.codeLanguage,
                    isLoading: false,
                    data: action.payload.result
                }
            case PAYMENT_ONLINE.GET_RECEIPT.FAIL:
                return {
                    ...state,
                    code: action.payload.code,
                    codeLanguage: action.payload.codeLanguage,
                    isLoading: false
                }
            case PAYMENT_ONLINE.GET_RECEIPT.REFRESH:
                return {
                    ...state,
                    code: undefined,
                    codeLanguage: undefined,
                    isLoading: false,
                    data: undefined
                }
            default: return state;
        }
    },
    // total: (state = initState.total, action) => {
    //     switch (action.type) {
    //         case PAYMENT_ONLINE.TOTAL.REQUEST:
    //             return {
    //                 ...state,
    //                 code: undefined,
    //                 codeLanguage: undefined,
    //                 isLoading: true
    //             }
    //         case PAYMENT_ONLINE.TOTAL.SUCCESS:
    //             return {
    //                 ...state,
    //                 code: action.payload.code,
    //                 codeLanguage: action.payload.codeLanguage,
    //                 isLoading: false,
    //                 total: action.payload.result
    //             }
    //         case PAYMENT_ONLINE.GETS.FAIL:
    //             return {
    //                 ...state,
    //                 code: action.payload.code,
    //                 codeLanguage: action.payload.codeLanguage,
    //                 isLoading: false
    //             }
    //         case PAYMENT_ONLINE.GETS.REFRESH:
    //             return {
    //                 ...state,
    //                 code: undefined,
    //                 codeLanguage: undefined,
    //                 isLoading: false,
    //                 data: {}
    //             }
    //         default: return state;
    //     }
    // },
    export: (state = initState.export, action) => {
        switch (action.type) {
            case PAYMENT_ONLINE.EXPORT.REQUEST:
                return {
                    ...state,
                    code: undefined,
                    codeLanguage: undefined,
                    isLoading: true
                }
            case PAYMENT_ONLINE.EXPORT.SUCCESS:
                return {
                    ...state,
                    code: action.payload.code,
                    codeLanguage: action.payload.codeLanguage,
                    isLoading: false,
                    data: action.payload.result
                }
            case PAYMENT_ONLINE.EXPORT.FAIL:
                return {
                    ...state,
                    code: action.payload.code,
                    codeLanguage: action.payload.codeLanguage,
                    isLoading: false
                }
            case PAYMENT_ONLINE.EXPORT.REFRESH:
                return {
                    ...state,
                    code: undefined,
                    codeLanguage: undefined,
                    isLoading: false,
                    data: undefined
                }
            default: return state;
        }
    }
})

export default reducer;