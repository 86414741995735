import { MESSAGE_NOTICE } from '../constants/actionType';

export const countBadges = () => ({
  type: MESSAGE_NOTICE.COUNT_BADGES.REQUEST,
});

export const countBadgesRequest = () => ({
  type: MESSAGE_NOTICE.COUNT_BADGES_REQUEST.REQUEST,
});

export const countBadgesUtilityBooking = () => ({
  type: MESSAGE_NOTICE.COUNT_BADGES_UTILITY_BOOKING.REQUEST,
});


