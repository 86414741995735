import { FORM_CONTRACT_TEMPLATE } from '../constants/actionType';
import { combineReducers } from 'redux';
import { initStatePagination } from './paginate';
import paginate from './paginate';
import { initStateFetching } from './fetching';
import fetching from './fetching';

export const initState = {
    formContractTemplateInit: {
        ...initStateFetching,
    },
    services: {
        ...initStatePagination,
    },
};

const formContractTemplateInit = fetching(FORM_CONTRACT_TEMPLATE.INIT);
const services = paginate(FORM_CONTRACT_TEMPLATE.SERVICE_GETS);


export default combineReducers({
    formContractTemplateInit,
    services,
});
