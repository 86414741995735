import { call, put, spawn } from 'redux-saga/effects';
import { RESIDENT } from '../constants/actionType';
import {
    activeAccountResidentService,
    addResidentService, deleteResidentService, exportResidentService,
    getResidentsService, importResidentService, inactiveAccountResidentService,
    updateResidentService,
} from '../services';
import { removeNullProperties } from '../libs/function';
import { PAGINATION, X_UNIT_GROUP_ID } from '../constants';

export function* initResidentPage(action) {
    const {
        buildingId,
        dataFetching,
    } = action.payload;
    try {
        let payload = {
            code: 200,
            residentRoles: undefined,
        };
        let error = [];
        // if (dataFetching.residentRole) {
        //     const resRole = yield call(getResidentRoleService);
        //     if (resRole.type === 'SUCCESS') {
        //         payload.residentRoles = resRole.payload.result;
        //     } else {
        //         error.push(resRole.payload.message);
        //     }
        // }
        const resResident = yield call(getResidentsService, {
            params: {
                page: 1,
                limit: PAGINATION.LIMIT,
            },
            headers: {
                [X_UNIT_GROUP_ID]: buildingId,
            },
        }, RESIDENT.GETS);
        yield put({
            ...resResident,
        });
        yield put({
            type: RESIDENT.INIT.SUCCESS,
            payload: {
                ...payload,
                error: error.join(', '),
            },
        });
    } catch (err) {
        yield put({
            type: RESIDENT.INIT.FAIL,
            payload: {
                code: 0,
                error: err,
            },
        });
    }
}

export function* getResidentsOfBuildingByPage(action) {
    const {
        buildingId,
        page,
        filters,
    } = action.payload;
    const result = yield call(getResidentsService, {
        params: {
            ...filters,
            page,
            limit: PAGINATION.LIMIT,
        },
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, RESIDENT.GETS);
    yield put({
        ...result,
    });
}

export function* updateResident(action) {
    const {
        resident,
    } = action.payload;
    const result = yield call(updateResidentService, resident, RESIDENT.UPDATE);
    yield put(result);
    yield put({
        type: RESIDENT.UPDATE.REFRESH,
    });
}

export function* addResident(action) {
    const {
        resident,
    } = action.payload;
    const result = yield call(addResidentService, resident, RESIDENT.ADD);
    yield put(result);
    yield put({
        type: RESIDENT.ADD.REFRESH,
    });
}

function* getResidentResource(buildingId, page, filterObject) {
    const resResident = yield call(getResidentsService, {
        params: {
            ...filterObject,
            page,
            limit: PAGINATION.LIMIT,
        },
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, RESIDENT.GETS);
    yield put({
        ...resResident,
    });
}

export function* importResidents(action) {
    const {
        buildingId,
        file,
    } = action.payload;
    const result = yield call(importResidentService, buildingId, file, RESIDENT.IMPORT);
    yield spawn(() => call(getResidentResource, buildingId, 1, {}));
    yield put(result);
    // yield put({
    //     type: RESIDENT.IMPORT.REFRESH,
    // });
}

export function* exportResident(action) {
    const {
        buildingId,
        filterObject,
    } = action.payload;
    const result = yield call(exportResidentService, buildingId, filterObject, RESIDENT.EXPORT);
    yield put(result);
    yield put({
        type: RESIDENT.EXPORT.REFRESH,
    });
}

export function* deleteResident(action) {
    const {
        unitGroupId,
        array,
        filterObject,
        isAll,
    } = action.payload;

    const body = {
        array,
        filterObject,
        isAll,
    };

    const result = yield call(deleteResidentService, { body, unitGroupId }, RESIDENT.DELETE);

    yield put(result);

    yield put({
        type: RESIDENT.DELETE.REFRESH,
    });
}

export function* activeAccountResident(action) {
    const {
        resident,
    } = action.payload;
    const result = yield call(activeAccountResidentService, {
        residentId: resident.id,
        unitGroupId: resident.unitGroupId,
    }, RESIDENT.ACTIVE_ACCOUNT);
    yield put({
        ...result,
    });
    yield put({
        type: RESIDENT.ACTIVE_ACCOUNT.REFRESH,
    });
}

export function* inactiveAccountResident(action) {
    let resident = action.payload.resident;
    resident = removeNullProperties(resident);
    resident.userId = null;
    const result = yield call(inactiveAccountResidentService, {
        residentId: resident.id,
        unitGroupId: resident.unitGroupId,
    }, RESIDENT.INACTIVE_ACCOUNT);
    yield put({
        ...result,
    });
    yield put({
        type: RESIDENT.INACTIVE_ACCOUNT.REFRESH,
    });
}

