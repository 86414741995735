import { language } from 'defined/Language';

export const EVENT_TYPE_MAINTAIN = 'MAINTAIN';
export const EVENT_TYPE_BUILDING = 'BUILDING';

export const EVENT_TYPES = [
  {
    label: language.building_event,
    value: EVENT_TYPE_BUILDING,
  },
  {
    label: language.maintenance_event,
    value: EVENT_TYPE_MAINTAIN,
  },
];
