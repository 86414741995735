import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { isEqual } from 'lodash';
import Cookie from 'defined/Cookie';
import { setLanguage } from 'defined/Language';
import reducers, { initState } from 'reducers';
import Routes from 'routes';
import saga from 'saga';
import { checkTokenExpirationMiddleware, webSocketMiddleware } from './middleware';
import * as Sentry from '@sentry/browser';
import initConfig from './config';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://d8efd3111dab4d5ebabea9f1d38f6be8@sentry.io/1502547',
    environment: process.env.NODE_ENV,
    logLevel: Sentry.Severity.debug,
  });
}

require('../public/assets/styles/style.scss');
require('../public/assets/javascript/scroll');

initConfig();

const sagaMiddleware = createSagaMiddleware();
const cache = Cookie.get('cyhome');
const defaultState = {
  ...initState,
  data: {
    ...initState.data,
    user: cache.user,
  },
};

console.log(process.env.NODE_ENV);
let middleware = [
  sagaMiddleware,
  webSocketMiddleware(),
  // checkTokenExpirationMiddleware,
];

if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, logger];
}

const enhancers = [applyMiddleware(...middleware)];

const composeEnhancers =
  process.env.NODE_ENV === 'development' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ shouldHotReload: false })
    : compose;

export let store = createStore(reducers, defaultState, composeEnhancers(...enhancers));

sagaMiddleware.run(saga);

//listen access token change after login
let currentState = undefined;
store.subscribe(() => {
  let prevState = currentState;
  currentState = store.getState();
  if (currentState && prevState) {
    if (!isEqual(currentState.data.user, prevState.data.user)) {
      Cookie.save('cyhome', {
        ...cache,
        user: currentState.data.user,
      });
    }
  }
});

//pass language selected from cache to project
// setLanguage(cache.language ? cache.language : 'en');

ReactDOM.render(<Routes store={store} />, document.getElementById('root'));
