import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
  getStaffsByPageService,
  addStaffService,
  updateStaffService,
  deleteListStaffService,
  importStaffService,
  exportStaffService,
} from 'services/staff';

import { getDepartmentsService } from 'services/department';

import { X_UNIT_GROUP_ID } from 'shared/constants';
import { STAFF_GETS, STAFF_ADD, STAFF_UPDATE, STAFF_DELETE, STAFF_IMPORT, STAFF_EXPORT, STAFF_DEPARTMENT_GETS } from './staff.types';
import { trimProperpty } from './staff.util';

function* getDataTable(action) {
  const { unitGroupId, filterObject, page } = action.payload;

  const params = {
    page,
    limit: 20,
    ...filterObject,
  };

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(getStaffsByPageService, { params, headers }, STAFF_GETS);
  yield put(result);
  yield put({
    type: STAFF_GETS.REFRESH,
  });
}

function* addStaff(action) {
  const { unitGroupId, staff } = action.payload;

  const result = yield call(addStaffService, { unitGroupId, staff: trimProperpty(staff) }, STAFF_ADD);
  yield put(result);
  yield put({
    type: STAFF_ADD.REFRESH,
  });
}

function* updateStaff(action) {
  const { unitGroupId, staff } = action.payload;

  const result = yield call(updateStaffService, { unitGroupId, staff: trimProperpty(staff) }, STAFF_UPDATE);
  yield put(result);
  yield put({
    type: STAFF_UPDATE.REFRESH,
  });
}

function* deleteStaff(action) {
  const { unitGroupId, ids, isAll } = action.payload;

  const result = yield call(deleteListStaffService, { unitGroupId, ids, isAll }, STAFF_DELETE);
  yield put(result);
  yield put({
    type: STAFF_DELETE.REFRESH,
  });
}

function* importStaff(action) {
  const { unitGroupId, file } = action.payload;

  const result = yield call(importStaffService, unitGroupId, file, STAFF_IMPORT);
  yield put(result);
  yield put({
    type: STAFF_IMPORT.REFRESH,
  });
}

function* exportStaff(action) {
  const { unitGroupId, filterObject } = action.payload;

  const result = yield call(exportStaffService, unitGroupId, filterObject, STAFF_EXPORT);
  yield put(result);
  yield put({
    type: STAFF_EXPORT.REFRESH,
  });
}

function* getDepartments(action) {
  const { unitGroupId, isIncludePermission } = action.payload;

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const params = {
    isIncludePermission,
  };

  const result = yield call(getDepartmentsService, { headers, params }, STAFF_DEPARTMENT_GETS);
  yield put(result);
  yield put({
    type: STAFF_DEPARTMENT_GETS.REFRESH,
  });
}

// eslint-disable-next-line import/prefer-default-export
export function* watch() {
  yield all([
    takeLatest(STAFF_GETS.REQUEST, getDataTable),
    takeLatest(STAFF_ADD.REQUEST, addStaff),
    takeLatest(STAFF_UPDATE.REQUEST, updateStaff),
    takeLatest(STAFF_DELETE.REQUEST, deleteStaff),
    takeLatest(STAFF_IMPORT.REQUEST, importStaff),
    takeLatest(STAFF_EXPORT.REQUEST, exportStaff),
    takeLatest(STAFF_DEPARTMENT_GETS.REQUEST, getDepartments),
  ]);
}
