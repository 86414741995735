import paginate, { initStatePagination } from '../paginate';
import asset, { initState as initStateAsset } from './asset';
import formAsset, { initState as initFormAsset } from './formAsset';
import supply, { initState as initSupply } from './supply';
import partner, { initState as initPartner } from './partner';
import { combineReducers } from 'redux';
import { PAGE_ASSET } from '../../constants';
import fetching, { initStateFetching } from '../fetching';

export const initState = {
    init: {
        ...initStateFetching,
    },
    departments: {
        ...initStatePagination,
    },
    employees: {
        ...initStatePagination,
    },
    asset: {
        ...initStateAsset,
    },
    supply: {
        ...initSupply,
    },
    partner: {
        ...initPartner,
    },
    formAsset: {
        ...initFormAsset,
    }
};

const init = fetching(PAGE_ASSET.INIT);
const departments = paginate(PAGE_ASSET.DEPARTMENT_GETS);
const employees = paginate(PAGE_ASSET.EMPLOYEE_GETS);

export default combineReducers({
    init,
    departments,
    employees,
    asset,
    supply,
    partner,
    formAsset,
});
