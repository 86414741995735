import { handleError, handleResponse } from '../libs/http';
import { METHOD } from '../constants';
import { callApi, downloadFile, callApiWithFile } from './api';
import { PAGINATION } from 'constants/pagination';
import {
  CARD_ADD,
  CARD_UPDATE,
  CARD_LIST,
  CARD_DELETE,
  CARD_EXPORT,
  CARD_HISTORY,
  CARD_IMPORT,
  CARD_UPDATE_STATUS,
  CARD_SUGGEST_GETS,
  CARD_REGISTER_ADD,
  CARD_REGISTER_SERVICE_GETS,
  CARD_REGISTER_GETS,
  CARD_REGISTER_DELETE,
  CARD_REGISTER_PREVIEW,
  CARD_REGISTER_PRINT,
  CARD_REGISTER_REPRINT,
  CONTRACT_TEMPLATE_GET,
  CARD_REGISTER_EXPORT,
  CARD_HISTORY_USAGE,
} from '../defined/API';

export async function addCardService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(CARD_ADD, { ...params }, METHOD.POST, {
      headers: headers,
    });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function updateCardService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(CARD_UPDATE, { ...params }, METHOD.PUT, {
      headers: headers,
    });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getCardListService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(CARD_LIST, {}, METHOD.GET, {
      params: params,
      headers: headers,
    });
    return handleResponse(response, type, 'result', params.page, params.limit);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function deleteCardService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(CARD_DELETE, { ...params }, METHOD.POST, {
      headers: headers,
    });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function importCardListService(
  { dataAttach, headers, file },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApiWithFile(CARD_IMPORT, file, METHOD.POST, 'file', {
      dataAttach,
      headers,
    });
    return handleResponse(response, type, 'result', 1);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

export async function exportCardService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await downloadFile(CARD_EXPORT, {}, METHOD.GET, {
      params: params,
      headers: headers,
    });
    return handleResponse(response, type, 'result');
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getCardHistoryService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(CARD_HISTORY(params.id), {}, METHOD.GET, {
      params: {},
      headers: headers,
    });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function updateCardStatusService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(CARD_UPDATE_STATUS, params, METHOD.PUT, {
      params: {},
      headers: headers,
    });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getCardsSuggestService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(CARD_SUGGEST_GETS, {}, METHOD.GET, {
      params: params,
      headers: headers,
    });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function addRegisterService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(CARD_REGISTER_ADD, { ...params }, METHOD.POST, {
      headers: headers,
    });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}
export async function getlistServices(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(CARD_REGISTER_SERVICE_GETS, {}, METHOD.GET, {
      params: params,
      headers: headers,
    });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getRegistersService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(CARD_REGISTER_GETS, {}, METHOD.GET, {
      params: params,
      headers: headers,
    });
    return handleResponse(response, type, 'result', params.page, PAGINATION.LIMIT);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function deleteRegisterService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(CARD_REGISTER_DELETE, { ...params }, METHOD.POST, {
      headers: headers,
    });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function printRegisterService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(CARD_REGISTER_PRINT, { ...params }, METHOD.POST, {
      headers: headers,
      bodyType: 'html',
    });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function rePrintRegisterService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(CARD_REGISTER_REPRINT, { ...params }, METHOD.POST, {
      headers: headers,
      bodyType: 'html',
    });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function previewRegisterService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(CARD_REGISTER_PREVIEW, {}, METHOD.GET, {
      params: params,
      headers: headers,
      bodyType: 'html',
    });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getContractTemplatesService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(CONTRACT_TEMPLATE_GET, {}, METHOD.GET, {
      params: params,
      headers: headers,
    });
    return handleResponse(response, type, 'result', params.page, params.limit);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function exportRegisterService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await downloadFile(CARD_REGISTER_EXPORT, {}, METHOD.GET, {
      params: params,
      headers: headers,
    });
    return handleResponse(response, type, 'result');
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getCardUsageHistoryService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(CARD_HISTORY_USAGE(params.id), {}, METHOD.GET, {
      params: params,
      headers: headers,
    });
    return handleResponse(response, type, 'result', params.page, params.limit);
  } catch (err) {
    return handleError(err, FAIL);
  }
}
