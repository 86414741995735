export const initStateFetching = {
  isFetching: false,
};

const fetching = (types) => {
  const { REQUEST, SUCCESS, FAIL, REFRESH } = types;
  const updateFetching = (state = initStateFetching, action) => {
    switch (action.type) {
      case REQUEST:
        return {
          code: undefined,
          codeLanguage: undefined,
          isFetching: true,
        };
      case SUCCESS:
      case FAIL:
        return {
          isFetching: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          message: action.payload.message,
        };
      case REFRESH:
        return {
          isFetching: false,
          code: undefined,
          codeLanguage: undefined,
        };
      default:
        return state;
    }
  };

  return (state = initStateFetching, action) => {
    switch (action.type) {
      case REQUEST:
      case SUCCESS:
      case FAIL:
      case REFRESH:
        return {
          ...state,
          ...updateFetching(state, action),
        };
      default:
        return state;
    }
  };
};

export default fetching;
