import { FORMULA } from '../constants/actionType';
import {call, put} from 'redux-saga/effects';
import { addFormulaService } from '../services';

export function* addFormula(action) {
    const {
        name,
        formulaType,
        detail,
        serviceId,
        buildingId,
    } = action.payload.formula;

    const formula = {
        name,
        // "description": "string",
        formularType: formulaType,
        formular: JSON.stringify(detail),
        serviceId,
        unitGroupId: buildingId,
    };

    const result = yield call(addFormulaService, formula, FORMULA.ADD);

    yield put(result);
    yield put({
        type: FORMULA.ADD.REFRESH
    });
}