import {call, put} from 'redux-saga/effects';
import { SURCHARGE } from '../constants';
import { addSurchargeService } from '../services';

export function* addSurcharge(action) {
    const {
        name,
        type,
        price,
        serviceId,
        buildingId,
    } = action.payload.surcharge;

    const surcharge = {
        name,
        type,
        price,
        serviceId,
        unitGroupId: buildingId
    };

    const result = yield call(addSurchargeService, surcharge, SURCHARGE.ADD);

    yield put(result);
    yield put({
        type: SURCHARGE.ADD.REFRESH
    });
}