import { getProfileService, updateProfileService } from '../services/profile';
import { call, put } from 'redux-saga/effects';
import { PROFILE } from '../constants/actionType';
import { updatePassword } from '../services';

export function* getProfile(action) {
    const result = yield call(getProfileService, PROFILE.GETS);
    yield put(result);
}

export function* updateProfile(action) {
    const {
        data
    } = action.payload;
    const result = yield call(updateProfileService, data, PROFILE.UPDATE);
    yield put(result);
    yield put({
        type: PROFILE.UPDATE.REFRESH
    });
}

export function* updatePasswordInProfile(action) {
    const {
        data
    } = action.payload;
    const result = yield call(updatePassword, data, PROFILE.PASSWORD_UPDATE);
    yield put(result);
    yield put({
        type: PROFILE.PASSWORD_UPDATE.REFRESH,
    });
}