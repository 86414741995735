import { useDispatch, useSelector } from 'react-redux';
import { groupBy, orderBy } from 'lodash';
import {
  getDepartmentsAction,
  getGroupPermissionAction,
  addDepartmentAction,
  updateDepartmentAction,
  deleteDepartmentAction,
  addPositionAction,
  updatePositionAction,
  deletePositionAction,
} from './authorization.actions';
import {
  departmentsSelector,
  groupPermissionSelector,
  departmentAddSelector,
  departmentUpdateSelector,
  departmentDeleteSelector,
  positionAddSelector,
  positionUpdateSelector,
  positionDeleteSelector,
} from './authorization.selectors';

export const useAuthorization = () => {
  const dispatch = useDispatch();

  const getGroupPermission = () => dispatch(getGroupPermissionAction());
  const getDepartments = (unitGroupId) => dispatch(getDepartmentsAction(unitGroupId, true));
  const addDepartment = (unitGroupId, payload) => dispatch(addDepartmentAction(unitGroupId, payload));
  const updateDepartment = (unitGroupId, payload) => dispatch(updateDepartmentAction(unitGroupId, payload));
  const deleteDepartment = (unitGroupId, departmentId) => dispatch(deleteDepartmentAction(unitGroupId, departmentId));

  const addPosition = (unitGroupId, payload) => dispatch(addPositionAction(unitGroupId, payload));
  const updatePosition = (unitGroupId, payload) => dispatch(updatePositionAction(unitGroupId, payload));
  const deletePosition = (unitGroupId, positionId) => dispatch(deletePositionAction(unitGroupId, positionId));

  const departments = useSelector(departmentsSelector);
  const groupPermission = useSelector(groupPermissionSelector);
  const departmentAdd = useSelector(departmentAddSelector);
  const departmentUpdate = useSelector(departmentUpdateSelector);
  const departmentDelete = useSelector(departmentDeleteSelector);

  const positionAdd = useSelector(positionAddSelector);
  const positionUpdate = useSelector(positionUpdateSelector);
  const positionDelete = useSelector(positionDeleteSelector);

  return {
    // Var
    departments,
    groupPermission,
    departmentAdd,
    departmentUpdate,
    departmentDelete,

    positionAdd,
    positionUpdate,
    positionDelete,
    // Func
    getDepartments,
    getGroupPermission,
    addDepartment,
    updateDepartment,
    deleteDepartment,

    addPosition,
    updatePosition,
    deletePosition,
  };
};

export function splitName(name) {
  const arr = name.split('_');
  const suffix = arr.pop();
  const prefix = arr.join('_');
  return [prefix, suffix];
}

export function getPrioritySuffix(suffix) {
  let priority = 0;
  switch (suffix) {
    case 'READ': {
      priority = 1;
      break;
    }
    case 'EXPORT': {
      priority = 2;
      break;
    }
    case 'WRITE': {
      priority = 3;
      break;
    }
    default: {
      priority = 0;
    }
  }
  return priority;
}

export function sortPermission(permissions) {
  let result = [];
  const obj = groupBy(permissions, 'prefix');

  Object.keys(obj).forEach((key) => {
    obj[key] = orderBy(obj[key], ['prioritySuffix'], 'desc');
    result = [...result, ...obj[key]];
  });

  return result;
}
