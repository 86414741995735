import { ADVANCE_PAYMENT_GETS, ADVANCE_PAYMENT_TOTAL_GET, ADVANCE_PAYMENT_EXPORT } from './advancePayment.types';

export const getAdvancePaymentAction = (unitGroupId, page, filterObject) => ({
  type: ADVANCE_PAYMENT_GETS.REQUEST,
  payload: {
    page,
    filterObject,
    unitGroupId,
  },
});

export const getAdvancePaymentTotalAction = (unitGroupId, filterObject) => ({
  type: ADVANCE_PAYMENT_TOTAL_GET.REQUEST,
  payload: {
    filterObject,
    unitGroupId,
  },
});

export const exportAdvancePaymentAction = (unitGroupId, filterObject) => ({
  type: ADVANCE_PAYMENT_EXPORT.REQUEST,
  payload: {
    filterObject,
    unitGroupId,
  },
});
