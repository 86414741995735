import { combineReducers } from 'redux';
import paginate, { initStatePagination } from 'shared/utils/paginate';
import { LOCK_DATA_FORM_STAFFS_FILTER, LOCK_DATA_FORM_CREATE, LOCK_DATA_FORM_UPDATE, LOCK_DATA_FORM_HISTORY } from './lockDataForm.types';

export const initState = {
  create: {
    ...initStatePagination,
  },
  update: {
    ...initStatePagination,
  },
  history: {
    ...initStatePagination,
  },
  staffs: {
    ...initStatePagination,
  },
};

const reducer = combineReducers({
  create: paginate(LOCK_DATA_FORM_CREATE),
  update: paginate(LOCK_DATA_FORM_UPDATE),
  history: paginate(LOCK_DATA_FORM_HISTORY),
  staffs: paginate(LOCK_DATA_FORM_STAFFS_FILTER),
});

export default reducer;
