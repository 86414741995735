import React from 'react';
import Cookie from '../../../defined/Cookie';
import AuthComponent from './AuthComponentView';
import { initApp } from '../../../actions';
import { connect } from 'react-redux';
type Props = {
    component: any,
    isFetchingApplicationData: boolean,
    path: string,
    initApp: Function,
}

type State = {

}

function authComponentEnhance(Component: any) {
    return class AuthComponentEnhance extends React.Component<Props, State> {

        constructor(args: any) {
            super(args);
        }

        static defaultProps = {
            isFetchingApplicationData: false,
        };

        componentDidMount() {
            const dataFetching = {
                application: !this.props.isFetchingApplicationData,
            };
            this.props.initApp(dataFetching);
        }

        render() {
            const props = {
                ...this.props,
                cookie: Cookie.get('cyhome'),
                Component: this.props.component,
                path: this.props.path,
                buildings: this.props.buildings,
            };
            return this.props.isFetchingApplicationData && <Component {...props} />;
        }
    };
}

const mapStateToProps = (state: Object) => ({
    isFetchingApplicationData: state.data.isFetchingApplicationData,
    buildings: state.application.applicationInit.buildings,
});

const mapDispatchToProps = (dispatch: Function) => ({
    initApp: (dataFetching: Object) => dispatch(initApp(dataFetching))
});

export default connect(mapStateToProps, mapDispatchToProps)(authComponentEnhance(AuthComponent));