import { combineReducers } from 'redux';
import paginate, { initStatePagination } from 'shared/utils/paginate';
import fetching, { initStateFetching } from 'shared/utils/fetching';
import {
  RESET_RESIDENT_FORM_GET_ENTITY,
  RESIDENT_FORM_GET_UNITS,
  RESIDENT_FORM_ADD,
  RESIDENT_FORM_UPDATE,
  RESIDENT_FORM_GET_ENTITY,
  RESIDENT_FORM_GET_COUNTRY_CODE,
} from './residentForm.types';

export const initState = {
  units: {
    ...initStatePagination,
  },
  add: {
    ...initStateFetching,
  },
  update: {
    ...initStateFetching,
  },
  entity: {
    ...initStatePagination,
  },
  countryCode: {
    ...initStatePagination,
  },
};

const handleEntity = (reducer) => (state, action) => {
  switch (action.type) {
    case RESET_RESIDENT_FORM_GET_ENTITY: {
      return {
        ...initStatePagination,
      };
    }
    default: {
      return reducer(state, action);
    }
  }
};

export default combineReducers({
  units: paginate(RESIDENT_FORM_GET_UNITS),
  add: fetching(RESIDENT_FORM_ADD),
  update: fetching(RESIDENT_FORM_UPDATE),
  entity: handleEntity(paginate(RESIDENT_FORM_GET_ENTITY)),
  countryCode: paginate(RESIDENT_FORM_GET_COUNTRY_CODE),
});
