import fetching, { initStateFetching } from 'shared/utils/fetching';
import { combineReducers } from 'redux';
import { LOGIN, PASSWORD, REGISTER } from './auth.types';

export const initState = {
    register: {
        ...initStateFetching,
    },
    login: {
        ...initStateFetching,
    },
    updatePassword: {
        ...initStateFetching,
    },
    forgotPassword: {
        ...initStateFetching,
    },
    setNewPassword: {
        ...initStateFetching,
    },
};

const register = fetching(REGISTER);
const login = fetching(LOGIN);
const updatePassword = fetching(PASSWORD.UPDATE);
const forgotPassword = fetching(PASSWORD.FORGOT);
const setNewPassword = fetching(PASSWORD.SET_NEW);

export default combineReducers({
    register,
    login,
    updatePassword,
    forgotPassword,
    setNewPassword,
});
