import { call, put, all, spawn } from 'redux-saga/effects';
import { isObject } from 'lodash';
import {
    getApartments as getApartmentService,
    getApartmentTypes as getApartmentTypesService,
    addApartment as addApartmentService,
    updateApartment as updateApartmentService,
    deleteApartment as deleteApartmentService,
    getServiceSystemService,
    getIcons,
    getFloorsService,
    importApartmentService,
    exportApartmentService,
} from '../services';
import { CONFIG, FLOOR } from '../constants/actionType';
import { checkResponseError } from '../libs/http';
import { PAGINATION, X_UNIT_GROUP_ID } from '../constants';
import { getLabelsService } from '../services/label';

export function* initApartmentConfigPage(action) {
    try {
        let payload = {
            code: 200,
            floors: undefined,
            apartmentTypes: undefined,
            blocks: undefined,
        };
        let error = [];
        // if (action.dataFetching.block) {
        //     let resBlock = yield call(getBlocksService, action.buildingId);
        //     if (resBlock.type === 'FAIL') {
        //         error.push(resBlock.payload.message);
        //     }
        //     else {
        //         payload.blocks = resBlock.payload.blocks;
        //     }
        // }
        if (action.dataFetching.floor) {
            let resFloor = yield call(getFloorsService, action.buildingId);
            if (resFloor.type === 'FAIL') {
                error.push(resFloor.payload.message);
            }
            else {
                payload.floors = resFloor.payload.floors;
            }
        }
        if (action.dataFetching.apartmentType) {
            let resApartmentType = yield call(getApartmentTypesService);
            if (resApartmentType.type === 'FAIL') {
                error.push(resApartmentType.payload.message);
            }
            else {
                payload.apartmentTypes = resApartmentType.payload.apartmentTypes;
            }
        }
        const result = yield call(getApartmentService, action.buildingId, 1, PAGINATION.LIMIT, {}, CONFIG.APARTMENT.GETS);
        yield put({
            ...result,
        });

        const resultLabel = yield call(getLabelsService, {
            headers: { [X_UNIT_GROUP_ID]: action.buildingId }
        }, CONFIG.LABEL.GETS);
        yield put(resultLabel);


        yield put({
            type: CONFIG.APARTMENT.INIT.SUCCESS,
            payload: {
                ...payload,
                error: error.join(', '),
            },
        });
    }
    catch (err) {
        yield put({
            type: CONFIG.APARTMENT.INIT.FAIL,
            payload: {
                code: 0,
                error: err,
            },
        });
    }
}

export function* getApartmentByPageInConfig(action) {
    const {
        buildingId,
        page,
        filters,
        limit,
    } = action.payload;
    const result = yield call(getApartmentService, buildingId, page, limit, filters, CONFIG.APARTMENT.GETS);
    yield put({
        ...result,
    });
}

export function* addApartment(action) {
    const {
        name,
        floor,
        buildStatus,
        apartmentType,
        balance,
        area,
        description,
        labelId,
        status,
        storey,
        bedroom,
        bathroom,
        balcony,
        localAddress,
        direction,
        windowNumber,
        useArea,
        buildingId,
        unitOnSale,
    } = action.payload.apartment;
    const apartment = {
        name,
        floorId: floor.id,
        unitTypeId: apartmentType.id,
        buildStatus,
        balance,
        area,
        description,
        labelId,
        status,
        storey,
        bedroom,
        bathroom,
        balcony,
        localAddress,
        direction,
        window: windowNumber,
        useArea,
        unitGroupId: buildingId,
        unitOnSale
    };
    const result = yield call(addApartmentService, apartment, CONFIG.APARTMENT.ADD);
    yield put({
        ...result,
    });
    yield put({
        type: CONFIG.APARTMENT.ADD.REFRESH,
    });
}

function* getApartmentResource(buildingId, page, size, filterObject) {
    const result = yield call(getApartmentService, buildingId, page, size, filterObject, CONFIG.APARTMENT.GETS);
    yield put(result);
}

function* getFloorResource(buildingId) {
    const result = yield call(getFloorsService, buildingId, CONFIG.FLOOR.GETS);
    yield put(result);
}

export function* importApartment(action) {
    const {
        buildingId,
        file,
    } = action.payload;
    const result = yield call(importApartmentService, buildingId, file, CONFIG.APARTMENT.IMPORT);

    yield all([
        spawn(() => call(getFloorResource, buildingId)),
    ]);

    yield put(result);
    yield put({
        type: CONFIG.APARTMENT.IMPORT.REFRESH,
    });
}

export function* exportApartmentInPageConfig(action) {
    const {
        buildingId,
        filterObject,
    } = action.payload;
    const result = yield call(exportApartmentService, buildingId, filterObject, CONFIG.APARTMENT.EXPORT_APARTMENT);
    yield put(result);
    yield put({
        type: CONFIG.APARTMENT.EXPORT_APARTMENT.REFRESH,
    });
}

export function* updateApartment(action) {
    const {
        id,
        name,
        floor,
        buildStatus,
        apartmentType,
        balance,
        area,
        description,
        labelId,
        status,
        storey,
        bedroom,
        bathroom,
        balcony,
        localAddress,
        direction,
        windowNumber,
        useArea,
        buildingId,
        unitOnSale
    } = action.payload.apartment;
    const apartment = {
        id,
        name,
        floorId: floor.id,
        buildStatus,
        unitTypeId: apartmentType.id,
        balance,
        area,
        description,
        labelId,
        // status,
        storey,
        bedroom,
        bathroom,
        balcony,
        localAddress,
        direction,
        window: windowNumber,
        useArea,
        unitGroupId: buildingId,
        unitOnSale
    };
    const result = yield call(updateApartmentService, apartment, CONFIG.APARTMENT.UPDATE);

    yield put({
        ...result,
    });
    yield put({
        type: CONFIG.APARTMENT.UPDATE.REFRESH,
    });
}

export function* deleteApartment(action) {
    const {
        isAll,
        array,
        filterObject,
        unitGroupId,
    } = action.payload;

    const body = {
        isAll,
        array,
        filterObject,
    };

    const resApartment = yield call(deleteApartmentService, {
        body,
        unitGroupId,
    }, CONFIG.APARTMENT.DELETE);
    yield put(resApartment);
    yield put({
        type: CONFIG.APARTMENT.DELETE.REFRESH,
    });
}

export function* initDepartmentConfigPage(action) {
    try {
        let resDepartment = {};
        if (action.dataFetching.department) resDepartment = yield call(api.getDepartments, action.buildingId);
        let payload = {
            code: 200,
            departments: undefined,
        };
        let error = [];

        if (isObject(resDepartment)) {
            const err = checkResponseError(resDepartment);
            if (!err) {
                payload.departments = resDepartment;
            } else {
                error.push(err.message);
            }
        }

        yield put({
            type: CONFIG.DEPARTMENT.INIT.SUCCESS,
            payload: {
                ...payload,
                error: error.join(', '),
            },
        });
    }
    catch (err) {
        yield put({
            type: CONFIG.DEPARTMENT.INIT.FAIL,
            payload: {
                code: 0,
                error: err,
            },
        });
    }
}

export function* initTagConfigPage(action) {
    yield put({
        type: CONFIG.TAG.INIT.SUCCESS,
        payload: {
            code: 200,
            tags: [],
        },
    });
    return;
    try {
        const resTag = yield call(api.getTags, action.buildingId);
        if (isObject(resTag)) {
            if (resTag.codeStatus === 200) {
                yield put({
                    type: CONFIG.TAG.INIT.SUCCESS,
                    payload: {
                        code: resTag.codeStatus,
                        tags: resTag.data,
                    },
                });
            }
            else {
                yield put({
                    type: CONFIG.TAG.INIT.FAIL,
                    payload: {
                        code: resTag.codeStatus,
                        error: resTag.responseMsg.message,
                    },
                });
            }
        }
        else {
            yield put({
                type: CONFIG.TAG.INIT.FAIL,
                payload: {
                    code: 0,
                },
            });
        }
    } catch (err) {
        yield put({
            type: CONFIG.TAG.INIT.FAIL,
            payload: {
                code: 0,
                error: err,
            },
        });
    }
}

export function* addTag(action) {
    try {
        const resTag = yield call(api.addTag, action.tag);
        if (isObject(resTag)) {
            if (resTag.codeStatus) {
                if (resTag.codeStatus === 200) {
                    yield put({
                        type: CONFIG.TAG.ADD.SUCCESS,
                        payload: {
                            code: resTag.codeStatus,
                            tag: {
                                ...action.tag,
                                id: resTag.data.id,
                            },
                        },
                    });
                }
                else {
                    yield put({
                        type: CONFIG.TAG.ADD.FAIL,
                        payload: {
                            code: resTag.codeStatus,
                            error: resTag.responseMsg.message,
                        },
                    });
                }
            }
            else {
                yield put({
                    type: CONFIG.TAG.ADD.FAIL,
                    payload: {
                        code: 0,
                    },
                });
            }
        }
    }
    catch (err) {
        yield put({
            type: CONFIG.TAG.ADD.FAIL,
            payload: {
                code: 0,
                error: err,
            },
        });
    }
}

export function* updateTag(action) {
    try {
        const resTag = yield call(api.updateTag, action.tag);
        if (isObject(resTag)) {
            if (resTag.codeStatus) {
                if (resTag.codeStatus === 200) {
                    yield put({
                        type: CONFIG.TAG.UPDATE.SUCCESS,
                        payload: {
                            code: resTag.codeStatus,
                            tag: {
                                ...action.tag,
                            },
                        },
                    });
                }
                else {
                    yield put({
                        type: CONFIG.TAG.UPDATE.FAIL,
                        payload: {
                            code: resTag.codeStatus,
                            error: resTag.responseMsg.message,
                        },
                    });
                }
            }
            else {
                yield put({
                    type: CONFIG.TAG.UPDATE.FAIL,
                    payload: {
                        code: 0,
                    },
                });
            }
        }
    }
    catch (err) {
        yield put({
            type: CONFIG.TAG.UPDATE.FAIL,
            payload: {
                code: 0,
                error: err,
            },
        });
    }
}

export function* deleteTag(action) {
    try {
        const resTag = yield call(api.deleteTag, action.tag);
        if (isObject(resTag)) {
            if (resTag.codeStatus) {
                if (resTag.codeStatus === 200) {
                    yield put({
                        type: CONFIG.TAG.DELETE.SUCCESS,
                        payload: {
                            code: resTag.codeStatus,
                            tag: {
                                ...action.tag,
                            },
                        },
                    });
                }
                else {
                    yield put({
                        type: CONFIG.TAG.DELETE.FAIL,
                        payload: {
                            code: resTag.codeStatus,
                            error: resTag.responseMsg.message,
                        },
                    });
                }
            }
            else {
                yield put({
                    type: CONFIG.TAG.DELETE.FAIL,
                    payload: {
                        code: 0,
                    },
                });
            }
        }
    }
    catch (err) {
        yield put({
            type: CONFIG.TAG.DELETE.FAIL,
            payload: {
                code: 0,
                error: err,
            },
        });
    }
}

// block

export function* initBlockConfigPage(action) {
    yield put({
        type: CONFIG.BLOCK.INIT.SUCCESS,
        payload: {
            code: 200,
            blocks: [],
            buildingServices: [],
            allowances: [],
            servicesSystem: [],
        },
    });
    return;
    try {
        let resBuildingService = {};
        let resServiceSystem = {};
        let resAllowance = {};
        let payload = {
            code: 200,
            blocks: undefined,
            buildingServices: undefined,
            allowances: undefined,
            servicesSystem: undefined,
        };
        if (action.dataFetching.buildingService) {
            resBuildingService = yield call(api.getAllServiceBuildingByBuildingId, action.buildingId);
        }
        if (action.dataFetching.allowance) {
            resAllowance = yield call(api.getAllowanceByBuilding, action.buildingId);
        }
        if (action.dataFetching.serviceSystem) {
            resServiceSystem = yield call(api.getAllServiceSystem);
        }
        const resBlock = yield call(api.getBlocks, action.buildingId);
        let error = '';
        if (isObject(resServiceSystem)) {
            if (resServiceSystem.codeStatus) {
                if (resServiceSystem.codeStatus === 200) {
                    payload.servicesSystem = resServiceSystem.data;
                }
                else {
                    error += resServiceSystem.responseMsg.message + ' | ';
                }
            }
            else {
                error += resServiceSystem.toString() + ' | ';
            }
        }
        if (isObject(resBuildingService)) {
            if (resBuildingService.codeStatus) {
                if (resBuildingService.codeStatus === 200) {
                    payload.buildingServices = resBuildingService.data;
                }
                else {
                    error += resBuildingService.responseMsg.message + ' | ';
                }
            }
            else {
                error += resBuildingService.toString() + ' | ';
            }
        }
        if (isObject(resAllowance)) {
            if (resAllowance.codeStatus) {
                if (resAllowance.codeStatus === 200) {
                    payload.allowances = resAllowance.data;
                }
                else {
                    error += resBuildingService.responseMsg.message + ' | ';
                }
            }
            else {
                error += resBuildingService.toString() + ' | ';
            }
        }
        if (isObject(resBlock)) {
            if (resBlock.codeStatus) {
                if (resBlock.codeStatus === 200) {
                    payload.blocks = resBlock.data;
                }
                else {
                    error += resBlock.responseMsg.message + ' | ';
                }
            }
            else {
                error += resBlock.toString() + ' | ';
            }
        }
        yield put({
            type: CONFIG.BLOCK.INIT.SUCCESS,
            payload: {
                ...payload,
                error: error,
            },
        });
    }
    catch (err) {
        yield put({
            type: CONFIG.BLOCK.INIT.FAIL,
            payload: {
                code: 0,
                error: err,
            },
        });
    }
}

export function* getBlocksSaga(action) {
    yield put({
        type: CONFIG.BLOCK.GETS.SUCCESS,
        payload: {
            code: 200,
            blocks: [],
        },
    });
    return;
    try {
        const res = yield call(api.getBlocks, action.buildingId);
        if (isObject(res)) {
            if (res.codeStatus) {
                if (res.codeStatus === 200) {
                    yield put({
                        type: CONFIG.BLOCK.GETS.SUCCESS,
                        payload: {
                            code: res.codeStatus,
                            blocks: res.data,
                        },
                    });
                } else {
                    yield put({
                        type: CONFIG.BLOCK.GETS.FAIL,
                        payload: {
                            code: res.codeStatus,
                            error: res.responseMsg.message,
                        },
                    });
                }
            } else {
                yield put({
                    type: CONFIG.BLOCK.GETS.FAIL,
                    payload: {
                        code: 0,
                    },
                });
            }
        }
    } catch (e) {
        yield put({
            type: CONFIG.BLOCK.GETS.FAIL,
            payload: {
                code: 0,
            },
        });
    }
}

export function* addBlock(action) {
    try {
        const block = {
            buildingId: action.block.buildingId,
            name: action.block.name,
            shortName: action.block.shortName,
            proportionBusiness: action.block.proportionBusiness,
            proportionLiving: action.block.proportionLiving,
            servicesAndAllowances: action.block.serviceAndAllowance,
        };
        const res = yield call(api.addBlock, block);
        if (isObject(res)) {
            if (res.codeStatus) {
                if (res.codeStatus === 201) {
                    yield put({
                        type: CONFIG.BLOCK.ADD.SUCCESS,
                        payload: {
                            code: res.codeStatus,
                            block: {
                                ...block,
                                id: res.data,
                            },
                        },
                    });
                } else {
                    yield put({
                        type: CONFIG.BLOCK.ADD.FAIL,
                        payload: {
                            code: res.codeStatus,
                            error: res.responseMsg.message,
                        },
                    });
                }
            } else {
                yield put({
                    type: CONFIG.BLOCK.ADD.FAIL,
                    payload: {
                        code: 0,
                    },
                });
            }
        }
    } catch (e) {
        yield put({
            type: CONFIG.BLOCK.ADD.FAIL,
            payload: {
                code: 0,
            },
        });
    }
}

export function* updateBlock(action) {
    try {
        const block = {
            ...action.blockPrev,
            id: action.block.id,
            buildingId: action.block.buildingId,
            name: action.block.name,
            shortName: action.block.shortName,
            proportionBusiness: action.block.proportionBusiness,
            proportionLiving: action.block.proportionLiving,
            servicesAndAllowances: action.block.serviceAndAllowance,
        };
        const res = yield call(api.updateBlock, block);
        if (isObject(res)) {
            if (res.codeStatus) {
                if (res.codeStatus === 200) {
                    yield put({
                        type: CONFIG.BLOCK.UPDATE.SUCCESS,
                        payload: {
                            code: res.codeStatus,
                            block: block,
                        },
                    });
                } else {
                    yield put({
                        type: CONFIG.BLOCK.UPDATE.FAIL,
                        payload: {
                            code: res.codeStatus,
                            error: res.responseMsg.message,
                        },
                    });
                }
            } else {
                yield put({
                    type: CONFIG.BLOCK.UPDATE.FAIL,
                    payload: {
                        code: 0,
                    },
                });
            }
        }
    } catch (e) {
        yield put({
            type: CONFIG.BLOCK.UPDATE.FAIL,
            payload: {
                code: 0,
            },
        });
    }
}

export function* deleteBlock(action) {
    try {
        const res = yield call(api.deleteBlock, action.block.id);
        if (isObject(res)) {
            if (res.codeStatus) {
                if (res.codeStatus === 200) {
                    yield put({
                        type: CONFIG.BLOCK.DELETE.SUCCESS,
                        payload: {
                            code: res.codeStatus,
                            block: action.block,
                        },
                    });
                } else {
                    yield put({
                        type: CONFIG.BLOCK.DELETE.FAIL,
                        payload: {
                            code: res.codeStatus,
                            error: res.responseMsg.message,
                        },
                    });
                }
            } else {
                yield put({
                    type: CONFIG.BLOCK.DELETE.FAIL,
                    payload: {
                        code: 0,
                    },
                });
            }
        }
    } catch (e) {
        yield put({
            type: CONFIG.BLOCK.DELETE.FAIL,
            payload: {
                code: 0,
            },
        });
    }
}

// Service

export function* initServiceConfigPage(action) {
    const { buildingId } = action.payload;
    const result = yield call(getServiceSystemService, buildingId, CONFIG.SERVICE.GETS);
    yield put(result);
    yield put({
        type: CONFIG.SERVICE.INIT.SUCCESS,
        payload: {
            code: 200,
        },
    });
}

export function* addServiceToBuilding(action) {
    try {
        const service = {
            buildingId: action.service.buildingId,
            serviceId: action.service.serviceId,
            status: action.service.status,
        };
        const res = yield call(api.addServiceBuilding, service);
        if (isObject(res)) {
            if (res.codeStatus) {
                if (res.codeStatus === 200) {
                    yield put({
                        type: CONFIG.SERVICE.BUILDING_USE_SERVICE.SUCCESS,
                        payload: {
                            code: res.codeStatus,
                            service: {
                                id: res.data,
                                ...service,
                            },
                        },
                    });
                } else {
                    yield put({
                        type: CONFIG.SERVICE.BUILDING_USE_SERVICE.FAIL,
                        payload: {
                            serviceId: action.service.serviceId,
                            code: res.codeStatus,
                            error: res.responseMsg.message,
                        },
                    });
                }
            } else {
                yield put({
                    type: CONFIG.SERVICE.BUILDING_USE_SERVICE.FAIL,
                    payload: {
                        serviceId: action.service.serviceId,
                        code: 0,
                    },
                });
            }
        }
    }
    catch (err) {
        yield put({
            type: CONFIG.SERVICE.BUILDING_USE_SERVICE.FAIL,
            payload: {
                serviceId: action.service.serviceId,
                code: 0,
                error: err,
            },
        });
    }
}

export function* updateServiceToBuilding(action) {
    try {
        const service = {
            id: action.service.id,
            buildingId: action.service.buildingId,
            serviceId: action.service.serviceId,
            status: action.service.status,
        };
        const res = yield call(api.updateServiceBuilding, service);
        if (isObject(res)) {
            if (res.codeStatus) {
                if (res.codeStatus === 200) {
                    yield put({
                        type: CONFIG.SERVICE.BUILDING_UPDATE_SERVICE.SUCCESS,
                        payload: {
                            code: res.codeStatus,
                            service: service,
                        },
                    });
                } else {
                    yield put({
                        type: CONFIG.SERVICE.BUILDING_UPDATE_SERVICE.FAIL,
                        payload: {
                            serviceId: action.service.serviceId,
                            code: res.codeStatus,
                            error: res.responseMsg.message,
                        },
                    });
                }
            } else {
                yield put({
                    type: CONFIG.SERVICE.BUILDING_UPDATE_SERVICE.FAIL,
                    payload: {
                        serviceId: action.service.serviceId,
                        code: 0,
                    },
                });
            }
        }
    }
    catch (err) {
        yield put({
            type: CONFIG.SERVICE.BUILDING_UPDATE_SERVICE.FAIL,
            payload: {
                serviceId: action.service.serviceId,
                code: 0,
                error: err,
            },
        });
    }
}

//
// export function* removeBuildingService(action) {
//     try {
//         const res = yield call(api.deleteServiceBuildingByBuildingIdAndServiceId, action.service.buildingId, action.service.id);
//         if (isObject(res)) {
//             if (res.codeStatus) {
//                 if (res.codeStatus === 200) {
//                     yield put({
//                         type: CONFIG.SERVICE.BUILDING_DO_NOT_USE_SERVICE.SUCCESS,
//                         payload: {
//                             code: res.codeStatus,
//                             service: action.service
//                         }
//                     });
//                 } else {
//                     yield put({
//                         type: CONFIG.SERVICE.BUILDING_DO_NOT_USE_SERVICE.FAIL,
//                         payload: {
//                             serviceId: action.service.id,
//                             code: res.codeStatus,
//                             error: res.responseMsg.message
//                         }
//                     });
//                 }
//             } else {
//                 yield put({
//                     type: CONFIG.SERVICE.BUILDING_DO_NOT_USE_SERVICE.FAIL,
//                     payload: {
//                         serviceId: action.service.id,
//                         code: 0,
//                     }
//                 });
//             }
//         }
//     }
//     catch (err) {
//         yield put({
//             type: CONFIG.SERVICE.BUILDING_DO_NOT_USE_SERVICE.FAIL,
//             payload: {
//                 serviceId: action.service.id,
//                 code: 0,
//                 error: err
//             }
//         });
//     }
// }

export function* addAdditionalFee(action) {
    try {
        const additionalFee = {
            name: action.additionalFee.name,
            fee: action.additionalFee.fee,
            quantity: action.additionalFee.quantity,
            type: action.additionalFee.type,
        };
        const res = yield call(api.addAdditionalFee, additionalFee);
        if (isObject(res)) {
            if (res.codeStatus) {
                if (res.codeStatus === 200) {
                    yield put({
                        type: CONFIG.ADDITIONAL_FEE.ADD.SUCCESS,
                        payload: {
                            code: res.codeStatus,
                            additionalFee: {
                                id: res.data,
                                ...additionalFee,
                            },
                        },
                    });
                } else {
                    yield put({
                        type: CONFIG.ADDITIONAL_FEE.ADD.FAIL,
                        payload: {
                            code: res.codeStatus,
                            error: res.responseMsg.message,
                        },
                    });
                }
            } else {
                yield put({
                    type: CONFIG.ADDITIONAL_FEE.ADD.FAIL,
                    payload: {
                        code: 0,
                    },
                });
            }
        }
    } catch (e) {
        yield put({
            type: CONFIG.ADDITIONAL_FEE.ADD.FAIL,
            payload: {
                code: 0,
                error: e,
            },
        });
    }
}

export function* updateAdditionalFee(action) {
    try {
        const additionalFee = {
            id: action.additionalFee.id,
            name: action.additionalFee.name,
            fee: action.additionalFee.fee,
            quantity: action.additionalFee.quantity,
            type: action.additionalFee.type,
        };
        const res = yield call(api.updateAdditionalFee, additionalFee);
        if (isObject(res)) {
            if (res.codeStatus) {
                if (res.codeStatus === 200) {
                    yield put({
                        type: CONFIG.ADDITIONAL_FEE.UPDATE.SUCCESS,
                        payload: {
                            code: res.codeStatus,
                            additionalFee: additionalFee,
                        },
                    });
                } else {
                    yield put({
                        type: CONFIG.ADDITIONAL_FEE.UPDATE.FAIL,
                        payload: {
                            code: res.codeStatus,
                            error: res.responseMsg.message,
                        },
                    });
                }
            } else {
                yield put({
                    type: CONFIG.ADDITIONAL_FEE.UPDATE.FAIL,
                    payload: {
                        code: 0,
                    },
                });
            }
        }
    } catch (e) {
        yield put({
            type: CONFIG.ADDITIONAL_FEE.UPDATE.FAIL,
            payload: {
                code: 0,
                error: e,
            },
        });
    }
}

export function* deleteAdditionalFee(action) {
    try {
        const res = yield call(api.deleteAdditionalFee, action.additionalFee.id);
        if (isObject(res)) {
            if (res.codeStatus) {
                if (res.codeStatus === 200) {
                    yield put({
                        type: CONFIG.ADDITIONAL_FEE.DELETE.SUCCESS,
                        payload: {
                            code: res.codeStatus,
                            additionalFee: action.additionalFee,
                        },
                    });
                } else {
                    yield put({
                        type: CONFIG.ADDITIONAL_FEE.DELETE.FAIL,
                        payload: {
                            code: res.codeStatus,
                            error: res.responseMsg.message,
                        },
                    });
                }
            } else {
                yield put({
                    type: CONFIG.ADDITIONAL_FEE.DELETE.FAIL,
                    payload: {
                        code: 0,
                    },
                });
            }
        }
    } catch (e) {
        yield put({
            type: CONFIG.ADDITIONAL_FEE.DELETE.FAIL,
            payload: {
                code: 0,
                error: e,
            },
        });
    }
}

export function* getAllAdditionFees(action) {
    yield put({
        type: CONFIG.ADDITIONAL_FEE.GETS.SUCCESS,
        payload: {
            code: 200,
            additionalFees: [],
        },
    });
    return;
    try {
        const res = yield call(api.getAllAdditionFees);
        if (isObject(res)) {
            if (res.codeStatus) {
                if (res.codeStatus === 200) {
                    yield put({
                        type: CONFIG.ADDITIONAL_FEE.GETS.SUCCESS,
                        payload: {
                            code: res.codeStatus,
                            additionalFees: res.data,
                        },
                    });
                } else {
                    yield put({
                        type: CONFIG.ADDITIONAL_FEE.GETS.FAIL,
                        payload: {
                            code: res.codeStatus,
                            error: res.responseMsg.message,
                        },
                    });
                }
            } else {
                yield put({
                    type: CONFIG.ADDITIONAL_FEE.GETS.FAIL,
                    payload: {
                        code: 0,
                    },
                });
            }
        }
    } catch (e) {
        yield put({
            type: CONFIG.ADDITIONAL_FEE.GETS.FAIL,
            payload: {
                code: 0,
                error: e,
            },
        });
    }
}

export function* addAllowance(action) {
    try {
        const allowance = {
            buildingId: action.allowance.buildingId,
            name: action.allowance.name,
            value: action.allowance.value,
            type: action.allowance.type,
        };
        const res = yield call(api.addAllowance, allowance);
        if (isObject(res)) {
            if (res.codeStatus) {
                if (res.codeStatus === 200) {
                    yield put({
                        type: CONFIG.SERVICE.ALLOWANCE.ADD.SUCCESS,
                        payload: {
                            code: res.codeStatus,
                            allowance: {
                                id: res.data,
                                ...allowance,
                            },
                        },
                    });
                } else {
                    yield put({
                        type: CONFIG.SERVICE.ALLOWANCE.ADD.FAIL,
                        payload: {
                            code: res.codeStatus,
                            error: res.responseMsg.message,
                        },
                    });
                }
            } else {
                yield put({
                    type: CONFIG.SERVICE.ALLOWANCE.ADD.FAIL,
                    payload: {
                        code: 0,
                    },
                });
            }
        }
    } catch (e) {
        yield put({
            type: CONFIG.SERVICE.ALLOWANCE.ADD.FAIL,
            payload: {
                code: 0,
                error: e,
            },
        });
    }
}

export function* deleteAllowance(action) {
    try {
        const res = yield call(api.deleteAllowance, action.allowance.id);
        if (isObject(res)) {
            if (res.codeStatus) {
                if (res.codeStatus === 200) {
                    yield put({
                        type: CONFIG.SERVICE.ALLOWANCE.DELETE.SUCCESS,
                        payload: {
                            code: res.codeStatus,
                            allowance: action.allowance,
                        },
                    });
                } else {
                    yield put({
                        type: CONFIG.SERVICE.ALLOWANCE.DELETE.FAIL,
                        payload: {
                            code: res.codeStatus,
                            error: res.responseMsg.message,
                        },
                    });
                }
            } else {
                yield put({
                    type: CONFIG.SERVICE.ALLOWANCE.DELETE.FAIL,
                    payload: {
                        code: 0,
                    },
                });
            }
        }
    } catch (e) {
        yield put({
            type: CONFIG.SERVICE.ALLOWANCE.DELETE.FAIL,
            payload: {
                code: 0,
                error: e,
            },
        });
    }
}

export function* getAllowanceByBuilding(action) {
    yield put({
        type: CONFIG.SERVICE.ALLOWANCE.GETS_BY_BUILDING.SUCCESS,
        payload: {
            code: 200,
            allowances: [],
        },
    });
    return;
    try {
        const res = yield call(api.getAllowanceByBuilding, action.buildingId);
        if (isObject(res)) {
            if (res.codeStatus) {
                if (res.codeStatus === 200) {
                    yield put({
                        type: CONFIG.SERVICE.ALLOWANCE.GETS_BY_BUILDING.SUCCESS,
                        payload: {
                            code: res.codeStatus,
                            allowances: res.data,
                        },
                    });
                } else {
                    yield put({
                        type: CONFIG.SERVICE.ALLOWANCE.GETS_BY_BUILDING.FAIL,
                        payload: {
                            code: res.codeStatus,
                            error: res.responseMsg.message,
                        },
                    });
                }
            } else {
                yield put({
                    type: CONFIG.SERVICE.ALLOWANCE.GETS_BY_BUILDING.FAIL,
                    payload: {
                        code: 0,
                    },
                });
            }
        }
    } catch (e) {
        yield put({
            type: CONFIG.SERVICE.ALLOWANCE.GETS_BY_BUILDING.FAIL,
            payload: {
                code: 0,
                error: e,
            },
        });
    }
}
