import { useDispatch, useSelector } from 'react-redux';
import { SUCCESS_CODE } from 'shared/constants';
import { FundDucksActions, FundDucksSelectors } from 'pages/Config/PaymentAccount/ducks';
import { CASH, ONLINE, TRANSFER } from 'constants/payment-account';
import find from 'lodash/find';
import useCurrentBuilding from './useCurrentBuilding';

const { getFundsAction } = FundDucksActions;
const { fundSelector, fundsTransferSelector, fundsCashSelector, fundsOnlineSelector } = FundDucksSelectors;

const useFund = () => {
  const dispatch = useDispatch();
  const { building } = useCurrentBuilding();

  // data
  const fundData = useSelector(fundSelector);
  const fundsTransfer = useSelector(fundsTransferSelector);
  const fundsCash = useSelector(fundsCashSelector);
  const fundsOnline = useSelector(fundsOnlineSelector);
  // function
  const getFunds = () => {
    const { code } = fundData;
    if (SUCCESS_CODE.includes(code)) {
      return;
    }
    dispatch(getFundsAction(building.id));
  };
  const getFundsByMethod = (method) => {
    switch (method) {
      case CASH: {
        return fundsCash;
      }
      case ONLINE: {
        return fundsOnline;
      }
      case TRANSFER: {
        return fundsTransfer;
      }
      default: {
        return [];
      }
    }
  };
  const getFundById = (id) => {
    return find(fundData?.data, { id });
  };
  return {
    getFunds,
    fundData,
    getFundsByMethod,
    getFundById,
    fundsTransfer,
  };
};

export default useFund;
