import { call, put } from 'redux-saga/effects';
import { getApartments as getApartmentsService } from '../services';
import { CARD } from '../constants/actionType';
import { X_UNIT_GROUP_ID } from '../constants';
import { PAGINATION } from 'constants/pagination';
import {
    getCardsSuggestService,
    addRegisterService,
    getlistServices,
    addCardService,
    updateCardService,
    getCardListService,
    deleteCardService,
    exportCardService,
    getCardHistoryService,
    importCardListService,
    updateCardStatusService,
    getRegistersService,
    deleteRegisterService,
    printRegisterService,
    rePrintRegisterService,
    previewRegisterService,
    getContractTemplatesService,
    exportRegisterService,
    getCardUsageHistoryService
} from '../services/card';
import { getCountryCodes as getCountryCodesService } from '../services/base'
import { getServiceContracts } from '../services/service';
import { getResidentsService } from '../services/resident';


export function* addCard(action) {
    const { buildingId, ...rest } = action.payload;

    const result = yield call(addCardService, {
        params: rest,
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, CARD.ADD);

    yield put(result);
    yield put({
        type: CARD.ADD.REFRESH
    });
}

export function* updateCard(action) {
    const { buildingId, ...rest } = action.payload;

    const result = yield call(updateCardService, {
        params: rest,
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, CARD.UPDATE);

    yield put(result);
    yield put({
        type: CARD.UPDATE.REFRESH
    });
}

export function* getCardList(action) {
    const { buildingId, ...rest } = action.payload;

    const result = yield call(getCardListService, {
        params: rest,
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, CARD.GETS);

    yield put(result);
    yield put({
        type: CARD.GETS.REFRESH
    });
}

export function* deleteCard(action) {
    const { buildingId, ...rest } = action.payload;

    const result = yield call(deleteCardService, {
        params: rest,
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, CARD.DELETE);

    yield put(result);
    yield put({
        type: CARD.DELETE.REFRESH
    });
}

export function* importCard(action) {
    const {
        buildingId,
        file,
    } = action.payload;
    const result = yield call(importCardListService, {
        dataAttach: {},
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
        file,
    }, CARD.IMPORT);
    yield put(result);
    yield put({
        type: CARD.IMPORT.REFRESH,
    });
}

export function* exportCard(action) {
    const { buildingId, ...rest } = action.payload;

    const result = yield call(exportCardService, {
        params: rest,
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, CARD.EXPORT);

    yield put(result);
    yield put({
        type: CARD.EXPORT.REFRESH
    });
}

export function* getCardHistory(action) {
    const { buildingId, ...rest } = action.payload;

    const result = yield call(getCardHistoryService, {
        params: rest,
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, CARD.HISTORY);

    yield put(result);
    yield put({
        type: CARD.HISTORY.REFRESH
    });
}

export function* updateCardStatus(action) {
    const { buildingId, ...rest } = action.payload;

    const result = yield call(updateCardStatusService, {
        params: rest,
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, CARD.UPDATE_STATUS);

    yield put(result);
    yield put({
        type: CARD.UPDATE_STATUS.REFRESH
    });
}

export function* getUnits(action) {
    const { buildingId, filters = {}, limit = PAGINATION.LIMIT, page = 1 } = action.payload;

    const result = yield call(getApartmentsService, buildingId, page, limit, filters, CARD.UNIT.GETS);

    yield put(result);
    yield put({
        type: CARD.UNIT.GETS.REFRESH
    });
}

export function* getCardsSuggest(action) {
    const { buildingId, posCard, ...rest } = action.payload;

    const result = yield call(getCardListService, {
        params: rest,
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, CARD.SUGGEST.GETS);
    result.index = posCard;
    yield put(result);
    yield put({
        type: CARD.SUGGEST.GETS.REFRESH
    });
}

export function* addRegister(action) {
    const { buildingId, ...rest } = action.payload;
    
    const result = yield call(addRegisterService, {
        params: rest,
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, CARD.REGISTER_ADD);

    yield put(result);
    yield put({
        type: CARD.REGISTER_ADD.REFRESH
    });
}

export function* getResidents(action) {
    const { buildingId, ...rest } = action.payload;

    const result = yield call(getResidentsService, {
        params: rest,
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, CARD.REGISTER_RESIDENT_GETS);

    yield put(result);
    yield put({
        type: CARD.REGISTER_RESIDENT_GETS.REFRESH
    });
}

export function* getVehicles(action) {
    const { buildingId, ...rest } = action.payload;
    const filter = {
        unitGroupId: buildingId,
        ...rest,
    }

    const result = yield call(getServiceContracts, filter, 1, CARD.REGISTER_VEHICLE_GETS);

    yield put(result);
    yield put({
        type: CARD.REGISTER_VEHICLE_GETS.REFRESH
    });
}

export function* getServices(action) {
    const { buildingId, ...rest } = action.payload;

    const result = yield call(getlistServices, {
        params: rest,
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, CARD.REGISTER_SERVICE_GETS);

    yield put(result);
    yield put({
        type: CARD.REGISTER_SERVICE_GETS.REFRESH
    });
}

export function* getRegisters(action) {
    const { buildingId, ...rest } = action.payload;

    const result = yield call(getRegistersService, {
        params: rest,
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, CARD.REGISTER_GETS);

    yield put(result);
    yield put({
        type: CARD.REGISTER_GETS.REFRESH
    });
}

export function* deleteRegister(action) {
    const { buildingId, ...rest } = action.payload;

    const result = yield call(deleteRegisterService, {
        params: rest,
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, CARD.REGISTER_DELETE);

    yield put(result);
    yield put({
        type: CARD.REGISTER_DELETE.REFRESH
    });
}

export function* printRegister(action) {
    const { buildingId, ...rest } = action.payload;

    const result = yield call(printRegisterService, {
        params: rest,
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, CARD.REGISTER_PRINT);

    yield put(result);
    yield put({
        type: CARD.REGISTER_PRINT.REFRESH
    });
}

export function* rePrintRegister(action) {
    const { buildingId, ...rest } = action.payload;

    const result = yield call(rePrintRegisterService, {
        params: rest,
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, CARD.REGISTER_REPRINT);

    yield put(result);
    yield put({
        type: CARD.REGISTER_REPRINT.REFRESH
    });
}

export function* previewRegister(action) {
    const { buildingId, ...rest } = action.payload;

    const result = yield call(previewRegisterService, {
        params: rest,
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, CARD.REGISTER_PREVIEW);

    yield put(result);
    yield put({
        type: CARD.REGISTER_PREVIEW.REFRESH
    });
}

export function* getContractTemplates(action) {
    const { buildingId, ...rest } = action.payload;

    const result = yield call(getContractTemplatesService, {
        params: rest,
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, CARD.REGISTER_CONTRACT_TEMPLATE_GETS);

    yield put(result);
    yield put({
        type: CARD.REGISTER_CONTRACT_TEMPLATE_GETS.REFRESH
    });
}

export function* exportRegister(action) {
    const { buildingId, ...rest } = action.payload;

    const result = yield call(exportRegisterService, {
        params: rest,
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, CARD.REGISTER_EXPORT);

    yield put(result);
    yield put({
        type: CARD.REGISTER_EXPORT.REFRESH
    });
}

export function* getCardUsageHistory(action) {
    const { buildingId, ...rest } = action.payload;

    const result = yield call(getCardUsageHistoryService, {
        params: rest,
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, CARD.HISTORY_USAGE);
    yield put(result);
}

export function* getCountryCodes() {
    const result = yield call(getCountryCodesService, CARD.REGISTER_COUNTRY_CODE_GETS);
    yield put(result);
}


