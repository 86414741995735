import { initStatePagination } from './paginate';
import paginate from './paginate';
import { combineReducers } from 'redux';
import { initStateFetching } from './fetching';
import fetching from './fetching';
import { FORM_RECEIPT } from '../constants';

export const initState = {
    formInit: {
        ...initStateFetching,
    },
    apartmentGetInForm: {
        ...initStatePagination,
    },
    payerSuggestions: {
        ...initStatePagination,
    },
    funds: {
        ...initStatePagination,
    },
    tags: {
        ...initStatePagination,
    },
};

export default combineReducers({
    formInit: fetching(FORM_RECEIPT.INIT),
    apartmentGetInForm: paginate(FORM_RECEIPT.SEARCH_APARTMENT),
    payerSuggestions: paginate(FORM_RECEIPT.PAYER_SUGGESTIONS),
    funds: paginate(FORM_RECEIPT.FUND.GETS),
    tags: paginate(FORM_RECEIPT.TAG.GETS),
});
