import {
    getApartments,
    searchEnterpriseByNameService,
} from '../services';
import { FORM_RESIDENT } from '../constants/actionType';
import { call, put } from 'redux-saga/effects';
import { PAGINATION } from '../constants';

export function* initFormResident(action) {
    const {
        buildingId,
        apartmentId,
    } = action.payload;
    try {
        let payload = {
            code: 200,
            positions: [],
            status: [],
        };
        let error = [];
        const result = yield call(getApartments, buildingId, 1, PAGINATION.LIMIT, {
            id: apartmentId
        }, FORM_RESIDENT.SEARCH_APARTMENT);
        yield put(result);

        yield put({
            type: FORM_RESIDENT.INIT.SUCCESS,
            payload: {
                ...payload,
                error: error.join(', ')
            }
        });
        yield put({
            type: FORM_RESIDENT.INIT.REFRESH,
        });
    } catch (e) {
        yield put({
            type: FORM_RESIDENT.INIT.FAIL,
            payload: {
                code: 0,
                error: e
            }
        });
    }
}

export function* searchApartmentInFormResident(action) {
    const {
        buildingId,
        name,
    } = action.payload;

    const result = yield call(getApartments, buildingId, 1, 20, {
        name
    }, FORM_RESIDENT.SEARCH_APARTMENT);

    yield put({
        ...result
    });
}
