import { STAFF_GETS, STAFF_ADD, STAFF_UPDATE, STAFF_DELETE, STAFF_IMPORT, STAFF_EXPORT, STAFF_DEPARTMENT_GETS } from './staff.types';

export const getStaffsAction = (unitGroupId = 0, filterObject = {}, page = 1) => ({
  type: STAFF_GETS.REQUEST,
  payload: {
    unitGroupId,
    filterObject,
    page,
  },
});

export const addStaffAction = (unitGroupId = 0, staff = {}) => ({
  type: STAFF_ADD.REQUEST,
  payload: {
    unitGroupId,
    staff,
  },
});

export const updateStaffAction = (unitGroupId = 0, staff = {}) => ({
  type: STAFF_UPDATE.REQUEST,
  payload: {
    unitGroupId,
    staff,
  },
});

export const deleteStaffsAction = (unitGroupId = 0, ids = [], isAll = false) => ({
  type: STAFF_DELETE.REQUEST,
  payload: {
    unitGroupId,
    ids,
    isAll,
  },
});

export const importStaffAction = (unitGroupId = 0, file) => ({
  type: STAFF_IMPORT.REQUEST,
  payload: {
    unitGroupId,
    file,
  },
});

export const resetDataImportStaffAction = () => ({
  type: STAFF_IMPORT.SUCCESS,
  payload: {
    isFetching: false,
    currentPage: 1,
    totalPage: 0,
    totalRecord: 0,
    data: [],
  },
});

export const exportStaffAction = (unitGroupId = 0, filterObject = {}) => ({
  type: STAFF_EXPORT.REQUEST,
  payload: {
    unitGroupId,
    filterObject,
  },
});

export const getDepartmentsAction = (unitGroupId = 0, isIncludePermission = true) => ({
  type: STAFF_DEPARTMENT_GETS.REQUEST,
  payload: {
    unitGroupId,
    isIncludePermission,
  },
});
