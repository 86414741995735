import { STATISTIC } from '../../constants/actionType';
import { call, put } from 'redux-saga/effects';
import {
  getPaymentOnlineOrder as getPaymentOnlineOrderService
} from '../../services/paymentOnline';
import { pick } from 'lodash';

export function* getPaymentOnlineOrder(action) {
  const { unitGroupId, page, limit = 50 } = action.payload;

  const params = pick(action.payload.filterObject, ['from', 'to', 'type']);

  const result = yield call(getPaymentOnlineOrderService, unitGroupId, {
    page, limit, params
  }, STATISTIC.PAYMENT_ONLINE.GETS);

  yield put(result);
}