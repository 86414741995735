import { call, takeLatest, put, all } from 'redux-saga/effects';
import {
  getsReceivePackageRegistrationFormService,
  approveReceivePackageRegistrationFormService,
  cancelReceivePackageRegistrationFormService,
  receivedReceivePackageRegistrationFormService,
  completeReceivePackageRegistrationFormService,
} from 'services/residentRegistrationForm';
import {
  RESIDENT_REGISTRATION_FORM_STATUS_APPROVED,
  RESIDENT_REGISTRATION_FORM_STATUS_CANCELLED,
  RESIDENT_REGISTRATION_FORM_STATUS_COMPLETE,
  RESIDENT_REGISTRATION_FORM_STATUS_RECEIVED,
  X_UNIT_GROUP_ID,
} from 'shared/constants';
import {
  REGISTRATION_FORM_RECEIVE_PACKAGE_ACTION_GETS,
  REGISTRATION_FORM_RECEIVE_PACKAGE_ACTION_UPDATE_STATUS,
} from './receive-package.types';

function* getsReceivePackageRegistrationForm(action) {
  const { idBuilding, filterObj, page, limit } = action.payload;
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const params = {
    ...filterObj,
    page,
    limit,
  };
  const result = yield call(getsReceivePackageRegistrationFormService, headers, params, REGISTRATION_FORM_RECEIVE_PACKAGE_ACTION_GETS);
  yield put(result);
}

function* updateStatusReceivePackageRegistrationForm(action) {
  const { idBuilding, id, status, reason, note, images } = action.payload;
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const body = {
    id,
    reason,
    note,
    images,
  };
  let result;
  if (status === RESIDENT_REGISTRATION_FORM_STATUS_APPROVED) {
    result = yield call(
      approveReceivePackageRegistrationFormService,
      headers,
      body,
      REGISTRATION_FORM_RECEIVE_PACKAGE_ACTION_UPDATE_STATUS
    );
  } else if (status === RESIDENT_REGISTRATION_FORM_STATUS_RECEIVED) {
    result = yield call(
      receivedReceivePackageRegistrationFormService,
      headers,
      body,
      REGISTRATION_FORM_RECEIVE_PACKAGE_ACTION_UPDATE_STATUS
    );
  } else if (status === RESIDENT_REGISTRATION_FORM_STATUS_CANCELLED) {
    result = yield call(cancelReceivePackageRegistrationFormService, headers, body, REGISTRATION_FORM_RECEIVE_PACKAGE_ACTION_UPDATE_STATUS);
  } else if (status === RESIDENT_REGISTRATION_FORM_STATUS_COMPLETE) {
    result = yield call(
      completeReceivePackageRegistrationFormService,
      headers,
      body,
      REGISTRATION_FORM_RECEIVE_PACKAGE_ACTION_UPDATE_STATUS
    );
  }
  yield put(result);
  yield put({
    type: REGISTRATION_FORM_RECEIVE_PACKAGE_ACTION_UPDATE_STATUS.REFRESH,
  });
}

export default function* watch() {
  yield all([
    takeLatest(REGISTRATION_FORM_RECEIVE_PACKAGE_ACTION_GETS.REQUEST, getsReceivePackageRegistrationForm),
    takeLatest(REGISTRATION_FORM_RECEIVE_PACKAGE_ACTION_UPDATE_STATUS.REQUEST, updateStatusReceivePackageRegistrationForm),
  ]);
}
