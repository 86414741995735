import { getAmenityMerchantOrderService } from 'services/amenities';
import { put, call, takeLatest, all } from 'redux-saga/effects';
import { X_UNIT_GROUP_ID } from 'shared/constants';
import { AMENITY_MERCHANT_ORDER_DETAIL } from './amenities-detail.types';

function* getOrder(action) {
  try {
    const { orderId = '', buildingId } = action.payload;
    const headers = {
      [X_UNIT_GROUP_ID]: buildingId,
    };
    const result = yield call(
      getAmenityMerchantOrderService,
      {
        params: { id: orderId },
        headers,
      },
      AMENITY_MERCHANT_ORDER_DETAIL.GET
    );
    yield put({
      ...result,
    });
  } catch (e) {
    yield put({
      type: AMENITY_MERCHANT_ORDER_DETAIL.GET.FAIL,
      payload: {
        code: 0,
        error: e,
      },
    });
  }
}

export default function* watch() {
  yield all([takeLatest(AMENITY_MERCHANT_ORDER_DETAIL.GET.REQUEST, getOrder)]);
}
