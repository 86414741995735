import {
    getDepartmentsService,
} from '../services';
import { FORM_RESIDENT, FORM_STAFF } from '../constants/actionType';
import { call, put } from 'redux-saga/effects';
import { X_UNIT_GROUP_ID } from '../constants';

export function* initFormStaff(action) {
    const {
        buildingId
    } = action.payload;
    try {
        const headersDepartment = {
            [X_UNIT_GROUP_ID]: buildingId
        };
        let resDepartment = yield call(getDepartmentsService, {
            headers: headersDepartment
        }, FORM_STAFF.DEPARTMENT.GETS);
        yield put(resDepartment);

        yield put({
            type: FORM_STAFF.INIT.SUCCESS,
            payload: {
                code: resDepartment.payload.code,
            },
        });
        yield put({
            type: FORM_RESIDENT.INIT.REFRESH,
        });
    } catch (e) {
        yield put({
            type: FORM_RESIDENT.INIT.FAIL,
            payload: {
                code: 0,
                error: e
            }
        });
    }
}