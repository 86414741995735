import {
  RESET_RESIDENT_FORM_GET_ENTITY,
  RESIDENT_FORM_GET_ENTITY,
  RESIDENT_FORM_ADD,
  RESIDENT_FORM_GET_UNITS,
  RESIDENT_FORM_UPDATE,
  RESIDENT_FORM_GET_COUNTRY_CODE,
} from './residentForm.types';

export const addResidentAction = (unitGroupId, resident) => ({
  type: RESIDENT_FORM_ADD.REQUEST,
  payload: {
    unitGroupId,
    resident,
  },
});

export const resetAddResidentAction = () => ({
  type: RESIDENT_FORM_ADD.REFRESH,
});

export const updateResidentAction = (unitGroupId, residentId, resident) => ({
  type: RESIDENT_FORM_UPDATE.REQUEST,
  payload: {
    unitGroupId,
    residentId,
    resident,
  },
});

export const resetUpdateResidentAction = () => ({
  type: RESIDENT_FORM_UPDATE.REFRESH,
});

export const searchApartmentInFormResident = (unitGroupId, name) => ({
  type: RESIDENT_FORM_GET_UNITS.REQUEST,
  payload: {
    unitGroupId,
    name,
  },
});

export const getResidentEntityAction = (unitGroupId, residentId) => ({
  type: RESIDENT_FORM_GET_ENTITY.REQUEST,
  payload: {
    unitGroupId,
    residentId,
  },
});

export const resetResidentEntityAction = () => ({
  type: RESET_RESIDENT_FORM_GET_ENTITY,
});

export const getCountryCodeAction = () => ({
  type: RESIDENT_FORM_GET_COUNTRY_CODE.REQUEST,
});
