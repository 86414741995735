import { createSelector } from 'reselect';
import reduce from 'lodash/reduce';
import { formatCurrency } from 'shared/helpers';

export const cashbookSelector = (state) => state.cashbook.data;

export const cashbookExportSelector = (state) => state.cashbook.export;

export const openingBalanceSelector = createSelector([cashbookSelector], (data) => formatCurrency(data.data.openingBalance));

export const closeBalanceSelector = createSelector([cashbookSelector], (data) => formatCurrency(data.data.closeBalance));

export const otherTotalSelector = createSelector([cashbookSelector], (data) => formatCurrency(data.data.otherTotal));

export const grossRevenueSelector = createSelector([cashbookSelector], (data) => formatCurrency(data.data.grossRevenue));

export const grossExpenseSelector = createSelector([cashbookSelector], (data) => formatCurrency(data.data.grossExpense));

export const totalUnitAccountRevenueSelector = createSelector([cashbookSelector], (data) =>
  formatCurrency(data.data.totalUnitAccountRevenue)
);

export const totalUnitAccountExpenseSelector = createSelector([cashbookSelector], (data) =>
  formatCurrency(data.data.totalUnitAccountExpense)
);

export const fundDataSelector = createSelector([cashbookSelector], (data) => data.data.funds);

export const fundTotalSelector = createSelector([fundDataSelector], (data) =>
  reduce(data, (result, item) => ({ ...result, [item.id]: formatCurrency(item.total) }), {})
);

export const serviceDataSelector = createSelector([cashbookSelector], (data) => data.data.services);

export const serviceTotalSelector = createSelector([serviceDataSelector], (data) =>
  reduce(data, (result, item) => ({ ...result, [item.id]: formatCurrency(item.total) }), {})
);

export const rowsSelector = createSelector([cashbookSelector], (data) => data.data.rows);
