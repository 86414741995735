import createAction from './createAction';
import convertArrToObj from './convertArrToObj';
import convertPeriodTimeToMonths from './convertPeriodTimeToMonths';
import combineOptionAll from './combineOptionAll';
import debounceFunc from './debounceFunc';
import fetching, { initStateFetching } from './fetching';
import mergeArr from './mergeArr';
import paginate, { initStatePagination } from './paginate';
import shouldForwardPropEmotion from './shouldForwardPropEmotion';
import getBase64 from './getBase64';
import threeDigits from './threeDigits';
import { checkResponseError, handleError, handleResponse, getTotalPage, getTotalRecord, getFilter } from './http';
import buildDetailBills from './buildDetailBills';
import isValidImageURL from './isValidImageURL';
import convertPhoneToNumber from './convertPhoneToNumber';
import intersectPeriodTime from './intersectPeriodTime';
import normalizeLabel from './normalizeLabel';
import convertDateToString from './convertDateToString';
import isNil from './isNil';
import xssFilter from './xss-filter';
import threeDigitsWithDecimal from './threeDigitsWithDecimal';
import isPhone from './isPhone';
import validatePhone from './validate/validatePhone';

export {
  initStateFetching,
  initStatePagination,
  createAction,
  convertArrToObj,
  debounceFunc,
  fetching,
  mergeArr,
  paginate,
  getBase64,
  threeDigits,
  checkResponseError,
  handleError,
  handleResponse,
  getTotalPage,
  getTotalRecord,
  getFilter,
  shouldForwardPropEmotion,
  combineOptionAll,
  convertPeriodTimeToMonths,
  buildDetailBills,
  isValidImageURL,
  convertPhoneToNumber,
  intersectPeriodTime,
  normalizeLabel,
  convertDateToString,
  isNil,
  isPhone,
  xssFilter,
  threeDigitsWithDecimal,
  validatePhone,
};
