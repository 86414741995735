import { call, put } from 'redux-saga/effects';
import { REPORT, X_UNIT_GROUP_ID } from '../../constants';
import {
    exportInvestorService,
    getInvestorService,
    getInvestorTotalService,
} from '../../services/report';

export function* getInvestor(action) {
    const {
        page,
        filterObject,
        unitGroupId,
    } = action.payload;

    const params = {
        ...filterObject,
        page,
        limit: 20,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(getInvestorService, { params, headers }, REPORT.INVESTOR.GETS);
    yield put(result);
}

export function* getTotalInvestor(action) {
    const {
        filterObject,
        unitGroupId,
    } = action.payload;

    const params = {
        ...filterObject,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(getInvestorTotalService, { params, headers }, REPORT.INVESTOR.GET_TOTAL);
    yield put(result);
}

export function* exportInvestor(action) {
    const {
        filterObject,
        unitGroupId,
    } = action.payload;

    const params = {
        ...filterObject,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(exportInvestorService, { params, headers }, REPORT.INVESTOR.EXPORT);
    yield put(result);

    yield put({
        type: REPORT.INVESTOR.EXPORT.REFRESH,
    });
}