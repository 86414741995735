import { combineReducers } from 'redux';
import { AMENITIES_ORDER_DETAIL } from './amenities-detail.types';

export const initState = {
  get: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
    data: undefined,
  },
  payment: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
    data: undefined,
  },
  refund: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
    data: undefined,
  },
  refundDeposit: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
    data: undefined,
  },
  previewReceipt: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
    data: undefined,
  },
  previewPaySlip: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
    data: undefined,
  },
};

const reducer = combineReducers({
  get: (state = initState.get, action) => {
    switch (action.type) {
      case AMENITIES_ORDER_DETAIL.GET.REQUEST:
        return {
          isLoading: true,
          code: undefined,
          codeLanguage: undefined,
          data: undefined,
        };
      case AMENITIES_ORDER_DETAIL.GET.SUCCESS:
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: action.payload.result,
        };
      case AMENITIES_ORDER_DETAIL.GET.FAIL:
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: undefined,
        };
      case AMENITIES_ORDER_DETAIL.GET.REFRESH:
        return {
          ...initState.get,
        };
      default:
        return state;
    }
  },
  payment: (state = initState.payment, action) => {
    switch (action.type) {
      case AMENITIES_ORDER_DETAIL.PAYMENT.REQUEST:
        return {
          isLoading: true,
          code: undefined,
          codeLanguage: undefined,
          data: undefined,
        };
      case AMENITIES_ORDER_DETAIL.PAYMENT.SUCCESS:
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: action.payload.result,
        };
      case AMENITIES_ORDER_DETAIL.PAYMENT.FAIL:
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: undefined,
        };
      case AMENITIES_ORDER_DETAIL.PAYMENT.REFRESH:
        return {
          ...initState.payment,
        };
      default:
        return state;
    }
  },
  refund: (state = initState.refund, action) => {
    switch (action.type) {
      case AMENITIES_ORDER_DETAIL.REFUND.REQUEST:
        return {
          isLoading: true,
          code: undefined,
          codeLanguage: undefined,
          data: undefined,
        };
      case AMENITIES_ORDER_DETAIL.REFUND.SUCCESS:
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: action.payload.result,
        };
      case AMENITIES_ORDER_DETAIL.REFUND.FAIL:
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: undefined,
        };
      case AMENITIES_ORDER_DETAIL.REFUND.REFRESH:
        return {
          ...initState.refund,
        };
      default:
        return state;
    }
  },
  refundDeposit: (state = initState.refundDeposit, action) => {
    switch (action.type) {
      case AMENITIES_ORDER_DETAIL.REFUND_DEPOSIT.REQUEST:
        return {
          isLoading: true,
          code: undefined,
          codeLanguage: undefined,
          data: undefined,
        };
      case AMENITIES_ORDER_DETAIL.REFUND_DEPOSIT.SUCCESS:
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: action.payload.result,
        };
      case AMENITIES_ORDER_DETAIL.REFUND_DEPOSIT.FAIL:
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: undefined,
        };
      case AMENITIES_ORDER_DETAIL.REFUND_DEPOSIT.REFRESH:
        return {
          ...initState.refundDeposit,
        };
      default:
        return state;
    }
  },
  previewReceipt: (state = initState.previewReceipt, action) => {
    switch (action.type) {
      case AMENITIES_ORDER_DETAIL.PREVIEW_RECEIPT.REQUEST:
        return {
          isLoading: true,
          code: undefined,
          codeLanguage: undefined,
          data: undefined,
        };
      case AMENITIES_ORDER_DETAIL.PREVIEW_RECEIPT.SUCCESS:
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: action.payload.result,
        };
      case AMENITIES_ORDER_DETAIL.PREVIEW_RECEIPT.FAIL:
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: undefined,
        };
      case AMENITIES_ORDER_DETAIL.PREVIEW_RECEIPT.REFRESH:
        return {
          ...initState.previewReceipt,
        };
      default:
        return state;
    }
  },
  previewPaySlip: (state = initState.previewPaySlip, action) => {
    switch (action.type) {
      case AMENITIES_ORDER_DETAIL.PREVIEW_PAYSLIP.REQUEST:
        return {
          isLoading: true,
          code: undefined,
          codeLanguage: undefined,
          data: undefined,
        };
      case AMENITIES_ORDER_DETAIL.PREVIEW_PAYSLIP.SUCCESS:
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: action.payload.result,
        };
      case AMENITIES_ORDER_DETAIL.PREVIEW_PAYSLIP.FAIL:
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: undefined,
        };
      case AMENITIES_ORDER_DETAIL.PREVIEW_PAYSLIP.REFRESH:
        return {
          ...initState.previewPaySlip,
        };
      default:
        return state;
    }
  },
});

export default reducer;
