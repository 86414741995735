//@flow
import {
    getDataApartmentPage as _getDataApartmentPage
} from './apartment';
import {
    initApartmentDetail as _initApartmentDetail,
    getApartmentById as _getApartmentById,
    getApartmentByIdRefresh as _getApartmentByIdRefresh,
    getResidentsByApartment as _getResidentsByApartment,
    addResidentToApartment as _addResidentToApartment,
    updateResidentToApartment as _updateResidentToApartment,
    deleteResidentToApartment as _deleteResidentToApartment,
    refreshAddResidentToApartment as _refreshAddResidentToApartment,
    refreshUpdateResidentToApartment as _refreshUpdateResidentToApartment,
    refreshDeleteResidentToApartment as _refreshDeleteResidentToApartment,
    getDocumentsByApartment as _getDocumentsByApartment,
    addDocumentsToApartment as _addDocumentsToApartment,
    refreshAddDocumentToApartment as _refreshAddDocumentToApartment,
    deleteDocumentToApartment as _deleteDocumentToApartment,
    refreshDeleteDocumentToApartment as _refreshDeleteDocumentToApartment,
} from './apartmentDetail';
import {
    getBuildings as _getBuildings,
    getBuildingById as _getBuildingById,
    addBuilding as _addBuilding,
    refreshAddBuilding as _refreshAddBuilding,
    updateBuilding as _updateBuilding,
    refreshUpdateBuilding as _refreshUpdateBuilding,
    deleteBuildings as _deleteBuildings,
    refreshDeleteBuildings as _refreshDeleteBuildings,
    changeViewTypeBuilding as _changeViewTypeBuilding
} from './building';
import {
    initDataApartmentConfigPage as _initDataApartmentConfigPage,
    addApartment as _addApartment,
    refreshAddApartment as _refreshAddApartment,
    importApartment as _importApartment,
    refreshImportApartment as _refreshImportApartment,
    updateApartment as _updateApartment,
    refreshUpdateApartment as _refreshUpdateApartment,
    deleteApartment as _deleteApartment,
    refreshDeleteApartment as _refreshDeleteApartment,
    addFloor as _addFloor,
    refreshAddFloor as _refreshAddFloor,
    updateFloor as _updateFloor,
    refreshUpdateFloor as _refreshUpdateFloor,
    deleteFloor as _deleteFloor,
    refreshDeleteFloor as _refreshDeleteFloor,
    initDataPositionConfigPage as _initDataPositionConfigPage,
    initDataDepartmentConfigPage as _initDataDepartmentConfigPage,
    addDepartment as _addDepartment,
    refreshAddDepartment as _refreshAddDepartment,
    updateDepartment as _updateDepartment,
    refreshUpdateDepartment as _refreshUpdateDepartment,
    deleteDepartment as _deleteDepartment,
    refreshDeleteDepartment as _refreshDeleteDepartment,
    addPosition as _addPosition,
    refreshAddPosition as _refreshAddPosition,
    updatePosition as _updatePosition,
    refreshUpdatePosition as _refreshUpdatePosition,
    deletePosition as _deletePosition,
    refreshDeletePosition as _refreshDeletePosition,
    initDataTagConfigPage as _initDataTagConfigPage,
    addTag as _addTag,
    refreshAddTag as _refreshAddTag,
    updateTag as _updateTag,
    refreshUpdateTag as _refreshUpdateTag,
    deleteTag as _deleteTag,
    refreshDeleteTag as _refreshDeleteTag,
    initDataBlockConfigPage as _initDataBlockConfigPage,
    addBlock as _addBlock,
    refreshAddBlock as _refreshAddBlock,
    updateBlock as _updateBlock,
    refreshUpdateBlock as _refreshUpdateBlock,
    deleteBlock as _deleteBlock,
    refreshDeleteBlock as _refreshDeleteBlock,
    initServiceConfigPage as _initServiceConfigPage,
    addServiceSystem as _addServiceSystem,
    refreshAddServiceSystem as _refreshAddServiceSystem,
    updateServiceSystem as _updateServiceSystem,
    refreshUpdateServiceSystem as _refreshUpdateServiceSystem,
    deleteServiceSystem as _deleteServiceSystem,
    refreshDeleteServiceSystem as _refreshDeleteServiceSystem,
    useService as _useService,
    refreshUseService as _refreshUseService,
    updateBuildingService as _updateBuildingService,
    refreshUpdateBuildingService as _refreshUpdateBuildingService,
    getAdditionalFee as _getAdditionalFee,
    addAdditionalFee as _addAdditionalFee,
    refreshAddAdditionalFee as _refreshAddAdditionalFee,
    updateAdditionalFee as _updateAdditionalFee,
    refreshUpdateAdditionalFee as _refreshUpdateAdditionalFee,
    deleteAdditionalFee as _deleteAdditionalFee,
    refreshDeleteAdditionalFee as _refreshDeleteAdditionalFee,
    getAllowanceByBuildingId as _getAllowanceByBuildingId,
    addAllowance as _addAllowance,
    refreshAddAllowance as _refreshAddAllowance,
    deleteAllowance as _deleteAllowance,
    refreshDeleteAllowance as _refreshDeleteAllowance,
} from './config';
import {
    initStaffPage as _initStaffPage,
    getStaffsByPage as _getStaffsByPage,
    addStaff as _addStaff,
    refreshAddStaff as _refreshAddStaff,
    updateStaff as _updateStaff,
    refreshUpdateStaff as _refreshUpdateStaff,
    deleteStaff as _deleteStaff,
    refreshDeleteStaff as _refreshDeleteStaff,
    importStaff as _importStaff,
} from './staff';
import {
    getRequests as _getRequests,
    addRequest as _addRequest,
    updateRequest as _updateRequest,
    deleteRequest as _deleteRequest,
    filterRequest as _filterRequest,
    filterReceive as _filterReceive,
} from './request';
import {
    updateFile as _updateFile,
    uploadFile as _uploadFile,
    deleteFile as _deleteFile,
} from './common';
import {
    initApp as _initApp
} from './application';
import {
    getPaymentOnlineOrders as _getPaymentOnlineOrders
} from './statistics/paymentOnline';
//Building
export const getBuildings = _getBuildings;
export const getBuildingById = _getBuildingById;
export const addBuilding = _addBuilding;
export const refreshAddBuilding = _refreshAddBuilding;
export const updateBuilding = _updateBuilding;
export const refreshUpdateBuilding = _refreshUpdateBuilding;
export const deleteBuildings = _deleteBuildings;
export const refreshDeleteBuildings = _refreshDeleteBuildings;
export const changeViewTypeBuilding = _changeViewTypeBuilding;
// Config
export const initDataApartmentConfigPage = _initDataApartmentConfigPage;
export const addApartment = _addApartment;
export const refreshAddApartment = _refreshAddApartment;
export const importApartment = _importApartment;
export const refreshImportApartment = _refreshImportApartment;
export const updateApartment = _updateApartment;
export const refreshUpdateApartment = _refreshUpdateApartment;
export const deleteApartment = _deleteApartment;
export const refreshDeleteApartment = _refreshDeleteApartment;
export const addFloor = _addFloor;
export const refreshAddFloor = _refreshAddFloor;
export const updateFloor = _updateFloor;
export const refreshUpdateFloor = _refreshUpdateFloor;
export const deleteFloor = _deleteFloor;
export const refreshDeleteFloor = _refreshDeleteFloor;
export const initDataPositionConfigPage = _initDataPositionConfigPage;
export const initDataDepartmentConfigPage = _initDataDepartmentConfigPage;
export const addDepartment = _addDepartment;
export const refreshAddDepartment = _refreshAddDepartment;
export const updateDepartment = _updateDepartment;
export const refreshUpdateDepartment = _refreshUpdateDepartment;
export const deleteDepartment = _deleteDepartment;
export const refreshDeleteDepartment = _refreshDeleteDepartment;
export const addPosition = _addPosition;
export const refreshAddPosition = _refreshAddPosition;
export const updatePosition = _updatePosition;
export const refreshUpdatePosition = _refreshUpdatePosition;
export const deletePosition = _deletePosition;
export const refreshDeletePosition = _refreshDeletePosition;
export const initDataTagConfigPage = _initDataTagConfigPage;
export const addTag = _addTag;
export const refreshAddTag = _refreshAddTag;
export const updateTag = _updateTag;
export const refreshUpdateTag = _refreshUpdateTag;
export const deleteTag = _deleteTag;
export const refreshDeleteTag = _refreshDeleteTag;
export const initDataBlockConfigPage = _initDataBlockConfigPage;
export const addBlock = _addBlock;
export const refreshAddBlock = _refreshAddBlock;
export const updateBlock = _updateBlock;
export const refreshUpdateBlock = _refreshUpdateBlock;
export const deleteBlock = _deleteBlock;
export const refreshDeleteBlock = _refreshDeleteBlock;
export const initServiceConfigPage = _initServiceConfigPage;
export const addServiceSystem = _addServiceSystem;
export const refreshAddServiceSystem = _refreshAddServiceSystem;
export const updateServiceSystem = _updateServiceSystem;
export const refreshUpdateServiceSystem = _refreshUpdateServiceSystem;
export const deleteServiceSystem = _deleteServiceSystem;
export const refreshDeleteServiceSystem = _refreshDeleteServiceSystem;
export const useService = _useService;
export const refreshUseService = _refreshUseService;
export const updateBuildingService = _updateBuildingService;
export const refreshUpdateBuildingService = _refreshUpdateBuildingService;
export const getAdditionalFee = _getAdditionalFee;
export const addAdditionalFee = _addAdditionalFee;
export const refreshAddAdditionalFee = _refreshAddAdditionalFee;
export const updateAdditionalFee = _updateAdditionalFee;
export const refreshUpdateAdditionalFee = _refreshUpdateAdditionalFee;
export const deleteAdditionalFee = _deleteAdditionalFee;
export const refreshDeleteAdditionalFee = _refreshDeleteAdditionalFee;
export const getAllowanceByBuildingId = _getAllowanceByBuildingId;
export const addAllowance = _addAllowance;
export const refreshAddAllowance = _refreshAddAllowance;
export const deleteAllowance = _deleteAllowance;
export const refreshDeleteAllowance = _refreshDeleteAllowance;
// Apartment
export const getDataApartmentPage = _getDataApartmentPage;
//apartment detail
export const initApartmentDetail = _initApartmentDetail;
export const getApartmentById = _getApartmentById;
export const getApartmentByIdRefresh = _getApartmentByIdRefresh;
export const getResidentsByApartment = _getResidentsByApartment;
export const addResidentToApartment = _addResidentToApartment;
export const updateResidentToApartment = _updateResidentToApartment;
export const deleteResidentToApartment = _deleteResidentToApartment;
export const refreshAddResidentToApartment = _refreshAddResidentToApartment;
export const refreshUpdateResidentToApartment = _refreshUpdateResidentToApartment;
export const refreshDeleteResidentToApartment = _refreshDeleteResidentToApartment;
export const getDocumentsByApartment = _getDocumentsByApartment;
export const addDocumentsToApartment = _addDocumentsToApartment;
export const refreshAddDocumentToApartment = _refreshAddDocumentToApartment;
export const deleteDocumentToApartment = _deleteDocumentToApartment;
export const refreshDeleteDocumentToApartment = _refreshDeleteDocumentToApartment;

// Staff
export const initStaffPage = _initStaffPage;
export const getStaffsByPage = _getStaffsByPage;
export const addStaff = _addStaff;
export const refreshAddStaff = _refreshAddStaff;
export const updateStaff = _updateStaff;
export const refreshUpdateStaff = _refreshUpdateStaff;
export const deleteStaff = _deleteStaff;
export const refreshDeleteStaff = _refreshDeleteStaff;
export const importStaff = _importStaff;
// Request
export const getRequests = _getRequests;
export const addRequest = _addRequest;
export const updateRequest = _updateRequest;
export const deleteRequest = _deleteRequest;
export const filterRequest = _filterRequest;
export const filterReceive = _filterReceive;
// Common
export const uploadFile = _uploadFile;
export const updateFile = _updateFile;
export const deleteFile = _deleteFile;
// Application
export const initApp = _initApp;
export const getPaymentOnlineOrders = _getPaymentOnlineOrders;

export * from './page-asset/partner';
export * from './asset';
export * from './page-asset/supply';
export * from './resident';
export * from './account';
export * from './document';
export * from './formResident';
export * from './formContractTemplate';
export * from './formula';
export * from './surcharge';
export * from './contractTemplate';
export * from './service';
export * from './bill';
export * from './payment';
export * from './formServiceRegister';
export * from './enterprise';
export * from './formTargetInfo';
