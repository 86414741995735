import { initStatePagination } from './paginate';
import paginate from './paginate';
import { FORM_TARGET_INFO } from '../constants/actionType';
import { combineReducers } from 'redux';
import { map } from 'lodash';
import { initStateFetching } from './fetching';
import fetching from './fetching';

export const initState = {
    targets: {
        ...initStatePagination,
    },
    targetSelected: [],
    dataTargetWhenCheckAll: [],
    targetWhenCheckAll: {
        ...initStateFetching,
    }
};

const targets = paginate(FORM_TARGET_INFO.GETS);
const targetWhenCheckAll = fetching(FORM_TARGET_INFO.GETS_TARGET_WHEN_CHECK_ALL);

function targetSelected(state = [], action) {
    switch (action.type) {
    case FORM_TARGET_INFO.INIT_ADD.SUCCESS:
        return [];
    case FORM_TARGET_INFO.INIT_UPDATE.SUCCESS:
        return flatTarget(action.payload.targetSelected);
    case FORM_TARGET_INFO.RESET_DATA_SELECTED.SUCCESS:
        return [];
    default:
        return state;
    }
}

function dataTargetWhenCheckAll(state = [], action) {
    switch (action.type) {
    case FORM_TARGET_INFO.GETS_TARGET_WHEN_CHECK_ALL.SUCCESS:
        return flatTargetSelected(action.payload.targets);
    default:
        return state;
    }
}

function flatTarget(target) {
    let tmp = [];
    target.map(unit => {
        tmp = [
            ...tmp,
            {
                ...unit,
                targetId: unit.id,
                targetAbout: 'UNIT',
            },
            ...map(unit.residents, resident => ({
                ...resident,
                targetId: resident.id,
                targetAbout: 'RESIDENT',
            }))];
    });
    return tmp;
}

function flatTargetSelected(targetsSelected) {
    let tmp = [];
    targetsSelected.map(unit => {
        tmp = [
            ...tmp,
            {
                ...unit,
                targetId: unit.id,
                targetAbout: 'UNIT',
                selected: true,
            },
            ...map(unit.residents, resident => ({
                ...resident,
                targetId: resident.id,
                targetAbout: 'RESIDENT',
                selected: true,
            }))];
    });
    return tmp;
}

export default combineReducers({
    targets,
    targetSelected,
    targetWhenCheckAll,
    dataTargetWhenCheckAll,
});
