import { X_UNIT_GROUP_ID } from '../../constants';
import { STATISTIC } from '../../constants/actionType';
import { call, put, all } from 'redux-saga/effects';
import { getAccountService } from '../../services/fund';
import { getContractTemplateService, getServiceInUseService } from '../../services';

export function* initDataReport(action) {
    const {
        unitGroupId,
    } = action.payload;

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const [
        funds,
        services,
        contractTemplates
    ] = yield all([
        call(getAccountService, unitGroupId, STATISTIC.FUND.GETS),
        call(getServiceInUseService, { headers }, STATISTIC.SERVICE.GETS),
        call(getContractTemplateService, { headers }, STATISTIC.CONTRACT_TEMPLATE.GETS),
    ]);
    yield all([
        put(funds),
        put(services),
        put(contractTemplates),
    ]);

    yield put({
        type: STATISTIC.INIT.SUCCESS,
        payload: {
            code: 200,
        },
    });
}
