import { initStatePagination } from './paginate';
import paginate from './paginate';
import { combineReducers } from 'redux';
import { initStateFetching } from './fetching';
import fetching from './fetching';
import { FORM_PAYSLIP } from '../constants';

export const initState = {
    formInit: {
        ...initStateFetching,
    },
    apartmentGetInForm: {
        ...initStatePagination,
    },
    receiverSuggestions: {
        ...initStatePagination,
    },
    funds: {
        ...initStatePagination,
    },
    tags: {
        ...initStatePagination,
    },
};

export default combineReducers({
    formInit: fetching(FORM_PAYSLIP.INIT),
    apartmentGetInForm: paginate(FORM_PAYSLIP.SEARCH_APARTMENT),
    receiverSuggestions: paginate(FORM_PAYSLIP.RECEIVER_SUGGESTIONS),
    funds: paginate(FORM_PAYSLIP.FUND.GETS),
    tags: paginate(FORM_PAYSLIP.TAG.GETS),
});
