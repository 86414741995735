import { combineReducers } from 'redux';
import paginate, { initStatePagination } from 'shared/utils/paginate';
import { OPERATING_REVENUE_REPORT_GETS, OPERATING_REVENUE_REPORT_EXPORT } from './operatingRevenueReport.types';

export const initState = {
  data: {
    ...initStatePagination,
  },
  export: {
    ...initStatePagination,
  },
};

export default combineReducers({
  data: paginate(OPERATING_REVENUE_REPORT_GETS),
  export: paginate(OPERATING_REVENUE_REPORT_EXPORT),
});
