import { language } from 'defined/Language';
import { BILL_TYPE_ADJUST_UP } from 'shared/constants';

export default function buildDetailBills({
  createdFor,
  serviceName,
  contractTemplateName,
  totalNumberUse,
  parentId,
  registrationPlate,
  type,
  contentBill,
}) {
  const createdForYear = parseInt(createdFor / 100, 10);
  let createdForMonth = parseInt(createdFor % 100, 10);
  createdForMonth = createdForMonth < 10 ? `0${createdForMonth}` : createdForMonth;

  let content = '';
  let note = '';
  content = `${serviceName}${
    registrationPlate ? ` (${registrationPlate})` : ''
  } ${language.label.month.toLowerCase()} ${createdForMonth}/${createdForYear} 
   ${parentId ? `(${language.label.debt.toLowerCase()})` : ''}`;

  if (type === 'INTEREST' || type === BILL_TYPE_ADJUST_UP) {
    content = `${contentBill} ${parentId ? `(${language.label.debt.toLowerCase()})` : ''}`;
  }

  note = `${language.label.fees}: ${contractTemplateName} - ${totalNumberUse}`;

  return { note, content };
}
