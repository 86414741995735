import { X_UNIT_GROUP_ID } from '../../constants';
import {
    getListTemplateBuildingService,
    getTemplateBuildingService,
    saveTemplateBuildingService,
} from '../../services';
import { call, put } from 'redux-saga/effects';
import { CONFIG } from '../../constants/actionType';

export function* getListTemplateBuilding(action) {
    const {
        unitGroupId,
    } = action.payload;

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(getListTemplateBuildingService, headers, CONFIG.TEMPLATE.GETS);
    yield put(result);
}

export function* saveTemplateBuilding(action) {
    const {
        templates,
        unitGroupId,
    } = action.payload;

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    let result = yield call(saveTemplateBuildingService, { headers, body: templates }, CONFIG.TEMPLATE.SAVE);
    result.payload.template = templates[0];
    yield put(result);
    yield put({
        type: CONFIG.TEMPLATE.SAVE.REFRESH
    });
}

export function* getTemplateBuilding(action) {
    const {
        name,
        unitGroupId,
    } = action.payload;
    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(getTemplateBuildingService, { name, headers }, CONFIG.TEMPLATE.GET_BY_NAME);
    yield put(result);
}