import { FORM_DOCUMENT } from '../constants/actionType';
import { getApartments } from '../services';
import { call, put } from 'redux-saga/effects';

export function* searchApartmentInFormDocument(action) {
    const {
        unitGroupId,
        name,
        id,
    } = action.payload;

    const result = yield call(getApartments, unitGroupId, 1, 20, {
        name,
        id,
    }, FORM_DOCUMENT.SEARCH_APARTMENT);
    yield put(result);
}
