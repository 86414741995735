import { STATISTIC } from '../../constants/actionType';

export const getPaySlip = (buildingId, data) => ({
  type: STATISTIC.PAY_SLIP.GETS.REQUEST,
  payload: {
    buildingId,
    data
  }
});

export const addPaySlip = (buildingId, data) => ({
  type: STATISTIC.PAY_SLIP.ADD.REQUEST,
  payload: {
    data,
    buildingId
  }
});

export const updatePaySlip = (buildingId, data) => ({
  type: STATISTIC.PAY_SLIP.UPDATE.REQUEST,
  payload: {
    data,
    buildingId
  }
});


export const cancelPaySlip = (buildingId, data) => ({
  type: STATISTIC.PAY_SLIP.CANCEL.REQUEST,
  payload: {
    data,
    buildingId
  }
});

export const exportPaySlip = (data, unitGroupId) => ({
  type: STATISTIC.PAY_SLIP.EXPORT.REQUEST,
  payload: {
    data,
    unitGroupId
  }
});

export const getPaySlipPreview = (buildingId, paySlipId) => ({
  type: STATISTIC.PAY_SLIP.GET_PREVIEW.REQUEST,
  payload: {
    buildingId,
    paySlipId
  }
});

export const refreshPaySlipPreview = () => ({
  type: STATISTIC.PAY_SLIP.GET_PREVIEW.SUCCESS,
  payload: {
    isFetching: false,
    data: null,
    code: undefined,
    codeLanguage: undefined,
  }
});

export const getTag = (buildingId) => ({
  type: STATISTIC.TAG.GETS.REQUEST,
  payload: {
    buildingId
  }
});

export const getSumTotalPayment = (buildingId, data) => ({
  type: STATISTIC.PAY_SLIP.SUM_TOTAL_PAYMENT.REQUEST,
  payload: {
    buildingId,
    data
  }
});