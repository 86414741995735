import { createSelector } from 'reselect';

export const authorizationSelector = (state) => state.authorization;

export const departmentsSelector = createSelector(authorizationSelector, (authorization) => authorization?.departments ?? {});
export const groupPermissionSelector = createSelector(authorizationSelector, (authorization) => authorization?.groupPermission ?? {});
export const departmentAddSelector = createSelector(authorizationSelector, (authorization) => authorization?.departmentAdd ?? {});
export const departmentUpdateSelector = createSelector(authorizationSelector, (authorization) => authorization?.departmentUpdate ?? {});
export const departmentDeleteSelector = createSelector(authorizationSelector, (authorization) => authorization?.departmentDelete ?? {});

export const positionAddSelector = createSelector(authorizationSelector, (authorization) => authorization?.positionAdd ?? {});
export const positionUpdateSelector = createSelector(authorizationSelector, (authorization) => authorization?.positionUpdate ?? {});
export const positionDeleteSelector = createSelector(authorizationSelector, (authorization) => authorization?.positionDelete ?? {});
