import { BUILDING } from './building.types';

export const getBuildings = () => ({
    type: BUILDING.GETS.REQUEST,
});

export const getBuildingById = id => ({
    type: BUILDING.GET_BY_ID.REQUEST,
    payload: {
        id,
    },
});

export const addBuilding = building => ({
    type: BUILDING.ADD.REQUEST,
    payload: {
        building: building,
    },
});

export const refreshAddBuilding = () => ({
    type: BUILDING.ADD.REFRESH,
});

export const updateBuilding = building => ({
    type: BUILDING.UPDATE.REQUEST,
    payload: {
        building: building,
    },
});

export const refreshUpdateBuilding = () => ({
    type: BUILDING.UPDATE.REFRESH,
});

export const deleteBuildings = buildings => ({
    type: BUILDING.DELETE.REQUEST,
    payload: {
        buildings,
    },
});

export const refreshDeleteBuildings = () => ({
    type: BUILDING.DELETE.REFRESH,
});