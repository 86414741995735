import {
  addLabelService,
  deleteLabelService,
  getLabelsService,
  updateLabelService,
  getLabelTreeService,
} from 'services'
import { takeLatest, all, put, call } from 'redux-saga/effects';
import { X_UNIT_GROUP_ID } from 'constants/http'
import { LABEL_GETS, LABEL_ADD, LABEL_UPDATE, LABEL_DELETE, LABEL_TREE_GETS } from './label.types';

function* getLabel(action) {
  const {
    unitGroupId,
  } = action.payload;
  const params = {};
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };
  const result = yield call(getLabelsService, { params, headers }, LABEL_GETS);
  yield put(result);
}

function* addLabel(action) {
  const {
    label,
    unitGroupId,
  } = action.payload;
  const result = yield call(addLabelService, {
    label,
    unitGroupId,
  }, LABEL_ADD);
  yield put(result);
}

function* updateLabel(action) {
  const {
    label,
    unitGroupId,
  } = action.payload;
  const result = yield call(updateLabelService, {
    label,
    unitGroupId,
  }, LABEL_UPDATE);
  yield put(result);
}

function* deleteLabel(action) {
  const {
    labelId,
    unitGroupId,
  } = action.payload;

  let result = yield call(deleteLabelService, {
    labelId,
    unitGroupId,
  }, LABEL_DELETE);
  result.payload.labelId = labelId;
  yield put(result);
}

 function* getLabelTree(action) {
   const { buildingId, filterObject } = action.payload;
   const params = {
     ...filterObject,
   };
   const headers = {
     [X_UNIT_GROUP_ID]: buildingId,
   };
   const result = yield call(getLabelTreeService, { params, headers }, LABEL_TREE_GETS);
   yield put(result);
 }

export function* watch() {
  yield all([
    takeLatest(LABEL_GETS.REQUEST, getLabel),
    takeLatest(LABEL_ADD.REQUEST, addLabel),
    takeLatest(LABEL_UPDATE.REQUEST, updateLabel),
    takeLatest(LABEL_DELETE.REQUEST, deleteLabel),
    takeLatest(LABEL_TREE_GETS.REQUEST, getLabelTree),
  ]);
}
