import {LOGIN, PASSWORD, LOGOUT, ACTIVE_ACCOUNT, REGISTER} from '../constants/actionType';

export const login = (email: string, password: string) => ({
    type: LOGIN.REQUEST,
    email,
    password
});

export const register = (user: Object) => ({
    type: REGISTER.REQUEST,
    payload: {
        user,
    }
});

export const forgotPassword = (email: string) => ({
    type: PASSWORD.FORGOT.REQUEST,
    email
});

export const setNewPassword = (accessToken: string, password: string) => ({
    type: PASSWORD.SET_NEW.REQUEST,
    payload: {
        accessToken,
        password,
    }
});

export const updatePassword = (username: string, oldPassword: string, newPassword: string) => ({
    type: PASSWORD.UPDATE.REQUEST,
    username,
    oldPassword,
    newPassword
});

export const logout = (user: Object) => ({
    type: LOGOUT.REQUEST,
    user
});

export const activeAccountByEmailStep1 = (email: string) => ({
    type: ACTIVE_ACCOUNT.EMAIL_STEP_1.REQUEST,
    email,
});

export const refreshActiveAccountByEmailStep1 = () => ({
    type: ACTIVE_ACCOUNT.EMAIL_STEP_1.REFRESH,
});

export const activeAccountByEmailStep2 = (username: string, code: string) => ({
    type: ACTIVE_ACCOUNT.EMAIL_STEP_2.REQUEST,
    username,
    code
});

export const refreshActiveAccountByEmailStep2 = () => ({
    type: ACTIVE_ACCOUNT.EMAIL_STEP_2.REFRESH,
});

