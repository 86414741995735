import { FORM_CONTRACT_TEMPLATE } from '../constants/actionType';
import { call, put } from 'redux-saga/effects';
import { getContractTemplateByFormulaService, getServiceInUseService } from '../services';
import { X_UNIT_GROUP_ID } from '../constants';

export function* initFormContractTemplate(action) {
    const {
        buildingId,
    } = action.payload;
    try {
        let payload = {
            code: 200,
        };
        let error = [];

        const headers = {
            [X_UNIT_GROUP_ID]: buildingId,
        };

        const resServices = yield call(getServiceInUseService, { headers }, FORM_CONTRACT_TEMPLATE.SERVICE_GETS);
        yield put(resServices);

        yield put({
            type: FORM_CONTRACT_TEMPLATE.INIT.SUCCESS,
            payload: {
                ...payload,
                error: error.join(', '),
            },
        });
    } catch (e) {
        yield put({
            type: FORM_CONTRACT_TEMPLATE.INIT.FAIL,
            payload: {
                code: 0,
                error: e,
            },
        });
    }
}