import { COUNT_BADGES, COUNT_BADGES_REQUEST, COUNT_BADGES_UTILITY_BOOKING } from '../defined/API';
import { METHOD } from '../constants';
import { callApi } from './api';
import { handleError, handleResponse } from '../libs/http';

export const countBadgesService = async (type) => {
  const { FAIL } = type;
  try {
    const response = await callApi(COUNT_BADGES, {}, METHOD.GET);
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const countBadgesRequestService = async (type) => {
  const { FAIL } = type;
  try {
    const response = await callApi(COUNT_BADGES_REQUEST, {}, METHOD.GET);
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const countBadgesUtilityBookingService = async (type) => {
  const { FAIL } = type;
  try {
    const response = await callApi(COUNT_BADGES_UTILITY_BOOKING, {}, METHOD.GET);
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};