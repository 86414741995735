import { CASHBOOK_GETS, CASHBOOK_EXPORT } from './cashbook.types';

export const getDataCashbookAction = (unitGroupId, filterObject, page) => ({
  type: CASHBOOK_GETS.REQUEST,
  payload: {
    unitGroupId,
    filterObject,
    page,
  },
});

export const exportDataCashbookAction = (unitGroupId, filterObject) => ({
  type: CASHBOOK_EXPORT.REQUEST,
  payload: {
    unitGroupId,
    filterObject,
  },
});
