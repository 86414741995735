import { createAction } from 'shared/utils';

export const ACCESS_MANAGEMENT_ACTION_TYPE_GETS = createAction('ACCESS_MANAGEMENT_ACTION_TYPE_GETS');
export const ACCESS_MANAGEMENT_ACTION_TYPE_APARTMENT_GETS = createAction('ACCESS_MANAGEMENT_ACTION_TYPE_APARTMENT_GETS');
export const ACCESS_MANAGEMENT_ACTION_TYPE_COUNTRY_CODE_GETS = createAction('ACCESS_MANAGEMENT_ACTION_TYPE_COUNTRY_CODE_GETS');
export const ACCESS_MANAGEMENT_ACTION_TYPE_ADD = createAction('ACCESS_MANAGEMENT_ACTION_TYPE_ADD');
export const ACCESS_MANAGEMENT_ACTION_TYPE_APPROVE = createAction('ACCESS_MANAGEMENT_ACTION_TYPE_APPROVE');
export const ACCESS_MANAGEMENT_ACTION_TYPE_REJECT = createAction('ACCESS_MANAGEMENT_ACTION_TYPE_REJECT');
export const ACCESS_MANAGEMENT_ACTION_TYPE_UPDATE = createAction('ACCESS_MANAGEMENT_ACTION_TYPE_UPDATE');
export const ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_IN = createAction('ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_IN');
export const ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_OUT = createAction('ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_OUT');
export const ACCESS_MANAGEMENT_ACTION_TYPE_REMOVE = createAction('ACCESS_MANAGEMENT_ACTION_TYPE_REMOVE');
export const ACCESS_MANAGEMENT_ACTION_TYPE_EXPORT = createAction('ACCESS_MANAGEMENT_ACTION_TYPE_EXPORT');
