export const initStatePagination = {
  isFetching: false,
  currentPage: 1,
  totalPage: 0,
  totalRecord: 0,
  data: [],
};

const paginate = (types, key = 'result') => {
  const { REQUEST, SUCCESS, FAIL, REFRESH } = types;

  const updatePagination = (state = initStatePagination, action) => {
    switch (action.type) {
      case REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isFetching: true,
        };
      case SUCCESS:
        return {
          ...state,
          isFetching: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          currentPage: action.payload.currentPage,
          totalPage: action.payload.totalPage,
          totalRecord: action.payload.totalRecord,
          data: action.payload[key],
        };
      case FAIL:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isFetching: false,
          message: action.payload.message,
        };
      case REFRESH:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isFetching: false,
        };
      default:
        return state;
    }
  };

  return (state = initStatePagination, action) => {
    switch (action.type) {
      case REQUEST:
      case SUCCESS:
      case FAIL:
      case REFRESH:
        return {
          ...state,
          ...updatePagination(state, action),
        };
      default:
        return state;
    }
  };
};

export default paginate;
