import { language } from 'defined/Language';
import convertArrToObj from '../utils/convertArrToObj';

export const COVID_TEST = 'COVID_TEST';
export const COVID_VACCINATION = 'COVID_VACCINATION';
export const RECEIVE_PACKAGE = 'RECEIVE_PACKAGE';
export const VISITOR = 'VISITOR';

export const RESIDENT_REGISTRATION_FORM_LIST = [
  {
    value: COVID_TEST,
    label: language.covid_test,
  },
  {
    value: COVID_VACCINATION,
    label: language.covid_vaccination,
  },
  {
    value: RECEIVE_PACKAGE,
    language: language.receive_package,
  },
];

export const RESIDENT_REGISTRATION_FORM_STATUS_NEW = 'NEW';
export const RESIDENT_REGISTRATION_FORM_STATUS_CANCELLED = 'CANCELLED';
export const RESIDENT_REGISTRATION_FORM_STATUS_WAITING_FOR_APPROVED = 'WAITING_FOR_APPROVED';
export const RESIDENT_REGISTRATION_FORM_STATUS_APPROVED = 'APPROVED';
export const RESIDENT_REGISTRATION_FORM_STATUS_RECEIVED = 'RECEIVED';
export const RESIDENT_REGISTRATION_FORM_STATUS_COMPLETE = 'COMPLETED';

export const RESIDENT_REGISTRATION_FORM_STATUS_LIST = [
  {
    value: RESIDENT_REGISTRATION_FORM_STATUS_NEW,
    label: language.new,
  },
  {
    value: RESIDENT_REGISTRATION_FORM_STATUS_CANCELLED,
    label: language.canceled,
  },
];

export const RESIDENT_REGISTRATION_FORM_RECEIVE_PACKAGE_STATUS_LIST = [
  {
    value: RESIDENT_REGISTRATION_FORM_STATUS_WAITING_FOR_APPROVED,
    label: language.waiting_for_approved,
  },
  {
    value: RESIDENT_REGISTRATION_FORM_STATUS_APPROVED,
    label: language.approved,
  },
  {
    value: RESIDENT_REGISTRATION_FORM_STATUS_RECEIVED,
    label: language.received,
  },
  {
    value: RESIDENT_REGISTRATION_FORM_STATUS_COMPLETE,
    label: language.complete,
  },
  {
    value: RESIDENT_REGISTRATION_FORM_STATUS_CANCELLED,
    label: language.canceled,
  },
];

export const RESIDENT_REGISTRATION_FORM_RECEIVE_PACKAGE_STATUS_OBJ = convertArrToObj(
  RESIDENT_REGISTRATION_FORM_RECEIVE_PACKAGE_STATUS_LIST,
  'value'
);

export const RESIDENT_REGISTRATION_FORM_DEFAULT_FILTER_COVID_TEST = {
  unitName: undefined,
  fullName: undefined,
  registerAt: [],
  status: RESIDENT_REGISTRATION_FORM_STATUS_NEW,
};

export const RESIDENT_REGISTRATION_FORM_DEFAULT_FILTER_RECEIVE_PACKAGE = {
  code: undefined,
  unitName: undefined,
  receiverName: undefined,
  receiverPhone: undefined,
  receiverAt: [],
  status: RESIDENT_REGISTRATION_FORM_STATUS_WAITING_FOR_APPROVED,
};
