import { PAYMENT_ONLINE } from './payment-online.types';

export const getDatas = (filter, page, limit, buildingId) => ({
    type: PAYMENT_ONLINE.GETS.REQUEST,
    payload: {
        filter,
        page,
        limit,
        buildingId
    }
});

export const getReceiptById = (receiptId, buildingId) => ({
    type: PAYMENT_ONLINE.GET_RECEIPT.REQUEST,
    payload: {
        receiptId,
        buildingId
    }
})

// export const getTotal = (filter, buildingId) => ({
//     type: PAYMENT_ONLINE.TOTAL.REQUEST,
//     payload: {
//         filter,
//         buildingId
//     }
// });

export const exportData = (filter, buildingId) => ({
    type: PAYMENT_ONLINE.EXPORT.REQUEST,
    payload: {
        filter,
        buildingId
    }
});