
import {METHOD} from '../constants';
import {callApi} from './api';
import { checkResponseError, handleError, handleResponse } from '../libs/http';
import { UNIT_GROUP } from '../defined/API';

export async function getBlocks(buildingId: number, type: Object = {SUCCESS: 'SUCCESS', FAIL: 'FAIL'}) {
    const { FAIL } = type;
    try {
        const filterObject = {
            where: {
                type: 'block',
                parentId: buildingId
            }
        };
        let response = await callApi(UNIT_GROUP, {}, METHOD.GET, filterObject);
        return handleResponse(response, type, 'blocks');
    } catch (err) {
        return handleError(err, FAIL);
    }
}

// export const addBlock = (block: Object) => callApi(BLOCK_ADD, block, METHOD.POST);
// export const updateBlock = (block: Object) => callApi(BLOCK_UPDATE, block, METHOD.PUT);
// export const deleteBlock = (blockId: number) => callApi(`${BLOCK_DELETE}/${blockId}`, {}, METHOD.DELETE);
