import { combineReducers } from 'redux';
import { AMENITY_MERCHANT_ORDER_DETAIL } from './amenities-detail.types';

export const initState = {
  get: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
    data: undefined,
  },
};

const reducer = combineReducers({
  get: (state = initState.get, action) => {
    switch (action.type) {
      case AMENITY_MERCHANT_ORDER_DETAIL.GET.REQUEST:
        return {
          isLoading: true,
          code: undefined,
          codeLanguage: undefined,
          data: undefined,
        };
      case AMENITY_MERCHANT_ORDER_DETAIL.GET.SUCCESS:
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: action.payload.result,
        };
      case AMENITY_MERCHANT_ORDER_DETAIL.GET.FAIL:
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: undefined,
        };
      case AMENITY_MERCHANT_ORDER_DETAIL.GET.REFRESH:
        return {
          ...initState.get,
        };
      default:
        return state;
    }
  },
});

export default reducer;
