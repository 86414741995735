import { DEBT } from './service-by-months.types';

export const getDataDebt = ({ unitGroupId, page, filterObject }) => ({
  type: DEBT.GETS.REQUEST,
  payload: {
    unitGroupId,
    page,
    filterObject,
  },
});

export const exportDebt = ({ unitGroupId, filterObject, type }) => ({
  type: DEBT.EXPORT.REQUEST,
  payload: {
    unitGroupId,
    filterObject,
    type,
  },
});

export const getTotalDebt = ({ unitGroupId, filterObject }) => ({
  type: DEBT.TOTAL.REQUEST,
  payload: {
    unitGroupId,
    filterObject,
  },
});
