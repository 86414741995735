import { FUND, FUND_ADD, FUND_UPDATE, FUND_GETS } from '../defined/API';
import { METHOD, X_UNIT_GROUP_ID } from '../constants';
import { callApi } from './api';
import { handleError, handleResponse } from '../libs/http';

export const getAccountService = async (unitGroupId, type = { SUCCESS: 'SUCCESS', FAIL: 'FAIL' }) => {
  const { FAIL } = type;
  try {
    const headers = { [X_UNIT_GROUP_ID]: unitGroupId };

    const response = await callApi(FUND_GETS, null, METHOD.GET, { headers });
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  };
}

export const addAccountService = async ({ body, unitGroupId }, type = { SUCCESS: 'SUCCESS', FAIL: 'FAIL' }) => {
  const { FAIL } = type;
  try {
    const response = await callApi(FUND_ADD, body, METHOD.POST, { headers: { [X_UNIT_GROUP_ID]: unitGroupId } });
    return handleResponse(response, type, 'account');
  } catch (e) {
    handleError(e, FAIL);
  };
}

export const updateAccountService = async ({ body, accountId, unitGroupId }, type = { SUCCESS: 'SUCCESS', FAIL: 'FAIL' }) => {
  const { FAIL } = type;
  try {
    const response = await callApi(`${FUND_UPDATE}/${accountId}`, body, METHOD.PUT, { headers: { [X_UNIT_GROUP_ID]: unitGroupId } });
    return handleResponse(response, type, 'account');
  } catch (e) {
    handleError(e, FAIL);
  };
}

export const deleteAccountService = async ({ data, unitGroupId }, type = { SUCCESS: 'SUCCESS', FAIL: 'FAIL' }) => {
  const { FAIL } = type;
  try {
    const response = await callApi(`${FUND}/${data}`, {}, METHOD.DELETE, { headers: { [X_UNIT_GROUP_ID]: unitGroupId } });
    return handleResponse(response, type, 'account');
  } catch (e) {
    handleError(e, FAIL);
  };
}