import { language } from 'defined/Language';

export const STATUS_PENDING = 'PENDING';
export const STATUS_APPROVED = 'APPROVED';
export const STATUS_REJECTED = 'REJECTED';
export const STATUS_CANCELLED = 'CANCELLED';

export const UTILITY_BOOKING_STATUS = [
  { id: 'PENDING', label: language.label.pending, color: 'blue' },
  { id: 'APPROVED', label: language.label.approved, color: 'green' },
  { id: 'REJECTED', label: language.label.rejected, color: 'red' },
  { id: 'CANCELLED', label: language.label.canceled, color: 'red' },
];
