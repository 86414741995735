import { put, call, all, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { getsAmenitiesOrderService, exportAmenitiesOrderService } from 'services/amenities';
import { X_UNIT_GROUP_ID, PAGINATION_LIMIT } from 'shared/constants';
import { AMENITIES_ORDER } from './amenities.types';
import { AMENITIES_ORDER_STATUS_ALL } from './amenities.constants';

function* gets(action) {
  try {
    const { filter, unitGroupId } = action.payload;
    const headers = {
      [X_UNIT_GROUP_ID]: unitGroupId,
    };
    const params = {
      from: filter?.orderTime?.length > 0 ? moment(filter.orderTime[0]).startOf('day').toISOString() : null,
      to: filter?.orderTime?.length > 0 ? moment(filter.orderTime[1]).endOf('day').toISOString() : null,
      status: filter.status !== AMENITIES_ORDER_STATUS_ALL ? filter.status : null,
      limit: PAGINATION_LIMIT,
      code: filter.code !== '' ? filter.code : null,
      name: filter.name !== '' ? filter.name : null,
      unitName: filter.unitName !== '' ? filter.unitName : null,
      page: filter.page,
    };
    const result = yield call(getsAmenitiesOrderService, { params, headers }, AMENITIES_ORDER.GETS);
    yield put(result);
  } catch (e) {
    yield put({
      type: AMENITIES_ORDER.GETS.FAIL,
      payload: {
        code: 0,
        error: e,
      },
    });
  }
}

function* exportData(action) {
  try {
    const result = yield call(exportAmenitiesOrderService, action.payload, AMENITIES_ORDER.EXPORT);
    yield put({
      ...result,
    });
  } catch (e) {
    yield put({
      type: AMENITIES_ORDER.EXPORT.FAIL,
      payload: {
        code: 0,
        error: e,
      },
    });
  }
}

export default function* watch() {
  yield all([takeLatest(AMENITIES_ORDER.GETS.REQUEST, gets), takeLatest(AMENITIES_ORDER.EXPORT.REQUEST, exportData)]);
}
