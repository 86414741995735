import { Typography } from 'antd';
import styled from '@emotion/styled';
import Title from './Title';
import Paragraph from './Paragraph';
import Text from './Text';

Typography.Title = Title;
Typography.Paragraph = Paragraph;
Typography.Text = Text;

const LabelTransparent = styled.label`
  color: transparent;
`;

export { LabelTransparent };

export default Typography;
