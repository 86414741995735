import { call, put } from 'redux-saga/effects';
import { getContractTemplateTargetService } from '../services';
import { FORM_TARGET_INFO, X_UNIT_GROUP_ID } from '../constants';

export function* initFormTargetInfo(action) {
    const {
        buildingId,
        contractTemplateId,
    } = action.payload;

    // get target of contract template

    const params = {
        contractTemplateId,
        type: 'ALL',
        page: 1,
        limit: 10,
    };
    const headers = {
        [X_UNIT_GROUP_ID]: buildingId,
    };

    const resultAllTargetOptions = yield call(getContractTemplateTargetService, {
        params,
        headers,
    }, FORM_TARGET_INFO.GETS);
    yield put(resultAllTargetOptions);

    yield put({
        type: FORM_TARGET_INFO.INIT_ADD.SUCCESS,
    });
}

export function* getTargetInFormTargetInfo(action) {
    const {
        buildingId,
        page,
        size,
        filterObject,
    } = action.payload;
    const params = {
        page,
        limit: size || 10,
        ...filterObject,
    };
    const headers = {
        [X_UNIT_GROUP_ID]: buildingId,
    };
    const result = yield call(getContractTemplateTargetService, {
        params,
        headers,
    }, FORM_TARGET_INFO.GETS);
    yield put(result);
}
