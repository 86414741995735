import {
  PAYMENT_SLIP_GETS,
  PAYMENT_SLIP_SUM_TOTAL_PAYMENT,
  PAYMENT_SLIP_EXPORT,
  PAYMENT_SLIP_PREVIEW,
  PAYMENT_SLIP_DELETE,
  PAYMENT_SLIP_UNIT_GETS,
  PAYMENT_SLIP_RESIDENT_GETS,
  PAYMENT_SLIP_ADD,
  PAYMENT_SLIP_UPDATE,
  PAYMENT_SLIP_BILL_ADJUST,
} from './paymentSlip.types';

export const getDataTableAction = (unitGroupId = 0, filterObject = {}, page = 1) => ({
  type: PAYMENT_SLIP_GETS.REQUEST,
  payload: {
    unitGroupId,
    filterObject,
    page,
  },
});

export const getSumTotalPaymentAction = (unitGroupId = 0, filterObject = {}) => ({
  type: PAYMENT_SLIP_SUM_TOTAL_PAYMENT.REQUEST,
  payload: {
    unitGroupId,
    filterObject,
  },
});

export const exportPaymnetSlipAction = (unitGroupId = 0, filterObject = {}) => ({
  type: PAYMENT_SLIP_EXPORT.REQUEST,
  payload: {
    unitGroupId,
    filterObject,
  },
});

export const previewPaymentSlipAction = (unitGroupId = 0, paySlipId = 0) => ({
  type: PAYMENT_SLIP_PREVIEW.REQUEST,
  payload: {
    unitGroupId,
    paySlipId,
  },
});

export const getUnitPaymentSlipAction = (unitGroupId = 0, unitName = '') => ({
  type: PAYMENT_SLIP_UNIT_GETS.REQUEST,
  payload: {
    unitGroupId,
    name: unitName,
    page: 1,
    limit: 20,
  },
});

export const getResidentPaymentSlipAction = (unitGroupId = 0, residentName = '', unitName = '') => ({
  type: PAYMENT_SLIP_RESIDENT_GETS.REQUEST,
  payload: {
    unitGroupId,
    fullName: residentName,
    unitName,
    page: 1,
    limit: 20,
  },
});

export const addPaymentSlipAction = (unitGroupId = 0, params = {}) => ({
  type: PAYMENT_SLIP_ADD.REQUEST,
  payload: {
    unitGroupId,
    params,
  },
});

export const updatePaymentSlipAction = (unitGroupId = 0, params = {}) => ({
  type: PAYMENT_SLIP_UPDATE.REQUEST,
  payload: {
    unitGroupId,
    params,
  },
});

export const deletePaymentSlipAction = (unitGroupId = 0, paySlipId = 0, reason = '') => ({
  type: PAYMENT_SLIP_DELETE.REQUEST,
  payload: {
    unitGroupId,
    paySlipId,
    reason,
  },
});

export const getBillAjustPaymentSlipAction = (unitGroupId = 0, filterObject = {}) => ({
  type: PAYMENT_SLIP_BILL_ADJUST.REQUEST,
  payload: {
    unitGroupId,
    filterObject,
  },
});
