import {
  ACTION_TYPE_EVENT_ADD,
  ACTION_TYPE_EVENT_DELETE,
  ACTION_TYPE_EVENT_FLOOR_GETS,
  ACTION_TYPE_EVENT_GETS,
  ACTION_TYPE_EVENT_TAG_GETS,
  ACTION_TYPE_EVENT_APARTMENT_GETS,
  ACTION_TYPE_EVENT_APARTMENT_RECEIVE_EVENT_GETS,
} from './event.types';

export const getEvents = (buildingId, page, limit, data) => ({
  type: ACTION_TYPE_EVENT_GETS.REQUEST,
  payload: {
    buildingId,
    page,
    limit,
    data,
  },
});

export const addEvent = (buildingId, event) => ({
  type: ACTION_TYPE_EVENT_ADD.REQUEST,
  payload: {
    buildingId,
    event,
  },
});

export const deleteEvent = (buildingId, eventId, refreshState) => ({
  type: ACTION_TYPE_EVENT_DELETE.REQUEST,
  payload: {
    buildingId,
    eventId,
    refreshState,
  },
});

export const refreshAddEvent = () => ({
  type: ACTION_TYPE_EVENT_ADD.REFRESH,
});

export const getFloors = (buildingId) => ({
  type: ACTION_TYPE_EVENT_FLOOR_GETS.REQUEST,
  payload: {
    buildingId,
  },
});

export const getTags = (buildingId, data) => ({
  type: ACTION_TYPE_EVENT_TAG_GETS.REQUEST,
  payload: {
    buildingId,
    data,
  },
});

export const getUnits = (buildingId, filter, page, limit) => ({
  type: ACTION_TYPE_EVENT_APARTMENT_GETS.REQUEST,
  payload: {
    buildingId,
    filter,
    page,
    limit,
  },
});

export const getUnitsReceiveEvent = (buildingId, filter, page, limit, idEvent) => ({
  type: ACTION_TYPE_EVENT_APARTMENT_RECEIVE_EVENT_GETS.REQUEST,
  payload: {
    buildingId,
    filter,
    page,
    limit,
    idEvent,
  },
});
