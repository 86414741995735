import { all, call, put, takeLatest } from 'redux-saga/effects';
import { X_UNIT_GROUP_ID } from 'constants/http';
import {
  deleteBillService,
  sumTotalPaymentService,
  createBillAdjustService,
  updateBillAdjustService,
  getListBillsNotGroupUnitService,
  getWalletApartmentService,
} from 'services';
import { PAGINATION_LIMIT } from 'shared/constants';
import {
  INVOICE_BILL_APARTMENT_ACCOUNT,
  INVOICE_BILL_GET_SUM,
  INVOICE_BILL_GETS,
  INVOICE_BILL_CANCEL_INVOICE,
  INVOICE_BILL_CREATE_ADJUST,
  INVOICE_BILL_UPDATE_ADJUST,
  INVOICE_BILL_FORM_CANCEL_GETS,
} from './invoiceBill.types';

function* getDataTable(action) {
  const { unitGroupId, filterObject, page } = action.payload;

  const params = {
    page,
    limit: PAGINATION_LIMIT,
    ...filterObject,
  };

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(getListBillsNotGroupUnitService, { params, headers }, INVOICE_BILL_GETS);
  yield put(result);
}

export function* getSumInvoiceBill(action) {
  const { unitGroupId, filterObject } = action.payload;

  const params = {
    ...filterObject,
  };

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(
    sumTotalPaymentService,
    {
      params,
      headers,
    },
    INVOICE_BILL_GET_SUM
  );
  yield put(result);
}

function* deleteBills(action) {
  const { isAll, ids, unitGroupId, filterObject } = action.payload;
  const body = {
    isAll,
    array: ids,
    filterObject,
  };
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };
  const result = yield call(
    deleteBillService,
    {
      body,
      headers,
    },
    INVOICE_BILL_CANCEL_INVOICE
  );
  yield put(result);
  yield put({
    type: INVOICE_BILL_CANCEL_INVOICE.REFRESH,
  });
}

function* createBillAdjust(action) {
  const { params, unitGroupId } = action.payload;
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };
  const result = yield call(
    createBillAdjustService,
    {
      body: params,
      headers,
    },
    INVOICE_BILL_CREATE_ADJUST
  );
  yield put(result);
  yield put({
    type: INVOICE_BILL_CREATE_ADJUST.REFRESH,
  });
}

function* updateBillAdjust(action) {
  const { id, params, unitGroupId } = action.payload;
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };
  const result = yield call(
    updateBillAdjustService,
    {
      body: { billId: id, ...params },
      headers,
    },
    INVOICE_BILL_UPDATE_ADJUST
  );
  yield put(result);
  yield put({
    type: INVOICE_BILL_UPDATE_ADJUST.REFRESH,
  });
}

function* getDataTableFormCancel(action) {
  const { unitGroupId, filterObject, page } = action.payload;

  const params = {
    page,
    limit: 10,
    ...filterObject,
  };

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(getListBillsNotGroupUnitService, { params, headers }, INVOICE_BILL_FORM_CANCEL_GETS);
  yield put(result);
}

function* getUnitAccount(action) {
  const { unitGroupId, unitId } = action.payload;
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(getWalletApartmentService, { unitId, headers }, INVOICE_BILL_APARTMENT_ACCOUNT);
  yield put(result);
}

export default function* watch() {
  yield all([
    takeLatest(INVOICE_BILL_GETS.REQUEST, getDataTable),
    takeLatest(INVOICE_BILL_GET_SUM.REQUEST, getSumInvoiceBill),
    takeLatest(INVOICE_BILL_CANCEL_INVOICE.REQUEST, deleteBills),
    takeLatest(INVOICE_BILL_CREATE_ADJUST.REQUEST, createBillAdjust),
    takeLatest(INVOICE_BILL_UPDATE_ADJUST.REQUEST, updateBillAdjust),
    takeLatest(INVOICE_BILL_FORM_CANCEL_GETS.REQUEST, getDataTableFormCancel),
    takeLatest(INVOICE_BILL_APARTMENT_ACCOUNT.REQUEST, getUnitAccount),
  ]);
}
