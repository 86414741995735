import { MESSAGE_NOTICE } from '../constants/actionType';
import paginate from './paginate';
import { combineReducers } from 'redux';

const countBadges = paginate(MESSAGE_NOTICE.COUNT_BADGES);
const countBadgesRequest = paginate(MESSAGE_NOTICE.COUNT_BADGES_REQUEST);
const countBadgesUtilityBooking = paginate(MESSAGE_NOTICE.COUNT_BADGES_UTILITY_BOOKING);

export default combineReducers({
  countBadges,
  countBadgesRequest,
  countBadgesUtilityBooking,
});