import { createAction } from 'shared/utils';

export const PAYMENT_SLIP_GETS = createAction('PAYMENT_SLIP_GETS');
export const PAYMENT_SLIP_SUM_TOTAL_PAYMENT = createAction('PAYMENT_SLIP_SUM_TOTAL_PAYMENT');
export const PAYMENT_SLIP_EXPORT = createAction('PAYMENT_SLIP_EXPORT');
export const PAYMENT_SLIP_PREVIEW = createAction('PAYMENT_SLIP_PREVIEW');
export const PAYMENT_SLIP_DELETE = createAction('PAYMENT_SLIP_DELETE');
export const PAYMENT_SLIP_UNIT_GETS = createAction('PAYMENT_SLIP_UNIT_GETS');
export const PAYMENT_SLIP_RESIDENT_GETS = createAction('PAYMENT_SLIP_RESIDENT_GETS');
export const PAYMENT_SLIP_ADD = createAction('PAYMENT_SLIP_ADD');
export const PAYMENT_SLIP_UPDATE = createAction('PAYMENT_SLIP_UPDATE');
export const PAYMENT_SLIP_BILL_ADJUST = createAction('PAYMENT_SLIP_BILL_ADJUST');
