import { useSelector } from 'react-redux';
import { find } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const PERIOD_NOTICE_REMIND_DEBT = 2;

const useCurrentBuilding = () => {
  const buildings = useSelector((state) => state.data.buildings);
  const { idBuilding } = useParams();

  const findBuilding = (buildingId, buildingList) => {
    return find(buildingList, { id: Number(buildingId) }) || {};
  };

  const building = useMemo(() => findBuilding(idBuilding, buildings), [idBuilding, buildings]);

  const isOnSale = useMemo(() => building.onsale && building.landsoftId, [building]);

  const isRemindDebt = useMemo(() => {
    const { dateOfDebtInvoiceSending } = building;
    if (dateOfDebtInvoiceSending) {
      const dateSendInvoiceRemindDebt = dateOfDebtInvoiceSending;
      const diff = dateSendInvoiceRemindDebt - moment().date();
      return diff <= PERIOD_NOTICE_REMIND_DEBT;
    }
    return false;
  }, [building]);

  return {
    building,
    isOnSale,
    isRemindDebt,
  };
};

export default useCurrentBuilding;
