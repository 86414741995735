import { call, put, select } from 'redux-saga/effects';
import { FORM_RECEIPT } from '../constants/actionType';
import { getApartments } from '../services';
import { X_UNIT_GROUP_ID } from '../constants';
import { getAccountService } from '../services/fund';
import { getLabelsService } from '../services/label';
import { getResidentsService } from '../services/resident';
import { pick } from 'lodash';

export function* initFormReceipt(action) {
    const {
        unitGroupId,
        funds,
        tags,
    } = action.payload;

    const resApartment = yield call(getApartments, unitGroupId, 1, 20, {}, FORM_RECEIPT.SEARCH_APARTMENT);
    yield put(resApartment);

    if (funds) {
        yield put({
            type: FORM_RECEIPT.FUND.GETS.SUCCESS,
            payload: {
                code: 200,
                result: funds,
            }
        });
    } else {
        const codeFund = yield select(state => state.formReceipt.funds.code);
        if (!codeFund) {
            const resFund = yield call(getAccountService, unitGroupId, FORM_RECEIPT.FUND.GETS);
            yield put(resFund);
        }
    }

    if (tags) {
        yield put({
            type: FORM_RECEIPT.TAG.GETS.SUCCESS,
            payload: {
                code: 200,
                result: tags,
            }
        });
    } else {
        const codeTag = yield select(state => state.formReceipt.tags.code);
        if (!codeTag) {
            const resTag = yield call(getLabelsService, {
                headers: {
                    [X_UNIT_GROUP_ID]: unitGroupId,
                },
            }, FORM_RECEIPT.TAG.GETS);
            yield put(resTag);
        }
    }

    yield put({
        type: FORM_RECEIPT.INIT.SUCCESS,
        payload: {
            code: 200,
        },
    });
}

export function* searchApartmentInFormReceipt(action) {
    const {
        unitGroupId,
        name,
    } = action.payload;

    const result = yield call(getApartments, unitGroupId, 1, 20, {
        name
    }, FORM_RECEIPT.SEARCH_APARTMENT);
    yield put(result);
}

export function* getPayerSuggestions(action) {
    const { data, unitGroupId } = action.payload;

    const params = pick(data, ['fullName', 'page', 'limit']);
    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(getResidentsService, { params, headers }, FORM_RECEIPT.PAYER_SUGGESTIONS);

    yield put(result);
}
