import { combineReducers } from 'redux';
import { FORM_CONFIRM_SERVICE } from '../constants/actionType';
import paginate from './paginate';

export const initState = {
    contractTemplates: [],
};

const contractTemplates = paginate(FORM_CONFIRM_SERVICE.INIT);

export default combineReducers({
    contractTemplates
});
