import {APARTMENT, FLOOR} from '../constants/actionType';

export const initApartmentPage = (buildingId) => ({
    type: APARTMENT.INIT.REQUEST,
    payload: {
        buildingId,
    }
});

export const getDataApartmentInUsePage = (buildingId, page, limit, filter) => ({
    type: APARTMENT.GETS.REQUEST,
    buildingId,
    page,
    limit,
    filter: {
        ...filter,
        status: 1,
    }
});

export const getDataApartmentPage = (buildingId, page, limit, filter) => ({
    type: APARTMENT.GETS.REQUEST,
    buildingId,
    page,
    limit,
    filter
});

export const getFloors = (buildingId) => ({
    type: FLOOR.GETS.REQUEST,
    buildingId
});

export const exportApartmentInfo = (buildingId, filter) => ({
    type: APARTMENT.EXPORT.REQUEST,
    buildingId,
    filter
});

export const resetDataApartment = () => ({
    type: APARTMENT.REFRESH.REQUEST,
});
