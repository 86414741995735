//@flow
import { ACTIVITY } from '../constants/actionType';
import paginate, { initStatePagination } from './paginate';
import fetching, { initStateFetching } from './fetching';
import { combineReducers } from 'redux';
import { ERROR_CODE } from 'constants';

export const initState = {
    activityLogGets: {
        ...initStatePagination
    },
    managerGets: {
        ...initStatePagination
    },
    exportActivityLogGets: {
      ...initStatePagination
    }
};

const activityLogGets = paginate(ACTIVITY.LOG.GETS)

const handleActivityLog = reducer => (state, action) => {
    switch (action.type) {
      case ACTIVITY.LOG.GETS.SUCCESS:
        let newData = action.payload.result.filter((activity) => {
          return !ERROR_CODE.includes(activity?.response?.statusCode);
        });
        return {
          ...state,
          isFetching: false,
          code: action.payload.code,
          loadMore: action.payload.loadMore,
          data: [...state.data, ...newData]
        }
      case ACTIVITY.LOG.GETS.REFRESH:
        return {
          ...initStatePagination,
          loadMore: true
        }
      case ACTIVITY.LOG.GETS.FINISH: 

      default:
        return reducer(state, action);
    }
  };

const reducer = combineReducers({
    activityLogGets: handleActivityLog(activityLogGets),
    managerGets: paginate(ACTIVITY.MANAGER.GETS),
    exportActivityLogGets: paginate(ACTIVITY.EXPORT.GETS)
});

export default reducer;