
import { initStatePagination } from './paginate';
import { INVOICE } from '../constants/actionType';
import paginate from './paginate';
import { combineReducers } from 'redux';
import { initStateFetching } from './fetching';
import fetching from './fetching';

export const initState = {
    invoiceInit: {
        ...initStateFetching
    },
    contracts: {
        ...initStatePagination,
    },
    sumPaymentContract: {
        ...initStatePagination,
    },
    bills: {
        ...initStatePagination,
    },
    billForCheckingAlls: {
        ...initStatePagination,
    },
    sumPayment: {
        ...initStatePagination,
    },
    billCreateAuto: {
        ...initStateFetching,
    },
    billCreateManual: {
        ...initStateFetching,
    },
    billDeleteMulti: {
        ...initStateFetching,
    },
    services: {
        ...initStatePagination,
    },
    invoiceSendEmail: {
        ...initStatePagination,
    },
    invoiceReSendEmail: {
        ...initStatePagination,
    },
    invoicePrint: {
        ...initStatePagination,
    },
    invoiceRePrint: {
        ...initStatePagination,
    },
    invoicePreview: {
        ...initStatePagination,
    },
    invoices: {
        ...initStatePagination,
    },
    invoiceGetById: {
        ...initStatePagination,
    },
    invoiceUpdateInterest: {
        ...initStateFetching,
    },
    invoiceForCheckingAlls :{
        ...initStatePagination
    },
    contractForCheckingAlls :{
        ...initStatePagination
    },
    invoicePrintDebt: {
        ...initStatePagination,
    },
    invoicePreviewDebt: {
        ...initStatePagination,
    },
    invoiceSendEmailDebt: {
        ...initStatePagination,
    },

};
const invoiceInit = fetching(INVOICE.INIT);
const contracts = paginate(INVOICE.CONTRACT.GETS);
const sumPaymentContract = paginate(INVOICE.CONTRACT.SUM_PAYMENT_GETS);
const bills = paginate(INVOICE.BILL.GETS);
const billForCheckingAlls = paginate(INVOICE.BILL.CHECKING_ALL_GETS);
const sumPayment = paginate(INVOICE.BILL.SUM_PAYMENT_GETS);
const billCreateAuto = fetching(INVOICE.BILL.CREATE_AUTO);
const billCreateManual = fetching(INVOICE.BILL.CREATE_MANUAL);
const billDeleteMulti = fetching(INVOICE.BILL.DELETE_MULTI);
const invoiceSendEmail = paginate(INVOICE.SEND_EMAIL);
const invoiceReSendEmail = paginate(INVOICE.RESEND_EMAIL);
const invoicePrint = paginate(INVOICE.PRINT);
const invoiceRePrint = paginate(INVOICE.REPRINT);
const invoicePreview = paginate(INVOICE.PREVIEW);
const invoices = paginate(INVOICE.GETS);
const invoiceGetById = paginate(INVOICE.GET_BY_ID);
const invoiceUpdateInterest = fetching(INVOICE.BILL.UPDATE_INTEREST);
const invoiceForCheckingAlls = paginate(INVOICE.CHECKING_ALL_GETS);
const contractForCheckingAlls = paginate(INVOICE.CONTRACT.CHECKING_ALL_GETS);
const invoicePrintDebt = paginate(INVOICE.PRINT_DEBT);
const invoicePreviewDebt = paginate(INVOICE.PREVIEW_DEBT);
const invoiceSendEmailDebt = paginate(INVOICE.SEND_EMAIL_DEBT);

const services = paginate(INVOICE.SERVICE.GETS);

export default combineReducers({
    invoiceInit,
    contracts,
    sumPaymentContract,
    bills,
    billForCheckingAlls,
    invoices,
    invoiceGetById,
    sumPayment,
    billCreateAuto,
    billCreateManual,
    billDeleteMulti,
    services,
    invoiceSendEmail,
    invoiceReSendEmail,
    invoicePrint,
    invoiceRePrint,
    invoicePreview,
    invoiceUpdateInterest,
    invoiceForCheckingAlls,
    contractForCheckingAlls,
    invoicePrintDebt,
    invoicePreviewDebt,
    invoiceSendEmailDebt
});
