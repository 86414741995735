import { RESIDENT, STAFF } from '../constants/actionType';
import {combineReducers} from 'redux';
import paginate, {initStatePagination} from './paginate';
import { initStateFetching } from './fetching';
import fetching from './fetching';

export const initState = {
    init: {
        ...initStateFetching,
    },
    staffAdd: {
        ...initStateFetching,
    },
    staffUpdate: {
        ...initStateFetching,
    },
    staffDelete: {
        ...initStateFetching,
    },
    staffImport: {
        ...initStatePagination,
    },
    staffExport: {
        ...initStateFetching,
    },
    pagination: {
        ...initStatePagination,
    },
    departments: {
        ...initStatePagination,
    },
    dataExport: '',
};

const init = fetching(STAFF.INIT);
const staffAdd = fetching(STAFF.ADD);
const staffUpdate = fetching(STAFF.UPDATE);
const staffDelete = fetching(STAFF.DELETE);
const staffImport = paginate(STAFF.IMPORT);
const staffExport = paginate(STAFF.EXPORT);
const pagination = paginate(STAFF.GETS);
const departments = paginate(STAFF.DEPARTMENT.GETS);
function dataExport(state = '', action) {
    switch (action.type) {
    case STAFF.EXPORT.SUCCESS:
        return action.payload.result;
    default:
        return state;
    }
}
export default combineReducers({
    init,
    staffAdd,
    staffUpdate,
    staffDelete,
    staffImport,
    staffExport,
    pagination,
    departments,
    dataExport,
});