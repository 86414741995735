import { initStateFetching } from './fetching';
import { FORMULA } from '../constants';
import fetching from './fetching';
import { combineReducers } from 'redux';

export const initState = {
    formulaAdd: {
        ...initStateFetching
    }
};

const formulaAdd = fetching(FORMULA.ADD);

export default combineReducers({
    formulaAdd
});