import moment from 'moment';

const convertFilterObject = (filterObject) => {
  const [from, to] = [
    filterObject.time && filterObject.time[0] ? moment(filterObject.time[0]).format('MM/YYYY') : '',
    filterObject.time && filterObject.time[1] ? moment(filterObject.time[1]).format('MM/YYYY') : '',
  ];
  return {
    unit: filterObject.unit,
    service: filterObject.service,
    isShowService: filterObject.isShowService,
    from,
    to,
    label: filterObject.customerCode,
  };
};

export { convertFilterObject };
