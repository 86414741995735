//@flow
import { REQUEST } from '../constants/actionType';
import fetching, { initStateFetching } from './fetching';
import paginate, { initStatePagination } from './paginate';
import { combineReducers } from 'redux';

export const initState = {
    //Request
    requestGet: {
        ...initStatePagination
    },
    requestGets: {
        ...initStatePagination
    },
    requestAdd: {
        ...initStateFetching
    },
    requestUpdate: {
        ...initStateFetching
    },
    requestDuplicate: {
        ...initStateFetching
    },
    requestCancel: {
        ...initStateFetching
    },
    requestApproval: {
        ...initStateFetching
    },
    requestDeny: {
        ...initStateFetching
    },
    //Work
    taskGets: {
        ...initStatePagination
    },
    taskAdd: {
        ...initStateFetching
    },
    taskUpdate: {
        ...initStateFetching
    },
    taskApproval: {
        ...initStateFetching
    },
    taskDelete: {
        ...initStateFetching
    },
    //Action box
    employeeGets: {
        ...initStatePagination
    },
    departmentGets: {
        ...initStatePagination
    },
    //TAB
    totalTabGets: {
        ...initStatePagination
    }
};

const reducer = combineReducers({
    requestGet: paginate(REQUEST.REQUEST_GET),
    requestGets: paginate(REQUEST.REQUEST_GETS),
    requestAdd: fetching(REQUEST.REQUEST_ADD),
    requestUpdate: fetching(REQUEST.REQUEST_UPDATE),
    requestDuplicate: fetching(REQUEST.REQUEST_DUPLICATE),
    requestExportData: paginate(REQUEST.REQUEST_EXPORT_DATA),
    requestCancel: fetching(REQUEST.REQUEST_CANCEL),
    requestApproval: fetching(REQUEST.REQUEST_APPROVE),
    requestDeny: fetching(REQUEST.REQUEST_DENY),
    requestChangeProgress: fetching(REQUEST.REQUEST_CHANGE_PROGRESS),

    taskGets: paginate(REQUEST.REQUEST_TASK_GETS),
    taskAdd: fetching(REQUEST.REQUEST_TASK_ADD),
    taskUpdate: fetching(REQUEST.REQUEST_TASK_UPDATE),
    taskApproval: fetching(REQUEST.REQUEST_TASK_APPROVAL),
    taskDelete: fetching(REQUEST.REQUEST_TASK_DELETE),
    taskChangeProgress: fetching(REQUEST.REQUEST_TASK_CHANGE_PROGRESS),

    requestActivitiesLog: paginate(REQUEST.REQUEST_ACTIVITY_LOG),
    requestTimeline: paginate(REQUEST.REQUEST_TIMELINE),

    employeeGets: paginate(REQUEST.REQUEST_EMPLOYEES_GETS),
    departmentGets: paginate(REQUEST.REQUEST_DEPARTMENT_GETS),
    apartmentGets: paginate(REQUEST.REQUEST_APARTMENT_GETS),

    totalTabGets: paginate(REQUEST.REQUEST_TOTAL_TAB_GETS)
});

export default reducer;