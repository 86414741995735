import moment from 'moment';
import 'moment/locale/eu';
import 'moment/locale/vi';
import { hot } from 'react-hot-loader';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import enGB from 'antd/es/locale/en_GB';
import viVN from 'antd/es/locale/vi_VN';
import jaJp from 'antd/es/locale/ja_JP';
import { getCacheLanguage } from 'defined/Language';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Alert from 'components/modules/Alert';
import global from 'defined/GlobalVariable';
import Loadable from 'react-loadable';
import AuthComponent from './components/common/AuthComponent/AuthComponentEnhance';
import ErrorBoundary from './ErrorBoundary';

// moment.locale(getCacheLanguage() === 'vi' ? 'vi_VN' : 'en_US');

const Loading = () => <div>Loading...</div>;

const Test1 = Loadable({
  loader: () => import('./test1'),
  loading: Loading,
});

const Print = Loadable({
  loader: () => import('components/modules/Print/Print'),
  loading: Loading,
});

const Login = Loadable({
  loader: () => import('pages/Auth/Login'),
  loading: Loading,
});

const Register = Loadable({
  loader: () => import('pages/Auth/Register'),
  loading: Loading,
});

const ForgotPassword = Loadable({
  loader: () => import('pages/Auth/ForgotPassword'),
  loading: Loading,
});

const NewPassword = Loadable({
  loader: () => import('pages/Auth/NewPassword'),
  loading: Loading,
});

const UpdatePassword = Loadable({
  loader: () => import('pages/Auth/UpdatePassword'),
  loading: Loading,
});

const App = Loadable({
  loader: () => import('App'),
  loading: Loading,
});

const Home = Loadable({
  loader: () => import('pages/Home/Home'),
  loading: Loading,
});

const BuildingAdd = Loadable({
  loader: () => import('pages/Home/Building/BuildingAdd'),
  loading: Loading,
});

const BuildingUpdate = Loadable({
  loader: () => import('pages/Home/Building/BuildingUpdate'),
  loading: Loading,
});

const Profile = Loadable({
  loader: () => import('pages/Profile/ProfileCtr'),
  loading: Loading,
});

const Document = Loadable({
  loader: () => import('pages/Document/DocumentView'),
  loading: Loading,
});

const Verify = Loadable({
  loader: () => import('pages/Auth/Verify/Verify'),
  loading: Loading,
});

function Routes(props: Object) {
  const { store } = props;

  const getLanguage = () => {
    if (getCacheLanguage() === 'vi') return viVN;
    if (getCacheLanguage() === 'en') return enGB;
    if (getCacheLanguage() === 'jp') return jaJp;
  }
  return (
    <Provider store={store}>
      <ConfigProvider locale={getLanguage()}>
        <ErrorBoundary>
          <Router>
            <div style={{ height: '100%' }}>
              <Alert
                ref={(ref) => {
                  global.set({
                    ...global.get(),
                    alert: ref,
                  });
                }}
              />
              <Switch>
                <Route path="/test1" component={Test1} />
                <Route path="/print" component={Print} />
                <Route path="/login" component={Login} />
                <Route path="/verified" component={Verify} />
                <Route path="/register" component={Register} />
                <Route path="/password/forgot" component={ForgotPassword} />
                <Route path="/password/set-new-password" component={NewPassword} />
                <Route path="/password/update" component={UpdatePassword} />
                <AuthComponent path="/buildings" component={Home} exact />
                <AuthComponent path="/building/add" component={BuildingAdd} exact />
                <AuthComponent path="/building/:idBuilding/update" component={BuildingUpdate} exact />
                <AuthComponent path="/dashboard" component={Home} exact />
                <AuthComponent path="/profile" component={Profile} exact />
                <AuthComponent path="/buildings/:idBuilding/*" component={App} />
                <AuthComponent path="/buildings" component={Home} />
                <AuthComponent path="/dashboard" component={Home} />
                <AuthComponent path="/news" component={Home} />
                <AuthComponent path="/profile" component={Profile} />
                <AuthComponent path="/" component={Home} />
              </Switch>
            </div>
          </Router>
        </ErrorBoundary>
      </ConfigProvider>
    </Provider>
  );
}

export default hot(module)(Routes);
