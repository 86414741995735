import { FORM_CONFIRM_SERVICE } from '../constants/actionType';
import { call, put } from 'redux-saga/effects';
import { getContractTemplateService } from '../services';
import { X_UNIT_GROUP_ID } from '../constants';

export function* initFormConfirmService(action) {
    const {
        buildingId,
        serviceId,
    } = action.payload;

    const params = {
        serviceId
    };

    const headers = {
        [X_UNIT_GROUP_ID]: buildingId,
    };

    const result = yield call(getContractTemplateService, {
        params,
        headers,
    }, FORM_CONFIRM_SERVICE.INIT);
    yield put(result);
}