import { STAFF } from '../constants/actionType';

export const initStaffPage = (buildingId: string) => ({
    type: STAFF.INIT.REQUEST,
    payload: {
        buildingId,
    },
});

export const getStaffsByPage = (buildingId: string, page: number, filters: Object) => ({
    type: STAFF.GETS.REQUEST,
    payload: {
        buildingId,
        page,
        filters,
    },
});

export const addStaff = (staff, unitGroupId) => ({
    type: STAFF.ADD.REQUEST,
    payload: {
        staff,
        unitGroupId,
    },
});

export const refreshAddStaff = () => ({
    type: STAFF.ADD.REFRESH,
});

export const updateStaff = (staff, unitGroupId) => ({
    type: STAFF.UPDATE.REQUEST,
    payload: {
        staff,
        unitGroupId,
    },
});

export const refreshUpdateStaff = () => ({
    type: STAFF.UPDATE.REFRESH,
});

export const deleteStaff = (ids, unitGroupId) => ({
    type: STAFF.DELETE.REQUEST,
    payload: {
        ids,
        unitGroupId,
    },
});

export const refreshDeleteStaff = () => ({
    type: STAFF.DELETE.REFRESH,
});

export const importStaff = (buildingId, file) => ({
    type: STAFF.IMPORT.REQUEST,
    payload: {
        buildingId,
        file,
    },
});

export const resetDataImportStaff = () => ({
    type: STAFF.IMPORT.SUCCESS,
    payload: {
        isFetching: false,
        currentPage: 1,
        totalPage: 0,
        totalRecord: 0,
        data: [],
    },
});

export const exportStaff = (buildingId, filterObject) => ({
    type: STAFF.EXPORT.REQUEST,
    payload: {
        buildingId,
        filterObject,
    },
});