import { combineReducers } from 'redux';
import {
  ACTION_TYPE_EVENT_ADD,
  ACTION_TYPE_EVENT_DELETE,
  ACTION_TYPE_EVENT_GETS,
  ACTION_TYPE_EVENT_GET,
  ACTION_TYPE_EVENT_TAG_GETS,
  ACTION_TYPE_EVENT_FLOOR_GETS,
  ACTION_TYPE_EVENT_APARTMENT_GETS,
  ACTION_TYPE_EVENT_APARTMENT_RECEIVE_EVENT_GETS,
} from './event.types';

export const initState = {
  gets: {
    isFetching: false,
    data: [],
    totalPage: 0,
    totalRecord: 0,
  },
  get: {
    isFetching: false,
    code: undefined,
    codeLanguage: undefined,
    data: undefined,
  },
  add: {
    isFetching: false,
    code: undefined,
    codeLanguage: undefined,
  },
  delete: {
    isFetching: false,
    code: undefined,
    codeLanguage: undefined,
  },
  tags: {
    isFetching: false,
    data: [],
  },
  floors: {
    isFetching: false,
    data: [],
  },
  units: {
    isFetching: false,
    data: [],
    totalRecord: 0,
  },
  unitsReceiveEvent: {
    isFetching: false,
    data: [],
    totalRecord: 0,
  },
};

const gets = (state = initState.gets, action) => {
  switch (action.type) {
    case ACTION_TYPE_EVENT_GETS.REQUEST: {
      return {
        isFetching: true,
        data: [],
        totalPage: 0,
        totalRecord: 0,
      };
    }
    case ACTION_TYPE_EVENT_GETS.SUCCESS: {
      return {
        isFetching: false,
        data: action.payload.result,
        totalPage: action.payload.totalPage,
        totalRecord: action.payload.totalRecord,
      };
    }
    case ACTION_TYPE_EVENT_GETS.FAIL: {
      return {
        isFetching: false,
        data: [],
        totalPage: 0,
        totalRecord: 0,
      };
    }
    case ACTION_TYPE_EVENT_GETS.REFRESH: {
      return {
        ...initState.gets,
      };
    }
    default: {
      return state;
    }
  }
};

const get = (state = initState.get, action) => {
  switch (action.type) {
    case ACTION_TYPE_EVENT_GET.REQUEST: {
      return {
        isFetching: true,
        code: undefined,
        codeLanguage: undefined,
        data: undefined,
      };
    }
    case ACTION_TYPE_EVENT_GET.SUCCESS: {
      return {
        isFetching: false,
        code: action.payload.code,
        codeLanguage: action.payload.codeLanguage,
        data: action.payload.result,
      };
    }
    case ACTION_TYPE_EVENT_GET.FAIL: {
      return {
        isFetching: false,
        code: action.payload.code,
        codeLanguage: action.payload.codeLanguage,
        data: undefined,
      };
    }
    case ACTION_TYPE_EVENT_GETS.REFRESH: {
      return {
        ...initState.get,
      };
    }
    default: {
      return state;
    }
  }
};

const add = (state = initState.add, action) => {
  switch (action.type) {
    case ACTION_TYPE_EVENT_ADD.REQUEST: {
      return {
        isFetching: true,
        code: undefined,
        codeLanguage: undefined,
      };
    }
    case ACTION_TYPE_EVENT_ADD.SUCCESS: {
      return {
        isFetching: false,
        code: action.payload.code,
        codeLanguage: action.payload.codeLanguage,
      };
    }
    case ACTION_TYPE_EVENT_ADD.FAIL: {
      return {
        isFetching: false,
        code: action.payload.code,
        codeLanguage: action.payload.codeLanguage,
      };
    }
    case ACTION_TYPE_EVENT_ADD.REFRESH: {
      return {
        ...initState.add,
      };
    }
    default: {
      return state;
    }
  }
};

const deleteEvent = (state = initState.delete, action) => {
  switch (action.type) {
    case ACTION_TYPE_EVENT_DELETE.REQUEST: {
      return {
        isFetching: true,
        code: undefined,
        codeLanguage: undefined,
      };
    }
    case ACTION_TYPE_EVENT_DELETE.SUCCESS: {
      return {
        isFetching: false,
        code: action.payload.code,
        codeLanguage: action.payload.codeLanguage,
      };
    }
    case ACTION_TYPE_EVENT_DELETE.FAIL: {
      return {
        isFetching: false,
        code: action.payload.code,
        codeLanguage: action.payload.codeLanguage,
      };
    }
    case ACTION_TYPE_EVENT_DELETE.REFRESH: {
      return {
        ...initState.delete,
      };
    }
    default: {
      return state;
    }
  }
};

const tags = (state = initState.tags, action) => {
  switch (action.type) {
    case ACTION_TYPE_EVENT_TAG_GETS.REQUEST: {
      return {
        isFetching: true,
        data: [],
      };
    }
    case ACTION_TYPE_EVENT_TAG_GETS.SUCCESS: {
      return {
        isFetching: false,
        data: action.payload.result,
      };
    }
    case ACTION_TYPE_EVENT_TAG_GETS.FAIL: {
      return {
        isFetching: false,
        data: [],
      };
    }
    case ACTION_TYPE_EVENT_TAG_GETS.REFRESH: {
      return {
        ...initState.tags,
      };
    }
    default: {
      return state;
    }
  }
};

const floors = (state = initState.floors, action) => {
  switch (action.type) {
    case ACTION_TYPE_EVENT_FLOOR_GETS.REQUEST: {
      return {
        isFetching: true,
        data: [],
      };
    }
    case ACTION_TYPE_EVENT_FLOOR_GETS.SUCCESS: {
      return {
        isFetching: false,
        data: action.payload.floors,
      };
    }
    case ACTION_TYPE_EVENT_FLOOR_GETS.FAIL: {
      return {
        isFetching: false,
        data: [],
      };
    }
    case ACTION_TYPE_EVENT_FLOOR_GETS.REFRESH: {
      return {
        ...initState.floors,
      };
    }
    default: {
      return state;
    }
  }
};

const units = (state = initState.units, action) => {
  switch (action.type) {
    case ACTION_TYPE_EVENT_APARTMENT_GETS.REQUEST: {
      return {
        isFetching: true,
        data: [],
        totalRecord: 0,
      };
    }
    case ACTION_TYPE_EVENT_APARTMENT_GETS.SUCCESS: {
      return {
        isFetching: false,
        data: action.payload.result,
        totalRecord: action.payload.totalRecord,
      };
    }
    case ACTION_TYPE_EVENT_APARTMENT_GETS.FAIL: {
      return {
        isFetching: false,
        data: [],
        totalRecord: 0,
      };
    }
    case ACTION_TYPE_EVENT_APARTMENT_GETS.REFRESH: {
      return {
        ...initState.units,
      };
    }
    default: {
      return state;
    }
  }
};

const unitsReceiveEvent = (state = initState.unitsReceiveEvent, action) => {
  switch (action.type) {
    case ACTION_TYPE_EVENT_APARTMENT_RECEIVE_EVENT_GETS.REQUEST: {
      return {
        isFetching: true,
        data: [],
        totalRecord: 0,
      };
    }
    case ACTION_TYPE_EVENT_APARTMENT_RECEIVE_EVENT_GETS.SUCCESS: {
      return {
        isFetching: false,
        data: action.payload.result,
        totalRecord: action.payload.totalRecord,
      };
    }
    case ACTION_TYPE_EVENT_APARTMENT_RECEIVE_EVENT_GETS.FAIL: {
      return {
        isFetching: false,
        data: [],
        totalRecord: 0,
      };
    }
    case ACTION_TYPE_EVENT_APARTMENT_RECEIVE_EVENT_GETS.REFRESH: {
      return {
        ...initState.unitsReceiveEvent,
      };
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  gets,
  get,
  add,
  delete: deleteEvent,
  tags,
  floors,
  units,
  unitsReceiveEvent,
});
