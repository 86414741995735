
import { initStatePagination } from './paginate';
import { INVOICE, PAYMENT } from '../constants/actionType';
import paginate from './paginate';
import { combineReducers } from 'redux';
import { initStateFetching } from './fetching';
import fetching from './fetching';

export const initState = {
    paymentInit: {
        ...initStateFetching
    },

    bills: {
        ...initStatePagination,
    },
    sumPayment: {
        ...initStatePagination,
    },
    receiptCreate: {
        ...initStatePagination,
    },
    funds: {
        ...initStatePagination,
    },
    tags: {
        ...initStatePagination,
    },
};

const paymentInit = fetching(PAYMENT.INIT);
const bills = paginate(PAYMENT.BILL.GETS);
const sumPayment = paginate(PAYMENT.BILL.SUM_PAYMENT_GETS);
const receiptCreate = paginate(PAYMENT.RECEIPT.CREATE);
const funds = paginate(PAYMENT.FUND.GETS);
const tags = paginate(PAYMENT.TAG.GETS);

export default combineReducers({
    paymentInit,
    bills,
    sumPayment,
    receiptCreate,
    funds,
    tags,
});