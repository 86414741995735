import { createReceiptService, getBillsService, sumPaymentService } from '../services';
import { PAYMENT, X_UNIT_GROUP_ID } from '../constants';
import { call, put } from 'redux-saga/effects';
import { getAccountService } from '../services/fund';
import { getLabelsService } from '../services/label';

export function* initPaymentPage(action) {
    const {
        unitGroupId,
    } = action.payload;

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(getBillsService, {
        params: {
            page: 1,
            limit: 20,
        },
        headers,
    }, PAYMENT.BILL.GETS);

    yield put(result);

    const resFund = yield call(getAccountService, unitGroupId, PAYMENT.FUND.GETS);
    yield put(resFund);

    const resTag = yield call(getLabelsService, { headers }, PAYMENT.TAG.GETS);
    yield put(resTag);

    const resSumPayment = yield call(sumPaymentService, {
        headers,
    }, PAYMENT.BILL.SUM_PAYMENT_GETS);
    yield put(resSumPayment);

    yield put({
        type: PAYMENT.INIT.SUCCESS,
        payload: {
            code: 200,
        },
    });
}


export function* getSumPaymentInPagePayment(action) {
    const {
        unitGroupId,
        filterObject,
    } = action.payload;

    const params = {
        ...filterObject,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(sumPaymentService, {
        params,
        headers,
    }, PAYMENT.BILL.SUM_PAYMENT_GETS);
    yield put(result);
}

export function* getBillByGroupTargetPagePayment(action) {
    const {
        unitGroupId,
        page,
        filterObject,
    } = action.payload;

    const params = {
        page,
        limit: 20,
        ...filterObject,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(getBillsService, { params, headers }, PAYMENT.BILL.GETS);
    yield put(result);
}

export function* createReceiptInPagePayment(action) {
    const {
        receipt,
        unitGroupId,
    } = action.payload;

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(createReceiptService, {
        body: receipt,
        headers,
        bodyType: 'html',
    }, PAYMENT.RECEIPT.CREATE);

    yield put(result);
    yield put({
        type: PAYMENT.RECEIPT.CREATE.REFRESH,
    });
}