import { omit } from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    forgotPassword as forgotPasswordService,
    login as loginService,
    registerService,
    setNewPasswordService,
    updatePassword as updatePasswordService,
    logout as logoutService
} from 'services';
import { deleteDeviceToken } from '../../../firebase';
import { LOGIN, LOGOUT, PASSWORD, REGISTER } from './auth.types';

function* login(action) {
    const { email, password } = action.payload;
    const result = yield call(
        loginService,
        {
            email,
            password,
        },
        LOGIN
    );
    if (result.type === LOGIN.SUCCESS) {
        result.payload.result.user = omit(result.payload.result.user, 'employees');
    }
    yield put(result);
}

function* register(action) {
    const { email, password, name, dob, phone, gender } = action.payload.user;

    const user = {
        email,
        password,
        username: email,
        fullName: name,
        birthday: dob,
        phone,
        gender,
    };

    const result = yield call(registerService, user, REGISTER);
    yield put(result);
}

function* logout() {
    let deviceToken = deleteDeviceToken();
    const result = yield call(logoutService, { deviceToken }, LOGOUT);
    yield put({
        type: LOGOUT.REFRESH,
    });
}

function* forgotPassword(action) {
    const { email } = action.payload;
    const result = yield call(forgotPasswordService, email, PASSWORD.FORGOT);
    yield put(result);
    yield put({
        type: PASSWORD.FORGOT.REFRESH,
    });
}

function* setNewPassword(action) {
    const { accessToken, password } = action.payload;
    const result = yield call(setNewPasswordService, accessToken, password, PASSWORD.SET_NEW);
    yield put(result);
}

function* updatePassword(action) {
    const { username, oldPassword, newPassword } = action.payload;
    const result = yield call(
        updatePasswordService,
        {
            username,
            oldPassword,
            newPassword,
        },
        PASSWORD.UPDATE
    );
    yield put(result);
}

export default function* watchAuth() {
    yield all([
        takeLatest(REGISTER.REQUEST, register),
        takeLatest(LOGIN.REQUEST, login),
        takeLatest(LOGOUT.REQUEST, logout),
        takeLatest(PASSWORD.FORGOT.REQUEST, forgotPassword),
        takeLatest(PASSWORD.SET_NEW.REQUEST, setNewPassword),
        takeLatest(PASSWORD.UPDATE.REQUEST, updatePassword),
    ]);
}
