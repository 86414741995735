import { language } from 'defined/Language';

export const SERVICE_DEBT_TYPE = 'SERVICE';
export const SERVICE_DEBT_TYPE_ARISE_BY_MONTH = 'SERVICE_DEBT_ARISE_BY_MONTH';

export const DEBT_VIEW_TYPES = [
    { id: SERVICE_DEBT_TYPE, name: language['label']['update_to_the_time_of_viewing'] },
    { id: SERVICE_DEBT_TYPE_ARISE_BY_MONTH, name: language['label']['arise_by_month'] },
];

export const REPORT_DEBT_OPTIONS = [
    { id: 'DEBT_MONTH', name: language['label']['debt_month'] },
    { id: 'CREATED_MONTH', name: language['label']['fee_notification_month'] },
];
