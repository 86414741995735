import { callApiParse, callApi } from './api';
import {
  ROOM_CHAT_GETS,
  ROOM_CHAT_GETS_BY_ID,
  ROOM_MEMBER_GETS,
  MESSAGES_GETS,
  MESSAGES_READ_BY_ID,
  MESSAGES_READ_ALL,
  ROOM_CHAT_CREATE
} from '../defined/API';
import { METHOD, X_UNIT_GROUP_ID, X_UNIT_ID, X_DEPARTMENT_ID } from '../constants';
import { handleError, handleResponse } from '../libs/http';

export async function createRoomChatService(buildingId, body, type) {
  const { FAIL } = type;

  const headers = {
    [X_UNIT_GROUP_ID]: buildingId,
  };

  try {
    const response = await callApiParse(ROOM_CHAT_CREATE, body, METHOD.POST, { headers });
    return handleResponse(response, type, 'result', undefined, undefined, true);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getRoomChatService(buildingId, params, type) {
  const { FAIL } = type;

  const headers = {
    [X_UNIT_GROUP_ID]: buildingId,
  };

  try {
    const response = await callApiParse(ROOM_CHAT_GETS, null, METHOD.GET, { params, headers });
    return handleResponse(response, type, 'result', undefined, undefined, true);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getRoomChatByIdService(buildingId, roomId, params, type) {
  const { FAIL } = type;

  const headers = {
    [X_UNIT_GROUP_ID]: buildingId,
  };

  try {
    const response = await callApiParse(ROOM_CHAT_GETS_BY_ID(roomId), null, METHOD.GET, { params, headers });
    return handleResponse(response, type, 'result');
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getRoomMemberService(buildingId, { unitId, roomType }, type) {
  const { FAIL } = type;

  const headers = {
    [X_UNIT_GROUP_ID]: buildingId,
    [X_UNIT_ID]: unitId,
  };

  const params = {
    roomType
  };

  try {
    const response = await callApi(ROOM_MEMBER_GETS(buildingId), null, METHOD.GET, { headers, params });
    return handleResponse(response, type, 'result');
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getMessagesService(buildingId, roomId, params, type) {
  const { FAIL } = type;

  const headers = {
    [X_UNIT_GROUP_ID]: buildingId,
  };

  try {
    const response = await callApiParse(MESSAGES_GETS(roomId), null, METHOD.GET, { params, headers });
    return handleResponse(response, type, 'result', undefined, undefined, true);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}


export async function sendMessagesService(buildingId, { roomId, body }, type) {
  const { FAIL } = type;

  const headers = {
    [X_UNIT_GROUP_ID]: buildingId,
  };

  try {
    const response = await callApiParse(MESSAGES_GETS(roomId), body, METHOD.POST, { headers });
    return handleResponse(response, type, 'result');
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function readMessageByIdService(buildingId, { roomId, messageId }, type) {
  const { FAIL } = type;

  const headers = {
    [X_UNIT_GROUP_ID]: buildingId,
  };

  try {
    const response = await callApiParse(MESSAGES_READ_BY_ID(roomId, messageId), {}, METHOD.PUT, { headers });
    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function readAllMessageService(buildingId, { roomId }, type) {
  const { FAIL } = type;

  const headers = {
    [X_UNIT_GROUP_ID]: buildingId,
  };

  try {
    const response = await callApiParse(MESSAGES_READ_ALL(roomId), {}, METHOD.PUT, { headers });

    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}