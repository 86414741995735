//@flow

function createAction(key) {
  return {
    REQUEST: `${key}:REQUEST`,
    SUCCESS: `${key}:SUCCESS`,
    FAIL: `${key}:FAIL`,
    REFRESH: `${key}:REFRESH`,
  };
}

export const APPLICATION = {
  INIT: createAction('APPLICATION_INIT'),
};
export const LOGIN = createAction('LOGIN');
export const REGISTER = createAction('REGISTER');
export const LOGOUT = createAction('LOGOUT');
export const PASSWORD = {
  FORGOT: createAction('PASSWORD_FORGOT'),
  UPDATE: createAction('PASSWORD_UPDATE'),
  SET_NEW: createAction('PASSWORD_SET_NEW'),
};
export const ACTIVE_ACCOUNT = {
  EMAIL_STEP_1: createAction('EMAIL_STEP_1'),
  EMAIL_STEP_2: createAction('EMAIL_STEP_2'),
};
export const PERMISSION = {
  GETS: createAction('PERMISSION_GETS'),
};
export const BUILDING = {
  GETS: createAction('BUILDING_GETS'),
  GET_BY_ID: createAction('BUILDING_GET_BY_ID'),
  ADD: createAction('BUILDING_ADD'),
  UPDATE: createAction('BUILDING_UPDATE'),
  DELETE: createAction('BUILDING_DELETE'),
  CHANGE_VIEW_TYPE: 'BUILDING_CHANGE_VIEW_TYPE',
  REFRESH_FETCHING_DATA_STATUS: 'BUILDING_REFRESH_FETCHING_DATA_STATUS',
};
export const FLOOR = {
  GETS: createAction('FLOOR_GETS'),
  ADD: createAction('FLOOR_ADD'),
  UPDATE: createAction('FLOOR_UPDATE'),
  DELETE: createAction('FLOOR_DELETE'),
};
export const APARTMENT = {
  INIT: createAction('APARTMENT_INIT'),
  GETS: createAction('APARTMENT_GETS'),
  GETS_EMPTY: createAction('APARTMENT_GETS_EMPTY'),
  GETS_IN_USE: createAction('APARTMENT_GETS_IN_USE'),
  EXPORT: createAction('APARTMENT_EXPORT'),
  REFRESH: createAction('APARTMENT_REFRESH'),
  LABEL_GETS: createAction('APARTMENT_LABEL_GETS'),
};
export const APARTMENT_DETAIL = {
  INIT: createAction('APARTMENT_DETAIL_INIT'),
  APARTMENT_INFO: {
    GET: createAction('APARTMENT_DETAIL_INFO_GET'),
  },
  PAYMENT_INFO_GET: createAction('APARTMENT_DETAIL_PAYMENT_INFO_GET'),
  RESIDENT: {
    GETS_BY_APARTMENT: createAction('APARTMENT_DETAIL_RESIDENT_GETS'),
    ADD: createAction('APARTMENT_DETAIL_RESIDENT_ADD'),
    UPDATE: createAction('APARTMENT_DETAIL_RESIDENT_UPDATE'),
    DELETE: createAction('APARTMENT_DETAIL_RESIDENT_DELETE'),
  },
  DOCUMENT: {
    GETS_BY_APARTMENT: createAction('APARTMENT_DETAIL_DOCUMENT_GETS'),
    ADD: createAction('APARTMENT_DETAIL_DOCUMENT_ADD'),
    UPDATE: createAction('APARTMENT_DETAIL_DOCUMENT_UPDATE'),
    DELETE: createAction('APARTMENT_DETAIL_DOCUMENT_DELETE'),
  },
  SERVICE_HISTORY: {
    INIT: createAction('APARTMENT_DETAIL_SERVICE_HISTORY_INIT'),
    GETS: createAction('APARTMENT_DETAIL_SERVICE_HISTORY_GETS'),
  },
  PAYMENT_HISTORY: {
    GETS: createAction('APARTMENT_DETAIL_PAYMENT_HISTORY_GETS'),
    PREVIEW: createAction('APARTMENT_DETAIL_PAYMENT_HISTORY_PREVIEW'),
  },
  SERVICE: {
    INIT: createAction('APARTMENT_DETAIL_SERVICE_INIT'),
    GETS: createAction('APARTMENT_DETAIL_SERVICE_GETS'),
  },
  BILL: {
    GETS: createAction('APARTMENT_DETAIL_BILL_GETS'),
  },
  RECEIPT: {
    CREATE: createAction('APARTMENT_DETAIL_RECEIPT_CREATE'),
  },
  FUND: {
    GETS: createAction('APARTMENT_DETAIL_FUND_GETS'),
  },
  TAG: {
    GETS: createAction('APARTMENT_DETAIL_TAG_GETS'),
  },
  WALLET: {
    GETS: createAction('APARTMENT_DETAIL_WALLET_GETS'),
  },
  QRCODE: {
    GET: createAction('APARTMENT_DETAIL_QRCODE_GET'),
    REFRESH: createAction('APARTMENT_DETAIL_QRCODE_REFRESH'),
    EXPORT: createAction('APARTMENT_DETAIL_QRCODE_EXPORT'),
  },
};
export const CONFIG = {
  FLOOR: {
    GETS: createAction('CONFIG_FLOOR_GETS'),
    ADD: createAction('CONFIG_FLOOR_ADD'),
    UPDATE: createAction('CONFIG_FLOOR_UPDATE'),
    DELETE: createAction('CONFIG_FLOOR_DELETE'),
  },
  APARTMENT: {
    INIT: createAction('CONFIG_APARTMENT_INIT'),
    ADD: createAction('CONFIG_APARTMENT_ADD'),
    UPDATE: createAction('CONFIG_APARTMENT_UPDATE'),
    DELETE: createAction('CONFIG_APARTMENT_DELETE'),
    IMPORT: createAction('CONFIG_APARTMENT_IMPORT'),
    EXPORT_APARTMENT: createAction('CONFIG_APARTMENT_EXPORT_APARTMENT'),
    GETS: createAction('CONFIG_APARTMENT_GETS'),
  },
  DEPARTMENT: {
    GETS: createAction('CONFIG_DEPARTMENT_GETS'),
    INIT: createAction('CONFIG_DEPARTMENT_INIT'),
    ADD: createAction('CONFIG_DEPARTMENT_ADD'),
    UPDATE: createAction('CONFIG_DEPARTMENT_UPDATE'),
    DELETE: createAction('CONFIG_DEPARTMENT_DELETE'),
  },
  LABEL: {
    GETS: createAction('CONFIG_LABEL_GETS'),
    INIT: createAction('CONFIG_LABEL_INIT'),
    ADD: createAction('CONFIG_LABEL_ADD'),
    UPDATE: createAction('CONFIG_LABEL_UPDATE'),
    DELETE: createAction('CONFIG_LABEL_DELETE'),
    GROUP_GETS: createAction('CONFIG_GROUP_LABEL_GETS'),
    TREE_GETS: createAction('CONFIG_LABEL_TREE_GETS'),
  },
  POSITION: {
    INIT: createAction('CONFIG_POSITION_INIT'),
    GETS: createAction('CONFIG_POSITION_GETS'),
    ADD: createAction('CONFIG_POSITION_ADD'),
    UPDATE: createAction('CONFIG_POSITION_UPDATE'),
    DELETE: createAction('CONFIG_POSITION_DELETE'),
    DEPARTMENT: {
      GETS: createAction('CONFIG_DEPARTMENT_GETS'),
      ADD: createAction('CONFIG_DEPARTMENT_ADD'),
      UPDATE: createAction('CONFIG_DEPARTMENT_UPDATE'),
      DELETE: createAction('CONFIG_DEPARTMENT_DELETE'),
    },
  },
  TAG: {
    INIT: createAction('TAG_CONFIG_INIT'),
    ADD: createAction('TAG_CONFIG_ADD'),
    UPDATE: createAction('TAG_CONFIG_UPDATE'),
    DELETE: createAction('TAG_CONFIG_DELETE'),
  },
  BLOCK: {
    INIT: createAction('CONFIG_BLOCK_INIT'),
    GETS: createAction('CONFIG_BLOCK_GETS'),
    ADD: createAction('CONFIG_BLOCK_ADD'),
    UPDATE: createAction('CONFIG_BLOCK_UPDATE'),
    DELETE: createAction('CONFIG_BLOCK_DELETE'),
  },
  SERVICE: {
    INIT: createAction('CONFIG_SERVICE_INIT'),
    RESET_REDUCER: createAction('CONFIG_RESET_REDUCER'),
    GETS: createAction('CONFIG_SERVICE_GETS'),
    ADD: createAction('CONFIG_SERVICE_ADD'),
    UPDATE: createAction('CONFIG_SERVICE_UPDATE'),
    DELETE: createAction('CONFIG_SERVICE_DELETE'),
    ACTIVE: createAction('CONFIG_SERVICE_ACTIVE'),
    INACTIVE: createAction('CONFIG_SERVICE_INACTIVE'),
    BUILDING_USE_SERVICE: createAction('CONFIG_BUILDING_USING_SERVICE'),
    BUILDING_UPDATE_SERVICE: createAction('CONFIG_BUILDING_UPDATE_SERVICE'),
    ADDITIONAL_FEE: {
      GETS: createAction('CONFIG_SERVICE_ADDITIONAL_FEE_GETS'),
      ADD: createAction('CONFIG_SERVICE_ADDITIONAL_FEE_ADD'),
      UPDATE: createAction('CONFIG_SERVICE_ADDITIONAL_FEE_UPDATE'),
      DELETE: createAction('CONFIG_SERVICE_ADDITIONAL_FEE_DELETE'),
    },
    ALLOWANCE: {
      GETS_BY_BUILDING: createAction('CONFIG_SERVICE_ALLOWANCE_GETS_BY_BUILDING'),
      GET_BY_ID: createAction('CONFIG_SERVICE_ALLOWANCE_GET_BY_ID'),
      ADD: createAction('CONFIG_SERVICE_ALLOWANCE_ADD'),
      DELETE: createAction('CONFIG_SERVICE_ALLOWANCE_DELETE'),
    },
  },
  TEMPLATE: {
    GET_BY_NAME: createAction('CONFIG_TEMPLATE_GET_BY_NAME'),
    GETS: createAction('CONFIG_TEMPLATE_GETS'),
    SAVE: createAction('CONFIG_TEMPLATE_SAVE'),
  },
  ACCOUNT: {
    GETS: createAction('CONFIG_ACCOUNT_GETS'),
    ADD: createAction('CONFIG_ACCOUNT_ADD'),
    UPDATE: createAction('CONFIG_ACCOUNT_UPDATE'),
    DELETE: createAction('CONFIG_ACCOUNT_DELETE'),
  },
};
export const INTERNAL_TASK = {
  INIT: createAction('INTERNAL_TASK_INIT'),
  GETS: createAction('INTERNAL_TASK_GETS'),
  ADD: createAction('INTERNAL_TASK_ADD'),
  UPDATE: createAction('INTERNAL_TASK_UPDATE'),
  DELETE: createAction('INTERNAL_TASK_DELETE'),
  CHANGE_STATUS: createAction('INTERNAL_CHANGE_STATUS'),
};
export const RESIDENT = {
  INIT: createAction('RESIDENT_INIT'),
  FILTER: createAction('RESIDENT_FILTER'),
  GETS: createAction('RESIDENT_GETS'),
  GET_BY_ID: createAction('GET_BY_ID'),
  ADD: createAction('RESIDENT_ADD'),
  UPDATE: createAction('RESIDENT_UPDATE'),
  DELETE: createAction('RESIDENT_DELETE'),
  IMPORT: createAction('RESIDENT_IMPORT'),
  EXPORT: createAction('RESIDENT_EXPORT'),
  ACTIVE_ACCOUNT: createAction('RESIDENT_ACTIVE_ACCOUNT'),
  INACTIVE_ACCOUNT: createAction('RESIDENT_INACTIVE_ACCOUNT'),
  FORM: {
    SEARCH_APARTMENT: createAction('RESIDENT_FORM_SEARCH_APARTMENT'),
  },
};

export const PAGE_ASSET = {
  INIT: createAction('PAGE_ASSET_INIT'),
  DEPARTMENT_GETS: createAction('PAGE_ASSET_DEPARTMENT_GETS'),
  EMPLOYEE_GETS: createAction('PAGE_ASSET_EMPLOYEE_GETS'),
  PARTNER_GETS: createAction('PAGE_ASSET_PARTNER_GETS'),
};

export const ASSET = {
  INIT: createAction('ASSET_INIT'),
  GETS: createAction('ASSET_GETS'),
  ADD: createAction('ASSET_ADD'),
  UPDATE: createAction('ASSET_UPDATE'),
  DELETE: createAction('ASSET_DELETE'),
  PARTNER: {
    GETS: createAction('ASSET_PARTNER_GETS'),
  },
  FLOOR: {
    GETS: createAction('ASSET_FLOOR_GETS'),
  },
  DEPARTMENT: {
    GETS: createAction('ASSET_DEPARTMENT_GETS'),
  },
  EMPLOYEE: {
    GETS: createAction('ASSET_EMPLOYEE_GETS'),
  },
  FORM: {
    SEARCH_APARTMENT: createAction('ASSET_FORM_SEARCH_APARTMENT'),
    SEARCH_PARTNER: createAction('ASSET_FORM_SEARCH_PARTNER'),
    SEARCH_FLOOR: createAction('ASSET_FORM_SEARCH_FLOOR'),
    SEARCH_BLOCK: createAction('ASSET_FORM_SEARCH_BLOCK'),
  },
  MAINTENANCE: {
    ADD: createAction('ASSET_MAINTENANCE_ADD'),
  },
  CHANGE_STATUS: createAction('ASSET_CHANGE_STATUS'),
  IMPORT: createAction('ASSET_IMPORT'),
  HISTORY_GETS: createAction('ASSET_HISTORY_GETS'),
  EXPORT: createAction('ASSET_EXPORT'),
};
export const PARTNER = {
  GETS: createAction('PARTNER_GETS'),
  ADD: createAction('PARTNER_ADD'),
  UPDATE: createAction('PARTNER_UPDATE'),
  DELETE: createAction('PARTNER_DELETE'),
  IMPORT: createAction('PARTNER_IMPORT'),
  EXPORT: createAction('PARTNER_EXPORT'),
};
export const SUPPLY = {
  INIT: createAction('SUPPLY_INIT'),
  GETS: createAction('SUPPLY_GETS'),
  ADD: createAction('SUPPLY_ADD'),
  UPDATE: createAction('SUPPLY_UPDATE'),
  DELETE: createAction('SUPPLY_DELETE'),
  RELOAD: createAction('SUPPLY_RELOAD'),
  FORM: {
    SEARCH_PARTNER: createAction('SUPPLY_SEARCH_PARTNER'),
    SEARCH_EMPLOYEE: createAction('SUPPLY_SEARCH_EMPLOYEE'),
    SEARCH_DEPARTMENT: createAction('SUPPLY_SEARCH_DEPARTMENT'),
  },
  TRANSACTION: {
    ADD: createAction('SUPPLY_TRANSACTION_ADD'),
  },
  HISTORY_GETS: createAction('SUPPLY_HISTORY_GETS'),
  EXPORT: createAction('SUPPLY_EXPORT'),
  IMPORT: createAction('SUPPLY_IMPORT'),
  HISTORY_EXPORT: createAction('SUPPLY_HISTORY_EXPORT'),
};

export const STAFF = {
  INIT: createAction('STAFF_INIT'),
  GETS: createAction('STAFF_GETS'),
  ADD: createAction('STAFF_ADD'),
  UPDATE: createAction('STAFF_UPDATE'),
  DELETE: createAction('STAFF_DELETE'),
  IMPORT: createAction('STAFF_IMPORT'),
  EXPORT: createAction('STAFF_EXPORT'),
  DEPARTMENT: {
    GETS: createAction('STAFF_DEPARTMENT_GETS'),
  },
};

export const COMMUNICATE = {
  TEMPLATE: {
    INIT: createAction('COMMUNICATE_TEMPLATE_INIT'),
    ADD: createAction('COMMUNICATE_TEMPLATE_ADD'),
    UPDATE: createAction('COMMUNICATE_TEMPLATE_UPDATE'),
    DELETE: createAction('COMMUNICATE_TEMPLATE_DELETE'),
  },
  ANNOUNCEMENT_TAB: {
    GETS: createAction('COMMUNICATE_ANNOUNCEMENT_TAB_GETS'),
    SEND: createAction('COMMUNICATE_ANNOUNCEMENT_TAB_SEND'),
    DELETE: createAction('COMMUNICATE_ANNOUNCEMENT_TAB_DELETE'),
  },
  FLOOR: {
    GETS: createAction('COMMUNICATE_FLOOR_GETS'),
  },
  TAG: {
    GETS: createAction('COMMUNICATE_TAG_GETS'),
  },
  UNIT: {
    GETS: createAction('COMMUNICATE_UNIT_GETS'),
  },
  UNIT_SEEN: {
    GETS: createAction('COMMUNICATE_UNIT_SEEN_GETS'),
  },
  UNIT_SEND_BY_EMAIL: {
    GETS: createAction('COMMUNICATE_UNIT_SEND_BY_EMAIL_GETS'),
  },
  UNIT_SEND_BY_APP: {
    GETS: createAction('COMMUNICATE_UNIT_SEND_BY_APP_GETS'),
  },
  UNIT_VOTE: {
    GETS: createAction('COMMUNICATE_UNIT_VOTE_GETS'),
  },
  RESEND_EMAIL: {
    GET: createAction('COMMUNICATE_RESEND_EMAIL_GET'),
  },
  ROOM_CHAT: {
    GETS: createAction('COMMUNICATE_ROOM_CHAT_GETS'),
    GET_BY_ID: createAction('COMMUNICATE_ROOM_CHAT_GET_BY_ID'),
    GETS_MEMBER: createAction('COMMUNICATE_ROOM_CHAT_GET_MEMBER'),
    READ: 'COMMUNICATE_ROOM_CHAT_READ',
    CREATE: createAction('COMMUNICATE_ROOM_CHAT_CREATE'),
  },
  MESSAGES: {
    GETS: createAction('COMMUNICATE_MESSAGES_GETS'),
    HAS_NEW_MESSAGE: 'COMMUNICATE_MESSAGES_HAS_NEW_MESSAGE',
    SEND_MESSAGE: createAction('COMMUNICATE_MESSAGES_SEND_MESSAGE'),
    READ_BY_ID: createAction('COMMUNICATE_MESSAGES_READ_BY_ID'),
    READ_ALL: createAction('COMMUNICATE_MESSAGES_READ_ALL'),
  },
  ANNOUNCEMENT: {
    INIT: createAction('COMMUNICATE_ANNOUNCEMENT_INIT'),
    GETS: createAction('COMMUNICATE_ANNOUNCEMENT_GETS'),
    ADD: createAction('COMMUNICATE_ANNOUNCEMENT_ADD'),
    DELETE: createAction('COMMUNICATE_ANNOUNCEMENT_DELETE'),
  },
  POST: {
    INIT: createAction('COMMUNICATE_POST_INIT'),
    GETS: createAction('COMMUNICATE_POST_GETS'),
    ADD: createAction('COMMUNICATE_POST_ADD'),
    UPDATE: createAction('COMMUNICATE_POST_UPDATE'),
    DELETE: createAction('COMMUNICATE_POST_DELETE'),
    PUBLISH: createAction('COMMUNICATION_POST_PUBLISH'),
    REVOKE: createAction('COMMUNICATION_POST_REVOKE'),
  },
  COMMENT: {
    GETS: createAction('COMMUNICATE_COMMENT_GETS'),
    ADD: createAction('COMMUNICATE_COMMENT_ADD'),
    DELETE: createAction('COMMUNICATE_COMMENT_DELETE'),
  },
  THREAD: {
    GETS: createAction('COMMUNICATE_THREAD_GETS'),
    ADD: createAction('COMMUNICATE_THREAD_ADD'),
    UPDATE: createAction('COMMUNICATE_THREAD_UPDATE'),
    DELETE: createAction('COMMUNICATE_THREAD_DELETE'),
  },
  RESIDENT_MESSAGES_PAGE: {
    INIT: createAction('COMMUNICATE_RESIDENT_MESSAGES_PAGE_INIT'),
  },
  CHAT: {
    CONNECTING: createAction('COMMUNICATE_CHAT_CONNECTING'),
    CREATE_CHAT_GROUP: createAction('COMMUNICATE_CREATE_CHAT_GROUP'),
    GET_ROOM: createAction('COMMUNICATE_CHAT_GET_ROOM'),
  },
  OVERALL: {
    ANNOUNCEMENT_FILTER_BY_TYPE: createAction('ANNOUNCEMENT_FILTER_BY_TYPE'),
    ANNOUNCEMENT_FILTER_BY_DATE: createAction('ANNOUNCEMENT_FILTER_BY_DATE'),
    ANNOUNCEMENT_FILTER_BY_ALL: createAction('ANNOUNCEMENT_FILTER_BY_ALL'),
    ANNOUNCEMENT_FILTER_BY_LABEL: createAction('ANNOUNCEMENT_FITLER_BY_LABEL'),
  },
  DEPARTMENT: {
    GETS: createAction('COMMUNICATE_DEPARTMENT_GETS'),
  }
};

export const COMMON = {
  UPLOAD_FILE: createAction('COMMON_UPLOAD_FILE'),
  UPDATE_FILE: createAction('COMMON_UPDATE_FILE'),
  DELETE_FILE: createAction('COMMON_DELETE_FILE'),
  GET_FILE_BY_ID: createAction('COMMON_GET_FILE_BY_ID'),
};

export const DEPARTMENT = {
  GETS: createAction('DEPARTMENT_GETS'),
};

export const DOCUMENT = {
  INIT: createAction('DOCUMENT_INIT'),
  GETS: createAction('DOCUMENT_GETS'),
  GETS_IN_MOVE_TO_BOX: createAction('DOCUMENT_GETS_IN_MOVE_TO_BOX'),
  ADD: createAction('DOCUMENT_ADD'),
  UPDATE: createAction('DOCUMENT_UPDATE'),
  DELETE: createAction('DOCUMENT_DELETE'),
  DEPARTMENT: {
    GETS: createAction('DOCUMENT_DEPARTMENT_GETS'),
  },
};

export const FILE = {
  DOWNLOAD: createAction('FILE_DOWNLOAD'),
};

export const ICON = {
  GETS: createAction('ICON_GETS'),
};

export const FORM_RESIDENT = {
  INIT: createAction('FORM_RESIDENT_INIT'),
  SEARCH_APARTMENT: createAction('FORM_RESIDENT_SEARCH_APARTMENT'),
};

export const FORM_STAFF = {
  INIT: createAction('FORM_STAFF_INIT'),
  DEPARTMENT: {
    GETS: createAction('FORM_STAFF_DEPARTMENT_GETS'),
  },
};

export const FORM_SERVICE = {
  INIT: createAction('FORM_SERVICE_INIT'),
  CONTRACT_TEMPLATE: {
    FORMULA_GETS: createAction('FORM_SERVICE_CONTRACT_TEMPLATE_FORMULA_GETS'),
    SURCHARGE_GETS: createAction('FORM_SERVICE_CONTRACT_TEMPLATE_SURCHARGE_GETS'),
    PROMOTION_GETS: createAction('FORM_SERVICE_CONTRACT_TEMPLATE_PROMOTION_GETS'),
  },
};

export const FORM_CONFIRM_SERVICE = {
  INIT: createAction('FORM_CONFIRM_SERVICE_INIT'),
};

export const FORM_PAYSLIP = {
  INIT: createAction('FORM_PAYSLIP_INIT'),
  SEARCH_APARTMENT: createAction('FORM_PAYSLIP_SEARCH_APARTMENT'),
  RECEIVER_SUGGESTIONS: createAction('FORM_PAYSLIP_RECEIVER_SUGGESTIONS'),
  FUND: {
    GETS: createAction('FORM_PAYSLIP_FUND_GETS'),
  },
  TAG: {
    GETS: createAction('FORM_PAYSLIP_TAG_GETS'),
  },
};

export const FORM_RECEIPT = {
  INIT: createAction('FORM_RECEIPT_INIT'),
  SEARCH_APARTMENT: createAction('FORM_RECEIPT_SEARCH_APARTMENT'),
  PAYER_SUGGESTIONS: createAction('FORM_RECEIPT_PAYER_SUGGESTIONS'),
  FUND: {
    GETS: createAction('FORM_RECEIPT_FUND_GETS'),
  },
  TAG: {
    GETS: createAction('FORM_RECEIPT_TAG_GETS'),
  },
};

export const FORM_DOCUMENT = {
  SEARCH_APARTMENT: createAction('FORM_DOCUMENT_SEARCH_APARTMENT'),
};

export const SERVICE = {
  GETS: createAction('SERVICE_GETS'),
  CONTRACT: {
    GETS: createAction('SERVICE_CONTRACT_GETS'),
    ADDS: createAction('SERVICE_CONTRACT_ADDS'),
    ADD: createAction('SERVICE_CONTRACT_ADD'),
    DELETE: createAction('SERVICE_CONTRACT_DELETE'),
    UPDATE: createAction('SERVICE_CONTRACT_UPDATE'),
    ACTIVE: createAction('SERVICE_CONTRACT_ACTIVE'),
    INACTIVE: createAction('SERVICE_CONTRACT_INACTIVE'),
    ACTIVE_MULTI: createAction('SERVICE_CONTRACT_ACTIVE_MULTI'),
    INACTIVE_MULTI: createAction('SERVICE_CONTRACT_INACTIVE_MULTI'),
    IMPORT: createAction('SERVICE_CONTRACT_IMPORT'),
    EXPORT: createAction('SERVICE_CONTRACT_EXPORT'),
    SUM_TOTAL: createAction('SERVICE_CONTRACT_SUM_TOTAL'),
  },
  CONTRACT_TEMPLATE: {
    GETS: createAction('SERVICE_CONTRACT_TEMPLATE_GETS'),
  },
  UNIT: {
    GETS: createAction('SERVICE_UNIT_GETS'),
  },
  DOWNLOAD_TEMPLATE: createAction('SERVICE_DOWNLOAD_TEMPLATE'),
};

export const FORM_CONTRACT_TEMPLATE = {
  INIT: createAction('FORM_CONTRACT_TEMPLATE_INIT'),
  SERVICE_GETS: createAction('FORM_CONTRACT_TEMPLATE_SERVICE_GETS'),
};

export const CONTRACT_TEMPLATE = {
  INIT: createAction('CONTRACT_TEMPLATE_INIT'),
  ADD: createAction('CONTRACT_TEMPLATE_ADD'),
  GETS: createAction('CONTRACT_TEMPLATE_GETS'),
  DELETE: createAction('CONTRACT_TEMPLATE_DELETE'),
  UPDATE: createAction('CONTRACT_TEMPLATE_UPDATE'),
  SERVICE_GETS: createAction('CONTRACT_TEMPLATE_SERVICE_GETS'),
};

export const BLOCK = {
  GETS: createAction('BLOCK_GETS'),
};

export const FORMULA = {
  ADD: createAction('FORMULA_ADD'),
};

export const SURCHARGE = {
  ADD: createAction('SURCHARGE_ADD'),
};

export const INVOICE = {
  INIT: createAction('INVOICE_INIT'),
  CONTRACT: {
    GETS: createAction('INVOICE_CONTRACT_GETS'),
    SUM_PAYMENT_GETS: createAction('INVOICE_CONTRACT_SUM_PAYMENT_GETS'),
    CHECKING_ALL_GETS: createAction('INVOICE_CONTRACT_CHECKING_ALL_GETS'),
  },
  BILL: {
    GETS: createAction('INVOICE_BILL_GETS'),
    CREATE_AUTO: createAction('INVOICE_BILL_CREATE_AUTO'),
    CREATE_MANUAL: createAction('INVOICE_BILL_CREATE_MANUAL'),
    DELETE_MULTI: createAction('INVOICE_BILL_DELETE_MULTI'),
    SUM_PAYMENT_GETS: createAction('INVOICE_BILL_SUM_PAYMENT_GETS'),
    UPDATE_INTEREST: createAction('INVOICE_BILL_UPDATE_INTEREST'),
    CHECKING_ALL_GETS: createAction('INVOICE_BILL_CHECKING_ALL_GETS'),
  },
  PREVIEW: createAction('INVOICE_PREVIEW_INVOICE'),
  SERVICE: {
    GETS: createAction('INVOICE_SERVICE_GETS'),
  },
  RESEND_EMAIL: createAction('INVOICE_RESEND_EMAIL'),
  SEND_EMAIL: createAction('INVOICE_SEND_EMAIL'),
  PRINT: createAction('INVOICE_PRINT'),
  REPRINT: createAction('INVOICE_REPRINT'),
  GETS: createAction('INVOICE_GETS'),
  GET_BY_ID: createAction('INVOICE_GET_BY_ID'),
  CHECKING_ALL_GETS: createAction('INVOICE_CHECKING_ALL_GETS'),
  PRINT_DEBT: createAction('INVOICE_PRINT_DEBT'),
  PREVIEW_DEBT: createAction('INVOICE_PREVIEW_DEBT'),
  SEND_EMAIL_DEBT: createAction('INVOICE_SEND_EMAIL_DEBT'),
};

export const PAYMENT = {
  RESET_PAGE: createAction('PAYMENT_RESET_PAGE'),
  INIT: createAction('PAYMENT_INIT'),
  FLOOR: {
    GETS: createAction('PAYMENT_FLOOR_GETS'),
  },
  BILL: {
    GETS: createAction('PAYMENT_BILL_GETS'),
    SUM_PAYMENT_GETS: createAction('PAYMENT_BILL_SUM_PAYMENT_GETS'),
  },
  RECEIPT: {
    CREATE: createAction('PAYMENT_RECEIPT_CREATE'),
  },
  FUND: {
    GETS: createAction('PAYMENT_FUND_GETS'),
  },
  TAG: {
    GETS: createAction('PAYMENT_TAG_GETS'),
  },
};

export const FORM_SERVICE_REGISTER = {
  SEARCH_TARGET: createAction('FORM_SERVICE_REGISTER_SEARCH_TARGET'),
  SEARCH_RESIDENT: createAction('FORM_SERVICE_REGISTER_SEARCH_RESIDENT'),
  SEARCH_UNIT: createAction('FORM_SERVICE_REGISTER_SEARCH_UNIT'),
};

export const ENTERPRISE = {
  ADD: createAction('ENTERPRISE_ADD'),
  UPDATE: createAction('ENTERPRISE_UPDATE'),
  DELETE: createAction('ENTERPRISE_DELETE'),
  GETS: createAction('ENTERPRISE_GETS'),
};

export const FORM_TARGET_INFO = {
  INIT_ADD: createAction('FORM_TARGET_INFO_INIT_ADD'),
  INIT_UPDATE: createAction('FORM_TARGET_INFO_INIT_UPDATE'),
  GETS: createAction('FORM_TARGET_INFO_GETS'),
  GETS_TARGET_WHEN_CHECK_ALL: createAction('FORM_TARGET_INFO_GETS_TARGET_WHEN_CHECK_ALL'),
  RESET_DATA_SELECTED: createAction('FORM_TARGET_INFO_RESET_DATA_SELECTED'),
};

export const STATISTIC = {
  INIT: createAction('STATISTIC_INIT'),
  CASH_BOOK: {
    INIT: createAction('STATISTIC_CASH_BOOK_INIT'),
    GETS: createAction('STATISTIC_CASH_BOOK_GETS'),
    EXPORT: createAction('STATISTIC_CASH_BOOK_EXPORT'),
  },
  SERVICE_CHARGE: {
    GETS: createAction('STATISTIC_SERVICE_CHARGE_GETS'),
    EXPORT: createAction('STATISTIC_SERVICE_CHARGE_EXPORT'),
    GETS_APARTMENT_USE_SERVICE: createAction('STATISTIC_SERVICE_CHARGE_GETS_APARTMENT_USE_SERVICE'),
  },
  PAY_SLIP: {
    GETS: createAction('STATISTIC_PAY_SLIP_GETS'),
    ADD: createAction('STATISTIC_PAY_SLIP_ADD'),
    UPDATE: createAction('STATISTIC_PAY_SLIP_UPDATE'),
    CANCEL: createAction('STATISTIC_PAY_SLIP_CANCEL'),
    EXPORT: createAction('STATISTIC_PAY_SLIP_EXPORT'),
    GET_PREVIEW: createAction('STATISTIC_PAY_SLIP_GET_PREVIEW'),
    SUM_TOTAL_PAYMENT: createAction('STATISTIC_PAY_SLIP_SUM_TOTAL_PAYMENT'),
  },
  RECEIPT: {
    INIT: createAction('STATISTIC_RECEIPT_INIT'),
    GETS: createAction('STATISTIC_RECEIPT_GETS'),
    GET_BY_ID: createAction('STATISTIC_RECEIPT_GET_BY_ID'),
    EXPORT: createAction('STATISTIC_RECEIPT_EXPORT'),
    ADD: createAction('STATISTIC_RECEIPT_ADD'),
    UPDATE: createAction('STATISTIC_RECEIPT_UPDATE'),
    CANCEL: createAction('STATISTIC_RECEIPT_CANCEL'),
    SUM_TOTAL_PAYMENT: createAction('STATISTIC_RECEIPT_SUM_TOTAL_PAYMENT'),
  },
  ACCOUNT: {
    GETS: createAction('STATISTIC_ACCOUNT_GETS'),
    EXPORT: createAction('STATISTIC_ACCOUNT_EXPORT'),
    SUM_TOTAL_PAYMENT: createAction('STATISTIC_ACCOUNT_SUM_TOTAL_PAYMENT'),
    HISTORY: createAction('STATISTIC_ACCOUNT_HISTORY'),
  },
  DEBT: {
    INIT: createAction('STATISTIC_DEBT_INIT'),
    GETS: createAction('STATISTIC_DEBT_GETS'),
    EXPORT: createAction('STATISTIC_DEBT_EXPORT'),
    SUM_TOTAL_PAYMENT: createAction('STATISTIC_DEBT_SUM_TOTAL_PAYMENT'),
  },
  SERVICE: {
    GETS: createAction('STATISTIC_SERVICE_GETS'),
  },
  CONTRACT_TEMPLATE: {
    GETS: createAction('STATISTIC_CONTRACT_TEMPLATE_GETS'),
  },
  FUND: {
    GETS: createAction('STATISTIC_FUND_GETS'),
  },
  TAG: {
    GETS: createAction('STATISTIC_TAG_GETS'),
  },
  RESIDENT: {
    GETS: createAction('STATISTIC_RESIDENT_GETS'),
  },
  REPORT_SERVICE: {
    GETS: createAction('STATISTIC_REPORT_SERVICE_GETS'),
    EXPORT: createAction('STATISTIC_REPORT_SERVICE_EXPORT'),
    SUM_PAYMENT: createAction('STATISTIC_REPORT_SERVICE_SUM_PAYMENT'),
  },
  PAYMENT_ONLINE: {
    GETS: createAction('STATISTIC_PAYMENT_ONLINE_GETS'),
  },
};

export const REPORT = {
  INIT: createAction('REPORT_INIT'),
  LABEL_GETS: createAction('REPORT_LABEL_GETS'),
  SERVICE_GETS: createAction('REPORT_SERVICE_GETS'),
  DEBT: {
    GETS: createAction('REPORT_DEBT_GETS'),
    GET_TOTAL: createAction('REPORT_DEBT_GET_TOTAL'),
    EXPORT: createAction('REPORT_DEBT_EXPORT'),
  },
  ADVANCE_PAYMENT: {
    GETS: createAction('REPORT_ADVANCE_PAYMENT_GETS'),
    GET_TOTAL: createAction('REPORT_ADVANCE_PAYMENT_GET_TOTAL'),
    EXPORT: createAction('REPORT_ADVANCE_PAYMENT_EXPORT'),
  },
  INVESTOR: {
    GETS: createAction('REPORT_INVESTOR_GETS'),
    GET_TOTAL: createAction('REPORT_INVESTOR_GET_TOTAL'),
    EXPORT: createAction('REPORT_INVESTOR_EXPORT'),
  },
  MUST_PAY: {
    GETS: createAction('REPORT_MUST_PAY_GETS'),
    GET_TOTAL: createAction('REPORT_MUST_PAY_GET_TOTAL'),
    EXPORT: createAction('REPORT_MUST_PAY_EXPORT'),
  },
  OPERATING: {
    GETS: createAction('REPORT_OPERATING_GETS'),
    EXPORT: createAction('REPORT_OPERATING_EXPORT'),
  },
};

export const PROFILE = {
  GETS: createAction('PROFILE_GETS'),
  UPDATE: createAction('PROFILE_UPDATE'),
  PASSWORD_UPDATE: createAction('PROFILE_PASSWORD_UPDATE'),
};

export const FIREBASE = {
  FCM: {
    SEND_DEVICE_TOKEN: createAction('FIREBASE_FCM_SEND_DEVICE_TOKEN'),
  },
};

export const ACTIVITY = {
  LOG: {
    GETS: createAction('ACTIVITY_LOG_GETS'),
  },
  MANAGER: {
    GETS: createAction('ACTIVITY_MANAGER_GETS'),
  },
  EXPORT: {
    GETS: createAction('ACTIVITY_EXPORT_GETS'),
  },
};

export const DASHBOARD = {
  OVERVIEW: {
    GETS: createAction('DASHBOARD_OVERVIEW_GETS'),
  },
  REVENUE: {
    GETS: createAction('DASHBOARD_REVENUE_GETS'),
  },
  TOTAL_PAID_UNPAID: {
    GETS: createAction('DASHBOARD_TOTAL_PAID_UNPAID_GETS'),
  },
  RESIDENT: {
    GETS: createAction('DASHBOARD_RESIDENT_GETS'),
  },
  SERVICE: {
    GETS: createAction('DASHBOARD_SERVICE_GETS'),
  },
  VEHICLE: {
    GETS: createAction('DASHBOARD_VEHICLE_GETS'),
  },
  BUILDING: {
    GETS: createAction('DASHBOARD_BUILDING_GETS'),
  }
};

export const NEWS = {
  GETS: createAction('NEWS_GETS'),
  ADD: createAction('NEWS_ADD'),
  DELETE: createAction('NEWS_DELETE'),
  GET_BY_ID: createAction('NEWS_GET_BY_ID'),
  INVESTOR: {
    GETS: createAction('NEWS_INVESTOR_GETS'),
  },
  BUILDING: {
    GETS: createAction('NEWS_BUILDING_GETS'),
  },
};

export const FORM_ASSET = {
  INIT: createAction('FORM_ASSET_INIT'),
  GET_MAINTENANCE_UNIT: createAction('FORM_ASSET_GET_MAINTENANCE_UNIT'),
  GET_DEPARTMENT: createAction('FORM_ASSET_GET_DEPARTMENT'),
  GET_SUPPLY: createAction('FORM_ASSET_GET_SUPPLY'),
  GET_UNIT: createAction('FORM_ASSET_GET_UNIT'),
  GET_FLOOR: createAction('FORM_ASSET_GET_FLOOR'),
};
export const CARD = {
  GETS: createAction('CARD_GETS'),
  ADD: createAction('CARD_ADD'),
  UPDATE: createAction('CARD_UPDATE'),
  DELETE: createAction('CARD_DELETE'),
  IMPORT: createAction('CARD_IMPORT'),
  EXPORT: createAction('CARD_EXPORT'),
  HISTORY: createAction('CARD_HISTORY'),
  HISTORY_USAGE: createAction('CARD_HISTORY_USAGE'),
  UPDATE_STATUS: createAction('CARD_UPDATE_STATUS'),
  UNIT: {
    GETS: createAction('UNIT_GETS'),
  },
  SUGGEST: {
    GETS: createAction('CARD_SUGGEST_GETS'),
    UPDATE_CARD_UNIT: 'CARD_SUGGEST_UPDATE_CARD_UNIT',
  },
  REGISTER_ADD: createAction('CARD_REGISTER_ADD'),
  REGISTER_RESIDENT_GETS: createAction('CARD_REGISTER_RESIDENT_GETS'),
  REGISTER_COUNTRY_CODE_GETS: createAction('CARD_REGISTER_COUNTRY_CODE_GETS'),
  REGISTER_VEHICLE_GETS: createAction('REGISTER_VEHICLE_GETS'),
  REGISTER_SERVICE_GETS: createAction('REGISTER_SERVICE_GETS'),
  REGISTER_GETS: createAction('CARD_REGISTER_GETS'),
  REGISTER_DELETE: createAction('CARD_REGISTER_DELETE'),
  REGISTER_PREVIEW: createAction('CARD_REGISTER_PREVIEW'),
  REGISTER_PRINT: createAction('CARD_REGISTER_PRINT'),
  REGISTER_REPRINT: createAction('CARD_REGISTER_REPRINT'),
  REGISTER_CONTRACT_TEMPLATE_GETS: createAction('CARD_REGISTER_CONTRACT_TEMPLATE_GETS'),
  REGISTER_EXPORT: createAction('CARD_REGISTER_EXPORT'),
};

export const REQUEST = {
  REQUEST_GET: createAction('REQUEST_GET'),
  REQUEST_GETS: createAction('REQUEST_GETS'),
  REQUEST_ADD: createAction('REQUEST_ADD'),
  REQUEST_UPDATE: createAction('REQUEST_UPDATE'),
  REQUEST_DUPLICATE: createAction('REQUEST_DUPLICATE'),
  REQUEST_EXPORT_DATA: createAction('REQUEST_EXPORT_DATA'),
  REQUEST_CANCEL: createAction('REQUEST_CANCEL'),
  REQUEST_APPROVE: createAction('REQUEST_APPROVE'),
  REQUEST_DENY: createAction('REQUEST_DENY'),
  REQUEST_CHANGE_PROGRESS: createAction('REQUEST_CHANGE_PROGRESS'),

  REQUEST_TASK_GETS: createAction('REQUEST_TASK_GETS'),
  REQUEST_TASK_ADD: createAction('REQUEST_TASK_ADD'),
  REQUEST_TASK_UPDATE: createAction('REQUEST_TASK_UPDATE'),
  REQUEST_TASK_APPROVAL: createAction('REQUEST_TASK_APPROVAL'),
  REQUEST_TASK_DELETE: createAction('REQUEST_TASK_DELETE'),
  REQUEST_TASK_CHANGE_PROGRESS: createAction('REQUEST_TASK_CHANGE_PROGRESS'),

  REQUEST_ACTIVITY_LOG: createAction('REQUEST_ACTIVITY_LOG'),
  REQUEST_TIMELINE: createAction('REQUEST_TIMELINE'),

  REQUEST_EMPLOYEES_GETS: createAction('RREQUEST_EMPLOYEES_GETS'),
  REQUEST_DEPARTMENT_GETS: createAction('REQUEST_DEPARTMENT_GETS'),
  REQUEST_APARTMENT_GETS: createAction('REQUEST_APARTMENT_GETS'),
  REQUEST_TOTAL_TAB_GETS: createAction('REQUEST_TOTAL_TAB_GETS'),
};

export const MESSAGE_NOTICE = {
  COUNT_BADGES: createAction('MESSAGE_NOTICE_COUNT_BADGES'),
  COUNT_BADGES_REQUEST: createAction('NOTICE_COUNT_BADGES_REQUEST'),
  COUNT_BADGES_UTILITY_BOOKING: createAction('NOTICE_COUNT_BADGES_UTILITY_BOOKING'),
};

export const RESIDENT_UTILITY = {
  UTILITY_GETS: createAction('UTILITY_GETS'),
  UTILITY_DETAIL: createAction('UTILITY_DETAIL'),
  UTILITY_ADD: createAction('UTILITY_ADD'),
  UTILITY_UPDATE: createAction('UTILITY_UPDATE'),
  UTILITY_DELETE: createAction('UTILITY_DELETE'),
  UTILITY_CHANGE_STATUS: createAction('UTILITY_CHANGE_STATUS'),
  ICON_GETS: createAction('UTILITY_ICON_GETS'),
};
