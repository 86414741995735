import { X_UNIT_GROUP_ID } from '../../constants';
import {
    exportAccountReportService, getAccountHistoryService,
    getDataAccountReportService, getSumTotalPaymentListApartmentWithBalanceService,
} from 'services/cashbook';
import { STATISTIC } from '../../constants/actionType';
import { call, put } from 'redux-saga/effects';

export function* getDataAccountReport(action) {
    const {
        unitGroupId,
        filterObject,
        page,
    } = action.payload;

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const params = {
        ...filterObject,
        page,
        limit: 20,
    };

    const result = yield call(getDataAccountReportService, { params, headers }, STATISTIC.ACCOUNT.GETS);
    yield put(result);
}

export function* exportAccountInPageReport(action) {
    const {
        unitGroupId,
        filterObject,
    } = action.payload;
    const params = {
        ...filterObject,
    };
    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };
    const result = yield call(exportAccountReportService, { params, headers }, STATISTIC.ACCOUNT.EXPORT);
    yield put(result);
    yield put({
        type: STATISTIC.ACCOUNT.EXPORT.REFRESH,
    });
}


export function* getSumTotalPaymentListApartmentWithBalance(action) {
    const {
        unitGroupId,
        filterObject,
    } = action.payload;

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const params = {
        ...filterObject
    };

    const result = yield call(getSumTotalPaymentListApartmentWithBalanceService, { params, headers }, STATISTIC.ACCOUNT.SUM_TOTAL_PAYMENT);
    yield put(result);
}

export function* getAccountHistory(action) {
    const {
        unitGroupId,
        unitId,
        page,
        filterObject,
    } = action.payload;

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const params = {
        ...filterObject,
        page,
        limit: 20,
    };

    const result = yield call(getAccountHistoryService, { params, headers, unitId }, STATISTIC.ACCOUNT.HISTORY);
    yield put(result);
}
