import React, { useState, useEffect } from 'react';
import Button from 'components/Button';
import Typography from 'components/Typography';
import { mergeArr } from 'shared/utils';
import { language } from 'defined/Language';
import { difference, find, intersection } from 'lodash';

const ButtonSelectAllRow = (props) => {
  const { isShow, isSelectAll, totalItemSelected, totalItem, onSelectAllItemTable, textSelectRow, textSelectAllRow } = props;
  const component = (
    <Typography.Text>
      {!isSelectAll
        ? textSelectRow.replace('{totalItemSelected}', totalItemSelected).replace('{totalItem}', totalItem)
        : textSelectRow.replace('{totalItemSelected}', totalItem).replace('{totalItem}', totalItem)}
      {totalItemSelected > 0 && (
        <Button
          style={{
            padding: '0 0 0 5px',
            height: 0,
            border: 0,
          }}
          type="link"
          onClick={() => onSelectAllItemTable(isShow && !isSelectAll)}
        >
          {totalItem === totalItemSelected
            ? ''
            : (isShow && !isSelectAll && textSelectAllRow.replace('{totalItem}', totalItem)) || language.description.deselect_all_rows}
        </Button>
      )}
    </Typography.Text>
  );
  return component;
};

export default function useTableCheckbox(props) {
  const { dataRows, rowKey, totalRows } = props;

  const [tableSelection, setDataTableSelection] = useState({
    isSelectAllItem: false,
    isShowQuestionSelectAll: false,
    selectedRowsCache: [],
    selectedRowKeysCache: [],
    selectedRowKeys: [],
    byId: {},
  });
  const [leafRelation, setLeftRelation] = useState({});
  const [nodeRelation, setNodeRelation] = useState({});

  const buildStructureData = (data, rowKeyBuild = 'key', byIdDefault = {}) => {
    const result = {};
    const byId = byIdDefault;
    data.forEach((item) => {
      const stack = [];
      stack.push(item);
      byId[item[rowKeyBuild]] = item;
      let node;
      while (stack.length) {
        node = stack.pop();
        if (!node.children) {
          result[node[rowKeyBuild]] = [...stack.map((i) => i[rowKeyBuild])];
        } else {
          const nextNode = find(node.children, (child) => !result[child[rowKeyBuild]]);
          if (nextNode) {
            stack.push(node);
            stack.push(nextNode);
            byId[node[rowKeyBuild]] = node;
            byId[nextNode[rowKeyBuild]] = nextNode;
          } else {
            stack.pop();
          }
        }
      }
    });

    const parent = {};
    Object.keys(result).forEach((key) => {
      result[key].forEach((item) => {
        if (!parent[item]) {
          parent[item] = [];
        }
        parent[item].push(key);
      });
    });
    return [result, parent, byId];
  };

  useEffect(() => {
    const [leafRelationNew, nodeRelationNew, byId] = buildStructureData(dataRows, rowKey, tableSelection.byId);
    const selectedRowKeys = dataRows
      .filter((dataRow) => tableSelection.selectedRowKeysCache.includes(dataRow[rowKey]))
      .reduce((result, item) => {
        const children = nodeRelationNew[item[rowKey]];
        return [...result, item[rowKey], ...children];
      }, []);
    // .map((dataRow) => dataRow[rowKey]);
    setLeftRelation(leafRelationNew);
    setNodeRelation(nodeRelationNew);
    setDataTableSelection({
      ...tableSelection,
      selectedRowKeys,
      byId,
    });
  }, [dataRows]);

  const resetDataTableSelection = () => {
    setDataTableSelection({
      isSelectAllItem: false,
      isShowQuestionSelectAll: false,
      selectedRowsCache: [],
      selectedRowKeysCache: [],
      selectedRowKeys: [],
      byId: tableSelection.byId ?? {},
    });
    // setLeftRelation({});
    // setNodeRelation({});
  };

  const isLeaf = (record) => {
    return Boolean(leafRelation[record.key]);
  };

  const onSelectRow = (record, selected, selectedRows = [], nativeEvent) => {
    let selectedRowKeys = selectedRows
      .map((selectedRow) => {
        if (selectedRow) return selectedRow.key;
        return null;
      })
      .filter(Boolean);
    const isRecordLeaf = isLeaf(record);
    if (selected) {
      tableSelection.selectedRowKeysCache.push(record.key);
      const dataRowFilters = dataRows.filter((dataRow) => dataRow[rowKey] === record.key);
      if (dataRowFilters.length > 0) {
        tableSelection.selectedRowsCache.push(dataRowFilters[0]);
      }

      if (isRecordLeaf) {
        leafRelation[record.key].forEach((nodeKey) => {
          if (intersection(nodeRelation[nodeKey], selectedRowKeys).length === nodeRelation[nodeKey].length) {
            selectedRowKeys.push(nodeKey);
            tableSelection.selectedRowKeysCache.push(nodeKey);
            tableSelection.selectedRowsCache.push(nodeKey);
          }
        });
      } else {
        selectedRowKeys = selectedRowKeys.concat(nodeRelation[record.key]);
        tableSelection.selectedRowKeysCache = tableSelection.selectedRowKeysCache.concat(nodeRelation[record.key]);
        tableSelection.selectedRowsCache = tableSelection.selectedRowsCache.concat(nodeRelation[record.key]);
      }
    } else {
      tableSelection.selectedRowKeysCache = tableSelection.selectedRowKeysCache.filter(
        (selectedRowKeyCache) => selectedRowKeyCache !== record.key
      );
      tableSelection.selectedRowsCache = tableSelection.selectedRowsCache.filter(
        (selectedRowCache) => selectedRowCache[rowKey] !== record.key
      );

      if (isRecordLeaf) {
        selectedRowKeys = difference(selectedRowKeys, leafRelation[record.key]);
        tableSelection.selectedRowKeysCache = difference(tableSelection.selectedRowKeysCache, leafRelation[record.key]);
        tableSelection.selectedRowsCache = difference(tableSelection.selectedRowsCache, leafRelation[record.key]);
      } else {
        selectedRowKeys = difference(selectedRowKeys, nodeRelation[record.key]);
        tableSelection.selectedRowKeysCache = difference(tableSelection.selectedRowKeysCache, nodeRelation[record.key]);
        tableSelection.selectedRowsCache = difference(tableSelection.selectedRowsCache, nodeRelation[record.key]);
      }
    }
    setDataTableSelection({
      ...tableSelection,
      isSelectAllItem: false,
      isShowQuestionSelectAll: false,
      selectedRowsCache: tableSelection.selectedRowsCache,
      selectedRowKeysCache: tableSelection.selectedRowKeysCache,
      selectedRowKeys,
    });
  };

  const onSelectAllRowOfPage = (selected, selectedRows) => {
    const selectedRowKeys = selectedRows.filter(Boolean).map((selectedRow) => selectedRow.key);
    let selectedRowKeysCache = [];
    if (selected) {
      selectedRowKeysCache = mergeArr(tableSelection.selectedRowKeysCache, selectedRowKeys);
      tableSelection.selectedRowsCache = tableSelection.selectedRowsCache.concat(dataRows);
    } else {
      selectedRowKeysCache = tableSelection.selectedRowKeysCache.filter(
        (selectedRowKeyCache) => !tableSelection.selectedRowKeys.includes(selectedRowKeyCache)
      );
      tableSelection.selectedRowsCache = tableSelection.selectedRowsCache.filter(
        (selectedRowCache) => !tableSelection.selectedRowKeys.includes(selectedRowCache[rowKey])
      );
    }
    setDataTableSelection({
      ...tableSelection,
      isShowQuestionSelectAll: selected,
      isSelectAllItem: false,
      selectedRowsCache: tableSelection.selectedRowsCache,
      selectedRowKeysCache,
      selectedRowKeys,
    });
  };

  const onSelectAllRow = (selected) => {
    if (!selected) {
      setDataTableSelection({
        ...tableSelection,
        isSelectAllItem: false,
        isShowQuestionSelectAll: false,
        selectedRowsCache: [],
        selectedRowKeysCache: [],
        selectedRowKeys: [],
      });
    } else {
      setDataTableSelection({
        ...tableSelection,
        isSelectAllItem: true,
      });
    }
  };

  const selectAllRowComponent = (propsSelectAll = {}) => {
    const {
      totalItemSelected = tableSelection.selectedRowKeysCache.length,
      textSelectRow = language.description.you_selected_rows,
      textSelectAllRow = language.description.select_all_rows,
    } = propsSelectAll;

    return (
      <ButtonSelectAllRow
        isShow={tableSelection.isShowQuestionSelectAll}
        isSelectAll={tableSelection.isSelectAllItem}
        totalItemSelected={totalItemSelected}
        totalItem={totalRows}
        textSelectRow={textSelectRow}
        textSelectAllRow={textSelectAllRow}
        onSelectAllItemTable={onSelectAllRow}
      />
    );
  };

  return [tableSelection, resetDataTableSelection, onSelectRow, onSelectAllRowOfPage, selectAllRowComponent];
}
