import { call, put } from 'redux-saga/effects';
import { RESIDENT_UTILITY } from '../../constants/actionType';
import {
  getUtilityService,
  getUtilityDetailService,
  addUtilityService,
  updateUtilityService,
  deleteUtilityService,
  changeStatusUtilityService,
} from '../../services/residentUltility';
import { getIcons } from '../../services/serviceSystem';
import { pick } from 'lodash';

export function* getUtility({ payload }) {
  const filters = {
    status: payload.status,
    keyword: payload.name,
    page: payload.page,
    limit: payload.limit,
  };

  const result = yield call(getUtilityService, payload.idBuilding, filters, RESIDENT_UTILITY.UTILITY_GETS);

  yield put(result);
  yield put({ type: RESIDENT_UTILITY.UTILITY_GETS.REFRESH });
}

export function* getUtilityDetail({ payload }) {
  const result = yield call(getUtilityDetailService, payload.idBuilding, payload.id, RESIDENT_UTILITY.UTILITY_DETAIL);

  yield put(result);
}

export function* addUtility({ payload }) {
  const params = pick(payload, ['name', 'enName', 'description', 'identifier', 'measureUnit', 'formula', 'icon']);

  // enName is not required but unique. Set to null for checking unique on server.
  if (params.enName === '') params.enName = null;

  const result = yield call(addUtilityService, payload.idBuilding, params, RESIDENT_UTILITY.UTILITY_ADD);

  yield put(result);
  yield put({
    type: RESIDENT_UTILITY.UTILITY_ADD.REFRESH,
  });
}

export function* updateUtility({ payload }) {
  const params = pick(payload, ['id', 'name', 'enName', 'description', 'identifier', 'measureUnit', 'formula', 'icon']);

  // enName is not required but is unique value. Set to null if empty string for checking unique on server.
  if (params.enName === '') params.enName = null;

  const result = yield call(updateUtilityService, payload.idBuilding, params, RESIDENT_UTILITY.UTILITY_UPDATE);

  yield put(result);
  yield put({
    type: RESIDENT_UTILITY.UTILITY_UPDATE.REFRESH,
  });
}

export function* deleteUtility({ payload }) {
  const result = yield call(deleteUtilityService, payload.idBuilding, payload.id, RESIDENT_UTILITY.UTILITY_DELETE);

  yield put(result);
  yield put({
    type: RESIDENT_UTILITY.UTILITY_DELETE.REFRESH,
  });
}

export function* changeStatusUtility({ payload }) {
  const data = pick(payload, ['id', 'status']);

  const result = yield call(changeStatusUtilityService, payload.idBuilding, data, RESIDENT_UTILITY.UTILITY_CHANGE_STATUS);

  yield put(result);
  yield put({
    type: RESIDENT_UTILITY.UTILITY_CHANGE_STATUS.REFRESH,
  });
}

export function* getIconList() {
  const result = yield call(getIcons, RESIDENT_UTILITY.ICON_GETS);

  yield put(result);
}
