import { STATISTIC_RECEIPT } from './receipt.types';

export const getReceipts = (data) => ({
  type: STATISTIC_RECEIPT.GETS.REQUEST,
  payload: data,
});

export const getReceiptById = ({ unitGroupId, receiptId }) => ({
  type: STATISTIC_RECEIPT.GET_BY_ID.REQUEST,
  payload: {
    unitGroupId,
    receiptId,
  },
});

export const resetReceiptById = () => ({
  type: STATISTIC_RECEIPT.GET_BY_ID.SUCCESS,
  payload: {
    isFetching: false,
    data: '',
    code: undefined,
    codeLanguage: undefined,
  },
});

export const exportReceipt = ({ unitGroupId, filterObject }) => ({
  type: STATISTIC_RECEIPT.EXPORT.REQUEST,
  payload: {
    unitGroupId,
    filterObject,
  },
});

export const createReceiptManual = ({ receipt, unitGroupId }) => ({
  type: STATISTIC_RECEIPT.ADD.REQUEST,
  payload: {
    receipt,
    unitGroupId,
  },
});

export const updateReceipt = ({ receipt, unitGroupId }) => ({
  type: STATISTIC_RECEIPT.UPDATE.REQUEST,
  payload: {
    receipt,
    unitGroupId,
  },
});

export const cancelReceipt = ({ receiptId, type, reason, fundId, isAllBill, bills }, unitGroupId) => ({
  type: STATISTIC_RECEIPT.CANCEL.REQUEST,
  payload: {
    receiptId,
    type,
    reason,
    fundId,
    isAllBill,
    bills,
    unitGroupId,
  },
});

export const getSumTotalPaymentReceipt = ({ unitGroupId, filterObject }) => ({
  type: STATISTIC_RECEIPT.SUM_TOTAL_PAYMENT.REQUEST,
  payload: {
    unitGroupId,
    filterObject,
  },
});

export const getUnitInReceiptForm = (data) => ({
  type: STATISTIC_RECEIPT.GET_UNITS_IN_RECEIPT_FORM.REQUEST,
  payload: data,
});
