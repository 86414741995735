import { handleError, handleResponse } from 'shared/utils';
import { callApi, downloadFile } from './api';
import {
  AMENITIES_GETS,
  AMENITIES_GET,
  AMENITIES_ADD,
  AMENITIES_UPDATE,
  AMENITIES_ACTIVE,
  AMENITIES_INACTIVE,
  AMENITIES_GET_PRIORITY_IN_APP_SELECTED,
  AMENITIES_ORDER_GETS,
  AMENITIES_ORDER_EXPORT,
  AMENITIES_ORDER_APPROVE,
  AMENITIES_ORDER_REJECT,
  AMENITIES_ORDER_GET,
  AMENITIES_ORDER_PAYMENT,
  AMENITIES_ORDER_REFUND,
  AMENITIES_ORDER_REFUND_DEPOSITS,
  AMENITY_MERCHANT_ORDERS_GETS,
  AMENITY_MERCHANT_ORDERS_GET,
  AMENITY_MERCHANT_ORDERS_EXPORT
} from 'defined/API';
import { METHOD } from 'shared/constants';

export async function getsAmenitiesService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(AMENITIES_GETS, {}, METHOD.GET, { params, headers });
    return handleResponse(response, type, 'result', true);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getAmenitiesService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  const { id } = params;
  try {
    const response = await callApi(AMENITIES_GET(id), {}, METHOD.GET, { params, headers });
    return handleResponse(response, type, 'result');
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getsPriorityInAppSelectedService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(AMENITIES_GET_PRIORITY_IN_APP_SELECTED, {}, METHOD.GET, { params, headers });
    return handleResponse(response, type, 'result');
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function addAmenitiesService(
  { body, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(AMENITIES_ADD, body, METHOD.POST, { headers });
    console.log(response);
    return handleResponse(response, type, 'result');
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function updateAmenitiesService(
  { body, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  const { id } = body;
  try {
    const response = await callApi(AMENITIES_UPDATE(id), body, METHOD.PUT, { headers });
    return handleResponse(response, type, 'result');
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function activeAmenitiesService(
  { body, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  const { id } = body;
  try {
    const response = await callApi(AMENITIES_ACTIVE(id), undefined, METHOD.PUT, { headers });
    return handleResponse(response, type, 'result');
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function inactiveAmenitiesService(
  { body, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  const { id } = body;
  try {
    const response = await callApi(AMENITIES_INACTIVE(id), undefined, METHOD.PUT, { headers });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getsAmenitiesOrderService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(AMENITIES_ORDER_GETS, undefined, METHOD.GET, { headers, params });
    return handleResponse(response, type, 'result', params.page);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getsAmenityMerchantOrderService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(AMENITY_MERCHANT_ORDERS_GETS, undefined, METHOD.GET, { headers, params });
    return handleResponse(response, type, 'result', params.page);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function exportAmenityMerchantOrderService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await downloadFile(AMENITY_MERCHANT_ORDERS_EXPORT, undefined, METHOD.GET, { headers, params });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getAmenityMerchantOrderService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(AMENITY_MERCHANT_ORDERS_GET(params.id), undefined, METHOD.GET, { headers });
    return handleResponse(response, type, 'result', params.page);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getAmenitiesOrderService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  const { id } = params;
  try {
    const response = await callApi(AMENITIES_ORDER_GET(id), undefined, METHOD.GET, { headers, params: undefined });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function exportAmenitiesOrderService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(AMENITIES_ORDER_EXPORT, undefined, METHOD.GET, { headers, params });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function approveAmenitiesOrdersService(
  { body, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(AMENITIES_ORDER_APPROVE, body, METHOD.POST, { headers });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function rejectAmenitiesOrdersService(
  { body, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(AMENITIES_ORDER_REJECT, body, METHOD.POST, { headers });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function paymentAmenitiesOrderService(
  { body, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  const { id } = body;
  try {
    const response = await callApi(AMENITIES_ORDER_PAYMENT(id), body, METHOD.POST, { headers });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function refundAmenitiesOrderService(
  { body, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  const { id } = body;
  try {
    const response = await callApi(AMENITIES_ORDER_REFUND(id), body, METHOD.POST, { headers });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function refundDepositAmenitiesOrderService(
  { body, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  const { id } = body;
  try {
    const response = await callApi(AMENITIES_ORDER_REFUND_DEPOSITS(id), body, METHOD.POST, { headers });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}
