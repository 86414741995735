import { FIREBASE } from '../constants/actionType';
import { combineReducers } from 'redux';
import fetching, { initStateFetching } from './fetching';

export const initState = {
  sendDeviceToken: {
    ...initStateFetching
  },
};

export default combineReducers({
  sendDeviceToken: fetching(FIREBASE.FCM.SEND_DEVICE_TOKEN),
});