import cookies from 'react-cookies';

function save(key = 'cyhome', data = {}) {
    cookies.save(key, data, {path: '/'});
}

function get(key = 'cyhome') {
    const cache = cookies.load(key);
    return cache || {};
}

export default {
    save: save,
    get: get
};