import { combineReducers } from 'redux';
import {
  ACCESS_MANAGEMENT_ACTION_TYPE_ADD,
  ACCESS_MANAGEMENT_ACTION_TYPE_APARTMENT_GETS,
  ACCESS_MANAGEMENT_ACTION_TYPE_APPROVE,
  ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_IN,
  ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_OUT,
  ACCESS_MANAGEMENT_ACTION_TYPE_COUNTRY_CODE_GETS,
  ACCESS_MANAGEMENT_ACTION_TYPE_EXPORT,
  ACCESS_MANAGEMENT_ACTION_TYPE_GETS,
  ACCESS_MANAGEMENT_ACTION_TYPE_REJECT,
  ACCESS_MANAGEMENT_ACTION_TYPE_REMOVE,
  ACCESS_MANAGEMENT_ACTION_TYPE_UPDATE,
} from './access-management.types';

export const initState = {
  gets: {
    isFetching: false,
    data: [],
    totalRecord: 0,
  },
  getApartments: {
    isFetching: false,
    data: [],
  },
  getCountryCodes: {
    isFetching: false,
    data: [],
  },
  add: {
    isFetching: false,
    code: undefined,
    codeLanguage: undefined,
  },
  update: {
    isFetching: false,
    code: undefined,
    codeLanguage: undefined,
  },
  updateStatus: {
    isFetching: false,
    code: undefined,
    codeLanguage: undefined,
  },
  export: {
    isFetching: false,
    code: undefined,
    codeLanguage: undefined,
    data: undefined,
  },
};

const gets = (state = initState.gets, action) => {
  switch (action.type) {
    case ACCESS_MANAGEMENT_ACTION_TYPE_GETS.REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case ACCESS_MANAGEMENT_ACTION_TYPE_GETS.SUCCESS: {
      return {
        isFetching: false,
        data: action.payload.result,
        totalRecord: action.payload.totalRecord,
      };
    }
    case ACCESS_MANAGEMENT_ACTION_TYPE_GETS.FAIL: {
      return {
        isFetching: false,
        data: [],
        totalRecord: 0,
      };
    }
    case ACCESS_MANAGEMENT_ACTION_TYPE_GETS.REFRESH: {
      return {
        ...initState.gets,
      };
    }
    default: {
      return state;
    }
  }
};

const getApartments = (state = initState.getApartments, action) => {
  switch (action.type) {
    case ACCESS_MANAGEMENT_ACTION_TYPE_APARTMENT_GETS.REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case ACCESS_MANAGEMENT_ACTION_TYPE_APARTMENT_GETS.SUCCESS: {
      return {
        isFetching: false,
        data: action.payload.result,
      };
    }
    case ACCESS_MANAGEMENT_ACTION_TYPE_APARTMENT_GETS.FAIL: {
      return {
        isFetching: false,
        data: [],
      };
    }
    case ACCESS_MANAGEMENT_ACTION_TYPE_APARTMENT_GETS.REFRESH: {
      return {
        ...initState.getApartments,
      };
    }
    default: {
      return state;
    }
  }
};

const getCountryCodes = (state = initState.getCountryCodes, action) => {
  switch (action.type) {
    case ACCESS_MANAGEMENT_ACTION_TYPE_COUNTRY_CODE_GETS.REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case ACCESS_MANAGEMENT_ACTION_TYPE_COUNTRY_CODE_GETS.SUCCESS: {
      return {
        isFetching: false,
        data: action.payload.result,
      };
    }
    case ACCESS_MANAGEMENT_ACTION_TYPE_COUNTRY_CODE_GETS.FAIL: {
      return {
        isFetching: false,
        data: [],
      };
    }
    case ACCESS_MANAGEMENT_ACTION_TYPE_COUNTRY_CODE_GETS.REFRESH: {
      return {
        ...initState.getCountryCodes,
      };
    }
    default: {
      return state;
    }
  }
};

const add = (state = initState.add, action) => {
  switch (action.type) {
    case ACCESS_MANAGEMENT_ACTION_TYPE_ADD.REQUEST: {
      return {
        ...initState.add,
        isFetching: true,
      };
    }
    case ACCESS_MANAGEMENT_ACTION_TYPE_ADD.SUCCESS:
    case ACCESS_MANAGEMENT_ACTION_TYPE_ADD.FAIL: {
      return {
        isFetching: false,
        code: action.payload.code,
        codeLanguage: action.payload.codeLanguage,
      };
    }
    case ACCESS_MANAGEMENT_ACTION_TYPE_ADD.REFRESH: {
      return {
        ...initState.add,
      };
    }
    default: {
      return state;
    }
  }
};

const update = (state = initState.update, action) => {
  switch (action.type) {
    case ACCESS_MANAGEMENT_ACTION_TYPE_UPDATE.REQUEST: {
      return {
        ...initState.update,
        isFetching: true,
      };
    }
    case ACCESS_MANAGEMENT_ACTION_TYPE_UPDATE.SUCCESS:
    case ACCESS_MANAGEMENT_ACTION_TYPE_UPDATE.FAIL: {
      return {
        isFetching: false,
        code: action.payload.code,
        codeLanguage: action.payload.codeLanguage,
      };
    }
    case ACCESS_MANAGEMENT_ACTION_TYPE_UPDATE.REFRESH: {
      return {
        ...initState.update,
      };
    }
    default: {
      return state;
    }
  }
};

const updateStatus = (state = initState.updateStatus, action) => {
  switch (action.type) {
    case ACCESS_MANAGEMENT_ACTION_TYPE_APPROVE.REQUEST:
    case ACCESS_MANAGEMENT_ACTION_TYPE_REJECT.REQUEST:
    case ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_IN.REQUEST:
    case ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_OUT.REQUEST:
    case ACCESS_MANAGEMENT_ACTION_TYPE_REMOVE.REQUEST: {
      return {
        ...initState.update,
        isFetching: true,
      };
    }
    case ACCESS_MANAGEMENT_ACTION_TYPE_APPROVE.SUCCESS:
    case ACCESS_MANAGEMENT_ACTION_TYPE_REJECT.SUCCESS:
    case ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_IN.SUCCESS:
    case ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_OUT.SUCCESS:
    case ACCESS_MANAGEMENT_ACTION_TYPE_REMOVE.SUCCESS:
    case ACCESS_MANAGEMENT_ACTION_TYPE_APPROVE.FAIL:
    case ACCESS_MANAGEMENT_ACTION_TYPE_REJECT.FAIL:
    case ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_IN.FAIL:
    case ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_OUT.FAIL:
    case ACCESS_MANAGEMENT_ACTION_TYPE_REMOVE.FAIL: {
      return {
        isFetching: false,
        code: action.payload.code,
        codeLanguage: action.payload.codeLanguage,
      };
    }
    case ACCESS_MANAGEMENT_ACTION_TYPE_APPROVE.REFRESH:
    case ACCESS_MANAGEMENT_ACTION_TYPE_REJECT.REFRESH:
    case ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_IN.REFRESH:
    case ACCESS_MANAGEMENT_ACTION_TYPE_CHECK_OUT.REFRESH:
    case ACCESS_MANAGEMENT_ACTION_TYPE_REMOVE.REFRESH: {
      return {
        ...initState.updateStatus,
      };
    }
    default: {
      return state;
    }
  }
};

const exportData = (state = initState.export, action) => {
  switch (action.type) {
    case ACCESS_MANAGEMENT_ACTION_TYPE_EXPORT.REQUEST: {
      return {
        ...initState.export,
        isFetching: true,
      };
    }
    case ACCESS_MANAGEMENT_ACTION_TYPE_EXPORT.SUCCESS: {
      return {
        isFetching: false,
        code: action.payload.code,
        codeLanguage: action.payload.codeLanguage,
        data: action.payload.result,
      };
    }
    case ACCESS_MANAGEMENT_ACTION_TYPE_EXPORT.FAIL: {
      return {
        isFetching: false,
        code: action.payload.code,
        codeLanguage: action.payload.codeLanguage,
        data: undefined,
      };
    }
    case ACCESS_MANAGEMENT_ACTION_TYPE_EXPORT.REFRESH: {
      return {
        ...initState.export,
      };
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  gets,
  getApartments,
  getCountryCodes,
  add,
  update,
  updateStatus,
  export: exportData,
});
