import {BUILDING} from '../constants/actionType';
import { initStateFetching } from './fetching';
import { combineReducers } from 'redux';
import fetching from './fetching';
import { initStatePagination } from './paginate';
import paginate from './paginate';

export const initState = {
    buildingGets: {
        ...initStateFetching,
    },
    buildingAdd: {
        ...initStateFetching,
    },
    buildingUpdate: {
        ...initStateFetching,
    },
    buildingDelete: {
        ...initStateFetching,
    },
    buildingGetById: {
        ...initStatePagination,
    },
};

const reducer = combineReducers({
    buildingGets: fetching(BUILDING.GETS),
    buildingAdd: fetching(BUILDING.ADD),
    buildingUpdate: fetching(BUILDING.UPDATE),
    buildingDelete: fetching(BUILDING.DELETE),
    buildingGetById: paginate(BUILDING.GET_BY_ID),
});

export default reducer;