import moment from 'moment';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { PAYMENT_ONLINE } from './payment-online.types';
import {
    getPaymentOnlineOrder,
    // getTotalPaymentOnlineOrder,
    getReceiptByIdService,
    exportPaymentOnlineOrder
} from 'services';

function* getDatas(action) {
    let {
        buildingId,
        filter,
        page,
        limit
    } = action.payload;
    filter.from = moment(filter.from).startOf('date').toISOString();
    filter.to = moment(filter.to).endOf('date').toISOString();
    const result = yield call(getPaymentOnlineOrder, {
        buildingId,
        params: {
            ...filter,
            page,
            limit
        },
        type: PAYMENT_ONLINE.GETS
    });
    yield put({
        ...result
    });
}

function* getReceiptById(action) {
    let {
        buildingId,
        receiptId,
    } = action.payload;
    const result = yield call(getReceiptByIdService, { unitGroupId: buildingId, receiptId }, PAYMENT_ONLINE.GET_RECEIPT);
    yield put({
        ...result
    });
}

// function* getTotal(action) {
//     let {
//         buildingId,
//         filter
//     } = action.payload;
//     filter.from = moment(filter.from).toISOString();
//     filter.to = moment(filter.to).toISOString();
//     const result = yield call(getTotalPaymentOnlineOrder, {
//         buildingId,
//         params: {
//             ...filter
//         },
//         type: PAYMENT_ONLINE.TOTAL
//     });
//     yield put({
//         ...result
//     });
// }

function* exportData(action) {
    let {
        buildingId,
        filter
    } = action.payload;
    filter.from = moment(filter.from).startOf('date').toISOString();
    filter.to = moment(filter.to).endOf('date').toISOString();
    const result = yield call(exportPaymentOnlineOrder, {
        buildingId,
        params: {
            ...filter
        },
        type: PAYMENT_ONLINE.EXPORT
    });
    yield put({
        ...result
    });
    yield put({
        type: PAYMENT_ONLINE.EXPORT.REFRESH
    });
}

export default function* watchPaymentOnline() {
    yield all([
        takeLatest(PAYMENT_ONLINE.GETS.REQUEST, getDatas),
        takeLatest(PAYMENT_ONLINE.GET_RECEIPT.REQUEST, getReceiptById),
        // takeLatest(PAYMENT_ONLINE.TOTAL.REQUEST, getTotal),
        takeLatest(PAYMENT_ONLINE.EXPORT.REQUEST, exportData)
    ]);
}