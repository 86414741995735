import { CONFIG } from '../constants/actionType';
import {call, put, select} from 'redux-saga/effects';
import {
    activeServiceSystemService,
    addServiceSystemService,
    deleteServiceSystemService,
    getServiceSystemService,
    inactiveServiceSystemService, updateServiceSystemService,
} from '../services';

export function* getServiceResource(buildingId) {
    const isFetchServiceSystem = yield select(state => state.systemService.dataGets);
    if (!isFetchServiceSystem) {
        const responseServiceSystem = yield call(getServiceSystemService, buildingId, CONFIG.SERVICE.GETS);
        yield put(responseServiceSystem);
    } else {
        yield put(CONFIG.SERVICE.GETS.SUCCESS);
    }
}

export function* getServiceSystem(action) {
    const { buildingId } = action.payload;
    const result = yield call(getServiceSystemService, buildingId, CONFIG.SERVICE.GETS);
    yield put(result);
}

export function* addServiceSystem(action) {
    const {
        name,
        enName,
        description,
        serviceType,
        identifier,
        status,
        measureUnit,
        formulas,
        surcharges,
        promotions,
        requiredInfo,
        buildingId,
        files,
        icon,
        isAddFromDefaultService,
    } = action.payload.service;
    const service = {
        name,
        enName,
        description,
        serviceType,
        identifier,
        status,
        // providerId: 0,
        measureUnit,
        formulas,
        surcharges,
        promotions,
        requiredInfo,
        unitGroupId: buildingId,
        files,
        icon,
        isAddFromDefaultService,
    };
    const result = yield call(addServiceSystemService, service, CONFIG.SERVICE.ADD);
    yield put({
        ...result
    });
    yield put({
        type: CONFIG.SERVICE.ADD.REFRESH
    });
}

export function* updateServiceSystem(action) {
    const {
        id,
        name,
        enName,
        description,
        serviceType,
        identifier,
        status,
        measureUnit,
        formulas,
        surcharges,
        promotions,
        requiredInfo,
        buildingId,
        files,
        icon,
        isAddFromDefaultService,
    } = action.payload.service;
    const service = {
        id,
        name,
        enName,
        description,
        serviceType,
        identifier,
        status,
        // providerId: 0,
        measureUnit,
        formulas,
        surcharges,
        promotions,
        requiredInfo,
        unitGroupId: buildingId,
        files,
        icon,
        isAddFromDefaultService,
    };
    const result = yield call(updateServiceSystemService, service, CONFIG.SERVICE.UPDATE);
    yield put(result);
    yield put({
        type: CONFIG.SERVICE.UPDATE.REFRESH
    });

}

export function* deleteServiceSystem(action) {
    const { serviceId, buildingId } = action.payload;
    const result = yield call(deleteServiceSystemService, {
        serviceId,
        unitGroupId: buildingId,
    }, CONFIG.SERVICE.DELETE);
    yield put({
        ...result,
        payload: {
            ...result.payload,
            serviceId
        }
    });
    yield put({
        type: CONFIG.SERVICE.DELETE.REFRESH
    });
}

export function* inactiveServiceSystem(action) {
    const { serviceId, buildingId } = action.payload;
    const result = yield call(inactiveServiceSystemService, {
        serviceId,
        unitGroupId: buildingId,
    }, CONFIG.SERVICE.INACTIVE);
    yield put({
        ...result,
        payload: {
            ...result.payload,
            serviceId,
        }
    });
    yield put({
        type: CONFIG.SERVICE.INACTIVE.REFRESH,
        payload: {
            serviceId,
        }
    });
}

export function* activeServiceSystem(action) {
    const { serviceId, buildingId } = action.payload;
    const result = yield call(activeServiceSystemService, {
        serviceId,
        unitGroupId: buildingId,
    }, CONFIG.SERVICE.ACTIVE);
    yield put({
        ...result,
        payload: {
            ...result.payload,
            serviceId
        }
    });
    yield put({
        type: CONFIG.SERVICE.ACTIVE.REFRESH,
        payload: {
            serviceId,
        }
    });
}