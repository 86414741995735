import { COMMON } from '../constants/actionType';
import { put, call } from 'redux-saga/effects';
import { deleteFileService, getFileByIdService, uploadFileService } from '../services';
import { isObject } from 'lodash';

export function* uploadFile(action) {
    const {
        file,
        id,
    } = action.payload;
    
    const result = yield call(uploadFileService, file, COMMON.UPLOAD_FILE);

    yield put({
        ...result,
        payload: {
            ...result.payload,
            id,
            url: (result.payload && result.payload.code === 200) ? result.payload.result.url : ''
        }
    });
}

export function* deleteFile(action) {
    const {
        key
    } = action.payload;

    yield call(deleteFileService, key);
}
