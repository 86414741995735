import { PARTNER } from '../../constants/actionType';
import { initStateFetching } from '../fetching';
import fetching from '../fetching';
import { combineReducers } from 'redux';
import paginate, { initStatePagination } from '../paginate';

export const initState = {
    partnerGets: {
        ...initStatePagination,
    },
    partnerAdd: {
        ...initStateFetching,
    },
    partnerUpdate: {
        ...initStateFetching,
    },
    partnerDelete: {
        ...initStateFetching,
    },
    partnerImport: {
        ...initStatePagination,
    },
    partnerExport: {
        ...initStatePagination,
    }
};

const partnerGets = paginate(PARTNER.GETS);
const partnerAdd = fetching(PARTNER.ADD);
const partnerUpdate = fetching(PARTNER.UPDATE);
const partnerDelete = fetching(PARTNER.DELETE);
const partnerImport = paginate(PARTNER.IMPORT);
const partnerExport = paginate(PARTNER.EXPORT);

export default combineReducers({
    partnerGets,
    partnerAdd,
    partnerUpdate,
    partnerDelete,
    partnerImport,
    partnerExport
});
