import React, { Fragment } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { map } from 'lodash';
import PermissionDeniedPage from '../../../pages/PermissionDenied';

type Props = {
    cookie: Object,
    Component: any,
    path: string
}

const AuthComponent = (props: Props) => {
    const {
        cookie,
        Component,
        path,
        buildings,
    } = props;

    const checkPermission = (currentBuilding) => {
        if (process.env.NODE_ENV === 'development') {
            return true;
        }

        if (!currentBuilding) {
            return true;
        } else {
            const listBuildingId = map(buildings, building => building.id.toString());
            if (listBuildingId.includes(currentBuilding.toString())) {
                return true;
            } else {
                return false;
            }
        }
    };

    const ComponentRedirect = props => {
        return <Fragment>
            {
                cookie && cookie.user && cookie.user.accessToken ? checkPermission(props.match.params.idBuilding) ?
                    <Component {...props} /> :
                    <PermissionDeniedPage/> :
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
            }
        </Fragment>;
    };

    return (
        <Route
            path={path}
            render={ComponentRedirect}
        />
    );
};

export default AuthComponent;
