// @flow

type State = {
  isFetching: boolean,
  code?: number,
  codeLanguage?: number,
  message?: string,
  currentPage: number,
  totalPage: number,
  totalRecord: number,
  data: Array<Object>,
};

export const initStatePagination = {
  isFetching: false,
  currentPage: 1,
  totalPage: 0,
  totalRecord: 0,
  data: [],
};
const paginate = (types: Object, key?: string = 'result') => {
  const { REQUEST, SUCCESS, FAIL, REFRESH } = types;

  const updatePagination = (state: State = initStatePagination, action: Object) => {
    switch (action.type) {
      case REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isFetching: true,
        };
      case SUCCESS:
        return {
          ...state,
          isFetching: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          currentPage: action.payload.currentPage,
          totalPage: action.payload.totalPage,
          totalRecord: action.payload.totalRecord,
          data: action.payload[key],
        };
      case FAIL:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isFetching: false,
          message: action.payload.message,
          totalPage: 0,
          totalRecord: 0,
          data: [],
        };
      case REFRESH:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isFetching: false,
        };
      default:
        return state;
    }
  };

  return (state: State = initStatePagination, action: Object) => {
    switch (action.type) {
      case REQUEST:
      case SUCCESS:
      case FAIL:
      case REFRESH:
        return {
          ...state,
          ...updatePagination(state, action),
        };
      default:
        return state;
    }
  };
};

export default paginate;
