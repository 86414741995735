import { handleError, handleResponse } from '../libs/http';
import { callApi } from './api';
import { ICONS, SERVICE, SERVICE_GETS, SERVICE_IN_USE_GETS, SERVICE_UPDATE_STATUS } from '../defined/API';
import { METHOD, X_UNIT_GROUP_ID } from '../constants';
import { Status } from '../constants/service';
import { uniq } from 'lodash';

export const getServiceSystemService = async (buildingId: number, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(SERVICE_GETS, {}, METHOD.GET, {
            headers: {
                [X_UNIT_GROUP_ID]: buildingId,
            },
        });
        return handleResponse(response, type, 'services');
    } catch (e) {
        handleError(e, FAIL);
    }
};

export const getServiceInUseService = async ({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(SERVICE_IN_USE_GETS, {}, METHOD.GET, {
            headers,
            params,
        });
        return handleResponse(response, type);
    } catch (e) {
        handleError(e, FAIL);
    }
};

export const addServiceSystemService = async (service: Object, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(SERVICE, service, METHOD.POST, {
            headers: {
                [X_UNIT_GROUP_ID]: service.unitGroupId,
            },
        });
        return handleResponse(response, type, 'service');
    } catch (e) {
        handleError(e, FAIL);
    }
};

export const updateServiceSystemService = async (service: Object, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(`${SERVICE}/${service.id}`, service, METHOD.PUT, {
            headers: {
                [X_UNIT_GROUP_ID]: service.unitGroupId,
            },
        });
        return handleResponse(response, type, 'service');
    } catch (e) {
        handleError(e, FAIL);
    }
};

export const deleteServiceSystemService = async ({ serviceId, unitGroupId }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(`${SERVICE}/${serviceId}`, {}, METHOD.DELETE, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type, 'service');
    } catch (e) {
        handleError(e, FAIL);
    }
};

export const inactiveServiceSystemService = async ({ serviceId, unitGroupId }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    return updateServiceStatusService({ serviceId, unitGroupId }, Status.INACTIVE, type);
};

export const activeServiceSystemService = async ({ serviceId, unitGroupId }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    return updateServiceStatusService({ serviceId, unitGroupId }, Status.ACTIVE, type);
};

const updateServiceStatusService = async ({ serviceId, unitGroupId }, status: string, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(`${SERVICE_UPDATE_STATUS}/${serviceId}`, {
            status,
        }, METHOD.PUT, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId
            }
        });
        return handleResponse(response, type);
    } catch (e) {
        handleError(e, FAIL);
    }
};

export const getIcons = async (type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { SUCCESS, FAIL } = type;
    try {
        const response = await callApi(ICONS, {}, METHOD.GET);
        return handleResponse(response, type);
    } catch (e) {
        handleError(e, FAIL);
    }
};