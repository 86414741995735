//building
import { METHOD, PAGINATION, X_UNIT_GROUP_ID } from '../constants';
import { callApi } from './api';
import {
    UNIT_GROUP, UNIT_GROUP_GET_BY_ID,
    UNIT_GROUP_GET_LIST, UNIT_GROUP_GET_TEMPLATE,
    UNIT_GROUP_LIST_TEMPLATE, UNIT_GROUP_SAVE_TEMPLATE,
    UNIT_GROUP_TARGET_GETS,
    UNIT_GROUP_TYPE_GETS,
} from '../defined/API';
import { handleError, handleResponse } from '../libs/http';

export const getBuildings = async (type: Object) => {
    const { FAIL } = type;
    try {
        const result = await callApi(UNIT_GROUP_GET_LIST, {}, METHOD.GET, {
            params: {
                // type: BUILDING,
            },
        });
        return handleResponse(result, type);

    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getBuildingByIdService = async (id, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const result = await callApi(UNIT_GROUP_GET_BY_ID(id), null, METHOD.GET, {
            headers: {
                [X_UNIT_GROUP_ID]: id,
            }
        });
        return handleResponse(result, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getTargetService = async (buildingId: number, page: number = 1, limit: number = PAGINATION.LIMIT, filterObject: Object = {
    apartment: '',
    resident: '',
}, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const { apartment, resident } = filterObject;
        const response = await callApi(UNIT_GROUP_TARGET_GETS, {}, METHOD.GET, {
            params: {
                page,
                limit,
                unitName: apartment,
                residentName: resident,
            },
            headers: {
                [X_UNIT_GROUP_ID]: buildingId,
            },
        });
        return handleResponse(response, type, 'targets', true);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const addBuildingResource = async (building: Object, type: Object) => {
    const { FAIL } = type;
    try {
        const result = await callApi(UNIT_GROUP, {
            ...building,
        }, METHOD.POST);
        return handleResponse(result, type, 'building');
    } catch (e) {
        return handleError(e, FAIL);
    }
};
export const updateBuildingResource = async (building: Object, type: Object) => {
    const { FAIL } = type;
    try {
        const result = await callApi(`${UNIT_GROUP}/${building.id}`, building, METHOD.PUT, {
            headers: {
                [X_UNIT_GROUP_ID]: building.id,
            },
        });
        return handleResponse(result, type, 'building');
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const deleteBuildingResource = async (buildingId: number, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const result = await callApi(`${UNIT_GROUP}/${buildingId}`, {}, METHOD.DELETE, {
            headers: {
                [X_UNIT_GROUP_ID]: buildingId,
            }
        });
        return handleResponse(result, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getListTemplateBuildingService = async (headers, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const result = await callApi(UNIT_GROUP_LIST_TEMPLATE, null, METHOD.GET, {
            headers,
        });
        return handleResponse(result, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const saveTemplateBuildingService = async({ body, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(UNIT_GROUP_SAVE_TEMPLATE, body, METHOD.POST, {
            headers
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getTemplateBuildingService = async({ name, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(`${UNIT_GROUP_GET_TEMPLATE}/${name}`, null, METHOD.GET, {
            headers
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getBuildingTypes = async (type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const result = await callApi(UNIT_GROUP_TYPE_GETS, null, METHOD.GET);
        return handleResponse(result, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};
