import { REQUEST } from '../constants/actionType';
import { PAGINATION } from '../constants';

export const getRequests = ({ buildingId = '', page = 1, limit = 20, filterObject = {}, status = 'NEW' }) => ({
  type: REQUEST.REQUEST_GETS.REQUEST,
  payload: {
    buildingId,
    page,
    limit,
    filterObject,
    status,
  }
});

export const changeProgressRequest = (data) => ({
  type: REQUEST.REQUEST_CHANGE_PROGRESS.REQUEST,
  payload: { ...data }
});

export const getRequestDetail = ({ buildingId = '', requestId = '' }) => ({
  type: REQUEST.REQUEST_GET.REQUEST,
  payload: {
    buildingId,
    requestId
  },
});

export const refreshRequestDetail = () => ({
  type: REQUEST.REQUEST_GET.REFRESH,
})

export const approvalRequest = ({ buildingId = '', requestId = '', dueDate }) => ({
  type: REQUEST.REQUEST_APPROVE.REQUEST,
  payload: {
    buildingId,
    requestId,
    dueDate,
  },
});

export const denyRequest = ({ buildingId = '', requestId = '', note = '' }) => ({
  type: REQUEST.REQUEST_DENY.REQUEST,
  payload: {
    buildingId,
    requestId,
    note
  },
});


export const getDepartments = ({ buildingId = '' }) => ({
  type: REQUEST.REQUEST_DEPARTMENT_GETS.REQUEST,
  payload: {
    buildingId,
  },
});

export const getEmployees = ({ buildingId = '' }) => ({
  type: REQUEST.REQUEST_EMPLOYEES_GETS.REQUEST,
  payload: {
    buildingId,
  },
});

export const addRequest = ({ description = '', departmentId = '', buildingId = '', dueDate = '', receiverId, unitId, residentId }) => ({
  type: REQUEST.REQUEST_ADD.REQUEST,
  payload: {
    buildingId,
    description,
    departmentId,
    unitId,
    residentId,
    dueDate,
    receiverId,
  }
});

export const updateRequest = ({ title, description, dueDate, departmentId, receiverId, labelIds, buildingId, isRefreshTable = false, tableParams = {}, requestId = '' }: Object) => ({
  type: REQUEST.REQUEST_UPDATE.REQUEST,
  payload: {
    title,
    description,
    dueDate,
    departmentId,
    receiverId,
    labelIds,
    buildingId,
    isRefreshTable,
    tableParams,
    requestId
  }
});

export const duplicateRequest = (data) => ({
  type: REQUEST.REQUEST_DUPLICATE.REQUEST,
  payload: { ...data }
});

export const exportDataRequest = (data) => ({
  type: REQUEST.REQUEST_EXPORT_DATA.REQUEST,
  payload: { ...data }
});

export const cancelRequest = (data) => ({
  type: REQUEST.REQUEST_CANCEL.REQUEST,
  payload: { ...data }
});

export const getTotalTabs = ({ buildingId = '' }) => ({
  type: REQUEST.REQUEST_TOTAL_TAB_GETS.REQUEST,
  payload: {
    buildingId,
  }
});

export const getApartments = (data) => ({
  type: REQUEST.REQUEST_APARTMENT_GETS.REQUEST,
  payload: { ...data }
});

export const getTask = (data) => ({
  type: REQUEST.REQUEST_TASK_GETS.REQUEST,
  payload: { ...data }
});

export const addTask = (data) => ({
  type: REQUEST.REQUEST_TASK_ADD.REQUEST,
  payload: { ...data }
});

export const updateTask = (data) => ({
  type: REQUEST.REQUEST_TASK_UPDATE.REQUEST,
  payload: { ...data }
});

export const changeProgressTask = (data) => ({
  type: REQUEST.REQUEST_TASK_CHANGE_PROGRESS.REQUEST,
  payload: { ...data }
});

export const getActivityLogRequest = (data) => ({
  type: REQUEST.REQUEST_ACTIVITY_LOG.REQUEST,
  payload: { ...data }
});

export const getTimelineRequest = (data) => ({
  type: REQUEST.REQUEST_TIMELINE.REQUEST,
  payload: { ...data }
});