import { LABEL_GETS, LABEL_ADD, LABEL_UPDATE, LABEL_DELETE, LABEL_TREE_GETS } from './label.types';

export const getLabelsAction = (unitGroupId) => ({
  type: LABEL_GETS.REQUEST,
  payload: {
    unitGroupId,
  },
});

export const addLabelAction = (label, unitGroupId) => ({
  type: LABEL_ADD.REQUEST,
  payload: {
    label,
    unitGroupId,
  },
});

export const refreshAddLabelAction = () => ({
  type: LABEL_ADD.REFRESH,
});

export const updateLabelAction = (label, unitGroupId) => ({
  type: LABEL_UPDATE.REQUEST,
  payload: {
    label,
    unitGroupId,
  },
});

export const refreshUpdateLabelAction = () => ({
  type: LABEL_UPDATE.REFRESH,
});

export const deleteLabelAction = (ids, unitGroupId) => ({
  type: LABEL_DELETE.REQUEST,
  payload: {
    ids,
    unitGroupId,
  },
});

export const refreshDeleteLabelAction = () => ({
  type: LABEL_DELETE.REFRESH,
});

export const getLabelTreeAction = (buildingId, filterObject = {}) => ({
  type: LABEL_TREE_GETS.REQUEST,
  payload: {
    filterObject,
    buildingId,
  },
});
