import { APARTMENT_DETAIL } from '../constants/actionType';
import { initStateFetching } from './fetching';
import { initStatePagination } from './paginate';
import { combineReducers } from 'redux';
import fetching from './fetching';
import paginate from './paginate';

export const initState = {
    apartmentDetailInit: {
        ...initStateFetching,
    },
    apartmentInfoGet: {
        ...initStatePagination,
    },
    residentGets: {
        ...initStatePagination,
    },
    residentAdd: {
        ...initStateFetching,
    },
    residentUpdate: {
        ...initStateFetching,
    },
    residentDelete: {
        ...initStateFetching,
    },
    documentGets: {
        ...initStatePagination,
    },
    documentDelete: {
        ...initStateFetching,
    },
    documentAdd: {
        ...initStateFetching,
    },
    documentUpdate: {
        ...initStateFetching
    },
    serviceHistoryInit: {
        ...initStateFetching,
    },
    serviceHistoryGets: {
        ...initStatePagination,
    },
    paymentHistoryGets: {
        ...initStatePagination,
    },
    paymentHistoryPreview: {
        ...initStatePagination,
    },
    services: {
        ...initStatePagination,
    },
    funds: {
        ...initStatePagination,
    },
    tags: {
        ...initStatePagination,
    },
    receiptCreate: {
        ...initStatePagination,
    },
    bills: {
        ...initStatePagination,
    },
    wallet: {
        ...initStatePagination,
    },
    qrCodeGet: {
        ...initStatePagination,
    },
    qrCodeRefresh: {
        ...initStateFetching
    },
    qrCodeExport: {
        ...initStatePagination,
    },
    paymentInfoGet: {
        ...initStatePagination,
    }
};

const handleResidentData = (reducer) => (state, action) => {
    switch (action.type) {
    case APARTMENT_DETAIL.RESIDENT.DELETE.SUCCESS:
        return {
            ...state,
            data: [
                ...state.data.filter(item => item.id !== action.payload.residentId),
            ],
        };
    default: {
        return reducer(state, action);
    }
    }
};

const handleDataDocument = (reducer) => (state, action) => {
    switch (action.type) {
    case APARTMENT_DETAIL.DOCUMENT.ADD.SUCCESS: {
        return {
            ...state,
            data: [
                ...state.data,
                action.payload.document,
            ],
        };
    }
    case APARTMENT_DETAIL.DOCUMENT.UPDATE.SUCCESS: {
        return {
            ...state,
            data: [
                ...state.data.filter(item => item.id !== action.payload.document.id),
                action.payload.document,
            ]
        };
    }
    case APARTMENT_DETAIL.DOCUMENT.DELETE.SUCCESS: {
        return {
            ...state,
            data: state.data.filter(item => item.id !== action.payload.documentId)
        };
    }
    default: {
        return reducer(state, action);
    }
    }
};


const reducer = combineReducers({
    apartmentDetailInit: fetching(APARTMENT_DETAIL.INIT),
    apartmentInfoGet: paginate(APARTMENT_DETAIL.APARTMENT_INFO.GET),
    residentGets: handleResidentData(paginate(APARTMENT_DETAIL.RESIDENT.GETS_BY_APARTMENT)),
    residentAdd: fetching(APARTMENT_DETAIL.RESIDENT.ADD),
    residentUpdate: fetching(APARTMENT_DETAIL.RESIDENT.UPDATE),
    residentDelete: fetching(APARTMENT_DETAIL.RESIDENT.DELETE),
    receiptCreate: paginate(APARTMENT_DETAIL.RECEIPT.CREATE),
    funds: paginate(APARTMENT_DETAIL.FUND.GETS),
    tags: paginate(APARTMENT_DETAIL.TAG.GETS),
    bills: paginate(APARTMENT_DETAIL.BILL.GETS),
    documentGets: handleDataDocument(paginate(APARTMENT_DETAIL.DOCUMENT.GETS_BY_APARTMENT)),
    documentDelete: fetching(APARTMENT_DETAIL.DOCUMENT.DELETE),
    documentAdd: fetching(APARTMENT_DETAIL.DOCUMENT.ADD),
    documentUpdate: fetching(APARTMENT_DETAIL.DOCUMENT.UPDATE),
    wallet: paginate(APARTMENT_DETAIL.WALLET.GETS),
    qrCodeGet: paginate(APARTMENT_DETAIL.QRCODE.GET),
    qrCodeRefresh: fetching(APARTMENT_DETAIL.QRCODE.REFRESH),
    qrCodeExport: paginate(APARTMENT_DETAIL.QRCODE.EXPORT),
    serviceHistoryInit: fetching(APARTMENT_DETAIL.SERVICE_HISTORY.INIT),
    serviceHistoryGets: paginate(APARTMENT_DETAIL.SERVICE_HISTORY.GETS),
    paymentHistoryGets: paginate(APARTMENT_DETAIL.PAYMENT_HISTORY.GETS),
    paymentHistoryPreview: paginate(APARTMENT_DETAIL.PAYMENT_HISTORY.PREVIEW),
    services: paginate(APARTMENT_DETAIL.SERVICE.GETS),
    paymentInfoGet: paginate(APARTMENT_DETAIL.PAYMENT_INFO_GET),
});

export default reducer;
