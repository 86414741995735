import Cookie from 'defined/Cookie';
import { METHOD } from '../constants';
import queryString from 'query-string';

type OptionsApi = {
  withoutToken?: boolean,
  withHeader?: boolean,
  params?: Object,
  headers?: Object,
};

const optionsDefault = {
  params: undefined,
  headers: {},
};

export async function callApi(apiUrl: string, body: Object, method: string, options?: OptionsApi = optionsDefault): Object {
  const cookie = Cookie.get('cyhome');
  const option = {
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      'content-type': 'application/json',
      Accept: 'application/json,text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8\n',
      Authorization: cookie.user && cookie.user.accessToken,
      ...options.headers,
    },
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // *client, no-referrer,
    body: undefined,
  };
  if (body && method !== METHOD.GET && method !== METHOD.DELETE) {
    option.body = JSON.stringify(body);
  }
  let url = apiUrl;
  const query = queryString.stringify(options.params, { arrayFormat: 'bracket' });
  if (query) {
    url = [url, query].join('?');
  }
  // create request
  try {
    const response = await fetch(url, option);
    try {
      let body;
      if (options.bodyType === 'html') {
        body = await response.text();
      } else {
        body = await response.json();
      }
      if (response.status === 401 || (response.status === 500 && body.error && body.error.message === 'Authorization error')) {
        Cookie.save('cyhome', undefined);
      }
      if (!response.ok) {
        if (response.status < 500) {
          let bodyTmp = typeof body === 'string' ? JSON.parse(body) : body;
          return {
            headers: response.headers,
            error: {
              ...bodyTmp,
              statusCode: response.status,
            },
          };
        } else {
          return {
            headers: response.headers,
            error: {
              ...body,
              statusCode: response.status,
              code: 'SERVER_ERROR',
            },
          };
        }
      } else {
        return options.bodyType === 'html'
          ? {
              headers: response.headers,
              code: response.status,
              data: body,
            }
          : {
              headers: response.headers,
              ...body,
              code: response.status,
            };
      }
    } catch (err) {
      if (response.status === 204) {
        return {
          code: 200,
        };
      }
    }
  } catch (error) {
    return {
      error: {
        ...body,
        code: 'SERVER_ERROR',
        statusCode: 500,
      },
    };
  }
}

export async function downloadFile(apiUrl: string, body: Object, method: string, options?: OptionsApi = optionsDefault): Object {
  const cookie = Cookie.get('cyhome');
  const option = {
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      'content-type': 'application/json',
      Accept: 'application/json,text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8\n',
      Authorization: cookie.user && cookie.user.accessToken,
      ...options.headers,
    },
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // *client, no-referrer,
    body: undefined,
  };
  if (method !== METHOD.GET && method !== METHOD.DELETE) {
    option.body = JSON.stringify(body);
  }
  let url = apiUrl;
  const query = queryString.stringify(options.params, { arrayFormat: 'bracket' });
  if (query) {
    url = [url, query].join('?');
  }
  // create request
  try {
    const response = await fetch(url, option);
    if (response.ok) {
      if (response.status !== 200) {
        try {
          const body = await response.json();
          if (response.status < 500) {
            let bodyTmp = typeof body === 'string' ? JSON.parse(body) : body;
            return {
              headers: response.headers,
              error: {
                ...bodyTmp,
                statusCode: response.status,
              },
            };
          } else {
            return {
              headers: response.headers,
              error: {
                ...body,
                statusCode: response.status,
                code: 'SERVER_ERROR',
              },
            };
          }
        } catch (e) {
          return {
            headers: response.headers,
            error: {
              message: e,
              statusCode: 500,
              code: 'SERVER_ERROR',
            },
          };
        }
      } else {
        const blob = await response.blob();
        const url = URL.createObjectURL(new Blob([blob]));
        return {
          headers: response.headers,
          code: response.status,
          data: url,
          error: undefined,
        };
      }
    } else {
      return {
        headers: response.headers,
        error: {
          message: 'Can not connect to server',
          statusCode: 500,
          code: 'SERVER_ERROR',
        },
      };
    }
  } catch (error) {
    return {
      error: {
        message: error,
        statusCode: 500,
        code: 'SERVER_ERROR',
      },
    };
  }
}

export async function callApiWithFile(apiUrl: string, file: Object, method: string, name: string = 'file', options = {}) {
  const cookie = Cookie.get('cyhome');

    const option = {
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, same-origin, *omit
        headers: {
            'Authorization': cookie.user && cookie.user.accessToken,
            ...options.headers,
        },
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // *client, no-referrer,
        body: undefined,
    };
    const formData = new FormData();
    formData.append(name, file);
    if (options.dataAttach) {
        Object.keys(options.dataAttach).map((key) => {
            if (options.dataAttach[key]) {
                formData.append(key, options.dataAttach[key]);
            }
        });
    }
    if (method !== METHOD.GET && method !== METHOD.DELETE) {
        option.body = formData;
    }
    let url = apiUrl;
    const query = queryString.stringify(options.params, { arrayFormat: 'bracket' });
    if (query) {
        url = [url, query].join('?');
    }
    try {
        const response = await fetch(url, option);

    try {
      const body = await response.json();
      if (response.status === 401 || (response.status === 500 && body.error && body.error.message === 'Authorization error')) {
        Cookie.save('cyhome', undefined);
      }
      if (!response.ok) {
        if (response.status < 500) {
          let bodyTmp = typeof body === 'string' ? JSON.parse(body) : body;
          return {
            headers: response.headers,
            error: {
              ...bodyTmp,
              statusCode: response.status,
            },
          };
        } else {
          return {
            headers: response.headers,
            error: {
              ...body,
              statusCode: response.status,
              code: 'SERVER_ERROR',
            },
          };
        }
      } else {
        return {
          headers: response.headers,
          ...body,
          code: response.status,
        };
      }
    } catch (err) {
      if (response.status === 204) {
        return {
          code: 200,
        };
      }
    }
  } catch (e) {
    return {
      error: {
        message: e,
        statusCode: 500,
        code: 'SERVER_ERROR',
      },
    };
  }
}

export async function callApiParse(apiUrl: string, body: Object, method: string, options?: OptionsApi): Object {
  const option = {
    headers: {
      'X-Parse-Application-Id': process.env.PARSE_SERVER_APPLICATION_ID,
      'X-Parse-Rest-Api-Key': process.env.PARSE_SERVER_REST_API_KEY,
      ...options.headers,
    },
    params: {
      ...options.params,
    },
  };
  return callApi(apiUrl, body, method, option);
}
