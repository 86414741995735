import { callApi, downloadFile } from './api';
import { REPORT_SERVICE_GETS, REPORT_SERVICE_EXPORT, REPORT_SERVICE_SUM_PAYMENT } from '../defined/API';
import { METHOD, X_UNIT_GROUP_ID } from '../constants';
import { handleError, handleResponse } from '../libs/http';
import { pick } from 'lodash';

export async function getReportServiceService(buildingId, data, type) {
    const { FAIL } = type;

    try {
        const params = pick(data, ['apartment', 'from', 'to', 'serviceId', 'contractTemplateId', 'page']);
        const limit = 20;

        const options = {
            headers: {
                [X_UNIT_GROUP_ID]: buildingId,
            },
            params: {
                ...params,
                limit,
            },
        };

        const response = await callApi(REPORT_SERVICE_GETS, null, METHOD.GET, options);

        return handleResponse(response, type, 'result', data.page, limit);
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}

export async function exportReportServiceService(buildingId, data, type) {
    const { FAIL } = type;

    try {
        const params = pick(data, ['apartment', 'from', 'to', 'serviceId', 'contractTemplateId', 'type']);

        const options = {
            headers: {
                [X_UNIT_GROUP_ID]: buildingId,
            },
            params: {
                ...params,
            },
        };

        const response = await downloadFile(REPORT_SERVICE_EXPORT, null, METHOD.GET, options);

        return handleResponse(response, type, 'result');
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}

export async function getSumPaymentReportServiceService(buildingId, data, type) {
    const { FAIL } = type;
    try {
        const params = pick(data, ['apartment', 'from', 'to', 'serviceId', 'contractTemplateId']);
        const options = {
            headers: {
                [X_UNIT_GROUP_ID]: buildingId,
            },
            params: {
                ...params,
            },
        };

        const response = await callApi(REPORT_SERVICE_SUM_PAYMENT, null, METHOD.GET, options);

        return handleResponse(response, type);
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}

export async function getPaymentOnlineOrder(buildingId, data) {
    const { FAIL } = type;

    try {
        const params = pick(data, ['apartment', 'from', 'to', 'page']);
        const limit = 20;

        const options = {
            headers: {
                [X_UNIT_GROUP_ID]: buildingId,
            },
            params: {
                ...params,
                limit,
            },
        };

        const response = await callApi(REPORT_SERVICE_GETS, null, METHOD.GET, options);

        return handleResponse(response, type, 'result', data.page, limit);
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}
