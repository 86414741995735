import { handleError, handleResponse } from '../libs/http';
import { callApi } from './api';
import {
    ENTERPRISE_ADD,
    ENTERPRISE_DELETE,
    ENTERPRISE_GET_LIST,
    ENTERPRISE_SEARCH_BY_NAME,
    ENTERPRISE_UPDATE,
} from '../defined/API';
import { METHOD } from '../constants/http';
import { PAGINATION } from '../constants';

export const addEnterpriseService = async (enterprise, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(ENTERPRISE_ADD, enterprise, METHOD.POST);
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const updateEnterpriseService = async (enterprise, type = {}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(`${ENTERPRISE_UPDATE}/${enterprise.id}`, enterprise, METHOD.PUT);
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const deleteEnterpriseService = async (enterpriseId, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(`${ENTERPRISE_DELETE}/${enterpriseId}`, {}, METHOD.DELETE);
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getListEnterpriseService = async (buildingId, page, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(ENTERPRISE_GET_LIST, {}, METHOD.GET, {
            params: {
                limit: PAGINATION.LIMIT,
                offset: page,
            },
        });
        return handleResponse(response, type, 'result', page);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const searchEnterpriseByNameService = async (buildingId, name, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL'
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(ENTERPRISE_SEARCH_BY_NAME, {}, METHOD.GET, {
            params: {
                unitGroupId: buildingId,
                name,
            }
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};