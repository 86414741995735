import { STATISTIC } from '../../constants/actionType';
import { call, put } from 'redux-saga/effects';
import {
  getPaySlipService,
  addPaySlipService,
  updatePaySlipService,
  cancelPaySlipService,
  exportPaySlipService,
  getPaySlipPreviewService,
  getSumTotalPaymentService
} from '../../services/paySlip';
import { getSumTotalPayment } from '../../actions/statistics/paySlip'
import { getLabelsService } from '../../services/label';
import { X_UNIT_GROUP_ID } from '../../constants';
import { pick } from 'lodash';

export function* getPaySlip(action) {
  const { buildingId, data: { page, limit } } = action.payload;

  const params = pick(action.payload.data, ['from', 'to', 'labelId', 'fundId', 'paymentMethod', 'code', 'status']);
  const result = yield call(getPaySlipService, buildingId, page, limit, params, STATISTIC.PAY_SLIP.GETS);

  yield put(getSumTotalPayment(buildingId, params));

  yield put(result);
}

export function* addPaySlip(action) {
  const { buildingId, data } = action.payload;

  const result = yield call(addPaySlipService, buildingId, data, STATISTIC.PAY_SLIP.ADD);

  yield put(result);
  yield put({ type: STATISTIC.PAY_SLIP.ADD.REFRESH });
}

export function* updatePaySlip(action) {
  const { buildingId, data } = action.payload;

  const result = yield call(updatePaySlipService, buildingId, data, STATISTIC.PAY_SLIP.UPDATE);

  yield put(result);
  yield put({ type: STATISTIC.PAY_SLIP.UPDATE.REFRESH });
}

export function* cancelPaySlip(action) {
  const { buildingId, data } = action.payload;

  const body = pick(data, ['reason']);
  const paySlipId = data.paySlipId;

  const result = yield call(cancelPaySlipService, buildingId, paySlipId, body, STATISTIC.PAY_SLIP.CANCEL);

  yield put(result);
  yield put({ type: STATISTIC.PAY_SLIP.CANCEL.REFRESH });
}

export function* exportPaySlip(action) {
  const { data, unitGroupId } = action.payload;

  const result = yield call(exportPaySlipService, {
    params: data,
    headers: {
      [X_UNIT_GROUP_ID]: unitGroupId,
    }
  }, STATISTIC.PAY_SLIP.EXPORT);

  yield put({ ...result });
  yield put({ type: STATISTIC.PAY_SLIP.EXPORT.REFRESH });
}

export function* getPaySlipPreview(action) {
  const { buildingId, paySlipId } = action.payload;

  const result = yield call(getPaySlipPreviewService, buildingId, paySlipId, STATISTIC.PAY_SLIP.GET_PREVIEW);

  yield put(result);
}

export function* getTag(action) {
  const { buildingId } = action.payload;

  const headers = {
    [X_UNIT_GROUP_ID]: buildingId
  };

  const result = yield call(getLabelsService, { headers }, STATISTIC.TAG.GETS);

  yield put(result);
}

export function* getSumTotalPayments(action) {
  const { buildingId, data } = action.payload;

  const params = pick(data, ['code', 'labelId', 'fundId', 'from', 'to', 'status']);

  const headers = {
    [X_UNIT_GROUP_ID]: buildingId
  };

  const result = yield call(getSumTotalPaymentService, { headers, params }, STATISTIC.PAY_SLIP.SUM_TOTAL_PAYMENT);

  yield put(result);
}
