import React from 'react';
import { language } from 'defined/Language';
import moment from 'moment';
import formatCurrency from './formatCurrency';

const textAlignRight = (data) => (
  <div
    style={{
      textAlign: 'right',
    }}
  >
    {data}
  </div>
);

const buildColumns = ({ isShowAdjustCol }) => {
  const columns = [
    {
      key: 'collapse',
      dataIndex: 'collapse',
      title: '',
      width: 60,
      fixed: 'left',
      children: [],
    },
    {
      key: 'customerCode',
      dataIndex: 'customerCode',
      title: language.customer_code,
      width: 120,
      fixed: 'left',
    },
    {
      key: 'apartment',
      dataIndex: 'apartment',
      title: language.apartment,
      width: 120,
      fixed: 'left',
    },
    {
      key: 'service',
      dataIndex: 'service',
      title: language.service,
      width: 150,
      fixed: 'left',
    },
    {
      key: '',
      dataIndex: '',
      title: language.opening_debt,
      width: 150,
      fixed: 'left',
      children: [
        {
          key: 'openingDebt',
          dataIndex: 'openingDebt',
          title: '1',
          width: 150,
          align: 'center',
        },
      ],
    },
    {
      key: '',
      dataIndex: '',
      title: language.opening_paid_before,
      width: 150,
      fixed: 'left',
      children: [
        {
          key: 'openingPaidBefore',
          dataIndex: 'openingPaidBefore',
          title: '2',
          width: 150,
          align: 'center',
        },
      ],
    },
  ];
  if (isShowAdjustCol) {
    columns.push({
      key: '',
      dataIndex: '',
      title: language.adjust,
      width: 150,
      fixed: 'left',
      children: [
        {
          key: 'adjust',
          dataIndex: 'adjust',
          title: '3',
          width: 150,
          align: 'center',
        },
      ],
    });
  }
  columns.push(
    {
      key: '',
      dataIndex: '',
      title: language.receivables,
      width: 150,
      fixed: 'left',
      children: [
        {
          key: 'receivables',
          dataIndex: 'receivables',
          title: isShowAdjustCol ? '4' : '3',
          width: 150,
          align: 'center',
        },
      ],
    },
    {
      key: '',
      dataIndex: '',
      title: language.deduct,
      width: 150,
      fixed: 'left',
      children: [
        {
          key: 'deduct',
          dataIndex: 'deduct',
          title: isShowAdjustCol ? '5' : '4',
          width: 150,
          align: 'center',
        },
      ],
    },
    {
      key: '',
      dataIndex: '',
      title: language.paid_in_period,
      width: 150,
      fixed: 'left',
      children: [
        {
          key: 'paidInPeriod',
          dataIndex: 'paidInPeriod',
          title: isShowAdjustCol ? '6' : '5',
          width: 150,
          align: 'center',
        },
      ],
    },
    {
      key: '',
      dataIndex: '',
      title: language.paid_debt,
      width: 150,
      fixed: 'left',
      children: [
        {
          key: 'paidDebt',
          dataIndex: 'paidDebt',
          title: isShowAdjustCol ? '7' : '6',
          width: 150,
          align: 'center',
        },
      ],
    },
    {
      key: '',
      dataIndex: '',
      title: language.paid_before,
      width: 150,
      fixed: 'left',
      children: [
        {
          key: 'paidBefore',
          dataIndex: 'paidBefore',
          title: isShowAdjustCol ? '8' : '7',
          width: 150,
          align: 'center',
        },
      ],
    },
    {
      key: '',
      dataIndex: '',
      title: language.total,
      width: 150,
      fixed: 'left',
      children: [
        {
          key: 'total',
          dataIndex: 'total',
          title: isShowAdjustCol ? '9=6+7+8' : '8=5+6+7',
          width: 150,
          align: 'center',
        },
      ],
    },
    {
      key: '',
      dataIndex: '',
      title: language.remaining_receivables,
      width: 150,
      fixed: 'left',
      children: [
        {
          key: 'remainingReceivables',
          dataIndex: 'remainingReceivables',
          title: isShowAdjustCol ? '10=3+4-5-6' : '9=3-4-5',
          width: 150,
          align: 'center',
        },
      ],
    },
    {
      key: '',
      dataIndex: '',
      title: language.closing_debt,
      width: 150,
      fixed: 'left',
      children: [
        {
          key: 'closingDebt',
          dataIndex: 'closingDebt',
          title: isShowAdjustCol ? '11=1-7+10' : '10=1-6+9',
          width: 150,
          align: 'center',
        },
      ],
    },
    {
      key: 'closingPaidBefore',
      dataIndex: 'closingPaidBefore',
      title: language.closing_paid_before,
      width: 150,
      fixed: 'left',
      children: [
        {
          key: 'closingPaidBefore',
          dataIndex: 'closingPaidBefore',
          title: isShowAdjustCol ? '12=2-5+8' : '11=2-4+7',
          width: 150,
          align: 'center',
        },
      ],
    }
  );
  return columns;
};

const buildDataSources = (props) => {
  const { units, services, months, total } = props;
  const rows = [];
  for (let indexUnit = 0; indexUnit < units.length; indexUnit += 1) {
    let totalRow = {};
    if (indexUnit === 0) {
      totalRow = {
        key: 'total',
        customerCode: '',
        apartment: language.total,
        service: '',
        openingDebt: textAlignRight(formatCurrency(total?.TOTAL?.openingDebt?.TOTAL ?? 0)),
        openingPaidBefore: textAlignRight(formatCurrency(total?.TOTAL?.openingPaidBefore?.TOTAL ?? 0)),
        receivables: textAlignRight(formatCurrency(total?.TOTAL?.inPeriodMustPay?.TOTAL ?? 0)),
        adjust: textAlignRight(formatCurrency(total?.TOTAL?.inPeriodAdjust?.TOTAL ?? 0)),
        deduct: textAlignRight(formatCurrency(total?.TOTAL?.inPeriodDeductPaidBefore?.TOTAL ?? 0)),
        paidInPeriod: textAlignRight(formatCurrency(total?.TOTAL?.inPeriodPaid?.TOTAL ?? 0)),
        paidDebt: textAlignRight(formatCurrency(total?.TOTAL?.inPeriodPaidDebt?.TOTAL ?? 0)),
        paidBefore: textAlignRight(formatCurrency(total?.TOTAL?.inPeriodPaidBefore?.TOTAL ?? 0)),
        total: textAlignRight(formatCurrency(total?.TOTAL?.inPeriodPaidTotal?.TOTAL ?? 0)),
        remainingReceivables: textAlignRight(formatCurrency(total?.TOTAL?.inPeriodRest?.TOTAL ?? 0)),
        closingDebt: textAlignRight(formatCurrency(total?.TOTAL?.closingDebt?.TOTAL ?? 0)),
        closingPaidBefore: textAlignRight(formatCurrency(total?.TOTAL?.closingPaidBefore?.TOTAL ?? 0)),
        children: [],
      };
    }
    const unit = units[indexUnit];
    const row = {
      key: unit?.unit?.id,
      customerCode: unit?.unit?.label?.title ?? '',
      apartment: unit?.unit?.name ?? '',
      service: '',
      openingDebt: textAlignRight(formatCurrency(unit?.TOTAL?.openingDebt?.TOTAL ?? 0)),
      openingPaidBefore: textAlignRight(formatCurrency(unit?.TOTAL?.openingPaidBefore?.TOTAL ?? 0)),
      receivables: textAlignRight(formatCurrency(unit?.TOTAL?.inPeriodMustPay?.TOTAL ?? 0)),
      adjust: textAlignRight(formatCurrency(unit?.TOTAL?.inPeriodAdjust?.TOTAL ?? 0)),
      deduct: textAlignRight(formatCurrency(unit?.TOTAL?.inPeriodDeductPaidBefore?.TOTAL ?? 0)),
      paidInPeriod: textAlignRight(formatCurrency(unit?.TOTAL?.inPeriodPaid?.TOTAL ?? 0)),
      paidDebt: textAlignRight(formatCurrency(unit?.TOTAL?.inPeriodPaidDebt?.TOTAL ?? 0)),
      paidBefore: textAlignRight(formatCurrency(unit?.TOTAL?.inPeriodPaidBefore?.TOTAL ?? 0)),
      total: textAlignRight(formatCurrency(unit?.TOTAL?.inPeriodPaidTotal?.TOTAL ?? 0)),
      remainingReceivables: textAlignRight(formatCurrency(unit?.TOTAL?.inPeriodRest?.TOTAL ?? 0)),
      closingDebt: textAlignRight(formatCurrency(unit?.TOTAL?.closingDebt?.TOTAL ?? 0)),
      closingPaidBefore: textAlignRight(formatCurrency(unit?.TOTAL?.closingPaidBefore?.TOTAL ?? 0)),
      children: [],
    };
    for (let indexMonth = 0; indexMonth < months.length; indexMonth += 1) {
      const month = months[indexMonth];
      let totalNestedSecondRow = {};
      if (indexUnit === 0) {
        totalNestedSecondRow = {
          key: `total.${indexMonth}`,
          customerCode: '',
          apartment: month === 'TOTAL' ? language.total : moment(month, 'YYYYMM').format('MM/YYYY'),
          service: '',
          openingDebt: textAlignRight(formatCurrency(total?.[month]?.openingDebt?.TOTAL ?? 0)),
          openingPaidBefore: textAlignRight(formatCurrency(total?.[month]?.openingPaidBefore?.TOTAL ?? 0)),
          receivables: textAlignRight(formatCurrency(total?.[month]?.inPeriodMustPay?.TOTAL ?? 0)),
          adjust: textAlignRight(formatCurrency(total?.[month]?.adjust?.TOTAL ?? 0)),
          deduct: textAlignRight(formatCurrency(total?.[month]?.inPeriodDeductPaidBefore?.TOTAL ?? 0)),
          paidInPeriod: textAlignRight(formatCurrency(total?.[month]?.inPeriodPaid?.TOTAL ?? 0)),
          paidDebt: textAlignRight(formatCurrency(total?.[month]?.inPeriodPaidDebt?.TOTAL ?? 0)),
          paidBefore: textAlignRight(formatCurrency(total?.[month]?.inPeriodPaidBefore?.TOTAL ?? 0)),
          total: textAlignRight(formatCurrency(total?.[month]?.inPeriodPaidTotal?.TOTAL ?? 0)),
          remainingReceivables: textAlignRight(formatCurrency(total?.[month]?.inPeriodRest?.TOTAL ?? 0)),
          closingDebt: textAlignRight(formatCurrency(total?.[month]?.closingDebt?.TOTAL ?? 0)),
          closingPaidBefore: textAlignRight(formatCurrency(total?.[month]?.closingPaidBefore?.TOTAL ?? 0)),
          children: [],
        };
      }
      const nestedSecondRow = {
        key: `${unit?.unit?.id}.${month}`,
        customerCode: '',
        apartment: month === 'TOTAL' ? language.total : moment(month, 'YYYYMM').format('MM/YYYY'),
        service: '',
        openingDebt: textAlignRight(formatCurrency(unit?.[month]?.openingDebt?.TOTAL ?? 0)),
        openingPaidBefore: textAlignRight(formatCurrency(unit?.[month]?.openingPaidBefore?.TOTAL ?? 0)),
        receivables: textAlignRight(formatCurrency(unit?.[month]?.inPeriodMustPay?.TOTAL ?? 0)),
        adjust: textAlignRight(formatCurrency(unit?.[month]?.inPeriodAdjust?.TOTAL ?? 0)),
        deduct: textAlignRight(formatCurrency(unit?.[month]?.inPeriodDeductPaidBefore?.TOTAL ?? 0)),
        paidInPeriod: textAlignRight(formatCurrency(unit?.[month]?.inPeriodPaid?.TOTAL ?? 0)),
        paidDebt: textAlignRight(formatCurrency(unit?.[month]?.inPeriodPaidDebt?.TOTAL ?? 0)),
        paidBefore: textAlignRight(formatCurrency(unit?.[month]?.inPeriodPaidBefore?.TOTAL ?? 0)),
        total: textAlignRight(formatCurrency(unit?.[month]?.inPeriodPaidTotal?.TOTAL ?? 0)),
        remainingReceivables: textAlignRight(formatCurrency(unit?.[month]?.inPeriodRest?.TOTAL ?? 0)),
        closingDebt: textAlignRight(formatCurrency(unit?.[month]?.closingDebt?.TOTAL ?? 0)),
        closingPaidBefore: textAlignRight(formatCurrency(unit?.[month]?.closingPaidBefore?.TOTAL ?? 0)),
        children: [],
      };
      for (let indexService = 0; indexService < services.length; indexService += 1) {
        const service = services[indexService];
        let totalNestedThirdRow = {};
        if (indexUnit === 0) {
          totalNestedThirdRow = {
            key: `total.${month}.${service.id}`,
            customerCode: '',
            apartment: '',
            service: service.name,
            openingDebt: textAlignRight(formatCurrency(total?.[month]?.openingDebt?.[service.id])),
            openingPaidBefore: textAlignRight(formatCurrency(total?.[month]?.openingPaidBefore?.[service.id] ?? 0)),
            receivables: textAlignRight(formatCurrency(total?.[month]?.inPeriodMustPay?.[service.id] ?? 0)),
            adjust: textAlignRight(formatCurrency(total?.[month]?.inPeriodAdjust?.[service.id] ?? 0)),
            deduct: textAlignRight(formatCurrency(total?.[month]?.inPeriodDeductPaidBefore?.[service.id] ?? 0)),
            paidInPeriod: textAlignRight(formatCurrency(total?.[month]?.inPeriodPaid?.[service.id] ?? 0)),
            paidDebt: textAlignRight(formatCurrency(total?.[month]?.inPeriodPaidDebt?.[service.id] ?? 0)),
            paidBefore: textAlignRight(formatCurrency(total?.[month]?.inPeriodPaidBefore?.[service.id] ?? 0)),
            total: textAlignRight(formatCurrency(total?.[month]?.inPeriodPaidTotal?.[service.id] ?? 0)),
            remainingReceivables: textAlignRight(formatCurrency(total?.[month]?.inPeriodRest?.[service.id] ?? 0)),
            closingDebt: textAlignRight(formatCurrency(total?.[month]?.closingDebt?.[service.id] ?? 0)),
            closingPaidBefore: textAlignRight(formatCurrency(total?.[month]?.closingPaidBefore?.[service.id] ?? 0)),
          };
        }
        const nestedThirdRow = {
          key: `${unit?.unit?.id}.${month}.${service.id}`,
          customerCode: '',
          apartment: '',
          service: service.name,
          openingDebt: textAlignRight(formatCurrency(unit?.[month]?.openingDebt?.[service.id])),
          openingPaidBefore: textAlignRight(formatCurrency(unit?.[month]?.openingPaidBefore?.[service.id] ?? 0)),
          receivables: textAlignRight(formatCurrency(unit?.[month]?.inPeriodMustPay?.[service.id] ?? 0)),
          adjust: textAlignRight(formatCurrency(unit?.[month]?.inPeriodAdjust?.[service.id] ?? 0)),
          deduct: textAlignRight(formatCurrency(unit?.[month]?.inPeriodDeductPaidBefore?.[service.id] ?? 0)),
          paidInPeriod: textAlignRight(formatCurrency(unit?.[month]?.inPeriodPaid?.[service.id] ?? 0)),
          paidDebt: textAlignRight(formatCurrency(unit?.[month]?.inPeriodPaidDebt?.[service.id] ?? 0)),
          paidBefore: textAlignRight(formatCurrency(unit?.[month]?.inPeriodPaidBefore?.[service.id] ?? 0)),
          total: textAlignRight(formatCurrency(unit?.[month]?.inPeriodPaidTotal?.[service.id] ?? 0)),
          remainingReceivables: textAlignRight(formatCurrency(unit?.[month]?.inPeriodRest?.[service.id] ?? 0)),
          closingDebt: textAlignRight(formatCurrency(unit?.[month]?.closingDebt?.[service.id] ?? 0)),
          closingPaidBefore: textAlignRight(formatCurrency(unit?.[month]?.closingPaidBefore?.[service.id] ?? 0)),
        };
        if (indexUnit === 0) {
          totalNestedSecondRow.children.push(totalNestedThirdRow);
        }
        nestedSecondRow.children.push(nestedThirdRow);
      }
      if (indexUnit === 0) {
        totalRow.children.push(totalNestedSecondRow);
      }
      row.children.push(nestedSecondRow);
    }
    if (indexUnit === 0) {
      rows.push(totalRow);
    }
    rows.push(row);
  }
  return rows;
};

export default {
  columns: buildColumns,
  buildDataSources,
};
