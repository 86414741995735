import { X_UNIT_GROUP_ID } from 'constants/http';
import { ACTIVE, INACTIVE } from 'constants/service';
import { call, put, all, takeLatest } from 'redux-saga/effects';
import { getsSettingRegistrationFormService, updateSettingRegistrationFormService } from 'services/residentRegistrationForm';
import { COVID_TEST, COVID_VACCINATION, VISITOR } from 'shared/constants';
import { SETTING_REGISTRATION_FORM_GETS, SETTING_REGISTRATION_FORM_UPDATE } from './resident-registration-form.types';

function* getsSettingRegistrationForm(action) {
  const { idBuilding } = action.payload;

  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  const result = yield call(getsSettingRegistrationFormService, headers, SETTING_REGISTRATION_FORM_GETS);

  yield put(result);
}

function* updateSettingRegistrationForm(action) {
  const {
    idBuilding,
    activeCovidTest,
    descriptionCovidTest,
    activeCovidVaccination,
    descriptionCovidVaccination,
    activeVisitor,
    autoApproveVisitorRegistrationForm,
  } = action.payload;

  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  const body = {
    formConfigs: [
      {
        type: COVID_TEST,
        status: activeCovidTest ? ACTIVE : INACTIVE,
        description: descriptionCovidTest,
      },
      {
        type: COVID_VACCINATION,
        status: activeCovidVaccination ? ACTIVE : INACTIVE,
        description: descriptionCovidVaccination,
      },
      {
        type: VISITOR,
        status: activeVisitor ? ACTIVE : INACTIVE,
        autoApprove: autoApproveVisitorRegistrationForm,
      },
    ],
  };

  const result = yield call(updateSettingRegistrationFormService, headers, body, SETTING_REGISTRATION_FORM_UPDATE);

  yield put(result);
  yield put({
    type: SETTING_REGISTRATION_FORM_UPDATE.REFRESH,
  });
}

export default function* watch() {
  yield all([
    takeLatest(SETTING_REGISTRATION_FORM_GETS.REQUEST, getsSettingRegistrationForm),
    takeLatest(SETTING_REGISTRATION_FORM_UPDATE.REQUEST, updateSettingRegistrationForm),
  ]);
}
