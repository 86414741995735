import { ASSET } from '../constants/actionType';

export const initPageAsset = (buildingId: number, dataFetching: Object) => ({
    type: ASSET.INIT.REQUEST,
    payload: {
        buildingId,
        dataFetching,
    }
});

export const getAssets = (buildingId, filters) => ({
    type: ASSET.GETS.REQUEST,
    payload: {
        buildingId,
        filters,
    }
});

export const getApartmentInFormAsset = (buildingId: number, name: string) => ({
    type: ASSET.FORM.SEARCH_APARTMENT.REQUEST,
    payload: {
        buildingId,
        name
    }
});

export const getPartnerInFormAsset = (buildingId: number, name: string) => ({
    type: ASSET.FORM.SEARCH_PARTNER.REQUEST,
    payload: {
        buildingId,
        name,
    }
});

export const getFloorInFormAsset = (buildingId: number) => ({
    type: ASSET.FORM.SEARCH_FLOOR.REQUEST,
    payload: {
        buildingId,
    }
});

export const getBlockInFormAsset = (buildingId: number) => ({
    type: ASSET.FORM.SEARCH_BLOCK.REQUEST,
    payload: {
        buildingId
    }
});

export const addAsset = (asset, unitGroupId) => ({
    type: ASSET.ADD.REQUEST,
    payload: {
        asset,
        unitGroupId,
    },
});

export const refreshAddAsset = () => ({
    type: ASSET.ADD.REFRESH,
});

export const updateAsset = (id, asset, unitGroupId) => ({
    type: ASSET.UPDATE.REQUEST,
    payload: {
        id,
        asset,
        unitGroupId,
    },
});

export const refreshUpdateAsset = () => ({
    type: ASSET.UPDATE.REFRESH,
});

export const deleteAsset = (assetId, unitGroupId) => ({
    type: ASSET.DELETE.REQUEST,
    payload: {
        assetId,
        unitGroupId,
    },
});

export const refreshDeleteAsset = () => ({
    type: ASSET.DELETE.REFRESH,
});

export const addMaintenance = (maintenance: Object) => ({
    type: ASSET.MAINTENANCE.ADD.REQUEST,
    payload: {
        maintenance
    }
});

export const refreshAddMaintenance = () => ({
    type: ASSET.MAINTENANCE.ADD.REFRESH,
});

export const changeStatusAsset = (data) => ({
    type: ASSET.CHANGE_STATUS.REQUEST,
    payload: data
});

export const importAsset = (buildingId, file, filterObject) => ({
    type: ASSET.IMPORT.REQUEST,
    payload: {
        buildingId,
        file,
        filterObject,
    },
});

export const resetDataImportAsset = () => ({
    type: ASSET.IMPORT.SUCCESS,
    payload: {
        currentPage: 0,
        totalPage: 0,
        totalRecord: 0,
        result: [],
    },
});

export const getMaintenanceUnit = (buildingId, name) => ({
    type: ASSET.PARTNER.GETS.REQUEST,
    payload: {
        buildingId,
        name,
    },
});

export const getAssetHistory = (buildingId, filters) => ({
    type: ASSET.HISTORY_GETS.REQUEST,
    payload: {
        buildingId,
        filters,
    },
});

export const exportAsset = (buildingId, filters) => ({
    type: ASSET.EXPORT.REQUEST,
    payload: {
        buildingId,
        filters,
    }
});
