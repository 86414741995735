import { combineReducers } from 'redux';
import paginate, { initStatePagination } from 'shared/utils/paginate';
import {
  PAYMENT_SLIP_GETS,
  PAYMENT_SLIP_SUM_TOTAL_PAYMENT,
  PAYMENT_SLIP_EXPORT,
  PAYMENT_SLIP_PREVIEW,
  PAYMENT_SLIP_DELETE,
  PAYMENT_SLIP_UNIT_GETS,
  PAYMENT_SLIP_RESIDENT_GETS,
  PAYMENT_SLIP_ADD,
  PAYMENT_SLIP_UPDATE,
  PAYMENT_SLIP_BILL_ADJUST,
} from './paymentSlip.types';

export const initState = {
  data: {
    ...initStatePagination,
  },
  sumTotalPayment: {
    ...initStatePagination,
  },
  export: {
    ...initStatePagination,
  },
  preview: {
    ...initStatePagination,
  },
  delete: {
    ...initStatePagination,
  },
  units: {
    ...initStatePagination,
  },
  residents: {
    ...initStatePagination,
  },
  add: {
    ...initStatePagination,
  },
  update: {
    ...initStatePagination,
  },
  billAdjusts: {
    ...initStatePagination,
  },
};

export default combineReducers({
  data: paginate(PAYMENT_SLIP_GETS),
  sumTotalPayment: paginate(PAYMENT_SLIP_SUM_TOTAL_PAYMENT),
  export: paginate(PAYMENT_SLIP_EXPORT),
  preview: paginate(PAYMENT_SLIP_PREVIEW),
  delete: paginate(PAYMENT_SLIP_DELETE),
  units: paginate(PAYMENT_SLIP_UNIT_GETS),
  residents: paginate(PAYMENT_SLIP_RESIDENT_GETS),
  add: paginate(PAYMENT_SLIP_ADD),
  update: paginate(PAYMENT_SLIP_UPDATE),
  billAdjusts: paginate(PAYMENT_SLIP_BILL_ADJUST),
});
