import { initStatePagination } from './paginate';
import paginate from './paginate';
import { FORM_STAFF } from '../constants/actionType';
import { combineReducers } from 'redux';
import { initStateFetching } from './fetching';
import fetching from './fetching';

export const initState = {
    formInit: {
        ...initStateFetching,
    },
    departments: {
        ...initStatePagination,
    },

};

const formInit = fetching(FORM_STAFF.INIT);
const departments = paginate(FORM_STAFF.DEPARTMENT.GETS);

export default combineReducers({
    formInit,
    departments,
});
