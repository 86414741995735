import {
  getAmenitiesOrderService,
  paymentAmenitiesOrderService,
  refundAmenitiesOrderService,
  refundDepositAmenitiesOrderService,
} from 'services/amenities';
import { getReceiptByIdService } from 'services';
import { getPaySlipPreviewService } from 'services/paySlip';
import { put, call, takeLatest, all } from 'redux-saga/effects';
import { X_UNIT_GROUP_ID } from 'shared/constants';
import { AMENITIES_ORDER_DETAIL } from './amenities-detail.types';

function* getOrder(action) {
  try {
    const { orderId = '', buildingId } = action.payload;
    const headers = {
      [X_UNIT_GROUP_ID]: buildingId,
    };
    const result = yield call(
      getAmenitiesOrderService,
      {
        params: { id: orderId },
        headers,
      },
      AMENITIES_ORDER_DETAIL.GET
    );
    yield put({
      ...result,
    });
  } catch (e) {
    yield put({
      type: AMENITIES_ORDER_DETAIL.GET.FAIL,
      payload: {
        code: 0,
        error: e,
      },
    });
  }
}

function* paymentOrder(action) {
  try {
    const { orderId, buildingId, params } = action.payload;
    const headers = {
      [X_UNIT_GROUP_ID]: buildingId,
    };
    const body = {
      id: orderId,
      ...params,
    };
    const result = yield call(
      paymentAmenitiesOrderService,
      {
        headers,
        body,
      },
      AMENITIES_ORDER_DETAIL.PAYMENT
    );
    yield put({
      ...result,
    });
  } catch (e) {
    yield put({
      type: AMENITIES_ORDER_DETAIL.PAYMENT.FAIL,
      payload: {
        code: 0,
        error: e,
      },
    });
  }
}

function* refundOrder(action) {
  try {
    const { orderId, buildingId, params } = action.payload;
    const headers = {
      [X_UNIT_GROUP_ID]: buildingId,
    };
    const body = {
      id: orderId,
      ...params,
    };
    const result = yield call(
      refundAmenitiesOrderService,
      {
        headers,
        body,
      },
      AMENITIES_ORDER_DETAIL.REFUND
    );
    yield put({
      ...result,
    });
  } catch (e) {
    yield put({
      type: AMENITIES_ORDER_DETAIL.REFUND.FAIL,
      payload: {
        code: 0,
        error: e,
      },
    });
  }
}

function* refundDepositOrder(action) {
  try {
    const { orderId, buildingId, params } = action.payload;
    const headers = {
      [X_UNIT_GROUP_ID]: buildingId,
    };
    const body = {
      id: orderId,
      ...params,
    };
    const result = yield call(
      refundDepositAmenitiesOrderService,
      {
        headers,
        body,
      },
      AMENITIES_ORDER_DETAIL.REFUND_DEPOSIT
    );
    yield put({
      ...result,
    });
  } catch (e) {
    yield put({
      type: AMENITIES_ORDER_DETAIL.REFUND_DEPOSIT.FAIL,
      payload: {
        code: 0,
        error: e,
      },
    });
  }
}

function* previewReceipt(action) {
  try {
    const { receiptId, unitGroupId } = action.payload;

    const result = yield call(getReceiptByIdService, { unitGroupId, receiptId }, AMENITIES_ORDER_DETAIL.PREVIEW_RECEIPT);
    yield put(result);
  } catch (e) {
    yield put({
      type: AMENITIES_ORDER_DETAIL.PREVIEW_RECEIPT.FAIL,
      payload: {
        code: 0,
        error: e,
      },
    });
  }
}

function* previewPaySlip(action) {
  try {
    const { unitGroupId, paySlipId } = action.payload;

    const result = yield call(getPaySlipPreviewService, unitGroupId, paySlipId, AMENITIES_ORDER_DETAIL.PREVIEW_PAYSLIP);
    yield put(result);
  } catch (e) {
    yield put({
      type: AMENITIES_ORDER_DETAIL.PREVIEW_PAYSLIP.FAIL,
      payload: {
        code: 0,
        error: e,
      },
    });
  }
}

export default function* watch() {
  yield all([
    takeLatest(AMENITIES_ORDER_DETAIL.GET.REQUEST, getOrder),
    takeLatest(AMENITIES_ORDER_DETAIL.PAYMENT.REQUEST, paymentOrder),
    takeLatest(AMENITIES_ORDER_DETAIL.REFUND.REQUEST, refundOrder),
    takeLatest(AMENITIES_ORDER_DETAIL.REFUND_DEPOSIT.REQUEST, refundDepositOrder),
    takeLatest(AMENITIES_ORDER_DETAIL.PREVIEW_RECEIPT.REQUEST, previewReceipt),
    takeLatest(AMENITIES_ORDER_DETAIL.PREVIEW_PAYSLIP.REQUEST, previewPaySlip),
  ]);
}
