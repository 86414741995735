import {
    TAG_GETS,
    TAG_ADD,
    TAG_UPDATE,
    TAG_DELETE
} from '../defined/API';
import {callApi} from './api';

export const getTags = (buildingId: string) => callApi(`${TAG_GETS}/${buildingId}`, {}, 'GET');
export const addTag = (tag: Object) => callApi(TAG_ADD, tag, 'POST');
export const updateTag = (tag: Object) => callApi(TAG_UPDATE, tag, 'PUT');
export const deleteTag = (tag: Object) => callApi(`${TAG_DELETE}/${tag.id}`, {}, 'DELETE');