import { FIREBASE } from '../../constants/actionType';
import { call, put } from 'redux-saga/effects';
import { sendDeviceTokenService } from '../../services/fcm';

export function* sendDeviceToken(action) {
  const { data } = action.payload;

  const result = yield call(sendDeviceTokenService, data, FIREBASE.FCM.SEND_DEVICE_TOKEN);

  yield put(result);
}