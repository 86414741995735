import { combineReducers } from 'redux';
import {
  REGISTRATION_FORM_COVID_TEST_ACTION_CANCEL,
  REGISTRATION_FORM_COVID_TEST_ACTION_EXPORT,
  REGISTRATION_FORM_COVID_TEST_ACTION_GETS,
  REGISTRATION_FORM_COVID_TEST_ACTION_UPDATE,
} from './covid-test.types';

export const initState = {
  gets: {
    isFetching: false,
    data: [],
    totalRecord: 0,
  },
  updateNote: {
    isFetching: false,
    code: undefined,
    codeLanguage: undefined,
  },
  cancel: {
    isFetching: false,
    code: undefined,
    codeLanguage: undefined,
  },
  export: {
    isFetching: false,
    code: undefined,
    codeLanguage: undefined,
    data: undefined,
  },
};

const reducer = combineReducers({
  gets: (state = initState.gets, action) => {
    switch (action.type) {
      case REGISTRATION_FORM_COVID_TEST_ACTION_GETS.REQUEST: {
        return {
          ...state,
          isFetching: true,
        };
      }
      case REGISTRATION_FORM_COVID_TEST_ACTION_GETS.SUCCESS: {
        return {
          isFetching: false,
          data: action.payload.result,
          totalRecord: action.payload.totalRecord,
        };
      }
      case REGISTRATION_FORM_COVID_TEST_ACTION_GETS.FAIL:
      case REGISTRATION_FORM_COVID_TEST_ACTION_GETS.REFRESH: {
        return {
          ...initState.gets,
        };
      }
      default: {
        return state;
      }
    }
  },
  updateNote: (state = initState.updateNote, action) => {
    switch (action.type) {
      case REGISTRATION_FORM_COVID_TEST_ACTION_UPDATE.REQUEST: {
        return {
          isFetching: true,
          code: undefined,
          codeLanguage: undefined,
        };
      }
      case REGISTRATION_FORM_COVID_TEST_ACTION_UPDATE.SUCCESS: {
        return {
          isFetching: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
        };
      }
      case REGISTRATION_FORM_COVID_TEST_ACTION_UPDATE.FAIL:
      case REGISTRATION_FORM_COVID_TEST_ACTION_UPDATE.REFRESH: {
        return {
          ...initState.updateNote,
        };
      }
      default: {
        return state;
      }
    }
  },
  cancel: (state = initState.updateNote, action) => {
    switch (action.type) {
      case REGISTRATION_FORM_COVID_TEST_ACTION_CANCEL.REQUEST: {
        return {
          isFetching: true,
          code: undefined,
          codeLanguage: undefined,
        };
      }
      case REGISTRATION_FORM_COVID_TEST_ACTION_CANCEL.SUCCESS: {
        return {
          isFetching: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
        };
      }
      case REGISTRATION_FORM_COVID_TEST_ACTION_CANCEL.FAIL:
      case REGISTRATION_FORM_COVID_TEST_ACTION_CANCEL.REFRESH: {
        return {
          ...initState.cancel,
        };
      }
      default: {
        return state;
      }
    }
  },
  export: (state = initState.export, action) => {
    switch (action.type) {
      case REGISTRATION_FORM_COVID_TEST_ACTION_EXPORT.REQUEST: {
        return {
          isFetching: true,
          code: undefined,
          codeLanguage: undefined,
          data: undefined,
        };
      }
      case REGISTRATION_FORM_COVID_TEST_ACTION_EXPORT.SUCCESS: {
        return {
          isFetching: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: action.payload.result,
        };
      }
      case REGISTRATION_FORM_COVID_TEST_ACTION_EXPORT.FAIL:
      case REGISTRATION_FORM_COVID_TEST_ACTION_EXPORT.REFRESH: {
        return {
          ...initState.export,
        };
      }
      default: {
        return state;
      }
    }
  },
});

export default reducer;
