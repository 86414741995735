import { initStateFetching } from './fetching';
import fetching from './fetching';
import { combineReducers } from 'redux';
import { SURCHARGE } from '../constants/actionType';

export const initState = {
    surchargeAdd: {
        ...initStateFetching
    }
};

const surchargeAdd = fetching(SURCHARGE.ADD);

export default combineReducers({
    surchargeAdd
});