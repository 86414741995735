import { combineReducers } from 'redux';
import paginate, { initStatePagination } from './paginate';
import fetching, { initStateFetching } from './fetching';
import { NEWS } from '../constants/actionType';

export const initState = {
  investor: {
    ...initStatePagination,
  },
}

const newsDataById = paginate(NEWS.GET_BY_ID);
const handleNewsDataById = reducer => (state, action) => {
  switch (action.type) {
    case NEWS.GET_BY_ID.REFRESH:
      return {
        ...initStatePagination
      }
    default:
      return reducer(state, action);
  }
};

const reducer = combineReducers({
  newsData: paginate(NEWS.GETS),
  newsDataById: handleNewsDataById(newsDataById),
  newsAdd: fetching(NEWS.ADD),
  newsDelete: fetching(NEWS.DELETE),
  investor: paginate(NEWS.INVESTOR.GETS),
});

export default reducer;