import { call, put, all, takeLatest } from 'redux-saga/effects';
import {
  getReceiptService,
  getSumTotalPaymentReceiptService,
  cancelReceiptService,
  getReceiptByIdService,
  exportReceiptService,
  createReceiptManualService,
  updateReceiptService,
} from 'services';
import { getApartments } from 'services/apartment';
import { X_UNIT_GROUP_ID } from 'shared/constants';
import { STATISTIC_RECEIPT } from './receipt.types';

function* getReceipts(action) {
  const { idBuilding, filters } = action.payload;

  const headers = { [X_UNIT_GROUP_ID]: idBuilding };

  const [receipts, sumPayment] = yield all([
    call(getReceiptService, { headers, params: filters }, STATISTIC_RECEIPT.GETS),
    call(
      getSumTotalPaymentReceiptService,
      { headers, params: { ...filters, page: undefined, limit: undefined } },
      STATISTIC_RECEIPT.SUM_TOTAL_PAYMENT
    ),
  ]);

  yield put(receipts);
  yield put(sumPayment);
}

export function* cancelReceipt(action) {
  const { receiptId, type, reason, fundId, isAllBill, bills, unitGroupId } = action.payload;

  const body = { type, reason, fundId, isAllBill, bills };

  const headers = { [X_UNIT_GROUP_ID]: unitGroupId };

  const result = yield call(cancelReceiptService, { receiptId, body, headers }, STATISTIC_RECEIPT.CANCEL);

  yield put(result);
  yield put({ type: STATISTIC_RECEIPT.CANCEL.REFRESH });
}

export function* getReceiptById(action) {
  const { receiptId, unitGroupId } = action.payload;

  const result = yield call(getReceiptByIdService, { unitGroupId, receiptId }, STATISTIC_RECEIPT.GET_BY_ID);

  yield put(result);
}

export function* exportReceipt(action) {
  const { unitGroupId, filterObject } = action.payload;

  const params = { ...filterObject };

  const headers = { [X_UNIT_GROUP_ID]: unitGroupId };

  const result = yield call(exportReceiptService, { params, headers }, STATISTIC_RECEIPT.EXPORT);

  yield put(result);
  yield put({ type: STATISTIC_RECEIPT.EXPORT.REFRESH });
}

export function* getUnitInReceiptForm(action) {
  const { idBuilding, name } = action.payload;

  const result = yield call(getApartments, idBuilding, 1, 20, { name }, STATISTIC_RECEIPT.GET_UNITS_IN_RECEIPT_FORM);

  yield put(result);
  yield put({ type: STATISTIC_RECEIPT.EXPORT.REFRESH });
}

export function* createReceiptManual(action) {
  const { unitGroupId, receipt } = action.payload;

  const headers = { [X_UNIT_GROUP_ID]: unitGroupId };

  const result = yield call(createReceiptManualService, { body: receipt, headers }, STATISTIC_RECEIPT.ADD);

  yield put(result);
  yield put({ type: STATISTIC_RECEIPT.ADD.REFRESH });
}

export function* updateReceipt(action) {
  const { unitGroupId, receipt } = action.payload;

  const headers = { [X_UNIT_GROUP_ID]: unitGroupId };

  const result = yield call(updateReceiptService, { body: receipt, headers, receiptId: receipt.id }, STATISTIC_RECEIPT.UPDATE);

  yield put(result);
  yield put({ type: STATISTIC_RECEIPT.UPDATE.REFRESH });
}

export default function* watchReceipt() {
  yield takeLatest(STATISTIC_RECEIPT.GETS.REQUEST, getReceipts);
  yield takeLatest(STATISTIC_RECEIPT.CANCEL.REQUEST, cancelReceipt);
  yield takeLatest(STATISTIC_RECEIPT.GET_BY_ID.REQUEST, getReceiptById);
  yield takeLatest(STATISTIC_RECEIPT.EXPORT.REQUEST, exportReceipt);
  yield takeLatest(STATISTIC_RECEIPT.GET_UNITS_IN_RECEIPT_FORM.REQUEST, getUnitInReceiptForm);
  yield takeLatest(STATISTIC_RECEIPT.ADD.REQUEST, createReceiptManual);
  yield takeLatest(STATISTIC_RECEIPT.UPDATE.REQUEST, updateReceipt);
}
