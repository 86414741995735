import { PERMISSION } from '../constants/actionType';
import {initStatePagination} from './paginate';
import paginate from './paginate';
import {combineReducers} from 'redux';

export const initState = {
    permissionGets: {
        ...initStatePagination
    },
};

const permissionGets = paginate(PERMISSION.GETS);

export default combineReducers({
    permissionGets,
});