import { combineReducers } from 'redux';
import {
  REGISTRATION_FORM_RECEIVE_PACKAGE_ACTION_GETS,
  REGISTRATION_FORM_RECEIVE_PACKAGE_ACTION_UPDATE_STATUS,
} from './receive-package.types';

export const initState = {
  gets: {
    isFetching: false,
    data: [],
    totalRecord: 0,
  },
  updateStatus: {
    code: undefined,
    codeLanguage: undefined,
    isFetching: false,
  },
};

const reducer = combineReducers({
  gets: (state = initState.gets, action) => {
    switch (action.type) {
      case REGISTRATION_FORM_RECEIVE_PACKAGE_ACTION_GETS.REQUEST: {
        return {
          ...state,
          isFetching: true,
        };
      }
      case REGISTRATION_FORM_RECEIVE_PACKAGE_ACTION_GETS.SUCCESS: {
        return {
          isFetching: false,
          data: action.payload.result,
          totalRecord: action.payload.totalRecord,
        };
      }
      case REGISTRATION_FORM_RECEIVE_PACKAGE_ACTION_GETS.FAIL:
      case REGISTRATION_FORM_RECEIVE_PACKAGE_ACTION_GETS.REFRESH: {
        return {
          ...initState.gets,
        };
      }
      default: {
        return state;
      }
    }
  },
  updateStatus: (state = initState.gets, action) => {
    switch (action.type) {
      case REGISTRATION_FORM_RECEIVE_PACKAGE_ACTION_UPDATE_STATUS.REQUEST: {
        return {
          ...state,
          isFetching: true,
        };
      }
      case REGISTRATION_FORM_RECEIVE_PACKAGE_ACTION_UPDATE_STATUS.SUCCESS:
      case REGISTRATION_FORM_RECEIVE_PACKAGE_ACTION_UPDATE_STATUS.FAIL: {
        return {
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isFetching: action.payload.isFetching,
        };
      }
      case REGISTRATION_FORM_RECEIVE_PACKAGE_ACTION_UPDATE_STATUS.REFRESH: {
        return {
          ...initState.updateStatus,
        };
      }
      default: {
        return state;
      }
    }
  },
});

export default reducer;
