//action chat type
export const CHAT_LOGIN = 'CHAT_LOGIN';
export const CHAT_GET_ROOM = 'CHAT_GET_ROOM';
export const CHAT_SUB_ROOM = 'CHAT_SUB_ROOM';
export const CHAT_SEND_MESSAGE = 'CHAT_SEND_MESSAGE';
export const CHAT_RECEIVED_MESSAGE = 'CHAT_RECEIVED_MESSAGE';
export const CHAT_LOAD_HISTORY = 'CHAT_LOAD_HISTORY';
export const CHAT_SELECT_ROOM = 'CHAT_SELECT_ROOM';
export const CHAT_GET_SUBSCRIPTIONS = 'CHAT_GET_SUBSCRIPTIONS';
export const CHAT_CHANGE_SUBSCRIPTIONS = 'CHAT_CHANGE_SUBSCRIPTIONS';
export const CHAT_GO_TO_ROOM = 'CHAT_GO_TO_ROOM';
export const CHAT_CREATE_GROUP = 'CHAT_CREATE_GROUP';
export const CHAT_CREATE_GROUP_SUCCESS = 'CHAT_CREATE_GROUP_SUCCESS';
export const CHAT_CREATE_GROUP_FAILURE = 'CHAT_CREATE_GROUP_FAILURE';

// *** group chat types
export const RESIDENT_VS_EMPLOYEE = 'RESIDENT_VS_EMPLOYEE'; // cu dan chat voi BQL
export const EMPLOYEE_VS_EMPLOYEE = 'EMPLOYEE_VS_EMPLOYEE'; // BQL chat voi nhau
export const CHAT_PRIVATE = 'CHAT_PRIVATE'; // chat rieng voi ai do

export class Message {
    /**
     * Message object for organizing and storing current message data.
     */

    constructor({ id, message, senderName, detail }) {
        if (detail) {
            this.detail = detail;
            this.id = detail.chatId; // id of the sender (0 is reserved for "blue bubble")
            this.message = detail.msg;
            this.senderName = detail.u.name;
        } else {
            this.id = id; // id of the sender (0 is reserved for "blue bubble")
            this.message = message;
            this.senderName = senderName || undefined;
        }
    }
}

Message.propTypes = {
    id: String | Number,
    message: String,
    senderName: String,
    detail: Object,
};