import firebase from "firebase/app";
import 'firebase/messaging';
import { CH_INSTALLATION } from './constants/firebase';

// Config options
const firebaseConfig = {
  apiKey: process.env.FCM_API_KEY,
  projectId: process.env.FCM_PROJECT_ID,
  appId: process.env.FCM_APP_ID,
  messagingSenderId: process.env.FCM_SENDER_ID,
}

firebase.initializeApp(firebaseConfig);

let messaging;

// we need to check if messaging is supported by the browser
if (firebase.messaging.isSupported() && !messaging) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(process.env.FCM_KEY_PAIR);
};

const deleteDeviceToken = () => {
  const deviceToken = localStorage.getItem(CH_INSTALLATION);
  if (deviceToken) {
    messaging.deleteToken(deviceToken);
    localStorage.removeItem(CH_INSTALLATION);
  };
  return deviceToken;
}

export { messaging, deleteDeviceToken };