import { useEffect } from 'react';
import notification from 'components/Notification';
import { language } from 'defined/Language';
import { SUCCESS_CODE, RESPONSE_MAPPING } from 'shared/constants';

const useNotificationResponse = (code, codeLanguage, successConfig, callbackSuccess = () => {}) => {
  useEffect(() => {
    if (code) {
      if (SUCCESS_CODE.includes(code)) {
        if (successConfig) {
          notification.success({
            ...successConfig,
            style: {
              zIndex: 2000,
            },
          });
        }
        callbackSuccess();
      } else {
        notification.error({
          message: language.label.error,
          description: RESPONSE_MAPPING[codeLanguage || 'FAIL'],
          style: {
            zIndex: 2000,
          },
        });
      }
    }
  }, [code]);
};

export default useNotificationResponse;
