import { language } from 'defined/Language';

// eslint-disable-next-line import/prefer-default-export
export const DEAL_METHOD_OPTION = [
  {
    id: 'TRANSFER',
    name: language.label.transfer,
  },
  {
    id: 'CASH',
    name: language.label.cash,
  },
  {
    id: 'ONLINE',
    name: language.label.online,
  },
];
