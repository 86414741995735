import { language } from 'defined/Language';

export const RECEIPT_STATUS = [
  { id: 'NOT_CANCELED', name: language.label.not_canceled },
  { id: 'CANCELED', name: language.label.canceled },
];

export const RECEIPT_CANCEL_TYPE_OPTIONS = [
  { id: 'PAY_TO_UNIT_ACCOUNT', name: language.label.transfer_to_apartment_account }, // Receipt top up to unit account only
  { id: 'CREATE_PAY_SLIP', name: language.label.create_pay_slip },
];

export const RECEIPT_PAYMENT_METHOD_OPTIONS = [
  { id: 'TRANSFER', name: language.label.transfer },
  { id: 'CASH', name: language.label.cash },
];

export const EXPORT_RECEIPT_TYPE = new Map([
  [
    'RECEIPT',
    {
      id: 'RECEIPT',
      name: language.label.receipt,
      fileName: 'thong-tin-phieu-thu.xlsx',
    },
  ],
  [
    'PAYMENT_SHEET',
    {
      id: 'PAYMENT_SHEET',
      name: language.label.payment_sheet,
      fileName: 'bang-ke-thu-tien.xlsx',
    },
  ],
]);

export const APARTMENT_ACCOUNT_TYPE_OPTIONS = [
  {
    id: 'DO_NOT_USING_UNIT_ACCOUNT',
    name: language.label.dont_use_apartment_account,
  },
  {
    id: 'PAY_TO_UNIT_ACCOUNT',
    name: language.label.transfer_to_apartment_account,
  },
  {
    id: 'PAY_USING_UNIT_ACCOUNT',
    name: language.label.payment_use_apartment_account,
  },
];

export const RECEIPT_OPTION_TYPES = [
  {
    id: 'MANUAL',
    name: language.label.manual_receipt,
  },
  {
    id: 'SERVICE_FEE',
    name: language.label.service_fee_receipt,
  },
  {
    id: 'CASH_TO_UNIT_ACCOUNT',
    name: language.label.cash_to_apartment_account_receipt,
  },
];
