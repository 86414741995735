import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import map from 'lodash/map';
import { SUCCESS_CODE } from 'shared/constants';
import { getPermission as getPermissionAction } from '../../actions/permission';

function hasAnyAuthority(authorities = [], hasAnyAuthorities = []) {
  if (authorities && authorities.length !== 0) {
    if (hasAnyAuthorities.length === 0) {
      return true;
    }
    return hasAnyAuthorities.some((auth) => authorities.includes(auth));
  }
  return false;
}

const usePermission = () => {
  // TODO: setup authorities
  const authorities = useSelector((state) => {
    const data = get(state, 'permission.permissionGets.data', {});
    // if (data.name === 'Admin') {
    //   return permissions;
    // }
    return map(data, (item) => item.name);
  });
  const code = useSelector((state) => state.permission.permissionGets.code);
  // const authorities = permissions;
  const dispatch = useDispatch();

  function getPermission(unitGroupId) {
    if (!SUCCESS_CODE.includes(code)) {
      dispatch(getPermissionAction(unitGroupId));
    }
  }

  function checkViewPermission(hasAnyAuthorities) {
    return hasAnyAuthority(authorities, hasAnyAuthorities);
  }

  return [checkViewPermission, getPermission, authorities];
};

export default usePermission;
