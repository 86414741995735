import { call, put } from 'redux-saga/effects';
import { SERVICE } from '../constants/actionType';
import {
    getServiceContracts,
    getContractTemplateService1,
    getApartments,
    addContracts,
    activeContractService,
    activeMultiContractService,
    inactiveContractService,
    inactiveMultiContractService,
    importContractService,
    exportContractService,
    getServiceInUseService,
    updateContractService,
    deleteContractsService, downloadFileTemplateContractService, getSumTotalServiceContracts,
} from '../services';
import { PAGINATION, X_UNIT_GROUP_ID } from '../constants';

export function* getServicesHandle(action) {
    const { buildingId } = action.payload;
    const result = yield call(getServiceInUseService, {
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, SERVICE.GETS);
    yield put(result);
}

export function* getServiceContractsHandle(action) {
    const { filter, page } = action;
    const result = yield call(getServiceContracts, filter, page, SERVICE.CONTRACT.GETS);
    yield put(result);
}

export function* getSumTotalContractsHandle(action) {
    const { filter, page } = action;
    const result = yield call(getSumTotalServiceContracts, filter, page, SERVICE.CONTRACT.SUM_TOTAL);
    yield put(result);
}

export function* getContractTemplatesHandle(action) {
    const { filter, page = 1, size = PAGINATION.LIMIT } = action;
    const params = {
        serviceId: filter.serviceId,
    };
    const headers = {
        [X_UNIT_GROUP_ID]: filter.buildingId || filter.unitGroupId,
    };
    const result = yield call(getContractTemplateService1, { params, headers }, SERVICE.CONTRACT_TEMPLATE.GETS);
    yield put(result);
}

export function* getUnits(action) {
    const { filter, page = 1, buildingId } = action;
    const result = yield call(getApartments, buildingId, page, {
        ...filter,
    }, SERVICE.UNIT.GETS);
    yield put(result);
}

export function* addContractsHandle(action) {
    const { contracts, unitGroupId } = action;
    const result = yield call(addContracts, { contracts, unitGroupId }, SERVICE.CONTRACT.ADDS);
    yield put(result);
    yield put({
        type: SERVICE.CONTRACT.ADDS.REFRESH,
    });
}

export function* deleteContracts(action) {
    const {
        isAll,
        ids,
        unitGroupId,
        serviceId,
        filterObject,
    } = action.payload;
    const result = yield call(deleteContractsService, { isAll, ids, serviceId, unitGroupId, filterObject }, SERVICE.CONTRACT.DELETE);
    yield put(result);
    yield put({
        type: SERVICE.CONTRACT.DELETE.REFRESH,
    });
}

export function* updateContract(action) {
    const {
        contract,
        unitGroupId,
    } = action.payload;
    const result = yield call(updateContractService, {
        contract,
        unitGroupId,
    }, SERVICE.CONTRACT.UPDATE);
    yield put(result);
    yield put({
        type: SERVICE.CONTRACT.UPDATE.REFRESH,
    });
}

export function* activeContract(action) {
    const {
        contractId,
        buildingId,
    } = action.payload;
    const result = yield call(activeContractService, contractId, buildingId, SERVICE.CONTRACT.ACTIVE);
    yield put(result);
    yield put({
        type: SERVICE.CONTRACT.ACTIVE.REFRESH,
    });
}

export function* activeMultiContract(action) {
    const {
        array,
        isAll,
        filterObject,
        buildingId,
        contractTemplateName
    } = action.payload;
    const result = yield call(activeMultiContractService, array, isAll, filterObject, buildingId, contractTemplateName, SERVICE.CONTRACT.ACTIVE_MULTI);
    yield put(result);
    yield put({
        type: SERVICE.CONTRACT.ACTIVE_MULTI.REFRESH,
    });
}

export function* inactiveContract(action) {
    const {
        contractId,
        buildingId,
    } = action.payload;
    const result = yield call(inactiveContractService, contractId, buildingId, SERVICE.CONTRACT.INACTIVE);
    yield put(result);
    yield put({
        type: SERVICE.CONTRACT.INACTIVE.REFRESH,
    });
}

export function* inactiveMultiContract(action) {
    const {
        array,
        isAll,
        filterObject,
        buildingId,
        contractTemplateName,
    } = action.payload;
    const result = yield call(inactiveMultiContractService,  array, isAll, filterObject, buildingId, contractTemplateName, SERVICE.CONTRACT.INACTIVE_MULTI);
    yield put(result);
    yield put({
        type: SERVICE.CONTRACT.INACTIVE_MULTI.REFRESH,
    });
}

export function* importContract(action) {
    const {
        buildingId,
        file,
        createdFor,
        startDate,
        endDate,
        identifier,
    } = action.payload;
    const result = yield call(importContractService, {
        dataAttach: {
            createdFor,
            startDate,
            endDate,
            identifier,
        },
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
        file,
    }, SERVICE.CONTRACT.IMPORT);
    yield put(result);
    yield put({
        type: SERVICE.CONTRACT.IMPORT.REFRESH,
    });
}

export function* exportContract(action) {
    const {
        buildingId,
        filterObject,
    } = action.payload;
    const params = {
        ...filterObject,
    };
    const headers = {
        [X_UNIT_GROUP_ID]: buildingId,
    };
    const result = yield call(exportContractService, { params, headers }, SERVICE.CONTRACT.EXPORT);
    yield put(result);
    yield put({
        type: SERVICE.CONTRACT.EXPORT.REFRESH,
    });
}

export function* downloadFileTemplateContract(action) {
    const {
        unitGroupId,
        serviceId,
        serviceType,
    } = action.payload;
    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };
    const result = yield call(downloadFileTemplateContractService, { serviceId, serviceType, headers }, SERVICE.DOWNLOAD_TEMPLATE);
    yield put(result);
    yield put({
        type: SERVICE.DOWNLOAD_TEMPLATE.REFRESH,
    });
}