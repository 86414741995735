import { initStatePagination } from './paginate';
import paginate from './paginate';
import { CONTRACT_TEMPLATE } from '../constants/actionType';
import { combineReducers } from 'redux';
import { initStateFetching } from './fetching';
import fetching from './fetching';

export const initState = {
    contractTemplateInit: {
        ...initStateFetching,
    },
    contractTemplateAdd: {
        ...initStateFetching,
    },
    contractTemplateDelete: {
        ...initStateFetching,
    },
    contractTemplateUpdate: {
        ...initStateFetching,
    },
    data: {
        ...initStatePagination,
    },
    services: {
        ...initStatePagination,
    },
};

const contractTemplateInit = fetching(CONTRACT_TEMPLATE.INIT);
const contractTemplateAdd = fetching(CONTRACT_TEMPLATE.ADD);
const contractTemplateUpdate = fetching(CONTRACT_TEMPLATE.UPDATE);
const contractTemplateDelete = fetching(CONTRACT_TEMPLATE.DELETE);
const data = paginate(CONTRACT_TEMPLATE.GETS);
const services = paginate(CONTRACT_TEMPLATE.SERVICE_GETS);

export default combineReducers({
    contractTemplateInit,
    contractTemplateAdd,
    contractTemplateUpdate,
    contractTemplateDelete,
    data,
    services,
});
