import {
    REPORT,
    X_UNIT_GROUP_ID,
} from '../../constants';
import { call, put } from 'redux-saga/effects';
import { getUnitWithBillsService, totalUnitWithBillsService, exportUnitWithBillsService } from '../../services/report';
import moment from 'moment';

export function* getDataMustPayReport(action) {
    const {
        unitGroupId,
        filterObject,
        page,
    } = action.payload;

    let { from, to } = filterObject;

    if (from && to && moment(from) > moment(to)) {
        yield put({
            type: REPORT.MUST_PAY.GETS.SUCCESS,
            payload: {
                data: [],
            }
        });
        return;
    }

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const params = {
        ...filterObject,
        page,
        limit: 20,
    };
    const result = yield call(getUnitWithBillsService, { params, headers }, REPORT.MUST_PAY.GETS);
    yield put(result);
}

export function* exportMustPayInPageReport(action) {
    const {
        unitGroupId,
        filterObject,
    } = action.payload;
    const params = {
        ...filterObject,
    };
    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };
    const result = yield call(exportUnitWithBillsService, { params, headers }, REPORT.MUST_PAY.EXPORT);;
    yield put(result);
    yield put({
        type: REPORT.MUST_PAY.EXPORT.REFRESH,
    });
}

export function* getSumTotalPaymentMustPay(action) {
    const {
        unitGroupId,
        filterObject,
    } = action.payload;

    let { from, to } = filterObject;

    if (from && to && moment(from) > moment(to)) {
        yield put({
            type: REPORT.MUST_PAY.GET_TOTAL.SUCCESS,
            payload: {
                data: {},
            }
        });
        return;
    }

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const params = {
        ...filterObject,
    };

    const result = yield call(totalUnitWithBillsService, { params, headers }, REPORT.MUST_PAY.GET_TOTAL);

    yield put(result);
}
