import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
  getPaySlipService,
  getSumTotalPaymentService,
  exportPaySlipService,
  getPaySlipPreviewService,
  cancelPaySlipService,
  addPaySlipService,
  updatePaySlipService,
} from 'services/paySlip';
import { getApartments, getListBillByUnit } from 'services';
import { getResidentsService } from 'services/resident';

import { X_UNIT_GROUP_ID } from 'shared/constants';
import {
  PAYMENT_SLIP_GETS,
  PAYMENT_SLIP_SUM_TOTAL_PAYMENT,
  PAYMENT_SLIP_EXPORT,
  PAYMENT_SLIP_PREVIEW,
  PAYMENT_SLIP_DELETE,
  PAYMENT_SLIP_UNIT_GETS,
  PAYMENT_SLIP_RESIDENT_GETS,
  PAYMENT_SLIP_ADD,
  PAYMENT_SLIP_UPDATE,
  PAYMENT_SLIP_BILL_ADJUST,
} from './paymentSlip.types';

function* getDataTable(action) {
  const { unitGroupId, filterObject, page } = action.payload;

  const result = yield call(getPaySlipService, unitGroupId, page, 20, filterObject, PAYMENT_SLIP_GETS);
  yield put(result);
  yield put({
    type: PAYMENT_SLIP_GETS.REFRESH,
  });
}

function* getSumTotalPayment(action) {
  const { unitGroupId, filterObject } = action.payload;

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const params = {
    ...filterObject,
  };

  const result = yield call(getSumTotalPaymentService, { params, headers }, PAYMENT_SLIP_SUM_TOTAL_PAYMENT);
  yield put(result);
  yield put({
    type: PAYMENT_SLIP_SUM_TOTAL_PAYMENT.REFRESH,
  });
}

function* exportPaymentSlip(action) {
  const { unitGroupId, filterObject } = action.payload;
  const params = {
    ...filterObject,
  };
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };
  const result = yield call(exportPaySlipService, { params, headers }, PAYMENT_SLIP_EXPORT);
  yield put(result);
  yield put({
    type: PAYMENT_SLIP_EXPORT.REFRESH,
  });
}

function* previewPaymentSlip(action) {
  const { unitGroupId, paySlipId } = action.payload;

  const result = yield call(getPaySlipPreviewService, unitGroupId, paySlipId, PAYMENT_SLIP_PREVIEW);
  yield put(result);
  yield put({
    type: PAYMENT_SLIP_PREVIEW.REFRESH,
  });
}

function* deletePaymentSlip(action) {
  const { unitGroupId, paySlipId, reason } = action.payload;

  const result = yield call(cancelPaySlipService, unitGroupId, paySlipId, { reason }, PAYMENT_SLIP_DELETE);
  yield put(result);
  yield put({
    type: PAYMENT_SLIP_DELETE.REFRESH,
  });
}

function* getUnitsPaymentSlip(action) {
  const { unitGroupId, name, page, limit } = action.payload;

  const result = yield call(getApartments, unitGroupId, page, limit, { name }, PAYMENT_SLIP_UNIT_GETS);
  yield put(result);
  yield put({
    type: PAYMENT_SLIP_UNIT_GETS.REFRESH,
  });
}

function* getResidentsPaymentSlip(action) {
  const { unitGroupId, fullName, page, limit, unitName } = action.payload;
  const params = {
    fullName,
    page,
    limit,
    unitName,
  };

  if (unitName) {
    delete params.page;
    delete params.limit;
  }

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(getResidentsService, { params, headers }, PAYMENT_SLIP_RESIDENT_GETS);
  yield put(result);
  yield put({
    type: PAYMENT_SLIP_RESIDENT_GETS.REFRESH,
  });
}

function* addPaymentSlip(action) {
  const { unitGroupId, params } = action.payload;

  const result = yield call(addPaySlipService, unitGroupId, params, PAYMENT_SLIP_ADD);
  yield put(result);
  yield put({
    type: PAYMENT_SLIP_ADD.REFRESH,
  });
}

function* updatePaymentSlip(action) {
  const { unitGroupId, params } = action.payload;

  const result = yield call(updatePaySlipService, unitGroupId, params, PAYMENT_SLIP_UPDATE);
  yield put(result);
  yield put({
    type: PAYMENT_SLIP_UPDATE.REFRESH,
  });
}

function* getBillAdjustPaymentSlip(action) {
  const { unitGroupId, filterObject } = action.payload;

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(getListBillByUnit, { params: filterObject, headers }, PAYMENT_SLIP_BILL_ADJUST);
  yield put(result);
}

// eslint-disable-next-line import/prefer-default-export
export function* watch() {
  yield all([
    takeLatest(PAYMENT_SLIP_GETS.REQUEST, getDataTable),
    takeLatest(PAYMENT_SLIP_SUM_TOTAL_PAYMENT.REQUEST, getSumTotalPayment),
    takeLatest(PAYMENT_SLIP_EXPORT.REQUEST, exportPaymentSlip),
    takeLatest(PAYMENT_SLIP_PREVIEW.REQUEST, previewPaymentSlip),
    takeLatest(PAYMENT_SLIP_DELETE.REQUEST, deletePaymentSlip),
    takeLatest(PAYMENT_SLIP_UNIT_GETS.REQUEST, getUnitsPaymentSlip),
    takeLatest(PAYMENT_SLIP_RESIDENT_GETS.REQUEST, getResidentsPaymentSlip),
    takeLatest(PAYMENT_SLIP_ADD.REQUEST, addPaymentSlip),
    takeLatest(PAYMENT_SLIP_UPDATE.REQUEST, updatePaymentSlip),
    takeLatest(PAYMENT_SLIP_BILL_ADJUST.REQUEST, getBillAdjustPaymentSlip),
  ]);
}
