import { ASSET } from '../../constants/actionType';
import { initStateFetching } from '../fetching';
import { initStatePagination } from '../paginate';
import paginate from '../paginate';
import fetching from '../fetching';
import { combineReducers } from 'redux';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';

export const initState = {
    assetGets: {
        ...initStatePagination,
    },
    assetAdd: {
        ...initStateFetching,
    },
    assetUpdate: {
        ...initStateFetching,
    },
    assetImport: {
        ...initStatePagination,
    },
    assetDelete: {
        ...initStateFetching,
    },
    assetInit: {
        ...initStateFetching,
    },
    apartmentGetInForm: {
        ...initStatePagination
    },
    partnerGetInForm: {
        ...initStatePagination
    },
    partners: {
        ...initStatePagination,
    },
    maintenanceAdd: {
        ...initStateFetching
    },
    assetChangeStatus: {
        ...initStateFetching,
    },
    floorGets: {
        ...initStatePagination,
    },
    departmentGets: {
        ...initStatePagination,
    },
    assetExport: { ...initStatePagination },
};

const assetInit = fetching(ASSET.INIT);
const assetAdd = fetching(ASSET.ADD);
const maintenanceAdd = fetching(ASSET.MAINTENANCE.ADD);
const assetUpdate = fetching(ASSET.UPDATE);
const assetDelete = fetching(ASSET.DELETE);
const assetGets = paginate(ASSET.GETS);
const apartmentGetInForm = paginate(ASSET.FORM.SEARCH_APARTMENT);
const partnerGetInForm = paginate(ASSET.FORM.SEARCH_PARTNER);
const partners = paginate(ASSET.PARTNER.GETS);
const assetChangeStatus = fetching(ASSET.CHANGE_STATUS);
const floorGets = paginate(ASSET.FLOOR.GETS);
const departmentGets = paginate(ASSET.DEPARTMENT.GETS);
const employeeGets = paginate(ASSET.EMPLOYEE.GETS);
const assetImport = paginate(ASSET.IMPORT);
const assetHistory = paginate(ASSET.HISTORY_GETS);
const assetExport = paginate(ASSET.EXPORT);

export default combineReducers({
    assetInit,
    assetAdd,
    maintenanceAdd,
    assetUpdate,
    assetImport,
    assetDelete,
    assetGets,
    partners,
    apartmentGetInForm,
    partnerGetInForm,
    assetChangeStatus,
    floorGets,
    departmentGets,
    employeeGets,
    assetHistory,
    assetExport
});
