import { handleError, handleResponse } from '../libs/http';
import { callApi } from './api';
import { PROFILE } from '../defined/API';
import { METHOD } from '../constants/http';

export const getProfileService = async (type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(PROFILE, null, METHOD.GET);
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const updateProfileService = async (body, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(PROFILE, body, METHOD.PUT);
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};