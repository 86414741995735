import {
    TEMPLATE_GETS,
    TEMPLATE_ADD,
    TEMPLATE_UPDATE,
    TEMPLATE_DELETE
} from '../defined/API';
import {callApi} from './api';

export const getTemplates = (buildingId: string) => callApi(`${TEMPLATE_GETS}/${buildingId}`, {}, 'GET');
export const addTemplateService = (template: Object) => callApi(TEMPLATE_ADD, template, 'POST');
export const updateTemplateService = (template: Object) => callApi(TEMPLATE_UPDATE, template, 'PUT');
export const deleteTemplateService = (template: Object) => callApi(`${TEMPLATE_DELETE}/${template.id}`, {}, 'DELETE');
