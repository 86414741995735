import { initStatePagination } from './paginate';
import paginate from './paginate';
import { combineReducers } from 'redux';
import { FORM_DOCUMENT } from '../constants/actionType';

export const initState = {
    apartmentGetInForm: {
        ...initStatePagination,
    },
};

export default combineReducers({
    apartmentGetInForm: paginate(FORM_DOCUMENT.SEARCH_APARTMENT),
});
