import { COMMON } from '../constants/actionType';

export const uploadFile = (file, id) => ({
    type: COMMON.UPLOAD_FILE.REQUEST,
    payload: {
        file,
        id,
    },
});

export const deleteFile = (key) => ({
    type: COMMON.DELETE_FILE.REQUEST,
    payload: {
        key,
    },
});
