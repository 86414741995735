import {
  DASHBOARD_OVERVIEW_GETS,
  DASHBOARD_REVENUE_GETS,
  DASHBOARD_TOTAL_PAID_AND_UNPAID_GETS,
  DASHBOARD_RESIDENT_GETS,
  DASHBOARD_SERVICE_GETS,
  DASHBOARD_VEHICLE_GETS,
  DASHBOARD_BUILDING_GETS,
} from '../defined/API';
import { handleError, handleResponse } from '../libs/http';
import { METHOD } from '../constants';
import { callApi } from './api';

export async function getOverviewService(
  { body },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(DASHBOARD_OVERVIEW_GETS, body, METHOD.POST, {});
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getRevenueService(
  { body },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(DASHBOARD_REVENUE_GETS, body, METHOD.POST, {});
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getTotalPaidAndUnpaidService(
  { body },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(DASHBOARD_TOTAL_PAID_AND_UNPAID_GETS, body, METHOD.POST, {});
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getResidentService(
  { body },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(DASHBOARD_RESIDENT_GETS, body, METHOD.POST, {});
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getServiceOfBuildingService(
  { body },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(DASHBOARD_SERVICE_GETS, body, METHOD.POST, {});
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getVehicleService(
  { body },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(DASHBOARD_VEHICLE_GETS, body, METHOD.POST, {});
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getBuildingForDashboardService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(DASHBOARD_BUILDING_GETS, {}, METHOD.GET, {
      params: {},
      headers: headers,
    });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}