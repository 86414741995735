import { CREATEDFOR_FORMAT, PAGINATION, X_UNIT_GROUP_ID } from '../constants';
import { handleError, handleResponse } from '../libs/http';
import { callApi } from './api';
import {
    BILL_GET_BY_TARGET,
    CONTRACT, INVOICE_GET_BY_ID,
    INVOICE_GETS,
    INVOICE_PREVIEW,
    INVOICE_PRINT, INVOICE_REPRINT, INVOICE_RESEND_EMAIL,
    INVOICE_SEND_EMAIL,
    INVOICE_PREVIEW_DEBT,
    INVOICE_PRINT_DEBT,
    INVOICE_SEND_EMAIL_DEBT
} from '../defined/API';
import { METHOD } from '../constants/http';
import moment from 'moment';

export const getInformUseServiceByTimeService = async (buildingId = 0, createdFor = moment().format(CREATEDFOR_FORMAT), filterObject = {
    serviceId: 0,
    target: '',
}, page = 1, size = PAGINATION.LIMIT, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(`${CONTRACT}/getInformUseServiceByTime`, {}, METHOD.GET, {
            params: {
                createdFor,
                page,
                limit: size,
                serviceId: filterObject.serviceId,
                target: filterObject.target,
            },
            headers: {
                [X_UNIT_GROUP_ID]: buildingId,
            },
        });
        return handleResponse(response, type, 'result', page);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getBillByGroupTargetService = async (buildingId = 0, createdFor = moment().format(CREATEDFOR_FORMAT), filterObject = {
    serviceId: 0,
    target: '',
}, page = 1, size = PAGINATION.LIMIT, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(BILL_GET_BY_TARGET, {}, METHOD.GET, {
            params: {
                // createdFor,
                page,
                limit: size,
                serviceId: filterObject.serviceId,
                target: filterObject.target,
            },
            headers: {
                [X_UNIT_GROUP_ID]: buildingId,
            },
        });
        return handleResponse(response, type, 'result', page);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const sendEmailInvoiceService = async ({ headers, body }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(INVOICE_SEND_EMAIL, body, METHOD.POST, {
            headers,
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const reSendEmailInvoiceService = async ({ headers, body }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(INVOICE_RESEND_EMAIL, body, METHOD.POST, {
            headers,
            bodyType: 'html',
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const printInvoiceService = async ({ headers, body }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(INVOICE_PRINT, body, METHOD.POST, {
            headers,
            bodyType: 'html',
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const rePrintInvoiceService = async ({ headers, body }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(INVOICE_REPRINT, body, METHOD.POST, {
            headers,
            bodyType: 'html',
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const previewInvoiceService = async ({ headers, body }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(INVOICE_PREVIEW, body, METHOD.POST, {
            headers,
            bodyType: 'html',
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getInvoicesService = async ({ headers, params }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(INVOICE_GETS, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type, 'result', true);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getInvoiceByIdService = async ({ unitGroupId, invoiceId }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(`${INVOICE_GET_BY_ID}/${invoiceId}`, {}, METHOD.GET, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId
            },
            bodyType: 'html',
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const previewInvoiceDebtService = async ({ headers, body }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(INVOICE_PREVIEW_DEBT, body, METHOD.POST, {
            headers,
            bodyType: 'html',
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const printInvoiceDebtService = async ({ headers, body }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(INVOICE_PRINT_DEBT, body, METHOD.POST, {
            headers,
            bodyType: 'html',
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const sendEmailInvoiceDebtService = async ({ headers, body }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(INVOICE_SEND_EMAIL_DEBT, body, METHOD.POST, {
            headers,
            bodyType: 'html',
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};
