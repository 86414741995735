import { language } from '../defined/Language';

export const DOCUMENT_TYPE_OPTIONS = [
    {
        id: 'GROUP',
        name: language['label']['group'],
    },
    {
        id: 'FILE',
        name: language['label']['file'],
    },
];

export const RESIDENT_DOCUMENT = 'RESIDENT_DOCUMENT';
export const UNIT_GROUP_DOCUMENT = 'UNIT_GROUP_DOCUMENT';
export const UNIT_DOCUMENT = 'UNIT_DOCUMENT';