import { call, put, all, takeLatest } from 'redux-saga/effects';
import { addResidentService, getApartments, getResidentEntityService, updateResidentService, getCountryCodes } from 'services';

import {
  RESIDENT_FORM_ADD,
  RESIDENT_FORM_GET_ENTITY,
  RESIDENT_FORM_GET_UNITS,
  RESIDENT_FORM_UPDATE,
  RESIDENT_FORM_GET_COUNTRY_CODE,
} from './residentForm.types';

export function* searchApartmentInFormResident(action) {
  const { unitGroupId, name } = action.payload;

  const result = yield call(
    getApartments,
    unitGroupId,
    1,
    20,
    {
      name,
    },
    RESIDENT_FORM_GET_UNITS
  );

  yield put(result);
}

export function* addResident(action) {
  const { unitGroupId, resident } = action.payload;
  const result = yield call(addResidentService, { unitGroupId, body: resident }, RESIDENT_FORM_ADD);
  yield put(result);
}

export function* updateResident(action) {
  const { unitGroupId, residentId, resident } = action.payload;
  const result = yield call(
    updateResidentService,
    {
      residentId,
      unitGroupId,
      body: resident,
    },
    RESIDENT_FORM_UPDATE
  );
  yield put(result);
}

export function* getResidentEntity(action) {
  const { unitGroupId, residentId } = action.payload;
  const result = yield call(getResidentEntityService, { unitGroupId, residentId }, RESIDENT_FORM_GET_ENTITY);
  yield put(result);
}

export function* getCountryCode() {
  const result = yield call(getCountryCodes, RESIDENT_FORM_GET_COUNTRY_CODE);
  yield put(result);
}

export default function* watch() {
  yield all([
    takeLatest(RESIDENT_FORM_GET_UNITS.REQUEST, searchApartmentInFormResident),
    takeLatest(RESIDENT_FORM_ADD.REQUEST, addResident),
    takeLatest(RESIDENT_FORM_UPDATE.REQUEST, updateResident),
    takeLatest(RESIDENT_FORM_GET_ENTITY.REQUEST, getResidentEntity),
    takeLatest(RESIDENT_FORM_GET_COUNTRY_CODE.REQUEST, getCountryCode),
  ]);
}
