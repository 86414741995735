import { COMMON } from '../constants/actionType';

export const initState = {
    id: 1,
    byId: {},
    isFetching: false
};

export default (state = initState, action) => {
    switch (action.type) {
        case COMMON.UPLOAD_FILE.REQUEST:
            return {
                ...state,
                isFetching: true,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        id: action.payload.id,
                        isFetching: true,
                        code: undefined,
                    },
                },
                id: state.id + 1,
            };
        case COMMON.UPLOAD_FILE.SUCCESS:
        case COMMON.UPLOAD_FILE.FAIL:
            return {
                ...state,
                isFetching: false,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        id: action.payload.id,
                        isFetching: false,
                        code: action.payload.code,
                        url: action.payload.url,
                    },
                },
            };
        case COMMON.UPLOAD_FILE.REFRESH:
            return {
                ...state,
                isFetching: false,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        id: action.payload.id,
                        isFetching: false,
                        code: undefined,
                    },
                },
            };
        default:
            return state;
    }
};