import { handleError, handleResponse } from '../libs/http';
import { callApi, downloadFile } from './api';
import { METHOD } from '../constants/http';
import {
    RECEIPT_CANCEL,
    RECEIPT_CREATE, RECEIPT_CREATE_MANUAL,
    RECEIPT_EXPORT,
    RECEIPT_GET_BY_ID,
    RECEIPT_GETS, RECEIPT_SUM_TOTAL_PAYMENT, RECEIPT_UPDATE, SUM_TOTAL_PAYMENT_LIST_UNIT_WITH_BALANCE,
} from '../defined/API';
import { X_UNIT_GROUP_ID } from '../constants';

export const createReceiptService = async ({ body, headers, bodyType }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(RECEIPT_CREATE, body, METHOD.POST, {
            headers,
            bodyType,
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const createReceiptManualService = async ({ body, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(RECEIPT_CREATE_MANUAL, body, METHOD.POST, {
            headers,
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const updateReceiptService = async ({ receiptId, body, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(RECEIPT_UPDATE(receiptId), body, METHOD.PUT, {
            headers
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getReceiptService = async ({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(RECEIPT_GETS, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type, 'result', true ,params.currentPage, params.limit);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getReceiptByIdService = async ({ unitGroupId, receiptId }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(`${RECEIPT_GET_BY_ID}/${receiptId}`, {}, METHOD.GET, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
            bodyType: 'html',
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export async function exportReceiptService({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        let response = await downloadFile(RECEIPT_EXPORT, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type);
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}

export async function cancelReceiptService({ receiptId, body, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(`${RECEIPT_CANCEL}/${receiptId}/cancel`, body, METHOD.POST, {
            headers
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function getSumTotalPaymentReceiptService({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(RECEIPT_SUM_TOTAL_PAYMENT, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
}
