import { createAction } from 'shared/utils';

export const LOGIN = createAction('LOGIN');
export const REGISTER = createAction('REGISTER');
export const LOGOUT = createAction('LOGOUT');
export const PASSWORD = {
    FORGOT: createAction('PASSWORD_FORGOT'),
    UPDATE: createAction('PASSWORD_UPDATE'),
    SET_NEW: createAction('PASSWORD_SET_NEW'),
};
export const ACTIVE_ACCOUNT = {
    EMAIL_STEP_1: createAction('EMAIL_STEP_1'),
    EMAIL_STEP_2: createAction('EMAIL_STEP_2'),
};
