import { PAGINATION_LIMIT } from 'shared/constants';

const getTotalPage = (totalRecord = 0, size = PAGINATION_LIMIT) => {
  return Math.ceil(totalRecord / size) || 1;
};

export default function paginate(data, page, limit = PAGINATION_LIMIT) {
  const tmp = [...data];
  const skip = (page - 1) * limit;
  const totalPage = getTotalPage(data.length, limit);
  const totalRecord = data.length;
  const sliced = tmp.slice(skip, skip + limit);
  return [sliced, totalPage, totalRecord];
}
