import { INVOICE, PAYMENT } from '../constants/actionType';

export const resetStatePagePayment = () => ({
    type: PAYMENT.RESET_PAGE.REQUEST,
});

export const initPaymentPage = (unitGroupId) => ({
    type: PAYMENT.INIT.REQUEST,
    payload: {
        unitGroupId,
    }
});

export const getBillInPagePayment = ({
    unitGroupId,
    page = 1,
    filterObject,
}) => ({
    type: PAYMENT.BILL.GETS.REQUEST,
    payload: {
        unitGroupId,
        page,
        filterObject,
    },
});

export const getSumPayment = ({
    unitGroupId,
    filterObject,
}) => ({
    type: PAYMENT.BILL.SUM_PAYMENT_GETS.REQUEST,
    payload: {
        unitGroupId,
        filterObject,
    },
});

export const createReceiptPagePayment = (receipt, unitGroupId) => ({
    type: PAYMENT.RECEIPT.CREATE.REQUEST,
    payload: {
        receipt,
        unitGroupId,
    },
});