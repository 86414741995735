import { COMMUNICATE } from '../../constants/actionType';
import { call, put } from 'redux-saga/effects';
import {
  getAnnouncementService,
  sendAnnouncementService,
  deleteAnnouncementService,
  getUnitSeenService,
  getUnitSendByAppService,
  getUnitSendByEmailService,
  getUnitVoteService,
  resendEmailService
} from '../../services/announcement';
import { getLabelsService } from '../../services/label';
import { getApartments } from '../../services/apartment';
import { getFloorsService } from '../../services/floor';
import { X_UNIT_GROUP_ID, PAGINATION } from '../../constants';
import { pick } from 'lodash';

export function* getAnnouncement(action) {
  const { buildingId, data, page, limit } = action.payload;

  const params = pick(data, ['title', 'labelIds', 'startDate', 'endDate']);

  const result = yield call(getAnnouncementService, buildingId, page, limit, params, COMMUNICATE.ANNOUNCEMENT_TAB.GETS);

  yield put(result);
}

export function* sendAnnouncement(action) {
  const { buildingId, data } = action.payload;

  const body = pick(data, ['language', 'title', 'announcementType', 'content', 'labelIds', 'unitIds', 'sendTypes', 'receiver', 'attachFiles', 'type', 'thumbnail']);

  const result = yield call(sendAnnouncementService, buildingId, body, COMMUNICATE.ANNOUNCEMENT_TAB.SEND);

  yield put(result);
}

export function* deleteAnnouncements(action) {
  const { buildingId, data } = action.payload;

  const result = yield call(deleteAnnouncementService, buildingId, data, COMMUNICATE.ANNOUNCEMENT_TAB.DELETE);

  yield put(result);
  yield put({ type: COMMUNICATE.ANNOUNCEMENT_TAB.DELETE.REFRESH });
}

export function* getFloorsAnnouncement(action) {
  const { buildingId } = action.payload;

  const result = yield call(getFloorsService, buildingId, COMMUNICATE.FLOOR.GETS);

  yield put(result);
}

export function* getTagsAnnouncement(action) {
  const { buildingId, data } = action.payload;

  const options = {
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
    params: {
      ...data,
    }
  };

  const result = yield call(getLabelsService, options, COMMUNICATE.TAG.GETS);

  yield put(result);
}

export function* getUnitsAnnouncement(action) {
  const { buildingId, data: { page = 1, limit = PAGINATION.LIMIT } } = action.payload;

  const filter = pick(action.payload.data, ['name', 'floorId']);

  const result = yield call(getApartments, buildingId, page, limit, filter, COMMUNICATE.UNIT.GETS);

  yield put(result);
}

export function* getUnitSeen(action) {
  const { buildingId, keyword, page, limit, annouceId } = action.payload;

  const result = yield call(getUnitSeenService, {
    params: {
      keyword,
      page,
      limit,
      annouceId
    },
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, COMMUNICATE.UNIT_SEEN.GETS);

  yield put(result);
}

export function* getUnitSendByEmail(action) {
  const { buildingId, keyword, page, limit, annouceId, emailStatus } = action.payload;

  const result = yield call(getUnitSendByEmailService, {
    params: {
      keyword,
      page,
      limit,
      annouceId,
      emailStatus
    },
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, COMMUNICATE.UNIT_SEND_BY_EMAIL.GETS);

  yield put(result);
}

export function* getUnitSendByApp(action) {
  const { buildingId, keyword, page, limit, annouceId } = action.payload;

  const result = yield call(getUnitSendByAppService, {
    params: {
      keyword,
      page,
      limit,
      annouceId
    },
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, COMMUNICATE.UNIT_SEND_BY_APP.GETS);

  yield put(result);
}

export function* getUnitVote(action) {
  const { buildingId, keyword, page, limit, annouceId, pollId } = action.payload;

  const result = yield call(getUnitVoteService, {
    params: {
      keyword,
      page,
      limit,
      annouceId,
      pollId
    },
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, COMMUNICATE.UNIT_VOTE.GETS);

  yield put(result);
}

export function* resendEmail(action) {
  const { buildingId, annouceId, unitId } = action.payload;

  const result = yield call(resendEmailService, {
    params: {
      annouceId,
      unitId
    },
    headers: {
      [X_UNIT_GROUP_ID]: buildingId,
    },
  }, COMMUNICATE.RESEND_EMAIL.GET);

  yield put(result);
  yield put({ type: COMMUNICATE.RESEND_EMAIL.GET.REFRESH });
}