import { call, put } from 'redux-saga/effects';
import { STATISTIC } from '../../constants/actionType';
import {
    getReportServiceService,
    exportReportServiceService, getSumPaymentReportServiceService,
} from '../../services/reportService';

export function* getReportService(action) {
    const { unitGroupId, data } = action.payload;

    const result = yield call(getReportServiceService, unitGroupId, data, STATISTIC.REPORT_SERVICE.GETS);

    yield put(result);
}

export function* exportReportService(action) {
    const { unitGroupId, data } = action.payload;

    const result = yield call(exportReportServiceService, unitGroupId, data, STATISTIC.REPORT_SERVICE.EXPORT);

    yield put(result);
    yield put({ type: STATISTIC.REPORT_SERVICE.EXPORT.REFRESH });
}

export function* getSumPaymentReportService(action) {
    const { unitGroupId, data } = action.payload;

    const result = yield call(getSumPaymentReportServiceService, unitGroupId, data, STATISTIC.REPORT_SERVICE.SUM_PAYMENT);

    yield put(result);
}