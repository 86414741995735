import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getServices } from 'services/service';
import {
  getIcons,
  addServiceSystemService,
  updateServiceSystemService,
  deleteServiceSystemService,
  inactiveServiceSystemService,
  activeServiceSystemService,
} from 'services/serviceSystem';
import {
  getContractTemplateService,
  getContractTemplateByFormulaService,
  getContractTemplateByPromotionService,
  getContractTemplateBySurchargeService,
} from 'services';
import { X_UNIT_GROUP_ID } from 'shared/constants';
import { handleError } from 'shared/utils';
import { SERVICE } from './service.types';
import { convertData } from './service.utils';
import { SERVICE_STATUS_ACTIVE } from './service.constants';

function* getsService(action) {
  const { unitGroupId } = action.payload;

  const result = yield call(
    getServices,
    {
      unitGroupId,
    },
    SERVICE.GETS
  );
  yield put(result);
}

function* getsIcon() {
  const result = yield call(getIcons, SERVICE.ICON_GETS);
  yield put(result);
}

function* addService(action) {
  try {
    const { service, unitGroupId } = action.payload;

    const serviceConvert = convertData(service, unitGroupId);

    const result = yield call(addServiceSystemService, serviceConvert, SERVICE.ADD);
    yield put(result);
    yield put({
      type: SERVICE.ADD.REFRESH,
    });
  } catch (error) {
    yield put({
      ...handleError(error, SERVICE.ADD.FAIL),
    });
  }
}

function* updateService(action) {
  try {
    const { service, unitGroupId } = action.payload;

    const serviceConvert = convertData(service, unitGroupId);

    const result = yield call(updateServiceSystemService, serviceConvert, SERVICE.UPDATE);
    yield put(result);
    yield put({
      type: SERVICE.UPDATE.REFRESH,
    });
  } catch (error) {
    yield put({
      ...handleError(error, SERVICE.UPDATE.FAIL),
    });
  }
}

function* deleteService(action) {
  try {
    const { id, unitGroupId } = action.payload;

    const result = yield call(
      deleteServiceSystemService,
      {
        serviceId: id,
        unitGroupId,
      },
      SERVICE.DELETE
    );
    yield put(result);
    yield put({
      type: SERVICE.DELETE.REFRESH,
    });
  } catch (error) {
    yield put({
      ...handleError(error, SERVICE.DELETE.FAIL),
    });
  }
}

function* changeStatusService(action) {
  try {
    const { id, unitGroupId, type = SERVICE_STATUS_ACTIVE } = action.payload;

    const changeStatusServiceApi = type === SERVICE_STATUS_ACTIVE ? activeServiceSystemService : inactiveServiceSystemService;

    const result = yield call(
      changeStatusServiceApi,
      {
        serviceId: id,
        unitGroupId,
      },
      SERVICE.CHANGE_STATUS
    );
    yield put(result);
    yield put({
      type: SERVICE.CHANGE_STATUS.REFRESH,
    });
  } catch (error) {
    yield put({
      ...handleError(error, SERVICE.CHANGE_STATUS.FAIL),
    });
  }
}

function* getContractTemplateByFormula(action) {
  try {
    const { id, unitGroupId, callback } = action.payload;

    const result = yield call(
      getContractTemplateByFormulaService,
      {
        params: {
          formulaId: id,
        },
        headers: {
          [X_UNIT_GROUP_ID]: unitGroupId,
        },
      },
      SERVICE.CONTRACT_TEMPLATE_BY_FORMULA_GETS
    );
    if (result?.payload?.result?.length > 0) callback.onError();
    else callback.onFinish();

    yield put(result);
  } catch (error) {
    yield put({
      ...handleError(error, SERVICE.CONTRACT_TEMPLATE_BY_FORMULA_GETS.FAIL),
    });
  }
}

function* getContractTemplateBySurcharge(action) {
  try {
    const { id, unitGroupId, callback } = action.payload;

    const result = yield call(
      getContractTemplateBySurchargeService,
      {
        params: {
          surchargeId: id,
        },
        headers: {
          [X_UNIT_GROUP_ID]: unitGroupId,
        },
      },
      SERVICE.CONTRACT_TEMPLATE_BY_SURCHARGE_GETS
    );

    if (result?.payload?.result?.length > 0) callback.onError();
    else callback.onFinish();

    yield put(result);
  } catch (error) {
    yield put({
      ...handleError(error, SERVICE.CONTRACT_TEMPLATE_BY_SURCHARGE_GETS.FAIL),
    });
  }
}

function* getContractTemplateByPromotion(action) {
  try {
    const { id, unitGroupId, callback } = action.payload;

    const result = yield call(
      getContractTemplateByPromotionService,
      {
        params: {
          promotionId: id,
        },
        headers: {
          [X_UNIT_GROUP_ID]: unitGroupId,
        },
      },
      SERVICE.CONTRACT_TEMPLATE_BY_PROMOTION_GETS
    );

    if (result?.payload?.result?.length > 0) callback.onError();
    else callback.onFinish();

    yield put(result);
  } catch (error) {
    yield put({
      ...handleError(error, SERVICE.CONTRACT_TEMPLATE_BY_PROMOTION_GETS.FAIL),
    });
  }
}

function* getContractTemplateByService(action) {
  try {
    const { id, unitGroupId } = action.payload;

    const result = yield call(
      getContractTemplateService,
      {
        params: {
          serviceId: id,
        },
        headers: {
          [X_UNIT_GROUP_ID]: unitGroupId,
        },
      },
      SERVICE.CONTRACT_TEMPLATE_BY_SERVICE_GETS
    );

    result.payload.id = id;

    yield put(result);

    yield put({
      type: SERVICE.CONTRACT_TEMPLATE_BY_SERVICE_GETS.REFRESH,
    });
  } catch (error) {
    yield put({
      ...handleError(error, SERVICE.CONTRACT_TEMPLATE_BY_SERVICE_GETS.FAIL),
    });
  }
}

export default function* watch() {
  yield all([
    takeLatest(SERVICE.GETS.REQUEST, getsService),
    takeLatest(SERVICE.ICON_GETS.REQUEST, getsIcon),
    takeLatest(SERVICE.ADD.REQUEST, addService),
    takeLatest(SERVICE.UPDATE.REQUEST, updateService),
    takeLatest(SERVICE.DELETE.REQUEST, deleteService),
    takeLatest(SERVICE.CHANGE_STATUS.REQUEST, changeStatusService),
    takeLatest(SERVICE.CONTRACT_TEMPLATE_BY_FORMULA_GETS.REQUEST, getContractTemplateByFormula),
    takeLatest(SERVICE.CONTRACT_TEMPLATE_BY_SURCHARGE_GETS.REQUEST, getContractTemplateBySurcharge),
    takeLatest(SERVICE.CONTRACT_TEMPLATE_BY_PROMOTION_GETS.REQUEST, getContractTemplateByPromotion),
    takeLatest(SERVICE.CONTRACT_TEMPLATE_BY_SERVICE_GETS.REQUEST, getContractTemplateByService),
  ]);
}
