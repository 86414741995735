import {
    createBillAutoService,
    createBillManualService,
    deleteBillService,
    getBillsService,
    getContractLatestService,
    getInvoiceByIdService, getInvoicesService,
    getServiceInUseService,
    previewInvoiceService,
    printInvoiceService, rePrintInvoiceService, reSendEmailInvoiceService,
    sendEmailInvoiceService, sumPaymentContractLatestService, sumPaymentService, updateInterestService,
    printInvoiceDebtService, previewInvoiceDebtService, sendEmailInvoiceDebtService
} from '../services';
import { INVOICE, X_UNIT_GROUP_ID } from '../constants';
import { call, put } from 'redux-saga/effects';
import moment from 'moment';
import { convertDateFormatServer } from '../libs/convert';

export function* initPageInvoice(action) {
    const {
        buildingId,
    } = action.payload;

    const result = yield call(getServiceInUseService, {
        headers: {
            [X_UNIT_GROUP_ID]: buildingId,
        },
    }, INVOICE.SERVICE.GETS);
    yield put(result);

    yield put({
        type: INVOICE.INIT.SUCCESS,
        payload: {
            code: 200,
        },
    });
}

export function* getContractLatest(action) {
    const {
        unitGroupId,
        unitName,
        serviceId,
        residentName,
        page,
    } = action.payload;

    const params = {
        serviceId,
        unitName,
        residentName,
        limit: 20,
        page,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    let result = yield call(getContractLatestService, { params, headers }, INVOICE.CONTRACT.GETS);

    yield put(result);
}

export function* getSumPaymentLatest(action) {
    const {
        unitGroupId,
        filterObject,
    } = action.payload;

    const params = {
        ...filterObject,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(sumPaymentContractLatestService, {
        params,
        headers,
    }, INVOICE.CONTRACT.SUM_PAYMENT_GETS);
    yield put(result);
}

export function* getSumPayment(action) {
    const {
        unitGroupId,
        filterObject,
    } = action.payload;

    const params = {
        ...filterObject,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(sumPaymentService, {
        params,
        headers,
    }, INVOICE.BILL.SUM_PAYMENT_GETS);
    yield put(result);
}

export function* getBillInPageInvoice(action) {
    const {
        unitGroupId,
        filterObject,
        page,
    } = action.payload;

    const params = {
        page,
        limit: 20,
        ...filterObject,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(getBillsService, { params, headers }, INVOICE.BILL.GETS);
    yield put(result);
}

export function* createBillAuto(action) {
    const {
        startDate,
        targets,
        unitGroupId,
        isAll,
        filterObject,
        incurredCreatedFor,
    } = action.payload;

    const body = {
        startDate: convertDateFormatServer(startDate),
        targets,
        isAll,
        filterObject,
        incurredCreatedFor,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(createBillAutoService, { body, headers }, INVOICE.BILL.CREATE_AUTO);

    yield put(result);

    yield put({
        type: INVOICE.BILL.CREATE_AUTO.REFRESH,
    });
}

export function* createBillManual(action) {
    const {
        startDate,
        endDate,
        targets,
        type,
        unitGroupId,
        isAll,
        filterObject,
        incurredCreatedFor,
        feePeriodType
    } = action.payload;
    const body = {
        startDate,
        endDate,
        targets,
        type,
        isAll,
        filterObject,
        incurredCreatedFor,
        feePeriodType
    };
    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };
    const result = yield call(createBillManualService, { body, headers }, INVOICE.BILL.CREATE_MANUAL);

    yield put(result);

    yield put({
        type: INVOICE.BILL.CREATE_MANUAL.REFRESH,
    });
}

export function* updateInterest(action) {
    const {
        unitGroupId,
    } = action.payload;

    let headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(updateInterestService, {
        headers
    }, INVOICE.BILL.UPDATE_INTEREST);
    yield put(result);
    yield put({
        type: INVOICE.BILL.UPDATE_INTEREST.REFRESH,
    });
}

export function* deleteBills(action) {
    const {
        isAll,
        ids,
        unitGroupId,
        filterObject,
    } = action.payload;
    const body = {
        isAll,
        array: ids,
        filterObject,
    };
    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };
    const result = yield call(deleteBillService, {
        body,
        headers,
    }, INVOICE.BILL.DELETE_MULTI);
    yield put(result);
    yield put({
        type: INVOICE.BILL.DELETE_MULTI.REFRESH,
    });
}

export function* previewInvoice(action) {
    const {
        unitGroupId,
        unitId,
        bills,
        createdAt,
        incurredCreatedFor,
    } = action.payload;
    const body = {
        unitId,
        bills,
        createdAt,
        incurredCreatedFor,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(previewInvoiceService, { body, headers }, INVOICE.PREVIEW);
    yield put(result);
    yield put({
        type: INVOICE.PREVIEW.REFRESH,
    });
}

export function* printInvoice(action) {
    const {
        isAll,
        filterObject,
        targets,
        unitGroupId,
    } = action.payload;

    const body = {
        isAll,
        filterObject,
        targets,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(printInvoiceService, { body, headers }, INVOICE.PRINT);
    yield put(result);
    yield put({
        type: INVOICE.PRINT.REFRESH,
    });
}

export function* rePrintInvoice(action) {
    const {
        isAll,
        filterObject,
        ids,
        unitGroupId,
    } = action.payload;

    const body = {
        isAll,
        filterObject,
        ids,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(rePrintInvoiceService, { body, headers }, INVOICE.REPRINT);
    yield put(result);
    yield put({
        type: INVOICE.REPRINT.REFRESH,
    });
}

export function* sendEmailInvoice(action) {
    const {
        isAll,
        filterObject,
        targets,
        unitGroupId,
    } = action.payload;

    const body = {
        isAll,
        filterObject,
        targets,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(sendEmailInvoiceService, { body, headers }, INVOICE.SEND_EMAIL);
    yield put(result);
    yield put({
        type: INVOICE.SEND_EMAIL.REFRESH,
    });
}

export function* reSendEmailInvoice(action) {
    const {
        isAll,
        filterObject,
        ids,
        unitGroupId,
    } = action.payload;

    const body = {
        isAll,
        filterObject,
        ids,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(reSendEmailInvoiceService, { body, headers }, INVOICE.RESEND_EMAIL);
    yield put(result);
    yield put({
        type: INVOICE.RESEND_EMAIL.REFRESH,
    });
}

export function* getInvoices(action) {
    const {
        unitGroupId,
        filterObject,
        page,
    } = action.payload;

    const params = {
        ...filterObject,
        page,
        limit: 20,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };
    const result = yield call(getInvoicesService, { params, headers }, INVOICE.GETS);
    yield put(result);
}

export function* getInvoiceById(action) {
    const {
        invoiceId,
        unitGroupId,
    } = action.payload;

    const result = yield call(getInvoiceByIdService, { unitGroupId, invoiceId }, INVOICE.GET_BY_ID);
    yield put(result);
}

export function* getBillForCheckingAllInvoice(action) {
    const {
        unitGroupId,
        filterObject,
        page,
    } = action.payload;

    const params = {
        page,
        limit: 20,
        ...filterObject,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(getBillsService, { params, headers }, INVOICE.BILL.CHECKING_ALL_GETS);
    yield put(result);
    yield put({
        type: INVOICE.BILL.CHECKING_ALL_GETS.REFRESH,
    });
}

export function* getInvoiceForCheckingAll(action) {
    const {
        unitGroupId,
        filterObject,
        page,
    } = action.payload;

    const params = {
        ...filterObject,
        page,
        limit: 20,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };
    const result = yield call(getInvoicesService, { params, headers }, INVOICE.CHECKING_ALL_GETS);
    yield put(result);
    yield put({
        type: INVOICE.CHECKING_ALL_GETS.REFRESH,
    });
}

export function* getContractLatestForCheckingAll(action) {
    const {
        unitGroupId,
        unitName,
        serviceId,
        residentName,
        page,
        limit,
    } = action.payload;

    const params = {
        serviceId,
        unitName,
        residentName,
        limit,
        page,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    let result = yield call(getContractLatestService, { params, headers }, INVOICE.CONTRACT.CHECKING_ALL_GETS);

    yield put(result);
    yield put({
        type: INVOICE.CONTRACT.CHECKING_ALL_GETS.REFRESH,
    });
}

export function* previewInvoiceDebt(action) {
    const {
        unitGroupId,
        unitId,
        bills,
        createdAt,
        type,
        duePayment,
        filterObject,
        incurredCreatedFor,
        remindDebtTimes
    } = action.payload;
    const body = {
        unitId,
        bills,
        createdAt,
        type,
        duePayment,
        filterObject,
        incurredCreatedFor,
        remindDebtTimes
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(previewInvoiceDebtService, { body, headers }, INVOICE.PREVIEW_DEBT);
    yield put(result);
    yield put({
        type: INVOICE.PREVIEW_DEBT.REFRESH,
    });
}

export function* printInvoiceDebt(action) {
    const {
        isAll,
        filterObject,
        targets,
        unitGroupId,
        type,
        duePayment
    } = action.payload;

    const body = {
        isAll,
        filterObject,
        targets,
        type,
        duePayment
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(printInvoiceDebtService, { body, headers }, INVOICE.PRINT_DEBT);
    yield put(result);
    yield put({
        type: INVOICE.PRINT_DEBT.REFRESH,
    });
}

export function* sendEmailInvoiceDebt(action) {
    const {
        isAll,
        filterObject,
        targets,
        unitGroupId,
        type,
        duePayment
    } = action.payload;

    const body = {
        isAll,
        filterObject,
        targets,
        type,
        duePayment
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(sendEmailInvoiceDebtService, { body, headers }, INVOICE.SEND_EMAIL_DEBT);
    yield put(result);
    yield put({
        type: INVOICE.SEND_EMAIL_DEBT.REFRESH,
    });
}