import { call, put, select } from 'redux-saga/effects';
import { FORM_PAYSLIP } from '../constants/actionType';
import { getApartments } from '../services';
import { getResidentsService } from '../services/resident';
import { X_UNIT_GROUP_ID } from '../constants';
import { getAccountService } from '../services/fund';
import { getLabelsService } from '../services/label';
import { pick } from 'lodash';

export function* initFormPaySlip(action) {
  const {
    unitGroupId,
    funds,
    tags,
  } = action.payload;

  const resApartment = yield call(getApartments, unitGroupId, 1, 20, {}, FORM_PAYSLIP.SEARCH_APARTMENT);
  yield put(resApartment);

  if (funds) {
    yield put({
      type: FORM_PAYSLIP.FUND.GETS.SUCCESS,
      payload: {
        code: 200,
        result: funds,
      }
    });
  } else {
    const codeFund = yield select(state => state.formPaySlip.funds.code);
    if (!codeFund) {
      const resFund = yield call(getAccountService, unitGroupId, FORM_PAYSLIP.FUND.GETS);
      yield put(resFund);
    }
  }

  if (tags) {
    yield put({
      type: FORM_PAYSLIP.TAG.GETS.SUCCESS,
      payload: {
        code: 200,
        result: tags,
      }
    });
  } else {
    const codeTag = yield select(state => state.formPaySlip.tags.code);
    if (!codeTag) {
      const resTag = yield call(getLabelsService, {
        headers: {
          [X_UNIT_GROUP_ID]: unitGroupId,
        },
      }, FORM_PAYSLIP.TAG.GETS);
      yield put(resTag);
    }
  }

  yield put({
    type: FORM_PAYSLIP.INIT.SUCCESS,
    payload: {
      code: 200,
    },
  });
}

export function* searchApartmentInFormPaySlip(action) {
  const {
    unitGroupId,
    name,
  } = action.payload;

  const result = yield call(getApartments, unitGroupId, 1, 20, {
    name
  }, FORM_PAYSLIP.SEARCH_APARTMENT);
  yield put(result);
}

export function* getReceiverSuggestions(action) {
  const { data, unitGroupId } = action.payload;

  const params = pick(data, ['fullName', 'page', 'limit']);
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(getResidentsService, { params, headers }, FORM_PAYSLIP.RECEIVER_SUGGESTIONS);

  yield put(result);
}
