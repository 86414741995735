import moment from 'moment';
import { VIEW_TYPE_SERVICE } from './service-update-until-current-time.constants';

const convertFilterObject = (filterObject) => {
  const { service, unitName, time, viewType, createdForType, customerCode } = filterObject;
  const [from, to] = [time && time[0] ? moment(time[0]).format('MM/YYYY') : '', time && time[1] ? moment(time[1]).format('MM/YYYY') : ''];
  let serviceId = service;
  let contractTemplateId;
  if (service && service.toString().indexOf('-') !== -1) {
    const serviceSplits = service.split('-');
    [serviceId, contractTemplateId] = serviceSplits;
  }
  return {
    unitName,
    serviceId,
    contractTemplateId,
    from,
    to,
    isShowService: viewType === VIEW_TYPE_SERVICE,
    createdForType,
    label: customerCode,
  };
};

export { convertFilterObject };
