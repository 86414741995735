import { callApi, callApiWithFile, downloadFile } from './api';
import {
    UNIT_ADD, UNIT_DELETE_MULTI, UNIT_GET_LIST,
    UNIT_TYPE, UNIT_UPDATE,
    UNIT_IMPORT, UNIT_EXPORT, UNIT, UNIT_WALLET_GETS,
    UNIT_QRCODE_GET, UNIT_QRCODE_REFRESH, UNIT_QRCODE_EXPORT, UNIT_PAYMENT_HISTORY,
} from '../defined/API';
import { METHOD, PAGINATION, X_UNIT_GROUP_ID } from '../constants';
import { handleError, handleResponse } from '../libs/http';

export async function getApartmentTypes(type: Object = { SUCCESS: 'SUCCESS', FAIL: 'FAIL' }) {
    const { FAIL } = type;
    try {
        let response = await callApi(UNIT_TYPE, {}, METHOD.GET);
        return handleResponse(response, type, 'apartmentTypes');
    } catch (err) {
        return handleError(err, FAIL);
    }
}

export async function getApartments(buildingId: string, page: number, limit: number = PAGINATION.LIMIT, filters: Object, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        let response = await callApi(UNIT_GET_LIST, {}, METHOD.GET, {
            params: {
                ...filters,
                page,
                limit: limit,
            },
            headers: {
                [X_UNIT_GROUP_ID]: buildingId,
            },
        });
        return handleResponse(response, type, 'result', page);
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}

export async function getApartmentByIdService({ unitId, unitGroupId }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(`${UNIT}/${unitId}`, {}, METHOD.GET, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function addApartment(apartment, type = { SUCCESS: 'SUCCESS', FAIL: 'FAIL' }) {
    const { FAIL } = type;
    try {
        let response = await callApi(UNIT_ADD, apartment, METHOD.POST, {
            headers: {
                [X_UNIT_GROUP_ID]: apartment.unitGroupId,
            },
        });
        return handleResponse(response, type, 'apartment');
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}

export async function updateApartment(apartment, type = { SUCCESS: 'SUCCESS', FAIL: 'FAIL' }) {
    const { FAIL } = type;
    try {
        let response = await callApi(`${UNIT_UPDATE}/${apartment.id}`, apartment, METHOD.PUT, {
            headers: {
                [X_UNIT_GROUP_ID]: apartment.unitGroupId,
            },
        });
        return handleResponse(response, type, 'apartment');
    } catch (err) {
        return handleError(err, FAIL);
    }
}

export async function deleteApartment({ body, unitGroupId }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        let response = await callApi(UNIT_DELETE_MULTI, body, METHOD.POST, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type, 'apartment');
    } catch (err) {
        return handleError(err, FAIL);
    }
}

export async function importApartmentService(unitGroupId, file, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApiWithFile(UNIT_IMPORT, file, METHOD.POST, 'file', {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type, 'result', 1);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function exportApartmentService(unitGroupId, filter, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        let response = await downloadFile(UNIT_EXPORT, {}, METHOD.GET, {
            params: {
                ...filter,
            },
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type, 'result');
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}

export async function getWalletApartmentService({ unitId, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(UNIT_WALLET_GETS(unitId), {}, METHOD.GET, {
            headers,
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function getQRCodeApartmentService({ unitId, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;

    try {
        const response = await callApi(UNIT_QRCODE_GET(unitId), {}, METHOD.GET, {
            headers,
        });
        return handleResponse(response, type, 'result');
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function exportQRCodeApartmentService({ unitIds, filterObject = {}, isAll = false, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        let response = await downloadFile(UNIT_QRCODE_EXPORT, {
            list: unitIds,
            filterObject,
            isAll
        }, METHOD.POST, {
            headers: headers,
        });

        return handleResponse(response, type, 'result');
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function refreshQRCodeApartmentService({ unitIds, filterObject = {}, isAll = false, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(UNIT_QRCODE_REFRESH, { list: unitIds, filterObject, isAll }, METHOD.POST, {
            headers,
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function getPaymentHistoryUnitService({ unitId, params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(UNIT_PAYMENT_HISTORY(unitId), null, METHOD.GET, { params, headers });
        return handleResponse(response, type, 'result', true);
    } catch (e) {
        return handleError(e, FAIL);
    }
}