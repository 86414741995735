import { COMMUNICATE } from '../../constants/actionType';

export const createRoomChat = (data) => ({
  type: COMMUNICATE.ROOM_CHAT.CREATE.REQUEST,
  payload: {
    ...data
  }
});

export const refreshCreateRoomChat = (data) => ({
  type: COMMUNICATE.ROOM_CHAT.CREATE.REFRESH,
});

export const getRoomChat = (buildingId, data) => ({
  type: COMMUNICATE.ROOM_CHAT.GETS.REQUEST,
  payload: {
    buildingId,
    data
  }
});

export const checkRead = (roomId) => ({
  type: COMMUNICATE.ROOM_CHAT.READ,
  payload: {
    roomId
  }
});

export const getRoomChatById = (buildingId, data) => ({
  type: COMMUNICATE.ROOM_CHAT.GET_BY_ID.REQUEST,
  payload: {
    buildingId,
    data
  }
});

export const getRoomMember = (buildingId, data) => ({
  type: COMMUNICATE.ROOM_CHAT.GETS_MEMBER.REQUEST,
  payload: {
    buildingId,
    data
  }
});

export const refreshRoomChatData = () => ({
  type: COMMUNICATE.ROOM_CHAT.GETS.REFRESH,
});

export const getMessages = (buildingId, data) => ({
  type: COMMUNICATE.MESSAGES.GETS.REQUEST,
  payload: {
    buildingId,
    data
  }
});

export const refreshMessagesData = () => ({
  type: COMMUNICATE.MESSAGES.GETS.REFRESH,
});

export const hasNewMessage = (data) => ({
  type: COMMUNICATE.MESSAGES.HAS_NEW_MESSAGE,
  payload: {
    data
  }
});

export const sendMessage = (buildingId, data) => ({
  type: COMMUNICATE.MESSAGES.SEND_MESSAGE.REQUEST,
  payload: {
    buildingId,
    data
  }
});

export const readMessageById = (buildingId, data) => ({
  type: COMMUNICATE.MESSAGES.READ_BY_ID.REQUEST,
  payload: {
    buildingId,
    data
  }
});

export const readAllMessage = (buildingId, data) => ({
  type: COMMUNICATE.MESSAGES.READ_ALL.REQUEST,
  payload: {
    buildingId,
    data
  }
});

export const getDepartmentOfUser = (payload) => ({
  type: COMMUNICATE.DEPARTMENT.GETS.REQUEST,
  payload
})