import { DOCUMENT, DOCUMENT_GET_LIST } from '../defined/API';
import { callApi } from './api';
import { METHOD, X_UNIT_GROUP_ID } from '../constants';
import { handleError, handleResponse } from '../libs/http';

export const getDocumentsInBuildingService = async ({ params, headers }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(DOCUMENT_GET_LIST, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type);
    } catch (e) {
        handleError(e, FAIL);
    }
};

export const getFolderInMoveToBoxService = async (buildingId: number, filter: Object, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { SUCCESS, FAIL } = type;
    try {
        const response = await callApi(DOCUMENT_GET_LIST, {}, METHOD.GET, {
            where: {
                isFolder: true,
                ...filter,
            },
            headers: {
                [X_UNIT_GROUP_ID]: buildingId,
            },
        });

        return handleResponse(response, type);
    } catch (e) {
        handleError(e, FAIL);
    }
};

export const addDocumentService = async ({ body, headers }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(DOCUMENT, body, METHOD.POST, {
            headers,
        });
        return handleResponse(response, type, 'document');
    } catch (e) {
        handleError(e, FAIL);
    }
};

export const updateDocumentService = async ({ document, headers }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(`${DOCUMENT}/${document.id}`, document, METHOD.PUT, {
            headers,
        });
        return handleResponse(response, type, 'document');
    } catch (e) {
        handleError(e, FAIL);
    }
};

export const deleteDocumentService = async ({ documentId, unitGroupId }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(`${DOCUMENT}/${documentId}`, {}, METHOD.DELETE, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        handleError(e, FAIL);
    }
};