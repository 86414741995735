import { call, put, all, takeLatest } from 'redux-saga/effects';
import {
  exportReportServiceByMonthService,
  getReportServiceByMonthService,
  getSumPaymentReportServiceByMonthService,
} from 'services/cashbook';
import { X_UNIT_GROUP_ID, PAGINATION_LIMIT } from 'shared/constants';
import { DEBT } from './service-by-months.types';
import { convertFilterObject } from './service-by-months.utils';

function* getDataDebtReport(action) {
  const { unitGroupId, filterObject, page } = action.payload;
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };
  const params = {
    ...convertFilterObject(filterObject),
    page,
    limit: PAGINATION_LIMIT,
  };

  const result = yield call(getReportServiceByMonthService, { params, headers }, DEBT.GETS);
  yield put(result);
}

function* exportDebtInPageReport(action) {
  const { unitGroupId, filterObject, type } = action.payload;
  const params = {
    ...convertFilterObject(filterObject),
    type,
  };
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(exportReportServiceByMonthService, { params, headers }, DEBT.EXPORT);
  yield put(result);
  yield put({
    type: DEBT.EXPORT.REFRESH,
  });
}

function* getTotalDebtReport(action) {
  const { unitGroupId, filterObject } = action.payload;
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };
  const params = {
    ...convertFilterObject(filterObject),
  };
  const result = yield call(getSumPaymentReportServiceByMonthService, { params, headers }, DEBT.TOTAL);
  yield put(result);
}

export default function* watch() {
  yield all([
    takeLatest(DEBT.GETS.REQUEST, getDataDebtReport),
    takeLatest(DEBT.TOTAL.REQUEST, getTotalDebtReport),
    takeLatest(DEBT.EXPORT.REQUEST, exportDebtInPageReport),
  ]);
}
