import { merge, omit, union, filter, map } from 'lodash';
import { convertArrayToObject } from '../libs/convert';
import { createSelector } from 'reselect';

export const initStateData = {
    byId: {},
    ids: [],
    dataGets: false,
    totalPage: 0,
    currentPage: 1,
    pageFetching: {},
};

const data = (types, keys = ['result', 'result', 'result', 'id']) => {
    if (!Array.isArray(types) || types.length !== 4) {
        throw new Error('Expected types to be an array of four elements.');
    }
    if (!Array.isArray(keys) || keys.length !== 4) {
        throw new Error('Expected keys to be an array of four elements.');
    }
    const [GETS, ADD, UPDATE, DELETE] = types;
    let [KEY_GETS, KEY_ADD, KEY_UPDATE, KEY_DELETE] = keys;

    function updateData(state: Object = initStateData, action: Object) {
        if (action.type === GETS.SUCCESS) {
            const byId = merge({}, state.byId, convertArrayToObject(action.payload[KEY_GETS] ? action.payload[KEY_GETS] : []));
            const ids = union(state.ids, map(action.payload[KEY_GETS], item => item.id));
            const totalPage = action.payload.totalPage || state.totalPage;
            const currentPage = action.payload.currentPage || state.currentPage;
            const pageFetching = merge({}, state.pageFetching, {
                [currentPage]: {
                    fetching: true,
                    ids: union(state.pageFetching[currentPage] && state.pageFetching[currentPage].ids, map(action.payload[KEY_GETS], item => item.id)),
                }
            });
            return {
                ...state,
                byId,
                ids,
                dataGets: true,
                totalPage,
                currentPage,
                pageFetching,
            };
        } else if (action.type === ADD.SUCCESS) {
            return {
                ...state,
                byId: merge({}, state.byId, {
                    [action.payload[KEY_ADD].id]: action.payload[KEY_ADD],
                }),
                ids: union(state.ids, [action.payload[KEY_ADD].id]),
                pageFetching: {
                    ...state.pageFetching,
                    [state.totalPage]: {
                        fetching: false,
                        ids: union(state.pageFetching[state.totalPage] && state.pageFetching[state.totalPage].ids, [action.payload[KEY_ADD].id]),
                    },
                },
            };
        } else if (action.type === UPDATE.SUCCESS) {
            return {
                ...state,
                byId: merge({}, state.byId, {
                    [action.payload[KEY_UPDATE].id]: action.payload[KEY_UPDATE],
                }),
            };
        } else if (action.type === DELETE.SUCCESS) {
            return {
                ...state,
                byId: omit(state.byId, action.payload[KEY_DELETE]),
                ids: filter(state.ids, id => id !== action.payload[KEY_DELETE]),
                pageFetching: {
                    ...state.pageFetching[state.currentPage],
                    [state.currentPage]: {
                        fetching: false,
                        ids: filter(state.pageFetching[state.currentPage].ids, id => id !== action.payload[KEY_DELETE]),
                    },
                },
            };
        } else {
            return state;
        }
    }

    return function(state: Object = initStateData, action: Object) {
        switch (action.type) {
        case GETS.SUCCESS:
        case ADD.SUCCESS:
        case UPDATE.SUCCESS:
        case DELETE.SUCCESS:
            return {
                ...state,
                ...updateData(state, action),
            };
        default:
            return state;
        }
    };
};

export default data;

export const getAllDataSelector = createSelector(
    state => state,
    state => state.ids.map(id => state.byId[id]),
);

export const getDataByPageSelector = createSelector(
    state => state,
    state => state.pageFetching[state.currentPage] && state.pageFetching[state.currentPage]
        .ids.map(id => state.byId[id]),
);