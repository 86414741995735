import { forEach, isObject } from 'lodash';
import PAGINATION_LIMIT from 'shared/constants/pagination';

export const getTotalPage = (totalRecord = 0, size = PAGINATION_LIMIT) => {
  return Math.ceil(totalRecord / size) || 1;
};

export const getTotalRecord = (headers) => {
  if (!headers.get('Content-Range')) {
    throw Error('Header need Content-Range');
  }
  const count = headers.get('Content-Range').split('/')[1];
  return parseInt(count, 10);
};

export const checkResponseError = (response) => {
  if (!isObject(response) || (isObject(response) && response && response.error)) {
    return (
      (response &&
        response.error && {
          code: response.error.statusCode, // code of status
          message: response.error.message,
          codeLanguage: response.error.code,
        }) || {
        code: 500,
        message: 'Something wrong',
      }
    );
  }
  return false;
};

export const handleError = (error, type, code = 500) => {
  return {
    type,
    payload: {
      code,
      error,
    },
  };
};

export const handleResponse = (response, type, key = 'result', page = 0, size = PAGINATION_LIMIT, loadMore = false) => {
  const err = checkResponseError(response);
  if (!err) {
    if (page) {
      return {
        type: type.SUCCESS,
        payload: {
          code: response.code,
          result: response.data,
          totalPage: getTotalPage(getTotalRecord(response.headers), size),
          totalRecord: getTotalRecord(response.headers),
          currentPage: page,
        },
      };
    }
    if (loadMore) {
      return {
        type: type.SUCCESS,
        payload: {
          code: response.code,
          [key]: response.data,
          loadMore: response.readmore,
        },
      };
    }
    return {
      type: type.SUCCESS,
      payload: {
        code: response.code,
        [key]: response.data,
      },
    };
  }
  return {
    type: type.FAIL,
    payload: {
      code: err.code,
      codeLanguage: err.codeLanguage,
      message: err.message,
    },
  };
};

export const getFilter = (filterObject) => {
  const filter = { ...filterObject };
  forEach(filter, (value, key) => {
    if (!value) {
      delete filter[key];
    } else {
      filter[key] = {
        ilike: `%25${value}%25`,
      };
    }
  });
  return filter;
};
