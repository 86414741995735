import { handleError, handleResponse } from 'libs/http';
import { callApi, downloadFile } from './api';
import {
  REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME,
  TOTAL_PAYMENT_REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME,
  EXPORT_REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME_REVENUE,
  EXPORT_REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME_DEBT,
  EXPORT_REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME_DEFAULT,
  EXPORT_REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME_REMAINING_DEBT,
} from 'defined/API';
import { METHOD } from '../constants';

export async function gets(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME, null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type, 'result', true);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

export async function getTotalMoney(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIl',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(TOTAL_PAYMENT_REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME, null, METHOD.GET, {
      headers,
      params,
    });
    return handleResponse(response, type);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

export async function exportDefault(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIl',
  }
) {
  const { FAIL } = type;
  try {
    let response = await downloadFile(EXPORT_REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME_DEFAULT, {}, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function exportRevenue(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIl',
  }
) {
  const { FAIL } = type;
  try {
    let response = await downloadFile(EXPORT_REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME_REVENUE, {}, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function exportDebt(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIl',
  }
) {
  const { FAIL } = type;
  try {
    let response = await downloadFile(EXPORT_REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME_DEBT, {}, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function exportRemainingDebt(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIl',
  }
) {
  const { FAIL } = type;
  try {
    let response = await downloadFile(EXPORT_REPORT_SERVICE_UPDATE_UNTIL_CURRENT_TIME_REMAINING_DEBT, {}, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}
