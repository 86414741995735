//@flow

import { APPLICATION, BUILDING } from '../constants/actionType';

type State = {
    applicationInit: {
        isFetching: boolean,
        code?: number
    }
}

export const initState = {
    applicationInit: {
        isFetching: false
    }
};

export default (state: State = initState, action: Object) => {
    switch (action.type) {
        case APPLICATION.INIT.REQUEST:
            return {
                ...state,
                applicationInit: {
                    code: undefined,
                    isFetching: true,
                    buildings: [],
                }
            };
        case APPLICATION.INIT.SUCCESS:
            return {
                ...state,
                applicationInit: {
                    code: action.payload.code,
                    isFetching: false,
                    buildings: action.payload.buildings,
                }
            };
        case BUILDING.ADD.SUCCESS:
            return {
                ...state,
                applicationInit: {
                    ...state.applicationInit,
                    buildings: [
                        ...state.applicationInit.buildings,
                        action.payload.building,
                    ],
                }
            };
        case APPLICATION.INIT.FAIL:
            return {
                ...state,
                applicationInit: {
                    code: action.payload.code,
                    isFetching: true
                }
            };
        default:
            return state;
    }
};