// @flow

import { initStateFetching } from './fetching';
import fetching from './fetching';
import { combineReducers } from 'redux';
import { CONFIG, FORMULA, SURCHARGE } from '../constants/actionType';
import { convertArrayToObject } from '../libs/function';
import { uniq, map, omit, filter, forEach, find, toArray } from 'lodash';
import { Status } from '../constants';
import { initStateData } from './operatorData';
import operatorData from './operatorData';

export const initState = {
    systemServiceInit: {
        ...initStateFetching,
    },
    systemServiceGets: {
        ...initStateFetching,
    },
    systemServiceAdd: {
        ...initStateFetching,
    },
    systemServiceUpdate: {
        ...initStateFetching,
    },
    systemServiceDelete: {
        ...initStateFetching,
    },
    systemServiceInactive: {
        ...initStateFetching,
    },
    systemServiceActive: {
        ...initStateFetching,
    },
    serviceStatusById: {},
    byId: {},
    allIds: [],
    dataGets: false,
};

const systemServiceInit = fetching(CONFIG.SERVICE.INIT);
const systemServiceGets = fetching(CONFIG.SERVICE.GETS);
const systemServiceAdd = fetching(CONFIG.SERVICE.ADD);
const systemServiceUpdate = fetching(CONFIG.SERVICE.UPDATE);
const systemServiceDelete = fetching(CONFIG.SERVICE.DELETE);
const systemServiceInactive = fetching(CONFIG.SERVICE.INACTIVE);
const systemServiceActive = fetching(CONFIG.SERVICE.ACTIVE);

function serviceStatusById(state = {}, action) {
    switch (action.type) {
    case CONFIG.SERVICE.ACTIVE.REQUEST:
    case CONFIG.SERVICE.INACTIVE.REQUEST:
        return {
            ...state,
            [action.payload.serviceId]: {
                isFetching: true,
                code: undefined,
                codeLanguage: undefined,
            },
        };
    case CONFIG.SERVICE.ACTIVE.SUCCESS:
    case CONFIG.SERVICE.INACTIVE.SUCCESS:
    case CONFIG.SERVICE.ACTIVE.FAIL:
    case CONFIG.SERVICE.INACTIVE.FAIL:
        return {
            ...state,
            [action.payload.serviceId]: {
                isFetching: false,
                code: action.payload.code,
                codeLanguage: action.payload.codeLanguage,
            },
        };
    case CONFIG.SERVICE.ACTIVE.REFRESH:
    case CONFIG.SERVICE.INACTIVE.REFRESH:
        return {
            ...state,
            [action.payload.serviceId]: {
                isFetching: false,
                code: undefined,
                codeLanguage: undefined,
            },
        };
    default:
        return state;
    }
}

function addServiceEntry(state: Object, action: Object) {
    const { service } = action.payload;
    return {
        ...state,
        [service.id]: service,
    };
}

function updateServiceEntry(state: Object, action) {
    const { service } = action.payload;
    return {
        ...state,
        [service.id]: service,
    };
}

function getServiceEntry(state: Object, action: Object) {
    const { services } = action.payload;
    return {
        ...state,
        ...convertArrayToObject(services),
    };
}

function deleteServiceEntry(state: Object, action: Object) {
    const { serviceId } = action.payload;
    return omit(state, serviceId);
}

function inactiveServiceEntry(state: Object, action: Object) {
    const { serviceId } = action.payload;
    return {
        ...state,
        [serviceId]: {
            ...state[serviceId],
            status: Status.INACTIVE,
        },
    };
}

function activeServiceEntry(state: Object, action: Object) {
    const { serviceId } = action.payload;
    return {
        ...state,
        [serviceId]: {
            ...state[serviceId],
            status: Status.ACTIVE,
        },
    };
}

function addServiceId(state: Array<number>, action: Object) {
    const { service } = action.payload;
    return [
        ...state,
        service.id,
    ];
}

function getServiceId(state: Array<number>, action: Object) {
    const { services } = action.payload;
    const arrServiceId = [
        ...state,
        ...map(services, service => service.id),
    ];
    return uniq(arrServiceId);
}

function deleteServiceId(state: Array<number>, action: Object) {
    const { serviceId } = action.payload;
    return filter(state, id => id !== serviceId);
}

function addFormula(state: Object = {}, action: Object) {
    const { formula } = action.payload;

    return {
        ...state,
        [formula.serviceId]: {
            ...state[formula.serviceId],
            formulas: [
                ...state[formula.serviceId].formulas,
                formula,
            ],
        },
    };
}

function addSurcharge(state: Object = {}, action: Object) {
    const { surcharge } = action.payload;


    return {
        ...state,
        [surcharge.serviceId]: {
            ...state[surcharge.serviceId],
            surcharges: [
                ...toArray(state[surcharge.serviceId].surcharges),
                surcharge,
            ],
        },
    };
}

function byId(state: Object = {}, action: Object) {
    switch (action.type) {
    case CONFIG.SERVICE.ADD.SUCCESS:
        return addServiceEntry(state, action);
    case CONFIG.SERVICE.UPDATE.SUCCESS:
        return updateServiceEntry(state, action);
    case CONFIG.SERVICE.GETS.SUCCESS:
        return getServiceEntry(state, action);
    case CONFIG.SERVICE.DELETE.SUCCESS:
        return deleteServiceEntry(state, action);
    case CONFIG.SERVICE.INACTIVE.SUCCESS:
        return inactiveServiceEntry(state, action);
    case CONFIG.SERVICE.ACTIVE.SUCCESS:
        return activeServiceEntry(state, action);
    case FORMULA.ADD.SUCCESS:
        return addFormula(state, action);
    case SURCHARGE.ADD.SUCCESS:
        return addSurcharge(state, action);
    default:
        return state;
    }
}

function allIds(state: Array<number> = [], action: Object) {
    switch (action.type) {
    case CONFIG.SERVICE.ADD.SUCCESS:
        return addServiceId(state, action);
    case CONFIG.SERVICE.GETS.SUCCESS:
        return getServiceId(state, action);
    case CONFIG.SERVICE.DELETE.SUCCESS:
        return deleteServiceId(state, action);
    default:
        return state;
    }
}

function dataGets(state: boolean = false, action: Object) {
    switch (action.type) {
    case CONFIG.SERVICE.GETS.SUCCESS:
        return true;
    default:
        return state;
    }
}

export const getAllServiceSystem = (state: Object) => {
    const services = state.allIds.map(id => state.byId[id]);
    const serviceDefault = filter(services, service => !service.unitGroupId);
    const serviceUse = filter(services, service => service.unitGroupId);
    const result = [];
    // Thay thế dịch vụ khi sử dụng dịch vụ mặc định
    forEach(serviceDefault, service => {
        const serviceTmp = find(serviceUse, item => service.identifier === item.identifier);
        if (serviceTmp) {
            result.push(serviceTmp);
        } else {
            result.push(service);
        }
    });
    forEach(serviceUse, service => {
        const serviceTmp = find(result, item => item.identifier === service.identifier);
        !serviceTmp && result.push(service);
    });
    return result;
};

export default combineReducers({
    systemServiceInit,
    systemServiceGets,
    systemServiceAdd,
    systemServiceUpdate,
    systemServiceDelete,
    systemServiceInactive,
    systemServiceActive,
    serviceStatusById,
    byId,
    allIds,
    dataGets,
});
