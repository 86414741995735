import { createSelector } from 'reselect';
import { map, get, forEach } from 'lodash';
import { buildDetailBills } from 'shared/utils';

const selectReceiptStat = (state) => state.receiptStatistic;

export const makeSelectReceipts = () =>
  createSelector(selectReceiptStat, (receiptStat) => {
    const dataSource = receiptStat.receipts.data?.map((record) => {
      const bills = map(record.bills, (bill) => {
        const { content, note } = buildDetailBills({
          createdFor: get(bill, 'createdFor'),
          serviceName: get(bill, 'contract.service.name', ''),
          contractTemplateName: get(bill, 'contract.contractTemplate.name'),
          totalNumberUse: get(bill, 'totalNumberUse', 0),
          registrationPlate: get(bill, 'contract.registrationPlate'),
          parentId: get(bill, 'parentId'),
          type: get(bill, 'type'),
          contentBill: get(bill, 'content'),
        });
        return { ...bill, key: bill.id, note, content };
      });
      return { ...record, key: record.id, bills };
    });
    return { ...receiptStat.receipts, dataSource };
  });

export const makeSelectSumTotalPayment = () =>
  createSelector(
    selectReceiptStat,
    (receiptStat) => receiptStat.receiptSumTotalPayment?.data?.sum && Number(receiptStat.receiptSumTotalPayment.data.sum)
  );

export const makeSelectSumUnitAccountPayment = () =>
  createSelector(
    selectReceiptStat,
    (receiptStat) =>
      receiptStat.receiptSumTotalPayment?.data?.unitAccountPayment && Number(receiptStat.receiptSumTotalPayment.data.unitAccountPayment)
  );

export const makeSelectReceiptCancel = () => createSelector(selectReceiptStat, (receiptStat) => receiptStat.receiptCancel);

export const makeSelectReceiptExport = () => createSelector(selectReceiptStat, (receiptStat) => receiptStat.receiptExport);

export const makeSelectReceiptById = () => createSelector(selectReceiptStat, (receiptStat) => receiptStat.receiptGetById);

export const makeSelectUnits = () => createSelector(selectReceiptStat, (receiptStat) => receiptStat.units);

export const makeSelectPayerOptions = () =>
  createSelector(selectReceiptStat, (receiptStat) => {
    const payerSuggestions = new Set([]);
    forEach(receiptStat.units.data, (item) => {
      forEach(item.residents, (resident) => {
        const option = resident.fullName;
        if (!payerSuggestions.has(option)) payerSuggestions.add(option);
      });
    });
    const result = Array.from(payerSuggestions.values());
    return result.map((el) => ({ value: el }));
  });

export const makeSelectReceiptAdd = () => createSelector(selectReceiptStat, (receiptStat) => receiptStat.receiptAdd);

export const makeSelectReceiptUpdate = () => createSelector(selectReceiptStat, (receiptStat) => receiptStat.receiptUpdate);
