import { X_UNIT_GROUP_ID } from '../../constants';
import { STATISTIC } from '../../constants/actionType';
import { call, put } from 'redux-saga/effects';
import {
    exportServiceChargeService,
    getDataServiceChargeService,
    getListApartmentUseServiceService,
} from 'services/cashbook';

export function* getDataServiceCharge(action) {
    const {
        unitGroupId,
        filterObject,
    } = action.payload;

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const params = {
        ...filterObject,
    };

    const result = yield call(getDataServiceChargeService, { params, headers }, STATISTIC.SERVICE_CHARGE.GETS);
    yield put(result);
}

export function* exportServiceSurchargeInPageReport(action) {
    const {
        unitGroupId,
        filterObject,
    } = action.payload;
    const params = {
        ...filterObject,
    };
    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };
    const result = yield call(exportServiceChargeService, { params, headers }, STATISTIC.SERVICE_CHARGE.EXPORT);
    yield put(result);
    yield put({
        type: STATISTIC.SERVICE_CHARGE.EXPORT.REFRESH,
    });
}

export function* getListApartmentUseService(action) {
    const {
        unitGroupId,
        serviceId,
        filterObject,
        page,
    } = action.payload;
    const params = {
        ...filterObject,
        page,
        limit: 20,
    };
    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };
    const result = yield call(getListApartmentUseServiceService,
        { params, headers, serviceId }, STATISTIC.SERVICE_CHARGE.GETS_APARTMENT_USE_SERVICE);
    yield put(result);
}
