import { METHOD, X_UNIT_GROUP_ID } from 'shared/constants';
import { handleError, handleResponse } from 'shared/utils';
import { callApi } from './api';

const SERVER = process.env.API_URL;
const EVENT_GETS = `${SERVER}/event/list`;
const EVENT_APARTMENT_GETS = (id) => `${SERVER}/event/${id}/units`;
const EVENT_GET = (idEvent) => `${SERVER}/event/${idEvent}`;
const EVENT_ADD = `${SERVER}/event`;
const EVENT_DELETE = (idEvent) => `${SERVER}/event/${idEvent}`;

export async function getEventsService(
  buildingId,
  page = 1,
  limit = 20,
  params,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;

  try {
    let response = await callApi(EVENT_GETS, {}, METHOD.GET, {
      params: {
        ...params,
        page,
        limit,
      },
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      },
    });
    return handleResponse(response, type, 'result', page, limit);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getUnitsReceiveEventService(
  buildingId,
  page = 1,
  limit = 20,
  params,
  idEvent,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;

  try {
    let response = await callApi(EVENT_APARTMENT_GETS(idEvent), {}, METHOD.GET, {
      params: {
        ...params,
        page,
        limit,
      },
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      },
    });
    return handleResponse(response, type, 'result', page, limit);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getEventService(
  buildingId,
  eventId,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;

  try {
    let response = await callApi(EVENT_GET(eventId), {}, METHOD.GET, {
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      },
    });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function addEventService(
  buildingId,
  event,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;

  try {
    let response = await callApi(EVENT_ADD, event, METHOD.POST, {
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      },
    });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}

export async function deleteEventService(
  buildingId,
  eventId,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;

  try {
    let response = await callApi(EVENT_DELETE(eventId), {}, METHOD.DELETE, {
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      },
    });
    return handleResponse(response, type);
  } catch (err) {
    return handleError(err, FAIL);
  }
}
