import { FCM_SEND_DEVICE_TOKEN } from '../defined/API';
import { METHOD } from '../constants';
import { callApi } from './api';
import { handleError, handleResponse } from '../libs/http';

export const sendDeviceTokenService = async (data, type) => {
  const { FAIL } = type;

  try {
    const body = {
      ...data,
    };

    const response = await callApi(FCM_SEND_DEVICE_TOKEN, body, METHOD.POST, {
      headers: {
        'x-white-label-key': process.env.WHITE_LABEL
      }
    });

    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
}