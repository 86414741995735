// @flow

import { SUPPLY } from '../../constants/actionType';
import { initStateFetching } from '../fetching';
import { initStatePagination } from '../paginate';
import fetching from '../fetching';
import paginate from '../paginate';
import { combineReducers } from 'redux';
import { SUPPLY_EXPORT } from 'defined/API';

export const initState = {
    supplyAdd: {
        ...initStateFetching,
    },
    supplyUpdate: {
        ...initStateFetching,
    },
    supplyDelete: {
        ...initStateFetching,
    },
    supplyGets: {
        ...initStatePagination,
    },
    partnerGetInForm: {
        ...initStatePagination,
    },
    employeeGetInForm: {
        ...initStatePagination,
    },
    departmentGetInForm: {
        ...initStatePagination,
    },
    transactionAdd: {
        ...initStateFetching,
    },
    historyGets: {
        ...initStatePagination,
    },
    supplyExport: {
        ...initStatePagination,
    },
    supplyImport: {
        ...initStatePagination,
    },
    supplyHistoryUseExport: {
        ...initStatePagination,
    },
};

const supplyAdd = fetching(SUPPLY.ADD);
const transactionAdd = fetching(SUPPLY.TRANSACTION.ADD);
const supplyUpdate = fetching(SUPPLY.UPDATE);
const supplyDelete = fetching(SUPPLY.DELETE);
const supplyGets = paginate(SUPPLY.GETS);
const partnerGetInForm = paginate(SUPPLY.FORM.SEARCH_PARTNER);
const employeeGetInForm = paginate(SUPPLY.FORM.SEARCH_EMPLOYEE);
const departmentGetInForm = paginate(SUPPLY.FORM.SEARCH_DEPARTMENT);
const historyGets = paginate(SUPPLY.HISTORY_GETS);
const supplyExport = paginate(SUPPLY.EXPORT);
const supplyImport = paginate(SUPPLY.IMPORT);
const supplyHistoryUseExport = paginate(SUPPLY.HISTORY_EXPORT);

export default combineReducers({
    supplyAdd,
    supplyUpdate,
    supplyDelete,
    supplyGets,
    partnerGetInForm,
    employeeGetInForm,
    departmentGetInForm,
    transactionAdd,
    historyGets,
    supplyExport,
    supplyImport,
    supplyHistoryUseExport,
});