import { language } from 'defined/Language';
import convertArrToObj from 'shared/utils/convertArrToObj';

export const INVOICE_NORMAL = 'NORMAL';
export const INVOICE_REMIND_DEBT = 'REMIND_DEBT';
export const INVOICE_REMIND_DEBT_FIRST = 'REMIND_DEBT_FIRST';
export const INVOICE_REMIND_DEBT_SECOND = 'REMIND_DEBT_SECOND';

export const INVOICE_BILLS_ALL = 'ALL';
export const INVOICE_BILLS_UNPAID = 'UNPAID';

export const INVOICE_EMAIL_NOT_STARTED = 'NOT_STARTED';
export const INVOICE_EMAIL_IN_PROGRESS = 'IN_PROGRESS';
export const INVOICE_EMAIL_COMPLETED = 'COMPLETED';
export const INVOICE_EMAIL_FAILED = 'FAILED';

export const INVOICE_EMAIL_STATUS = [
  {
    id: INVOICE_EMAIL_IN_PROGRESS,
    name: language.label.sending,
  },
  {
    id: INVOICE_EMAIL_COMPLETED,
    name: language.label.success,
  },
  {
    id: INVOICE_EMAIL_FAILED,
    name: language.label.failed,
  },
];

export const INVOICE_EMAIL_STATUS_BY_ID = convertArrToObj(INVOICE_EMAIL_STATUS, 'id');

export const INVOICE_TYPE_FILTERS = [
  { id: INVOICE_NORMAL, name: language.label.normal_invoice },
  { id: INVOICE_REMIND_DEBT_FIRST, name: language.remind_debt_first_times },
  { id: INVOICE_REMIND_DEBT_SECOND, name: language.remind_debt_second_times },
];
export const INVOICE_TYPES = [
  { id: INVOICE_NORMAL, name: language.label.normal_invoice },
  { id: INVOICE_REMIND_DEBT, name: language.label.debt_invoice },
];

export const INVOICE_REMIND_DEBT_TYPES = [
  { id: INVOICE_REMIND_DEBT_FIRST, name: language.remind_debt_first_times },
  { id: INVOICE_REMIND_DEBT_SECOND, name: language.remind_debt_second_times },
];

export const INVOICE_TYPE_ID = {
  [INVOICE_NORMAL]: language.label.normal_invoice,
  [INVOICE_REMIND_DEBT_FIRST]: language.remind_debt_first_times,
  [INVOICE_REMIND_DEBT_SECOND]: language.remind_debt_second_times,
};

export const INVOICE_BILLS_TYPE = [
  { id: INVOICE_BILLS_ALL, name: language.label.show_all_invoices_arising_in_the_month },
  { id: INVOICE_BILLS_UNPAID, name: language.label.show_all_outstanding_invoices_arising_in_the_month },
];

export const BILL_ACTIVE = 'ACTIVE'; // Bill active
export const BILL_DISABLED = 'DISABLED'; // Bill has been deleted
export const BILL_CANCELLED = 'CANCELLED'; // Bill has been cancelled

export const BILL_PAID = 'PAID'; // Bill has been paid
export const BILL_UNPAID = 'UNPAID'; // Bill unpaid
export const BILL_IN_PROCESS = 'IN_PROCESS'; // Bill is in processing
export const BILLS_PARTIAL_PAID = 'PARTIAL_PAID'; // Bill has been partial paid
export const EMPTY = 'EMPTY'; // Bill is adjust down

export const BILL_INCURRED_CREATED_FOR_INVALID = 'BILL_INCURRED_CREATED_FOR_INVALID';
export const EXISTS_ADJUST_BILL_HAVE_NOT_CANCELLED_YET = 'EXISTS_ADJUST_BILL_HAVE_NOT_CANCELLED_YET';
export const ORIGIN_BILL_CAN_NOT_ADJUST = 'ORIGIN_BILL_CAN_NOT_ADJUST';
export const ADJUST_BILL_CAN_NOT_UPDATE = 'ADJUST_BILL_CAN_NOT_UPDATE';
export const BILL_CREATED_BEFORE_LOCK_DATE = 'BILL_CREATED_BEFORE_LOCK_DATE';
export const DEBT_MONTH_TYPE = 'DEBT_MONTH';
export const INCURRED_MONTH_TYPE = 'INCURRED_MONTH';
export const FEE_NOTIFICATION_MONTH_TYPE = 'FEE_NOTIFICATION_MONTH';

export const BILL_STATE_OPTIONS = [
  { id: BILL_ACTIVE, name: language.label.close },
  { id: BILL_CANCELLED, name: language.label.void },
];

export const BILL_PAYMENT_STATE_OPTIONS = [
  { id: BILL_UNPAID, name: language.label.unpaid },
  { id: BILL_PAID, name: language.label.paid },
  { id: BILLS_PARTIAL_PAID, name: language.label.partial_paid },
  { id: EMPTY, name: language.label.empty },
];

export const BILL_TYPE_ADJUST_UP = 'ADJUST_UP';
export const BILL_TYPE_ADJUST_DOWN = 'ADJUST_DOWN';

export const ADJUST_TYPE = [
  { id: BILL_TYPE_ADJUST_UP, name: language.label.adjust_up },
  { id: BILL_TYPE_ADJUST_DOWN, name: language.label.adjust_down },
];

export const INVOICE_BILL_TYPE_BILL_FILTER = [
  { id: 'NORMAL', name: language.label.invoice1 },
  { id: 'DEBT', name: language.label.debt_bill },
  { id: 'ADJUSTED', name: language.label.bill_have_adjust },
  { id: 'ADJUST_UP', name: language.label.voucher_adjust_up },
  { id: 'ADJUST_DOWN', name: language.label.voucher_adjust_down },
];

export const INVOICE_BILL_ADJUST = [BILL_TYPE_ADJUST_UP, BILL_TYPE_ADJUST_DOWN];

export const INVOICE_TYPE_CREATED_FOR = [
  { id: DEBT_MONTH_TYPE, name: language.label.debt_month1 },
  { id: INCURRED_MONTH_TYPE, name: language.label.accrued_month },
  { id: FEE_NOTIFICATION_MONTH_TYPE, name: language.label.fee_notification_month },
];
