import ReactGA from 'react-ga';
import Cookie from '../defined/Cookie';
import get from 'lodash/get';
const trackingId = 'UA-150929557-1';

export const initializeGA = () => {
    if (process.env.NODE_ENV === 'production') {
        ReactGA.initialize(trackingId);
    }
};

export const logPageView = (history) => {
    if (process.env.NODE_ENV === 'production') {
        history.listen((location) => {
            const page = location.pathname || window.location.pathname;
            if (page.includes('/buildings')) {
                const cookie = Cookie.get('cyhome');
                ReactGA.set({
                    page: page,
                    userId: get(cookie, 'user.id')
                });
                ReactGA.pageview(page);
            }
        });
    }
};
