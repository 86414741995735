import {
    addDepartmentService, addPositionService,
    deleteDepartmentService, deletePositionService,
    getDepartmentsService, getGroupPermissionsService, getPositionsService,
    updateDepartmentService, updatePositionService,
} from '../../services';
import { CONFIG } from '../../constants/actionType';
import { call, put, select } from 'redux-saga/effects';
import { findIndex, map, groupBy, orderBy } from 'lodash';
import { language } from '../../defined/Language';
import { X_UNIT_GROUP_ID } from '../../constants';
import { getPermission } from '../../actions/permission';

export function* initPositionConfigPage(action) {
    function splitName(name) {
        let arr = name.split('_');
        let suffix = arr.pop();
        let prefix = arr.join('_');
        return [prefix, suffix];
    }

    function getPrioritySuffix(suffix) {
        let priority = 0;
        switch (suffix) {
        case 'READ': {
            priority = 1;
            break;
        }
        case 'EXPORT': {
            priority = 2;
            break;
        }
        case 'WRITE': {
            priority = 3;
            break;
        }
        }
        return priority;
    }

    function sortPermission(permissions) {
        let result = [];
        let obj = groupBy(permissions, 'prefix');

        Object.keys(obj).forEach(key => {
            obj[key] = orderBy(obj[key], ['prioritySuffix'], 'desc');
            result = [...result, ...obj[key]];
        });

        return result;
    }

    const {
        buildingId,
    } = action.payload;
    try {
        let payload = {
            code: 200,
            groupPermissions: undefined,
        };
        const error = [];
        const headersDepartment = {
            [X_UNIT_GROUP_ID]: buildingId
        };
        const params = {
            isIncludePermission: true
        };
        let resDepartment = yield call(getDepartmentsService, {
            headers: headersDepartment,
            params,
        }, CONFIG.POSITION.DEPARTMENT.GETS);
        yield put(resDepartment);

        const isFetchingGroupPermissionData = yield select(state => state.data.isFetchingGroupPermissionData);
        if (!isFetchingGroupPermissionData) {
            const resGroupPermissions = yield call(getGroupPermissionsService);
            if (resGroupPermissions.type === 'SUCCESS') {

                let groupPermissions = map(resGroupPermissions.payload.result, group => {
                    let permissions = map(group.permissions, permission => {
                        let [prefix, suffix] = splitName(permission.name);
                        let prioritySuffix = getPrioritySuffix(suffix);
                        return {
                            ...permission,
                            prefix,
                            suffix,
                            prioritySuffix,
                        };
                    });
                    permissions = sortPermission(permissions);
                    return {
                        ...group,
                        permissions,
                    };
                });

                payload.groupPermissions = groupPermissions;

            }
        }
        yield put({
            type: CONFIG.POSITION.INIT.SUCCESS,
            payload: {
                ...payload,
                error: error.join(', '),
            },
        });
    }
    catch (err) {
        yield put({
            type: CONFIG.POSITION.INIT.FAIL,
            payload: {
                code: 0,
                error: err,
            },
        });
    }
}

// department
export function* getDepartments(action) {
    const {
        buildingId,
    } = action.payload;
    const headersDepartment = {
        [X_UNIT_GROUP_ID]: buildingId
    };
    const params = {
        isIncludePermission: true
    };
    const result = yield call(getDepartmentsService, {
        headers: headersDepartment,
        params,
    }, CONFIG.DEPARTMENT.GETS);
    yield put(result);
}

export function* addDepartment(action) {
    const {
        department,
        buildingId,
    } = action.payload;
    const result = yield call(addDepartmentService, {
        department,
        unitGroupId: buildingId,
    }, CONFIG.POSITION.DEPARTMENT.ADD);
    yield put(result);
    yield put({
        type: CONFIG.POSITION.DEPARTMENT.ADD.REFRESH,
    });
}

export function* updateDepartment(action) {
    const {
        department,
        buildingId,
    } = action.payload;

    const result = yield call(updateDepartmentService, {
        department,
        unitGroupId: buildingId,
    }, CONFIG.POSITION.DEPARTMENT.UPDATE);
    yield put(result);
    yield put({
        type: CONFIG.POSITION.DEPARTMENT.UPDATE.REFRESH,
    });
}

export function* deleteDepartment(action) {
    const {
        department,
        buildingId,
    } = action.payload;

    const result = yield call(deleteDepartmentService, {
        departmentId: department.id,
        unitGroupId: buildingId,
    }, CONFIG.POSITION.DEPARTMENT.DELETE);
    yield put(result);
    yield put({
        type: CONFIG.POSITION.DEPARTMENT.DELETE.REFRESH,
    });
}

// position

export function* getPosition(action) {
    const {
        buildingId
    } = action.payload;
    const result = yield call(getPositionsService, buildingId, CONFIG.POSITION.GETS);
    const indexDepartmentEmpty = findIndex(result.payload.result, item => !item.id);
    const departmentEmpty = {
        ...result.payload.result[indexDepartmentEmpty],
        name: language['description']['not_in_department']
    };
    result.payload.result.splice(indexDepartmentEmpty, 1, departmentEmpty);
    yield put(result);
}

export function* addPosition(action) {
    const {
        position,
        buildingId,
    } = action.payload;
    const result = yield call(addPositionService, {
        position,
        unitGroupId: buildingId,
    }, CONFIG.POSITION.ADD);
    yield put(result);
    yield put({
        type: CONFIG.POSITION.ADD.REFRESH,
    });
}

export function* updatePosition(action) {
    const {
        position,
        buildingId,
    } = action.payload;
    const result = yield call(updatePositionService, {
        position,
        unitGroupId: buildingId,
    }, CONFIG.POSITION.UPDATE);
    yield put(result);
    yield put({
        type: CONFIG.POSITION.UPDATE.REFRESH,
    });
    yield put(getPermission(buildingId));
}

export function* deletePosition(action) {
    const {
        position,
        buildingId,
    } = action.payload;

    const result = yield call(deletePositionService, {
        positionId: position.id,
        unitGroupId: buildingId,
    }, CONFIG.POSITION.DELETE);
    yield put(result);
    yield put({
        type: CONFIG.POSITION.DELETE.REFRESH,
    });
}