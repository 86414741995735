import { language } from 'defined/Language';
import moment from 'moment';

export const AMENITIES_STATUS_ACTIVE = 'ACTIVE';
export const AMENITIES_STATUS_INACTIVE = 'INACTIVE';
export const AMENITIES_STATUS_MAPPING = {
  [AMENITIES_STATUS_ACTIVE]: language.active,
  [AMENITIES_STATUS_INACTIVE]: language.inactive,
};

export const AMENITIES_TYPE_NOT_PRE_ORDER = 'NO_RESERVATION';
export const AMENITIES_TYPE_PRE_ORDER_PERIOD_TIME = 'TIME_FRAME';
export const AMENITIES_TYPE_PRE_ORDER_TIME = 'SLOT_TIME';
export const AMENITIES_TYPE_MAPPING = {
  [AMENITIES_TYPE_NOT_PRE_ORDER]: language.amenities_type_not_pre_order,
  [AMENITIES_TYPE_PRE_ORDER_PERIOD_TIME]: language.amenities_type_pre_order_by_period_time,
  [AMENITIES_TYPE_PRE_ORDER_TIME]: language.amenities_type_pre_order_by_time,
};
export const AMENITIES_TYPE_ARRAY = [
  {
    label: language.amenities_type_not_pre_order,
    key: AMENITIES_TYPE_NOT_PRE_ORDER,
  },
  {
    label: language.amenities_type_pre_order_by_period_time,
    key: AMENITIES_TYPE_PRE_ORDER_PERIOD_TIME,
  },
  {
    label: language.amenities_type_pre_order_by_time,
    key: AMENITIES_TYPE_PRE_ORDER_TIME,
  },
];

export const AMENITIES_SERVE_DAY_SUNDAY = 0;
export const AMENITIES_SERVE_DAY_MONDAY = 1;
export const AMENITIES_SERVE_DAY_TUESDAY = 2;
export const AMENITIES_SERVE_DAY_WEDNESDAY = 3;
export const AMENITIES_SERVE_DAY_THURSDAY = 4;
export const AMENITIES_SERVE_DAY_FRIDAY = 5;
export const AMENITIES_SERVE_DAY_SATURDAY = 6;

export const AMENITIES_SERVE_DAY_ARRAY = [
  {
    label: language.amenities_serve_day_monday,
    key: AMENITIES_SERVE_DAY_MONDAY,
  },
  {
    label: language.amenities_serve_day_tuesday,
    key: AMENITIES_SERVE_DAY_TUESDAY,
  },
  {
    label: language.amenities_serve_day_wednesday,
    key: AMENITIES_SERVE_DAY_WEDNESDAY,
  },
  {
    label: language.amenities_serve_day_thursday,
    key: AMENITIES_SERVE_DAY_THURSDAY,
  },
  {
    label: language.amenities_serve_day_friday,
    key: AMENITIES_SERVE_DAY_FRIDAY,
  },
  {
    label: language.amenities_serve_day_saturday,
    key: AMENITIES_SERVE_DAY_SATURDAY,
  },
  {
    label: language.amenities_serve_day_sunday,
    key: AMENITIES_SERVE_DAY_SUNDAY,
  },
];

export const PAYMENT_EXPIRED_DATE_TYPE_HOUR = 'HOUR';
export const PAYMENT_EXPIRED_DATE_TYPE_DATE = 'DAY';
export const PAYMENT_EXPIRED_DATE_TYPES = [
  {
    key: PAYMENT_EXPIRED_DATE_TYPE_HOUR,
    label: language.hour,
  },
  {
    key: PAYMENT_EXPIRED_DATE_TYPE_DATE,
    label: language.date,
  },
];

export const PAYMENT_METHOD_ONLINE = 'ONLINE';
export const PAYMENT_METHOD_AT_THE_COUNTER = 'CHECKOUT';
export const PAYMENT_METHOD_ALL = 'ALL';
export const PAYMENT_METHODS = [
  // {
  //   key: PAYMENT_METHOD_ALL,
  //   label: language.all,
  // },
  // {
  //   key: PAYMENT_METHOD_ONLINE,
  //   label: language.payment_method_online,
  // },
  {
    key: PAYMENT_METHOD_AT_THE_COUNTER,
    label: language.payment_method_at_the_counter,
  },
];

export const AMENITIES_LIMIT_ORDER_PER_UNIT_TIME_WEEK = 'WEEK';
export const AMENITIES_LIMIT_ORDER_PER_UNIT_TIME_MONTH = 'MONTH';
export const AMENITIES_LIMIT_ORDER_PER_UNIT_TIMES = [
  {
    key: AMENITIES_LIMIT_ORDER_PER_UNIT_TIME_WEEK,
    label: language.one_week.toLowerCase(),
  },
  {
    key: AMENITIES_LIMIT_ORDER_PER_UNIT_TIME_MONTH,
    label: language.one_month.toLowerCase(),
  },
];

export const AMENITIES_REFUNDABLE_TYPE_REFUNDABLE = 'REFUND';
export const AMENITIES_REFUNDABLE_TYPE_NOT_REFUNDABLE = 'NO_REFUND';
export const AMENITIES_REFUNDABLE_TYPES = [
  {
    key: AMENITIES_REFUNDABLE_TYPE_REFUNDABLE,
    label: language.refundable,
  },
  {
    key: AMENITIES_REFUNDABLE_TYPE_NOT_REFUNDABLE,
    label: language.not_refundable,
  },
];

export const AMENITIES_REFUND_LEVEL_PERCENT = 'PERCENT';
export const AMENITIES_REFUND_LEVEL_FIXED = 'FIXED_PRICE';
export const AMENITIES_REFUND_LEVELS = [
  {
    key: AMENITIES_REFUND_LEVEL_PERCENT,
    label: language.percent,
  },
  {
    key: AMENITIES_REFUND_LEVEL_FIXED,
    label: language.fixed,
  },
];

export const AMENITIES_FORMULA_TYPE_NUMBER_USE = 'NUMBER_USE';
export const AMENITIES_FORMULA_TYPE_ACCUMULATED = 'ACCUMULATED';

export const AMENITIES_FORMULA_STATUS_ACTIVE = 'ACTIVE';
export const AMENITIES_FORMULA_STATUS_INACTIVE = 'INACTIVE';

export const AMENITIES_DEFAULT_VALUE = {
  amenitiesName: '',
  amenitiesStatus: false,
  amenitiesType: AMENITIES_TYPE_NOT_PRE_ORDER,
  amenitiesCode: '',
  amenitiesPaymentMethod: null,
  amenitiesPaymentExpiredNumberTime: null,
  amenitiesPaymentExpiredUnitTime: null,
  amenitiesLocation: '',
  amenitiesOrderInResidentApp: undefined,
  amenitiesDescription: '',
  amenitiesAvatar: '',
  amenitiesDescriptionImages: [],
  amenitiesStartDateServe: moment(),
  amenitiesEndDateServe: moment(),
  amenitiesTimeServes: [
    {
      amenitiesDayServe: AMENITIES_SERVE_DAY_MONDAY,
      amenitiesPeriodTimeServe: [moment().startOf('day'), moment().endOf('day')],
    },
  ],
  amenitiesLimitServe: 1,
  amenitiesPreOrder: {
    from: '',
    to: '',
  },
  amenitiesPreOrderLimit: {
    time: undefined,
    unit: undefined,
  },
  // time
  amenitiesFormulas: [
    {
      amenitiesFormula: {
        to: '',
      },
      amenitiesFormulaPrice: '',
    },
  ],
  // period time
  amenitiesPeriodTimePrice: '',
  amenitiesDepositAmount: '',
  amenitiesSurcharges: [],
  amenitiesCancelOrderPolicies: [],
};

export const AMOUNT_DATES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const PAYMENT_EXPIRED_DAYS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const TIMES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const MAX_PRIORITY_IN_RESIDENT_APP = 100000;
export const MAX_LIMIT_SERVE = 100000;
export const MAX_PRE_ORDER = 31;
export const MAX_TIME = 744;
export const MAX_PRICE = 2000000000;
export const MAX_DATE_CANCEL_ORDER_POLICY = 10;
