import { call, put, all, takeLatest } from 'redux-saga/effects';
import { exportAdvancePaymentService, getAdvancePaymentService, getAdvancePaymentTotalService } from 'services/report';
import { X_UNIT_GROUP_ID } from 'shared/constants';
import { ADVANCE_PAYMENT_GETS, ADVANCE_PAYMENT_TOTAL_GET, ADVANCE_PAYMENT_EXPORT } from './advancePayment.types';

function* getAdvancePayment(action) {
  const { page, filterObject, unitGroupId } = action.payload;

  const params = {
    ...filterObject,
    page,
    limit: 20,
  };

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(getAdvancePaymentService, { params, headers }, ADVANCE_PAYMENT_GETS);
  yield put(result);
}

function* getTotalAdvancePayment(action) {
  const { filterObject, unitGroupId } = action.payload;

  const params = {
    ...filterObject,
  };

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(getAdvancePaymentTotalService, { params, headers }, ADVANCE_PAYMENT_TOTAL_GET);
  yield put(result);
}

function* exportAdvancePayment(action) {
  const { filterObject, unitGroupId } = action.payload;

  const params = {
    ...filterObject,
  };

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(exportAdvancePaymentService, { params, headers }, ADVANCE_PAYMENT_EXPORT);
  yield put(result);

  yield put({
    type: ADVANCE_PAYMENT_EXPORT.REFRESH,
  });
}

export default function* watch() {
  yield all([
    takeLatest(ADVANCE_PAYMENT_GETS.REQUEST, getAdvancePayment),
    takeLatest(ADVANCE_PAYMENT_TOTAL_GET.REQUEST, getTotalAdvancePayment),
    takeLatest(ADVANCE_PAYMENT_EXPORT.REQUEST, exportAdvancePayment),
  ]);
}
