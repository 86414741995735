import { addPartnerService, deletePartnerService, getPartnerService, updatePartnerService } from '../../services/partner';
import { CONFIG, PARTNER } from '../../constants/actionType';
import { all, call, put } from 'redux-saga/effects';
import { X_UNIT_GROUP_ID } from '../../constants';
import { importApartmentService, importPartnerService, exportPartnerService } from '../../services';

export function* getPartners(action) {
    const {
        unitGroupId,
        filterObject,
    } = action.payload;
    const params = {
        ...filterObject,
    };
    const result = yield call(getPartnerService, { unitGroupId, params }, PARTNER.GETS);
    yield put(result);
}

export function* addPartner(action) {
    const {
        partner,
        unitGroupId,
    } = action.payload;
    const result = yield call(addPartnerService, {
        body: partner,
        headers: {
            [X_UNIT_GROUP_ID]: unitGroupId,
        }
    }, PARTNER.ADD);
    yield put({
        ...result
    });
    yield put({
        type: PARTNER.ADD.REFRESH
    });
}

export function* updatePartner(action) {
    const {
        id,
        partner,
        unitGroupId,
    } = action.payload;
    const result = yield call(updatePartnerService, {
        id,
        body: partner,
        headers: {
            [X_UNIT_GROUP_ID]: unitGroupId,
        }
    }, PARTNER.UPDATE);
    yield put({
        ...result
    });

    yield put({
        type: PARTNER.UPDATE.REFRESH
    });
}

export function* deletePartner(action) {
    const { partner } = action.payload;
    let result = yield call(deletePartnerService, {
        partnerId: partner.id,
        unitGroupId: partner.unitGroupId,
    }, PARTNER.DELETE);
    result.payload.partnerId = partner.id;
    yield put({
        ...result
    });
    yield put({
        type: PARTNER.DELETE.REFRESH
    });
}

export function* importPartner(action) {
    const {
        buildingId,
        file,
        filterObject,
    } = action.payload;
    const result = yield call(importPartnerService, buildingId, file, PARTNER.IMPORT);

    const resultPartnerGets = yield call(getPartnerService, {
        unitGroupId: buildingId,
        params: { ...filterObject },
    }, PARTNER.GETS);
    yield all([
        put(result),
        put(resultPartnerGets),
    ]);
    yield put({
        type: PARTNER.IMPORT.REFRESH,
    });
}

export function* exportPartners(action) {
    const {
        unitGroupId,
        filterObject,
    } = action.payload;
    const result = yield call(exportPartnerService, {
        headers: {
            [X_UNIT_GROUP_ID]: unitGroupId,
        }, params: {
            ...filterObject,
        }
    }, PARTNER.EXPORT);
    yield put(result);
}