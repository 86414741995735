const capitalizeFirstLetter = (string) => {
  return string[0].toUpperCase() + string.slice(1);
};

const normalizeLabel = (label) => {
  if (!label) return null;
  return label.split(' ').filter(Boolean).map(capitalizeFirstLetter).join(' ');
};

export default normalizeLabel;
