import { useDispatch, useSelector } from 'react-redux';
import { SUCCESS_CODE } from 'shared/constants';
import { LabelDucksActions, LabelDucksSelectors } from 'pages/Config/Label/ducks';
import useCurrentBuilding from './useCurrentBuilding';

const { getLabelsAction, getLabelTreeAction } = LabelDucksActions;
const { labelsCustomerCode, labelSelector, labelsAccounting, labelTreeSelector, labelTreeDataStatisticsSelector } = LabelDucksSelectors;

const useLabel = () => {
  const dispatch = useDispatch();
  const { building } = useCurrentBuilding();

  // data
  const labelData = useSelector(labelSelector);
  const labelsCustomerCodeData = useSelector(labelsCustomerCode);
  const labelsAccountingData = useSelector(labelsAccounting);
  const labelTreeData = useSelector(labelTreeSelector);
  const labelTreeDataStatistics = useSelector(labelTreeDataStatisticsSelector);

  // function
  const getLabels = () => {
    const { code } = labelData;
    if (SUCCESS_CODE.includes(code)) {
      return;
    }
    dispatch(getLabelsAction(building.id));
  };

  const getLabelTree = () => {
    const { code } = labelTreeData;
    if (SUCCESS_CODE.includes(code)) {
      return;
    }
    dispatch(getLabelTreeAction(building.id));
  };

  return {
    getLabelTree,
    getLabels,
    labelData,
    labelTreeData,
    labelsCustomerCodeData,
    labelsAccountingData,
    labelTreeDataStatistics,
  };
};

export default useLabel;
