import { combineReducers } from 'redux';
import paginate, { initStatePagination } from './paginate';
import fetching, { initStateFetching } from './fetching';
import { SERVICE } from '../constants';
import { RESIDENT } from '../constants/actionType';

export const initState = {
    service: {
        ...initStatePagination,
    },
    contract: {
        ...initStatePagination,
    },
    contractTemplate: {
        ...initStatePagination,
    },
    serviceGets: {
        ...initStateFetching,
    },
    contractGets: {
        ...initStateFetching,
    },
    contractAdds: {
        ...initStateFetching,
    },
    contractDelete: {
        ...initStateFetching,
    },
    contractUpdate: {
        ...initStateFetching,
    },
    contractActive: {
        ...initStateFetching,
    },
    contractInactive: {
        ...initStateFetching,
    },
    contractImport: {
        ...initStatePagination,
    },
    contractExport: {
        ...initStateFetching,
    },
    contractDownloadTemplate: {
        ...initStatePagination,
    },
    dataExport: '',
    sumTotalContract: {
        ...initStatePagination,
    },
    contractMultiActive: {
        ...initStateFetching,
    },
    contractMultiInactive: {
        ...initStateFetching,
    },
};

const serviceGets = fetching(SERVICE.GETS);
const contractGets = fetching(SERVICE.CONTRACT.GETS);
const contractAdds = fetching(SERVICE.CONTRACT.ADDS);
const contractDelete = fetching(SERVICE.CONTRACT.DELETE);
const contractUpdate = fetching(SERVICE.CONTRACT.UPDATE);
const contractActive = fetching(SERVICE.CONTRACT.ACTIVE);
const contractInactive = fetching(SERVICE.CONTRACT.INACTIVE);
const contractExport = fetching(SERVICE.CONTRACT.EXPORT);
const contractDownloadTemplate = paginate(SERVICE.DOWNLOAD_TEMPLATE);
const contractMultiActive = fetching(SERVICE.CONTRACT.ACTIVE_MULTI);
const contractMultiInactive = fetching(SERVICE.CONTRACT.INACTIVE_MULTI);

const service = paginate(SERVICE.GETS);
const contract = paginate(SERVICE.CONTRACT.GETS);
const contractTemplate = paginate(SERVICE.CONTRACT_TEMPLATE.GETS);
const unit = paginate(SERVICE.UNIT.GETS);
const contractImport = paginate(SERVICE.CONTRACT.IMPORT);

const sumTotalContract = paginate(SERVICE.CONTRACT.SUM_TOTAL);

function dataExport(state = '', action) {
    switch (action.type) {
    case SERVICE.CONTRACT.EXPORT.SUCCESS:
        return action.payload.result;
    default:
        return state;
    }
}


export default combineReducers({
    serviceGets,
    contractGets,
    contractAdds,
    contractDelete,
    contractUpdate,
    service,
    contract,
    contractTemplate,
    unit,
    contractActive,
    contractInactive,
    contractImport,
    contractExport,
    contractDownloadTemplate,
    dataExport,
    sumTotalContract,
    contractMultiActive,
    contractMultiInactive
});