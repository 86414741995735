import moment from 'moment';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { X_UNIT_GROUP_ID } from 'constants/http';
import {
  previewInvoiceDebtService,
  previewInvoiceService,
  printInvoiceDebtService,
  printInvoiceService,
  sendEmailInvoiceDebtService,
  sendEmailInvoiceService,
  deleteBillService,
  getListSendBillsService,
  sumTotalPaymentSendBillService,
  getFloorsService,
} from 'services';
import { INVOICE_REMIND_DEBT, PAGINATION_LIMIT } from 'shared/constants';
import {
  SEND_BILL_GET_SUM,
  SEND_BILL_GET_DETAIL_PREVIEW,
  SEND_BILL_GETS,
  SEND_BILL_PRINT,
  SEND_BILL_SEND_EMAIL,
  SEND_BILL_CANCEL_INVOICE,
  SEND_BILL_GET_FLOORS,
} from './sendBill.types';
import {
  SEND_BILL_SEND_EMAIL_START_PROGRESS,
  SEND_BILL_SEND_EMAIL_DONE_PROGRESS,
  SEND_BILL_PRINT_START_PROGRESS,
  SEND_BILL_PRINT_DONE_PROGRESS,
} from './sendBill.constants';

function* getDataTable(action) {
  const { unitGroupId, filterObject, page } = action.payload;

  const params = {
    page,
    limit: PAGINATION_LIMIT,
    ...filterObject,
  };

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(getListSendBillsService, { params, headers }, SEND_BILL_GETS);
  yield put(result);
}

export function* getSumInvoiceBill(action) {
  const { unitGroupId, filterObject } = action.payload;

  const params = {
    ...filterObject,
  };

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(
    sumTotalPaymentSendBillService,
    {
      params,
      headers,
    },
    SEND_BILL_GET_SUM
  );
  yield put(result);
}

function* previewInvoice(action) {
  const {
    unitGroupId,
    unitId,
    bills,
    createdAt,
    type,
    duePayment,
    filterObject,
    invoiceType,
    feeNoticePeriod,
    remindDebtTimes,
  } = action.payload;
  let body = {
    unitGroupId,
    unitId,
    bills,
    createdAt,
    incurredCreatedFor: moment(feeNoticePeriod).startOf('month').format('YYYYMM'),
  };
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  let result;
  if (invoiceType === INVOICE_REMIND_DEBT) {
    body = {
      ...body,
      type,
      remindDebtTimes,
      duePayment,
      filterObject,
    };
    result = yield call(previewInvoiceDebtService, { body, headers }, SEND_BILL_GET_DETAIL_PREVIEW);
  } else {
    result = yield call(previewInvoiceService, { body, headers }, SEND_BILL_GET_DETAIL_PREVIEW);
  }
  yield put(result);
  yield put({
    type: SEND_BILL_GET_DETAIL_PREVIEW.REFRESH,
  });
}

function* getDataPageAndNormalize(page, filterObject, headers) {
  // get data
  const resultPage = yield call(getListSendBillsService, {
    params: {
      page,
      limit: PAGINATION_LIMIT,
      ...filterObject,
    },
    headers,
  });

  // normalize data
  const targetsPage = resultPage.payload.result.map((item) => ({
    unitId: item.id,
    bills: item.bills.map((bill) => bill.id),
  }));
  return [resultPage, targetsPage];
}

function* sendEmailPage({ page, filterObject, invoiceType, headers, typeView, duePayment, incurredCreatedFor, remindDebtTimes }) {
  const [resultPage, targets] = yield call(getDataPageAndNormalize, page, filterObject, headers);
  let body = {
    targets,
    incurredCreatedFor,
  };
  // send email
  let resultSendEmailPage;
  if (invoiceType === INVOICE_REMIND_DEBT) {
    body = {
      ...body,
      type: typeView,
      remindDebtTimes,
      duePayment,
    };
    resultSendEmailPage = yield call(sendEmailInvoiceDebtService, { body, headers }, SEND_BILL_SEND_EMAIL);
  } else {
    resultSendEmailPage = yield call(sendEmailInvoiceService, { body, headers }, SEND_BILL_SEND_EMAIL);
  }
  resultSendEmailPage.payload.dataPage = {
    [page]: {
      data: resultPage.payload.result,
      code: resultSendEmailPage.payload.code,
      codeLanguage: resultSendEmailPage.payload.codeLanguage,
    },
  };
  yield put(resultSendEmailPage);
}

function* sendEmailInvoice(action) {
  const {
    isAll,
    filterObject,
    targets,
    unitGroupId,
    invoiceType,
    typeView,
    duePayment,
    totalPage,
    feeNoticePeriod,
    remindDebtTimes,
  } = action.payload;
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  if (isAll) {
    for (let page = 1; page <= totalPage; page += 1) {
      yield call(sendEmailPage, {
        page,
        filterObject,
        invoiceType,
        headers,
        typeView,
        duePayment,
        incurredCreatedFor: moment(feeNoticePeriod).startOf('month').format('YYYYMM'),
      });
    }
    yield put({
      type: SEND_BILL_SEND_EMAIL_DONE_PROGRESS,
      payload: {
        code: 200,
      },
    });
  } else {
    let result;
    let body = {
      targets,
      incurredCreatedFor: moment(feeNoticePeriod).startOf('month').format('YYYYMM'),
    };
    if (invoiceType === INVOICE_REMIND_DEBT) {
      body = {
        ...body,
        type: typeView,
        remindDebtTimes,
        duePayment,
      };
      result = yield call(sendEmailInvoiceDebtService, { body, headers });
    } else {
      result = yield call(sendEmailInvoiceService, { body, headers });
    }
    yield put({
      ...result,
      type: SEND_BILL_SEND_EMAIL_DONE_PROGRESS,
    });
  }
}

function* printInvoicePage({ page, filterObject, invoiceType, headers, typeView, duePayment, incurredCreatedFor, remindDebtTimes }) {
  const [resultPage, targets] = yield call(getDataPageAndNormalize, page, filterObject, headers);
  let body = {
    targets,
    incurredCreatedFor,
  };
  // print
  let resultPrintPage;
  if (invoiceType === INVOICE_REMIND_DEBT) {
    body = {
      ...body,
      type: typeView,
      remindDebtTimes,
      duePayment,
    };
    resultPrintPage = yield call(printInvoiceDebtService, { body, headers }, SEND_BILL_PRINT);
  } else {
    resultPrintPage = yield call(printInvoiceService, { body, headers }, SEND_BILL_PRINT);
  }
  resultPrintPage.payload.dataPage = {
    [page]: {
      data: resultPrintPage.payload.result,
      units: resultPage.payload.result,
      code: resultPrintPage.payload.code,
      codeLanguage: resultPrintPage.payload.codeLanguage,
    },
  };
  yield put(resultPrintPage);
}

function* printInvoice(action) {
  const {
    isAll,
    filterObject,
    targets,
    unitGroupId,
    invoiceType,
    typeView,
    duePayment,
    totalPage,
    feeNoticePeriod,
    remindDebtTimes,
  } = action.payload;
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  if (isAll) {
    for (let page = 1; page <= totalPage; page += 1) {
      yield call(printInvoicePage, {
        page,
        filterObject,
        invoiceType,
        headers,
        typeView,
        duePayment,
        incurredCreatedFor: moment(feeNoticePeriod).startOf('month').format('YYYYMM'),
      });
    }
    yield put({
      type: SEND_BILL_PRINT_DONE_PROGRESS,
      payload: {
        code: 200,
      },
    });
  } else {
    let result;
    let body = {
      targets,
      incurredCreatedFor: moment(feeNoticePeriod).startOf('month').format('YYYYMM'),
    };
    if (invoiceType === INVOICE_REMIND_DEBT) {
      body = {
        ...body,
        type: typeView,
        remindDebtTimes,
        duePayment,
      };
      result = yield call(printInvoiceDebtService, { body, headers }, SEND_BILL_PRINT);
    } else {
      result = yield call(printInvoiceService, { body, headers }, SEND_BILL_PRINT);
    }
    result.payload.dataPage = {
      1: {
        data: result.payload.result,
        units: targets,
        code: result.payload.code,
        codeLanguage: result.payload.codeLanguage,
      },
    };
    yield put(result);
    yield put({
      type: SEND_BILL_PRINT_DONE_PROGRESS,
      payload: {
        code: 200,
      },
    });
  }
}

function* deleteBills(action) {
  const { isAll, ids, unitGroupId, filterObject } = action.payload;
  const body = {
    isAll,
    array: ids,
    filterObject,
  };
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };
  const result = yield call(
    deleteBillService,
    {
      body,
      headers,
    },
    SEND_BILL_CANCEL_INVOICE
  );
  yield put(result);
  yield put({
    type: SEND_BILL_CANCEL_INVOICE.REFRESH,
  });
}

export function* getFloorsSendBillPage(action) {
  const { unitGroupId } = action.payload;
  const result = yield call(getFloorsService, unitGroupId, SEND_BILL_GET_FLOORS);

  yield put(result);
}

export default function* watch() {
  yield all([
    takeLatest(SEND_BILL_GETS.REQUEST, getDataTable),
    takeLatest(SEND_BILL_GET_SUM.REQUEST, getSumInvoiceBill),
    takeLatest(SEND_BILL_GET_DETAIL_PREVIEW.REQUEST, previewInvoice),
    takeLatest(SEND_BILL_SEND_EMAIL_START_PROGRESS, sendEmailInvoice),
    takeLatest(SEND_BILL_PRINT_START_PROGRESS, printInvoice),
    takeLatest(SEND_BILL_CANCEL_INVOICE.REQUEST, deleteBills),
    takeLatest(SEND_BILL_GET_FLOORS.REQUEST, getFloorsSendBillPage),
  ]);
}
