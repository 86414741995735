import { initStateFetching } from './fetching';
import { BLOCK } from '../constants/actionType';
import fetching from './fetching';
import { combineReducers } from 'redux';
import { convertArrayToObject } from '../libs/convert';
import { map } from 'lodash';

export const initState = {
    blockGets: {
        ...initStateFetching
    },
    dataGets: false,
    byId: {},
    allIds: [],
};

const blockGets = fetching(BLOCK.GETS);

function dataGets(state: boolean = false, action: Object) {
    switch (action.type) {
    case BLOCK.GETS.SUCCESS:
        return true;
    default:
        return state;
    }
}

function addBlockEntry(state: Object, action: Object) {
    const { blocks } = action.payload;

    return {
        ...state,
        ...convertArrayToObject(blocks)
    };
}

function addBlockId(state: Array<number>, action: Object) {
    const { blocks } = action.payload;
    return [
        ...state,
        ...map(blocks, block => block.id)
    ];
}

function byId(state: Object = {}, action: Object) {
    switch (action.type) {
    case BLOCK.GETS.SUCCESS:
        return addBlockEntry(state, action);
    default:
        return state;
    }
}

function allIds(state: Array<number> = [], action: Object) {
    switch (action.type) {
    case BLOCK.GETS.SUCCESS:
        return addBlockId(state, action);
    default:
        return state;
    }
}

export const getAllBlocks = (state: Object) =>
    state.allIds.map(id => state.byId[id]);

export default combineReducers({
    blockGets,
    dataGets,
    byId,
    allIds,
});

