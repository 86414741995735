import { LABEL, LABEL_ADD, LABEL_DELETE_LIST, LABEL_GETS, LABEL_UPDATE, LABEL_GROUP_LIST, LABEL_TREE_LIST } from '../defined/API';
import { METHOD, X_UNIT_GROUP_ID } from '../constants';
import { callApi } from './api';
import { handleError, handleResponse } from '../libs/http';

export const getLabelsService = async ({ headers, params }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(LABEL_GETS, {}, METHOD.GET, {
            headers,
            params,
        });
        return handleResponse(response, type);
    } catch (e) {
        handleError(e, FAIL);
    }

};
export const addLabelService = async ({ label, unitGroupId }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(LABEL_ADD, label, METHOD.POST, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type, 'label');
    } catch (e) {
        handleError(e, FAIL);
    }

};
export const updateLabelService = async ({ label, unitGroupId }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(`${LABEL_UPDATE}/${label.id}`, label, METHOD.PUT, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type, 'label');
    } catch (e) {
        handleError(e, FAIL);
    }

};
export const deleteLabelService = async ({ params, unitGroupId }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(LABEL_DELETE_LIST, {
            ...params
        }, METHOD.POST, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type, 'label');
    } catch (e) {
        handleError(e, FAIL);
    }
};
export const getLabelGroupsService = async ({ headers, params }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(LABEL_GROUP_LIST, {}, METHOD.GET, {
            headers,
            params,
        });
        return handleResponse(response, type);
    } catch (e) {
        handleError(e, FAIL);
    }

};
export const getLabelTreeService = async ({ headers, params }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(LABEL_TREE_LIST, {}, METHOD.GET, {
            headers,
            params,
        });
        return handleResponse(response, type);
    } catch (e) {
        handleError(e, FAIL);
    }

};
