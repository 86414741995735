import {
  addAssetService,
  addMaintenanceService,
  deleteAssetService,
  getBlocks,
  getDepartmentsService,
  getFloors,
  getFloorsService,
  searchApartment,
  updateAssetService,
  updateStatusAssetService,
  importAssetService,
  getAllStaffService,
  getAssetHistoryService,
  exportAssetService,
} from '../../services';
import { ASSET } from '../../constants/actionType';
import { all, call, put } from 'redux-saga/effects';
import { getAssetService } from '../../services';
import { getPartnerService } from '../../services';
import { X_UNIT_GROUP_ID } from '../../constants';

export function* initTabAsset(action) {
  try {
    let payload = {
      code: 200,
      departments: undefined,
    };
    const error = [];

    const { buildingId } = action.payload;

    const headers = {
      [X_UNIT_GROUP_ID]: buildingId,
    };

    const [resPartner, resFloor, resDepartment, resEmployee] = yield all([
      call(getPartnerService, { unitGroupId: buildingId, params: { limit: 20 } }, ASSET.PARTNER.GETS),
      call(getFloorsService, buildingId, ASSET.FLOOR.GETS),
      call(getDepartmentsService, { headers }, ASSET.DEPARTMENT.GETS),
      call(getAllStaffService, { unitGroupId: buildingId }, ASSET.EMPLOYEE.GETS),
    ]);

    resFloor.payload = {
      ...resFloor.payload,
      result: resFloor.payload.floors,
    };

    yield all([put(resPartner), put(resFloor), put(resDepartment), put(resEmployee)]);

    yield put({
      type: ASSET.INIT.SUCCESS,
      payload: {
        ...payload,
        error: error.join(', '),
      },
    });
  } catch (e) {
    yield put({
      type: ASSET.INIT.FAIL,
      payload: {
        error: e,
      },
    });
  }
}

export function* getAssets(action) {
  const { buildingId, filters } = action.payload;
  const result = yield call(
    getAssetService,
    {
      params: { ...filters },
      unitGroupId: buildingId,
    },
    ASSET.GETS
  );
  yield put({
    ...result,
  });
}

export function* addAsset(action) {
  const { asset, unitGroupId } = action.payload;

  const result = yield call(addAssetService, { asset, unitGroupId }, ASSET.ADD);

  yield put(result);
  yield put({
    type: ASSET.ADD.REFRESH,
  });
}

export function* updateAsset(action) {
  const { id, asset, unitGroupId } = action.payload;

  const result = yield call(
    updateAssetService,
    {
      id,
      asset,
      unitGroupId,
    },
    ASSET.UPDATE
  );

  yield put(result);

  yield put({
    type: ASSET.UPDATE.REFRESH,
  });
}

export function* importAsset(action) {
  const { buildingId, file, filterObject } = action.payload;
  const result = yield call(importAssetService, buildingId, file, ASSET.IMPORT);

  const resultAssetGets = yield call(
    getAssetService,
    {
      unitGroupId: buildingId,
      params: { ...filterObject },
    },
    ASSET.GETS
  );
  yield all([put(result), put(resultAssetGets)]);
  yield put({
    type: ASSET.IMPORT.REFRESH,
  });
}

export function* changeStatus(action) {
  const params = action.payload;

  const result = yield call(updateStatusAssetService, params, ASSET.CHANGE_STATUS);

  yield put(result);
  yield put({ type: ASSET.CHANGE_STATUS.REFRESH });
}

export function* deleteAsset(action) {
  const { assetId, unitGroupId } = action.payload;
  let result = yield call(deleteAssetService, { assetId, unitGroupId }, ASSET.DELETE);
  result.payload.id = assetId;
  yield put({
    ...result,
  });
  yield put({
    type: ASSET.DELETE.REFRESH,
  });
}

export function* addMaintenance(action) {
  const { startDate, endDate, content, assetId, buildingId } = action.payload.maintenance;

  const maintenance = {
    startDate,
    content,
    // "status": "string",
    assetId,
    endDate,
    // "repeatType": "00NRE",
    // "creatorId": 0,
    unitGroupId: buildingId,
  };

  const result = yield call(addMaintenanceService, maintenance, ASSET.MAINTENANCE.ADD);
  yield put({
    ...result,
  });
}

export function* getMaintenanceUnit(action) {
  const { buildingId, name } = action.payload;
  const result = yield call(
    getPartnerService,
    {
      params: { name, limit: 20 },
      unitGroupId: buildingId,
    },
    ASSET.PARTNER.GETS
  );
  yield put({
    ...result,
  });
}

export function* getAssetHistory(action) {
  const { buildingId, filters } = action.payload;

  const result = yield call(getAssetHistoryService, buildingId, filters, ASSET.HISTORY_GETS);

  yield put(result);
  yield put({ type: ASSET.HISTORY_GETS.REFRESH });
}

export function* exportAsset(action) {
  const { buildingId, filters } = action.payload;
  const result = yield call(
    exportAssetService,
    {
      params: { ...filters, page: undefined, limit: undefined },
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      },
    },
    ASSET.EXPORT
  );
  yield put({
    ...result,
  });
  yield put({
    type: ASSET.EXPORT.REFRESH,
  });
}
