import { put, call, all, takeLatest } from 'redux-saga/effects';
import { CONFIRM_CHANGE_STATUS_AMENITIES_ORDER } from './confirm-change-status-amenities-order.types';
import { approveAmenitiesOrdersService, rejectAmenitiesOrdersService } from 'services/amenities';
import { X_UNIT_GROUP_ID } from 'constants/';

function* approveAmenitiesOrders(action) {
  try {
    const result = yield call(
      approveAmenitiesOrdersService,
      {
        headers: {
          [X_UNIT_GROUP_ID]: action.payload.amenitiesOrder.unitGroupId,
        },
        body: {
          bookingIds: [action.payload.amenitiesOrder.id],
        },
      },
      CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.APPROVES
    );
    yield put({
      ...result,
    });
  } catch (e) {
    yield put({
      type: CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.APPROVES.FAIL,
      payload: {
        code: 0,
        error: e,
      },
    });
  }
}

function* rejectAmenitiesOrders(action) {
  try {
    const result = yield call(
      rejectAmenitiesOrdersService,
      {
        headers: {
          [X_UNIT_GROUP_ID]: action.payload.amenitiesOrder.unitGroupId,
        },
        body: {
          bookingIds: [action.payload.amenitiesOrder.id],
          rejectReason: action.payload.reason,
        },
      },
      CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.REJECTS
    );
    yield put({
      ...result,
    });
  } catch (e) {
    yield put({
      type: CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.REJECTS.FAIL,
      payload: {
        code: 0,
        error: e,
      },
    });
  }
}

export default function* watch() {
  yield all([
    takeLatest(CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.APPROVES.REQUEST, approveAmenitiesOrders),
    takeLatest(CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.REJECTS.REQUEST, rejectAmenitiesOrders),
  ]);
}
