import {
    REPORT,
    X_UNIT_GROUP_ID,
} from '../../constants';
import { call, put } from 'redux-saga/effects';
import { exportInvestorService, exportOperatingService, getOperatingService } from '../../services/report';

export function* getDataOperatingReport(action) {
    const {
        unitGroupId,
        filterObject,
    } = action.payload;

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const params = {
        ...filterObject,
    };
    const result = yield call(getOperatingService, { params, headers }, REPORT.OPERATING.GETS);
    yield put(result);
}

export function* exportOperating(action) {
    const {
        unitGroupId,
        filterObject,
    } = action.payload;

    const params = {
        ...filterObject,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: unitGroupId,
    };

    const result = yield call(exportOperatingService, { params, headers }, REPORT.OPERATING.EXPORT);
    yield put(result);

    yield put({
        type: REPORT.OPERATING.EXPORT.REFRESH,
    });
}
