import { createAction } from 'shared/utils';

export const AUTHORIZATION_DEPARTMENTS_GETS = createAction('AUTHORIZATION_DEPARTMENTS_GETS');
export const AUTHORIZATION_DEPARTMENT_ADD = createAction('AUTHORIZATION_DEPARTMENT_ADD');
export const AUTHORIZATION_DEPARTMENT_UPDATE = createAction('AUTHORIZATION_DEPARTMENT_UPDATE');
export const AUTHORIZATION_DEPARTMENT_DELETE = createAction('AUTHORIZATION_DEPARTMENT_DELETE');

export const AUTHORIZATION_POSITION_ADD = createAction('AUTHORIZATION_POSITION_ADD');
export const AUTHORIZATION_POSITION_UPDATE = createAction('AUTHORIZATION_POSITION_UPDATE');
export const AUTHORIZATION_POSITION_DELETE = createAction('AUTHORIZATION_POSITION_DELETE');

export const AUTHORIZATION_GROUP_PERMISSION_GETS = createAction('AUTHORIZATION_GROUP_PERMISSION_GETS');
