import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { each, map, isEmpty } from 'lodash';
import Loading from 'components/Loading';
import { language } from 'defined/Language';
import Icon from 'components/Icon';
import { base64MimeType } from '../../libs/function';
import { downloadFileFromUrl } from '../../libs/function'

export const fileTypes = {
    image: {
        '.bmp': 'image/bmp',
        '.gif': 'image/gif',
        '.ico': 'image/vnd.microsoft.icon',
        '.jpeg': 'image/jpeg',
        '.jpg': 'image/jpeg',
        '.png': 'image/png',
        '.svg': 'image/svg+xml',
        '.tif': 'image/tiff',
        '.tiff': 'image/tiff',
        '.webp': 'image/webp'
    },
    document: {
        '.doc': 'application/msword',
        '.dot': 'application/msword',
        '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        '.dotx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        '.docm': 'application/vnd.ms-word.document.macroEnabled.12',
        '.dotm': 'application/vnd.ms-word.template.macroEnabled.12',
        '.xls': 'application/vnd.ms-excel',
        '.xlt': 'application/vnd.ms-excel',
        '.xla': 'application/vnd.ms-excel',
        '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        '.xltx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        '.xlsm': 'application/vnd.ms-excel.sheet.macroEnabled.12',
        '.xltm': 'application/vnd.ms-excel.template.macroEnabled.12',
        '.xlam': 'application/vnd.ms-excel.addin.macroEnabled.12',
        '.xlsb': 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
        '.ppt': 'application/vnd.ms-powerpoint',
        '.pot': 'application/vnd.ms-powerpoint',
        '.pps': 'application/vnd.ms-powerpoint',
        '.ppa': 'application/vnd.ms-powerpoint',
        '.pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        '.potx': 'application/vnd.openxmlformats-officedocument.presentationml.template',
        '.ppsx': 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        '.ppam': 'application/vnd.ms-powerpoint.addin.macroEnabled.12',
        '.pptm': 'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
        '.potm': 'application/vnd.ms-powerpoint.template.macroEnabled.12',
        '.ppsm': 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
        '.mdb': 'application/vnd.ms-access',
        '.csv': 'text/csv',
        '.pdf': 'application/pdf',
    },
    audio: {
        '.aac': 'audio/aac',
        '.mp3': 'audio/mpeg',
        '.mpeg': 'audio/mpeg',
        '.oga': 'audio/ogg',
        '.wav': 'audio/wav',
        '.weba': 'audio/webm',
        '.3gp': 'audio/3gpp',
        '.3g2': 'audio/3gpp2'
    },
    video: {
        '.mov': 'video/quicktime',
        '.mp4': 'video/mp4',
    }
};

function Upload(props) {
    let inputFile;
    const [isShowEmptyPage, setIsShowEmptyPage] = useState(false);
    const {
        files,
        type,
        grid,
        list,
        listHorizontal,
        multi,
        drag,
        onFileChange,
        onRemoveFile,
        preview,
        description,
        className,
        style,
        label,
        disabled
    } = props;

    function getInputFile() {
        let accept = undefined;
        switch (type) {
            case 'image':
            case 'avatar':
                accept = 'image/*';
                break;
            case 'video':
                accept = 'video/*';
                break;
            case 'audio':
                accept = 'audio/*';
                break;
            case 'file':
                accept = Object.keys(fileTypes['document']).map(item => item + ', ');
                break;
            case 'file-import':
                accept = '.xls, .xlsx';
                break;
            case 'all':
                accept = `${Object.keys(fileTypes['document']).map(item => item + ', ')}, image/*, ${Object.keys(fileTypes['video']).map(item => item + ', ')}`;
                break;
            default:
                break;
        }
        return <input
            ref={ref => (inputFile = ref)}
            type="file"
            className={'visibility--hidden'}
            onChange={e => onFileChangeHandle(e.target.files, onFileChange)}
            onClick={onClickBtnUpload}
            multiple={multi ? true : undefined}
            accept={accept}
            disabled={disabled}
        />;
    }

    function getPreview(type: string, file: any) {
        return <React.Fragment>
            {
                !description &&
                <div className="position--relative">
                    {
                        !file.error ?
                            (
                                file && file.type && (file.type.includes('image') || file.type.includes('avatar')) ?
                                    (
                                        <img src={file.src} className="upload__item__file" />
                                    ) :
                                    (
                                        (type === 'file' || type === 'file-import' || type === 'all') ?
                                            (
                                                <div className="upload__item__file">
                                                    <h1>
                                                        {(file && file.name && file.name.substring(file.name.lastIndexOf('.'), file.name.length)) || ''}
                                                    </h1>
                                                </div>
                                            ) :
                                            (
                                                null
                                            )
                                    )
                            ) :
                            (
                                null
                            )
                    }
                    {
                        file.isFetching && (
                            <Loading
                                className={'upload__item__loading'}
                                isLoading={file.isFetching}
                            />
                        )
                    }
                    {
                        file.error && (
                            <div className="upload__item__error upload__item__file">
                                <Icon width={'20px'} height={'20px'} name={'error'} color="#ffffff" />
                            </div>
                        )
                    }
                    {
                        file.error ?
                            (
                                <div className="upload__item__action">
                                    <Icon
                                        width={'20px'}
                                        height={'20px'}
                                        name={'refresh'}
                                        onClick={e => onRemoveFile(file)}
                                        className={'pointer'}
                                        color="#3487cb"
                                    />
                                </div>
                            ) :
                            (
                                file.uploaded ?
                                    (
                                        <div className="upload__item__action" style={{ justifyContent: "space-evenly" }}>
                                            <Icon
                                                width={'20px'}
                                                height={'20px'}
                                                name={'export'}
                                                color="#3487cb"
                                                onClick={e => downloadFileFromUrl(file.url, document, file.name)}
                                                className={'pointer'}
                                            />
                                            {
                                                !disabled && (
                                                    <Icon
                                                        width={'20px'}
                                                        height={'20px'}
                                                        name={'trash'}
                                                        color="#3487cb"
                                                        onClick={e => onRemoveFile(file)}
                                                        className={'pointer'}
                                                    />
                                                )
                                            }
                                        </div>
                                    ) :
                                    (
                                        !disabled && (
                                            <div className="upload__item__action">
                                                <Icon
                                                    width={'20px'}
                                                    height={'20px'}
                                                    name={'trash'}
                                                    color="#3487cb"
                                                    onClick={e => onRemoveFile(file)}
                                                    className={'pointer'}
                                                />
                                            </div>
                                        )
                                    )

                            )

                    }
                </div>
            }
            {
                type !== 'avatar' &&
                <div className="upload__item__description display--flex display--flex--horizontal" style={{ alignItems: 'baseline', justifyContent: 'space-between' }}>
                    <div>
                        <Icon width={'14px'} height={'14px'} name={'file'} />
                        <span style={{ marginLeft: '3px', wordBreak: 'break-all' }}>{file.name}</span>
                    </div>
                    {
                        !preview ? file.isFetching ?
                            <div className="spinner" style={{ width: '0.6875rem', height: '0.6875rem' }} /> :
                            <Icon
                                width={'11px'}
                                height={'11px'}
                                className={'pointer'}
                                style={{ marginLeft: '5px' }}
                                name={'trash'}
                                color="#3487cb"
                                onClick={e => onRemoveFile(file)}
                            /> :
                            null
                    }
                </div>
            }
        </React.Fragment>;
    }

    function getDragDrop() {
        return <div
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={e => {
                e.preventDefault();
                e.stopPropagation();
                onFileChangeHandle(e.dataTransfer.files, onFileChange);
                onDragLeave(e);
            }}
            className={`upload__empty ${isShowEmptyPageTmp ? '' : 'visibility--hidden'}`}
            onClick={onClickBtnUpload}
        >
            <Icon style={{
                pointerEvents: 'none'
            }} width={'50px'} height={'40px'} name={'upload'} />
            <h4
                style={{
                    marginTop: '10px',
                    color: '#7c7c7c',
                    pointerEvents: 'none'
                }}
            >
                {language['description']['upload']}
            </h4>
        </div>;
    }

    function onClickBtnUpload(e: any) {
        inputFile && (inputFile.value = '');
        inputFile && inputFile.click();
    }

    function readFile(file: any): Promise<void> {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        return new Promise((resolve, reject) => {
            reader.onload = function (e) {
                resolve(e.target.result);
            };
        });
    }

    async function onFileChangeHandle(files: Array<any>, handleFileChange: Function) {
        if (files) {
            const result = await Promise.all(
                map(files, async (file, index) => {
                    const id = '_' + Math.random().toString(36).substr(2, 9);
                    try {
                        const fileBath = await readFile(file);
                        const fileType = base64MimeType(fileBath);
                        let error = false;
                        switch (type) {
                            case 'image':
                            case 'avatar':
                                error = !Object.values(fileTypes['image']).includes(fileType);
                                break;
                            case 'video':
                                error = !Object.values(fileTypes['video']).includes(fileType);
                                break;
                            case 'audio':
                                error = !Object.values(fileTypes['audio']).includes(fileType);
                                break;
                            case 'file':
                                error = !Object.values(fileTypes['document']).includes(fileType);
                                break;
                            case 'file-import':
                                error = ![fileTypes['document']['.xls'], fileTypes['document']['.xlsx']].includes(fileType);
                                break;
                            case 'all':
                                error = !Object.values(fileTypes['document']).includes(fileType) && !Object.values(fileTypes['image']).includes(fileType) && !Object.values(fileTypes['video']).includes(fileType);
                                break;
                            default:
                                break;
                        }
                        return {
                            src: fileBath,
                            url: fileBath,
                            type: fileType,
                            name: file.name,
                            id,
                            file: file,
                            error: error
                        };
                    } catch (err) {
                        return {
                            src: '',
                            url: '',
                            name: file.name,
                            id,
                            file: file,
                            error: err
                        };
                    }
                })
            );
            handleFileChange && handleFileChange(result);
        }
    }

    function onDragEnter(e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (!isShowEmptyPage) {
            setIsShowEmptyPage(true);
        }
        return true;
    }

    function onDragOver(e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (!isShowEmptyPage) {
            setIsShowEmptyPage(true);
        }
        return true;
    }

    function onDragLeave(e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (isShowEmptyPage) {
            setIsShowEmptyPage(false);
        }
        return true;
    }

    let isShowEmptyPageTmp = isShowEmptyPage
        ? true
        : files.length === 0;

    return (
        <div
            onDragEnter={onDragEnter}
            className={`upload ${className}`}
            style={style}
        >
            {getInputFile()}
            <div
                className={`${grid ? 'upload--grid grid grid--3-col' : ''} ${
                    list ? 'upload--list grid grid--1-col' : ''} ${
                    listHorizontal ? 'upload--list grid grid--12-row' : ''
                    }`}
            >
                {preview && map(files, (file, index) => {
                    return (
                        <div key={index} className="upload__item">
                            {getPreview(type, file)}
                        </div>
                    );
                })}
                {
                    !drag && files.length === 0 && type === 'avatar' &&
                    <div
                        className={'upload__btn'}
                        onClick={onClickBtnUpload}
                    >
                        <Icon name="camera" width='25px' height='25px' />
                    </div>
                }
                {
                    type !== 'avatar' && ((files.length === 0 && !drag) || (files.length !== 0 && multi)) &&
                    <React.Fragment>
                        {
                            preview ?
                                <div className="upload__btn" onClick={onClickBtnUpload}>
                                    <Icon name="plus-circle" width='25px' height='25px' />
                                </div> :
                                <div>
                                    <div style={{ marginTop: '10px' }} onClick={onClickBtnUpload}>
                                        {label}
                                    </div>
                                    {
                                        !isEmpty(files) &&
                                        <div style={{ height: '100px', overflowY: 'auto', overflowX: 'hidden' }}>
                                            {
                                                description && map(files, (file, index) => (
                                                    <div key={index} className="upload__item">
                                                        {getPreview(type, file)}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }
                                </div>
                        }
                    </React.Fragment>
                }
                {
                    drag && getDragDrop()
                }
            </div>

        </div >
    );
}

Upload.propTypes = {
    files: PropTypes.array,
    grid: PropTypes.bool,
    list: PropTypes.bool,
    listHorizontal: PropTypes.bool,
    multi: PropTypes.bool,
    preview: PropTypes.bool,
    description: PropTypes.bool,
    drag: PropTypes.bool,
    // image, file, avatar
    type: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.any,
    style: PropTypes.object,
    onFileChange: PropTypes.func,
    onRemoveFile: PropTypes.func
};

Upload.defaultProps = {
    files: [],
    grid: false,
    list: false,
    listHorizontal: false,
    preview: false,
    description: false,
    drag: false,
    multi: false,
    type: 'file',
    className: '',
    style: {},
    label: (
        <span>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1rem"
                height="1rem"
                viewBox="0 0 12 12"
            >
                <defs>
                    <path
                        id="a"
                        d="M5.19 2.732H6V8.98c0 .55-.133 1.055-.399 1.517a2.98 2.98 0 0 1-1.085 1.098c-.457.27-.956.405-1.496.405s-1.042-.135-1.504-.405a3.067 3.067 0 0 1-1.104-1.098A2.914 2.914 0 0 1 0 8.98V2.196c0-.392.1-.756.3-1.091C.502.769.77.5 1.106.3 1.44.1 1.804 0 2.196 0s.754.098 1.085.294c.331.196.595.464.79.804.197.34.295.706.295 1.098v5.712a1.374 1.374 0 0 1-.673 1.176c-.205.123-.43.184-.673.184a1.36 1.36 0 0 1-.687-.183 1.394 1.394 0 0 1-.51-.497 1.29 1.29 0 0 1-.189-.68V2.732h.824v5.176a.5.5 0 0 0 .163.386c.109.1.242.15.399.15.156 0 .285-.05.385-.15.1-.1.15-.229.15-.386V2.196a1.36 1.36 0 0 0-.182-.686A1.394 1.394 0 0 0 2.876 1a1.29 1.29 0 0 0-.68-.19c-.244 0-.47.064-.68.19-.209.126-.377.296-.503.51-.126.213-.19.442-.19.686V8.98c0 .393.1.759.301 1.098.2.34.469.61.804.81.336.201.7.302 1.092.302.392 0 .753-.1 1.085-.301.33-.2.594-.47.79-.81A2.16 2.16 0 0 0 5.19 8.98V2.732z"
                    />
                </defs>
                <g fill="none" fillRule="evenodd" transform="translate(3)">
                    <mask id="b" fill="#fff">
                        <use xlinkHref="#a" />
                    </mask>
                    <use fill="#3487CB" xlinkHref="#a" />
                    <g fill="#4A4A4A" mask="url(#b)">
                        <rect width="12" height="12" rx="4" transform="translate(-3)" />
                    </g>
                </g>
            </svg>
            <span
                className="pointer text--link"
                style={{
                    marginLeft: '8px',
                }}
            >
                {language['label']['upload_file']}
            </span>
        </span>
    ),
};

export default Upload;
