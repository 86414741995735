import { call, put, all, takeLatest } from 'redux-saga/effects';
import { X_UNIT_GROUP_ID } from 'shared/constants';
import { exportOperatingService, getOperatingService } from 'services/report';
import { OPERATING_REVENUE_REPORT_GETS, OPERATING_REVENUE_REPORT_EXPORT } from './operatingRevenueReport.types';

export function* getDataOperatingReport(action) {
  const { unitGroupId, filterObject } = action.payload;

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const params = {
    ...filterObject,
  };
  const result = yield call(getOperatingService, { params, headers }, OPERATING_REVENUE_REPORT_GETS);
  yield put(result);
}

export function* exportOperatingReport(action) {
  const { unitGroupId, filterObject } = action.payload;

  const params = {
    ...filterObject,
  };

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const result = yield call(exportOperatingService, { params, headers }, OPERATING_REVENUE_REPORT_EXPORT);
  yield put(result);

  yield put({
    type: OPERATING_REVENUE_REPORT_EXPORT.REFRESH,
  });
}

export default function* watch() {
  yield all([
    takeLatest(OPERATING_REVENUE_REPORT_GETS.REQUEST, getDataOperatingReport),
    takeLatest(OPERATING_REVENUE_REPORT_EXPORT.REQUEST, exportOperatingReport),
  ]);
}
