import { handleError, handleResponse } from '../libs/http';
import {
    CONTRACT_TEMPLATE_ADD,
    CONTRACT_TEMPLATE_DELETE,
    CONTRACT_TEMPLATE_GET,
    CONTRACT_TEMPLATE_GET_BY_FORMULA_ID, CONTRACT_TEMPLATE_GET_BY_PROMOTION_ID,
    CONTRACT_TEMPLATE_GET_BY_SURCHARGE_ID,
    CONTRACT_TEMPLATE_TARGETS_GET,
    CONTRACT_TEMPLATE_UPDATE,
} from '../defined/API';
import { callApi } from './api';
import { METHOD, X_UNIT_GROUP_ID } from '../constants/http';

export const addContractTemplateService = async (contractTemplate: Object, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(CONTRACT_TEMPLATE_ADD, contractTemplate, METHOD.POST, {
            headers: {
                [X_UNIT_GROUP_ID]: contractTemplate.unitGroupId,
            },
        });
        return handleResponse(response, type, 'contractTemplate');
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const updateContractTemplateService = async (contractTemplate: Object, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(`${CONTRACT_TEMPLATE_UPDATE}/${contractTemplate.id}`, contractTemplate, METHOD.PUT, {
            headers: {
                [X_UNIT_GROUP_ID]: contractTemplate.unitGroupId,
            },
        });
        return handleResponse(response, type, 'contractTemplate');
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getContractTemplateService = async ({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(CONTRACT_TEMPLATE_GET, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type, 'result', true);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getContractTemplateByFormulaService = async ({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(CONTRACT_TEMPLATE_GET_BY_FORMULA_ID, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getContractTemplateBySurchargeService = async ({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(CONTRACT_TEMPLATE_GET_BY_SURCHARGE_ID, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getContractTemplateByPromotionService = async ({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(CONTRACT_TEMPLATE_GET_BY_PROMOTION_ID, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};


export const deleteContractTemplateService = async (contractTemplateId = 0, unitGroupId = 0, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(`${CONTRACT_TEMPLATE_DELETE}/${contractTemplateId}`, {}, METHOD.DELETE, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const getContractTemplateTargetService = async ({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(CONTRACT_TEMPLATE_TARGETS_GET, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type, 'targets', params.page);
    } catch (e) {
        return handleError(e, FAIL);
    }
};
