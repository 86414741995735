import * as React from 'react';
import Error from './pages/Error';
import * as Sentry from '@sentry/browser';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        console.log(error, info);
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
        // Sentry.withScope(scope => {
        //     scope.setExtras(info);
        //     const eventId = Sentry.captureException(error);
        //     this.setState({eventId});
        // });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <Error />;
        }
        return this.props.children;
    }
}
