import { DOCUMENT } from '../constants';
import { initStateFetching } from './fetching';
import { initStatePagination } from './paginate';
import fetching from './fetching';
import paginate from './paginate';
import { combineReducers } from 'redux';

export const initState = {
    documentInit: {
        ...initStateFetching,
    },
    documentAdd: {
        ...initStateFetching,
    },
    documentUpdate: {
        ...initStateFetching,
    },
    documentDelete: {
        ...initStateFetching,
    },
    documentGets: {
        ...initStatePagination,
    },
    folderInMoveToBox: [],
    departments: {
        ...initStatePagination,
    },
};

const documentInit = fetching(DOCUMENT.INIT);
const documentAdd = fetching(DOCUMENT.ADD);
const documentUpdate = fetching(DOCUMENT.UPDATE);
const documentDelete = fetching(DOCUMENT.DELETE);
const documentGets = paginate(DOCUMENT.GETS);
const departments = paginate(DOCUMENT.DEPARTMENT.GETS);

const handleData = (reducer) => (state, action) => {
    switch (action.type) {
    case DOCUMENT.ADD.SUCCESS: {
        return {
            ...state,
            data: [
                ...state.data,
                action.payload.document,
            ],
        };
    }
    case DOCUMENT.UPDATE.SUCCESS: {
        return {
            ...state,
            data: [
                ...state.data.filter(item => item.id !== action.payload.document.id),
                action.payload.document,
            ]
        };
    }
    case DOCUMENT.DELETE.SUCCESS: {
        return {
            ...state,
            data: state.data.filter(item => item.id !== action.payload.documentId)
        };
    }
    default: {
        return reducer(state, action);
    }
    }
};

function folderInMoveToBox(state: Array<Object> = [], action: Object) {
    switch (action.type) {
    case DOCUMENT.GETS_IN_MOVE_TO_BOX.SUCCESS:
        return [
            ...action.payload.result,
        ];
    }
    return state;
}

export default combineReducers({
    documentInit,
    documentAdd,
    documentUpdate,
    documentDelete,
    documentGets: handleData(documentGets),
    departments,
    folderInMoveToBox,
});