import { each } from 'lodash';
import { handleError, handleResponse } from '../libs/http';
import { callApi, callApiWithFile, downloadFile } from './api';
import {
    CONTRACT_TEMPLATE,
    CONTRACT_ADDS,
    SERVICE_GETS,
    CONTRACT_GET_LIST,
    CONTRACT_TEMPLATE_GET,
    CONTRACT,
    CONTRACT_IMPORT,
    RESIDENT_EXPORT,
    CONTRACT_EXPORT,
    CONTRACT_UPDATE, CONTRACT_DELETE, CONTRACT_DELETE_LIST, CONTRACT_FILE_TEMPLATE, CONTRACT_GET_SUM_TOTAL,
} from '../defined/API';
import { METHOD, X_UNIT_GROUP_ID } from '../constants';

export async function getServices(filter, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(SERVICE_GETS, {}, METHOD.GET, {
            params: {
                ...filter,
            },
            headers: {
                [X_UNIT_GROUP_ID]: filter.unitGroupId,
            },
        });
        return handleResponse(response, type, 'result');
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function getServiceContracts(filter, page, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(CONTRACT_GET_LIST, {}, METHOD.GET, {
            params: {
                ...filter,
                page,
                limit: 20,
            },
            headers: {
                [X_UNIT_GROUP_ID]: filter.unitGroupId,
            },
        });
        return handleResponse(response, type, 'result', page);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function getSumTotalServiceContracts(filter, page, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(CONTRACT_GET_SUM_TOTAL, {}, METHOD.GET, {
            params: {
                ...filter,
                page,
            },
            headers: {
                [X_UNIT_GROUP_ID]: filter.unitGroupId,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function getContractTemplateService1({ params, headers }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(`${CONTRACT_TEMPLATE_GET}`, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type, 'result', true);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function addContracts({ contracts, unitGroupId }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(CONTRACT_ADDS, contracts, METHOD.POST, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type, 'result');
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function deleteContractsService({ isAll, ids, serviceId, filterObject, unitGroupId }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(`${CONTRACT_DELETE_LIST}/${serviceId}`, {
            isAll,
            ids,
            filterObject,
        }, METHOD.POST, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId
            }
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function updateContractService({ contract, unitGroupId }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(`${CONTRACT_UPDATE}/${contract.id}`, contract, METHOD.PUT, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId
            }
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function activeContractService(contractId, unitGroupId, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(`${CONTRACT}/${contractId}/active`, {}, METHOD.PUT, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function activeMultiContractService(array = [], isAll = false, filterObject = {}, unitGroupId, contractTemplateName = '', type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(`${CONTRACT}/active-multi`, 
        {
            array,
            isAll,
            filterObject,
            contractTemplateName
        }, METHOD.PUT, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function inactiveContractService(contractId, unitGroupId, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(`${CONTRACT}/${contractId}/inactive`, {}, METHOD.PUT, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function inactiveMultiContractService(array = [], isAll = false, filterObject = {}, unitGroupId, contractTemplateName = '', type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApi(`${CONTRACT}/inactive-multi`, 
        {
            array,
            isAll,
            filterObject,
            contractTemplateName
        }, METHOD.PUT, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function importContractService({dataAttach, headers, file}, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApiWithFile(CONTRACT_IMPORT, file, METHOD.POST, 'file', {
            dataAttach,
            headers
        });
        return handleResponse(response, type, 'result', 1);
    } catch (e) {
        return handleError(e, FAIL);
    }
}

export async function exportContractService({headers, params},type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        let response = await downloadFile(CONTRACT_EXPORT, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type, 'result');
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}

export async function downloadFileTemplateContractService({headers, serviceId, serviceType},type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        let response = await downloadFile(CONTRACT_FILE_TEMPLATE(serviceType, serviceId), {}, METHOD.GET, {
            headers,
        });
        return handleResponse(response, type, 'result');
    }
    catch (err) {
        return handleError(err, FAIL);
    }
}