import { CONFIG } from '../../constants/actionType';
import { call, put } from 'redux-saga/effects';
import { X_UNIT_GROUP_ID } from '../../constants';
import { addLabelService, deleteLabelService, getLabelsService, updateLabelService, getLabelGroupsService, getLabelTreeService } from '../../services/label';

export function* getLabel(action) {
    const {
        buildingId,
    } = action.payload;
    const params = {};
    const headers = {
        [X_UNIT_GROUP_ID]: buildingId,
    };
    const result = yield call(getLabelsService, { params, headers }, CONFIG.LABEL.GETS);
    yield put(result);
}

export function* addLabel(action) {
    const {
        label,
        buildingId,
    } = action.payload;
    const result = yield call(addLabelService, {
        label,
        unitGroupId: buildingId,
    }, CONFIG.LABEL.ADD);
    yield put(result);
    yield put({
        type: CONFIG.LABEL.ADD.REFRESH,
    });
}

export function* updateLabel(action) {
    const {
        label,
        buildingId,
    } = action.payload;
    const result = yield call(updateLabelService, {
        label,
        unitGroupId: buildingId,
    }, CONFIG.LABEL.UPDATE);
    yield put(result);
    yield put({
        type: CONFIG.LABEL.UPDATE.REFRESH,
    });
}

export function* deleteLabel(action) {
    const {
        params,
        buildingId,
    } = action.payload;

    let result = yield call(deleteLabelService, {
        params,
        unitGroupId: buildingId,
    }, CONFIG.LABEL.DELETE);
    yield put(result);
    yield put({
        type: CONFIG.LABEL.DELETE.REFRESH,
    });
}

export function* getLabelGroup(action) {
    const {
        buildingId,
    } = action.payload;
    const params = {};
    const headers = {
        [X_UNIT_GROUP_ID]: buildingId,
    };
    const result = yield call(getLabelGroupsService, { params, headers }, CONFIG.LABEL.GROUP_GETS);
    yield put(result);
    yield put({
        type: CONFIG.LABEL.GROUP_GETS.REFRESH
    });
}

export function* getLabelTree(action) {
    const {
        buildingId,
        filterObject
    } = action.payload;
    const params = {
        ...filterObject
    };
    const headers = {
        [X_UNIT_GROUP_ID]: buildingId,
    };
    const result = yield call(getLabelTreeService, { params, headers }, CONFIG.LABEL.TREE_GETS);
    yield put(result);
}