import { combineReducers } from 'redux';
import paginate, { initStatePagination } from 'shared/utils/paginate';
import fetching, { initStateFetching } from 'shared/utils/fetching';
import { omit } from 'lodash';
import {
  REPORT_STORED_GET_DETAIL_TOTAL,
  REPORT_STORED_GET,
  REPORT_STORED_GET_DETAIL,
  REPORT_STORED_EXPORT,
  REPORT_STORED_SAVE,
  REPORT_STORED_GET_BY_ID,
} from './reportStored.types';

export const initState = {
  data: {
    ...initStatePagination,
  },
  dataDetail: {
    ...initStatePagination,
  },
  report: {
    ...initStatePagination,
  },
  dataTotal: {
    ...initStatePagination,
  },
  export: {},
  save: {
    ...initStateFetching,
  },
};

const exportData = (state = {}, action) => {
  switch (action.type) {
    case REPORT_STORED_EXPORT.REQUEST: {
      return {
        ...state,
        [action.payload.reportId]: {
          loading: true,
        },
      };
    }
    case REPORT_STORED_EXPORT.FAIL:
    case REPORT_STORED_EXPORT.SUCCESS: {
      return {
        ...state,
        [action.payload.reportId]: {
          loading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          data: action.payload.result,
          name: action.payload.reportName,
        },
      };
    }
    case REPORT_STORED_EXPORT.REFRESH: {
      return omit(state, [action.payload.reportId]);
    }
    default: {
      return state;
    }
  }
};

const handleReport = (reducer) => (state, action) => {
  switch (action.type) {
    case REPORT_STORED_GET_BY_ID.REFRESH: {
      return {
        ...initStatePagination,
      };
    }
    default: {
      return reducer(state, action);
    }
  }
};

export default combineReducers({
  data: paginate(REPORT_STORED_GET),
  report: handleReport(paginate(REPORT_STORED_GET_BY_ID)),
  dataDetail: paginate(REPORT_STORED_GET_DETAIL),
  dataTotal: paginate(REPORT_STORED_GET_DETAIL_TOTAL),
  export: exportData,
  save: fetching(REPORT_STORED_SAVE),
});
