import reducers, { initState } from './cashbook.reducers';
import watch from './cashbook.operations';

export * from './cashbook.util';

export default {
  reducers,
  initState,
  watch,
};
