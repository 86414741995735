import { STATISTIC } from '../constants/actionType';
import paginate, { initStatePagination } from './paginate';
import { combineReducers } from 'redux';
import { initStateFetching } from './fetching';
import fetching from './fetching';
import { DOCUMENT } from '../constants';

export const initState = {
    services: {
        ...initStatePagination,
    },
    funds: {
        ...initStatePagination,
    },
    tags: {
        ...initStatePagination,
    },
    contractTemplates: {
        ...initStatePagination,
    },
    cashBookInit: {
        ...initStateFetching,
    },
    cashBook: {
        ...initStatePagination,
    },
    cashBookExport: {
        ...initStatePagination,
    },
    serviceChargeGets: {
        ...initStatePagination,
    },
    serviceChargeExport: {
        ...initStatePagination,
    },
    apartmentUseService: {
        ...initStatePagination,
    },
    accountGets: {
        ...initStatePagination,
    },
    accountExport: {
        ...initStatePagination,
    },
    accountSumTotalPayment: {
        ...initStatePagination,
    },
    accountHistory: {
        ...initStatePagination,
    },
    debtInit: {
        ...initStateFetching,
    },
    debtGets: {
        ...initStatePagination,
    },
    debtExport: {
        ...initStatePagination,
    },
    debtSumTotalPayment: {
        ...initStatePagination,
    },
    reportServiceData: {
        ...initStatePagination,
    },
    reportServiceExport: {
        ...initStatePagination,
    },
    reportServiceSumPayment: {
        ...initStatePagination,
    },
    paymentOnlineGets: {
        ...initStateFetching,
    },
    paymentOnline: {
        ...initStatePagination,
    },
    paySlipAdd: {
        ...initStatePagination,
    }
};

function dataExport(state = '', action) {
    switch (action.type) {
    case STATISTIC.PAY_SLIP.EXPORT.SUCCESS: {
        return action.payload.data;
    }
    default:
        return state;
    }
}

const reducer = combineReducers({
    init: fetching(STATISTIC.INIT),
    paySlipData: paginate(STATISTIC.PAY_SLIP.GETS),
    paySlipAdd: paginate(STATISTIC.PAY_SLIP.ADD),
    paySlipUpdate: fetching(STATISTIC.PAY_SLIP.UPDATE),
    paySlipCancel: fetching(STATISTIC.PAY_SLIP.CANCEL),
    paySlipExport: fetching(STATISTIC.PAY_SLIP.EXPORT),
    paySlipPreview: paginate(STATISTIC.PAY_SLIP.GET_PREVIEW),
    paySlipSumTotalPayment: paginate(STATISTIC.PAY_SLIP.SUM_TOTAL_PAYMENT),
    dataExport: dataExport,
    services: paginate(STATISTIC.SERVICE.GETS),
    funds: paginate(STATISTIC.FUND.GETS),
    tags: paginate(STATISTIC.TAG.GETS),
    contractTemplates: paginate(STATISTIC.CONTRACT_TEMPLATE.GETS),
    cashBookInit: fetching(STATISTIC.CASH_BOOK.INIT),
    cashBook: paginate(STATISTIC.CASH_BOOK.GETS),
    cashBookExport: paginate(STATISTIC.CASH_BOOK.EXPORT),
    serviceChargeGets: paginate(STATISTIC.SERVICE_CHARGE.GETS),
    serviceChargeExport: paginate(STATISTIC.SERVICE_CHARGE.EXPORT),
    apartmentUseService: paginate(STATISTIC.SERVICE_CHARGE.GETS_APARTMENT_USE_SERVICE),
    accountGets: paginate(STATISTIC.ACCOUNT.GETS),
    accountExport: paginate(STATISTIC.ACCOUNT.EXPORT),
    accountSumTotalPayment: paginate(STATISTIC.ACCOUNT.SUM_TOTAL_PAYMENT),
    accountHistory: paginate(STATISTIC.ACCOUNT.HISTORY),
    debtInit: fetching(STATISTIC.DEBT.INIT),
    debtGets: paginate(STATISTIC.DEBT.GETS),
    debtExport: paginate(STATISTIC.DEBT.EXPORT),
    debtSumTotalPayment: paginate(STATISTIC.DEBT.SUM_TOTAL_PAYMENT),
    reportServiceData: paginate(STATISTIC.REPORT_SERVICE.GETS),
    reportServiceExport: paginate(STATISTIC.REPORT_SERVICE.EXPORT),
    reportServiceSumPayment: paginate(STATISTIC.REPORT_SERVICE.SUM_PAYMENT),
    paymentOnlineGets: fetching(STATISTIC.PAYMENT_ONLINE.GETS),
    paymentOnline: paginate(STATISTIC.PAYMENT_ONLINE.GETS)
});

export default reducer;
