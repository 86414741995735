import { callApi, callApiWithFile, downloadFile } from './api';
import {
  REQUEST_GETS,
  REQUEST_ADD,
  REQUEST_UPDATE,
  REQUEST_DUPLICATE,
  REQUEST_EXPORT_DATA,
  REQUEST_CANCEL,
  REQUEST_GET,
  REQUEST_EMPLOYEES_GETS,
  REQUEST_TOTAL_TABS_GETS,
  REQUEST_APPROVE,
  REQUEST_DENY,
  REQUEST_TASK_GETS,
  REQUEST_TASK_ADD,
  REQUEST_TASK_UPDATE,
  REQUEST_TASK_CHANGE_PROGRESS,
  REQUEST_ACTIVITY_LOG,
  REQUEST_TIMELINE,
  REQUEST_CHANGE_PROGRESS,
} from '../defined/API';
import { METHOD, PAGINATION, X_UNIT_GROUP_ID } from '../constants';
import { handleError, handleResponse } from '../libs/http';

export async function getRequestsService({ params, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;
  try {
    let response = await callApi(REQUEST_GETS, {}, METHOD.GET, {
      params: params,
      headers
    });

    return handleResponse(response, type, 'result', params.page, params.limit);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getRequestDetailService({ params, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;
  try {
    let response = await callApi(REQUEST_GET(params.requestId), {}, METHOD.GET, {
      headers
    });

    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function approvalRequestService({ body, params, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;
  try {
    let response = await callApi(REQUEST_APPROVE(params.requestId), body, METHOD.PUT, {
      headers
    });

    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function changeProgressRequestService({ body, params, headers }, type) {
  const { FAIL } = type;

  try {
    const {
      requestId,
      progressId,
    } = params

    let response = await callApi(REQUEST_CHANGE_PROGRESS(requestId, progressId), body, METHOD.PUT, {
      headers,
    });

    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function denyRequestService({ params, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;
  try {
    let response = await callApi(REQUEST_DENY(params.requestId), { note: params.note }, METHOD.PUT, {
      headers
    });

    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function addRequestsService({ body, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;
  try {
    let response = await callApi(REQUEST_ADD, { ...body }, METHOD.POST, {
      headers
    });

    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function updateRequestService({ body, headers, id }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;
  try {
    let response = await callApi(REQUEST_GET(id), { ...body }, METHOD.PUT, {
      headers
    });

    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function duplicateRequestService({ body, headers }, type) {
  const { FAIL } = type;
  try {
    let response = await callApi(REQUEST_DUPLICATE, body, METHOD.POST, {
      headers,
    });

    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function exportDataRequestService({ params, headers }) {
  let response = await callApi(REQUEST_GETS, {}, METHOD.GET, { params, headers });
  return response;
}

export async function cancelRequestService({ params, headers }, type) {
  // const { FAIL } = type;

  // try {
  //   const {
  //     requestId,
  //     progressId,
  //   } = params;

  //   let response = await callApi(C, {}, METHOD.PUT, {
  //     headers
  //   });

  //   return handleResponse(response, type);
  // }
  // catch (err) {
  //   return handleError(err, FAIL);
  // }
}

export async function getEmployeesService({ params, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;
  try {
    let response = await callApi(REQUEST_EMPLOYEES_GETS, {}, METHOD.GET, {
      params: params,
      headers: headers
    });
    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getTotalTabService({ params, headers }, type = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}) {
  const { FAIL } = type;
  try {
    let response = await callApi(REQUEST_TOTAL_TABS_GETS, {}, METHOD.GET, {
      params: params,
      headers: headers
    });
    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getTasksService({ params, headers }, type) {
  const { FAIL } = type;

  try {
    let response = await callApi(REQUEST_TASK_GETS, {}, METHOD.GET, {
      params,
      headers
    });

    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function addTaskService({ body, headers }, type) {
  const { FAIL } = type;

  try {
    let response = await callApi(REQUEST_TASK_ADD, body, METHOD.POST, {
      headers
    });

    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function updateTaskService({ body, params, headers }, type) {
  const { FAIL } = type;

  try {
    let response = await callApi(REQUEST_TASK_UPDATE(params.taskId), body, METHOD.PUT, {
      headers
    });

    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function changeProgressTaskService({ body, params, headers }, type) {
  const { FAIL } = type;

  try {
    const {
      taskId,
      progressId,
    } = params

    let response = await callApi(REQUEST_TASK_CHANGE_PROGRESS(taskId, progressId), body, METHOD.PUT, {
      headers
    });

    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getActivitiesLogRequestService({ params, headers }, type) {
  const { FAIL } = type;

  try {
    const {
      requestId,
    } = params

    let response = await callApi(REQUEST_ACTIVITY_LOG(requestId), {}, METHOD.GET, {
      headers,
    });

    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}

export async function getTimelineRequestService({ params, headers }, type) {
  const { FAIL } = type;

  try {
    const {
      requestId,
    } = params

    let response = await callApi(REQUEST_TIMELINE(requestId), {}, METHOD.GET, {
      headers,
    });

    return handleResponse(response, type);
  }
  catch (err) {
    return handleError(err, FAIL);
  }
}


