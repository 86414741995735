import { createAction } from 'shared/utils';

const DEBT = {
  INIT: createAction('STATISTIC_SERVICE_DEBT_BY_MONTHS_INIT'),
  GETS: createAction('STATISTIC_SERVICE_DEBT_BY_MONTHS_GETS'),
  EXPORT: createAction('STATISTIC_SERVICE_DEBT_BY_MONTHS_EXPORT'),
  TOTAL: createAction('STATISTIC_SERVICE_DEBT_BY_MONTHS_TOTAL'),
};

export { DEBT };
