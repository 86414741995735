import moment from 'moment';
import { cloneDeep, isDate, isString } from 'lodash';
import { PHONE_CODE } from '../constants/phone';

export const convertDateToString = (date: Date | string, type?: string = 'dd/MM/yyyy') => {
    const getDate = (date) => {
        return date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    };
    const getMonth = (date) => {
        return date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
    };
    const getYear = (date) => {
        let year = date.getFullYear() + '';
        let tmp = 4 - year.length;
        for (let i = 0; i < tmp; i++) {
            year = '0' + year;
        }
        return year;
    };
    const getHours = (date) => {
        return date.getHours();
    };
    const getMinutes = (date) => {
        return date.getMinutes();
    };
    const getSeconds = (date) => {
        return date.getSeconds();
    };
    if (!date) {
        return '';
    }
    if (typeof date === 'string') {
        date = moment(date).toDate();
    }
    if (isDate(date)) {
        switch (type) {
            case 'dd/MM/yyyy':
                if (date) {
                    return getDate(date) + '/' + getMonth(date) + '/' + getYear(date);
                }
                else {
                    return date;
                }
            case 'yyyy-MM-dd':
                if (date) {
                    return getYear(date) + '-' + getMonth(date) + '-' + getDate(date);
                }
                else {
                    return date;
                }
            case 'yyyy-MM':
                if (date) {
                    return getYear(date) + '-' + getMonth(date);
                }
                else {
                    return date;
                }
            case 'yyyy-MM-dd hh:mm:ss':
                if (date) {
                    return getYear(date) + '-' + getMonth(date) + '-' + getDate(date) + ' ' + getHours(date) + ':' + getMinutes(date) + ':' + getSeconds(date);
                } else {
                    return date;
                }
            case 'dd/MM/yyyy hh:mm:ss':
                if (date) {
                    return getDate(date) + '/' + getMonth(date) + '/' + getYear(date) + ' ' + getHours(date) + ':' + getMinutes(date) + ':' + getSeconds(date);
                }
                else {
                    return date;
                }
            case 'MM/YYYY':
                if (date) {
                    return getMonth(date) + '/' + getYear(date);
                }
                else {
                    return date;
                }
            default:
                return date;
        }
    }
    else {
        return date;
    }
};

export function convertCreatedForToString(createdFor, type = 'MM/YYYY') {
    const createdForYear = parseInt(createdFor / 100);
    const createdForMonth = parseInt(createdFor % 100);

    return moment().year(createdForYear).month(createdForMonth - 1).format(type);
}

export const convertDateFormatServer = (date: Date | string) => {
    // format into server iso string;
    if (!date) return;
    if (isString(date) && new Date(date).toString() === 'Invalid Date') {
        console.error('Function convertFormatServer can\'t convert date');
    }
    const dt = new Date(date);
    return moment(dt).toISOString();
};

export const convertDateFormatClient = (date: Date | string) => {
    // format into client 'yyyy-MM-dd'
    if (!date) return;
    let dt = [];
    if (new Date(date).toString() === 'Invalid Date') {
        dt = date.split('/');
        date = new Date(dt[2], dt[1], dt[0]);
    }
    return convertDateToString(new Date(date), 'yyyy-MM-dd');
};

export const convertDateTimeFormatClient = (date: Date | string) => {
    // format into client 'yyyy-MM-dd hh:mm:ss'
    if (!date) return;
    let dt = [];
    if (new Date(date).toString() === 'Invalid Date') {
        dt = date.split('/');
        date = new Date(dt[2], dt[1], dt[0]);
    }
    return convertDateToString(new Date(date), 'yyyy-MM-dd hh:mm:ss');
};

export const convertStringToDate = (dateString: string, type = 'dd/MM/yyyy') => {
    if (!dateString) return;
    let dt = [];
    switch (type) {
        case 'dd/MM/yyyy':
            dt = dateString.split('/');
            return new Date(dt[2], dt[1], dt[0]);
        default:
            return new Date(dateString);
    }
};

export const convertStartOfDate = (date = new Date()) => {
    return moment(date).startOf('day').toDate();
};

export const convertEndOfDate = (date = new Date()) => {
    return moment(date).endOf('day').toDate();
};

export const convertStartOfMonth = (date = new Date()) => {
    return moment(date).startOf('month').toDate();
};

export const convertEndOfMonth = (date = new Date()) => {
    return moment(date).endOf('month').toDate();
};

// Get list months between 2 date
export const getListMonthDiff = (fromDate, toDate) => {
    let currentDate = new moment(fromDate, 'MM/YYYY');
    let endDate = new moment(toDate, 'MM/YYYY');

    let monthArr = [currentDate.format('MM/YYYY')];

    while (currentDate.isBefore(endDate, 'months')) {
        monthArr.push(currentDate.add(1, 'months').format('MM/YYYY'));
    };
    return monthArr;
};

export const convertMoneyToText = (money: number) => {
    const mangso = ['không', 'một', 'hai', 'ba', 'bốn', 'năm', 'sáu', 'bảy', 'tám', 'chín'];

    const dochangchuc = (so: number, daydu: boolean) => {
        let chuoi = '';
        let chuc = Math.floor(so / 10);
        let donvi = so % 10;
        if (chuc > 1) {
            chuoi = ' ' + mangso[chuc] + ' mươi';
            if (donvi === 1) {
                chuoi += ' mốt';
            }
        } else if (chuc === 1) {
            chuoi = ' mười';
            if (donvi === 1) {
                chuoi += ' một';
            }
        } else if (daydu && donvi > 0) {
            chuoi = ' lẻ';
        }
        if (donvi === 5 && chuc > 1) {
            chuoi += ' lăm';
        } else if (donvi > 1 || (donvi === 1 && chuc === 0)) {
            chuoi += ' ' + mangso[donvi];
        }
        return chuoi;
    };

    const docblock = (so: number, daydu: boolean) => {
        let chuoi = '';
        let tram = Math.floor(so / 100);
        so = so % 100;
        if (daydu || tram > 0) {
            chuoi = ' ' + mangso[tram] + ' trăm';
            chuoi += dochangchuc(so, true);
        } else {
            chuoi = dochangchuc(so, false);
        }
        return chuoi;
    };

    const dochangtrieu = function (so: number, daydu: boolean) {
        let chuoi = '';
        let trieu = Math.floor(so / 1000000);
        so = so % 1000000;
        if (trieu > 0) {
            chuoi = docblock(trieu, daydu) + ' triệu';
            daydu = true;
        }
        let nghin = Math.floor(so / 1000);
        so = so % 1000;
        if (nghin > 0) {
            chuoi += docblock(nghin, daydu) + ' nghìn';
            daydu = true;
        }
        if (so > 0) {
            chuoi += docblock(so, daydu);
        }
        return chuoi;
    };

    const docso = function (so: number) {
        if (so === 0) return mangso[0];
        let soString = so + '';
        let truocphay = '';
        let sauphay = '';
        if (soString.indexOf('.') !== -1) {
            truocphay = soString.substring(0, soString.indexOf('.'));
            sauphay = soString.substring(soString.indexOf('.') + 1, soString.length);
        }
        else {
            truocphay = soString;
        }
        let soTruocPhay = parseInt(truocphay);
        let soSauPhay = sauphay === '' ? 0 : parseInt(sauphay);
        let chuoiTruocPhay = '',
            hauto = '',
            chuoiSauPhay = '';
        do {
            let ty = soTruocPhay % 1000000000;
            soTruocPhay = Math.floor(soTruocPhay / 1000000000);
            if (soTruocPhay > 0) {
                chuoiTruocPhay = dochangtrieu(ty, true) + hauto + chuoiTruocPhay;
            } else {
                chuoiTruocPhay = dochangtrieu(ty, false) + hauto + chuoiTruocPhay;
            }
            hauto = ' tỷ';
        } while (soTruocPhay > 0);
        hauto = '';
        do {
            let ty = soSauPhay % 1000000000;
            soSauPhay = Math.floor(soSauPhay / 1000000000);
            if (soSauPhay > 0) {
                chuoiSauPhay = dochangtrieu(ty, true) + hauto + chuoiSauPhay;
            } else {
                chuoiSauPhay = dochangtrieu(ty, false) + hauto + chuoiSauPhay;
            }
            hauto = ' tỷ';
        } while (soSauPhay > 0);
        return chuoiTruocPhay + (sauphay === '' ? '' : ' phẩy ' + chuoiSauPhay);
    };

    return docso;
};

export const convertArrayToObject = (arr: Array<Object>, keyField: string = 'id') =>
    Object.assign({}, ...arr.map(item => ({ [item[keyField]]: cloneDeep(item) })));

export const convertSrcToFile = (src: string, fileName) => {
    return (fetch(src)
        .then(function (res) {
            return res.arrayBuffer();
        })
        .then(function (buf) {
            return new File([buf], fileName);
        })
    );
};

export const convertSizeImage = (url, width, height) => {
    if (!url) {
        return null;
    }
    let tmpArr = url.split('/');
    const size = `${width}x${height}`;
    tmpArr.splice(tmpArr.length - 1, 0, size);
    return tmpArr.join('/');
};

export const convertPhoneToNumber = (phone, prefixCode = PHONE_CODE) => {
    if (!phone) {
        return '';
    }
    if (/^0/.test(phone)) {
        return phone.substring(1);
    }
    return phone.substring(prefixCode.length);
};

export const formatPhone = (phone, prefixCode = PHONE_CODE) => {
    if (!phone) {
        return '';
    }
    return `${prefixCode}${phone}`;
};

export const convertThreeDigistToNumber = (value) => {
    if (!value && value !== 0) {
        return 0;
    }
    return Number(String(value).replace(/\,/g, ''));
};

export function dynamicAccessFieldObj(obj, path) {
    let i;
    path = path.split('.');
    for (i = 0; i < path.length - 1; i++) {
      if (!obj[path[i]]) {
        return null;
      }

      obj = obj[path[i]];
    }

    return obj[path[i]];
  }
