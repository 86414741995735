import { combineReducers } from 'redux';
import data, { initState as initStateData } from './data';
import AuthDucks from 'pages/Auth/ducks';
import CashbookDucks from 'pages/Statistics/CashBook/ducks';
import serviceEntity, { initState as initStateServiceEntity } from 'shared/serviceEntity/ducks';
import label, { initState as initStateLabel } from 'pages/Config/Label/ducks';
import fund, { initState as initStateFund } from 'pages/Config/PaymentAccount/ducks';
import advancePayment, { initState as initStateAdvancePayment } from 'pages/Reports/AdvancePayment/ducks';
import invoiceBill, { initState as initStateInvoiceBill } from 'pages/Invoice/NewBill/ducks';
import reportStored, { initState as initStateReportStored } from 'pages/Reports/ReportStored/ducks';
import operatingRevenueReport, { initState as initStateOperatingRevenueReport } from 'pages/Reports/OperatingRevenueNew/ducks';
import apartment, { initState as initStateApartment } from 'pages/Apartment/ducks';
import application, { initState as initStateApplication } from './application';
import building, { initState as initStateBuilding } from './building';
import buildingForm, { initState as initStateBuildingForm } from 'pages/Home/Building/BuildingForm/ducks';
import lockDataForm, { initState as initStateLockDataForm } from 'pages/Home/Building/BuildingForm/LockDataForm/ducks';
import config, { initState as initStateConfig } from './config';
import resident, { initState as initStateResident } from 'pages/Resident/ducks';
import staff, { initState as initStateStaff } from './staff';
import request, { initState as initStateRequest } from './request';
import apartmentDetail, { initState as initApartmentDetail } from './apartmentDetail';
import communicateTab, { initState as initCommunicateTab } from './communicate';
import common, { initState as initCommon } from './common';
import document, { initState as initDocument } from './document';
import systemService, { initState as initSystemService } from './serviceSystem';
import residentForm, { initState as initFormResident } from 'pages/Resident/ResidentForm/ducks';
import block, { initState as initBlock } from './block';
import floor, { initState as initFloor } from './floor';
import formula, { initState as initFormula } from './formula';
import surcharge, { initState as initSurcharge } from './surcharge';
import formContractTemplate, { initState as initFormContractTemplate } from './formContractTemplate';
import contractTemplate, { initState as initContractTemplate } from './contractTemplate';
import service, { initState as initService } from './service';
import invoice, { initState as initInvoice } from './invoice';
import payment, { initState as initPayment } from './payment';
import formServiceRegister, { initState as initFormServiceRegister } from './formServiceRegister';
import enterprise, { initState as initEnterprise } from './enterprise';
import formTargetInfo, { initState as initFormTargetInfo } from './formTargetInfo';
import formStaff, { initState as initFormStaff } from './formStaff';
import formService, { initState as initFormService } from './formService';
import formConfirmService, { initState as initFormConfirmService } from './formConfirmService';
import statistic, { initState as initStateStatistic } from './statistic';
import report, { initState as initStateReport } from './report';
import formPaySlip, { initState as initStateFormPaySlip } from './formPaySlip';
import formReceipt, { initState as initStateFormReceipt } from './formReceipt';
import formDocument, { initState as initStateFormDocument } from './formDocument';
import firebase, { initState as initStateFirebase } from './firebase';
import profile, { initState as initProfile } from './profile';
import permission, { initState as initPermission } from './permission';
import activity, { initState as initActivity } from './activity';
import dashboard, { initState as initDashboard } from './dashboard';
import news, { initState as initNews } from './news';
import card, { initState as initCard } from './card';
import pageAsset, { initState as initPageAsset } from './page-asset/page-asset';
import messageNotice from './messageNotice';
import residentUtility, { initState as initResidentUtility } from './residentUtility';
import paymentOnline, { initState as initPaymentOnline } from 'pages/Statistics/PaymentOnline/ducks';
import statisticServiceDebtByMonth, { initState as initStatisticServiceDebtByMonth } from 'pages/Statistics/Debt/ServiceByMonths/ducks';
import statisticServiceUpdateUntilCurrentTime, {
  initState as initStatisticServiceUpdateUntilCurrentTime,
} from 'pages/Statistics/Debt/ServiceUpdateUntilCurrentTime/ducks';
import receiptStatistic, { initState as initReceiptStatistic } from 'pages/Statistics/Receipt/ducks';
import paymentSlipDucks, { initState as initPaymentSlip } from 'pages/Statistics/PaymentSlip/ducks';
import sendBill, { initState as initStateSendBill } from 'pages/Invoice/SendBill/ducks';
import authorizationDucks, { initState as initAuthorization } from 'pages/Config/Authorization/ducks';
import staffDucks, { initState as initStaff } from 'pages/Staff/ducks';
import amenitiesDucks, { initState as initAmenities } from 'pages/Config/Amenities/ducks';
import amenitiesOrderDucks, { initState as initAmenitiesOrder } from 'pages/Amenities/ducks';
import amenitiesOrderDetailDucks, { initState as initAmenitiesOrderDetail } from 'pages/AmenitiesDetail/ducks';
import amenitiesOrderConfirmChangeStatusDucks, {
  initState as initAmenitiesOrderConfirmChangeStatus,
} from 'pages/AmenitiesOrderConfirmChangeStatus/ducks';
import paymentDucks, { initState as initPaymentPage } from 'pages/Payment/Content/ducks';
import serviceDucks, { initState as initConfigService } from 'pages/Config/Service/ducks';
import amenityMerchantOrderDucks, { initState as initAmenityMerchantOrderState } from 'pages/AmenityMerchant/ducks';
import amenityMerchantOrderDetailDucks, { initState as initAmenityMerchantOrderDetailState } from 'pages/AmenityMerchant/Detail/ducks';
import registrationFormCovidTestReducer, {
  initState as initRegistrationFormCovidTestState,
} from 'pages/ResidentRegistrationForm/CovidTest/ducks';
import registrationFormCovidVaccinationReducer, {
  initState as initRegistrationFormCovidVaccinationState,
} from 'pages/ResidentRegistrationForm/CovidVaccination/ducks';
import registrationFormSettingReducer, { initState as initRegistrationFormSettingState } from 'pages/ResidentRegistrationForm/ducks';
import registrationFormReceivePackageReducer, {
  initState as initRegistrationFormReceivePackageState,
} from 'pages/ResidentRegistrationForm/ReceivePackage/ducks';
import accessManagementReducer, { initState as initAccessManagementState } from 'pages/ResidentRegistrationForm/AccessManagement/ducks';
import eventReducer, { initState as initEventState } from 'pages/Communication/Event/ducks';

import { APARTMENT, CONFIG, PAYMENT } from '../constants/actionType';
import { INVOICE_BILL_RESET_STATE } from 'pages/Invoice/NewBill/ducks/invoiceBill.types';
import { SEND_BILL_RESET_STATE } from 'pages/Invoice/SendBill/ducks/sendBill.types';
import { RESET_DATA_CACHE_BUILDING } from 'pages/Home/Building/ducks';

export const initState = {
  data: initStateData,
  auth: AuthDucks.initState,
  cashbook: CashbookDucks.initState,
  label: initStateLabel,
  serviceEntity: initStateServiceEntity,
  fund: initStateFund,
  advancePayment: initStateAdvancePayment,
  invoiceBill: initStateInvoiceBill,
  reportStored: initStateReportStored,
  operatingRevenueReport: initStateOperatingRevenueReport,
  apartment: initStateApartment,
  building: initStateBuilding,
  config: initStateConfig,
  resident: initStateResident,
  staff: initStateStaff,
  request: initStateRequest,
  apartmentDetail: initApartmentDetail,
  communicateTab: initCommunicateTab,
  common: initCommon,
  application: initStateApplication,
  document: initDocument,
  systemService: initSystemService,
  residentForm: initFormResident,
  service: initService,
  block: initBlock,
  floor: initFloor,
  formula: initFormula,
  surcharge: initSurcharge,
  formContractTemplate: initFormContractTemplate,
  contractTemplate: initContractTemplate,
  invoice: initInvoice,
  payment: initPayment,
  formServiceRegister: initFormServiceRegister,
  enterprise: initEnterprise,
  formTargetInfo: initFormTargetInfo,
  formStaff: initFormStaff,
  formService: initFormService,
  formConfirmService: initFormConfirmService,
  statistic: initStateStatistic,
  report: initStateReport,
  formPaySlip: initStateFormPaySlip,
  formReceipt: initStateFormReceipt,
  formDocument: initStateFormDocument,
  firebase: initStateFirebase,
  profile: initProfile,
  permission: initPermission,
  activity: initActivity,
  dashboard: initDashboard,
  news: initNews,
  card: initCard,
  pageAsset: initPageAsset,
  residentUtility: initResidentUtility,
  buildingForm: initStateBuildingForm,
  lockDataForm: initStateLockDataForm,
  paymentOnline: initPaymentOnline,
  statisticServiceDebtByMonth: initStatisticServiceDebtByMonth,
  statisticServiceUpdateUntilCurrentTime: initStatisticServiceUpdateUntilCurrentTime,
  receiptStatistic: initReceiptStatistic,
  paymentSlip: initPaymentSlip,
  sendBill: initStateSendBill,
  authorization: initAuthorization,
  staffPage: initStaff,
  amenities: initAmenities,
  amenitiesOrder: initAmenitiesOrder,
  amenitiesOrderDetail: initAmenitiesOrderDetail,
  amenitiesOrderConfirmChangeStatus: initAmenitiesOrderConfirmChangeStatus,
  paymentPage: initPaymentPage,
  configService: initConfigService,
  amenityMerchantOrder: initAmenityMerchantOrderState,
  amenityMerchantOrderDetail: initAmenityMerchantOrderDetailState,
  registrationForm: {
    covidTest: initRegistrationFormCovidTestState,
    covidVaccination: initRegistrationFormCovidVaccinationState,
    receivePackage: initRegistrationFormReceivePackageState,
    setting: initRegistrationFormSettingState,
  },
  accessManagement: initAccessManagementState,
  event: initEventState,
};

const reducer = combineReducers({
  data,
  auth: AuthDucks.reducers,
  cashbook: CashbookDucks.reducers,
  label,
  serviceEntity,
  fund,
  advancePayment,
  invoiceBill,
  reportStored,
  operatingRevenueReport,
  apartment,
  building,
  config,
  resident,
  staff,
  request,
  apartmentDetail,
  communicateTab,
  common,
  application,
  document,
  systemService,
  residentForm,
  service,
  block,
  floor,
  formula,
  surcharge,
  formContractTemplate,
  contractTemplate,
  invoice,
  payment,
  formServiceRegister,
  enterprise,
  formTargetInfo,
  formStaff,
  formService,
  formConfirmService,
  statistic,
  report,
  formPaySlip,
  formReceipt,
  formDocument,
  firebase,
  profile,
  permission,
  activity,
  dashboard,
  news,
  card,
  pageAsset,
  messageNotice,
  residentUtility,
  buildingForm,
  lockDataForm,
  paymentOnline,
  statisticServiceDebtByMonth,
  statisticServiceUpdateUntilCurrentTime,
  receiptStatistic,
  paymentSlip: paymentSlipDucks,
  sendBill,
  authorization: authorizationDucks,
  staffPage: staffDucks,
  amenities: amenitiesDucks,
  amenitiesOrder: amenitiesOrderDucks,
  amenitiesOrderDetail: amenitiesOrderDetailDucks,
  amenitiesOrderConfirmChangeStatus: amenitiesOrderConfirmChangeStatusDucks,
  paymentPage: paymentDucks,
  configService: serviceDucks,
  amenityMerchantOrder: amenityMerchantOrderDucks,
  amenityMerchantOrderDetail: amenityMerchantOrderDetailDucks,
  registrationForm: combineReducers({
    covidTest: registrationFormCovidTestReducer,
    covidVaccination: registrationFormCovidVaccinationReducer,
    receivePackage: registrationFormReceivePackageReducer,
    setting: registrationFormSettingReducer,
  }),
  accessManagement: accessManagementReducer,
  event: eventReducer,
});

function refreshPageData(state, action) {
  switch (action.type) {
    case APARTMENT.REFRESH.REQUEST:
      return {
        ...state,
        apartment: initStateApartment,
      };
    case PAYMENT.RESET_PAGE.REQUEST:
      return {
        ...state,
        payment: initPayment,
      };
    case CONFIG.SERVICE.RESET_REDUCER.REQUEST:
      return {
        ...state,
        systemService: initSystemService,
      };
    case INVOICE_BILL_RESET_STATE: {
      return {
        ...state,
        invoiceBill: initStateInvoiceBill,
      };
    }
    case RESET_DATA_CACHE_BUILDING.REQUEST: {
      return {
        ...state,
        label: initStateLabel,
        serviceEntity: initStateServiceEntity,
        fund: initStateFund,
      };
    }
    case SEND_BILL_RESET_STATE: {
      return {
        ...state,
        sendBill: initStateSendBill,
        fund: initStateFund,
        serviceEntity: initStateServiceEntity,
      };
    }
    default:
      return state;
  }
}

export default function rootReducer(state, action) {
  const preState = reducer(state, action);
  const finalState = refreshPageData(preState, action);
  return finalState;
}
