import { call, put, takeLatest, all } from 'redux-saga/effects';
import { exportCashbookService, getDataCashbookService } from 'services/cashbook';
import { X_UNIT_GROUP_ID } from 'shared/constants';
import { CASHBOOK_EXPORT, CASHBOOK_GETS } from './cashbook.types';

function* getDataCashbook(action) {
  const { unitGroupId, filterObject, page } = action.payload;

  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };

  const params = {
    ...filterObject,
    page,
    limit: 20,
  };

  const result = yield call(getDataCashbookService, { params, headers }, CASHBOOK_GETS);
  yield put(result);
}

function* exportDataCashbook(action) {
  const { unitGroupId, filterObject } = action.payload;
  const params = {
    ...filterObject,
  };
  const headers = {
    [X_UNIT_GROUP_ID]: unitGroupId,
  };
  const result = yield call(exportCashbookService, { params, headers }, CASHBOOK_EXPORT);
  yield put(result);
  yield put({
    type: CASHBOOK_EXPORT.REFRESH,
  });
}

export default function* watch() {
  yield all([takeLatest(CASHBOOK_GETS.REQUEST, getDataCashbook), takeLatest(CASHBOOK_EXPORT.REQUEST, exportDataCashbook)]);
}
