import { language } from '../defined/Language';

export const PUBLISH_POST = 'publish';
export const REVOKE_POST = 'revoke';
export const DELETE_POST = 'delete';

export const actionPostOptions = [
    {
        label: language['label']['publish'],
        value: PUBLISH_POST,
    },
    {
        label: language['label']['revoke'],
        value: REVOKE_POST,
    },
    {
        label: language['label']['delete'],
        value: DELETE_POST,
    },
];

export const POST_STATUS = {
    DRAFT: '00DRA',
    PUBLISH: '10PUB',
    PENDING: '20PEN',
};