import { METHOD, X_UNIT_GROUP_ID } from '../constants';
import {
  RESIDENT,
  RESIDENT_ADD,
  RESIDENT_DELETE,
  RESIDENT_DELETE_LIST,
  RESIDENT_EXPORT,
  RESIDENT_GET_LIST,
  RESIDENT_IMPORT,
  RESIDENT_ROLE,
  RESIDENT_STATUS,
  RESIDENT_UPDATE,
} from '../defined/API';
import { callApi, callApiWithFile, downloadFile } from './api';
import { handleError, handleResponse } from '../libs/http';
import { RESIDENT_GET_ENTITY } from 'defined/API';

export const getResidentRoleService = async (
  type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) => {
  const { FAIL } = type;
  try {
    const response = await callApi(RESIDENT_ROLE, {}, METHOD.GET);
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const getResidentStatusService = async (
  type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) => {
  const { FAIL } = type;
  try {
    const response = await callApi(RESIDENT_STATUS, {}, METHOD.GET);
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const getResidentsService = async ({ headers, params }, type: Object) => {
  const { SUCCESS, FAIL } = type;
  try {
    const response = await callApi(RESIDENT_GET_LIST, {}, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type, 'result', params.page, params.limit);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const getResidentEntityService = async ({ residentId, unitGroupId }, type: Object) => {
  const { FAIL } = type;
  try {
    const response = await callApi(RESIDENT_GET_ENTITY(residentId), {}, METHOD.GET, {
      headers: {
        [X_UNIT_GROUP_ID]: unitGroupId,
      },
    });
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const addResidentService = async (
  { unitGroupId, body },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) => {
  const { FAIL } = type;
  try {
    const response = await callApi(RESIDENT_ADD, body, METHOD.POST, {
      headers: {
        [X_UNIT_GROUP_ID]: unitGroupId,
      },
    });
    return handleResponse(response, type, 'resident');
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const updateResidentService = async ({ residentId, body, unitGroupId }, type) => {
  const { FAIL } = type;
  try {
    const response = await callApi(`${RESIDENT_UPDATE}/${residentId}`, body, METHOD.PUT, {
      headers: {
        [X_UNIT_GROUP_ID]: unitGroupId,
      },
    });
    return handleResponse(response, type, 'resident');
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const deleteResidentService = async (
  { body, unitGroupId },
  type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) => {
  const { FAIL } = type;
  try {
    const response = await callApi(RESIDENT_DELETE_LIST, body, METHOD.POST, {
      headers: {
        [X_UNIT_GROUP_ID]: unitGroupId,
      },
    });
    return handleResponse(response, type);
  } catch (e) {
    handleError(e, FAIL);
  }
};

export const activeAccountResidentService = async ({ residentId, unitGroupId }, type: Object) => {
  const { FAIL } = type;
  try {
    const response = await callApi(`${RESIDENT}/${residentId}/active`, {}, METHOD.PUT, {
      headers: {
        [X_UNIT_GROUP_ID]: unitGroupId,
      },
    });
    return handleResponse(response, type);
  } catch (e) {
    return handleError(e, FAIL);
  }
};

export const inactiveAccountResidentService = async (
  { residentId, unitGroupId },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) => {
  const { FAIL } = type;
  try {
    const response = await callApi(`${RESIDENT}/${residentId}/inactive`, {}, METHOD.PUT, {
      headers: {
        [X_UNIT_GROUP_ID]: unitGroupId,
      },
    });
    return handleResponse(response, type);
  } catch (e) {
    return handleError(e, FAIL);
  }
};

export async function importResidentService(
  unitGroupId,
  file,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApiWithFile(RESIDENT_IMPORT, file, METHOD.POST, 'file', {
      headers: {
        [X_UNIT_GROUP_ID]: unitGroupId,
      },
    });
    return handleResponse(response, type, 'result', 1);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

export async function exportResidentService(
  unitGroupId,
  filter,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await downloadFile(RESIDENT_EXPORT, {}, METHOD.GET, {
      params: {
        ...filter,
      },
      headers: {
        [X_UNIT_GROUP_ID]: unitGroupId,
      },
    });
    return handleResponse(response, type, 'result');
  } catch (err) {
    return handleError(err, FAIL);
  }
}
