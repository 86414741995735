// import { language } from '../defined/language';

import { language } from '../defined/Language';
import { convertArrayToObject } from '../libs/convert';

export const SAVE_FROM_TO = 'SAVE_FROM_TO';
export const SAVE_NUMBER_USE = 'SAVE_NUMBER_USE';
export const REGISTER_ONE_TIME = 'REGISTER_ONE_TIME';
export const REGISTER_LONG_TERM = 'REGISTER_LONG_TERM';
export const SERVICE_TYPES = [{
    label: language['label']['save_from_to'],
    value: SAVE_FROM_TO,
}, {
    label: language['label']['save_number_use'],
    value: SAVE_NUMBER_USE,
}, {
    label: language['label']['register_one_time'],
    value: REGISTER_ONE_TIME,
}, {
    label: language['label']['register_long_term'],
    value: REGISTER_LONG_TERM,
}];
export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';
export const DISABLED = 'DISABLED';
export const CONTRACT_STATUS = [
    { id: ACTIVE, name: language['label']['active'] },
    { id: INACTIVE, name: language['label']['inactive'] },
];
export const TARGET_ABOUT_UNIT = 'UNIT';
export const TARGET_ABOUT_RESIDENT = 'RESIDENT';
const ACCUMULATED = 'ACCUMULATED';
const NUMBER_USE = 'NUMBER_USE';
const FORMULA_TYPE_PERCENT = 'PERCENT';
export const FORMULA_TYPES = [
    {
        value: ACCUMULATED,
        label: language['label']['accumulate'],
    }, {
        value: NUMBER_USE,
        label: language['label']['number_use'],
    }, {
        value: FORMULA_TYPE_PERCENT,
        label: language['label']['percent'],
    },
];
const PERCENT = 'PERCENT';
const PRICE_MULTI_AMOUNT = 'PRICE_MULTI_AMOUNT';
export const SURCHARGE_TYPES = [{
    value: PERCENT,
    label: language['label']['percent'],
}, {
    value: PRICE_MULTI_AMOUNT,
    label: language['description']['price_multi_amount'],
}];
const TEXT = 'TEXT';
const NUMBER = 'NUMBER';
const DATE = 'DATE';
export const EXPIRED = 'EXPIRED';
export const STOP = 'STOP';
export const INFORMATION_TYPES = [{
    value: TEXT,
    label: language['label']['text'],
}, {
    value: NUMBER,
    label: language['label']['number'],
}, {
    value: DATE,
    label: language['label']['date'],
}];

export const PROMOTION_TYPES = [
    {
        value: PERCENT,
        label: language['label']['percent'],
    }
];

export const PARKING_TYPE_OPTIONS = [
    {
        id: 'CAR',
        name: language['label']['car'],
    },
    {
        id: 'BICYCLE',
        name: language['label']['bicycle'],
    },
    {
        id: 'MOTORBIKE',
        name: language['label']['motorbike'],
    },
];
export const BICYCLE = 'BICYCLE';

export const PARKING_TYPE_BY_ID = convertArrayToObject(PARKING_TYPE_OPTIONS);

export const PARKING = 'PARKING';

export const RENT = 'RENT';

export const KEY_REQUIRED_IN_REQUIRED_INFO_WITH_PARKING_SERVICE = ['registration_plate'];

export const Type = {
    SAVE_FROM_TO: SAVE_FROM_TO,
    SAVE_NUMBER_USE: SAVE_NUMBER_USE,
    REGISTER_ONE_TIME: REGISTER_ONE_TIME,
    REGISTER_LONG_TERM: REGISTER_LONG_TERM,
    ACCUMULATED: ACCUMULATED,
    NUMBER_USE: NUMBER_USE,
    FORMULA_TYPE_PERCENT: FORMULA_TYPE_PERCENT,
    PERCENT,
    PRICE_MULTI_AMOUNT,
    TEXT,
    NUMBER,
    DATE,
    TYPES: [{
        label: 'SAVE_FROM_TO',
        value: SAVE_FROM_TO,
    }, {
        label: 'SAVE_NUMBER_USE',
        value: SAVE_NUMBER_USE,
    }, {
        label: 'REGISTER_ONE_TIME',
        value: REGISTER_ONE_TIME,
    }, {
        label: 'REGISTER_LONG_TERM',
        value: REGISTER_LONG_TERM,
    }],
    FORMULA_TYPES: [{
        value: ACCUMULATED,
        label: 'ACCUMULATED',
    }, {
        value: NUMBER_USE,
        label: 'NUMBER_USE',
    }],
    SURCHARGE_TYPES: [{
        value: PERCENT,
        label: 'PERCENT',
    }, {
        value: PRICE_MULTI_AMOUNT,
        label: 'PRICE_MULTI_AMOUNT',
    }],
    INFOMARION_TYPES: [{
        value: TEXT,
        label: 'TEXT',
    }, {
        value: NUMBER,
        label: 'NUMBER',
    }, {
        value: DATE,
        label: 'DATE',
    }],
};

export const Status = {
    INACTIVE,
    ACTIVE,
    EXPIRED,
    STOP,
};

export const NUMBER_OF_DIGITS_AFTER_DECIMAL = 4;