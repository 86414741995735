import { createAction } from 'shared/utils';

export const BUILDING = {
    GETS: createAction('BUILDING_GETS'),
    GET_BY_ID: createAction('BUILDING_GET_BY_ID'),
    ADD: createAction('BUILDING_ADD'),
    UPDATE: createAction('BUILDING_UPDATE'),
    DELETE: createAction('BUILDING_DELETE'),
    CHANGE_VIEW_TYPE: 'BUILDING_CHANGE_VIEW_TYPE',
    REFRESH_FETCHING_DATA_STATUS: 'BUILDING_REFRESH_FETCHING_DATA_STATUS',
};

export const RESET_DATA_CACHE_BUILDING = createAction('RESET_DATA_CACHE_BUILDING');
