import { call, put, spawn } from 'redux-saga/effects';
import { STAFF } from '../constants/actionType';
import {
    addStaffService, deleteListStaffService, exportStaffService,
    getDepartmentsService,
    getStaffsByPageService, importStaffService,
    updateStaffService,
} from '../services';
import { PAGINATION, X_UNIT_GROUP_ID } from '../constants';

export function* initStaffPage(action) {
    const {
        buildingId,
    } = action.payload;
    try {
        let payload = {
            code: 200,
        };
        let error = [];

        const params = {
            page: 1,
            limit: PAGINATION.LIMIT
        };

        const headers = {
            [X_UNIT_GROUP_ID]: buildingId,
        };

        const result = yield call(getStaffsByPageService, { params, headers }, STAFF.GETS);
        yield put(result);

        const headersDepartment = {
            [X_UNIT_GROUP_ID]: buildingId,
        };

        let resDepartment = yield call(getDepartmentsService, {
            headers: headersDepartment,
        }, STAFF.DEPARTMENT.GETS);
        yield put(resDepartment);

        yield put({
            type: STAFF.INIT.SUCCESS,
            payload: {
                ...payload,
                error: error.join(', '),
            },
        });
    }
    catch (err) {
        yield put({
            type: STAFF.INIT.FAIL,
            payload: {
                code: 0,
                error: err,
            },
        });
    }
}

export function* getStaffs(action) {
    const {
        buildingId,
        page,
        filters,
    } = action.payload;
    const params = {
        page,
        limit: PAGINATION.LIMIT,
        ...filters,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: buildingId,
    };
    const result = yield call(getStaffsByPageService, { params, headers }, STAFF.GETS);
    yield put({
        ...result,
    });
}

export function* addStaff(action) {
    const {
        staff,
        unitGroupId,
    } = action.payload;
    const result = yield call(addStaffService, {
        staff,
        unitGroupId,
    }, STAFF.ADD);
    yield put({
        ...result,
    });
    yield put({
        type: STAFF.ADD.REFRESH,
    });
}

export function* updateStaff(action) {
    const {
        staff,
        unitGroupId,
    } = action.payload;
    const result = yield call(updateStaffService, { staff, unitGroupId }, STAFF.UPDATE);
    yield put({
        ...result,
    });
    yield put({
        type: STAFF.UPDATE.REFRESH,
    });
}

export function* deleteStaff(action) {
    const {
        ids,
        unitGroupId,
    } = action.payload;

    const result = yield call(deleteListStaffService, { ids, unitGroupId }, STAFF.DELETE);

    yield put(result);

    yield put({
        type: STAFF.DELETE.REFRESH,
    });
}

function* getStaffResource(buildingId, page, filterObject) {
    const params = {
        page,
        limit: PAGINATION.LIMIT,
        ...filterObject,
    };

    const headers = {
        [X_UNIT_GROUP_ID]: buildingId,
    };
    const result = yield call(getStaffsByPageService, { params, headers }, STAFF.GETS);
    yield put(result);
}

export function* importStaff(action) {
    const {
        buildingId,
        file,
    } = action.payload;
    const result = yield call(importStaffService, buildingId, file, STAFF.IMPORT);
    yield spawn(() => call(getStaffResource, buildingId, 1, {}));
    yield put(result);
    yield put({
        type: STAFF.IMPORT.REFRESH,
    });
}

export function* exportStaff(action) {
    const {
        buildingId,
        filterObject,
    } = action.payload;
    const result = yield call(exportStaffService, buildingId, filterObject, STAFF.EXPORT);
    yield put(result);
    yield put({
        type: STAFF.EXPORT.REFRESH,
    });
}