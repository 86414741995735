import createDOMPurify from 'dompurify';

const DOMPurify = createDOMPurify(window);

const xssFilter = (text = '') => {
  const textFormat = String(text);
  if (DOMPurify.isSupported) {
    return DOMPurify.sanitize(textFormat);
  }
  return textFormat;
};

export default xssFilter;
