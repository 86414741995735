//account
import { GET_PERMISSION, LOGIN, LOGOUT, PASSWORD_FORGOT, PASSWORD_UPDATE, REGISTER, SET_NEW_PASSWORD, USER } from '../defined/API';
import { METHOD } from '../constants';
import { callApi } from './api';
import { checkResponseError, handleError, handleResponse } from '../libs/http';
import { isObject } from 'lodash';

const CAPTCHA_KEY = process.env['CAPTCHA_KEY'];

const getCaptcha = async () => {
  const promise = new Promise((resolve) => {
    grecaptcha.ready(function() {
      grecaptcha.execute(CAPTCHA_KEY, { action: 'submit' }).then(function(token) {
        console.log(token);
        resolve(token);
      });
    });
  });
  return promise;
};

export async function registerService(
  user: Object,
  type: {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(REGISTER, user, METHOD.POST);
    return handleResponse(response, type);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

export async function login(data: { username: string, password: string }, type: Object = { SUCCESS: 'SUCCESS', FAIL: 'FAIL' }) {
  const { SUCCESS, FAIL } = type;
  const captcha = await getCaptcha();
  try {
    let response = await callApi(LOGIN, data, METHOD.POST, {
      headers: {
        'X-CAPTCHA-TOKEN': captcha,
      },
    });
    return handleResponse(response, type);
  } catch (error) {
    return handleError(error, FAIL);
  }
}

export async function logout(data: { deviceToken: string }, type: Object = { SUCCESS: 'SUCCESS', FAIL: 'FAIL' }) {
  const { FAIL } = type;
  try {
    let response = await callApi(LOGOUT, data, METHOD.POST);
    return handleResponse(response, type);
  } catch (error) {
    return handleError(error, FAIL);
  }
}

export async function forgotPassword(email: string, type: Object = { SUCCESS: 'SUCCESS', FAIL: 'FAIL' }) {
  const { FAIL } = type;
  try {
    let response = await callApi(
      PASSWORD_FORGOT,
      {
        email,
      },
      METHOD.POST
    );
    return handleResponse(response, type);
  } catch (error) {
    return handleError(error, FAIL);
  }
}

export async function setNewPasswordService(
  accessToken,
  password,
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    let response = await callApi(
      SET_NEW_PASSWORD,
      {
        accessToken,
        password,
      },
      METHOD.PUT
    );
    return handleResponse(response, type);
  } catch (e) {
    return handleError(e, FAIL);
  }
}

export async function updatePassword(data, type: Object = { SUCCESS: 'SUCCESS', FAIL: 'FAIL' }) {
  const { FAIL } = type;
  try {
    let response = await callApi(PASSWORD_UPDATE, data, METHOD.PUT);
    return handleResponse(response, type);
  } catch (error) {
    return handleError(error, FAIL);
  }
}

export async function getPermissionService(
  { params, headers },
  type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
  }
) {
  const { FAIL } = type;
  try {
    const response = await callApi(GET_PERMISSION, null, METHOD.GET, {
      params,
      headers,
    });
    return handleResponse(response, type);
  } catch (e) {
    return handleError(e, FAIL);
  }
}
