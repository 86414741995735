import { LOGIN, PASSWORD, LOGOUT, REGISTER } from './auth.types';

// login
export const login = (email, password) => ({
    type: LOGIN.REQUEST,
    payload: {
        email,
        password,
    },
});

export const resetLogin = () => ({
    type: LOGIN.REFRESH,
});

// register
export const register = user => ({
    type: REGISTER.REQUEST,
    payload: {
        user,
    },
});

export const resetRegister = () => ({
    type: REGISTER.REFRESH,
});

// forgot password
export const forgotPassword = email => ({
    type: PASSWORD.FORGOT.REQUEST,
    payload: {
        email,
    },
});

export const resetForgotPassword = () => ({
    type: PASSWORD.FORGOT.REFRESH,
});

// set new password
export const setNewPassword = (accessToken, password) => ({
    type: PASSWORD.SET_NEW.REQUEST,
    payload: {
        accessToken,
        password,
    },
});

export const resetNewPassword = () => ({
    type: PASSWORD.SET_NEW.REFRESH,
});

// update password
export const updatePassword = (username, oldPassword, newPassword) => ({
    type: PASSWORD.UPDATE.REQUEST,
    payload: {
        username,
        oldPassword,
        newPassword,
    },
});

export const resetUpdatePassword = () => ({
    type: PASSWORD.UPDATE.REFRESH,
});

export const logout = user => ({
    type: LOGOUT.REQUEST,
    user,
});
