import { CARD } from '../constants/actionType';
import { combineReducers } from 'redux';
import paginate, { initStatePagination } from './paginate';
import fetching, { initStateFetching } from './fetching';
import { isEmpty } from 'lodash';

export const initState = {
    unitGets: { ...initStatePagination },
    cardSuggestGets: { ...initStatePagination },
    registerAdd: { ...initStateFetching },
    residentGets: { ...initStateFetching },
    vehicleGets: { ...initStatePagination },
    serviceGets: { ...initStatePagination },
    cardGets: { ...initStatePagination },
    cardAdd: {
        ...initStateFetching
    },
    cardUpdate: {
        ...initStateFetching
    },
    cardDelete: { ...initStatePagination },
    cardExport: { ...initStatePagination },
    cardHistory: { ...initStatePagination },
    cardHistoryUsage: { ...initStatePagination },
    cardImport: { ...initStatePagination },
    cardUpdateStatus: { ...initStatePagination },
    cardRegisterGets: { ...initStatePagination },
    cardRegisterPrint: { ...initStatePagination },
    cardRegisterRePrint: { ...initStatePagination },
    cardRegisterPreview: { ...initStatePagination },
    cardRegisterDelete: { ...initStateFetching },
    cardRegisterContractTemplate: { ...initStatePagination },
    cardRegisterExport: { ...initStatePagination },
    countryCode: {
        ...initStatePagination,
    },
};
const cardSuggestGets = paginate(CARD.SUGGEST.GETS);
const handleCardSuggest = reducer => (state, action) => {
    switch (action.type) {
        case CARD.SUGGEST.GETS.SUCCESS: {
            let currentCardByUnit = state.data['UNIT'] ? [...state.data['UNIT']] : [];
            let currentData = isEmpty(state.data) ? [] : [...state.data];
            currentData[action.index] = action.payload.result;
            if (action.index !== 'UNIT') {
                currentData['UNIT'] = currentCardByUnit;
            } else {
                currentData[action.index] = currentData[action.index].filter((item) => (item.type !== 'EMPTY'));
            }
            return {
                ...state,
                isFetching: false,
                code: action.payload.code,
                loadMore: action.payload.loadMore,
                data: currentData
            }
        }

        case CARD.SUGGEST.UPDATE_CARD_UNIT: {
            let currentData = isEmpty(state.data) ? [] : [...state.data];
            currentData['UNIT'] = action.payload.result;
            return {
                ...state,
                data: currentData
            }
        }
        default:
            return reducer(state, action);
    }
};

const reducer = combineReducers({
    unitGets: paginate(CARD.UNIT.GETS),
    cardSuggestGets: handleCardSuggest(cardSuggestGets),
    registerAdd: paginate(CARD.REGISTER_ADD),
    residentGets: paginate(CARD.REGISTER_RESIDENT_GETS),
    vehicleGets: paginate(CARD.REGISTER_VEHICLE_GETS),
    serviceGets: paginate(CARD.REGISTER_SERVICE_GETS),
    cardGets: paginate(CARD.GETS),
    cardAdd: paginate(CARD.ADD),
    cardUpdate: paginate(CARD.UPDATE),
    cardDelete: paginate(CARD.DELETE),
    cardExport: paginate(CARD.EXPORT),
    cardHistory: paginate(CARD.HISTORY),
    cardHistoryUsage: paginate(CARD.HISTORY_USAGE),
    cardImport: fetching(CARD.IMPORT),
    cardUpdateStatus: fetching(CARD.UPDATE_STATUS),
    cardRegisterGets: paginate(CARD.REGISTER_GETS),
    cardRegisterPrint: paginate(CARD.REGISTER_PRINT),
    cardRegisterRePrint: paginate(CARD.REGISTER_REPRINT),
    cardRegisterPreview: paginate(CARD.REGISTER_PREVIEW),
    cardRegisterDelete: fetching(CARD.REGISTER_DELETE),
    cardRegisterContractTemplate: paginate(CARD.REGISTER_CONTRACT_TEMPLATE_GETS),
    cardRegisterExport: paginate(CARD.REGISTER_EXPORT),
    countryCode: paginate(CARD.REGISTER_COUNTRY_CODE_GETS),
});
export default reducer;
