import { METHOD, X_UNIT_GROUP_ID } from '../constants';
import { callApi, downloadFile, callApiWithFile } from './api';
import { SUPPLY, SUPPLY_DELETE, SUPPLY_GETS, SUPPLY_UPDATE, TRANSACTION, SUPPLY_HISTORY_GETS, SUPPLY_EXPORT, SUPPLY_IMPORT, SUPPLY_HISTORY_EXPORT } from '../defined/API';
import { handleError, handleResponse } from '../libs/http';

export const getSupplyService = async (buildingId: number, filter: Object, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(SUPPLY_GETS, {}, METHOD.GET, {
            params: {
                ...filter,
            },
            headers: {
                [X_UNIT_GROUP_ID]: buildingId,
            },
        });
        return handleResponse(response, type, 'result', filter?.page, filter?.limit);
    } catch (e) {
        handleError(e, FAIL);
    }
};

export const addSupplyService = async ({ supply, unitGroupId }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(SUPPLY, supply, METHOD.POST, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        handleError(e, FAIL);
    }
};

export const updateSupplyService = async ({ id, supply, unitGroupId }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(SUPPLY_UPDATE(id), supply, METHOD.PUT, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        handleError(e, FAIL);
    }
};

export const deleteSupplyService = async ({ supplyId, unitGroupId }, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await callApi(SUPPLY_DELETE(supplyId), {}, METHOD.DELETE, {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        handleError(e, FAIL);
    }
};

export const addTransactionService = async (params, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;

    const { supplyId, idBuilding, amount, price, billNo, note, action } = params;

    try {
        const response = await callApi(TRANSACTION(supplyId), { amount, price, billNo, note, type: action }, METHOD.PUT, {
            headers: {
                [X_UNIT_GROUP_ID]: idBuilding,
            },
        });
        return handleResponse(response, type);
    } catch (e) {
        handleError(e, FAIL);
    }
};

export const getSupplyHistoryService = async (buildingId, filters, type) => {
    const { FAIL } = type;
    try {
        const response = await callApi(SUPPLY_HISTORY_GETS, {}, METHOD.GET, {
            params: filters,
            headers: {
                [X_UNIT_GROUP_ID]: buildingId,
            },
        });
        return handleResponse(response, type, 'result', filters.page, filters.limit);
    } catch (e) {
        handleError(e, FAIL);
    }
};

export const exportSupplyService = async ({ headers, params }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await downloadFile(SUPPLY_EXPORT, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type, 'result');
    } catch (e) {
        handleError(e, FAIL);
    }
};

export async function importSupplyService(unitGroupId, file, type = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) {
    const { FAIL } = type;
    try {
        const response = await callApiWithFile(SUPPLY_IMPORT, file, METHOD.POST, 'file', {
            headers: {
                [X_UNIT_GROUP_ID]: unitGroupId,
            },
        });
        return handleResponse(response, type, 'result', 1);
    } catch (e) {
        return handleError(e, FAIL);
    }
};

export const exportHistoryUseSupplyService = async ({ headers, params }, type: Object = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}) => {
    const { FAIL } = type;
    try {
        const response = await downloadFile(SUPPLY_HISTORY_EXPORT, {}, METHOD.GET, {
            params,
            headers,
        });
        return handleResponse(response, type, 'result');
    } catch (e) {
        handleError(e, FAIL);
    }
};