import { BUILDING } from './building.types';
import { combineReducers } from 'redux';
import paginate, { initStatePagination } from 'shared/utils/paginate';
import fetching, { initStateFetching } from 'shared/utils/fetching';

export const initState = {
    buildingGets: {
        ...initStateFetching,
    },
    buildingAdd: {
        ...initStateFetching,
    },
    buildingUpdate: {
        ...initStateFetching,
    },
    buildingDelete: {
        ...initStateFetching,
    },
    buildingGetById: {
        ...initStatePagination,
    },
};

const reducer = combineReducers({
    buildingGets: fetching(BUILDING.GETS),
    buildingAdd: fetching(BUILDING.ADD),
    buildingUpdate: fetching(BUILDING.UPDATE),
    buildingDelete: fetching(BUILDING.DELETE),
    buildingGetById: paginate(BUILDING.GET_BY_ID),
});

export default reducer;
