import paginate, { initStatePagination } from './paginate';
import { REPORT } from '../constants';
import { combineReducers } from 'redux';

export const initState = {
    labelData: {
        ...initStatePagination,
    },
    serviceData: {
        ...initStatePagination,
    },

    // debt
    debtGets: {
        ...initStatePagination,
    },
    totalDebtGets: {
        ...initStatePagination,
    },
    debtExport: {
        ...initStatePagination,
    },

    // advance payment
    advancePaymentGets: {
        ...initStatePagination,
    },
    totalAdvancePaymentGets: {
        ...initStatePagination,
    },
    advancePaymentExport: {
        ...initStatePagination,
    },

    // investor
    investorGets: {
        ...initStatePagination,
    },
    totalInvestorGets: {
        ...initStatePagination,
    },
    investorExport: {
        ...initStatePagination,
    },

    // must pay
    mustPayGets: {
        ...initStatePagination,
    },
    totalMustPayGets: {
        ...initStatePagination,
    },
    mustPayExport: {
        ...initStatePagination,
    },

    // operating
    operatingGets: {
        ...initStatePagination,
    },
    operatingExport: {
        ...initStatePagination,
    },
};

const labelData = paginate(REPORT.LABEL_GETS);
const serviceData = paginate(REPORT.SERVICE_GETS);

const debtGets = paginate(REPORT.DEBT.GETS);
const totalDebtGets = paginate(REPORT.DEBT.GET_TOTAL);
const debtExport = paginate(REPORT.DEBT.EXPORT);

const advancePaymentGets = paginate(REPORT.ADVANCE_PAYMENT.GETS);
const totalAdvancePaymentGets = paginate(REPORT.ADVANCE_PAYMENT.GET_TOTAL);
const advancePaymentExport = paginate(REPORT.ADVANCE_PAYMENT.EXPORT);

const investorGets = paginate(REPORT.INVESTOR.GETS);
const totalInvestorGets = paginate(REPORT.INVESTOR.GET_TOTAL);
const investorExport = paginate(REPORT.INVESTOR.EXPORT);

const mustPayGets = paginate(REPORT.MUST_PAY.GETS);
const totalMustPayGets = paginate(REPORT.MUST_PAY.GET_TOTAL);
const mustPayExport = paginate(REPORT.MUST_PAY.EXPORT);

const operatingGets = paginate(REPORT.OPERATING.GETS);
const operatingExport = paginate(REPORT.OPERATING.EXPORT);

export default combineReducers({
    labelData,
    serviceData,

    debtGets,
    totalDebtGets,
    debtExport,

    advancePaymentGets,
    totalAdvancePaymentGets,
    advancePaymentExport,

    investorGets,
    totalInvestorGets,
    investorExport,

    mustPayGets,
    totalMustPayGets,
    mustPayExport,

    operatingGets,
    operatingExport,
});
