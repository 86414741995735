import {
  AUTHORIZATION_DEPARTMENTS_GETS,
  AUTHORIZATION_GROUP_PERMISSION_GETS,
  AUTHORIZATION_DEPARTMENT_ADD,
  AUTHORIZATION_DEPARTMENT_UPDATE,
  AUTHORIZATION_DEPARTMENT_DELETE,
  AUTHORIZATION_POSITION_ADD,
  AUTHORIZATION_POSITION_UPDATE,
  AUTHORIZATION_POSITION_DELETE,
} from './authorization.types';

export const getDepartmentsAction = (unitGroupId = 0, isIncludePermission = true) => ({
  type: AUTHORIZATION_DEPARTMENTS_GETS.REQUEST,
  payload: {
    unitGroupId,
    isIncludePermission,
  },
});

export const addDepartmentAction = (unitGroupId = 0, payload = {}) => ({
  type: AUTHORIZATION_DEPARTMENT_ADD.REQUEST,
  payload: {
    unitGroupId,
    ...payload,
  },
});

export const updateDepartmentAction = (unitGroupId = 0, payload = {}) => ({
  type: AUTHORIZATION_DEPARTMENT_UPDATE.REQUEST,
  payload: {
    unitGroupId,
    ...payload,
  },
});

export const deleteDepartmentAction = (unitGroupId = 0, departmentId = 0) => ({
  type: AUTHORIZATION_DEPARTMENT_DELETE.REQUEST,
  payload: {
    unitGroupId,
    departmentId,
  },
});

export const addPositionAction = (unitGroupId = 0, payload = {}) => ({
  type: AUTHORIZATION_POSITION_ADD.REQUEST,
  payload: {
    unitGroupId,
    ...payload,
  },
});

export const updatePositionAction = (unitGroupId = 0, payload = {}) => ({
  type: AUTHORIZATION_POSITION_UPDATE.REQUEST,
  payload: {
    unitGroupId,
    ...payload,
  },
});

export const deletePositionAction = (unitGroupId = 0, positionId = 0) => ({
  type: AUTHORIZATION_POSITION_DELETE.REQUEST,
  payload: {
    unitGroupId,
    positionId,
  },
});

export const getGroupPermissionAction = () => ({
  type: AUTHORIZATION_GROUP_PERMISSION_GETS.REQUEST,
  payload: {},
});
