import { all, call, put } from 'redux-saga/effects';
import { getApartments as getApartmentsService, getFloorsService, exportApartmentService, getLabelsService } from 'services';
import { X_UNIT_GROUP_ID } from 'shared/constants';
import { APARTMENT, FLOOR } from './apartment.types';

export function* initApartmentPage(action) {
  try {
    const { buildingId } = action.payload;
    const [apartments, labels, floors] = yield all([
      call(getApartmentsService, buildingId, 1, 50, {}, APARTMENT.GETS),
      call(getLabelsService, { headers: { [X_UNIT_GROUP_ID]: buildingId } }, APARTMENT.LABEL_GETS),
      call(getFloorsService, buildingId, FLOOR.GETS),
    ]);
    yield all([put(apartments), put(labels), put(floors)]);
    yield put({
      type: APARTMENT.INIT.SUCCESS,
      payload: {
        code: 200,
      },
    });
  } catch (e) {
    yield put({
      type: APARTMENT.INIT.FAIL,
      payload: {
        code: 0,
        error: e,
      },
    });
  }
}

export function* getDataApartmentPage(action) {
  const result = yield call(getApartmentsService, action.buildingId, action.page, action.limit, action.filter, APARTMENT.GETS);
  yield put({
    ...result,
  });
}

export function* getFloors(action) {
  const result = yield call(getFloorsService, action.buildingId, FLOOR.GETS);
  yield put({
    ...result,
  });
}

export function* exportApartment(action) {
  const result = yield call(exportApartmentService, action.buildingId, action.filter, APARTMENT.EXPORT);
  yield put({
    ...result,
  });
  yield put({
    type: APARTMENT.EXPORT.REFRESH,
  });
}
