import { call, put, all, takeLatest, spawn } from 'redux-saga/effects';
import { PAGINATION_LIMIT, X_UNIT_GROUP_ID } from 'shared/constants';
import {
  activeAccountResidentService,
  deleteResidentService,
  exportResidentService,
  getResidentsService,
  importResidentService,
  inactiveAccountResidentService,
} from 'services';
import { RESIDENT } from 'constants/actionType';
import {
  RESIDENT_CHANGE_STATUS,
  RESIDENT_DELETE,
  RESIDENT_EXPORT,
  RESIDENT_GETS,
  RESIDENT_IMPORT,
} from 'pages/Resident/ducks/resident.types';

export function* getResidents(action) {
  const { unitGroupId, page, filterObject } = action.payload;
  const result = yield call(
    getResidentsService,
    {
      params: {
        ...filterObject,
        page,
        limit: PAGINATION_LIMIT,
      },
      headers: {
        [X_UNIT_GROUP_ID]: unitGroupId,
      },
    },
    RESIDENT_GETS
  );
  yield put({
    ...result,
  });
}

function* getResidentResource(buildingId, page, filterObject) {
  const resResident = yield call(
    getResidentsService,
    {
      params: {
        ...filterObject,
        page,
        limit: PAGINATION_LIMIT,
      },
      headers: {
        [X_UNIT_GROUP_ID]: buildingId,
      },
    },
    RESIDENT.GETS
  );
  yield put({
    ...resResident,
  });
}

export function* importResidents(action) {
  const { unitGroupId, file } = action.payload;
  const result = yield call(importResidentService, unitGroupId, file, RESIDENT_IMPORT);
  yield spawn(() => call(getResidentResource, unitGroupId, 1, {}));
  yield put(result);
}

export function* exportResident(action) {
  const { unitGroupId, filterObject } = action.payload;
  const result = yield call(exportResidentService, unitGroupId, filterObject, RESIDENT_EXPORT);
  yield put(result);
  yield put({
    type: RESIDENT.EXPORT.REFRESH,
  });
}

export function* deleteResident(action) {
  const { unitGroupId, array, filterObject, isAll } = action.payload;

  const body = {
    array,
    filterObject,
    isAll,
  };

  const result = yield call(deleteResidentService, { body, unitGroupId }, RESIDENT_DELETE);
  yield put(result);
}

export function* changeStatusResident(action) {
  const { residentId, unitGroupId, status } = action.payload;
  let result;
  if (status === 'ACTIVE') {
    result = yield call(activeAccountResidentService, { residentId, unitGroupId }, RESIDENT_CHANGE_STATUS);
  } else {
    result = yield call(inactiveAccountResidentService, { residentId, unitGroupId }, RESIDENT_CHANGE_STATUS);
  }
  yield put(result);
}

export default function* watch() {
  yield all([
    takeLatest(RESIDENT_GETS.REQUEST, getResidents),
    takeLatest(RESIDENT_DELETE.REQUEST, deleteResident),
    takeLatest(RESIDENT_DELETE.REQUEST, deleteResident),
    takeLatest(RESIDENT_IMPORT.REQUEST, importResidents),
    takeLatest(RESIDENT_EXPORT.REQUEST, exportResident),
    takeLatest(RESIDENT_CHANGE_STATUS.REQUEST, changeStatusResident),
  ]);
}
