import { call, takeLatest, put, all } from 'redux-saga/effects';
import {
  getsCovidTestRegistrationFormService,
  cancelCovidTestRegistrationFormService,
  exportCovidTestRegistrationFormService,
  updateNoteCovidTestRegistrationFormService,
} from 'services/residentRegistrationForm';
import { X_UNIT_GROUP_ID } from 'shared/constants';
import {
  REGISTRATION_FORM_COVID_TEST_ACTION_CANCEL,
  REGISTRATION_FORM_COVID_TEST_ACTION_EXPORT,
  REGISTRATION_FORM_COVID_TEST_ACTION_GETS,
  REGISTRATION_FORM_COVID_TEST_ACTION_UPDATE,
} from './covid-test.types';

function* getsCovidTestRegistrationForm(action) {
  const { idBuilding, filterObj, page, limit } = action.payload;
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const params = {
    ...filterObj,
    page,
    limit,
  };
  const result = yield call(getsCovidTestRegistrationFormService, headers, params, REGISTRATION_FORM_COVID_TEST_ACTION_GETS);
  yield put(result);
}

function* updateNoteCovidTestRegistrationForm(action) {
  const { idBuilding, id, note } = action.payload;
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const body = {
    id,
    note,
  };
  const result = yield call(updateNoteCovidTestRegistrationFormService, headers, body, REGISTRATION_FORM_COVID_TEST_ACTION_UPDATE);
  yield put(result);
  yield put({
    type: REGISTRATION_FORM_COVID_TEST_ACTION_UPDATE.REFRESH,
  });
}

function* cancelNoteCovidTestRegistrationForm(action) {
  const { idBuilding, id, reason } = action.payload;
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const body = {
    id,
    reason,
  };
  const result = yield call(cancelCovidTestRegistrationFormService, headers, body, REGISTRATION_FORM_COVID_TEST_ACTION_CANCEL);
  yield put(result);
  yield put({
    type: REGISTRATION_FORM_COVID_TEST_ACTION_CANCEL.REFRESH,
  });
}

function* exportCovidTestRegistrationForm(action) {
  const { idBuilding, filterObj } = action.payload;
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const params = {
    ...filterObj,
  };
  const result = yield call(exportCovidTestRegistrationFormService, headers, params, REGISTRATION_FORM_COVID_TEST_ACTION_EXPORT);
  yield put(result);
  yield put({
    type: REGISTRATION_FORM_COVID_TEST_ACTION_EXPORT.REFRESH,
  });
}

export default function* watch() {
  yield all([
    takeLatest(REGISTRATION_FORM_COVID_TEST_ACTION_GETS.REQUEST, getsCovidTestRegistrationForm),
    takeLatest(REGISTRATION_FORM_COVID_TEST_ACTION_UPDATE.REQUEST, updateNoteCovidTestRegistrationForm),
    takeLatest(REGISTRATION_FORM_COVID_TEST_ACTION_CANCEL.REQUEST, cancelNoteCovidTestRegistrationForm),
    takeLatest(REGISTRATION_FORM_COVID_TEST_ACTION_EXPORT.REQUEST, exportCovidTestRegistrationForm),
  ]);
}
